export const ExtraChanceIconBlue = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="36"
            height="35"
            viewBox="0 0 36 35"
            fill="none"
        >
            <g clip-path="url(#clip0_33677_488466)">
                <circle
                    cx="17.6998"
                    cy="17.6657"
                    r="16.0492"
                    fill="#FFFDFB"
                    stroke="#00D1D2"
                    stroke-width="2.11183"
                />
                <path
                    d="M17.7207 5.3291C20.9912 5.3291 24.1278 6.62832 26.4405 8.94094C28.7531 11.2536 30.0523 14.3901 30.0523 17.6607C30.0523 20.9312 28.7531 24.0678 26.4405 26.3804C24.1278 28.6931 20.9912 29.9923 17.7207 29.9923V5.3291Z"
                    fill="#00D1D2"
                />
                <path
                    d="M19.845 25.7095H16.172V17.2327H11.9131L18.0056 9.10742L24.0981 17.2327H20.425H19.8392V25.7095H19.845Z"
                    fill="#FFFDFB"
                />
                <path
                    d="M19.2616 25.1164V16.6397H22.9346L18.0138 10.0785L13.0929 16.6397H16.766V25.1164H19.2674M20.4391 26.2881H15.5944V17.8113H11.9213C11.4409 17.8113 11.1656 17.2606 11.4526 16.874L17.5451 8.74874C17.7794 8.43825 18.2481 8.4324 18.4824 8.74874L24.5749 16.874C24.862 17.2606 24.5866 17.8113 24.1063 17.8113H20.4332V26.2881H20.4391Z"
                    fill="#34303D"
                />
            </g>
            <defs>
                <clipPath id="clip0_33677_488466">
                    <rect
                        width="35.8014"
                        height="34.1896"
                        fill="white"
                        transform="translate(0.199219 0.560547)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};
