import styles from './ProjectCard.module.scss';
import { useWindowSize } from '@utils/hooks/windowSize';
import { IProjectCardProps } from '@containers/IQOSUniverse/articles/RdwVoting/components/ProjectCard/interface';

const ProjectCard = ({ project }: IProjectCardProps) => {
    const windowSize = useWindowSize();
    const isMobile = windowSize.width < 500;

    return (
        <section className={styles.cardWrapper}>
            <img className={styles.thumbnail} src={project.thumbnail} alt={project.title} />

            {isMobile ? (
                <div className={styles.mobileContent}>
                    <h3>{project.title}</h3>
                    <small>Proiect înscris de: {project.creditsTo}</small>
                    <p>{project.subtitle}</p>
                </div>
            ) : (
                <div className={styles.content}>
                    <div className={styles.title}>
                        <h3>{project.title}</h3>
                    </div>
                    <div className={styles.extraInfo}>
                        <small>Proiect înscris de: {project.creditsTo}</small>
                        <p>{project.subtitle}</p>
                    </div>
                </div>
            )}
        </section>
    );
};

export default ProjectCard;
