import React, { useState } from 'react';
import styles from './SummerFirstExperience.module.scss';

// Helpers
import { useWindowSize } from '@utils/hooks/windowSize';
import classNames from 'classnames';

// Components
import ArticleContainer from '@containers/IQOSUniverse/components/ArticleContainer';
import KeenSlider from '@components/KeenSlider';
import ArticleHeader from '@containers/IQOSUniverse/components/ArticleHeader';
import VideoModalQuiz from '@containers/IQOSUniverse/components/VideoModalQuiz';
import CompletedStateArticle from '@containers/IQOSUniverse/components/CompletedStateArticle/CompletedStateArticle';
import {
    Experience,
    SliderExperience1,
    SliderExperience2,
    SliderExperience3
} from '@containers/IQOSUniverse/articles/SummerFirstExperience/SliderList/SliderExperience';

// Redux
import { useAppSelector } from '@app/hooks';
import Button from '@components/Layout/Button';
import { config } from '@utils/config';

const SummerFirstExperience = () => {
    const { article } = useAppSelector((state) => state.iqosUniverse);
    const quiz = useAppSelector((state) => state.quiz?.quizQuestions?.quiz);

    const [toggleModal, setToggleModal] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);

    const windowSize = useWindowSize();
    const isMobile = windowSize.width < 650;

    return (
        <section className={styles.wrapper}>
            <ArticleHeader
                videoQuiz={() => {
                    setToggleModal(true);
                }}
            />

            <VideoModalQuiz
                toggleModal={toggleModal}
                setToggleModal={() => setToggleModal(false)}
                setShowSuccess={() => {
                    setShowSuccess(true);
                }}
            />
            <div className="content">
                <h3>
                    Marea Neagră, dintr-o perspectivă inedită, pentru 10 dintre membrii IQOS Club!
                </h3>
                <p>
                    Primii 10 câștigători ai concursului „Trăiește vara la următorul nivel cu IQOS
                    ILUMA” au avut parte de o experiență la malul mării marca Together X, în
                    perioada 25-27 iulie.
                </p>
            </div>

            <ArticleContainer background={'#F6F4F0'}>
                <KeenSlider
                    className={styles.experienceSlider}
                    spacing={isMobile ? 8 : 16}
                    mode={'free-snap'}
                    withDots={true}
                    dotColor={'#8C8A90'}
                    withArrows={false}
                    dotsClass={styles.leftDots}
                >
                    {SliderExperience1.map((exp: (typeof SliderExperience1)[0]) => {
                        return (
                            <div className={styles.slideItem}>
                                <img src={exp.image} alt={`${exp.id}`} />
                            </div>
                        );
                    })}
                </KeenSlider>
                <div className={classNames('content', styles.noMarginTop)}>
                    <h3>O plimbare pe velier, cu muzică live și Prosecco</h3>
                    <p>
                        Unul dintre momentele memorabile a fost o plimbare cu velierul, unde
                        câștigătorii au savurat un pahar de prosecco și s-au bucurat de întreaga
                        experiență, acompaniați de un recital surpriză live la saxofon.
                    </p>
                </div>
            </ArticleContainer>

            <ArticleContainer background={'#00d1d2'}>
                <KeenSlider
                    className={styles.experienceSlider}
                    spacing={isMobile ? 8 : 16}
                    mode={'free-snap'}
                    withDots={true}
                    dotColor={'#fffdfb'}
                    dotsClass={styles.sliderDots}
                    withArrows={false}
                >
                    {SliderExperience2.map((exp: Experience) => {
                        return (
                            <div className={styles.slideItem}>
                                <h3>{exp.title}</h3>
                                <img src={exp.image} alt={exp.title} />
                                <h3>{exp.belowDescription.title}</h3>
                                <p>{exp.belowDescription.subtitle}</p>
                            </div>
                        );
                    })}
                </KeenSlider>
            </ArticleContainer>
            <ArticleContainer background={'#F6F4F0'}>
                <div className={classNames('content', styles.noMarginbottom)}>
                    <h3>O petrecere memorabilă </h3>
                </div>

                <KeenSlider
                    className={styles.experienceSlider}
                    spacing={isMobile ? 8 : 16}
                    mode={'free-snap'}
                    withDots={true}
                    dotColor={'#8C8A90'}
                    withArrows={false}
                    dotsClass={styles.leftDots}
                >
                    {SliderExperience3.map((exp: (typeof SliderExperience3)[0]) => {
                        return (
                            <div className={styles.slideItem}>
                                <img src={exp.image} alt={`${exp.id}`} />
                            </div>
                        );
                    })}
                </KeenSlider>

                <div className={classNames('content', styles.noMarginTop)}>
                    <p>
                        Finalul experienței Together X a fost marcat de o petrecere specială, unde
                        câștigătorii au avut parte de o mulțime de surprize.
                    </p>

                    {/*<p>*/}
                    {/*    Participă zilnic în campanie și poți acumula Șanse pentru următoarele*/}
                    {/*    experiențe Together X.*/}
                    {/*</p>*/}

                    <Button
                        type={'solid'}
                        color={'dark'}
                        hover={'turquoise'}
                        label={'Participă'}
                        href={config.path.contests}
                    />
                </div>
            </ArticleContainer>

            {(showSuccess || article.completed_quiz) && (
                <CompletedStateArticle
                    className={styles.completedStateArticle}
                    article={{
                        points: article.points,
                        quiz_points: article.quiz_points,
                        quiz_end_date: article.quiz_end_date
                    }}
                    // chances={quiz?.chances}
                    campaignTitle={quiz?.campaign_title}
                />
            )}
        </section>
    );
};

export default SummerFirstExperience;
