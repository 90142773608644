export const CheckIcon = () => {
    return (
        <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.038 19.3094C15.6067 19.3094 19.3103 15.6057 19.3103 11.037C19.3103 6.46831 15.6067 2.76465 11.038 2.76465C6.46928 2.76465 2.76562 6.46831 2.76562 11.037C2.76562 15.6057 6.46928 19.3094 11.038 19.3094Z"
                fill="#00D1D2"
            />
            <path
                d="M6.99805 11.9199L9.49709 14.5346L15.8342 8.02393"
                stroke="#34303D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
                stroke="#34303D"
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
        </svg>
    );
};
