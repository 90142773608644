// Axios
import axios, { AxiosError, AxiosResponse } from 'axios';

// Utils
import { config } from '@utils/config';
import store from 'store';
import dayjs from '@utils/dayjs';
import * as Sentry from '@sentry/react';

// Error switch
import Errors, { logoutUser } from '@utils/errorsHandler';

// Refresh token
import refreshTokenAsync from '@components/RefreshTokenDesktop/refreshTokenAsync';

const axiosRequest = axios.create({
    baseURL: config.apiUrl,
    withCredentials: true,
    withXSRFToken: true,
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
    }
});

let isRefreshingToken = false;
let tokenRefreshQueue: Array<(token: string) => void> = [];

axiosRequest.interceptors.request.use(
    async (axiosConfig) => {
        const token = store.get(config.user.authToken);
        const isPWA = !!store.get(config.user.isPWA);
        const whereToUseCaptcha = ['user/insert-code'];

        axiosConfig.headers!.isPWA = isPWA;

        if (token) {
            axiosConfig.headers!['X-Api-Key'] = `Bearer ${token}`;
        }

        if (!token || axiosConfig.method !== 'get') {
            axiosConfig.baseURL = config.proxiedApiUrl;
        }

        const authTokenExpired = isAuthTokenExpired();

        if (authTokenExpired) {
            // If requests are using an expired token
            if (isPWA) {
                // If is on PWA, refresh the authToken
                if (isRefreshingToken) {
                    const newToken = await new Promise<string>((resolve) => {
                        tokenRefreshQueue.push((token: string) => {
                            resolve(token);
                        });
                    });
                    axiosConfig.headers!['X-Api-Key'] = `Bearer ${newToken}`;
                } else {
                    isRefreshingToken = true;
                    try {
                        await refreshTokenAsync();
                        const newToken = store.get(config.user.authToken);
                        axiosConfig.headers!['X-Api-Key'] = `Bearer ${newToken}`;
                        tokenRefreshQueue.forEach((cb) => cb(newToken));
                    } finally {
                        isRefreshingToken = false;
                        tokenRefreshQueue = [];
                    }
                }
            } else {
                // Else, it means we are on desktop: logout the user
                logoutUser('we are on desktop, logout the user in axios.ts');
            }
        }

        if (axiosConfig.url && whereToUseCaptcha.includes(axiosConfig.url)) {
            // Turnstile Captcha
            const turnstileKey = config.storage.turnstileKey;
            await new Promise((resolve) => {
                turnstile.ready(() => {
                    turnstile.render('#turnstile', {
                        sitekey: turnstileKey,
                        callback: (token: string) => {
                            axiosConfig.headers!['Captcha-Token'] = token;
                            resolve(token);
                        }
                    });
                });
            });
        }

        return axiosConfig;
    },
    (err) => {
        Errors.errorSwitch(err.response.status);
    }
);

axiosRequest.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error: AxiosError) => {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            Errors.errorSwitch(error.response.status);
            Sentry.captureException(error.response.data);
        } else {
            // Something happened in setting up the request that triggered an Error
            Sentry.captureException(error.message);
        }
        Sentry.captureException(error);
        return Promise.reject(error);
    }
);

export const isAuthTokenExpired = (): boolean => {
    const authTokenExpiresAt = store.get(config.user.authTokenExpiresAt);

    // Check if token is still valid. If it's not present in store, we assume that it's expired
    if (authTokenExpiresAt) {
        return dayjs().isAfter(authTokenExpiresAt);
    } else {
        return true;
    }
};

export const isRefreshTokenExpired = (): boolean => {
    const refreshTokenExpiresAt = store.get(config.user.refreshTokenExpiresAt);

    // Check if token is still valid. If it's not present in store, we assume that it's expired
    if (refreshTokenExpiresAt) {
        return dayjs().isAfter(refreshTokenExpiresAt);
    } else {
        return true;
    }
};

export default axiosRequest;
