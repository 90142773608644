export const ContestMenuIcon = () => {
    return (
        <svg
            width="19"
            height="22"
            viewBox="0 0 19 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M15.2158 13.5305L18 18.3535L14.8226 17.9317L13.5992 20.8946L10.3526 15.3778"
                stroke="#C4C3C6"
                strokeWidth="1.71"
                strokeLinejoin="round"
            />
            <path
                d="M3.78498 13.5295L0.999945 18.3541L4.17574 17.9314L5.39918 20.8944L8.76161 15.4919"
                stroke="#C4C3C6"
                strokeWidth="1.71"
                strokeLinejoin="round"
            />
            <path
                d="M9.44246 15.3808C13.413 15.3808 16.6318 12.1616 16.6318 8.19039C16.6318 4.21922 13.413 0.999939 9.44246 0.999939C5.47188 0.999939 2.25308 4.21922 2.25308 8.19039C2.25308 12.1616 5.47188 15.3808 9.44246 15.3808Z"
                stroke="#C4C3C6"
                strokeWidth="1.71"
            />
            <path
                d="M6.20923 7.13281H12.6757M7.82675 9.44314H11.06H7.82675Z"
                stroke="#C4C3C6"
                strokeWidth="1.71"
                strokeLinecap="round"
            />
        </svg>
    );
};
