import { config } from '@utils/config';
import styles from './SocialMedia.module.scss';

// Components
import Button from '@components/Layout/Button';

// Assets
import { FaFacebookF, FaInstagram, FaYoutube } from 'react-icons/fa';

const SocialMedia = () => {
    return (
        <section className={styles.wrapper}>
            <h3>Urmărește-ne pe social media</h3>

            <div className={styles.socials}>
                <Button noStyle={true} href={config.path.socialMedia.facebook}>
                    <FaFacebookF />
                </Button>

                <Button noStyle={true} href={config.path.socialMedia.instagram}>
                    <FaInstagram />
                </Button>

                <Button noStyle={true} href={config.path.socialMedia.youtube}>
                    <FaYoutube />
                </Button>
            </div>
        </section>
    );
};

export default SocialMedia;
