import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@app/store';
import { fetchCount } from '../../features/counter/counterAPI';

export interface ExampleState {
  value: number,
  status: 'idle' | 'loading' | 'failed';
}

const initialState: ExampleState = {
  value: 0,
  status: 'idle'
};

export const exampleAsync = createAsyncThunk(
  'counter/fetchCount',
  async () => {
    const randomNumber = Math.floor(Math.random() * 100);
    const response = await fetchCount(randomNumber);
    return response.data;
  }
);

export const exampleSlice = createSlice({
  name: 'example',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(exampleAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(exampleAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = action.payload;
      })
      .addCase(exampleAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const selectCount = (state: RootState) => state.example.value;

export default exampleSlice.reducer;
