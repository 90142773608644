import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axiosRequest from '@utils/axios';

export interface FaqState {
    status: 'idle' | 'loading' | 'failed';
    faqs: {
        data: Array<{
            id: number;
            title: string;
            content: string;
        }>;
        links: {};
        meta: {
            current_page: number;
            per_page: number;
            total: number;
        };
    };
    error: any;
}

interface AxiosError {
    response: {
        data: {
            message: string;
        };
    };
}

const initialState: FaqState = {
    status: 'idle',
    faqs: {
        data: [],
        links: {},
        meta: {
            current_page: 1,
            per_page: 10,
            total: 1
        }
    },
    error: {}
};

export const getFaqs = createAsyncThunk('containers/FAQs', async (values: object) => {
    try {
        const response = await axiosRequest.get(`/faqs`, { params: values }).then((res) => {
            return res;
        });

        return response.data;
    } catch (e) {
        return (e as AxiosError).response.data.message;
    }
});

export const FAQsSlice = createSlice({
    name: 'faqs',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getFaqs.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getFaqs.fulfilled, (state, action) => {
                state.status = 'idle';
                state.faqs = action.payload;
            })
            .addCase(getFaqs.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    }
});

export default FAQsSlice.reducer;
