import styles from './Input.module.scss';

// Components
import { Form, Input as AntdInput } from 'antd';

// Props
import { IInputProps } from '@components/Quiz/Input/interface';

const Input = (props: IInputProps) => {
    const { data } = props;

    return (
        <div className={styles.inputContainer}>
            <Form.Item
                name={data.id}
                rules={[
                    { type: 'string' },
                    {
                        required: data.client_validation === 'required',
                        message: 'Acest câmp este obligatoriu'
                    },
                    { min: 3, message: 'Minimum 3 caractere' },
                    { max: 300, message: 'Maximum 300 de caractere' },
                    { whitespace: true, message: 'Răspunsul nu poate fi gol' }
                ]}
            >
                <AntdInput placeholder={'Răspunsul tău'} />
            </Form.Item>
        </div>
    );
};

export default Input;
