export const MapPinIcon = () => {
    return (
        <svg
            width="14"
            height="19"
            viewBox="0 0 14 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M13.0278 6.69649C13.0278 9.49188 9.29535 14.4686 7.65883 16.5219C7.26644 17.0113 6.5391 17.0113 6.14672 16.5219C4.51019 14.4686 0.777771 9.49188 0.777771 6.69649C0.777771 3.30621 3.52126 0.555603 6.90277 0.555603C10.2843 0.555603 13.0278 3.30621 13.0278 6.69649Z"
                fill="#00D1D2"
            />
            <circle cx="6.99997" cy="6.38889" r="3.61111" stroke="#FFFDFB" strokeWidth="0.555556" />
            <circle cx="7.00002" cy="6.38894" r="2.33333" fill="#FFFDFB" />
        </svg>
    );
};