// Assets Slide 1
import img1 from '../assets/slider1/img1.jpg';
import img2 from '../assets/slider1/img2.jpg';
import img3 from '../assets/slider1/img3.jpg';
import img4 from '../assets/slider1/img4.jpg';

// Assets Slide 2
import image1 from '../assets/slider2/img1.jpg';
import image2 from '../assets/slider2/img2.jpg';
import image3 from '../assets/slider2/img3.jpg';
import image4 from '../assets/slider2/img4.jpg';

// Assets Slide 3
import img31 from '../assets/slider3/img3.1.jpg';
import img33 from '../assets/slider3/img3.3.jpg';
import img34 from '../assets/slider3/img3.4.jpg';

// import image4 from '../assets/slide1Img4.png';

export interface Experience {
    title: string;
    image: string;
    titleDark: boolean;
    belowDescription: { title: string; subtitle: string };
}

export const SliderExperience1: { id: number; image: string }[] = [
    {
        id: 1,
        image: img2
    },
    {
        id: 2,
        image: img1
    },
    {
        id: 3,
        image: img3
    },
    {
        id: 4,
        image: img4
    }
];

export const SliderExperience2: Experience[] = [
    {
        title: `Mai multe momente speciale, marca TogetherX`,
        belowDescription: {
            title: 'Fine dining',
            subtitle:
                'Cu Chef Alex Petricean, au experimentat fine dining-ul într-o manieră desăvârșită. '
        },
        image: image1,
        titleDark: true
    },
    {
        title: `Mai multe momente speciale, marca TogetherX`,
        belowDescription: {
            title: 'Degustare cafea de specialitate',
            subtitle:
                'Au apreciat aromele cafelei de specialitate, pregătită de un barista cu experiență.'
        },
        image: image2,
        titleDark: true
    },
    {
        title: `Mai multe momente speciale, marca TogetherX`,
        belowDescription: {
            title: 'Cocktailuri speciale',
            subtitle: 'S-au bucurat de cocktailuri savuroase și de un show de bartending flair.'
        },
        image: image3,
        titleDark: true
    },
    {
        title: `Mai multe momente speciale, marca TogetherX`,
        belowDescription: {
            title: 'Concert Soundopamine',
            subtitle: 'Au dansat pe beat-uri tropicale si jazz mixate de Soundopamine. '
        },
        image: image4,
        titleDark: true
    }
];

export const SliderExperience3: { id: number; image: string }[] = [
    {
        id: 1,
        image: img31
    },
    {
        id: 2,
        image: img33
    },
    {
        id: 3,
        image: img34
    }
];
