import store from 'store';

// Tracking
import TagManager from 'react-gtm-module';
import { config } from './config';

const appMedium = store.get(config.user.isPWA) ? 'progressiveWebApp' : 'browserWebApp';

const pageViewDataLayer = () => {
    const isLoggedIn = store.get(config.user.authToken);
    const user = store.get(config.user.user);
    const appVersion = store.get(config.user.appVersion);

    return {
        appMedium,
        environment: window.location.hostname === 'www.iqos.ro' ? 'production' : 'staging',
        environmentVersion: appVersion || '',
        page: {
            pageInfo: {
                pageID: window.location.pathname,
                pageName: 'IQOS Club',
                pageBrand: 'p1'
            },
            category: {
                primaryCategory: 'IQOS Club',
                pageType: ''
            },
            attributes: {
                country: 'RO',
                language: 'ro-RO',
                currency: 'RON'
            },
            user: {
                affiliation: isLoggedIn ? 'Registered' : 'Anonymous',
                ...(user?.acs_id && { dcsID: user.acs_id }),
                ...(user?.dcs_id && { ecomID: user.dcs_id })
            }
        }
    };
};

const getDataLayer = (eventCategory: string, eventAction: string, eventLabel: string) => ({
    dataLayer: {
        event: 'gtmZoneEvent',
        appMedium,
        eventCategory,
        eventAction,
        eventLabel
    }
});

const getLegalAgeDataLayer = (selectedLegalAgeBoolean: 'Yes' | 'No') => ({
    dataLayer: {
        event: 'gatewayLegalAge',
        selectedLegalAgeBoolean
    }
});

const getLegalAgeDisplay = () => ({
    dataLayer: {
        event: 'gatewayLegalAgeDisplay'
    }
});

const TagEvents = {
    init: { gtmId: config.storage.gtmId },

    // Pageview
    pageView: { dataLayer: pageViewDataLayer() },

    // AgeGate
    gatewayLegalAge: (selectedLegalAgeBoolean: 'Yes' | 'No') =>
        getLegalAgeDataLayer(selectedLegalAgeBoolean),
    getLegalAgeDisplay: () => getLegalAgeDisplay(),

    // Homepage
    accessAccountMain: getDataLayer('account_login', 'click_intra_in_cont_main', ''),
    accessAccountTopnav: getDataLayer('account_login', 'click_intra_in_cont_nav', ''),
    createAccount: getDataLayer('account_login', 'click_create_acocunt', ''),
    loginSuccess: getDataLayer('account_login', 'login_succes', 'login_succes'),
    loginError: getDataLayer('account_login', 'login_error', 'login_error'),

    // General
    mainNavHome: (to: string) =>
        getDataLayer(
            'overall_website',
            'click_main_nav_home',
            `internal_link_click | from ${window.location.pathname} to: ${process.env.REACT_APP_BASENAME}${to}`
        ),
    mainNavIQOSUniverse: (to: string) =>
        getDataLayer(
            'overall_website',
            'click_main_nav_universul_iqos',
            `internal_link_click | from ${window.location.pathname} to: ${process.env.REACT_APP_BASENAME}${to}`
        ),
    mainNavHowItWorks: (to: string) =>
        getDataLayer(
            'overall_website',
            'click_main_nav_cum_functioneaza',
            `internal_link_click | from ${window.location.pathname} to: ${process.env.REACT_APP_BASENAME}${to}`
        ),
    mainNavContests: (to: string) =>
        getDataLayer(
            'overall_website',
            'click_main_nav_concursuri',
            `internal_link_click | from ${window.location.pathname} to: ${process.env.REACT_APP_BASENAME}${to}`
        ),
    mainNavComunnity: (to: string) =>
        getDataLayer(
            'overall_website',
            'click_main_nav_comunitatea_iqos',
            `internal_link_click | from ${window.location.pathname} to: ${process.env.REACT_APP_BASENAME}${to}`
        ),
    mainNavMyBadges: (to: string) =>
        getDataLayer(
            'overall_website',
            'click_main_nav_badges',
            `internal_link_click | from ${window.location.pathname} to: ${process.env.REACT_APP_BASENAME}${to}`
        ),
    mainNavBenefits: (to: string) =>
        getDataLayer(
            'overall_website',
            'click_main_nav_benefits',
            `internal_link_click | from ${window.location.pathname} to: ${process.env.REACT_APP_BASENAME}${to}`
        ),
    mainNavMyProfile: (to: string) =>
        getDataLayer(
            'overall_website',
            'click_main_nav_profile',
            `internal_link_click | from ${window.location.pathname} to: ${process.env.REACT_APP_BASENAME}${to}`
        ),
    mainNavRecommend: (path: string) =>
        getDataLayer('overall_website', 'click_main_nav_recommend', path),
    mainNavNotifications: (path: string) =>
        getDataLayer('overall_website', 'click_main_nav_notifications', path),

    // IQOS Universe
    allArticles: (to: string) =>
        getDataLayer(
            'iqos_universe',
            'click_all_articles',
            `internal_link_click | from ${window.location.pathname} to: ${process.env.REACT_APP_BASENAME}${to}`
        ),
    universeSlider: getDataLayer('iqos_universe', 'click_slider_recommend', ''),
    clickRecommendation: (title: string) =>
        getDataLayer('iqos_universe', 'click_recommendation', title),

    // Notifications
    expandNotification: (title: string) =>
        getDataLayer('notifications', 'extend_notification', title),
    ctaNotification: (title: string) => getDataLayer('notifications', 'click_notification', title),
    pinNotification: (title: string) => getDataLayer('notifications', 'pin_notification', title),

    // How it works
    tabActivities: (to: string) =>
        getDataLayer(
            'how_it_works',
            'click_tab_activities',
            `internal_link_click | from ${window.location.pathname} to: ${process.env.REACT_APP_BASENAME}${to}`
        ),
    tabOther: (to: string) =>
        getDataLayer(
            'how_it_works',
            'click_tab_other_activities',
            `internal_link_click | from ${window.location.pathname} to: ${process.env.REACT_APP_BASENAME}${to}`
        ),
    readMoreBadges: (to: string) =>
        getDataLayer(
            'how_it_works',
            'click_read_more_about_badges',
            `internal_link_click | from ${window.location.pathname} to: ${process.env.REACT_APP_BASENAME}${to}`
        ),

    // My Profile
    tabPersonalization: getDataLayer('my_profile', 'click_tab_customize', ''),
    howItWorks: getDataLayer('my_profile', 'click_how_it_works', ''),
    myBenefits: getDataLayer('my_profile', 'click_my_benefits', ''),
    allRewards: getDataLayer('my_profile', 'click_all_rewards', ''),
    redeemVoucher: (voucherName: string) =>
        getDataLayer('my_profile', 'claim_voucher', voucherName),
    aboutBadges: getDataLayer('my_profile', 'click_about_badges', ''),
    benefitsTab: getDataLayer('my_profile', 'click_expand_benefits', ''),

    // Homepage logged
    slider1Click: (name: string, position: string) =>
        getDataLayer('homepage_logged_in', 'slider1_click_banner', `${name}_${position}`),
    slider1Impression: (name: string, position: string) =>
        getDataLayer('homepage_logged_in', 'slider1_impression_banner', `${name}_${position}`),
    homepageRecommend: (to: string) =>
        getDataLayer(
            'homepage_logged_in',
            'click_recommend',
            `internal_link_click | from ${window.location.pathname} to: ${process.env.REACT_APP_BASENAME}${to}`
        ),
    slider2Nav: getDataLayer('homepage_logged_in', 'slider2_click_nav', ''),
    slider2Click: (name: string, position: string) =>
        getDataLayer('homepage_logged_in', 'slider2_click_banner', `${name}_${position}`),
    slider2Impression: (name: string, position: string) =>
        getDataLayer('homepage_logged_in', 'slider2_impression_banner', `${name}_${position}`),
    insertCode: (to: string) =>
        getDataLayer(
            'homepage_logged_in',
            'click_insert_code',
            `internal_link_click | from ${window.location.pathname} to: ${process.env.REACT_APP_BASENAME}${to}`
        ),
    sliderBenefitsNav: getDataLayer('homepage_logged_in', 'slider_benefits_click_nav', ''),
    sliderBenefitsClick: (name: string, position: string) =>
        getDataLayer('homepage_logged_in', 'slider_benefits_click_banner', `${name}_${position}`),
    sliderBenefitsImpression: (name: string, position: string) =>
        getDataLayer(
            'homepage_logged_in',
            'slider_benefits_impression_banner',
            `${name}_${position}`
        ),
    sliderContestsNav: getDataLayer('homepage_logged_in', 'slider_concursuri_click_nav', ''),
    sliderContestsClick: (name: string, position: string) =>
        getDataLayer('homepage_logged_in', 'slider_concursuri_click_banner', `${name}_${position}`),
    sliderContestsImpression: (name: string, position: string) =>
        getDataLayer(
            'homepage_logged_in',
            'slider_concursuri_impression_banner',
            `${name}_${position}`
        ),
    homepageAllArticles: (to: string) =>
        getDataLayer(
            'homepage_logged_in',
            'click_all_articles',
            `internal_link_click | from ${window.location.pathname} to: ${process.env.REACT_APP_BASENAME}${to}`
        ),
    homepageClickArticle: (title: string) =>
        getDataLayer('homepage_logged_in', 'click_articole', title),

    // Recommend
    clickTabRecommend: getDataLayer('reffer_a_friend', 'click_tab_recommend', ''),
    clickTabMyInvitations: getDataLayer('reffer_a_friend', 'click_tab_my_invitations', ''),
    clickTabBenefits: getDataLayer('reffer_a_friend', 'tab_benefits_click_recommend', ''),
    clickTabBenefits2: getDataLayer('reffer_a_friend', 'tab_benefits_click_recommend_2', ''),
    recommendStartForm: getDataLayer('reffer_a_friend', 'recommend_start_form', ''),
    recommendSuccesForm: getDataLayer('reffer_a_friend', 'recommend_form_succes', ''),

    // Contests
    contestParticipate: (title: string) =>
        getDataLayer('contests', `click_participate_${title}`, ''),
    contestPlayNow: (title: string) => getDataLayer('contests', `click_play_now_${title}`, '')
};

export { TagManager, TagEvents };
