import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axiosRequest from '@utils/axios';

export interface HeroBannerState {
    status: 'idle' | 'loading' | 'failed';
    slides: [];
    error: any;
}

interface AxiosError {
    response: {
        data: {
            message: string;
        };
    };
}

const initialState: HeroBannerState = {
    status: 'idle',
    slides: [],
    error: {}
};

export const getHeroBanners = createAsyncThunk('homepage/heroBanners', async () => {
    try {
        const response = await axiosRequest.get('/top-hero-banners').then((res) => {
            return res;
        });

        return response.data.data;
    } catch (e) {
        return (e as AxiosError).response.data.message;
    }
});

export const heroBannerSlice = createSlice({
    name: 'heroBanner',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getHeroBanners.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getHeroBanners.fulfilled, (state, action) => {
                state.status = 'idle';
                state.slides = action.payload;
            })
            .addCase(getHeroBanners.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    }
});

export default heroBannerSlice.reducer;
