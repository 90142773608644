import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axiosRequest from '@utils/axios';

export interface EntryState {
    status: 'idle' | 'loading' | 'failed';
    entry: {
        id: number;
        challenge_id: number;
        user_id: number;
        option: string[];
        getstream_id: string;
        content: {
            title: string;
            description: string;
            tags: any[];
        };
        published_at: string | null;
        image: string;
        video: string;
        user: {
            dcs_id: number;
            fullname: string;
            first_name: string;
            last_name: string;
            avatars: {
                full: string;
                thumbnail: string;
            };
        };
        likes_count: number | null;
        liked: boolean;
    };
    error: any;
}

interface AxiosError {
    response: {
        data: {
            message: string;
        };
    };
}

const initialState: EntryState = {
    status: 'loading',
    entry: {
        id: 0,
        challenge_id: 0,
        user_id: 0,
        option: [],
        getstream_id: '',
        content: {
            title: '',
            description: '',
            tags: []
        },
        published_at: null,
        image: '',
        video: '',
        user: {
            dcs_id: 0,
            fullname: '',
            first_name: '',
            last_name: '',
            avatars: {
                full: '',
                thumbnail: ''
            }
        },
        likes_count: null,
        liked: false
    },
    error: {}
};

export const getEntry = createAsyncThunk(
    'monthlyChallenge/getEntry',
    async (values: { challenge_id: number; id: number }, { rejectWithValue }) => {
        try {
            const response = await axiosRequest
                .get(`/challenges/${values.challenge_id}/registrations/${values.id}`)
                .then((res) => {
                    return res;
                });

            return response.data.data;
        } catch (e) {
            return rejectWithValue((e as AxiosError).response.data.message);
        }
    }
);

export const entrySlice = createSlice({
    name: 'entry',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getEntry.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getEntry.fulfilled, (state, action) => {
                state.status = 'idle';
                state.entry = action.payload;
            })
            .addCase(getEntry.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(resetState, () => initialState);
    }
});

export const resetState = createAction('REVERT_ALL');

export default entrySlice.reducer;
