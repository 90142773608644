export const SelectArrow = () => {
    return (
        <svg
            width="14"
            height="12"
            viewBox="0 0 14 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                opacity="0.7"
                d="M1.42859 3.30005L6.70002 8.57148C6.73849 8.61247 6.78497 8.64515 6.83656 8.66749C6.88816 8.68983 6.94379 8.70135 7.00002 8.70135C7.05624 8.70135 7.11187 8.68983 7.16347 8.66749C7.21507 8.64515 7.26154 8.61247 7.30002 8.57148L12.5714 3.30005"
                stroke="#34303D"
                strokeWidth="1.7"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
