import React, { useState, useEffect } from 'react';
import store from 'store';

import styles from './LeadfamlyGamePage.module.scss';

import BaseLoader from '@components/Layout/BaseLoader';
import { config } from '@utils/config';
import { useAppSelector } from '@app/hooks';
import setHeaderTitle from '@utils/setHeaderTitle';

const LeadfamlyGamePage: React.FC = () => {
  const { article } = useAppSelector((state) => state.iqosUniverse);

  const [isLoading, setLoading] = useState(true);
  const [dcsId, setDcsId] = useState(null);

  useEffect(() => {
      const script = document.createElement('script');
      script.src =
          'https://files.cdn.leadfamly.com/campaign/sdk/utilities.responsive_iframe.min.js';
      script.async = true;
      document.body.appendChild(script);

      setDcsId(store.get(config.user.user).dcs_id);

      setHeaderTitle('IQOS Quiz');
  }, []);

  return (
    <section className={styles.wrapper}>
      {isLoading && <BaseLoader size="fullscreen" />}
      {dcsId && (
          <iframe
              title="LeadfamlyQuizPage"
              id="LeadfamlyQuizPage"
              onLoad={() => setLoading(false)}
              src={`https://philip-morris-romania-b2c.leadfamly.com/${article.cta_link}?iframe=dontbreakout&dcs_id=${dcsId}&article_id=${article.id}`}
              scrolling="no"
              allow="autoplay; fullscreen"
              sandbox="allow-popups allow-same-origin allow-popups-to-escape-sandbox allow-scripts allow-top-navigation"
          />
      )}
    </section>
  );
};

export default LeadfamlyGamePage;
