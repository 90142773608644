export interface FirstUse {
    id: number;
    description: string;
}

export const FirstUseList: FirstUse[] = [
    {
        id: 1,
        description: `Glisează capacul interior și introdu o rezervă de tutun Fiit, cu partea de tutun în jos.`
    },
    {
        id: 2,
        description: `Apasă butonul timp de două secunde, până simți o vibrație. Dispozitivul va începe preîncălzirea, care durează până la 25 de secunde.`
    },
    {
        id: 3,
        description: `Când LED-ul se aprinde și dispozitivul vibrează, acesta poate fi utilizat. Fiecare experiență durează până la 5 minute sau 14 inhalări, oricare are loc prima.`
    },
    {
        id:4,
        description:`Dispozitivul vibrează și LED-ul clipește pentru a semnaliza ultimele 3 inhalări sau ultimele 30 de secunde ale experienței, oricare are loc prima.`
    },
    {
        id:5,
        description:`La final, răsucește rezerva de minim 3 ori în aceeași direcție. Apoi scoate-o și închide capacul.`
    }
];
