import { useEffect, useState } from 'react';
import styles from './WinnersSection.module.scss';

// Assets
import accessories from '@containers/MyRewards/assets/realConnectionAccessories.gif';
import voucher from '@containers/MyRewards/assets/realConnectionVoucher.gif';
import vip from '../../assets/weeklyPrizes/prize1.gif';
import iluma from '../../assets/weeklyPrizes/prize2.gif';
import bigPrize from '../../assets/big-prize.gif';

// Components
import KeenSlider from '@components/KeenSlider';
import BaseLoader from '@components/Layout/BaseLoader';
import { Select } from 'antd';

// Utils
import { config } from '@utils/config';
import classNames from 'classnames';
import dayjs from '@utils/dayjs';
import axios from '@utils/axios';
import qs from 'qs';

const winnersOptions = [
    {
        id: 1,
        value: 1,
        label: 'Experiență unică IQOS în Mykonos'
    },
    {
        id: 2,
        value: 2,
        label: 'Experiență VIP la concertul anului'
    },
    {
        id: 3,
        value: 3,
        label: 'Dispozitive IQOS ILUMA PRIME'
    },
    {
        id: 4,
        value: 4,
        label: 'Accesorii IQOS'
    },
    {
        id: 5,
        value: 5,
        label: 'Vouchere Next Level Pass'
    }
];

const WinnersSection = () => {
    const [winnerListType, setWinnerListType] = useState<number | null>(null);
    const [loading, setLoading] = useState(false);

    const [instantWinners, setInstantWinners] = useState([]);
    const [weeklyWinners, setWeeklyWinners] = useState([]);
    const [finalWinners, setFinalWinners] = useState([]);

    const instantSlug = 'real-connection-premiu-instant';
    const weeklySlug = 'real-connection-premiu-saptamanal';
    const finalSlug = 'real-connection-premiu-final';

    const [displayedWinners, setDisplayedWinners] = useState([]);

    const getFilters = (slug?: string) => {
        return qs.stringify(
            {
                filter: {
                    redemption_campaign_slugs: [slug]
                },
                per_page: 50
            },
            { arrayFormat: 'brackets', encodeValuesOnly: true }
        );
    };

    const getWinners = () => {
        setLoading(true);

        Promise.all([
            axios.get(`/redemption-campaign-winners?${getFilters(instantSlug)}`),
            axios.get(`/redemption-campaign-winners?${getFilters(weeklySlug)}`),
            axios.get(`/redemption-campaign-winners?${getFilters(finalSlug)}`)
        ])
            .then(([instantWinners, weeklyWinners, finalWinners]) => {
                setInstantWinners(instantWinners.data.data);
                setWeeklyWinners(weeklyWinners.data.data);
                setFinalWinners(finalWinners.data.data);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        getWinners();
    }, []);

    useEffect(() => {
        switch (winnerListType) {
            case 1:
                setDisplayedWinners(finalWinners);
                break;
            case 2:
                setDisplayedWinners(
                    weeklyWinners.filter((winner: any) => winner.gift.name.includes('VIP'))
                );
                break;
            case 3:
                setDisplayedWinners(
                    weeklyWinners.filter((winner: any) =>
                        winner.gift.name.includes('IQOS ILUMA PRIME')
                    )
                );
                break;
            case 4:
                setDisplayedWinners(
                    instantWinners.filter((winner: any) => !winner.gift.name.includes('VOUCHER'))
                );
                break;
            case 5:
                setDisplayedWinners(
                    instantWinners.filter((winner: any) => winner.gift.name.includes('VOUCHER'))
                );
                break;
            default:
                setDisplayedWinners([...finalWinners, ...instantWinners, ...weeklyWinners]);
        }
    }, [instantWinners, weeklyWinners, winnerListType]);

    return (
        <div id={'winners-real-people'} className={styles.winnersSection}>
            <div className={styles.details}>
                <h3>Câștigătorii concursului</h3>
                <p>
                    Câștigătorii tuturor premiilor din campania „Oameni reali. Legături reale.” au
                    fost extrași și anunțați printr-o notificare în platformă sau validați
                    telefonic.
                    <br />
                    Poți consulta regulamentul campaniei accesând link-ul de mai jos.
                    <br />
                    <br />
                    <a href={`.${config.path.regulation.index}`}>Vezi regulamentul</a>
                </p>
            </div>

            <Select
                allowClear
                defaultActiveFirstOption={false}
                virtual={false}
                placeholder="Selectează"
                popupClassName={styles.popupSelect}
                onChange={(value) => setWinnerListType(value)}
                value={winnerListType}
                options={winnersOptions}
            />

            <section className={styles.winners}>
                {loading ? (
                    <div className={styles.noWinners}>
                        <BaseLoader size="small" />
                    </div>
                ) : displayedWinners.length < 5 ? (
                    <div className={styles.noWinners}>
                        <p>Momentan nu există câștigători.</p>
                    </div>
                ) : (
                    <KeenSlider
                        reset
                        infinite
                        withArrows={false}
                        autoSwitch={true}
                        className={styles.sliderWrapper}
                        spacing={8}
                    >
                        {displayedWinners.map((winner: any, index) => (
                            <WinnerItem
                                key={`${index}-${winner.created_at}-${winner.first_name}`}
                                winner={winner}
                            />
                        ))}
                    </KeenSlider>
                )}
            </section>

            {/* <div className={styles.allWinners}>
                    <Button href={config.path.listWinners} type="solid" color="dark">
                        Vezi lista completă a câștigătorilor
                    </Button>
                </div> */}
        </div>
    );
};

const WinnerItem = ({ className, winner }: any) => {
    const stage = winner?.data?.stage;
    const { name, image } = ((giftName: string) => {
        switch (true) {
            case giftName.includes('VIP'):
                return { name: 'o experiență VIP la concertul anului', image: vip };
            case giftName.includes('IQOS ILUMA PRIME'):
                return { name: 'un dispozitiv IQOS ILUMA PRIME', image: iluma };
            case giftName.includes('VOUCHER'):
                return { name: 'un voucher Next Level Pass', image: voucher };
            case giftName.includes('Mykonos'):
                return { name: 'o experiență unică in Mykonos', image: bigPrize };
            default:
                return { name: 'un accesoriu IQOS', image: accessories };
        }
    })(winner.gift.name);

    return (
        <div key={winner.id} className={classNames(styles.item, className)}>
            <div className={styles.thumbnail}>
                <img src={image} alt="" />
            </div>

            <div className={styles.details}>
                <h4>{`${winner.first_name}${' '}${winner.last_name}`}</h4>
                <p className={styles.prizeDetails}>
                    A câștigat {name}
                    {stage ? ` în etapa ${stage}.` : '.'}
                </p>

                <p>{dayjs(winner.created_at).format('DD MMM YYYY')}</p>
            </div>
        </div>
    );
};

export default WinnersSection;
