export const SeenArticleIcon = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="24.0003" height="24.0009" rx="12.0002" fill="#00D1D2" />
            <path
                d="M5.03729 11.2905C5.27635 11.2806 5.46892 11.4107 5.65137 11.572C6.85376 12.6337 8.05867 13.6929 9.26156 14.7545C9.41824 14.8931 9.58199 15.0133 9.80084 15.0345C10.1 15.0637 10.3386 14.9569 10.5382 14.7397C11.82 13.3461 13.1037 11.9539 14.387 10.5618C15.51 9.34333 16.632 8.12484 17.7561 6.90783C18.1013 6.53383 18.5941 6.49821 18.9443 6.81483C19.2839 7.12155 19.2865 7.60242 18.9473 7.97098C16.1554 11.0046 13.363 14.0382 10.5701 17.0718C10.1855 17.4893 9.7139 17.5106 9.29946 17.1292C7.70235 15.6579 6.10675 14.1851 4.50863 12.7153C4.27664 12.5016 4.15534 12.2562 4.21549 11.9401C4.28877 11.5562 4.61578 11.2905 5.03679 11.291L5.03729 11.2905Z"
                fill="#FFFDFB"
            />
        </svg>
    );
};
