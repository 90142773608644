import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosRequest from '@utils/axios';
import { map, omit } from 'lodash';

export interface IStory {
    id: number;
    approved?: 0 | 1;
    pinned?: 0 | 1;
    start_date?: string;
    end_date?: string;
    type: 'image' | 'video';
    image: string;
    video: string;
    profile_image?: string;
    views: {
        views: number;
        has_viewed: 0 | 1;
    };
    interactions: {
        CLAP: {
            type: 'CLAP';
            count: number;
            has_reacted: 0 | 1;
        };
        LIKE: {
            type: 'LIKE';
            count: number;
            has_reacted: 0 | 1;
        };
        LOVE: {
            type: 'LOVE';
            count: number;
            has_reacted: 0 | 1;
        };
    } | null;
    properties: {
        author_name: string;
        muted: 0 | 1 | '0' | '1';
        text?: string;
        text_color?: string;
        text_x?: number;
        text_y?: number;
        text_rotation?: number;
        text_font_size?: number;
        width?: number;
        height?: number;
        link?: string;
    };
}

export interface InitialState {
    status: 'idle' | 'loading' | 'failed';
    error: any;
    activeStoryIndex: number | undefined;
    stories: IStory[];
}

interface AxiosError {
    response: {
        data: {
            message: string;
        };
    };
}

const initialState: InitialState = {
    status: 'idle',
    error: {},
    activeStoryIndex: undefined,
    stories: []
};

export const getStories = createAsyncThunk('stories/getStories', async (preview: boolean) => {
    try {
        const response = await axiosRequest.get('/stories', {
            params: preview ? { type: 'PREVIEW' } : {}
        });

        if (preview) {
            return map(response.data.stories, (story) => omit(story, 'video'));
        }

        return response.data.stories;
    } catch (e) {
        return (e as AxiosError).response.data.message;
    }
});

export const setStory = createAsyncThunk('stories/setStory', async (values: object) => {
    try {
        const response = await axiosRequest.post('/stories', values, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        window.location.reload();
        return response.data;
    } catch (e) {
        return (e as AxiosError).response.data.message;
    }
});

export const editStory = createAsyncThunk(
    'stories/setStory',
    async (data: { values: object; id: number }) => {
        const { values, id } = data;
        try {
            const response = await axiosRequest.put(`/stories/${id}`, values);
            return response.data;
        } catch (e) {
            return (e as AxiosError).response.data.message;
        }
    }
);

export const markAsSeen = createAsyncThunk('stories/markAsSeen', async (id: number) => {
    try {
        const response = await axiosRequest.put(`/stories/${id}/mark-as-seen`);
        return response.data;
    } catch (e) {
        return (e as AxiosError).response.data.message;
    }
});

export const reactStory = createAsyncThunk(
    'stories/reactStory',
    async ({ id, type }: { id: number; type: string }) => {
        try {
            const response = await axiosRequest.put(`/stories/${id}/react`, { type });
            return response.data;
        } catch (e) {
            return (e as AxiosError).response.data.message;
        }
    }
);

export const storiesSlice = createSlice({
    name: 'stories',
    initialState,
    reducers: {
        setActiveStoryIndex: (state, action) => {
            state.activeStoryIndex = action.payload;
        },
        setReaction: (state, action) => {
            const {
                storyIndex,
                type,
                hasReacted
            }: {
                storyIndex: number;
                type: 'LOVE' | 'LIKE' | 'CLAP';
                hasReacted: boolean;
            } = action.payload;

            if (hasReacted) {
                state.stories[storyIndex].interactions![type].count--;
                state.stories[storyIndex].interactions![type].has_reacted = 0;
            } else {
                state.stories[storyIndex].interactions![type].count++;
                state.stories[storyIndex].interactions![type].has_reacted = 1;
            }
        },
        setStorySeen: (state, action) => {
            const { storyIndex } = action.payload;

            state.stories[storyIndex].views.has_viewed = 1;
        },
        rearrangeAllStories: (state) => {
            const pinnedStories: IStory[] = [];
            const unseenStories: IStory[] = [];
            const seenStories: IStory[] = [];

            state.stories.forEach((story) => {
                if (story.pinned) {
                    pinnedStories.push(story);
                } else if (story.views.has_viewed === 0) {
                    unseenStories.push(story);
                } else {
                    seenStories.push(story);
                }
            });

            state.stories = [...pinnedStories, ...unseenStories, ...seenStories];
        },
        clearStories: (state) => {
            state.stories.forEach((story) => {
                story.interactions = null;
                story.video = '';
            });
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getStories.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getStories.fulfilled, (state, action) => {
                state.status = 'idle';
                state.stories = action.payload;
            })
            .addCase(getStories.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })

            .addCase(setStory.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(setStory.fulfilled, (state, action) => {
                state.status = 'idle';
                state.stories = action.payload.stories;
            })
            .addCase(setStory.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    }
});

export const { setActiveStoryIndex, setReaction, setStorySeen, rearrangeAllStories, clearStories } =
    storiesSlice.actions;

export default storiesSlice.reducer;
