import DSC08550 from './assets/gallery/DSC08550.jpg';
import DSC08540 from './assets/gallery/DSC08540.jpg';
import DSC08517 from './assets/gallery/DSC08517.jpg';
import DSC09494 from './assets/gallery/DSC09494.jpg';
import DSC09471 from './assets/gallery/DSC09471.jpg';
import DSC09439 from './assets/gallery/DSC09439.jpg';
import DSC09426 from './assets/gallery/DSC09426.jpg';
import DSC09405 from './assets/gallery/DSC09405.jpg';
import DSC09394 from './assets/gallery/DSC09394.jpg';
import DSC09280 from './assets/gallery/DSC09280.jpg';
import DSC08905 from './assets/gallery/DSC08905.jpg';
import DSC08902 from './assets/gallery/DSC08902.jpg';
import DSC08751 from './assets/gallery/DSC08751.jpg';
import DSC08724 from './assets/gallery/DSC08724.jpg';
import DSC08675 from './assets/gallery/DSC08675.jpg';
import DSC08673 from './assets/gallery/DSC08673.jpg';
import DSC08651 from './assets/gallery/DSC08651.jpg';
import DSC08644 from './assets/gallery/DSC08644.jpg';
import DSC08636 from './assets/gallery/DSC08636.jpg';

export default [
    DSC08550,
    DSC08540,
    DSC08517,
    DSC09494,
    DSC09471,
    DSC09439,
    DSC09426,
    DSC09405,
    DSC09394,
    DSC09280,
    DSC08905,
    DSC08902,
    DSC08751,
    DSC08724,
    DSC08675,
    DSC08673,
    DSC08651,
    DSC08644,
    DSC08636
];
