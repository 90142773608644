import styles from './RDW2021.module.scss';

// Assets
import img01 from './assets/img1.png';
import img02 from './assets/img2.png';
import img03 from './assets/img3.png';
import img04 from './assets/img4.png';

// Components
import VideoWrapper from '@containers/IQOSUniverse/components/VideoWrapper';
import QuoteWrapper from '@containers/IQOSUniverse/components/QuoteWrapper';
import ArticleHeader from '@containers/IQOSUniverse/components/ArticleHeader';
import MediaGallery from "@containers/IQOSUniverse/components/MediaGallery";

const RDW2021 = () => {
    return (
        <section className={styles.wrapper}>
            <ArticleHeader />

            <div className="content">
                <h3>Progres prin inovație</h3>

                <p>
                    Evoluția este în natura noastră. Este ceea ce ne reprezintă. Este dorința de a
                    concepe alternative mai bune decât țigările, cum ar fi dispozitivul
                    heat-not-burn IQOS, care nu produce nici fum, nici scrum.
                </p>

                <p>
                    Ce se întâmplă atunci când îi sprijinim pe cei care apreciază, de asemenea,
                    progresul și lucrează pentru a îmbunătăți mediul?
                </p>
            </div>

            <VideoWrapper
                video="788659492"
                title="Urmărește Crearea Muralului Art for Air București"
            />

            <div className="content">
                <h3>Progres prin inovație</h3>

                <p>
                    Evoluția este în natura noastră. Este ceea ce ne reprezintă. Este dorința de a
                    concepe alternative mai bune decât țigările, cum ar fi dispozitivul
                    heat-not-burn IQOS, care nu produce nici fum, nici scrum.
                </p>

                <p>
                    Ce se întâmplă atunci când îi sprijinim pe cei care apreciază, de asemenea,
                    progresul și lucrează pentru a îmbunătăți mediul?
                </p>
            </div>

            <QuoteWrapper
                image="https://picsum.photos/720/600"
                quote="Scelerisque ac luctus tempor morbi. Purus est nunc non viverra libero id ornare facilisis. Pretium sed bibendum ac commodo nibh."
            />

            <MediaGallery images={[img01, img02, img03, img04]} />
        </section>
    );
};

export default RDW2021;
