import { useState } from 'react';

//Styles
import styles from './RomanianDesignWeek2021.module.scss';

//Components
import ArticleHeader from '@containers/IQOSUniverse/components/ArticleHeader';
import ArticleImage from '@containers/IQOSUniverse/components/ArticleImage';
import ArticleContainer from '@containers/IQOSUniverse/components/ArticleContainer';
import VideoModal from '@components/VideoModal';

//Assets
import image1 from './assets/image1.png';
import image2 from './assets/image2.png';
import videoThumbnail from './assets/videoThumbnail.png';

//Helpers
import classNames from 'classnames';

const RomanianDesignWeek2021 = () => {
    const [toggleModal, setToggleModal] = useState(false);

    return (
        <section className={styles.wrapper}>
            <ArticleHeader />

            <div className="content">
                <h3>Cel mai mare festival creativ din România</h3>

                <p>
                    Ediția a noua a Romanian Design Week, care promovează designul, arhitectura și
                    creativitatea, a avut tema #together și s-a desfășurat în perioada 4-13 iunie la
                    Combinatul Fondului Plastic și în spațiile partenere.
                </p>

                <p>
                    Romanian Design Week și IQOS au dezvoltat, în cadrul programului festivalului, o
                    serie de inițiative, expoziții, instalații și evenimente care au adus în prim
                    plan importanța colaborării și a unei viziuni orientate spre viitor, bazată pe
                    optimism și creativitate.
                </p>
            </div>

            <ArticleImage cover={true} mobileImage={image1} image={image1} />

            <ArticleContainer background={'#00D1D2'}>
                <div className="content">
                    <h3>
                        Atracția principală a ediției de anul acesta a RDW a fost instalația
                        imersivă IQOS World – A Network of Emotions.
                    </h3>
                    {/*Button goes to empty page*/}

                    {/*<Button*/}
                    {/*    href={config.path.iqos.iqosWorldSecondary}*/}
                    {/*    label="IQOS World Network of Emotions"*/}
                    {/*    type="solid"*/}
                    {/*    color="dark"*/}
                    {/*    hover="dark"*/}
                    {/*/>*/}
                </div>
            </ArticleContainer>

            <div className={styles.space}>
                <ArticleImage cover={true} mobileImage={image2} image={image2} />
            </div>

            <div className="content">
                <h3>Art for Air, realizat în colaborare cu Obie Platon</h3>
                <p>
                    Un alt proiect dezvoltat de IQOS în cadrul Romanian Design Week 2021, este
                    muralul Art for Air, realizat în colaborare cu Obie Platon, unul dintre cei mai
                    renumiți artiști stradali din România.
                </p>
                <p>
                    Dacă nu ai reușit să vezi până acum muralul Art for Air, te invităm să faci o
                    vizită pe strada Matei Millo nr. 9.
                </p>
                {/*Button goes to empty page*/}

                {/*<Button*/}
                {/*    href={config.path.iqosArtForAir}*/}
                {/*    label="IQOS World Art for Air"*/}
                {/*    type="solid"*/}
                {/*    color="dark"*/}
                {/*    hover="dark"*/}
                {/*/>*/}
            </div>

            <ArticleContainer background={'#00D1D2'}>
                <div className={classNames(styles.videoRomDesignWeek, 'content')}>
                    <ArticleImage
                        isVideo
                        mobileImage={videoThumbnail}
                        image={videoThumbnail}
                        onClick={() => setToggleModal(true)}
                    />

                    <VideoModal
                        toggleModal={toggleModal}
                        setToggleModal={() => setToggleModal(false)}
                        video="584844497"
                    />

                    <p className={styles.topParagraph}>
                        Obie Platon a adus pasiunea pentru culoare și la RDW Design House, în
                        Bulevardul Aviatorilor nr. 8A, unul dintre punctele de intâlnire importante
                        pentru comunitatea creativă din București.
                    </p>

                    <p>
                        Și aici, artistul și-a pus amprenta asupra locului prin realizarea unui
                        mural în fața clădirii Qreator by IQOS, care a găzduit mai multe expoziții
                        internaționale în cadrul Romanian Design Week 2021.
                    </p>
                </div>
            </ArticleContainer>
        </section>
    );
};

export default RomanianDesignWeek2021;
