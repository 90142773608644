// Utils
import { FC, ReactNode } from 'react';
import { config } from '@utils/config';
import store from 'store';
import { Navigate } from 'react-router-dom';

// Interface
interface IPublicRoute {
    redirectPath?: string;
    children: ReactNode;
}

const PublicRoute: FC<IPublicRoute> = ({
    redirectPath = config.path.index,
    children
}: IPublicRoute) => {
    const token = store.get(config.user.authToken);

    if (token) {
        return <Navigate to={redirectPath} replace />;
    }

    return <>{children}</>;
};

export default PublicRoute;
