import { useEffect, useState } from 'react';
import useSound from 'use-sound';

import styles from './NewLevia.module.scss';

// Components
import KeenSlider from '@components/KeenSlider';
import ArticleHeader from '@containers/IQOSUniverse/components/ArticleHeader';

// Components
import Button from '@components/Layout/Button';

// Assets
import musicalNote from '@containers/IQOSUniverse/articles/NewLevia/assets/musicalNote.svg';

// Sound
import electro from './sounds/electro.mp3';
import island from './sounds/island.mp3';

// Data
import { slides } from './data';

// Helpers
import { useWindowSize } from '@utils/hooks/windowSize';
import axios from '@utils/axios';
import { config } from '@utils/config';
import store from 'store';

const NewLevia = () => {
    const windowSize = useWindowSize();
    const isMobile = windowSize.width < 850;

    const slug = 'levia-prelansare';
    const isPlatinumTier = store.get(config.user.user)?.tier === 'Platinum';

    const [userEligibleToReedemedPack, setUserEligibleToReedemedPack] = useState(false);

    useEffect(() => {
        if (isPlatinumTier) {
            checkEligibility();
        } else return;
    }, []);

    const checkEligibility = () => {
        axios
            .get(`/user/gifts-redemption/${slug}/reward-eligibility`)
            .then(() => {
                // User is eligible
                setUserEligibleToReedemedPack(true);
            })
            .catch(() => {
                setUserEligibleToReedemedPack(false);
            });
    };

    // audio files
    const [isPlayingElectro, setIsPlayingElectro] = useState(false);
    const [isPlayingIsland, setIsPlayingIsland] = useState(false);

    const [playSoundElectro, electroSound] = useSound(electro, {
        onplay: () => setIsPlayingElectro(true),
        onend: () => setIsPlayingElectro(false),
        interrupt: true
    });

    const [playSoundIsland, islandSound] = useSound(island, {
        onplay: () => setIsPlayingIsland(true),
        onend: () => setIsPlayingIsland(false),
        interrupt: true
    });

    useEffect(() => {
        return () => {
            if (isPlayingElectro) electroSound.stop();
            if (isPlayingIsland) islandSound.stop();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPlayingElectro, isPlayingIsland]);

    const playPackSound = (sound: string) => {
        // Stop previous sound so it doesn't overlap
        if (sound === 'electro' && isPlayingIsland) islandSound.stop();
        setIsPlayingIsland(false);

        if (sound === 'island' && isPlayingElectro) electroSound.stop();
        setIsPlayingElectro(false);

        // Start new sound (stop if already playing)
        if (sound === 'electro') {
            if (isPlayingElectro) electroSound.stop();
            if (!isPlayingElectro) {
                playSoundElectro();
            }
        } else {
            if (isPlayingIsland) islandSound.stop();
            if (!isPlayingIsland) {
                playSoundIsland();
            }
        }
    };

    return (
        <section className={styles.wrapper}>
            <ArticleHeader />

            <div className="content">
                <p>
                    IQOS a revoluționat modul în care savurăm tutunul. De-a lungul anilor am evoluat
                    împreună și am oferit constant experiențe noi de gust, pentru a răspunde
                    preferințelor voastre. Dar nu ne-am oprit aici.
                </p>

                <p>
                    Inspirați de voi și datorită expertizei noastre în crearea de arome, am explorat
                    alte posibilități, dincolo de universul tutunului. Descoperirea noastră se
                    numește LEVIA. Un nou tip de rezervă, fără tutun, care conține nicotină și
                    deschide calea către un nou mod de a savura aromele intense.
                </p>

                <p>Explorează lumea aromelor care îți încântă simțurile!</p>
            </div>

            {isMobile ? (
                <div className={styles.leviaSlider}>
                    <div className={styles.sliderWrapper}>
                        <div className={styles.slider}>
                            <KeenSlider
                                withArrows={true}
                                mode="snap"
                                spacing={10}
                                withDots
                                dotsClass={styles.leviaSliderDots}
                            >
                                <div className={styles.slide}>
                                    <>
                                        <img
                                            src={slides[0].cardBackground}
                                            className={styles.cardBackgroundImage}
                                            alt={`sliderimg-1`}
                                        />

                                        <div className={styles.cardContent}>
                                            {isMobile ? (
                                                <h4 className={styles.invite}>
                                                    Te invităm să te bucuri de:
                                                </h4>
                                            ) : (
                                                <h5 className={styles.title}>{slides[0].title}</h5>
                                            )}

                                            <img
                                                src={slides[0].packImage}
                                                className={styles.pack}
                                                alt={''}
                                            />

                                            <img
                                                src={slides[0].flavourImage}
                                                className={styles.flavour}
                                                alt={''}
                                            />

                                            <p className={styles.aroma}>{slides[0].aroma}</p>

                                            <h5 className={styles.title}>{slides[0].title}</h5>

                                            <p className={styles.titleDescription}>
                                                {slides[0].titleDescription}
                                            </p>

                                            <p
                                                className={styles.contentTitle}
                                                dangerouslySetInnerHTML={{
                                                    __html: slides[0].contentTitle
                                                }}
                                            />

                                            <div className={styles.sliderButtons}>
                                                <Button
                                                    key={slides[0].sound}
                                                    label="Ascultă"
                                                    color="light"
                                                    type="solid"
                                                    hover="turquoise"
                                                    className={styles.listenSound}
                                                    onClick={() =>
                                                        playPackSound(`${slides[0].sound}`)
                                                    }
                                                >
                                                    <p>Ascultă</p>
                                                    <img src={musicalNote} alt="" />
                                                </Button>

                                                {userEligibleToReedemedPack && (
                                                    <Button
                                                        label="Revendică"
                                                        color="turquoise"
                                                        type="solid"
                                                        hover="light"
                                                        href={
                                                            config.path.campaigns
                                                                .prelaunchLeviaRedeem
                                                        }
                                                        className={styles.redeemPack}
                                                    />
                                                )}

                                                <a
                                                    href={
                                                        'https://www.iqos.ro/iluma-all/levia-iluma'
                                                    }
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className={styles.orderNow}
                                                >
                                                    Comandă acum
                                                </a>
                                            </div>

                                            <p className={styles.smallDisclaimer}>
                                                Sau vino în orice{' '}
                                                <a
                                                    href={'https://www.iqos.ro/store/ro/search'}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    locație IQOS
                                                </a>{' '}
                                                să testezi cele două noi sortimente.
                                            </p>

                                            <div className={styles.disclaimer}>
                                                <p>
                                                    Rezervele LEVIA™ se utilizează exclusiv cu
                                                    dispozitivele din gama IQOS ILUMA™, din cauza
                                                    riscului de deteriorare a lamelei.
                                                </p>
                                                <p>
                                                    Acestea conțin un element metalic ascuțit. Nu
                                                    lăsați la îndemâna copiilor pentru a evita
                                                    dezasamblarea sau înghițirea acestora.
                                                </p>
                                            </div>
                                        </div>
                                    </>
                                </div>
                                <div className={styles.slide}>
                                    <>
                                        <img
                                            src={slides[1].cardBackground}
                                            className={styles.cardBackgroundImage}
                                            alt={`sliderimg-2`}
                                        />

                                        <div className={styles.cardContent}>
                                            {isMobile ? (
                                                <h4 className={styles.invite}>
                                                    Te invităm să te bucuri de:
                                                </h4>
                                            ) : (
                                                <h5 className={styles.title}>{slides[1].title}</h5>
                                            )}

                                            <img
                                                src={slides[1].packImage}
                                                className={styles.pack}
                                                alt={''}
                                            />

                                            <img
                                                src={slides[1].flavourImage}
                                                className={styles.flavour}
                                                alt={''}
                                            />

                                            <p className={styles.aroma}>{slides[1].aroma}</p>

                                            <h5 className={styles.title}>{slides[1].title}</h5>

                                            <p className={styles.titleDescription}>
                                                {slides[1].titleDescription}
                                            </p>

                                            <p
                                                className={styles.contentTitle}
                                                dangerouslySetInnerHTML={{
                                                    __html: slides[1].contentTitle
                                                }}
                                            />

                                            <div className={styles.sliderButtons}>
                                                <Button
                                                    key={slides[0].sound}
                                                    label="Ascultă"
                                                    color="light"
                                                    type="solid"
                                                    hover="turquoise"
                                                    className={styles.listenSound}
                                                    onClick={() =>
                                                        playPackSound(`${slides[1].sound}`)
                                                    }
                                                >
                                                    <p>Ascultă</p>
                                                    <img src={musicalNote} alt="" />
                                                </Button>

                                                {userEligibleToReedemedPack && (
                                                    <Button
                                                        label="Revendică"
                                                        color="turquoise"
                                                        type="solid"
                                                        hover="light"
                                                        href={
                                                            config.path.campaigns
                                                                .prelaunchLeviaRedeem
                                                        }
                                                        className={styles.redeemPack}
                                                    />
                                                )}

                                                <a
                                                    href={
                                                        'https://www.iqos.ro/iluma-all/levia-iluma'
                                                    }
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className={styles.orderNow}
                                                >
                                                    Comandă acum
                                                </a>
                                            </div>

                                            <p className={styles.smallDisclaimer}>
                                                Sau vino în orice{' '}
                                                <a
                                                    href={'https://www.iqos.ro/store/ro/search'}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    locație IQOS
                                                </a>{' '}
                                                să testezi cele două noi sortimente.
                                            </p>

                                            <div className={styles.disclaimer}>
                                                <p>
                                                    Rezervele LEVIA™ se utilizează exclusiv cu
                                                    dispozitivele din gama IQOS ILUMA™, din cauza
                                                    riscului de deteriorare a lamelei.
                                                </p>
                                                <p>
                                                    Acestea conțin un element metalic ascuțit. Nu
                                                    lăsați la îndemâna copiilor pentru a evita
                                                    dezasamblarea sau înghițirea acestora.
                                                </p>
                                            </div>
                                        </div>
                                    </>
                                </div>
                            </KeenSlider>
                        </div>
                    </div>
                </div>
            ) : (
                <div className={styles.desktopCards}>
                    <div className={styles.slide}>
                        <>
                            <img
                                src={slides[0].cardBackground}
                                className={styles.cardBackgroundImage}
                                alt={`sliderimg-1`}
                            />

                            <div className={styles.cardContent}>
                                {isMobile ? (
                                    <h4 className={styles.invite}>Te invităm să te bucuri de:</h4>
                                ) : (
                                    <h5 className={styles.title}>{slides[0].title}</h5>
                                )}

                                <img src={slides[0].packImage} className={styles.pack} alt={''} />

                                <img
                                    src={slides[0].flavourImage}
                                    className={styles.flavour}
                                    alt={''}
                                />

                                <p className={styles.aroma}>{slides[0].aroma}</p>

                                <h5 className={styles.title}>{slides[0].title}</h5>

                                <p className={styles.titleDescription}>
                                    {slides[0].titleDescription}
                                </p>

                                <p
                                    className={styles.contentTitle}
                                    dangerouslySetInnerHTML={{
                                        __html: slides[0].contentTitle
                                    }}
                                />

                                <div className={styles.sliderButtons}>
                                    <Button
                                        key={slides[0].sound}
                                        label="Ascultă"
                                        color="light"
                                        type="solid"
                                        hover="turquoise"
                                        className={styles.listenSound}
                                        onClick={() => playPackSound(`${slides[0].sound}`)}
                                    >
                                        <p>Ascultă</p>
                                        <img src={musicalNote} alt="" />
                                    </Button>

                                    {userEligibleToReedemedPack && (
                                        <Button
                                            label="Revendică"
                                            color="turquoise"
                                            type="solid"
                                            hover="light"
                                            href={config.path.campaigns.prelaunchLeviaRedeem}
                                            className={styles.redeemPack}
                                        />
                                    )}

                                    <a
                                        href={'https://www.iqos.ro/iluma-all/levia-iluma'}
                                        target="_blank"
                                        rel="noreferrer"
                                        className={styles.orderNow}
                                    >
                                        Comandă acum
                                    </a>
                                </div>

                                <p className={styles.smallDisclaimer}>
                                    Sau vino în orice{' '}
                                    <a
                                        href={'https://www.iqos.ro/store/ro/search'}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        locație IQOS
                                    </a>{' '}
                                    să testezi cele două noi sortimente.
                                </p>

                                <div className={styles.disclaimer}>
                                    <p>
                                        Rezervele LEVIA™ se utilizează exclusiv cu dispozitivele din
                                        gama IQOS ILUMA™, din cauza riscului de deteriorare a
                                        lamelei.
                                    </p>
                                    <p>
                                        Acestea conțin un element metalic ascuțit. Nu lăsați la
                                        îndemâna copiilor pentru a evita dezasamblarea sau
                                        înghițirea acestora.
                                    </p>
                                </div>
                            </div>
                        </>
                    </div>
                    <div className={styles.slide}>
                        <>
                            <img
                                src={slides[1].cardBackground}
                                className={styles.cardBackgroundImage}
                                alt={`sliderimg-2`}
                            />

                            <div className={styles.cardContent}>
                                {isMobile ? (
                                    <h4 className={styles.invite}>Te invităm să te bucuri de:</h4>
                                ) : (
                                    <h5 className={styles.title}>{slides[1].title}</h5>
                                )}

                                <img src={slides[1].packImage} className={styles.pack} alt={''} />

                                <img
                                    src={slides[1].flavourImage}
                                    className={styles.flavour}
                                    alt={''}
                                />

                                <p className={styles.aroma}>{slides[1].aroma}</p>

                                <h5 className={styles.title}>{slides[1].title}</h5>

                                <p className={styles.titleDescription}>
                                    {slides[1].titleDescription}
                                </p>

                                <p
                                    className={styles.contentTitle}
                                    dangerouslySetInnerHTML={{
                                        __html: slides[1].contentTitle
                                    }}
                                />

                                <div className={styles.sliderButtons}>
                                    <Button
                                        key={slides[0].sound}
                                        label="Ascultă"
                                        color="light"
                                        type="solid"
                                        hover="turquoise"
                                        className={styles.listenSound}
                                        onClick={() => playPackSound(`${slides[1].sound}`)}
                                    >
                                        <p>Ascultă</p>
                                        <img src={musicalNote} alt="" />
                                    </Button>

                                    {userEligibleToReedemedPack && (
                                        <Button
                                            label="Revendică"
                                            color="turquoise"
                                            type="solid"
                                            hover="light"
                                            href={config.path.campaigns.prelaunchLeviaRedeem}
                                            className={styles.redeemPack}
                                        />
                                    )}

                                    <a
                                        href={'https://www.iqos.ro/iluma-all/levia-iluma'}
                                        target="_blank"
                                        rel="noreferrer"
                                        className={styles.orderNow}
                                    >
                                        Comandă acum
                                    </a>
                                </div>

                                <p className={styles.smallDisclaimer}>
                                    Sau vino în orice{' '}
                                    <a
                                        href={'https://www.iqos.ro/store/ro/search'}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        locație IQOS
                                    </a>{' '}
                                    să testezi cele două noi sortimente.
                                </p>

                                <div className={styles.disclaimer}>
                                    <p>
                                        Rezervele LEVIA™ se utilizează exclusiv cu dispozitivele din
                                        gama IQOS ILUMA™, din cauza riscului de deteriorare a
                                        lamelei.
                                    </p>
                                    <p>
                                        Acestea conțin un element metalic ascuțit. Nu lăsați la
                                        îndemâna copiilor pentru a evita dezasamblarea sau
                                        înghițirea acestora.
                                    </p>
                                </div>
                            </div>
                        </>
                    </div>
                </div>
            )}
        </section>
    );
};

export default NewLevia;
