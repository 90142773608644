import store from 'store';
import axios from 'axios';
import { config } from './config';
import cookies from '@utils/cookies';
import { addPackCodesCookie } from '@containers/Dashboard/AddPackCodes/AddPackCodes';

const handleLogout = () => {
    const keysToKeepInStore = [
        config.user.appVersion,
        config.user.tasteExplorationLastShow,
        config.user.tradeInModal
    ];

    cookies.erase(addPackCodesCookie);

    store.each((_value, key) => {
        if (!keysToKeepInStore.includes(key)) {
            store.remove(key);
        }
    });
    (window as Window).location = `${process.env.REACT_APP_BASENAME}${config.path.login}`;
};

export const logoutUser = (whereIsCalled: string) => {
    const authToken = store.get(config.user.authToken);
    const refreshToken = store.get(config.user.refreshToken);

    if (authToken || refreshToken) {
        axios
            .create({
                baseURL: config.apiUrl,
                headers: {
                    'X-Api-Key': `Bearer ${authToken}`
                }
            })
            .post('/user/logout', {
                auth_token: authToken,
                refresh_token: refreshToken
            })
            .then(() => {
                handleLogout();
            })
            .catch(() => {
                handleLogout();
            });
    }
};

const errorSwitch = (status: any) => {
    switch (status) {
        case 401:
            logoutUser('case 401 in errorSwitch');
            break;
        case 500:
            window.location.href = `.${config.path.error}`;
            break;
        default:
            break;
    }
};

const Errors = {
    errorSwitch
};

export default Errors;
