import { RootState } from '@app/store';
import axiosRequest from '@utils/axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export interface ssoState {
    status: 'idle' | 'loading' | 'failed' | 'success';
}

const initialState: ssoState = {
    status: 'idle'
};

export const redirectSSOAsync = createAsyncThunk('profile/user', async (redirect_url: String) => {
    try {
        const response = await axiosRequest.post('/user/dcs-token', { redirect_url });
        window.location = response.data.link;
    } catch (e: any) {
        return e.response.data.message;
    }
});

export const ssoSlice = createSlice({
    name: 'sso',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(redirectSSOAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(redirectSSOAsync.fulfilled, (state) => {
                state.status = 'success';
            })
            .addCase(redirectSSOAsync.rejected, (state) => {
                state.status = 'failed';
            });
    }
});

export const selectLoading = (state: RootState) => state.sso.status === 'loading';
export const selectSuccess = (state: RootState) => state.sso.status === 'success';
export const selectFailed = (state: RootState) => state.sso.status === 'failed';

export default ssoSlice.reducer;
