export const StarFull = () => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.73818 16.0686C9.90182 15.9785 10.0982 15.9785 10.2618 16.0686L15.5685 19L14.5573 12.8052C14.5245 12.6056 14.5867 12.4025 14.7242 12.263L19 7.88356L13.0829 6.9794C12.898 6.95292 12.7393 6.8293 12.6542 6.65094L10 1L7.34582 6.65094C7.26236 6.8293 7.102 6.95115 6.91709 6.9794L1 7.88356L5.27582 12.263C5.41327 12.4043 5.47545 12.6056 5.44273 12.8052L4.43145 19L9.73818 16.0686Z"
                fill="#00D1D2"
                stroke="#34303D"
                strokeLinejoin="round"
            />
        </svg>
    );
};
