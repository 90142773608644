import superSocialite from '@assets/badges/supersocialite.svg';
import socialite from '@assets/badges/socialite.svg';
import festivalier from '@assets/badges/2024/festivalier.svg';

export const listBadges: Array<{
    id: number;
    image: any;
    title: string;
    slug: string;
}> = [
    {
        id: 1,
        image: superSocialite,
        title: `Super socialite`,
        slug: 'BDG-SUPER-SOCIALITE'
    },
    {
        id: 2,
        image: socialite,
        title: 'Socialite',
        slug: 'BDG-SOCIALITE'
    },
    {
        id: 3,
        image: festivalier,
        title: 'Festivalier',
        slug: 'BDG-FESTIVALIER'
    }
];
