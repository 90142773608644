import classnames from 'classnames';
import styles from './VideoWrapper.module.scss';
import { IVideoWrapperProps } from './interface';

// Components
import Vimeo from '@u-wave/react-vimeo';

const VideoWrapper = (props: IVideoWrapperProps) => {
    const { video, title, description, textColor = 'dark', backgroundColor = '#00D1D2' } = props;

    return (
        <section
            style={{ backgroundColor: backgroundColor }}
            className={classnames(styles.wrapper, styles[textColor])}
        >
            {title && <h3>{title}</h3>}
            {description && <p>{title}</p>}

            <div className={styles.videoContainer}>
                <Vimeo video={video} autoplay={false} />
            </div>
        </section>
    );
};

export default VideoWrapper;
