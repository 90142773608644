import { useRef, useEffect, useState } from 'react';
import { IVimeoPlayerProps } from './interface';

// Vimeo Player
import Vimeo from '@vimeo/player';

const VimeoPlayer = (props: IVimeoPlayerProps) => {
    const {
        className,
        video,
        playOnHover,
        options,
        onReady,
        onPlay,
        onTimeUpdate,
        onPause,
        onEnd,
        currentTime = false
    } = props;
    const playerRef = useRef<HTMLDivElement>(null);

    const [myPlayer, setMyPlayer] = useState<any>();

    useEffect(() => {
        const player = new Vimeo(playerRef.current!, {
            url: `https://player.vimeo.com/video/${video}?background=1/`,
            pip: false,
            ...options
        });

        setMyPlayer(player);

        if (playOnHover) {
            const mouseenter = () => {
                player.play();
            };

            const mouseleave = () => {
                player.pause();
            };

            playerRef.current?.addEventListener('mouseenter', mouseenter);
            playerRef.current?.addEventListener('mouseleave', mouseleave);

            return () => {
                playerRef.current?.removeEventListener('mouseenter', mouseenter);
                playerRef.current?.removeEventListener('mouseleave', mouseleave);
            };
        }

        if (onReady) {
            player.ready().then(onReady);
        }

        if (onPlay) {
            player.on('play', onPlay);
        }

        if (onTimeUpdate) {
            player.on('timeupdate', (progress) => onTimeUpdate(progress));
        }

        if (onPause) {
            player.on('pause', onPause);
        }

        if (onEnd) {
            player.on('ended', onEnd);
        }

        return () => {
            player.destroy();
        };
    }, [video]);

    useEffect(() => {
        if (currentTime) {
            myPlayer?.setCurrentTime(0);
        }
    }, [currentTime]);

    return <div className={className} ref={playerRef} />;
};

export default VimeoPlayer;
