import { useState, useEffect } from 'react';

interface CustomWindow extends Window {
    MSStream?: any;
}

export enum MobileOS {
    Android = 'Android',
    iOS = 'iOS',
    Samsung = 'Samsung',
    Unknown = 'Unknown'
}

const useMobileOS = (): MobileOS => {
    const [operatingSystem, setOperatingSystem] = useState<MobileOS>(MobileOS.Unknown);

    useEffect(() => {
        const userAgent = navigator.userAgent || navigator.vendor;

        if (/SamsungBrowser/i.test(userAgent)) {
            setOperatingSystem(MobileOS.Samsung);
        } else if (/iPad|iPhone|iPod/.test(userAgent) && !(window as CustomWindow).MSStream) {
            setOperatingSystem(MobileOS.iOS);
        } else if (/android/i.test(userAgent)) {
            setOperatingSystem(MobileOS.Android);
        } else {
            setOperatingSystem(MobileOS.Unknown);
        }
    }, []);

    return operatingSystem;
};

export default useMobileOS;
