//Styles
import styles from './AutoDelivery.module.scss';

//Components
import ArticleHeader from '@containers/IQOSUniverse/components/ArticleHeader';
import ArticleImage from '@containers/IQOSUniverse/components/ArticleImage';
import Button from '@components/Layout/Button';

//Helpers
import classNames from 'classnames';
import { config } from '@utils/config';

//Assets
import image1 from './assets/image 5 (4).png';
import image1Desktop from './assets/RANGE_background_ExtremeVertical_CMYK 1 (4).png';
import image4 from './assets/image 5 (6).png';
import image4Desktop from './assets/RANGE_background_ExtremeVertical_CMYK 1 (6).png';

//Lists
import { HowItWorksList, HowItWorks, PreferencesList, Preferences } from './AutoDeliveryLists';

const AutoDelivery = () => {
    return (
        <section className={styles.wrapper}>
            <ArticleHeader isCustomPhotoDimension />

            <div className={classNames(styles.autoTopContent, 'content')}>
                <h3>
                    Cel mai simplu mod în care să acumulezi FP necesare pentru avansare sau
                    menținere în IQOS CLUB.
                </h3>

                <p>
                    Știi momentele alea în care nu prea ai timp sau răbdare să ieși la cumpărături?
                    Pierzi prea multe ore în trafic sau ajungi la magazin și vezi că nu mai au fix
                    produsele de care ai nevoie? Nimeni nu vrea asta și, în plus, ai putea să
                    folosești timpul respectiv pentru ceva ce chiar îți place.
                </p>

                <p className={styles.subtitle}>
                    Faci o singură comandă și primești lunar consumabilele preferate, la o ofertă
                    avantajoasă pe care te poți baza lună de lună.
                </p>
            </div>

            <ArticleImage cover={true} mobileImage={image1} image={image1Desktop} />

            <div className={classNames(styles.marginTop, 'content')}>
                <h3>Cum funcționează?</h3>

                {HowItWorksList.map((item: HowItWorks) => {
                    return (
                        <p key={item.id}>
                            <span className={styles.listIndex}>{item.id}. </span>
                            {item.description}
                        </p>
                    );
                })}

                <a
                    type={'text'}
                    href={'https://www.iqos-on.ro/legal/termeni-conditii-heets-delivery'}
                    target={'_blank'}
                    rel={'noreferrer noopener'}
                >
                    Program organizat de Mediapost Hitmail S.A. Consultă Termenele și condițiile
                    aici.
                </a>
            </div>

            {/*
            <ArticleImage cover={true} mobileImage={image2} image={image2} /> 
          
            <div className="content">
                <h3>
                    Poți plăti pachetele de rezerve HEETS & Fiit cu punctele din contul IQOS Club
                </h3>

                <p>
                    Poți alege un număr fix de puncte din contul tău pentru comanda AUTO Delivery și
                    completezi restul sumei cu bani. Alternativ, poți alege să folosești toate
                    punctele din cont pentru plata lunară.
                </p>
            </div>

            <div className="content">
                <h3>Primești puncte pentru pachetele de rezerve HEETS & Fiit și după livrare</h3>

                <p>
                    După fiecare livrare, vei primi automat în contul tău punctele aferente comenzii
                    AUTO Delivery, în limita a 30 de pachete lunar*. În plus, vei primi și 5 puncte
                    bonus după fiecare livrare.
                </p>

                <p>
                    *Nu poți acumula mai mult de 30 de puncte lunar din achiziția de pachete de
                    HEETS sau Fiit.
                </p>
            </div> 
            */}

            <ArticleImage cover={true} mobileImage={image4} image={image4Desktop} />

            <div className={classNames(styles.marginTop, 'content')}>
                <h3>
                    În plus, abonamentul AUTO Delivery îți oferă flexibilitate și se adaptează după
                    preferințele tale:
                </h3>

                {PreferencesList.map((item: Preferences) => {
                    return (
                        <p key={item.id}>
                            <span className={styles.listIndex}>{item.id}. </span>
                            {item.description}
                        </p>
                    );
                })}

                <Button
                    href={config.path.iqos.autoDelivery}
                    label="Activează acum AutoDelivery"
                    type="solid"
                    color="dark"
                    hover="turquoise"
                />
            </div>
        </section>
    );
};
export default AutoDelivery;
