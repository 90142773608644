import header from './assets/header.png';
import cardBkg from './assets/cardBkg.jpg';
import imgGuessed1 from './assets/imgGuessed1.jpg';
import imgGuessed2 from './assets/imgGuessed2.jpg';
import imgNotGuessed1 from './assets/imgNotGuessed1.png';
import imgNotGuessed2 from './assets/imgNotGuessed2.png';

import drag1 from './assets/card1.png';
import drag2 from './assets/card2.png';

import electro from './sounds/electro.mp3';
import island from './sounds/island.mp3';

export const dragGameData = [
    {
        id: 1,
        slug: 'trivia-game-9',
        header: header,
        title: 'Lasă-te purtat de ritmurile aromelor LEVIA',
        description: `Ascultă cum sună lumea aromelor LEVIA și potrivește fiecare pachet cu sunetul inspirat de acesta.`,
        successTitle: 'Felicitări, răspunsul tău este corect!',
        successText: `Prin prospețimea mentolată a lui Island Beat sau notele fresh și fructate ale lui Electro Rouge, te invităm să te lași purtat în lumea aromelor cu nicotină și zero tutun!`,
        failTitle: 'Hmm…mai încearcă',
        failText: `<strong>LEVIA Electro Rouge</strong> <br/>
                  Un amestec echilibrat între prospețimea mentei și aroma dulce-acrișoară a afinelor.<br/>
                  <strong>LEVIA Island Beat</strong><br/>
                  Un mix de senzații fresh, cu note proaspete de mentă.
                  `,
        data: [
            {
                region: {
                    image: cardBkg,
                    imageGuessed: imgGuessed1,
                    imageNotGuessed: imgNotGuessed2,
                    soundName: 'island',
                    sound: island,
                    notGuessed: false
                },
                card: {
                    image: drag1,
                    notGuessed: false
                }
            },
            {
                region: {
                    image: cardBkg,
                    imageGuessed: imgGuessed2,
                    imageNotGuessed: imgNotGuessed1,
                    soundName: 'electro',
                    sound: electro,
                    notGuessed: false
                },
                card: {
                    image: drag2,
                    notGuessed: false
                }
            }
        ]
    }
];
