import { lazy } from 'react';
import { config } from '@utils/config';
import { createBrowserRouter, Navigate } from 'react-router-dom';

// Components
import PublicRoute from './PublicRoute';
import ProtectedRoute from './ProtectedRoute';
import isCastingCallEnded from '@containers/Campaigns/CastingCall/isCastingCallEnded';
import RealPeopleRealConnections from '@containers/Campaigns/RealPeopleRealConnections';
import RealConnectionsGames from '@containers/Campaigns/RealPeopleRealConnections/Components/RealConnectionsGames';
// CallForDreamsGames
// import CallForDreamsGames from '@containers/Campaigns/CallForDreamsGames';

// ArticlePage
import ArticlePage from '@containers/IQOSUniverse/pages/ArticlePage';

// Main
const App = lazy(() => import('App'));

// Auth pages
const Login = lazy(() => import('@containers/Auth/Login'));
const FirstLogin = lazy(() => import('@containers/Auth/Onboarding'));
const LoginSSO = lazy(() => import('@containers/Auth/LoginSSO'));

// Homepage handler
const HomeHandler = lazy(() => import('@containers/HomeHandler'));

// User profile
const UserProfile = lazy(() => import('@containers/UserProfile'));
const MyDevices = lazy(() => import('@containers/MyDevices'));

// Misc pages
const FAQs = lazy(() => import('@containers/FAQs'));
const NoDevice = lazy(() => import('@containers/NoDevice'));
const NotFound = lazy(() => import('@containers/NotFound'));
const ErrorPage = lazy(() => import('@containers/ErrorPage'));
const Community = lazy(() => import('@containers/Community'));
const Platinum5 = lazy(() => import('@containers/Platinum5'));
const ExamplePage = lazy(() => import('@containers/ExamplePage'));
const ListWinners = lazy(() => import('@containers/ListWinners'));
const ListLocations = lazy(() => import('@containers/ListLocations'));
const HowItWorksPage = lazy(() => import('@containers/HowItWorksPage'));
const Notifications = lazy(() => import('@containers/Notifications/Notifications'));
const Contact = lazy(() => import('@containers/Contact'));

// Regulation
const Regulation = lazy(() => import('@containers/Regulation'));
const Promotion = lazy(() => import('@containers/Regulation/Promotion'));
const TermsAndConditions = lazy(() => import('@containers/TermsAndConditions'));

// Activity journal
const ActivityJournal = lazy(() => import('@containers/ActivityJournal'));

// MGM
const MGM = lazy(() => import('@containers/MGM'));
const Invite = lazy(() => import('@containers/MGM/pages/Invite'));
const InvitePublic = lazy(() => import('@containers/MGM/pages/InvitePublic'));

// Monthly challenge
const MonthlyChallenge = lazy(() => import('@containers/MonthlyChallenge'));
const ChallengeEnroll = lazy(() => import('@containers/MonthlyChallenge/pages/ChallengeEnroll'));
const ChallengeGallery = lazy(() => import('@containers/MonthlyChallenge/pages/ChallengeGallery'));
const PreviousChallenges = lazy(
    () => import('@containers/MonthlyChallenge/pages/PreviousChallenges')
);
const EndedChallenge = lazy(() => import('@containers/MonthlyChallenge/pages/EndedChallenge'));
const Entry = lazy(() => import('@containers/MonthlyChallenge/pages/Entry'));

// IQOS Universe
const IQOSUniverse = lazy(() => import('@containers/IQOSUniverse'));
// const ArticlePage = lazy(() => import('@containers/IQOSUniverse/pages/ArticlePage'));
const CategoryPage = lazy(() => import('@containers/IQOSUniverse/pages/CategoryPage'));

// Admin routes
const AddStory = lazy(() => import('@containers/Admin/AddStory'));

// Campaigns
const Contests = lazy(() => import('@containers/Contests'));
const ExampleCampaign = lazy(() => import('@containers/Campaigns/ExampleCampaign/ExampleCampaign'));

const NewIqosClubBenefits = lazy(
    () => import('@containers/Campaigns/NewIqosClubBenefits/NewIqosClubBenefits')
);

const QuizesWithSurprize = lazy(() => import('@containers/Campaigns/QuizesWithSurprize'));
const SummerGame = lazy(() => import('@containers/Campaigns/SummerGame'));

const FlavourBanQuiz = lazy(() => import('@containers/Campaigns/FlavourBanQuiz'));

const CallForDreams = lazy(() => import('@containers/Campaigns/CallForDreams'));
const DreamGallery = lazy(
    () => import('@containers/Campaigns/CallForDreams/components/pages/DreamGallery')
);
const DreamEntry = lazy(
    () => import('@containers/Campaigns/CallForDreams/components/pages/DreamEntry')
);
const CallForDreamsPhaseTwo = lazy(() => import('@containers/Campaigns/CallForDreamsPhaseTwo'));
const CallForDreamsPhaseTwoTop = lazy(
    () => import('@containers/Campaigns/CallForDreamsPhaseTwo/pages/TopExperiences')
);

const CallForDreamsList = lazy(
    () => import('@containers/Campaigns/CallForDreamsPhaseThree/pages/Dreams')
);
const CallForDreamsEnroll = lazy(
    () => import('@containers/Campaigns/CallForDreamsPhaseThree/pages/Dream')
);
const GoTechWorld = lazy(() => import('@containers/Campaigns/GoTechWorld/GoTechWorld'));

const PrelaunchTereaFuse = lazy(() => import('@containers/Campaigns/PrelaunchTereaFuse'));
const PrelaunchTereaFuseRedeem = lazy(
    () => import('@containers/Campaigns/PrelaunchTereaFuse/pages/RedeemPage')
);
const LeviaFeedback = lazy(() => import('@containers/Campaigns/LeviaFeedback/LeviaFeedback'));

const ConversionSurveyModal = lazy(() => import('@components/ConversionSurveyModal'));

const TaskforceSurveyModal = lazy(() => import('@components/TaskforceSurveyModal'));

const ExperienceVoting = lazy(
    () => import('@containers/Campaigns/RealPeopleRealConnections/ExperienceVoting')
);
const TopVotedExperiences = lazy(
    () =>
        import(
            '@containers/Campaigns/RealPeopleRealConnections/ExperienceVoting/pages/TopVotedExperiences'
        )
);
const AuroraExperience = lazy(() => import('@containers/Campaigns/AuroraExperience'));

// Iluma

const IQOSXRdwChestionar = lazy(() => import('@containers/Campaigns/LeadfamlyQuiz'));
const IlumaSummer = lazy(() => import('@containers/Campaigns/IlumaSummer'));
const IlumaSummerUGCEntries = lazy(
    () => import('@containers/Campaigns/IlumaSummer/pages/Gallery/SummerGallery')
);
const IlumaSummerUGCEntry = lazy(
    () => import('@containers/Campaigns/IlumaSummer/pages/Entry/SummerEntry')
);

const ToscanaExperienceFeedback = lazy(
    () => import('@containers/Campaigns/ToscanaExperienceFeedback')
);
const SummerExperienceFeedback = lazy(
    () => import('@containers/Campaigns/SummerExperienceFeedback')
);
const FlavourBan = lazy(() => import('@containers/Campaigns/FlavourBan'));
const IlumaStardriftPrelaunch = lazy(() => import('@containers/Campaigns/IlumaStardriftPrelaunch'));
const StardriftSurvey = lazy(
    () => import('@containers/Campaigns/IlumaStardriftPrelaunch/SurveyStardrift/SurveyStardrift')
);
const PrelaunchLevia = lazy(() => import('@containers/Campaigns/PrelaunchLevia'));
const PrelaunchLeviaRedeem = lazy(
    () => import('@containers/Campaigns/PrelaunchLevia/pages/RedeemPage')
);
const TasteExploration = lazy(() => import('@containers/Campaigns/TasteExploration'));

const IlumaNeonPurplePrelaunch = lazy(
    () => import('@containers/Campaigns/IlumaNeonPurplePrelaunch')
);

// Live Ticketing
const LiveTicketing = lazy(() => import('@containers/Campaigns/LiveTicketing'));
const OutsideClubTickets = lazy(
    () => import('@containers/Campaigns/LiveTicketing/pages/OutsideClubTickets')
);
const BucharestConfirmation = lazy(
    () => import('@containers/Campaigns/LiveTicketing/pages/BucharestConfirmation')
);

const TicketingVoting = lazy(
    () => import('@containers/Campaigns/LiveTicketing/pages/TicketingVoting/TicketingVoting')
);

const TicketingTopVoted = lazy(
    () =>
        import(
            '@containers/Campaigns/LiveTicketing/pages/TicketingVoting/pages/TicketingTopVoted/TicketingTopVoted'
        )
);

// Kiruna quiz
const KirunaQuiz = lazy(() => import('@components/KirunaQuiz'));

// IQOS Experience Contest
const IQOSExperienceContestSpecial = lazy(
    () => import('@containers/Campaigns/IQOSExperienceContestSpecial')
);

const IqosExperienceBrunch = lazy(() => import('@containers/Campaigns/IqosExperienceBrunch'));

// My Rewards
const MyRewards = lazy(() => import('@containers/MyRewards'));
const GiftPage = lazy(() => import('@containers/MyRewards/components/GiftPage'));
const VoucherPage = lazy(() => import('@containers/MyRewards/components/VoucherPage'));
const AccessoriesVoucher = lazy(() => import('@containers/MyRewards/pages/AccessoriesVoucher'));
const PartnersVoucherPage = lazy(
    () => import('@containers/MyRewards/components/PartnersVoucherPage')
);
const OfferVoucher = lazy(() => import('@containers/MyRewards/components/OfferVoucherPage'));
// Badges
const AboutBadges = lazy(() => import('@containers/AboutBadges/pages/AboutBadges'));
const BadgesCollection = lazy(() => import('@containers/AboutBadges/pages/BadgesCollection'));

const campaignEnded = isCastingCallEnded();

// Important note:
// PublicRoute is a wrapper for public pages that users don't need to have accces after login (eg. login page, onboarding page, no-device page);
// ProtectedRoute is a wrapper for pages that users need to have access after login (eg. homepage, user profile, my devices, etc);
// If you want to add a new page, you need to add it to the routes below. (eg. check regulation page below)

const router = createBrowserRouter(
    [
        {
            path: config.path.index,
            element: <App />,
            children: [
                // Homepage handler
                {
                    index: true,
                    element: <HomeHandler />
                },
                {
                    path: config.path.notFound,
                    element: <NotFound />
                },

                // Auth + No device + SSO
                {
                    path: config.path.login,
                    element: (
                        <PublicRoute>
                            <Login />
                        </PublicRoute>
                    )
                },
                {
                    path: config.path.firstLogin,
                    element: (
                        <ProtectedRoute>
                            <FirstLogin />
                        </ProtectedRoute>
                    )
                },
                {
                    path: config.path.noDevice,
                    element: (
                        <ProtectedRoute>
                            <NoDevice />
                        </ProtectedRoute>
                    )
                },
                {
                    path: `${config.path.auth}/:token`,
                    element: (
                        <PublicRoute>
                            <LoginSSO />
                        </PublicRoute>
                    )
                },

                // Terms and conditions
                {
                    path: config.path.termsAndConditions,
                    handle: { title: 'Termeni și condiții', goBackTo: config.path.index },
                    element: (
                        <ProtectedRoute>
                            <TermsAndConditions />
                        </ProtectedRoute>
                    )
                },

                // User profile
                {
                    path: config.path.userProfileHash,
                    handle: { title: 'Profilul meu', goBackTo: config.path.index },
                    element: (
                        <ProtectedRoute>
                            <UserProfile />
                        </ProtectedRoute>
                    )
                },

                // My devices
                {
                    path: config.path.myDevices,
                    handle: { title: 'Dispozitivele mele', goBackTo: config.path.index },
                    element: (
                        <ProtectedRoute>
                            <MyDevices />
                        </ProtectedRoute>
                    )
                },

                // Admin routes
                {
                    path: config.path.campaigns.addStory,
                    handle: { title: 'Adaugă Story', goBackTo: config.path.index },
                    element: (
                        <ProtectedRoute>
                            <AddStory />
                        </ProtectedRoute>
                    )
                },

                // MGM
                {
                    path: config.path.mgm.index,
                    handle: {
                        title: 'Trimite o recomandare',
                        goBackTo: config.path.index,
                        forceNavigatePath: true
                    },
                    element: (
                        <ProtectedRoute>
                            <MGM />
                        </ProtectedRoute>
                    ),
                    children: [{ path: ':tab' }]
                },
                {
                    path: config.path.mgm.invite,
                    handle: {
                        title: 'Trimite o recomandare',
                        goBackTo: config.path.index,
                        forceNavigatePath: true
                    },
                    element: (
                        <ProtectedRoute>
                            <Invite />
                        </ProtectedRoute>
                    )
                },
                {
                    path: `${config.path.mgm.invite}/:token`,
                    handle: { title: '', goBackTo: config.path.index, forceNavigatePath: true },
                    element: (
                        <PublicRoute>
                            <InvitePublic />
                        </PublicRoute>
                    )
                },

                // Monthly challenge
                {
                    path: config.path.monthlyChallenge.index,
                    handle: {
                        title: 'Provocarea IQOS',
                        goBackTo: config.path.index,
                        forceNavigatePath: true
                    },
                    element: (
                        <ProtectedRoute>
                            <MonthlyChallenge />
                        </ProtectedRoute>
                    )
                },
                {
                    path: config.path.monthlyChallenge.oldChallenges,
                    handle: {
                        title: 'Toate provocările',
                        goBackTo: config.path.monthlyChallenge.index
                    },
                    element: (
                        <ProtectedRoute>
                            <PreviousChallenges />
                        </ProtectedRoute>
                    )
                },
                {
                    path: config.path.monthlyChallenge.gallery,
                    handle: {
                        title: 'Înscrierile de până acum',
                        goBackTo: config.path.monthlyChallenge.index,
                        forceNavigatePath: true
                    },
                    element: (
                        <ProtectedRoute>
                            <ChallengeGallery />
                        </ProtectedRoute>
                    )
                },
                {
                    path: config.path.monthlyChallenge.oldChallengesDetails,
                    handle: {
                        title: 'Înscrieri',
                        goBackTo: config.path.monthlyChallenge.oldChallenges
                    },
                    element: (
                        <ProtectedRoute>
                            <ChallengeGallery />
                        </ProtectedRoute>
                    )
                },
                {
                    path: config.path.monthlyChallenge.enroll,
                    handle: { title: 'Înscrie-te', goBackTo: config.path.monthlyChallenge.index },
                    element: (
                        <ProtectedRoute>
                            <ChallengeEnroll />
                        </ProtectedRoute>
                    )
                },
                {
                    path: config.path.monthlyChallenge.endedCampaign,
                    handle: {
                        title: 'Trimite o recomandare',
                        goBackTo: config.path.monthlyChallenge.index
                    },
                    element: (
                        <ProtectedRoute>
                            <EndedChallenge />
                        </ProtectedRoute>
                    )
                },
                {
                    path: config.path.monthlyChallenge.entry,
                    handle: {
                        title: 'Votează înscrierea',
                        goBackTo: config.path.monthlyChallenge.gallery,
                        forceNavigatePath: true
                    },
                    element: (
                        <ProtectedRoute>
                            <Entry />
                        </ProtectedRoute>
                    )
                },
                {
                    path: config.path.monthlyChallenge.oldChallengesEntry,
                    handle: {
                        title: 'Înapoi',
                        goBackTo: config.path.monthlyChallenge.oldChallenges
                    },
                    element: (
                        <ProtectedRoute>
                            <Entry />
                        </ProtectedRoute>
                    )
                },

                // Activity journal
                {
                    path: config.path.activityJournal,
                    handle: {
                        title: 'Jurnal activitate',
                        goBackTo: config.path.index,
                        forceNavigatePath: true
                    },
                    element: (
                        <ProtectedRoute>
                            <ActivityJournal />
                        </ProtectedRoute>
                    ),
                    children: [{ path: ':tab' }]
                },

                // Community
                {
                    path: config.path.community.index,
                    handle: {
                        title: 'Comunitatea IQOS',
                        goBackTo: config.path.index,
                        forceNavigatePath: true
                    },
                    element: (
                        <ProtectedRoute>
                            <Community />
                        </ProtectedRoute>
                    ),
                    children: [{ path: ':tab' }]
                },
                {
                    path: config.path.campaigns.platinum5,
                    handle: {
                        title: 'Leaderboard Platinum',
                        goBackTo: config.path.index,
                        forceNavigatePath: true
                    },
                    element: (
                        <ProtectedRoute>
                            <Platinum5 />
                        </ProtectedRoute>
                    )
                },
                {
                    path: config.path.community.leaderboard,
                    handle: {
                        title: 'Comunitatea IQOS',
                        goBackTo: config.path.community.index,
                        forceNavigatePath: true
                    },
                    element: (
                        <ProtectedRoute>
                            <Community />
                        </ProtectedRoute>
                    )
                },

                // IQOS Universe
                {
                    path: config.path.iqosUniverse.index,
                    handle: { title: 'Universul IQOS', goBackTo: config.path.index },
                    element: (
                        <ProtectedRoute>
                            <IQOSUniverse />
                        </ProtectedRoute>
                    )
                },
                {
                    path: config.path.iqosUniverse.article,
                    handle: { title: ' ', goBackTo: config.path.iqosUniverse.index },
                    element: (
                        <ProtectedRoute>
                            <ArticlePage />
                        </ProtectedRoute>
                    )
                },
                {
                    path: config.path.iqosUniverse.inspiration,
                    handle: {
                        title: 'Inspirație & inovație',
                        goBackTo: config.path.iqosUniverse.index
                    },
                    element: (
                        <ProtectedRoute>
                            <CategoryPage />
                        </ProtectedRoute>
                    )
                },
                {
                    path: config.path.iqosUniverse.iqosHub,
                    handle: { title: 'IQOS Hub', goBackTo: config.path.iqosUniverse.index },
                    element: (
                        <ProtectedRoute>
                            <CategoryPage />
                        </ProtectedRoute>
                    )
                },
                {
                    path: config.path.iqosUniverse.iqosAnswers,
                    handle: { title: 'IQOS răspunde', goBackTo: config.path.iqosUniverse.index },
                    element: (
                        <ProtectedRoute>
                            <CategoryPage />
                        </ProtectedRoute>
                    )
                },
                {
                    path: config.path.iqosUniverse.iqosQuiz,
                    handle: { title: 'IQOS Quiz', goBackTo: config.path.iqosUniverse.index },
                    element: (
                        <ProtectedRoute>
                            <CategoryPage />
                        </ProtectedRoute>
                    )
                },

                // FAQ
                {
                    path: config.path.FAQs,
                    handle: { title: 'Întrebări frecvente', goBackTo: config.path.index },
                    element: (
                        <ProtectedRoute>
                            <FAQs />
                        </ProtectedRoute>
                    )
                },

                // List winners
                {
                    path: config.path.listWinners,
                    handle: { title: 'Câștigători', goBackTo: config.path.index },
                    element: (
                        <ProtectedRoute>
                            <ListWinners />
                        </ProtectedRoute>
                    )
                },

                // List locations
                {
                    path: config.path.locations,
                    handle: { title: 'Locații IQOS', goBackTo: config.path.index },
                    element: (
                        <ProtectedRoute>
                            <ListLocations />
                        </ProtectedRoute>
                    )
                },

                // Notifications
                {
                    path: config.path.notifications,
                    handle: { title: 'Notificările mele', goBackTo: config.path.index },
                    element: (
                        <ProtectedRoute>
                            <Notifications />
                        </ProtectedRoute>
                    )
                },

                // Contact
                {
                    path: config.path.contact,
                    handle: { title: 'Contact', goBackTo: config.path.index },
                    element: (
                        <ProtectedRoute>
                            <Contact />
                        </ProtectedRoute>
                    )
                },

                // Offline Redemption
                {
                    path: config.path.offlineRedemption,
                    element: <Navigate to={config.path.index} />
                },

                // About Badges
                {
                    path: config.path.aboutBadges,
                    handle: { title: 'Badge-uri', goBackTo: config.path.userProfile },
                    element: (
                        <ProtectedRoute>
                            <AboutBadges />
                        </ProtectedRoute>
                    )
                },

                {
                    path: config.path.badgesCollectionYear,
                    handle: { title: ' Profilul meu', goBackTo: config.path.userProfile },
                    element: (
                        <ProtectedRoute>
                            <BadgesCollection />
                        </ProtectedRoute>
                    )
                },

                // Regulation
                {
                    path: config.path.regulation.index,
                    handle: { title: 'Regulament' },
                    element: <Regulation />
                },
                {
                    path: config.path.regulation.promotion,
                    handle: { title: 'Regulament' },
                    element: <Promotion />
                },

                // HowItWorks
                {
                    path: config.path.howItWorks,
                    handle: { title: 'Cum funcționează' },
                    element: (
                        <ProtectedRoute>
                            <HowItWorksPage />
                        </ProtectedRoute>
                    )
                },

                // My Rewards
                {
                    path: config.path.myRewards.indexId,
                    handle: {
                        title: 'Recompensele mele',
                        goBackTo: config.path.index,
                        forceNavigatePath: true
                    },
                    element: (
                        <ProtectedRoute>
                            <MyRewards />
                        </ProtectedRoute>
                    )
                },

                {
                    path: config.path.myRewards.giftPage,
                    handle: { title: ' ', goBackTo: config.path.myRewards.index },
                    element: (
                        <ProtectedRoute>
                            <GiftPage />
                        </ProtectedRoute>
                    )
                },

                {
                    path: config.path.myRewards.voucherPage,
                    handle: { title: ' ', goBackTo: config.path.myRewards.index },
                    element: (
                        <ProtectedRoute>
                            <VoucherPage />
                        </ProtectedRoute>
                    )
                },
                {
                    path: config.path.myRewards.partnersGift,
                    handle: { title: ' ', goBackTo: config.path.myRewards.index },
                    element: (
                        <ProtectedRoute>
                            <PartnersVoucherPage />
                        </ProtectedRoute>
                    )
                },

                {
                    path: config.path.myRewards.staticDiscount,
                    handle: { title: ' ', goBackTo: config.path.myRewards.index },
                    element: (
                        <ProtectedRoute>
                            <AccessoriesVoucher />
                        </ProtectedRoute>
                    )
                },

                {
                    path: config.path.myRewards.offerVoucher,
                    handle: { title: ' ', goBackTo: config.path.myRewards.index },
                    element: (
                        <ProtectedRoute>
                            <OfferVoucher />
                        </ProtectedRoute>
                    )
                },

                // Example page
                {
                    path: config.path.example,
                    element: (
                        <ProtectedRoute>
                            <ExamplePage />
                        </ProtectedRoute>
                    )
                },

                // Campaigns
                {
                    path: config.path.contests,
                    handle: {
                        title: 'Concursuri',
                        goBackTo: config.path.index // Go back to specific page (instead of navigate(-1))
                    },
                    element: (
                        <ProtectedRoute>
                            <Contests />
                        </ProtectedRoute>
                    )
                },
                {
                    path: config.path.campaigns.launch,
                    handle: {
                        title: 'Concurs',
                        goBackTo: config.path.index // Go back to specific page (instead of navigate(-1))
                    },
                    element: (
                        <ProtectedRoute>
                            <ExampleCampaign />
                        </ProtectedRoute>
                    )
                },
                {
                    path: config.path.campaigns.newFeatures,

                    element: <Navigate to={config.path.index} />
                },
                {
                    path: config.path.campaigns.newFeaturesGamepage,

                    element: <Navigate to={config.path.index} />
                },

                {
                    path: config.path.campaigns.castingCall,
                    element: <Navigate to={config.path.index} />
                },

                {
                    path: config.path.campaigns.castingCallGame,
                    element: <Navigate to={config.path.index} />
                },

                {
                    path: config.path.campaigns.castingCallEntries,
                    element: <Navigate to={config.path.index} />
                },

                {
                    path: config.path.campaigns.castingCallEntry,
                    element: <Navigate to={config.path.index} />
                },

                {
                    path: config.path.campaigns.castingCallEnroll,
                    element: <Navigate to={config.path.index} />
                },

                {
                    path: config.path.campaigns.castingCallEditProfile,
                    element: <Navigate to={config.path.index} />
                },

                {
                    path: config.path.campaigns.summerEngagement,
                    handle: {
                        title: 'Concurs',
                        goBackTo: config.path.index
                    },
                    element: (
                        <ProtectedRoute>
                            <IlumaSummer />
                        </ProtectedRoute>
                    )
                },
                {
                    path: config.path.campaigns.summerEngagementUGCGallery,
                    handle: {
                        title: 'Postările de până acum',
                        goBackTo: config.path.index,
                        forceNavigatePath: true
                    },
                    element: (
                        <ProtectedRoute>
                            <IlumaSummerUGCEntries />
                        </ProtectedRoute>
                    )
                },

                {
                    path: config.path.campaigns.summerEngagementUGCEntry,
                    handle: {
                        title: 'Postare',
                        goBackTo: config.path.campaigns.summerEngagementUGCGallery,
                        forceNavigatePath: true
                    },
                    element: (
                        <ProtectedRoute>
                            <IlumaSummerUGCEntry />
                        </ProtectedRoute>
                    )
                },

                // Flavour Ban
                {
                    path: config.path.campaigns.flavourBan,
                    handle: {
                        title: 'Exploratorii gustului',
                        goBackTo: config.path.index
                    },
                    element: (
                        <ProtectedRoute>
                            <FlavourBan />
                        </ProtectedRoute>
                    )
                },

                // IQOS Experience Contest
                {
                    path: config.path.campaignsStandardLayout.iqosExperienceContest,
                    handle: {
                        title: 'Concurs',
                        goBackTo: config.path.index
                    },
                    element: (
                        <ProtectedRoute>
                            <IQOSExperienceContestSpecial />
                        </ProtectedRoute>
                    )
                },

                {
                    path: config.path.campaignsStandardLayout.iqosExperienceBrunch,
                    handle: {
                        title: 'Concurs',
                        goBackTo: config.path.index
                    },
                    element: (
                        <ProtectedRoute>
                            <IqosExperienceBrunch />
                        </ProtectedRoute>
                    )
                },

                {
                    path: config.path.campaigns.ilumaTeasingIndex,

                    element: <Navigate to={config.path.campaigns.ilumaTeasingLiveStream} />
                },

                {
                    path: config.path.campaigns.ilumaTeasingIndexNoContest,

                    element: <Navigate to={config.path.campaigns.ilumaTeasingLiveStream} />
                },

                {
                    path: config.path.ilumaTeasingPages.quizPage,

                    element: <Navigate to={config.path.campaigns.ilumaTeasingLiveStream} />
                },

                {
                    path: config.path.ilumaTeasingPages.quizPagePromotion,

                    element: <Navigate to={config.path.campaigns.ilumaTeasingLiveStream} />
                },

                {
                    path: config.path.ilumaTeasingPages.quizSubmit,

                    element: <Navigate to={config.path.campaigns.ilumaTeasingLiveStream} />
                },

                {
                    path: config.path.ilumaTeasingPages.quizSubmitPromotion,

                    element: <Navigate to={config.path.campaigns.ilumaTeasingLiveStream} />
                },

                {
                    path: config.path.campaigns.ilumaTeasingLiveStream,
                    element: <Navigate to={config.path.index} />
                },

                {
                    path: config.path.campaignsStandardLayout.leadfamlyQuiz,
                    handle: {
                        title: 'Chestionar IQOS x RDW',
                        goBackTo: config.path.index
                    },
                    element: (
                        <ProtectedRoute>
                            <IQOSXRdwChestionar />
                        </ProtectedRoute>
                    )
                },

                {
                    path: config.path.campaignsStandardLayout.summerCampaignLeadFamlyQuiz,
                    element: <Navigate to={config.path.index} />
                },

                {
                    path: config.path.campaignsStandardLayout.toscanaExperienceFeedback,
                    handle: {
                        title: 'Chestionar',
                        goBackTo: config.path.index,
                        forceNavigatePath: true
                    },
                    element: (
                        <ProtectedRoute>
                            <ToscanaExperienceFeedback />
                        </ProtectedRoute>
                    )
                },

                {
                    path: config.path.campaignsStandardLayout.summerExperienceFeedback,
                    handle: {
                        title: 'Chestionar',
                        goBackTo: config.path.index,
                        forceNavigatePath: true
                    },
                    element: (
                        <ProtectedRoute>
                            <SummerExperienceFeedback />
                        </ProtectedRoute>
                    )
                },

                {
                    path: config.path.campaignsStandardLayout.feedbackLevia,
                    handle: {
                        title: 'Chestionar',
                        goBackTo: config.path.index,
                        forceNavigatePath: true
                    },
                    element: (
                        <ProtectedRoute>
                            <LeviaFeedback />
                        </ProtectedRoute>
                    )
                },

                {
                    path: config.path.campaigns.illumaPrelaunch,
                    element: <Navigate to={config.path.index} />
                },

                {
                    path: config.path.campaigns.ilumaOnePrelaunch,
                    element: <Navigate to={config.path.index} />
                },

                {
                    path: config.path.campaigns.ilumaLaunch,
                    element: <Navigate to={config.path.index} />
                },
                {
                    path: config.path.campaignsStandardLayout.goTechWorld,
                    handle: {
                        title: 'GoTechWorld 2023',
                        goBackTo: config.path.index
                    },
                    element: (
                        <ProtectedRoute>
                            <GoTechWorld />
                        </ProtectedRoute>
                    )
                },

                //NewIqosClubBenefits
                {
                    path: config.path.campaigns.newIqosClubBenefits,
                    handle: {
                        title: 'Noul IQOS CLUB',
                        goBackTo: config.path.campaigns.newFeatures
                    },
                    element: (
                        <ProtectedRoute>
                            <NewIqosClubBenefits />
                        </ProtectedRoute>
                    )
                },

                // Lucky Draws
                {
                    path: config.path.luckyDrawRDW,
                    element: <Navigate to={config.path.index} />
                },
                {
                    path: config.path.luckyDrawFlightFestival,
                    element: <Navigate to={config.path.index} />
                },
                {
                    path: config.path.luckyDrawTiff,
                    element: <Navigate to={config.path.index} />
                },
                {
                    path: config.path.luckyDrawSingFest,
                    element: <Navigate to={config.path.index} />
                },

                // Conversion survey
                {
                    path: config.path.conversionSurvey,
                    element: (
                        <ProtectedRoute>
                            <ConversionSurveyModal />
                        </ProtectedRoute>
                    )
                },
                // Taskforce survey
                {
                    path: config.path.taskforceSurvey,
                    element: (
                        <ProtectedRoute>
                            <TaskforceSurveyModal />
                        </ProtectedRoute>
                    )
                },

                // Redirect to IQOS Universe iluma stardrift article
                {
                    path: config.path.ilumaStardrift,
                    element: (
                        <Navigate
                            to={`${config.path.iqosUniverse.inspiration}/iqos-iluma-stardrift-iti-aduce-inspiratie-pentru-dorintele-tale`}
                        />
                    )
                },

                // Redirect to IQOS Universe iluma stardrift article
                {
                    path: config.path.ilumaStardrift,
                    element: (
                        <Navigate
                            to={`${config.path.iqosUniverse.inspiration}/iqos-iluma-stardrift-iti-aduce-inspiratie-pentru-dorintele-tale`}
                        />
                    )
                },

                // PWA page redirect to IQOS Universe article
                {
                    path: config.path.pwaPage,
                    element: (
                        <Navigate
                            to={`${config.path.iqosUniverse.iqosHub}${config.path.pwaPage}`}
                        />
                    )
                },
                // Prelaunch Levia redirect
                {
                    path: config.path.campaigns.prelaunchLevia,
                    element: <Navigate to={config.path.index} />
                },
                {
                    path: config.path.campaigns.prelaunchLeviaRedeem,
                    handle: {
                        title: 'Revendică pachetul LEVIA',
                        goBackTo: config.path.index
                    },
                    element: (
                        <ProtectedRoute>
                            <PrelaunchLeviaRedeem />
                        </ProtectedRoute>
                    )
                },

                // QuizWithSurprize
                {
                    path: config.path.campaigns.quizWithSurprize,
                    handle: {
                        title: 'Ești gata să explorezi?',
                        goBackTo: config.path.index
                    },
                    element: (
                        <ProtectedRoute>
                            <QuizesWithSurprize />
                        </ProtectedRoute>
                    )
                },

                // Summer game
                {
                    path: config.path.campaignsStandardLayout.summerGame,
                    handle: {
                        title: ' ',
                        goBackTo: config.path.campaigns.summerEngagement
                    },
                    element: (
                        <ProtectedRoute>
                            <SummerGame />
                        </ProtectedRoute>
                    )
                },

                {
                    path: config.path.campaignsStandardLayout.jazzInTheParkUGC,
                    element: <Navigate to={config.path.index} />
                },

                // Brizo Prty

                {
                    path: config.path.campaignsStandardLayout.brizoParty,
                    element: <Navigate to={config.path.index} />
                },

                // Prelaunch Terea Fuse
                {
                    path: config.path.campaigns.prelaunchTereaFuse,
                    handle: {
                        title: 'TEREA Fuse',
                        goBackTo: config.path.index
                    },
                    element: (
                        <ProtectedRoute>
                            <PrelaunchTereaFuse />
                        </ProtectedRoute>
                    )
                },
                {
                    path: config.path.campaigns.prelaunchTereaFuseRedeem,
                    handle: {
                        title: 'TEREA Fuse',
                        goBackTo: config.path.campaigns.prelaunchTereaFuse
                    },
                    element: (
                        <ProtectedRoute>
                            <PrelaunchTereaFuseRedeem />
                        </ProtectedRoute>
                    )
                },

                // Flavour Ban Quiz
                {
                    path: config.path.campaigns.flavourBanQuiz,
                    handle: {
                        title: 'Completează chestionarul',
                        goBackTo: config.path.index
                    },
                    element: (
                        <ProtectedRoute>
                            <FlavourBanQuiz />
                        </ProtectedRoute>
                    )
                },

                {
                    path: config.path.campaigns.tasteExploration,
                    element: <Navigate to={config.path.index} />
                },
                // Iluma Stardrift Prelaunch

                {
                    path: config.path.campaigns.ilumaStardriftPrelaunch,
                    handle: {
                        title: ' Noua gamă IQOS ILUMA ',
                        goBackTo: config.path.index
                    },
                    element: (
                        <ProtectedRoute>
                            <IlumaStardriftPrelaunch />
                        </ProtectedRoute>
                    )
                },

                {
                    path: config.path.stardriftSurvey,
                    handle: {
                        title: ' Chestionar ',
                        goBackTo: config.path.index
                    },
                    element: (
                        <ProtectedRoute>
                            <StardriftSurvey />
                        </ProtectedRoute>
                    )
                },

                // Kiruna quiz

                {
                    path: config.path.kirunaQuiz,
                    handle: {
                        title: ' Chestionar ',
                        goBackTo: config.path.index
                    },
                    element: (
                        <ProtectedRoute>
                            <KirunaQuiz />
                        </ProtectedRoute>
                    )
                },

                // CallForDreamsCampaign

                {
                    path: config.path.campaigns.callForDreamsTeasing,
                    handle: {
                        title: '  ',
                        goBackTo: config.path.index
                    },
                    element: <Navigate to={config.path.campaigns.callForDreamsCampaignPage} />
                },

                {
                    path: config.path.campaigns.callForDreamsCampaignPage,
                    handle: {
                        title: 'Oameni reali. Visuri reale.',
                        goBackTo: config.path.index
                    },
                    element: (
                        <ProtectedRoute>
                            <CallForDreams />
                        </ProtectedRoute>
                    )
                },

                {
                    path: config.path.campaigns.callForDreamsGallery,
                    handle: {
                        title: 'Visuri câștigătoare',
                        goBackTo: config.path.campaigns.callForDreamsCampaignPage,
                        forceNavigatePath: true
                    },
                    element: (
                        <ProtectedRoute>
                            <DreamGallery />
                        </ProtectedRoute>
                    )
                },

                {
                    path: config.path.campaigns.callForDreamsEntry,
                    handle: {
                        title: ' ',
                        goBackTo: config.path.campaigns.callForDreamsGallery
                    },
                    element: (
                        <ProtectedRoute>
                            <DreamEntry />
                        </ProtectedRoute>
                    )
                },
                {
                    path: config.path.campaigns.callForDreamsPhaseTwo,
                    handle: {
                        title: 'Alege experiența',
                        goBackTo: config.path.campaigns.callForDreamsCampaignPage
                    },
                    element: (
                        <ProtectedRoute>
                            <CallForDreamsPhaseTwo />
                        </ProtectedRoute>
                    )
                },
                {
                    path: config.path.campaigns.callForDreamsPhaseTwoTop,
                    handle: {
                        title: 'Topul preferințelor',
                        goBackTo: config.path.campaigns.callForDreamsCampaignPage
                    },
                    element: (
                        <ProtectedRoute>
                            <CallForDreamsPhaseTwoTop />
                        </ProtectedRoute>
                    )
                },

                // Call For Dreams Games
                {
                    path: config.path.campaignsStandardLayout.callForDreamsGame,
                    handle: {
                        title: ' ',
                        goBackTo: config.path.index
                    },
                    element: <Navigate to={config.path.campaigns.callForDreamsCampaignPage} />
                },
                {
                    path: config.path.campaigns.callForDreamsAllDreams,
                    handle: {
                        title: 'Visurile câștigătoare',
                        goBackTo: config.path.campaigns.callForDreamsCampaignPage
                    },
                    element: (
                        <ProtectedRoute>
                            <CallForDreamsList />
                        </ProtectedRoute>
                    )
                },
                {
                    path: config.path.campaigns.callForDreamsPassions,
                    handle: {
                        title: ' ',
                        goBackTo: config.path.index
                    },
                    element: <Navigate to={config.path.campaigns.callForDreamsCampaignPage} />
                },
                {
                    path: config.path.campaigns.callForDreamsProgress,
                    handle: {
                        title: ' ',
                        goBackTo: config.path.index
                    },
                    element: <Navigate to={config.path.campaigns.callForDreamsCampaignPage} />
                },
                {
                    path: config.path.campaigns.callForDreamsEnroll,
                    handle: {
                        title: 'Înscriere',
                        goBackTo: config.path.campaigns.callForDreamsCampaignPage
                    },
                    element: (
                        <ProtectedRoute>
                            <CallForDreamsEnroll />
                        </ProtectedRoute>
                    )
                },

                // Real People Real Connections Campaign
                {
                    path: config.path.campaigns.realPeopleRealConnectionsCampaignPage,
                    handle: {
                        title: 'Oameni reali. Legături reale.',
                        goBackTo: config.path.index
                    },
                    element: (
                        <ProtectedRoute>
                            <RealPeopleRealConnections />
                        </ProtectedRoute>
                    )
                },

                {
                    path: config.path.campaignsStandardLayout.realPeopleRealConnectionsGame,
                    handle: {
                        title: ' ',
                        goBackTo: config.path.campaigns.realPeopleRealConnectionsCampaignPage
                    },
                    element: (
                        <ProtectedRoute>
                            <RealConnectionsGames />
                        </ProtectedRoute>
                    )
                },

                // Experience Voting LP from Real People Real Connections Campaign
                {
                    path: config.path.campaigns.realConnectionsVotingExperience,

                    handle: {
                        title: 'Alege experiența',
                        goBackTo: config.path.campaigns.realPeopleRealConnectionsCampaignPage
                    },

                    element: (
                        <ProtectedRoute>
                            <ExperienceVoting />
                        </ProtectedRoute>
                    )
                },

                {
                    path: config.path.campaigns.realConnectionsTopVotedExperiences,

                    handle: {
                        title: 'Alege o experiența',
                        goBackTo: config.path.campaigns.realPeopleRealConnectionsCampaignPage
                    },

                    element: (
                        <ProtectedRoute>
                            <TopVotedExperiences />
                        </ProtectedRoute>
                    )
                },

                {
                    path: config.path.campaigns.auroraExperience,
                    handle: {
                        title: 'Aurora Experience',
                        goBackTo: config.path.index
                    },
                    element: (
                        <ProtectedRoute>
                            <AuroraExperience />
                        </ProtectedRoute>
                    )
                },

                {
                    path: config.path.campaigns.liveTicketing,
                    handle: {
                        title: 'Together X',
                        goBackTo: config.path.index
                    },
                    element: (
                        <ProtectedRoute>
                            <LiveTicketing />
                        </ProtectedRoute>
                    )
                },
                {
                    path: config.path.campaigns.liveTicketingPublic,
                    element: <OutsideClubTickets />
                },
                {
                    path: config.path.campaigns.liveTicketingBucharestConfirmation,
                    element: <BucharestConfirmation />
                },

                // Live ticketing voting
                {
                    path: config.path.campaigns.ticketingVoting,
                    handle: {
                        title: 'Alege experiența',
                        goBackTo: config.path.campaigns.liveTicketing
                    },
                    element: (
                        <ProtectedRoute>
                            <TicketingVoting />
                        </ProtectedRoute>
                    )
                },

                {
                    path: config.path.campaigns.ticketingTopVoted,

                    handle: {
                        title: 'Alege experiența',
                        goBackTo: config.path.campaigns.liveTicketing
                    },

                    element: (
                        <ProtectedRoute>
                            <TicketingTopVoted />
                        </ProtectedRoute>
                    )
                },

                // Iqos Iluma Neon Purple Prelaunch
                {
                    path: config.path.campaigns.prelaunchIlumaNeonPurple,
                    handle: {
                        title: 'Noua gamă IQOS ILUMA',
                        goBackTo: config.path.index
                    },
                    element: (
                        <ProtectedRoute>
                            <IlumaNeonPurplePrelaunch />
                        </ProtectedRoute>
                    )
                },

                // Old IQOS Club redirects
                {
                    path: '/provocarea-lunii',
                    element: <Navigate to={config.path.monthlyChallenge.index} />
                },
                {
                    path: config.path.oldRdwArticle,
                    handle: {
                        title: 'Noul IQOS Club',
                        goBackTo: config.path.campaigns.newFeatures
                    },
                    element: (
                        <Navigate to="/universul-iqos/inspiratie-inovatie/juriul-re-connect-a-ales-cele-trei-proiecte-finaliste-descopera-le-si-voteaza-preferatul" />
                    )
                },
                {
                    path: '/recompensele-mele/aniversare',
                    handle: {
                        title: 'Noul IQOS Club',
                        goBackTo: config.path.campaigns.newFeatures
                    },
                    element: <Navigate to={config.path.myRewards.index} />
                },
                {
                    path: '/beneficii',
                    handle: {
                        title: 'Noul IQOS Club',
                        goBackTo: config.path.campaigns.newFeatures
                    },
                    element: <Navigate to={config.path.howItWorks} />
                },
                {
                    path: '/recompensele-mele/aniversare-platinum',
                    handle: {
                        title: 'Noul IQOS Club',
                        goBackTo: config.path.campaigns.newFeatures
                    },
                    element: <Navigate to={config.path.myRewards.index} />
                },
                {
                    path: '/recompensele-mele/avansare-tier',
                    handle: {
                        title: 'Noul IQOS Club',
                        goBackTo: config.path.campaigns.newFeatures
                    },
                    element: <Navigate to={config.path.myRewards.index} />
                },

                // Not found
                {
                    path: '*',
                    element: <NotFound />
                },

                // Custom Error Page
                {
                    path: config.path.error,
                    element: <ErrorPage />
                },

                // Redirects To Iqos Universe
                {
                    path: 'iqos-hub/terea-fuse',
                    handle: {
                        goBackTo: config.path.iqosUniverse.index
                    },
                    element: (
                        <Navigate
                            to={`/universul-iqos/iqos-hub/te-asteapta-o-infuzie-de-nuante-si-senzatii-aparte-cu-noile-amarelo-fuse-si-wind-fuse`}
                        />
                    )
                },
                {
                    path: 'comunitate/living-room/e03-rolul-design-ului-living-room-talks-x-omid-ghannadi',
                    handle: {
                        title: 'Universul IQOS',
                        goBackTo: config.path.index
                    },
                    element: <Navigate to={config.path.iqosUniverse.index} />
                },
                {
                    path: 'comunitate/living-room/omid-ghannadi-in-the-living-room-talks',
                    handle: {
                        title: 'Universul IQOS',
                        goBackTo: config.path.index
                    },
                    element: <Navigate to={config.path.iqosUniverse.index} />
                }
            ],
            errorElement: <ErrorPage />
        }
    ],
    { basename: process.env.REACT_APP_BASENAME }
);

export default router;
