import { RootState } from '@app/store';
import axiosRequest from '@utils/axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { updateUserAsync } from '@slices/userSlice';

export interface AddDeviceState {
    serialNumber: string;
    dateOfPurchase: string;
    status: 'idle' | 'loading' | 'failed' | 'success';
    response: {
        message: string;
    };
    error: any;
}

interface AxiosError {
    response: {
        data: {
            message: string;
        };
    };
}

const initialState: AddDeviceState = {
    serialNumber: '',
    dateOfPurchase: '',
    status: 'idle',
    response: {
        message: ''
    },
    error: {}
};

export const addDeviceAsync = createAsyncThunk(
    'devices/addDevice',
    async (values: object, { rejectWithValue, dispatch }) => {
        try {
            const response = await axiosRequest.post('/user/devices/store', values);

            // Update user after adding a device, to check for eligibility
            await dispatch(updateUserAsync());

            return response.data;
        } catch (e) {
            return rejectWithValue((e as AxiosError).response.data.message);
        }
    }
);

export const addDeviceSlice = createSlice({
    name: 'addDevice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(addDeviceAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(addDeviceAsync.fulfilled, (state, action) => {
                state.status = 'success';
                state.response = action.payload;
            })
            .addCase(addDeviceAsync.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    }
});

export const selectLoading = (state: RootState) => state.addDevice.status === 'loading';
export const selectSuccess = (state: RootState) => state.addDevice.status === 'success';
export const selectFailed = (state: RootState) => state.addDevice.status === 'failed';
export const selectError = (state: RootState) => state.addDevice.error;

export default addDeviceSlice.reducer;
