import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axiosRequest from '@utils/axios';
import { message } from 'antd';

export interface LikeState {
    status: 'idle' | 'loading' | 'failed';
    data: {};
    error: any;
}

interface AxiosError {
    response: {
        data: {
            message: string;
        };
    };
}

const initialState: LikeState = {
    status: 'idle',
    data: {},
    error: {}
};

export const likeEntry = createAsyncThunk(
    'monthlyChallenge/likeEntry',
    async (
        values: { alreadyLiked: boolean; challenge_id: number; id: number },
        { rejectWithValue }
    ) => {
        try {
            let response;
            const endpoint = `/challenges/${values.challenge_id}/registrations/${values.id}/likes`;

            if (values.alreadyLiked) {
                response = await axiosRequest.delete(endpoint);
            } else {
                response = await axiosRequest.post(endpoint);
            }

            return response.data.data;
        } catch (e) {
            return rejectWithValue((e as AxiosError).response.data.message);
        }
    }
);

export const likeSlice = createSlice({
    name: 'like',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(likeEntry.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(likeEntry.fulfilled, (state, action) => {
                state.status = 'idle';
                state.data = action.payload;
            })
            .addCase(likeEntry.rejected, (state, action) => {
                state.status = 'failed';
                message.error(`${action.payload}`, 10);
                state.error = action.payload;
            });
    }
});

export default likeSlice.reducer;
