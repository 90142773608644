import { useState } from 'react';

//Styles
import styles from './JazzInTheParkCelebration.module.scss';

//Components
import ArticleHeader from '@containers/IQOSUniverse/components/ArticleHeader';
import ArticleImage from '@containers/IQOSUniverse/components/ArticleImage';
import ArticleContainer from '@containers/IQOSUniverse/components/ArticleContainer';
import VideoModal from '@components/VideoModal';

//Assets
import image1 from './assets/img_02.png';
import image1Desktop from './assets/image1Desktop.png';
import videoThumbnail from './assets/video_thumbnail_1.png';

//Helpers
import classNames from 'classnames';

const JazzInTheParkCelebration = () => {
    const [toggleModal, setToggleModal] = useState(false);
    const [toggleModal1, setToggleModal1] = useState(false);
    const [toggleModal2, setToggleModal2] = useState(false);

    return (
        <div className={styles.wrapper}>
            <ArticleHeader />

            <div className="content">
                <h3>Ne-am extins orizonturile muzicale</h3>

                <p>
                    Între 1 și 4 septembrie la Jazz in the Park în Cluj ne-am lăsat cuceriți de
                    mozaicul de experiențe, genuri muzicale și personalități. Am ascultat combinații
                    surprinzătoare între rap și techno, reggae și muzică clasică și desigur, am
                    descoperit jazz-ul în toate formele lui.
                </p>
            </div>

            <ArticleImage cover={true} mobileImage={image1} image={image1Desktop} />

            <div className="content">
                <h3>Ne-am relaxat la IQOS Lounge</h3>

                <p>
                    Pe lângă relaxarea dintre performance-uri pe care ne-a oferit-o, IQOS Lounge
                    ne-a surprins cu proiecții interactive, experiențe muzicale senzoriale,
                    instalații care au îmbinat emoția și tehnologia și, bineînțeles, photo corners
                    instagramabile.
                </p>
            </div>

            <ArticleContainer background={'#34303D'} textColor={'#F6F4F0'}>
                <div className={classNames(styles.videoSubtitle, 'content')}>
                    <ArticleImage
                        isVideo
                        mobileImage={videoThumbnail}
                        image={videoThumbnail}
                        onClick={() => setToggleModal(true)}
                    />

                    <VideoModal
                        toggleModal={toggleModal}
                        setToggleModal={() => setToggleModal(false)}
                        video="751252222"
                    />

                    <h3>Play și descoperă atmosfera festivalului</h3>

                    <p>
                        Chiar dacă ai fost acolo sau nu, am filmat cele mai tari momente, ca să-ți
                        oferim șansa să vezi și tu cum a fost. Am descoperit împreună tot ce-i mai
                        frumos din jazz și am creat amintiri de care ne vom bucura mult timp de acum
                        înainte.
                    </p>

                    <p className={styles.videoSubText}>
                        În plus, poți urmări și livestreamurile făcute de noi pentru membrii
                        comunității IQOS Club.
                    </p>
                </div>
            </ArticleContainer>

            <ArticleContainer background={'#00D1D2'}>
                <div className="content">
                    <h3>Livestream ziua 1: Iorga</h3>

                    <ArticleImage
                        isVideo
                        mobileImage={videoThumbnail}
                        image={videoThumbnail}
                        onClick={() => setToggleModal1(true)}
                    />

                    <VideoModal
                        toggleModal={toggleModal1}
                        setToggleModal={() => setToggleModal1(false)}
                        video="745473431"
                    />
                </div>
            </ArticleContainer>

            <ArticleContainer>
                <div className="content">
                    <h3>Livestream ziua 2: Ezirk</h3>

                    <ArticleImage
                        isVideo
                        mobileImage={videoThumbnail}
                        image={videoThumbnail}
                        onClick={() => setToggleModal2(true)}
                    />

                    <VideoModal
                        toggleModal={toggleModal2}
                        setToggleModal={() => setToggleModal2(false)}
                        video="745834715"
                    />
                </div>
            </ArticleContainer>
        </div>
    );
};

export default JazzInTheParkCelebration;
