import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@app/store';
import axiosRequest from '@utils/axios';

// Tracking
import { TagManager, TagEvents } from '@utils/GTM';

export interface RecommendState {
    status: 'idle' | 'loading' | 'success' | 'failed';
    response: object;
    error: any;
}

interface AxiosError {
    response: {
        data: {
            errors: object;
            message: string;
        };
    };
}

const initialState: RecommendState = {
    status: 'idle',
    response: {},
    error: {}
};

export const recommendAsync = createAsyncThunk(
    'recommendForm/recommend',
    async (values: object, { rejectWithValue }) => {
        try {
            const response = await axiosRequest.post('/referrals/store', values);
            TagManager.dataLayer(TagEvents.recommendSuccesForm);
            return response.data;
        } catch (e) {
            return rejectWithValue((e as AxiosError).response.data);
        }
    }
);

export const recommend = createSlice({
    name: 'recommend',
    initialState,
    reducers: {
        reset: () => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(recommendAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(recommendAsync.fulfilled, (state, action) => {
                state.status = 'success';
                state.response = action.payload;
            })
            .addCase(recommendAsync.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(resetState, () => initialState);
    }
});

export const resetState = createAction('REVERT_ALL');
export const selectRecommendLoading = (state: RootState) => state.recommend.status === 'loading';
export const selectRecommendSuccess = (state: RootState) => state.recommend.status === 'success';
export const selectRecommendFailed = (state: RootState) => state.recommend.status === 'failed';
export const selectRecommendError = (state: RootState) => state.recommend.error;

export default recommend.reducer;
