/* eslint-disable no-useless-computed-key */
import axiosRequest from '@utils/axios';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
    rewardTemplates,
    sectionsTemplates,
    sectionTemplatesExpired
} from '@containers/MyRewards/data';
import dayjs from '@utils/dayjs';
import store from 'store';
import { config } from '@utils/config';

const userData = store.get(config.user.user);

export interface UserRewardsState {
    status: 'idle' | 'loading' | 'failed';
    sections: [
        {
            title: string;
            type: string;
            rewards: [];
        }
    ];
    filteredSections: [
        {
            title: string;
            type: string;
            rewards: [];
        }
    ];
    latestRewards: any[];
    countRewards: number;
    unclaimedClubRewards: number;
    selectedReward: Reward | null;
    partnersVoucher: any;
    offerVoucher: any;
    redeemSuccess: boolean;
    voucherStock: number;
    voucherHash: string;
    error: any;
}

interface AxiosError {
    response: {
        data: {
            message: string;
        };
    };
}

const initialState: UserRewardsState = {
    status: 'idle',
    sections: [
        {
            title: '',
            type: '',
            rewards: []
        }
    ],
    filteredSections: [
        {
            title: '',
            type: '',
            rewards: []
        }
    ],
    latestRewards: [],
    countRewards: 0,
    unclaimedClubRewards: 0,
    selectedReward: null,
    partnersVoucher: null,
    offerVoucher: null,
    redeemSuccess: false,
    voucherStock: 0,
    voucherHash: '',
    error: {}
};

export interface Reward {
    code: string | null;
    created_at: string | null;
    expired_at: string | null;
    gift: any;
    selectedRedeemOption: string | null;
    status: string;
    campaign_slug: string;
    telcor: any;
    user_redemption_id: number;
}

const updateRewardsFromTemplate = (rewards: any) => {
    return rewards.map((reward: any) => {
        let template;

        const isRealConnectionTicket = reward?.gift?.name?.includes('VIP la concertul anului');
        if (isRealConnectionTicket) {
            // IC50M-1867: Added exception in case this weekly prize contains ticket
            template = rewardTemplates['real-connection-premiu-saptamanal:ticket'];
        } else {
            template = rewardTemplates[reward.campaign_slug];
        }

        return {
            ...reward,
            ...template
        };
    });
};

export const getReward = createAsyncThunk(
    `user/rewards/telcor/show/`,
    async (rewardHash: string, { rejectWithValue }) => {
        try {
            const response = await axiosRequest.get(`user/rewards/telcor/${rewardHash}`);

            return response.data;
        } catch (e) {
            return rejectWithValue((e as AxiosError).response.data);
        }
    }
);

export const getVoucherStock = createAsyncThunk(
    `user/gifts-redemption/gifts-stock`,
    async (slug: string, { rejectWithValue }) => {
        try {
            const response = await axiosRequest.get(`user/gifts-redemption/${slug}/gifts-stock`);
            return response.data.stock;
        } catch (e) {
            return rejectWithValue((e as AxiosError).response.data);
        }
    }
);

export const redeemVoucher = createAsyncThunk(
    `user/gifts-redemption/redeem-telcor-voucher`,
    async (slug: string, { rejectWithValue }) => {
        try {
            const response = await axiosRequest.post(
                `user/gifts-redemption/${slug}/redeem-telcor-voucher`
            );
            return response.data.response.content.rewardHash;
        } catch (e) {
            return rejectWithValue((e as AxiosError).response.data);
        }
    }
);

export const chooseReward = createAsyncThunk(
    'user/rewards/telcor/choose',
    async (values: { reward_hash: string; redeem_option: string }, { rejectWithValue }) => {
        try {
            let res = await axiosRequest.put(`user/rewards/telcor/${values.reward_hash}`, {
                redeem_option: values.redeem_option
            });

            return {
                redeemOption: values.redeem_option,
                content: res.data.response.content
            };
        } catch (e) {
            return rejectWithValue((e as AxiosError).response.data);
        }
    }
);

export const getAvailableRewards = createAsyncThunk(
    'user/rewards/available',
    async (type: string) => {
        try {
            const response = await axiosRequest.get(`user/rewards/${type}`);

            let availableRewards: typeof response.data = { ...response.data };
            let keys = Object.keys(availableRewards);
            let latestRewards: any[] = [];
            let templateSections =
                type === 'inactive' ? [...sectionTemplatesExpired] : [...sectionsTemplates];

            let sections = templateSections.map((s) => {
                let section = { ...s };
                keys.forEach((key: any) => {
                    if (key === section.type) {
                        const endSections = userData?.tier !== 'Silver' ? [...section.rewards] : [];

                        const allRewards = updateRewardsFromTemplate(availableRewards[key]);

                        let idxrwd = allRewards.findIndex((rwd: any) => rwd.status === 'Redeemed');

                        if (idxrwd !== -1) {
                            allRewards.splice(idxrwd, 0, ...endSections);
                        } else {
                            allRewards.push(...endSections);
                        }

                        section.rewards = [...allRewards];
                    }
                });

                latestRewards.push(...section?.rewards);
                return section;
            });

            const countRewards = latestRewards.length;

            // we need first 3 rewards for homepage rewards section

            latestRewards = latestRewards
                .filter(
                    (reward: any) =>
                        reward.campaign_slug !== 'redefinim-experienta-gustului' &&
                        reward.status !== 'Redeemed'
                )
                .splice(0, 3);

            // Set TRADE IN CARD FIRST

            return {
                updatedSections: sections,
                latestRewards: latestRewards,
                unclaimedClubRewards: availableRewards?.unredeemed_gifts_and_vouchers,
                countRewards: countRewards
            };
        } catch (e) {
            return (e as AxiosError).response.data.message;
        }
    }
);

export const userRewardsSlice = createSlice({
    name: 'userRewards',
    initialState,
    reducers: {
        setSuccess: (state, action) => {
            state.redeemSuccess = action.payload;
        },

        resetSelectedReward: (state, action) => {
            state.selectedReward = {
                ...action.payload.selectedReward,
                status: action.payload.status
            };
            state.error = {};
        },

        setFilteredSections: (state, action) => {
            state.filteredSections = action.payload.filteredSections;
        },
        resetState: (state) => {
            return { ...initialState, unclaimedClubRewards: state.unclaimedClubRewards };
        },

        setPartnerVoucher: (state, action) => {
            const response = [...action.payload.sections];

            let partnersVoucher = response.filter(
                (section: any) => section.type === 'partner_vouchers'
            );

            if (partnersVoucher.length > 0) {
                partnersVoucher = partnersVoucher[0].rewards.filter(
                    (reward: any) => reward.campaign_slug === action.payload.slug
                );

                if (partnersVoucher.length > 0) {
                    if (dayjs(partnersVoucher[0].expired_at).isAfter(dayjs())) {
                        state.partnersVoucher = partnersVoucher[0];
                    } else {
                        state.partnersVoucher = null;
                    }
                } else {
                    state.partnersVoucher = null;
                }
            } else {
                state.partnersVoucher = null;
            }
        },

        setOfferVoucher: (state, action) => {
            const response = [...action.payload.sections];
            let offerVoucher = response.filter((section: any) => section.type === 'offers');

            if (offerVoucher.length > 0) {
                offerVoucher = offerVoucher[0].rewards.filter(
                    (reward: any) => reward.campaign_slug === action.payload.slug
                );

                if (offerVoucher.length > 0) {
                    if (dayjs(offerVoucher[0].expired_at).isAfter(dayjs())) {
                        state.offerVoucher = offerVoucher[0];
                    } else {
                        state.offerVoucher = 'Unavailable';
                    }
                } else {
                    state.offerVoucher = null;
                }
            } else {
                state.offerVoucher = null;
            }
        }
    },
    extraReducers: (builder) => {
        builder
            // Get Available Rewards
            .addCase(getAvailableRewards.pending, (state) => {
                state.status = 'loading';
            })

            .addCase(getAvailableRewards.fulfilled, (state, action: any) => {
                state.status = 'idle';
                state.sections = action.payload.updatedSections;
                state.filteredSections = action.payload.updatedSections;
                state.latestRewards = action.payload.latestRewards;
                state.unclaimedClubRewards = action.payload.unclaimedClubRewards
                    ? action.payload.unclaimedClubRewards
                    : state.unclaimedClubRewards;
                state.countRewards = action.payload.countRewards;
            })

            .addCase(getAvailableRewards.rejected, (state, action: any) => {
                state.status = 'failed';
                state.error = action.payload;
            })

            // Get reward
            .addCase(getReward.pending, (state) => {
                state.status = 'loading';
            })

            .addCase(getReward.fulfilled, (state, action) => {
                state.status = 'idle';

                state.selectedReward = action.payload;
            })
            .addCase(getReward.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })

            // ChooseReward
            .addCase(chooseReward.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(chooseReward.fulfilled, (state, action) => {
                state.status = 'idle';
                if (action.payload.redeemOption === 'offline') {
                    state.redeemSuccess = true;
                }

                if (state.selectedReward) {
                    state.selectedReward = {
                        ...state.selectedReward,
                        telcor: {
                            ...state.selectedReward.telcor,
                            expirationDate: action.payload.content.expirationDate,
                            selectedRedeemOption: action.payload.redeemOption
                        },
                        expired_at: action.payload.content.expirationDate,
                        status: 'RedeemedVoucher'
                    };
                }
            })
            .addCase(chooseReward.rejected, (state, action) => {
                state.status = 'failed';
                state.redeemSuccess = false;
                state.error = action.payload;
            })

            // GetVoucherStock

            .addCase(getVoucherStock.pending, (state) => {
                state.status = 'loading';
            })

            .addCase(getVoucherStock.fulfilled, (state, action) => {
                state.status = 'idle';

                state.voucherStock = action.payload;
            })
            .addCase(getVoucherStock.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })

            // Redeem Special Voucher
            .addCase(redeemVoucher.pending, (state) => {
                state.status = 'loading';
            })

            .addCase(redeemVoucher.fulfilled, (state, action) => {
                state.status = 'idle';

                state.voucherHash = action.payload;
            })
            .addCase(redeemVoucher.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    }
});

export const {
    setSuccess,
    resetSelectedReward,
    resetState,
    setFilteredSections,
    setPartnerVoucher,
    setOfferVoucher
} = userRewardsSlice.actions;

export default userRewardsSlice.reducer;
