import React, { useEffect, useState } from 'react';
import styles from '@containers/IQOSUniverse/articles/ClubZeroBill/ClubZeroBill.module.scss';
import { IVideoModalQuiz } from '@containers/IQOSUniverse/components/VideoModalQuiz/interface';
import classnames from 'classnames';

// Components
import BaseModal from '@components/Layout/BaseModal';
import RatingQuiz from '@containers/IQOSUniverse/components/RatingQuiz';
import VimeoPlayer from '@components/VimeoPlayer';
import VideoDisclaimer from '@containers/IQOSUniverse/components/VideoDisclaimer';

// Redux
import { useAppSelector } from '@app/hooks';
import dayjs from '@utils/dayjs';

const VideoModalQuiz = ({
    toggleModal,
    setToggleModal,
    setShowSuccess,
    showQuiz = true,
    quizVideo = true,
    lockScroll = true,
    customClass,
    customClassVideo,
    videoResponsive = true
}: IVideoModalQuiz) => {
    const { article } = useAppSelector((state) => state.iqosUniverse);
    const quiz = useAppSelector((state) => state.quiz?.quizQuestions?.quiz);

    const [showDisclaimer, toggleDisclaimer] = useState(false);
    const [isPastPercentage, setIsPastPercentage] = useState(false);
    const [hidePlayer, setHidePlayer] = useState(false);
    const [videoEnded, setVideoEnded] = useState(false);

    useEffect(() => {
        if (!article.completed_quiz && (article.quiz_points || article.points)) {
            toggleDisclaimer(true);
        }
    }, [article]);

    let playing = false;
    let progress = 0;
    const triggerMomentPercentage = 75; // 75%

    const handleTimeUpdate = (data: any) => {
        if (playing && data.seconds - progress <= 10) {
            if (data.seconds < progress) {
                //don't update progress if user seeked back
            } else {
                progress = data.seconds;
            }
        }

        const durationPercentage = (triggerMomentPercentage * data.duration) / 100;

        if (progress >= durationPercentage && !isPastPercentage) {
            setIsPastPercentage(true);
        }
    };

    useEffect(() => {
        if (videoEnded) {
            if (isPastPercentage && !article.completed_quiz && quizVideo) {
                setHidePlayer(true);
            } else {
                setTimeout(() => {
                    handleCloseModal();
                }, 1000);
            }
        }
    }, [videoEnded, isPastPercentage]);

    const handleCloseModal = () => {
        setToggleModal();
        setHidePlayer(false);
        setIsPastPercentage(false);
        toggleDisclaimer(false);
        setVideoEnded(false);
    };

    //TODO submit quiz automatically if showQuiz = false;

    return (
        <>
            <BaseModal
                size="large"
                maskClosable={true}
                isModalOpen={toggleModal}
                className={styles.videoModal}
                closeModal={() => handleCloseModal()}
                destroyOnClose={true}
                lockBodyScroll={lockScroll}
            >
                <div className={classnames(styles.modalContent, customClass)}>
                    {!article.completed_quiz &&
                        showDisclaimer &&
                        dayjs(quiz?.end_date).isAfter(dayjs()) &&
                        quizVideo && (
                            <VideoDisclaimer handleClose={() => toggleDisclaimer(false)} />
                        )}

                    {isPastPercentage && article.quiz_slug && showQuiz && quizVideo && (
                        <div className={classnames(styles.rating, hidePlayer && styles.padding)}>
                            <RatingQuiz
                                handleSuccess={setShowSuccess}
                                quizSlug={article.quiz_slug}
                            />
                        </div>
                    )}

                    {!hidePlayer && (
                        <VimeoPlayer
                            className={classnames(styles.player, customClassVideo)}
                            video={article.video_link}
                            options={{
                                autoplay: false,
                                responsive: videoResponsive
                            }}
                            onPlay={() => (playing = true)}
                            onTimeUpdate={(progress) => handleTimeUpdate(progress)}
                            onPause={() => (playing = false)}
                            onEnd={() => setVideoEnded(true)}
                        />
                    )}
                </div>
            </BaseModal>
        </>
    );
};

export default VideoModalQuiz;
