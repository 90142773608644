import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import styles from './ArticleQuiz.module.scss';
import { IArticleQuizProps } from './interface';

// Helpers
import dotFormat from '@utils/numberFormat';
import dayjs from '@utils/dayjs';

// Components
import { Form, Progress } from 'antd';
import Radio from '@components/Quiz/Radio';
import Input from '@components/Quiz/Input';
import Button from '@components/Layout/Button';
import RewardBadge from '@components/RewardBadge';
import BaseLoader from '@components/Layout/BaseLoader';
import transformAnswers from '@components/Quiz/transformAnswers';

// Assets
import { FPIcon } from '@assets/icons';
import originalTaste from '@containers/IQOSUniverse/articles/TereaFuseLine/assets/originalTaste.svg';

// Redux
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { updateUserAsync } from '@slices/userSlice';
import { getQuiz, postQuizQuestions, resetQuiz } from '@components/Quiz/QuizSlice';
import { markArticleAsRead } from '@containers/IQOSUniverse/IQOSUniverseSlice';
import { config } from '@utils/config';
import { useNavigate } from 'react-router-dom';
import {
    Badge,
    getBadges,
    setBadge,
    toggleModal
} from '@containers/AboutBadges/pages/AboutBadges/AboutBadgesSlice';

const QuizComponents: { [key: string]: React.JSXElementConstructor<any> } = {
    Radio: Radio,
    Input: Input
};

const ArticleQuiz = ({ slug }: IArticleQuizProps) => {
    const [form] = Form.useForm();
    const dispatch = useAppDispatch();

    const navigate = useNavigate();

    const { quizQuestions, status } = useAppSelector((state) => state.quiz);

    const [disableButton, setDisableButton] = useState(true);
    const [isCustomArticleQuiz, setIsCustomArticleQuiz] = useState(false);

    useEffect(() => {
        dispatch(getQuiz({ slug: slug, statistics: true }));

        return () => {
            dispatch(resetQuiz());
        };
    }, [slug]);

    const fuseLineArticleQuizSlug =
        'citesti-articolul-te-asteapta-o-infuzie-de-nuante-si-senzatii-aparte-cu-noile-amarelo-fuse-si-wind-fuse';

    useEffect(() => {
        if (slug === fuseLineArticleQuizSlug) {
            setIsCustomArticleQuiz(true);
        }
    }, []);

    let sum = 0;
    let statisticsLength = quizQuestions?.quiz?.steps[0]?.questions[0]?.statistics?.length;

    const fuseLineArticleSlug =
        'te-asteapta-o-infuzie-de-nuante-si-senzatii-aparte-cu-noile-amarelo-fuse-si-wind-fuse';

    const submitQuiz = async () => {
        form.validateFields().then(async (values) => {
            let currentStep: {
                quiz_slug: string;
                step_id: number;
                answers: Array<{
                    quiz_question_id: number;
                    values: Array<{ value: string | unknown | []; custom_value?: string }>;
                }>;
            } = {
                quiz_slug: quizQuestions?.quiz?.slug,
                step_id: quizQuestions?.quiz?.steps[0]?.id,
                answers: transformAnswers(values)
            };

            await dispatch(postQuizQuestions(currentStep)).then((response) => {
                if (response.meta.requestStatus === 'fulfilled') {
                    dispatch(
                        markArticleAsRead({
                            slug: isCustomArticleQuiz ? fuseLineArticleSlug : slug
                        })
                    );
                }

                dispatch(updateUserAsync());
            });
        });
    };

    const CompletedState = () => {
        return (
            <section className={styles.congratsContainer}>
                <div
                    className={classnames(
                        styles.completed,
                        isCustomArticleQuiz && styles.customCongrats
                    )}
                >
                    {isCustomArticleQuiz ? (
                        <>
                            <h3>Felicitări!</h3>

                            <p>
                                Ai parcurs articolul și ai acumulat{' '}
                                {dotFormat(quizQuestions?.quiz?.points)} FP! În plus, ai mai făcut
                                un pas înspre obținerea badge-ului{' '}
                                <a onClick={() => openBadge()}>Original Taste Pioneer</a>.
                            </p>
                        </>
                    ) : (
                        <h3>
                            Felicitări, ai acumulat {dotFormat(quizQuestions?.quiz?.points)} FP
                            pentru că ai citit articolul.
                        </h3>
                    )}

                    <div className={styles.reward}>
                        <FPIcon />
                        <span>
                            <b>+{quizQuestions?.quiz?.points}</b> <br />
                            Forward Points
                        </span>
                    </div>
                </div>
            </section>
        );
    };

    const SuccessState = () => {
        return (
            <div className={styles.completed}>
                <p className={styles.title}>{quizQuestions?.quiz?.steps[0]?.questions[0]?.title}</p>

                <p className={styles.description}>
                    lată cum au răspuns ceilalți membri ai comunității:
                </p>

                <div className={styles.poll}>
                    {quizQuestions?.quiz?.steps[0]?.questions[0]?.statistics
                        ?.map(({ answer, statistic }, i) => {
                            if (i === (statisticsLength || 0) - 1) {
                                return { answer, statistic: 100 - sum };
                            }
                            statistic = Math.round(Number(statistic)).toString();
                            sum = sum + Number(statistic);

                            return { answer, statistic };
                        })
                        .map((r, key) => (
                            <div key={key} className={styles.result}>
                                <span
                                    className={classnames(
                                        styles.name,
                                        r?.answer ===
                                            quizQuestions?.quiz?.steps[0]?.questions[0]?.answer
                                                ?.values[0]?.value && styles.mostVoted
                                    )}
                                >
                                    {r.answer}
                                </span>

                                <Progress
                                    percent={Math.round(Number(r.statistic))}
                                    status="active"
                                    strokeColor={
                                        r?.answer ===
                                        quizQuestions?.quiz?.steps[0]?.questions[0]?.answer
                                            ?.values[0]?.value
                                            ? '#00D1D2'
                                            : '#C1C0C4'
                                    }
                                />
                            </div>
                        ))}
                </div>
            </div>
        );
    };

    if (status === 'loading' || !quizQuestions) {
        return <BaseLoader size="large" />;
    }

    const onValuesChange = (allValues: any) => {
        if (allValues !== undefined) {
            setDisableButton(false);
        } else {
            setDisableButton(true);
        }
    };

    const openBadge = () => {
        dispatch(
            getBadges({
                page: 1,
                per_page: 50
            })
        ).then((response) => {
            let originalTasteBadge = response.payload.badges.filter(
                (badge: (typeof response.payload.badges)[0]) =>
                    badge.label === 'BDG-ORIGINAL-TASTE-PIONEER'
            )[0];
            dispatch(setBadge(originalTasteBadge));
            dispatch(toggleModal(true));
        });
    };

    return slug !==
        'juriul-re-connect-a-ales-cele-trei-proiecte-finaliste-descopera-le-si-voteaza-preferatul' ||
        quizQuestions?.is_completed ? (
        <>
            <section
                className={classnames(
                    styles.wrapper,
                    quizQuestions.quiz?.steps[0]?.questions[0]?.displayable_type !== 'Radio' &&
                        quizQuestions?.is_completed &&
                        styles.hideWrapper
                )}
            >
                {quizQuestions?.is_completed ? (
                    <SuccessState />
                ) : (
                    slug !==
                        'juriul-re-connect-a-ales-cele-trei-proiecte-finaliste-descopera-le-si-voteaza-preferatul' && (
                        <>
                            <p className={styles.title}>
                                {quizQuestions?.quiz?.steps[0]?.questions[0]?.title}
                            </p>

                            {quizQuestions?.quiz?.points > 0 &&
                                dayjs(quizQuestions?.quiz?.end_date).isAfter(dayjs()) && (
                                    <div
                                        className={classnames(
                                            styles.details,
                                            isCustomArticleQuiz && styles.customDetails
                                        )}
                                    >
                                        <p className={styles.description}>
                                            {isCustomArticleQuiz && (
                                                <p>
                                                    Trimite răspunsul tău și primești automat{' '}
                                                    {quizQuestions?.quiz?.points}
                                                    FP. Mai mult, vei fi cu un pas mai aproape de
                                                    obținerea badge-ului{' '}
                                                    <a onClick={() => openBadge()}>
                                                        Original Taste Pioneer
                                                    </a>
                                                    !
                                                </p>
                                            )}
                                        </p>
                                        <div className={styles.badgesContainer}>
                                            <RewardBadge
                                                absolute={false}
                                                backgroundColor="#FFFDFB"
                                                points={quizQuestions?.quiz?.points}
                                                className={styles.FpIcon}
                                            />
                                            {isCustomArticleQuiz && (
                                                <div
                                                    onClick={() => openBadge()}
                                                    className={styles.badge}
                                                >
                                                    <img
                                                        src={originalTaste}
                                                        alt={'original taste badge'}
                                                    />
                                                    <p>Original Taste Pioneer</p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}

                            <div className={styles.form}>
                                <Form form={form} onValuesChange={onValuesChange}>
                                    {quizQuestions?.quiz?.steps[0]?.questions?.map((item: any) => {
                                        if (
                                            typeof QuizComponents[item.displayable_type] !==
                                            'undefined'
                                        ) {
                                            return React.createElement(
                                                QuizComponents[item.displayable_type],
                                                {
                                                    key: item.id,
                                                    data: item,
                                                    noTitle: true
                                                }
                                            );
                                        }
                                    })}
                                </Form>
                            </div>

                            <div
                                className={classnames(
                                    isCustomArticleQuiz && styles.buttonsContainer
                                )}
                            >
                                <Button
                                    type="solid"
                                    color="dark"
                                    hover="turquoise"
                                    onClick={submitQuiz}
                                    label="Trimite răspunsul tău"
                                    className={styles.submitButton}
                                    disabled={disableButton}
                                />
                                {isCustomArticleQuiz && (
                                    <Button
                                        type="text"
                                        color="turquoise"
                                        hover="dark"
                                        label="Vezi badge-urile tale"
                                        className={styles.badgesButton}
                                        href={config.path.badgesCollection}
                                    />
                                )}
                            </div>
                        </>
                    )
                )}
            </section>

            {quizQuestions?.is_completed &&
                quizQuestions.quiz.points > 0 &&
                dayjs(quizQuestions.quiz.end_date).isAfter(dayjs()) && <CompletedState />}
        </>
    ) : (
        <></>
    );
};

export default ArticleQuiz;
