export const UserProfileIcon = () => {
    return (
        <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_1310_9660)">
                <path
                    d="M11 11C13.0331 11 14.6812 9.35183 14.6812 7.31874C14.6812 5.28566 13.0331 3.63751 11 3.63751C8.96691 3.63751 7.31877 5.28566 7.31877 7.31874C7.31877 9.35183 8.96691 11 11 11Z"
                    stroke="#C4C3C6"
                    strokeWidth="1.7"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M18 18.3624C17.5245 16.8796 16.5903 15.5861 15.3323 14.6683C14.0742 13.7506 12.5572 13.2561 11 13.2561C9.44278 13.2561 7.92578 13.7506 6.66772 14.6683C5.40966 15.5861 4.47553 16.8796 4 18.3624"
                    stroke="#C4C3C6"
                    strokeWidth="1.7"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <rect
                    x="0.855"
                    y="0.855"
                    width="20.29"
                    height="20.29"
                    rx="10.145"
                    stroke="#C4C3C6"
                    strokeWidth="1.71"
                />
            </g>
            <defs>
                <clipPath id="clip0_1310_9660">
                    <rect width="22" height="22" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
