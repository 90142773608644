import styles from './DifferentTogether.module.scss';

// Assets
import img1 from './assets/differentTogether1.png';
import img2 from './assets/differentTogether2.png';
import image1Desktop from './assets/image1Desktop.png';
import image2Desktop from './assets/image2Desktop.png';

// Components
import ArticleImage from '@containers/IQOSUniverse/components/ArticleImage';
import ArticleHeader from '@containers/IQOSUniverse/components/ArticleHeader';
import ArticleContainer from '@containers/IQOSUniverse/components/ArticleContainer';

const DifferentTogether = () => {
    return (
        <section className={styles.wrapper}>
            <ArticleHeader />

            <div className="content">
                <h3>O comunitate care inspiră.</h3>

                <p>
                    De-a lungul timpului ne-ai inspirat să evoluăm și să reinventăm dispozitivul
                    IQOS 3 DUO prin ediții limitate care să vorbească despre utilizatorii din
                    comunitate. Așa au apărut variantele Copper Red, Lucid Teal, Frosted Red sau
                    Moonlight Silver. Ne pregătim pentru o nouă lansare, o nouă ediție limitată,
                    care se adaugă pe această listă. Un nume inspirat de voi. De fiecare dintre voi.
                </p>
            </div>

            <ArticleImage cover image={image1Desktop} mobileImage={img1} />

            <ArticleContainer background={'#F6F4F0'}>
                <div className="content">
                    <h3>Dincolo de diferențe, avem același vis.</h3>

                    <p>
                        Trăim diferit. Simțim diferit. Ne bucurăm diferit. Iubim diferit. Dar mai
                        presus de toate aceste lucruri, suntem o singură comunitate și avem același
                        vis: un viitor mai bun, fără fum și fără scrum.
                    </p>
                </div>
            </ArticleContainer>

            <div className={styles.articleWrapper}>
                <ArticleContainer background={'#00D1D2'}>
                    <div className="content">
                        <ArticleImage
                            title="Frumusețea diferențelor, într-o nouă ediție limitată."
                            subtitle="Ne pregătim să sărbătorim toate diferențele care ne fac unici printr-o nouă ediție limitată a dispozitivului IQOS 3 DUO. Una cu totul și cu totul specială. Fii activ în IQOS Club în continuare și te vei număra printre primii utilizatori care descoperă surpriza pe care v-am pregătit-o."
                            image={image2Desktop}
                            mobileImage={img2}
                        />
                    </div>
                </ArticleContainer>
            </div>
        </section>
    );
};

export default DifferentTogether;
