import React, { useState } from 'react';
import styles from './IQOSAnswers.module.scss';

// Assets
import { FPIcon } from '@assets/icons';

// Components
import EpisodeHeader from '@containers/IQOSUniverse/components/EpisodeHeader';
import Button from '@components/Layout/Button';

import { config } from '@utils/config';

// Redux
import { useAppSelector } from '@app/hooks';
import dayjs from '@utils/dayjs';

const IQOSAnswers = () => {
    const [showSuccess, setShowSuccess] = useState(false);

    const { article } = useAppSelector((state) => state.iqosUniverse);

    const CompletedState = () => {
        return (
            <>
                {(article.points || article.quiz_points) &&
                dayjs(article.quiz_end_date).isAfter(dayjs()) ? (
                    <section className={styles.congratsContainer}>
                        <div className={styles.completed}>
                            <h3>
                                Felicitări, ai acumulat {article.points || article.quiz_points} FP{' '}
                                pentru că ai urmărit episodul.
                            </h3>

                            <div className={styles.reward}>
                                <FPIcon />
                                <span>
                                    <b>+{article.points || article.quiz_points}</b> <br />
                                    Forward Points
                                </span>
                            </div>
                        </div>
                    </section>
                ) : null}
            </>
        );
    };

    return (
        <section className={styles.wrapper}>
            <EpisodeHeader handleSuccess={() => setShowSuccess(true)} />

            <div className="content">
                <div
                    className={styles.subtitle}
                    dangerouslySetInnerHTML={{ __html: article.subtitle }}
                />

                {article.episode === 'Episodul 4' && (
                    <Button
                        className={styles.cta}
                        href={`${config.path.iqosUniverse.iqosHub}${config.path.pwaPage}`}
                    >
                        Citește și aici cum îți instalezi PWA
                    </Button>
                )}
            </div>

            {(showSuccess || article.completed_quiz) && <CompletedState />}
        </section>
    );
};

export default IQOSAnswers;
