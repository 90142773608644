export const RemoveImageIcon = () => {
    return (
        <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M14.2972 24.7645C20.1223 24.7645 24.8445 20.0424 24.8445 14.2173C24.8445 8.39221 20.1223 3.67004 14.2972 3.67004C8.47216 3.67004 3.75 8.39221 3.75 14.2173C3.75 20.0424 8.47216 24.7645 14.2972 24.7645Z"
                fill="#00D1D2"
            />
            <path
                d="M18.25 9.67004L9.75 18.17"
                stroke="#212121"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M18.25 18.17L9.75 9.67004"
                stroke="#212121"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M14.25 26.92C21.2916 26.92 27 21.2117 27 14.17C27 7.12841 21.2916 1.42004 14.25 1.42004C7.20837 1.42004 1.5 7.12841 1.5 14.17C1.5 21.2117 7.20837 26.92 14.25 26.92Z"
                stroke="#212121"
                strokeWidth="1.62963"
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
        </svg>
    );
};
