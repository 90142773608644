export const LeaderBoardBadge = () => {
    return (
        <svg
            width="30"
            height="31"
            viewBox="0 0 30 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.1341 19.0042L24.0931 24.1294L20.717 23.6805L19.4182 26.8285L15.9678 20.9672"
                fill="#00D1D2"
            />
            <path
                d="M21.1341 19.0042L24.0931 24.1294L20.717 23.6805L19.4182 26.8285L15.9678 20.9672"
                stroke="#34303D"
                strokeWidth="1.44444"
                strokeLinejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.9886 19.0037L6.02954 24.129L9.4057 23.68L10.705 26.8284L14.2771 21.09"
                fill="#00D1D2"
            />
            <path
                d="M8.9886 19.0037L6.02954 24.129L9.4057 23.68L10.705 26.8284L14.2771 21.09"
                stroke="#34303D"
                strokeWidth="1.44444"
                strokeLinejoin="round"
            />
            <path
                d="M15 21.2957C19.3983 21.2957 22.9638 17.7302 22.9638 13.3319C22.9638 8.93366 19.3983 5.36816 15 5.36816C10.6017 5.36816 7.03625 8.93366 7.03625 13.3319C7.03625 17.7302 10.6017 21.2957 15 21.2957Z"
                fill="#00D1D2"
                stroke="#34303D"
                strokeWidth="1.44444"
            />
            <path
                d="M15.0612 19.1444C18.3477 19.1444 21.0118 16.4802 21.0118 13.1938C21.0118 9.90735 18.3477 7.24316 15.0612 7.24316C11.7748 7.24316 9.1106 9.90735 9.1106 13.1938C9.1106 16.4802 11.7748 19.1444 15.0612 19.1444Z"
                fill="#FFFDFB"
            />
        </svg>
    );
};
