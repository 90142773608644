import styles from './IqosXDiplomaArta.module.scss';

// Helpers
import classNames from 'classnames';

// Assets
import image1 from './assets/image1.jpg';
import image1Mobile from './assets/image1Mobile.jpg';

import img3 from './assets/img3.jpg';
import image3Desktop from './assets/image3Desktop.png';

import imgSlide1 from './assets/xDiplomaSlide1.png';
import imgSlide2 from './assets/imgSlide2.png';

// Components
import Button from '@components/Layout/Button';
import ArticleImage from '@containers/IQOSUniverse/components/ArticleImage';
import ArticleHeader from '@containers/IQOSUniverse/components/ArticleHeader';
import ArticleContainer from '@containers/IQOSUniverse/components/ArticleContainer';

const IqosXDiplomaArta = () => {
    return (
        <section className={styles.wrapper}>
            <ArticleHeader isSlider={true} sliderImages={[imgSlide1, imgSlide2]} />

            <div className={classNames(styles.diplomaArtaContent, 'content')}>
                <h3>Facem loc ideilor noi în viața noastră. Cu IQOS.</h3>

                <p>
                    Doar împreună le putem da aripi artiștilor aflați la început de drum. Asta vom
                    face la Diploma, festivalul de artă contemporană, ce are loc între 7 – 16
                    octombrie la Combinatul Fondului Plastic. Remus Andrei Moldovan este unul dintre
                    artiștii care dau startul schimbării.
                </p>

                <p>
                    Absolvent al Facultății de Arte Decorative și Design, mizează pe experiența
                    personală pentru a-și crea un stil unic și ne propune un design original, care
                    reinterpretează valori consacrate ale brandului, precum progresul, inovația și
                    rolul pe care îl au acestea în comunitate.
                </p>
            </div>

            <ArticleImage cover image={image1} mobileImage={image1Mobile} />

            <ArticleContainer>
                <div className={classNames(styles.diplomaArtaContent, 'content')}>
                    <h3>Inspirați de artă, ți-am pregătit experiențe unice</h3>

                    <p>
                        Pe lângă lounge-ul confortabil IQOS în care te poți relaxa alături de alți
                        membri ai comunității, avem și alte surprize pentru tine. Prin intermediul
                        lor, te ajutăm să te bucuri de artă în orice moment.
                    </p>

                    <h3 className={styles.contentSubtitle}>
                        Vino la festival și descoperă-le pe toate!
                    </h3>
                </div>
            </ArticleContainer>

            <div className={styles.articleWrapper}>
                <ArticleContainer background={'#00D1D2'}>
                    <div className={classNames(styles.diplomaArtaContent, 'content')}>
                        <h3>IQOS x Diploma de purtat, în catalog</h3>

                        <ArticleImage image={image3Desktop} mobileImage={img3} />

                        <p className={styles.paragraphContent}>
                            Arta nu se admiră doar la un festival, ci te poate însoți zilnic. Tocmai
                            de aceea, alături de Remus Andrei Moldovan, am creat o piesă
                            vestimentară pentru catalogul IQOS. Cu un print ce simbolizează ideea de
                            comunitate și dialog, artistul reușește să dea o formă nouă valorilor
                            brandului.
                        </p>
                    </div>
                </ArticleContainer>
            </div>
        </section>
    );
};

export default IqosXDiplomaArta;
