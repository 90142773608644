import classnames from 'classnames';
import styles from './MaintenanceLilSolid.module.scss';

// Helpers
import { HeatCleanList } from './Lists/HeatCleanList';
import { BrushCleanList, BurshClean } from './Lists/BrushCleanList';
import { HeatsColorsList, HeatsColor } from './Lists/HeatsColorsList';

// Assets
import img1 from './assets/maintenance1.png';
import img1V1 from './assets/img1V1.png';
import img2 from './assets/maintenance2.png';
import img2M from './assets/img2Mobile.png';
import img3 from './assets/maintenance3.png';
import img3M from './assets/img3Mobile.png';
import img4 from './assets/maintenance4.png';
import img4M from './assets/img4Mobile.png';
import img01 from './assets/img01.png';
import img02 from './assets/img02.png';
import img03 from './assets/img03.png';
import img04 from './assets/img04.png';
import { CheckIcon } from '@assets/icons';

// Components
import ArticleHeader from '@containers/IQOSUniverse/components/ArticleHeader';
import ArticleImage from '@containers/IQOSUniverse/components/ArticleImage';
import MediaGallery from '@containers/IQOSUniverse/components/MediaGallery';

const MaintenanceLilSolid = () => {
    return (
        <section className={styles.wrapper}>
            <ArticleHeader />

            <div className="content">
                <h3>Curățarea și încărcarea? Atât de simplu.</h3>

                <p>
                    Așa cum știi deja, acum îți poți adăuga dispozitivul lil SOLID 2.0 în contul tău
                    IQOS. Curățarea și încărcarea acestuia sunt simplu de realizat și sunt esențiale
                    pentru a te bucura oricând dorești de gustul autentic de tutun, fără fum și fără
                    scrum.
                </p>
            </div>

            <ArticleImage image={img1V1} mobileImage={img1} />

            <div className="content">
                <h3>Curățarea cu ajutorul periuței cu două capete.</h3>

                <p>
                    În kit-ul dispozitivului lil SOLID 2.0 vei găsi o periuță cu două capete pentru
                    curățare. Urmează pașii de mai jos pentru a te asigura că o folosești corect:
                </p>

                {BrushCleanList.map((b: BurshClean) => {
                    return (
                        <p key={b.id}>
                            <span className={styles.listIndex}>{b.id}. </span>
                            {b.description}
                        </p>
                    );
                })}
            </div>

            <ArticleImage cover image={img2} mobileImage={img2M} />

            <div className="content">
                <h3>Curățarea cu ajutorul funcției HEAT.</h3>

                <p>
                    Există și o a doua variantă prin care îți poți curăța dispozitivul lil SOLID
                    2.0. Aceasta este funcția HEAT, prin care sunt încălzite reziduurile lipite de
                    elementul de încălzire din dispozitiv. Iată cum trebuie să procedezi:
                </p>

                {HeatCleanList.map((h: BurshClean) => {
                    return (
                        <p key={h.id}>
                            <span className={styles.listIndex}>{h.id}. </span>
                            {h.description}
                        </p>
                    );
                })}
            </div>

            <ArticleImage cover image={img3} mobileImage={img3M} />

            <div className="content">
                <h3>O încărcare în siguranță.</h3>

                <p>
                    Dispozitivul lil SOLID 2.0 îți oferă 25 de utilizări, fără scrum, fum sau
                    ardere, cu o singură încărcare completă. Pentru încărcare, îți recomandăm să
                    folosești doar cablul USB și adaptorul AC ale dispozitivului, care sunt special
                    concepute pentru lil SOLID 2.0. Doar așa te poți asigura că îl folosești în
                    condiții de siguranță.
                </p>
                <p>
                    O încărcare completă a dispozitivului durează 2 ore. Atunci când se încarcă,
                    LED-ul dispozitivului clipește. Atunci când dispozitivul este încărcat complet,
                    LED-ul se stinge.
                </p>
            </div>

            <ArticleImage cover image={img4} mobileImage={img4M} />

            <div className="content">
                <h3>Verificarea bateriei dispozitivului.</h3>

                <p>
                    Iată cele două modalități prin care poți verifica nivelul de încărcare a
                    dispozitivului lil SOLID 2.0:
                </p>

                <div className={styles.iconList}>
                    <div className={styles.listIcon}>
                        <CheckIcon />
                    </div>
                    <p className="smallText">printr-o simplă apăsare a butonului;</p>
                </div>

                <div className={styles.iconList}>
                    <div className={styles.listIcon}>
                        <CheckIcon />
                    </div>
                    <p className="smallText">
                        prin conectarea dispozitivul la o priză, cu ajutorul cablului USB și al
                        adaptorului AC.
                    </p>
                </div>

                <p>
                    <strong>
                        Culoarea afișată îți va indica procentul de încărcare a dispozitivului:
                    </strong>
                </p>

                {HeatsColorsList.map((h: HeatsColor) => {
                    return (
                        <div className={styles.iconList}>
                            <div className={styles.listIcon}>
                                <div
                                    className={classnames(styles.listDecoration, styles[h.color])}
                                ></div>
                            </div>
                            <p
                                className="smallText"
                                dangerouslySetInnerHTML={{
                                    __html: h.description
                                }}
                            />
                        </div>
                    );
                })}
            </div>

            <MediaGallery images={[img01, img02, img03, img04]} />
        </section>
    );
};

export default MaintenanceLilSolid;
