export const PWABenefitsIcon = () => {
    return (
        <svg
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M28 18.5498V47.4248" stroke="#00D1D2" strokeWidth="8.75" />
            <path
                d="M24.5 18.6665V46.6665M31.5 18.6665V46.6665V18.6665Z"
                stroke="white"
                strokeWidth="2.33333"
                strokeLinecap="round"
            />
            <path
                d="M46.0833 18.5498H9.91667C9.27233 18.5498 8.75 19.0721 8.75 19.7165V27.5145C8.75 28.1588 9.27233 28.6811 9.91667 28.6811H46.0833C46.7277 28.6811 47.25 28.1588 47.25 27.5145V19.7165C47.25 19.0721 46.7277 18.5498 46.0833 18.5498Z"
                stroke="white"
                strokeWidth="2.33333"
            />
            <path
                d="M43.195 28.875H12.8034C12.1591 28.875 11.6367 29.3973 11.6367 30.0417V46.116C11.6367 46.7603 12.1591 47.2827 12.8034 47.2827H43.195C43.8394 47.2827 44.3617 46.7603 44.3617 46.116V30.0417C44.3617 29.3973 43.8394 28.875 43.195 28.875Z"
                stroke="white"
                strokeWidth="2.33333"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.0759 8.26235C21.6884 8.09435 22.2752 8.15269 22.8376 8.43969C24.3309 9.20269 25.6002 11.2502 26.8112 14.392C27.3467 15.7804 27.7294 17.0182 27.9394 18.102L27.8811 17.8255L27.4961 17.822C23.9961 17.7415 20.3059 16.632 18.3436 14.8284L18.1242 14.6184C17.4744 13.958 17.0626 13.2067 17.1466 12.362C17.2294 11.5244 17.7637 10.6564 18.7426 9.73702C19.5534 8.97519 20.3234 8.46885 21.0759 8.26235ZM32.8487 8.43969C33.4099 8.15152 34.0014 8.09552 34.6221 8.26352C35.3897 8.47119 36.1807 8.98102 37.0184 9.74752C38.0217 10.6657 38.5759 11.5325 38.6716 12.369C38.7672 13.2067 38.3624 13.951 37.7219 14.6055C35.7561 16.611 31.6599 17.8255 27.8986 17.8255L27.9896 17.8244L28.0071 17.7345C28.1797 16.863 28.4586 15.8959 28.8296 14.8342L28.9952 14.3734C30.1409 11.2432 31.3682 9.19919 32.8487 8.43969Z"
                fill="#00D1D2"
                stroke="white"
                strokeWidth="2.33333"
            />
        </svg>
    );
};
