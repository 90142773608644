import { useState } from 'react';
import styles from './FlavourBanVideo.module.scss';

// Components
import ArticleHeader from '@containers/IQOSUniverse/components/ArticleHeader';
import VideoModalQuiz from '@containers/IQOSUniverse/components/VideoModalQuiz';

// Assets
import Rewards from './assets/rewards.svg';

// Utils
import classNames from 'classnames';

// Redux
import { useAppSelector } from '@app/hooks';

const FlavourBanVideo = () => {
    const { article } = useAppSelector((state) => state.iqosUniverse);

    const [toggleModal, setToggleModal] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);

    return (
        <section className={styles.wrapper}>
            <ArticleHeader
                videoQuiz={() => {
                    setToggleModal(true);
                }}
                showHardcodedSubtitle
            />

            <VideoModalQuiz
                toggleModal={toggleModal}
                setToggleModal={() => setToggleModal(false)}
                setShowSuccess={() => {
                    setShowSuccess(true);
                }}
            />

            <div className={classNames(styles.content)}>
                <p>
                    Uneori schimbările vin și cu surprize. Suntem alături de tine. <br />
                    Urmărește video-ul de mai sus și poți afla totul despre “Interdicția UE privind
                    aromele caracteristice pentru produsele din tutun încălzit”. <br /> Hai să ne
                    bucurăm împreună de un nou început.
                </p>
            </div>

            {(showSuccess || article.completed_quiz) && (
                <div className={styles.completed}>
                    <h2>Felicitări!</h2>
                    <p>
                        Ai urmărit video-ul și ai acumulat 100 FP! În plus, ai mai făcut un pas
                        înspre obținerea badge-ului Original Taste Pioneer.
                    </p>
                    <img src={Rewards} />
                </div>
            )}
        </section>
    );
};

export default FlavourBanVideo;
