import React, { useState } from 'react';

import styles from './DiplomaShow.module.scss';

// Components
import ArticleHeader from '@containers/IQOSUniverse/components/ArticleHeader';
import VideoModalQuiz from '@containers/IQOSUniverse/components/VideoModalQuiz';
import CompletedStateArticle from '@containers/IQOSUniverse/components/CompletedStateArticle/CompletedStateArticle';
import MediaGallery from '@containers/IQOSUniverse/components/MediaGallery';

// Assets
import img1 from './assets/img1.png';
import img2 from './assets/img2.png';
import img3 from './assets/img3.jpg';
import img4 from './assets/img4.jpg';
import img5 from './assets/img5.jpg';
import img6 from './assets/img6.jpg';
import img7 from './assets/img7.jpg';

// Helpers
import classnames from 'classnames';

// Redux
import { useAppSelector } from '@app/hooks';

const DiplomaShow = () => {
    const { article } = useAppSelector((state) => state.iqosUniverse);

    const [toggleModal, setToggleModal] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);

    return (
        <section className={styles.wrapper}>
            <div className={styles.customDiplomaSubtitle}>
                <ArticleHeader
                    videoQuiz={() => {
                        setToggleModal(true);
                    }}
                    showSubtitle
                />
            </div>

            <VideoModalQuiz
                toggleModal={toggleModal}
                setToggleModal={() => setToggleModal(false)}
                setShowSuccess={() => {
                    setShowSuccess(true);
                }}
            />

            <div className={classnames(styles.diplomaFirstContent, 'content')}>
                <h3>O ediție aniversară cu peste 130 de lucrări</h3>
                <p>
                    Între 7 și 14 octombrie, am descoperit împreună viziunile artistice ale noilor
                    creativi români. Au fost expuse lucrări din domenii diverse, de la arhitectură
                    și peisagistică, la arte grafice, design de produs și fashion, până la artele
                    clasice - pictură și sculptură.
                </p>
            </div>

            <div className={classnames('content', styles.diplomaSecondContent)}>
                <h3>IQOS împreună cu Omid Ghannadi a creat o instalație imersivă</h3>
                <p>
                    Instalația IQOS poziționată la intrarea în sala SENAT a fost concepută drept un
                    pasaj simbolic al comunității către viitor. Realizată din neoane colorate și
                    oglinzi care proiectează la infinit imaginea, conceptul acesteia a avut la bază
                    progresul și inovația, caracteristice filozofiei IQOS.
                </p>
                <p>Vezi mai jos câteva imagini de la eveniment.</p>
            </div>

            <MediaGallery
                showTitle={false}
                darkArrows={false}
                images={[img1, img2, img3, img4, img5, img6, img7]}
                sliderClassName={styles.customDiplomaGallery}
            />

            {(showSuccess || article.completed_quiz) && (
                <CompletedStateArticle
                    className={styles.completedStateArticle}
                    article={{
                        points: article.points,
                        quiz_points: article.quiz_points,
                        quiz_end_date: article.quiz_end_date
                    }}
                />
            )}
        </section>
    );
};

export default DiplomaShow;
