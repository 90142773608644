export const ExtraChanceIconWhite = () => {
    return (
        <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="14" cy="14" r="14" fill="#FFFDFB" />
            <path
                d="M14.0225 3.90674C16.6993 3.90674 19.2665 4.97011 21.1593 6.86292C23.0521 8.75573 24.1155 11.3229 24.1155 13.9998C24.1155 16.6766 23.0521 19.2438 21.1593 21.1366C19.2665 23.0294 16.6993 24.0928 14.0225 24.0928V3.90674Z"
                fill="#00D1D2"
            />
            <path
                d="M15.7587 20.5797H12.7524V13.6417H9.2666L14.2531 6.99146L19.2396 13.6417H16.2333H15.7539V20.5797H15.7587Z"
                fill="#FFFDFB"
            />
            <path
                d="M15.2808 20.1003V13.1624H18.2871L14.2595 7.79227L10.232 13.1624H13.2383V20.1003H15.2856M16.2446 21.0593H12.2793V14.1213H9.27303C8.87987 14.1213 8.65451 13.6706 8.88946 13.3541L13.876 6.70387C14.0678 6.44975 14.4513 6.44495 14.6431 6.70387L19.6296 13.3541C19.8646 13.6706 19.6392 14.1213 19.2461 14.1213H16.2398V21.0593H16.2446Z"
                fill="#34303D"
            />
        </svg>
    );
};
