import React, { useEffect } from 'react';
import { IMatchThree } from './interface';

// Components
import { GameBoard } from './components/GameBoard';

// Assets
import styles from './MatchThreeGame.module.scss';

// Helpers
import { useMatchThree } from './match-three/useMatchThree';
import { useDisableZoom } from './components/useDisableZoom';
import useCountdown from '@utils/hooks/useCountdown';
import store from 'store';
import { startMatchThreeMiddleware } from './match-three';
import { useWindowSize } from '@utils/hooks/windowSize';
import { animateScroll as scroll } from 'react-scroll/modules';

const MatchThreeGame: React.FC<IMatchThree> = ({
    time = 60 * 1000,
    winningScore = 750,
    onLose,
    onWin,
    game
}) => {
    const { score, resetScore, board } = useMatchThree();

    const [countdown, togglePauseCountdown] = useCountdown(time, 'mm:ss', () => userLost());
    const [lockCall, setLockCall] = React.useState(true);

    const { width } = useWindowSize();
    const isSmallScreen = width <= 320; 

    useEffect(() => {
        if (isSmallScreen) scroll.scrollTo(50);
    }, [isSmallScreen]);

    useEffect(() => {
        store.set('matchThreeGame', game);
        startMatchThreeMiddleware();
        return () => {
            store.remove('matchThreeGame');
        };
    }, []);

    useDisableZoom();

    /*
     * We always need to reset the score so the state changes between loads
     */

    const userLost = () => {
        onLose(); //* Trigger Lose
        resetScore();
    };

    useEffect(() => {
        togglePauseCountdown();
        resetScore(); // Reset score on init
        if (board) togglePauseCountdown();

        return () => {
            resetScore();
        };
    }, []);

    useEffect(() => {
        if (lockCall && score >= winningScore) {
            setLockCall(false);
            togglePauseCountdown();
            onWin(); //* Trigger Win
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [score]);

    return (
        <div className={styles.container} style={{ touchAction: 'none' }}>
            <div className={styles.countdown}>
                Timp rămas: <span>{countdown}</span>
            </div>
            <div className={styles.gameBoardWrapper}>
                <GameBoard />
            </div>

            <div className={styles.gameBar}>
                <div className={styles.scoreGot}>
                    <p>
                        Ai acumulat: <span>{score} puncte</span>
                    </p>
                </div>

                <div className={styles.scoreRemaining}>
                    <p>
                        De acumulat: <span>{winningScore} puncte</span>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default MatchThreeGame;
