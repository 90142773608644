export const CollapseCloseIcon = () => {
    return (
        <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M17.6206 10.381L10.3828 17.6187"
                stroke="#8C8A90"
                strokeWidth="1.77033"
                strokeLinecap="round"
            />
            <path
                d="M10.3828 10.3809L17.6206 17.6187"
                stroke="#8C8A90"
                strokeWidth="1.77033"
                strokeLinecap="round"
            />
            <rect
                x="0.46875"
                y="0.46875"
                width="27.0625"
                height="27.0625"
                rx="13.5312"
                stroke="#8C8A90"
                strokeWidth="0.9375"
            />
        </svg>
    );
};
