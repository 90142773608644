import { useEffect } from 'react';
import styles from './VideoDisclaimer.module.scss';

const VideoDisclaimer = ({ handleClose }: any) => {
    useEffect(() => {
        setTimeout(() => {
            handleClose();
        }, 6 * 1000);
    }, []);

    return (
        <section className={styles.wrapper}>
            <div className={styles.progressBar}>
                <p>Moment informativ despre FP</p>
            </div>

            <div className={styles.content}>
                <p>
                    <strong>Pentru a colecționa FP</strong>, urmărește videoclipul integral, fără să
                    derulezi sau să sari aproape de final, cât și să răspunzi la întrebarea de la
                    sfârșit.
                </p>
            </div>
        </section>
    );
};

export default VideoDisclaimer;
