export const DateIcon = () => {
    return (
        <svg
            width="21"
            height="22"
            viewBox="0 0 21 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10.5002 21C16.0231 21 20.5002 16.5228 20.5002 11C20.5002 5.47715 16.0231 1 10.5002 1C4.9774 1 0.500244 5.47715 0.500244 11C0.500244 16.5228 4.9774 21 10.5002 21Z"
                stroke="#34303D"
            />
            <path
                d="M10.5002 2.40002C12.7811 2.40002 14.9685 3.30609 16.5814 4.91891C18.1942 6.53172 19.1002 8.71916 19.1002 11C19.1002 13.2809 18.1942 15.4683 16.5814 17.0811C14.9685 18.694 12.7811 19.6 10.5002 19.6V2.40002Z"
                fill="#19CCBA"
            />
            <path
                d="M13.1396 10.2065H6.86061C6.56145 10.2065 6.31894 10.4491 6.31894 10.7482V14.2414C6.31894 14.5406 6.56145 14.7831 6.86061 14.7831H13.1396C13.4388 14.7831 13.6813 14.5406 13.6813 14.2414V10.7482C13.6813 10.4491 13.4388 10.2065 13.1396 10.2065Z"
                fill="#19CCBA"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.7084 6.89575C13.232 6.89575 13.7059 7.10809 14.0491 7.45096C14.3919 7.79411 14.6043 8.26806 14.6043 8.79159V15.8333C14.6043 15.908 14.5739 15.9757 14.5249 16.0247C14.4762 16.0738 14.4085 16.1041 14.3334 16.1041H5.66677C5.59202 16.1041 5.52431 16.0738 5.47529 16.0247C5.42627 15.976 5.39594 15.9083 5.39594 15.8333V7.16659C5.39594 7.09184 5.42627 7.02413 5.47529 6.97511C5.52404 6.92609 5.59175 6.89575 5.66677 6.89575H12.7084Z"
                stroke="#34303D"
                strokeWidth="0.541656"
            />
            <path
                d="M12.5324 12.9625H11.9772C11.8276 12.9625 11.7064 13.0838 11.7064 13.2334V13.7886C11.7064 13.9381 11.8276 14.0594 11.9772 14.0594H12.5324C12.682 14.0594 12.8032 13.9381 12.8032 13.7886V13.2334C12.8032 13.0838 12.682 12.9625 12.5324 12.9625Z"
                fill="#34303D"
            />
            <path
                d="M10.3386 12.9625H9.78344C9.63386 12.9625 9.5126 13.0838 9.5126 13.2334V13.7886C9.5126 13.9381 9.63386 14.0594 9.78344 14.0594H10.3386C10.4882 14.0594 10.6095 13.9381 10.6095 13.7886V13.2334C10.6095 13.0838 10.4882 12.9625 10.3386 12.9625Z"
                fill="#34303D"
            />
            <path
                d="M8.02303 12.9625H7.46782C7.31825 12.9625 7.19699 13.0838 7.19699 13.2334V13.7886C7.19699 13.9381 7.31825 14.0594 7.46782 14.0594H8.02303C8.17261 14.0594 8.29387 13.9381 8.29387 13.7886V13.2334C8.29387 13.0838 8.17261 12.9625 8.02303 12.9625Z"
                fill="#34303D"
            />
            <path
                d="M12.5324 11.0125H11.9772C11.8276 11.0125 11.7064 11.1337 11.7064 11.2833V11.8385C11.7064 11.9881 11.8276 12.1093 11.9772 12.1093H12.5324C12.682 12.1093 12.8032 11.9881 12.8032 11.8385V11.2833C12.8032 11.1337 12.682 11.0125 12.5324 11.0125Z"
                fill="#34303D"
            />
            <path
                d="M10.2777 11.0125H9.72249C9.57292 11.0125 9.45166 11.1337 9.45166 11.2833V11.8385C9.45166 11.9881 9.57292 12.1093 9.72249 12.1093H10.2777C10.4273 12.1093 10.5485 11.9881 10.5485 11.8385V11.2833C10.5485 11.1337 10.4273 11.0125 10.2777 11.0125Z"
                fill="#34303D"
            />
            <path
                d="M8.02303 11.0125H7.46782C7.31825 11.0125 7.19699 11.1337 7.19699 11.2833V11.8385C7.19699 11.9881 7.31825 12.1093 7.46782 12.1093H8.02303C8.17261 12.1093 8.29387 11.9881 8.29387 11.8385V11.2833C8.29387 11.1337 8.17261 11.0125 8.02303 11.0125Z"
                fill="#34303D"
            />
            <path
                d="M5.42978 9.12341H14.4485"
                stroke="#34303D"
                strokeWidth="0.541656"
                strokeLinecap="square"
            />
        </svg>
    );
};
