import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axiosRequest from '@utils/axios';

export interface ActivitiesState {
    status: 'idle' | 'loading' | 'failed';
    activities: [];
    error: any;
}

interface AxiosError {
    response: {
        data: {
            message: string;
        };
    };
}

const initialState: ActivitiesState = {
    status: 'idle',
    activities: [],
    error: {}
};

export const getActivities = createAsyncThunk('dashboard/Activities', async () => {
    try {
        const response = await axiosRequest.get('/cards').then((res) => {
            return res;
        });

        return response.data.data;
    } catch (e) {
        return (e as AxiosError).response.data.message;
    }
});

export const activitiesSlice = createSlice({
    name: 'activities',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getActivities.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getActivities.fulfilled, (state, action) => {
                state.status = 'idle';
                state.activities = action.payload;
            })
            .addCase(getActivities.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    }
});

export default activitiesSlice.reducer;
