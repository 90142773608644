import { createSlice } from "@reduxjs/toolkit";

export const qrCodeModalSlice = createSlice({
    name: "qrCodeModal",
    initialState: {
        isOpen: false,
    },
    reducers: {
      toggleQRCodeModal: (state) => {
        state.isOpen = !state.isOpen;
      }
    }
});

export const { toggleQRCodeModal } = qrCodeModalSlice.actions;
export default qrCodeModalSlice.reducer;