/* eslint-disable no-useless-computed-key */
import store from 'store';
import { config } from '@utils/config';

// Cover assets
import mgmCover from './assets/mgm-cover.jpeg';
import bdayCover from './assets/bday-cover.jpeg';
import platinumCover from './assets/platinum-cover.jpg';
import anniversaryCover from './assets/anniversary-cover.jpg';
import anniversaryVoucher from './assets/anniversary-coverVoucher.jpg';
import weeklyConver from './assets/weekly-cover.png';
import vouchers35000Cover from './assets/vouchers35000-cover.png';
import elefantCover from './assets/elefant.png';
import samsung from './assets/samsung.jpg';
import Gift1KDropping from './assets/1kAccessories.jpg';
import campaignWeeklyCover from './assets/campaignWeekly-cover.png';
import tasteCover from './assets/taste-cover.png';
import summerVoucher from './assets/summerVoucherCover.jpg';
import phase2Stardrift from './assets/real-people-phase2-stardrift.png';
import electrolux from './assets/electroluxVoucher.jpg';
import birthDayCover from './assets/birthDayCover.jpg';
import rowenta from './assets/rowenta.jpg';
import rowentaDiscount2 from './assets/rowenta-cover.jpg';
import rowentaDiscountDec from './assets/RowentaDec.jpg';
import krups from './assets/krups.jpg';
import tefal from './assets/tefal-cover.jpg';
import voucher20veev from './assets/voucher20-veev.jpg';
import voucher20 from './assets/voucher20.jpg';
import flavourBanGift from './assets/flavourBanGift.jpg';
import tradeInCover from './assets/tradein-cover.jpg';
import realConnectionAccessories from './assets/realConnectionAccessories.gif';
import realConnectionVoucher from './assets/realConnectionVoucher.gif';
import realConnectionVip from '@containers/Campaigns/RealPeopleRealConnections/assets/weeklyPrizes/prize1.gif';
import realConnectionIluma from '@containers/Campaigns/RealPeopleRealConnections/assets/weeklyPrizes/prize2.gif';
import hypedTickets from './assets/hyped.jpg';
import brunchEntourage from './assets/brunch-entourage-cover.jpg';
import rowentaDiscountMar from './assets/rowentaDiscountMar.png';
import tefalDiscountMar from './assets/tefalDiscountMar.png';
import voucherTasteExploration from '@containers/Campaigns/TasteExploration/assets/voucher.gif';
import voucherTasteExplorationVeev from '@containers/Campaigns/TasteExploration/assets/voucherVeev.gif';
import oxetteVoucher from './assets/oxette-ro.jpg';
import avStoreVoucher from './assets/avStoreDiscount.jpg';
import avStoreVoucherPage from './assets/avStoreDiscountPage.jpg';
import tradeInHeader from './assets/tradeInHeader.jpg';
import mixTaste32Cover from './assets/mixTaste32Cover.jpg';
import mixTaste32Page from './assets/mixTaste32Page.jpg';
import mixTaste210Cover from './assets/mixTaste210Cover.jpg';
import mixTaste210Page from './assets/mixTaste210Page.jpg';
import electroluxMai2024 from './assets/electroluxMai2024Cover.jpg';
import mgmExtragereCover from './assets/mgmExtragereCover.jpg';
import rdwTickets from './assets/rdwTickets.jpg';

// Tier advancement assets
import goldAdvancement from './assets/gold-advancement.jpg';
import platinumAdvancement from './assets/platinum-advancement.jpg';

// DDOY assets
import ddoyGold from './assets/ddoy-gold.jpg';
import ddoyPlatinum from './assets/ddoy-platinum.jpg';

// Acc discounts assets
import accDiscountGold from './assets/accDiscountGold.jpg';
import accDiscountPlatinum from './assets/accDiscountPlatinum.jpg';

// TierGiftsTag
import bdaySilver from './assets/bdaySilver.svg';
import bdayGold from './assets/bdayGold.svg';
import bdayPlatinum from './assets/bdayPlatinum.svg';
import yellowTag from './assets/yellowTag.svg';
import rowentaDiscount from './assets/rowentaDiscount.svg';

// TierElefantTags
import elefantSilver from './assets/elefantSilver.svg';
import elefantGold from './assets/elefantGold.svg';
import elefantPlatinum from './assets/elefantPlatinum.svg';

// TierSamsungTags
import samsungSilver from './assets/samsungSilver.svg';
import samsungGold from './assets/samsungGold.svg';
import samsungPlatinum from './assets/samsungPlatinum.svg';

import platinum5 from './assets/platinum-5.jpg';

// CallForDream assets
import callForDreamsVoucher from './assets/call-for-dreams-voucher.png';

const userData = store.get(config.user.user);

export const tierGifts: { id: string; image: string; color: string }[] = [
    {
        id: 'Silver',
        image: bdaySilver,
        color: '#287BAF'
    },
    {
        id: 'Gold',
        image: bdayGold,
        color: '#E3813B'
    },
    {
        id: 'Platinum',
        image: bdayPlatinum,
        color: '#4C4D67'
    },
    {
        id: 'Yellow', // Used for MGM cards
        image: yellowTag,
        color: '#FFD657'
    },
    {
        id: 'Rowenta',
        image: rowentaDiscount,
        color: '#4C4D67'
    }
];

export const tierGiftsElefant: { id: string; image: string; color: string }[] = [
    {
        id: 'Silver',
        image: elefantSilver,
        color: '#287BAF'
    },
    {
        id: 'Gold',
        image: elefantGold,
        color: '#E3813B'
    },
    {
        id: 'Platinum',
        image: elefantPlatinum,
        color: '#4C4D67'
    }
];

export const tierGiftsSamsung: { id: string; image: string; color: string }[] = [
    {
        id: 'Silver',
        image: samsungSilver,
        color: '#287BAF'
    },
    {
        id: 'Gold',
        image: samsungGold,
        color: '#E3813B'
    },
    {
        id: 'Platinum',
        image: samsungPlatinum,
        color: '#4C4D67'
    }
];

export const sectionTemplatesExpired = [
    {
        type: 'vouchers',
        title: 'Vouchere',
        rewards: []
    },
    {
        type: 'offers',
        title: 'Oferte',
        rewards: [
            {
                rewardHash: 'mixeaza-gustul-3-2',
                title: `MIXEAZĂ GUSTUL - 3 Pachete la preț de 2`,
                partnersTitle: 'MIXEAZĂ GUSTUL - 3 Pachete la preț de 2',
                redeemedTitle: '',

                cancelledTitle: 'Acest cadou s-a întors la noi',
                partnersDescription: `La achiziția a 2 pachete de același fel dintre sortimentele: <strong>TEREA Wind Fuse, TEREA Amarelo Fuse, LEVIA Electro Rouge sau LEVIA Island Beat</strong>, se oferă înca un pachet din același sortiment.<br/>
                                      Consultă regulamentul pe: <a href="https://www.iqos.ro/promo-parteneri" target="_blank">iqos.ro/promo-parteneri</a> `,

                description: ``,
                campaign_slug: 'mixeaza-gustul-3-2',
                ctaText: 'Folosește-l acum',
                ctaPageText: 'Vezi regulamentul',
                ctaLink: `${config.path.myRewards.index}/offer-voucher/mixeaza-gustul-3-2`,
                externalLink: 'https://www.iqos.ro/promo-terea-levia-fuse-line',
                image: mixTaste32Cover,
                pageImage: mixTaste32Page,
                tier: '',
                discount: false,
                backgroundColor: '#f6f4f0',
                textColor: 'dark',
                addDate: '2024-04-19 15:29:51',
                status: 'ExpiredVoucher',
                ['status-club']: 'ExpiredVoucher',
                expirationDate: '2024-04-21 23:59:59',
                expired_at: '2024-04-21 23:59:59',
                isOffer: true,
                expireDateBgCustomMessage: '10 - ',
                expireDateBgColor: 'turquoise',
                customDateFormat: 'DD MMMM YYYY'
            },
            {
                rewardHash: 'mixeaza-gustul-2-10',
                title: `MIXEAZĂ GUSTUL - 10 lei discount la 2 pachete`,
                partnersTitle: 'MIXEAZĂ GUSTUL - 10 lei discount la 2 pachete',
                redeemedTitle: '',

                cancelledTitle: 'Acest cadou s-a întors la noi',
                partnersDescription: `La achiziția a 2 pachete din sortimentele: <strong>TEREA Wind Fuse, TEREA Amarelo Fuse, LEVIA Electro Rouge sau LEVIA Island Beat</strong> în orice combinație, se ofera o reducere în valoare de 10 lei față de prețul practicat în magazin.<br/>
                                      Consultă regulamentul pe: <a href="https://www.iqos.ro/promo-parteneri" target="_blank">iqos.ro/promo-parteneri</a> `,

                description: ``,
                campaign_slug: 'mixeaza-gustul-2-10',
                ctaText: 'Folosește-l acum',
                ctaPageText: 'Vezi regulamentul',
                ctaLink: `${config.path.myRewards.index}/offer-voucher/mixeaza-gustul-2-10`,
                externalLink: 'https://www.iqos.ro/regulament-mix-special-levia-terea-fuse',
                image: mixTaste210Cover,
                pageImage: mixTaste210Page,
                tier: '',
                discount: false,
                backgroundColor: '#f6f4f0',
                textColor: 'dark',
                addDate: '2024-04-19 15:29:51',
                status: 'ExpiredVoucher',
                ['status-club']: 'ExpiredVoucher',
                expirationDate: '2024-04-24 23:59:59',
                expired_at: '2024-04-24 23:59:59',
                isOffer: true,
                expireDateBgCustomMessage: '10 - ',
                expireDateBgColor: 'turquoise',
                customDateFormat: 'DD MMMM YYYY'
            }
        ]
    },
    {
        type: 'gifts',
        title: 'Alte premii și cadouri',
        rewards: []
    },

    {
        type: 'partner_vouchers',
        title: 'Vouchere Parteneri',
        rewards: []
    }
];

export const sectionsTemplates = [
    {
        type: 'vouchers',
        title: 'Vouchere valorice',
        rewards: []
    },
    {
        type: 'offers',
        title: 'Oferte',
        rewards: [
            {
                rewardHash: 'trade-in-offer',
                title: `200 RON discount total la IQOS ILUMA PRIME, prin programul Trade-In`,
                partnersTitle:
                    '200 RON discount total la IQOS ILUMA PRIME, prin programul Trade-In',
                redeemedTitle: '',

                cancelledTitle: 'Acest cadou s-a întors la noi',
                partnersDescription: `<strong>Dă la schimb dispozitivul tău vechi IQOS sau lil SOLID și primești 100 RON discount la IQOS ILUMA PRIME</strong> prin programul Trade-In*. <br/>
                                      În plus, <strong>ca membru IQOS Club</strong> ai parte și de o <strong>reducere exclusivă de 100 RON</strong> la achiziție.<br/><br/>
                                      *poți beneficia de programul Trade-In o singură dată, în perioada 16.01.2024 - 31.07.2024.`,

                description: ``,
                disclaimer: `    Poți face Trade-In accesând pagina „Dispozitivele mele” de pe contul tău
                    IQOS.ro, prin LiveChat, în magazinele oficiale IQOS, sau contactând Centrul de
                    Asistență Clienți IQOS la numărul de telefon 0800 030 333.`,
                campaign_slug: 'trade-in-offer',
                ctaText: 'Folosește-l acum',
                ctaPageText: 'Aici',
                ctaLink: `${config.path.myRewards.index}/offer-voucher/trade-in-offer`,
                externalLink: config.path.iqos.tradeIn,
                image: tradeInCover,
                pageImage: tradeInHeader,
                tier: '',
                discount: false,
                backgroundColor: '#f6f4f0',
                textColor: 'dark',
                addDate: '2024-01-29 15:29:51',

                status: -1,
                ['status-club']: 'Unredeemed',
                expirationDate: '2024-07-31 23:59:59',
                expired_at: '2024-07-31 23:59:59',
                isOffer: true
            }
        ]
    },
    {
        type: 'gifts',
        title: 'Alte premii și cadouri',
        rewards: [
            {
                rewardHash: 'cadouri-pentru-recomandari',
                title: `Accesorii cu preț special pentru membri IQOS Club`,
                description: `Beneficiezi de o reducere permanentă la achiziția accesoriilor
                                    IQOS și lil SOLID în insulele și magazinele IQOS cât și pe
                                    <a href="https://iqos.ro" rel={'noopener noreferrer'} target={"_blank"} >www.iqos.ro</a>, exclusiv pentru
                                    membrii IQOS Club.`,
                slug: 'cadouri-pentru-recomandari',
                ctaText: 'Folosește-l acum',
                ctaLink: config.path.myRewards.staticDiscount,
                image: userData?.tier === 'Platinum' ? accDiscountPlatinum : accDiscountGold,
                tier: userData?.tier,
                discount: true,
                backgroundColor: '#00D1D2',
                textColor: 'dark',
                addDate: '2023-02-11 15:29:51',
                status: -1,
                ['status-club']: 'Unredeemed',
                expirationDate: '2024-12-31 23:59:59'
            }
        ]
    },
    {
        type: 'partner_vouchers',
        title: 'Vouchere Parteneri',
        rewards: []
    }

    // {
    //     type: 'old-points',
    //     title: 'Folosește punctele restante',
    //     rewards: [
    //         {
    //             rewardHash: '',
    //             title: `Revendică pachete de HEETS sau Fiit din magazine`,
    //             description:
    //                 'Poți folosi punctele acumulate în vechiul Club pentru a revendica pachete de HEETS sau Fiit.',
    //             slug: 'cadouri-pentru-recomandari',
    //             ctaText: 'Folosește punctele',
    //             ctaLink: config.path.offlineRedemption,
    //             image: offlineRedemptionCover,
    //             tier: '',
    //             discount: false,
    //             backgroundColor: '#00D1D2',
    //             textColor: 'dark',
    //             fromClub: true,
    //             status: -1
    //         }
    //     ]
    // }
];

// Dictionary for rewards

// MGM - MGM
// DDOY - Device Discount
// CMP-ENG-LANSARE - Campanie engagement lansare

// @ts-ignore
export const rewardTemplates: {
    [key: string]: {
        title: string;

        redeemedTitle: string;

        partnersTitle?: string;
        partnersDescription?: string;
        cancelledTitle?: string;

        addDate?: string;
        image: string;
        pageImage?: string;
        ctaText: string;
        ctaLink: string;
        customCtaText?: string;
        externalLink?: string;
        tier: string;
        discount: boolean;
        voucher?: boolean;
        isGift?: boolean;
        isPrize?: boolean;
        isOffer?: boolean;
        backgroundColor: string;
        expirationDate?: string;
        textColor: 'dark' | 'light' | 'turquoise';
        voucherTag?: any;
        voucherTagTextColor?: 'dark' | 'light' | 'turquoise';
        redeemed?: boolean;
        type?: string;
        status?: number;
        value?: number;
        giftImage?: boolean;
        giftDescription?: boolean;
        showDescription?: boolean;
        disclaimer?: string;
        discountTagProperties?:
            | {
                  left: string;
                  right: string;
                  top: string;
                  bottom: string;
                  fontSize?: number;
                  width?: number;
              }
            | undefined;
        partnersGift?: boolean;
        noAvailableMessage?: boolean;
        expireDateBgColor?: string;
        expireDateBgCustomMessage?: string;
        customDateFormat?: string;
        textTransform?:
            | {
                  top?: string;
                  right?: string;
                  bottom?: string;
                  left?: string;
                  transform?: string;
                  textAlign?: string;
                  textWrap?: string;
                  lineHeight?: string;
              }
            | undefined;
        labelCount?: number;
        labelMax?: number;
        noDiscountType?: boolean;
    };
} = {
    // VOUCHERS

    ['MGM']: {
        title: `Bună ${userData?.first_name}! Te așteaptă o recompensă!`,

        redeemedTitle: `Felicitări! Ai revendicat cu succes voucherul!`,

        type: 'MGM',
        ctaText: 'Vezi recompensa',
        ctaLink: `${config.path.myRewards.index}/voucher`,
        voucherTag: tierGifts.filter(
            (gift: { id: string; image: string }) => gift.id === 'Yellow'
        )[0].image,
        voucherTagTextColor: 'dark',
        discountTagProperties: {
            top: 'auto',
            right: '2%',
            bottom: '2%',
            left: 'auto',
            fontSize: 14
        },
        image: mgmCover,
        tier: '',
        discount: false,
        voucher: true,
        isGift: false,
        backgroundColor: '',
        textColor: 'dark'
    },

    ['ANIVERSARE-IQOS']: {
        title: 'Un cadou special pentru aniversarea ta cu IQOS!',

        redeemedTitle: 'Felicitări! Ai revendicat cadoul aniversar!',

        type: 'aniversare-voucher',
        ctaText: 'Revendică voucher-ul',
        ctaLink: `${config.path.myRewards.index}/voucher`,
        image: anniversaryVoucher,
        redeemed: true,
        voucherTag: userData?.tier
            ? tierGifts.filter(
                  (gift: { id: string; image: string }) => gift.id === userData?.tier
              )[0].image
            : '',
        discountTagProperties: {
            top: '3%',
            right: '3%',
            bottom: 'auto',
            left: 'auto',
            width: 83,
            fontSize: 14
        },
        tier: '',
        discount: false,
        isGift: false,
        voucher: true,
        backgroundColor: '#00d1d2',
        textColor: 'dark'
    },

    // BirthdayGift
    ['BDG']: {
        title: `La mulți ani, ${userData?.first_name}!`,

        redeemedTitle: 'Felicitări! Ai revendicat cadoul pentru ziua ta!',

        type: 'cadou-de-ziua-ta',
        ctaText: 'Descoperă cadoul',
        ctaLink: `${config.path.myRewards.index}/voucher`,
        voucherTag: userData?.tier
            ? tierGifts.filter(
                  (gift: { id: string; image: string }) => gift.id === userData?.tier
              )[0].image
            : '',
        image: bdayCover,
        discountTagProperties: {
            top: 'auto',
            right: '2%',
            bottom: '2%',
            left: 'auto',
            width: 65,
            fontSize: 11
        },
        tier: '',
        discount: false,
        voucher: true,
        isGift: false,
        backgroundColor: '#00D1D2',
        textColor: 'dark'
    },

    // Members only promotions
    ['CMP-ENG-LANSARE']: {
        title: 'Ai un cadou  de revendicat!',

        redeemedTitle: 'Felicitări! Ai revendicat cu succes voucherul!',

        type: 'promotii-pentru-membri',
        ctaText: 'Vezi detalii',
        ctaLink: `${config.path.myRewards.index}/voucher`,
        voucherTag: tierGifts.filter(
            (gift: { id: string; image: string }) => gift.id === 'Yellow'
        )[0].image,
        voucherTagTextColor: 'dark',
        discountTagProperties: {
            top: 'auto',
            right: '2%',
            bottom: '2%',
            left: 'auto',
            width: 65,
            fontSize: 11
        },
        image: bdayCover,
        tier: '',
        discount: false,
        voucher: true,
        isGift: false,
        backgroundColor: '#00D1D2',
        textColor: 'dark'
    },
    ['CMP-OR-BENEFICII']: {
        title: `Felicitări, ${userData?.first_name}!`,

        redeemedTitle: 'Felicitări! Ai revendicat cu succes voucherul!',

        type: 'promotii-pentru-membri',
        ctaText: 'Vezi detalii',
        ctaLink: `${config.path.myRewards.index}/voucher`,
        voucherTag: tierGifts.filter(
            (gift: { id: string; image: string }) => gift.id === 'Yellow'
        )[0].image,
        voucherTagTextColor: 'dark',
        discountTagProperties: {
            top: 'auto',
            right: '2%',
            bottom: '2%',
            left: 'auto',
            width: 65,
            fontSize: 11
        },
        image: bdayCover,
        tier: '',
        discount: false,
        voucher: true,
        isGift: false,
        backgroundColor: '#00D1D2',
        textColor: 'dark'
    },
    ['CMP-OR-MECANICA']: {
        title: `Felicitări, ${userData?.first_name}!`,

        redeemedTitle: 'Felicitări! Ai revendicat cu succes voucherul!',

        type: 'promotii-pentru-membri',
        ctaText: 'Vezi detalii',
        ctaLink: `${config.path.myRewards.index}/voucher`,
        voucherTag: tierGifts.filter(
            (gift: { id: string; image: string }) => gift.id === 'Yellow'
        )[0].image,
        voucherTagTextColor: 'dark',
        discountTagProperties: {
            top: 'auto',
            right: '2%',
            bottom: '2%',
            left: 'auto',
            width: 65,
            fontSize: 11
        },
        image: bdayCover,
        tier: '',
        discount: false,
        voucher: true,
        isGift: false,
        backgroundColor: '#00D1D2',
        textColor: 'dark'
    },
    ['CMP-OR-COMUNITATE']: {
        title: `Felicitări, ${userData?.first_name}!`,

        redeemedTitle: 'Felicitări! Ai revendicat cu succes voucherul!',

        type: 'promotii-pentru-membri',
        ctaText: 'Vezi detalii',
        ctaLink: `${config.path.myRewards.index}/voucher`,
        voucherTag: tierGifts.filter(
            (gift: { id: string; image: string }) => gift.id === 'Yellow'
        )[0].image,
        voucherTagTextColor: 'dark',
        discountTagProperties: {
            top: 'auto',
            right: '2%',
            bottom: '2%',
            left: 'auto',
            width: 65,
            fontSize: 11
        },
        image: bdayCover,
        tier: '',
        discount: false,
        voucher: true,
        isGift: false,
        backgroundColor: '#00D1D2',
        textColor: 'dark'
    },
    ['CMP-ILUMA-TRAIESTE-VARA']: {
        title: `Felicitări, ${userData?.first_name}!`,

        redeemedTitle: 'Felicitări! Ai revendicat premiul instant câștigat!',

        type: 'voucher-campanie-vara',
        ctaText: 'Revendică premiul',
        ctaLink: `${config.path.myRewards.index}/voucher`,
        voucherTag: tierGifts.filter(
            (gift: { id: string; image: string }) => gift.id === 'Yellow'
        )[0].image,
        voucherTagTextColor: 'dark',
        discountTagProperties: {
            top: '5%',
            right: '10%',
            bottom: 'auto',
            left: 'auto',
            width: 65,
            fontSize: 11
        },
        image: summerVoucher,
        tier: '',
        discount: false,
        voucher: true,
        isGift: false,
        backgroundColor: '#00D1D2',
        textColor: 'dark'
    },

    ['DDOY']: {
        title: `Pentru că ești membru ${userData?.tier}, ai primit un voucher cool!`,

        redeemedTitle: 'Felicitări! Ai revendicat cu succes voucherul!',

        type: 'voucher-anual',
        ctaText: 'Vezi detalii',
        ctaLink: `${config.path.myRewards.index}/voucher`,
        image: userData?.tier === 'Platinum' ? ddoyPlatinum : ddoyGold,
        tier: '',
        discount: false,
        voucher: true,
        isGift: false,
        backgroundColor: '#34303D',
        textColor: 'light'
    },

    ['CMP-TACTICA']: {
        title: 'Voucher de bun venit în noul IQOS Club!',

        redeemedTitle: 'Felicitări! Ai revendicat cu succes voucherul!',

        type: 'voucher-de-bun-venit',
        ctaText: 'Revendică acum',
        ctaLink: `${config.path.myRewards.index}/voucher`,
        voucherTag: tierGifts.filter(
            (gift: { id: string; image: string }) => gift.id === 'Yellow'
        )[0].image,
        voucherTagTextColor: 'dark',
        discountTagProperties: {
            top: '35%',
            right: '1%',
            bottom: 'auto',
            left: 'auto',
            width: 65,
            fontSize: 11
        },
        image: vouchers35000Cover,
        tier: '',
        discount: false,
        voucher: true,
        isGift: false,
        backgroundColor: '#34303D',
        textColor: 'light'
    },

    ['CMP-TASTE-EXPLORATION']: {
        title: `Ai un cadou de revendicat!`,

        redeemedTitle: 'Felicitări! Ai revendicat cu succes voucherul!',

        type: 'exploreaza-gustul',
        ctaText: 'Revendică voucher',
        ctaLink: `${config.path.myRewards.index}/voucher`,
        voucherTag: tierGifts.filter(
            (gift: { id: string; image: string }) => gift.id === 'Yellow'
        )[0].image,
        voucherTagTextColor: 'dark',
        discountTagProperties: {
            top: 'auto',
            right: '3%',
            bottom: '3%',
            left: 'auto',
            width: 65,
            fontSize: 11
        },
        image: tasteCover,
        tier: '',
        discount: false,
        voucher: true,
        isGift: false,
        backgroundColor: '#00D1D2',
        textColor: 'dark',
        disclaimer:
            '*Acest voucher se aplică doar la achiziția următoarelor pachete de HEETS, Fiit și TEREA: Teak, Silver, Russet, Sienna, Amber, Bronze, Yellow, Fiit Regular.'
    },

    ['CMP-VOTING-GAME-1']: {
        title: `Felicitări, ${userData?.first_name}!`,
        redeemedTitle: 'Felicitări! Ai revendicat voucherul câștigat!',

        type: 'casting-calls-voucher',
        ctaText: 'Revendică voucher',
        ctaLink: `${config.path.myRewards.index}/voucher`,
        voucherTag: tierGifts.filter(
            (gift: { id: string; image: string }) => gift.id === 'Yellow'
        )[0].image,
        voucherTagTextColor: 'dark',
        discountTagProperties: {
            top: 'auto',
            right: '2%',
            bottom: '2%',
            left: 'auto',
            width: 65,
            fontSize: 11
        },
        image: bdayCover,
        tier: '',
        discount: false,
        voucher: true,
        isGift: false,
        backgroundColor: '#00D1D2',
        textColor: 'dark'
    },
    ['CMP-VOTING-GAME-2']: {
        title: `Felicitări, ${userData?.first_name}!`,
        redeemedTitle: 'Felicitări! Ai revendicat voucherul câștigat!',

        type: 'discount-exclusiv-pentru-membri',
        ctaText: 'casting-calls-voucher',
        ctaLink: `${config.path.myRewards.index}/voucher`,
        voucherTag: tierGifts.filter(
            (gift: { id: string; image: string }) => gift.id === 'Yellow'
        )[0].image,
        voucherTagTextColor: 'dark',
        discountTagProperties: {
            top: 'auto',
            right: '2%',
            bottom: '2%',
            left: 'auto',
            width: 65,
            fontSize: 11
        },
        image: bdayCover,
        tier: '',
        discount: false,
        voucher: true,
        isGift: false,
        backgroundColor: '#00D1D2',
        textColor: 'dark'
    },
    ['CMP-VOTING-GAME-3']: {
        title: `Felicitări, ${userData?.first_name}!`,
        redeemedTitle: 'Felicitări! Ai revendicat voucherul câștigat!',

        type: 'casting-calls-voucher',
        ctaText: 'Revendică voucher',
        ctaLink: `${config.path.myRewards.index}/voucher`,
        voucherTag: tierGifts.filter(
            (gift: { id: string; image: string }) => gift.id === 'Yellow'
        )[0].image,
        voucherTagTextColor: 'dark',
        discountTagProperties: {
            top: 'auto',
            right: '2%',
            bottom: '2%',
            left: 'auto',
            width: 65,
            fontSize: 11
        },
        image: bdayCover,
        tier: '',
        discount: false,
        voucher: true,
        isGift: false,
        backgroundColor: '#00D1D2',
        textColor: 'dark'
    },
    ['CMP-VOTING-GAME-4']: {
        title: `Felicitări, ${userData?.first_name}!`,
        redeemedTitle: 'Felicitări! Ai revendicat voucherul câștigat!',

        type: 'casting-calls-voucher',
        ctaText: 'Revendică voucher',
        ctaLink: `${config.path.myRewards.index}/voucher`,
        voucherTag: tierGifts.filter(
            (gift: { id: string; image: string }) => gift.id === 'Yellow'
        )[0].image,
        voucherTagTextColor: 'dark',
        discountTagProperties: {
            top: 'auto',
            right: '2%',
            bottom: '2%',
            left: 'auto',
            width: 65,
            fontSize: 11
        },
        image: bdayCover,
        tier: '',
        discount: false,
        voucher: true,
        isGift: false,
        backgroundColor: '#00D1D2',
        textColor: 'dark'
    },
    ['CMP-FB-VEEV']: {
        title: 'Te așteaptă un voucher în valoare de 20 RON!',
        redeemedTitle: 'Felicitări! Ai revendicat cu succes voucherul!',

        type: 'discount-exclusiv-pentru-membri',
        ctaText: 'Revendică voucher',
        ctaLink: `${config.path.myRewards.index}/voucher`,
        image: voucher20veev,
        tier: '',
        discount: false,
        voucher: true,
        isGift: false,
        backgroundColor: '#00D1D2',
        textColor: 'dark',
        disclaimer: ''
    },
    ['CMP-FB']: {
        title: 'Te așteaptă un voucher în valoare de 20 RON!',
        redeemedTitle: 'Felicitări! Ai revendicat cu succes voucherul!',
        type: 'discount-exclusiv-pentru-membri',
        ctaText: 'Revendică voucher',
        ctaLink: `${config.path.myRewards.index}/voucher`,
        image: voucher20,
        tier: '',
        discount: false,
        voucher: true,
        isGift: false,
        backgroundColor: '#00D1D2',
        textColor: 'dark',
        disclaimer: ''
    },

    ['CMP-REAL-DREAMS']: {
        title: 'Ai câștigat un voucher într-o campanie IQOS!',
        redeemedTitle: 'Felicitări! Ai revendicat cu succes voucherul!',
        type: 'members-only-promotion',
        ctaText: 'Revendică voucher',
        ctaLink: `${config.path.myRewards.index}/voucher`,
        voucherTag: tierGifts.filter(
            (gift: { id: string; image: string }) => gift.id === 'Yellow'
        )[0].image,
        voucherTagTextColor: 'dark',
        discountTagProperties: {
            top: '6%',
            right: '3%',
            bottom: 'auto',
            left: 'auto',
            fontSize: 12,
            width: 70
        },
        image: callForDreamsVoucher,
        tier: '',
        discount: false,
        voucher: true,
        isGift: false,
        backgroundColor: '#F6F4F0',
        textColor: 'dark',
        disclaimer: ''
    },

    ['CMP-REAL-CONNECTIONS']: {
        title: 'Felicitări! Ai câștigat un voucher Next Level Pass  în valoare de 50 de RON.',
        redeemedTitle: 'Felicitări! Ai revendicat cu succes voucherul!',
        type: 'members-only-promotion',
        ctaText: 'Revendică voucher',
        ctaLink: `${config.path.myRewards.index}/voucher`,
        image: realConnectionVoucher,
        tier: '',
        discount: false,
        voucher: true,
        isGift: false,
        backgroundColor: '#F6F4F0',
        textColor: 'dark',
        disclaimer: ''
    },
    ['CMP-QUIZZ-TASTE-EXPL-1']: {
        title: `Te așteaptă un voucher în valoare de 20 RON!`,
        redeemedTitle: 'Felicitări, ai revendicat cu succes voucherul!',
        cancelledTitle: 'Această invitație a expirat!',
        type: 'members-only-promotion',
        ctaText: 'Revendică acum',
        ctaLink: `${config.path.myRewards.index}/voucher`,
        image: voucherTasteExploration,
        tier: '',
        discount: false,
        voucher: true,
        isGift: false,
        backgroundColor: '#00D1D2',
        textColor: 'dark',
        disclaimer: ''
    },
    ['CMP-QUIZZ-TASTE-EXPL-2']: {
        title: `Te așteaptă un voucher în valoare de 20 RON!`,
        redeemedTitle: 'Felicitări, ai revendicat cu succes voucherul!',
        cancelledTitle: 'Această invitație a expirat!',
        type: 'members-only-promotion',
        ctaText: 'Revendică acum',
        ctaLink: `${config.path.myRewards.index}/voucher`,
        image: voucherTasteExplorationVeev,
        tier: '',
        discount: false,
        voucher: true,
        isGift: false,
        backgroundColor: '#00D1D2',
        textColor: 'dark',
        disclaimer: ''
    },
    ['MGM-EXTRAGERE-1']: {
        title: `Ai câștigat un voucher în valoare de 100 RON!`,

        redeemedTitle: 'Felicitări! Ai revendicat cu succes voucherul!',

        type: 'MGM',
        ctaText: 'Revendică acum',
        ctaLink: `${config.path.myRewards.index}/voucher`,
        image: mgmExtragereCover,
        tier: '',
        discount: false,
        voucher: true,
        isGift: false,
        backgroundColor: '#34303D',
        textColor: 'light',
        labelMax: 10,
        labelCount: 1
    },
    ['MGM-EXTRAGERE-2']: {
        title: `Ai câștigat un voucher în valoare de 100 RON!`,

        redeemedTitle: 'Felicitări! Ai revendicat cu succes voucherul!',

        type: 'MGM',
        ctaText: 'Revendică acum',
        ctaLink: `${config.path.myRewards.index}/voucher`,
        image: mgmExtragereCover,
        tier: '',
        discount: false,
        voucher: true,
        isGift: false,
        backgroundColor: '#34303D',
        textColor: 'light',
        labelMax: 10,
        labelCount: 2
    },
    ['MGM-EXTRAGERE-3']: {
        title: `Ai câștigat un voucher în valoare de 100 RON!`,

        redeemedTitle: 'Felicitări! Ai revendicat cu succes voucherul!',

        type: 'MGM',
        ctaText: 'Revendică acum',
        ctaLink: `${config.path.myRewards.index}/voucher`,
        image: mgmExtragereCover,
        tier: '',
        discount: false,
        voucher: true,
        isGift: false,
        backgroundColor: '#34303D',
        textColor: 'light',
        labelMax: 10,
        labelCount: 3
    },
    ['MGM-EXTRAGERE-4']: {
        title: `Ai câștigat un voucher în valoare de 100 RON!`,

        redeemedTitle: 'Felicitări! Ai revendicat cu succes voucherul!',

        type: 'MGM',
        ctaText: 'Revendică acum',
        ctaLink: `${config.path.myRewards.index}/voucher`,
        image: mgmExtragereCover,
        tier: '',
        discount: false,
        voucher: true,
        isGift: false,
        backgroundColor: '#34303D',
        textColor: 'light',
        labelMax: 10,
        labelCount: 4
    },
    ['MGM-EXTRAGERE-5']: {
        title: `Ai câștigat un voucher în valoare de 100 RON!`,

        redeemedTitle: 'Felicitări! Ai revendicat cu succes voucherul!',

        type: 'MGM',
        ctaText: 'Revendică acum',
        ctaLink: `${config.path.myRewards.index}/voucher`,
        image: mgmExtragereCover,
        tier: '',
        discount: false,
        voucher: true,
        isGift: false,
        backgroundColor: '#34303D',
        textColor: 'light',
        labelMax: 10,
        labelCount: 5
    },
    ['MGM-EXTRAGERE-6']: {
        title: `Ai câștigat un voucher în valoare de 100 RON!`,

        redeemedTitle: 'Felicitări! Ai revendicat cu succes voucherul!',

        type: 'MGM',
        ctaText: 'Revendică acum',
        ctaLink: `${config.path.myRewards.index}/voucher`,
        image: mgmExtragereCover,
        tier: '',
        discount: false,
        voucher: true,
        isGift: false,
        backgroundColor: '#34303D',
        textColor: 'light',
        labelMax: 10,
        labelCount: 6
    },
    ['MGM-EXTRAGERE-7']: {
        title: `Ai câștigat un voucher în valoare de 100 RON!`,

        redeemedTitle: 'Felicitări! Ai revendicat cu succes voucherul!',

        type: 'MGM',
        ctaText: 'Revendică acum',
        ctaLink: `${config.path.myRewards.index}/voucher`,
        image: mgmExtragereCover,
        tier: '',
        discount: false,
        voucher: true,
        isGift: false,
        backgroundColor: '#34303D',
        textColor: 'light',
        labelMax: 10,
        labelCount: 7
    },
    ['MGM-EXTRAGERE-8']: {
        title: `Ai câștigat un voucher în valoare de 100 RON!`,

        redeemedTitle: 'Felicitări! Ai revendicat cu succes voucherul!',

        type: 'MGM',
        ctaText: 'Revendică acum',
        ctaLink: `${config.path.myRewards.index}/voucher`,
        image: mgmExtragereCover,
        tier: '',
        discount: false,
        voucher: true,
        isGift: false,
        backgroundColor: '#34303D',
        textColor: 'light',
        labelMax: 10,
        labelCount: 8
    },
    ['MGM-EXTRAGERE-9']: {
        title: `Ai câștigat un voucher în valoare de 100 RON!`,

        redeemedTitle: 'Felicitări! Ai revendicat cu succes voucherul!',

        type: 'MGM',
        ctaText: 'Revendică acum',
        ctaLink: `${config.path.myRewards.index}/voucher`,
        image: mgmExtragereCover,
        tier: '',
        discount: false,
        voucher: true,
        isGift: false,
        backgroundColor: '#34303D',
        textColor: 'light',
        labelMax: 10,
        labelCount: 9
    },
    ['MGM-EXTRAGERE-10']: {
        title: `Ai câștigat un voucher în valoare de 100 RON!`,

        redeemedTitle: 'Felicitări! Ai revendicat cu succes voucherul!',

        type: 'MGM',
        ctaText: 'Revendică acum',
        ctaLink: `${config.path.myRewards.index}/voucher`,
        image: mgmExtragereCover,
        tier: '',
        discount: false,
        voucher: true,
        isGift: false,
        backgroundColor: '#34303D',
        textColor: 'light',
        labelMax: 10,
        labelCount: 10
    },
    // GIFTS (CLUB)

    ['campanie-de-vara-premiu-saptamanal']: {
        title: `Bravo, ${userData?.first_name}!`,

        redeemedTitle: 'Cadoul tău este în drum spre tine!',

        cancelledTitle: 'Acest cadou s-a întors la noi',

        type: 'premiu-saptamanal-campanie-vara',
        ctaText: 'Revendică premiul',
        ctaLink: `${config.path.myRewards.index}/cadou-fizic`,
        image: summerVoucher,
        tier: '',
        discount: false,
        voucher: false,
        isGift: false,
        backgroundColor: '#F6F4F0',
        textColor: 'dark',
        giftImage: true,
        showDescription: true
    },

    ['real-people-faza-2-stardrift']: {
        title: `Un premiu care te inspiră să îți realizezi visul!`,
        redeemedTitle: 'Premiul tău este în drum spre tine!',
        cancelledTitle: 'Acest premiu s-a întors la noi',
        type: 'real-people-faza-2-stardrift',
        ctaText: 'Revendică premiul',
        ctaLink: `${config.path.myRewards.index}/cadou-fizic`,
        image: phase2Stardrift,
        tier: '',
        discount: false,
        voucher: false,
        isGift: false,
        isPrize: true,
        backgroundColor: '#F6F4F0',
        textColor: 'dark',
        giftImage: true,
        showDescription: true
    },

    ['real-people-premiu-saptamanal']: {
        title: `Un premiu care te inspiră să îți realizezi visul!`,
        redeemedTitle: 'Premiul tău este în drum spre tine!',
        cancelledTitle: 'Acest premiu s-a întors la noi',
        type: 'real-people-premiu-saptamanal',
        ctaText: 'Revendică premiul',
        ctaLink: `${config.path.myRewards.index}/cadou-fizic`,
        image: phase2Stardrift,
        tier: '',
        discount: false,
        voucher: false,
        isGift: false,
        isPrize: true,
        backgroundColor: '#F6F4F0',
        textColor: 'dark',
        giftImage: true,
        showDescription: true
    },

    ['gift-dropping']: {
        title: `Un cadou special pentru tine!`,

        redeemedTitle: 'Cadoul tău este în drum spre tine!',

        cancelledTitle: 'Acest cadou s-a întors la noi',

        type: 'cadou-special',
        ctaText: 'Revendică',
        ctaLink: `${config.path.myRewards.index}/cadou-fizic`,
        image: Gift1KDropping,
        tier: '',
        discount: false,
        voucher: false,
        isGift: true,
        backgroundColor: '#00D1D2',
        textColor: 'dark'
    },

    ['casting-calls-step-2-iframe-prize']: {
        title: `Felicitări, ${userData?.first_name}!`,

        redeemedTitle: 'Cadoul tău este în drum spre tine!',

        cancelledTitle: 'Acest cadou s-a întors la noi',

        type: 'casting-calls-step-2-iframe-prize',
        ctaText: 'Revendică premiul',
        ctaLink: `${config.path.myRewards.index}/cadou-fizic`,
        voucherTag: '',
        image: campaignWeeklyCover,
        tier: '',
        discount: false,
        voucher: false,
        isGift: false,
        backgroundColor: '#F6F4F0',
        textColor: 'dark',
        giftImage: true
    },
    ['casting-calls-step-2-weekly-prize']: {
        title: `Felicitări, ${userData?.first_name}!`,

        redeemedTitle: 'Cadoul tău este în drum spre tine!',

        cancelledTitle: 'Acest cadou s-a întors la noi',

        type: 'casting-calls-step-2-weekly-prize',
        ctaText: 'Revendică premiul',
        ctaLink: `${config.path.myRewards.index}/cadou-fizic`,
        voucherTag: '',
        image: campaignWeeklyCover,
        tier: '',
        discount: false,
        voucher: false,
        isGift: false,
        backgroundColor: '#F6F4F0',
        textColor: 'dark',
        giftImage: true
    },

    // Launch Campaign
    ['release-campaign-weekly-iqos-one']: {
        title: `Felicitări, ${userData?.first_name}!`,

        redeemedTitle: 'Cadoul tău este în drum spre tine!',

        cancelledTitle: 'Acest cadou s-a întors la noi',

        type: 'release-campaign-weekly-iqos-one',
        ctaText: 'Revendică premiul',
        ctaLink: `${config.path.myRewards.index}/cadou-fizic`,
        image: weeklyConver,
        tier: '',
        discount: false,
        backgroundColor: '#F6F4F0',
        textColor: 'dark'
    },
    ['release-campaign-weekly-iqos-duo']: {
        title: `Felicitări, ${userData?.first_name}!`,

        redeemedTitle: 'Cadoul tău este în drum spre tine!',

        cancelledTitle: 'Acest cadou s-a întors la noi',

        type: 'release-campaign-weekly-iqos-duo',
        ctaText: 'Revendică premiul',
        ctaLink: `${config.path.myRewards.index}/cadou-fizic`,
        image: weeklyConver,
        tier: '',
        discount: false,
        backgroundColor: '#F6F4F0',
        textColor: 'dark'
    },

    // Tier advancement
    ['platinum-advancement']: {
        title: 'Un cadou care ți se potrivește!',

        redeemedTitle: 'Cadoul tău este în drum spre tine!',

        cancelledTitle: 'Acest cadou s-a întors la noi',

        type: 'platinum-advancement',
        ctaText: 'Revendică',
        ctaLink: `${config.path.myRewards.index}/cadou-fizic`,
        image: platinumAdvancement,
        redeemed: false,
        tier: 'Platinum',
        discount: false,
        isGift: true,
        backgroundColor: '#00D1D2',
        textColor: 'dark',
        giftImage: true
    },
    ['gold-advancement']: {
        title: 'Un cadou care ți se potrivește!',

        redeemedTitle: 'Cadoul tău este în drum spre tine!',

        cancelledTitle: 'Acest cadou s-a întors la noi',

        type: 'gold-advancement',
        ctaText: 'Revendică',
        ctaLink: `${config.path.myRewards.index}/cadou-fizic`,
        image: goldAdvancement,
        redeemed: false,
        tier: 'Gold',
        discount: false,
        isGift: true,
        backgroundColor: '#00D1D2',
        textColor: 'dark',
        giftImage: true
    },

    // Anniversaries
    ['platinum-anniversary']: {
        title: 'Încă un an în care te bucuri de beneficiile Platinum!',

        redeemedTitle: 'Cadoul tău este în drum spre tine!',

        cancelledTitle: 'Acest cadou s-a întors la noi',

        type: 'platinum-anniversary',
        ctaText: 'Află mai multe',
        ctaLink: `${config.path.myRewards.index}/cadou-fizic`,
        image: platinumCover,
        redeemed: false,
        tier: 'Platinum',
        discount: false,
        isGift: true,
        backgroundColor: '#00D1D2',
        textColor: 'dark'
    },
    ['iqos-anniversary']: {
        title: 'Aniversarea ta cu IQOS',

        redeemedTitle: 'Cadoul tău este în drum spre tine!',

        cancelledTitle: 'Acest cadou s-a întors la noi',

        type: 'iqos-anniversary',
        ctaText: 'Descoperă cadoul',
        ctaLink: `${config.path.myRewards.index}/cadou-fizic`,
        image: anniversaryCover,
        redeemed: true,
        tier: '',
        discount: false,
        isGift: true,
        backgroundColor: '',
        textColor: 'dark'
    },
    ['birthday-gift']: {
        title: `La mulți ani, ${userData?.first_name}!`,

        redeemedTitle: 'Cadoul tău este în drum spre tine!',

        cancelledTitle: 'Acest cadou s-a întors la noi',

        type: 'birthday-gift',
        ctaText: 'Descoperă cadoul',
        ctaLink: `${config.path.myRewards.index}/cadou-fizic`,
        image: birthDayCover,
        redeemed: true,
        tier: '',
        discount: false,
        isGift: true,
        backgroundColor: '',
        textColor: 'dark'
    },
    ['castigatori-platinum5-septembrie']: {
        title: `Ai un cadou de revendicat!`,
        redeemedTitle: 'Cadoul tău este în drum spre tine!',

        cancelledTitle: 'Acest cadou s-a întors la noi',

        type: 'castigatori-platinum5-septembrie',
        ctaText: 'Revendică premiul',
        ctaLink: `${config.path.myRewards.index}/cadou-fizic`,
        image: platinum5,
        tier: 'Platinum',
        discount: false,
        voucher: false,
        isGift: true,
        backgroundColor: '#F6F4F0',
        textColor: 'dark',
        giftImage: false,
        showDescription: false
    },
    ['castigatori-platinum5-octombrie']: {
        title: `Ai un cadou de revendicat!`,
        redeemedTitle: 'Cadoul tău este în drum spre tine!',

        cancelledTitle: 'Acest cadou s-a întors la noi',

        type: 'castigatori-platinum5-octombrie',
        ctaText: 'Revendică premiul',
        ctaLink: `${config.path.myRewards.index}/cadou-fizic`,
        image: platinum5,
        tier: 'Platinum',
        discount: false,
        voucher: false,
        isGift: true,
        backgroundColor: '#F6F4F0',
        textColor: 'dark',
        giftImage: false,
        showDescription: false
    },
    ['castigatori-platinum5-noiembrie']: {
        title: `Ai un cadou de revendicat!`,
        redeemedTitle: 'Cadoul tău este în drum spre tine!',

        cancelledTitle: 'Acest cadou s-a întors la noi',

        type: 'castigatori-platinum5-noiembrie',
        ctaText: 'Revendică premiul',
        ctaLink: `${config.path.myRewards.index}/cadou-fizic`,
        image: platinum5,
        tier: 'Platinum',
        discount: false,
        voucher: false,
        isGift: true,
        backgroundColor: '#F6F4F0',
        textColor: 'dark',
        giftImage: false,
        showDescription: false
    },
    ['castigatori-platinum5-decembrie']: {
        title: `Ai un cadou de revendicat!`,
        redeemedTitle: 'Cadoul tău este în drum spre tine!',
        cancelledTitle: 'Acest cadou s-a întors la noi',

        type: 'castigatori-platinum5-decembrie',
        ctaText: 'Revendică premiul',
        ctaLink: `${config.path.myRewards.index}/cadou-fizic`,
        image: platinum5,
        tier: 'Platinum',
        discount: false,
        voucher: false,
        isGift: true,
        backgroundColor: '#F6F4F0',
        textColor: 'dark',
        giftImage: false,
        showDescription: false
    },
    ['castigatori-platinum5-ianuarie-2024']: {
        title: `Ai un cadou de revendicat!`,
        redeemedTitle: 'Cadoul tău este în drum spre tine!',
        cancelledTitle: 'Acest cadou s-a întors la noi',
        type: 'castigatori-platinum5-ianuarie-2024',
        ctaText: 'Revendică premiul',
        ctaLink: `${config.path.myRewards.index}/cadou-fizic`,
        image: platinum5,
        tier: 'Platinum',
        discount: false,
        voucher: false,
        isGift: true,
        backgroundColor: '#F6F4F0',
        textColor: 'dark',
        giftImage: false,
        showDescription: false
    },
    ['castigatori-platinum5-februarie-2024']: {
        title: `Ai un cadou de revendicat!`,
        redeemedTitle: 'Cadoul tău este în drum spre tine!',
        cancelledTitle: 'Acest cadou s-a întors la noi',
        type: 'castigatori-platinum5-februarie-2024',
        ctaText: 'Revendică premiul',
        ctaLink: `${config.path.myRewards.index}/cadou-fizic`,
        image: platinum5,
        tier: 'Platinum',
        discount: false,
        voucher: false,
        isGift: true,
        backgroundColor: '#F6F4F0',
        textColor: 'dark',
        giftImage: false,
        showDescription: false
    },

    ['castigatori-platinum5-ianuarie']: {
        title: `Ai un cadou de revendicat!`,
        redeemedTitle: 'Cadoul tău este în drum spre tine!',
        cancelledTitle: 'Acest cadou s-a întors la noi',

        type: 'castigatori-platinum5-ianuarie',
        ctaText: 'Revendică premiul',
        ctaLink: `${config.path.myRewards.index}/cadou-fizic`,
        image: platinum5,
        tier: 'Platinum',
        discount: false,
        voucher: false,
        isGift: true,
        backgroundColor: '#F6F4F0',
        textColor: 'dark',
        giftImage: false,
        showDescription: false
    },

    ['castigatori-platinum5-februarie']: {
        title: `Ai un cadou de revendicat!`,
        redeemedTitle: 'Cadoul tău este în drum spre tine!',
        cancelledTitle: 'Acest cadou s-a întors la noi',

        type: 'castigatori-platinum5-februarie',
        ctaText: 'Revendică premiul',
        ctaLink: `${config.path.myRewards.index}/cadou-fizic`,
        image: platinum5,
        tier: 'Platinum',
        discount: false,
        voucher: false,
        isGift: true,
        backgroundColor: '#F6F4F0',
        textColor: 'dark',
        giftImage: false,
        showDescription: false
    },

    ['flavourban-gift']: {
        title: 'Ai un cadou de revendicat!',

        redeemedTitle: 'Cadoul tău este în drum spre tine!',

        cancelledTitle: 'Acest cadou s-a întors la noi',

        ctaText: 'Descoperă cadoul',
        ctaLink: `${config.path.myRewards.index}/cadou-fizic`,
        image: flavourBanGift,
        redeemed: false,
        tier: '',
        discount: false,
        isGift: true,
        backgroundColor: '',
        textColor: 'dark'
    },
    ['castigatori-provocare-stardrift']: {
        title: `Ai un cadou de revendicat!`,
        redeemedTitle: 'Cadoul tău este în drum spre tine!',
        cancelledTitle: 'Acest cadou s-a întors la noi',

        type: 'castigatori-provocare-stardrift',
        ctaText: 'Revendică premiul',
        ctaLink: `${config.path.myRewards.index}/cadou-fizic`,
        image: '',
        tier: '',
        discount: false,
        voucher: false,
        isGift: false,
        isPrize: true,
        giftDescription: true,
        backgroundColor: '#F6F4F0',
        textColor: 'dark',
        giftImage: true,
        showDescription: false
    },
    ['castigatori-provocare-asociere-potrivita']: {
        title: `Felicitări! Ai câștigat un premiu în Provocarea IQOS.`,
        redeemedTitle: '',
        cancelledTitle: '',
        type: 'castigatori-provocare-asociere-potrivita',
        ctaText: 'Vezi detalii',
        ctaLink: `${config.path.myRewards.index}/cadou-fizic`,
        image: '',
        tier: '',
        discount: false,
        voucher: false,
        isGift: false,
        isPrize: true,
        giftDescription: true,
        backgroundColor: '#44135D',
        textColor: 'light',
        giftImage: true,
        showDescription: false
    },
    ['nio-castigatori-provocare-asociere-potrivita']: {
        title: `Felicitări! Ai câștigat un premiu în Provocarea IQOS.`,
        redeemedTitle: 'Premiul tău este în drum spre tine!',
        cancelledTitle: '',
        type: 'nio-castigatori-provocare-asociere-potrivita',
        ctaText: 'Vezi detalii',
        ctaLink: `${config.path.myRewards.index}/cadou-fizic`,
        image: '',
        tier: '',
        discount: false,
        voucher: false,
        isGift: false,
        isPrize: true,
        giftDescription: true,
        backgroundColor: '#F6F4F0',
        textColor: 'dark',
        giftImage: true,
        showDescription: false
    },
    ['provocarea-iqos-februarie-martie-2024']: {
        title: 'Felicitări! Ai câștigat un premiu în Provocarea IQOS!',
        redeemedTitle: 'Cadoul tău este în drum spre tine!',
        cancelledTitle: 'Acest cadou s-a întors la noi',
        type: 'provocarea-iqos-februarie-martie-2024',
        ctaText: 'Vezi detalii',
        ctaLink: `${config.path.myRewards.index}/cadou-fizic`,
        image: '',
        tier: '',
        discount: false,
        voucher: false,
        isGift: false,
        isPrize: true,
        giftDescription: false,
        backgroundColor: '#f6f4f0',
        textColor: 'dark',
        giftImage: true,
        showDescription: false,
        noAvailableMessage: true
    },
    ['mgm-experience-grand-prize']: {
        title: `Experiența ta la Denisa Boutique în Poiana Brașov`,
        redeemedTitle: 'Cadoul tău este în drum spre tine!',
        cancelledTitle: 'Acest cadou s-a întors la noi',

        type: 'mgm-experience-grand-prize',
        ctaText: 'Descoperă',
        ctaLink: `${config.path.myRewards.index}/cadou-fizic`,
        image: '',
        tier: '',
        discount: false,
        voucher: false,
        isGift: false,
        isPrize: true,
        giftDescription: true,
        backgroundColor: '#00D1D2',
        textColor: 'dark',
        giftImage: true,
        showDescription: false
    },

    ['hyped-tickets']: {
        title: `Felicitări, ai revendicat cu succes invitația!`,
        redeemedTitle: 'Felicitări, ai revendicat cu succes invitația!',
        cancelledTitle: 'Această invitație a expirat!',
        type: 'hyped-tickets',
        ctaText: 'Descarcă invitația',
        ctaLink: `${config.path.myRewards.index}/cadou-fizic`,
        customCtaText: 'Descarcă invitația',
        image: hypedTickets,
        tier: '',
        discount: false,
        voucher: false,
        isGift: false,
        isPrize: false,
        giftDescription: false,
        backgroundColor: '#00D1D2',
        textColor: 'dark',
        giftImage: false,
        showDescription: false
    },

    ['rdw-tickets']: {
        title: `Felicitări, ai revendicat cu succes invitația!`,
        redeemedTitle: 'Felicitări, ai revendicat cu succes invitația!',
        cancelledTitle: 'Această invitație a expirat!',
        type: 'rdw-tickets',
        ctaText: 'Descarcă invitația',
        ctaLink: `${config.path.myRewards.index}/cadou-fizic`,
        customCtaText: 'Descarcă invitația',
        image: rdwTickets,
        tier: '',
        discount: false,
        voucher: false,
        isGift: false,
        isPrize: false,
        giftDescription: false,
        backgroundColor: '#00D1D2',
        textColor: 'dark',
        giftImage: false,
        showDescription: false
    },

    ['brunch-entourage-16-03']: {
        title: `Felicitări! Ai câștigat o invitație dublă la brunch!`,
        redeemedTitle: 'Felicitări! Ai câștigat o invitație dublă la brunch!',
        cancelledTitle: 'Această invitație a expirat!',
        type: 'brunch-entourage-06-04',
        ctaText: 'Vezi detalii',
        ctaLink: `${config.path.myRewards.index}/cadou-fizic`,
        customCtaText: 'Vezi detalii',
        image: brunchEntourage,
        tier: '',
        discount: false,
        voucher: false,
        isGift: false,
        isPrize: true,
        giftDescription: false,
        backgroundColor: '#00D1D2',
        textColor: 'dark',
        giftImage: false,
        showDescription: false,
        noAvailableMessage: true
    },

    ['brunch-entourage-23-03']: {
        title: `Felicitări! Ai câștigat o invitație dublă la brunch!`,
        redeemedTitle: 'Felicitări! Ai câștigat o invitație dublă la brunch!',
        cancelledTitle: 'Această invitație a expirat!',
        type: 'brunch-entourage-06-04',
        ctaText: 'Vezi detalii',
        ctaLink: `${config.path.myRewards.index}/cadou-fizic`,
        customCtaText: 'Vezi detalii',
        image: brunchEntourage,
        tier: '',
        discount: false,
        voucher: false,
        isGift: false,
        isPrize: true,
        giftDescription: false,
        backgroundColor: '#00D1D2',
        textColor: 'dark',
        giftImage: false,
        showDescription: false,
        noAvailableMessage: true
    },
    ['brunch-entourage-30-03']: {
        title: `Felicitări! Ai câștigat o invitație dublă la brunch!`,
        redeemedTitle: 'Felicitări! Ai câștigat o invitație dublă la brunch!',
        cancelledTitle: 'Această invitație a expirat!',
        type: 'brunch-entourage-06-04',
        ctaText: 'Vezi detalii',
        ctaLink: `${config.path.myRewards.index}/cadou-fizic`,
        customCtaText: 'Vezi detalii',
        image: brunchEntourage,
        tier: '',
        discount: false,
        voucher: false,
        isGift: false,
        isPrize: true,
        giftDescription: false,
        backgroundColor: '#00D1D2',
        textColor: 'dark',
        giftImage: false,
        showDescription: false,
        noAvailableMessage: true
    },

    ['brunch-entourage-06-04']: {
        title: `Felicitări! Ai câștigat o invitație dublă la brunch!`,
        redeemedTitle: 'Felicitări! Ai câștigat o invitație dublă la brunch!',
        cancelledTitle: 'Această invitație a expirat!',
        type: 'brunch-entourage-06-04',
        ctaText: 'Vezi detalii',
        ctaLink: `${config.path.myRewards.index}/cadou-fizic`,
        customCtaText: 'Vezi detalii',
        image: brunchEntourage,
        tier: '',
        discount: false,
        voucher: false,
        isGift: false,
        isPrize: true,
        giftDescription: false,
        backgroundColor: '#00D1D2',
        textColor: 'dark',
        giftImage: false,
        showDescription: false,
        noAvailableMessage: true
    },
    ['taste-exploration']: {
        title: ``,
        redeemedTitle: 'Premiul tău este în drum spre tine!',
        cancelledTitle: '',
        type: 'taste-exploration',
        ctaText: 'Vezi detalii',
        ctaLink: `${config.path.myRewards.index}/cadou-fizic`,
        image: '',
        tier: '',
        discount: false,
        voucher: false,
        isGift: false,
        isPrize: true,
        giftDescription: true,
        backgroundColor: '#34303D',
        textColor: 'light',
        giftImage: true,
        showDescription: false
    },

    ['live-ticketing-grand-prizes']: {
        title: `Felicitări! Ai câștigat un premiu!`,
        redeemedTitle: 'Premiul tău e în drum spre tine!',
        cancelledTitle: 'Acest cadou s-a întors la noi',
        type: 'live-ticketing-grand-prizes',
        ctaText: 'Vezi detalii',
        ctaLink: `${config.path.myRewards.index}/cadou-fizic`,
        image: '',
        tier: '',
        discount: false,
        voucher: false,
        isGift: false,
        isPrize: true,
        giftDescription: true,
        backgroundColor: '#f6f4f0',
        textColor: 'dark',
        giftImage: true,
        showDescription: false,
        noAvailableMessage: true
    },
    ['live-ticketing-weekly-prizes']: {
        title: `Felicitări! Ai câștigat un premiu!`,
        redeemedTitle: 'Premiul tău e în drum spre tine!',
        cancelledTitle: 'Acest cadou s-a întors la noi',
        type: 'live-ticketing-weekly-prizes',
        ctaText: 'Revendică acum',
        ctaLink: `${config.path.myRewards.index}/cadou-fizic`,
        image: '',
        tier: '',
        discount: false,
        voucher: false,
        isGift: false,
        isPrize: true,
        giftDescription: false,
        backgroundColor: '#f6f4f0',
        textColor: 'dark',
        giftImage: true,
        showDescription: false
    },

    ['real-connection-premiu-instant']: {
        title: `Felicitări! Ai câștigat un accesoriu IQOS.`,
        redeemedTitle: 'Premiul tău este în drum spre tine!',
        cancelledTitle: 'Acest premiu s-a întors la noi',
        type: 'real-people-premiu-saptamanal',
        ctaText: 'Revendică premiul',
        ctaLink: `${config.path.myRewards.index}/cadou-fizic`,
        image: realConnectionAccessories,
        tier: '',
        discount: false,
        voucher: false,
        isGift: false,
        isPrize: true,
        backgroundColor: '#F6F4F0',
        textColor: 'dark',
        giftImage: false,
        showDescription: true
    },
    ['real-connection-premiu-saptamanal']: {
        title: `Felicitări! Ai câștigat un dispozitiv IQOS ILUMA PRIME.`,
        redeemedTitle: 'Premiul tău este în drum spre tine!',
        cancelledTitle: 'Acest premiu s-a întors la noi',
        type: 'real-connection-premiu-saptamanal',
        ctaText: 'Revendică premiul',
        ctaLink: `${config.path.myRewards.index}/cadou-fizic`,
        image: realConnectionIluma,
        tier: '',
        discount: false,
        voucher: false,
        isGift: false,
        isPrize: true,
        backgroundColor: '#F6F4F0',
        textColor: 'dark',
        giftImage: false,
        showDescription: true
    },
    ['real-connection-premiu-saptamanal:ticket']: {
        title: `Felicitări! Ai câștigat o experiență VIP la concertul anului.`,
        redeemedTitle: 'Felicitări! Ai câștigat o experiență VIP la concertul anului.',
        cancelledTitle: 'Felicitări! Ai câștigat o experiență VIP la concertul anului.',
        type: 'real-connection-premiu-saptamanal:ticket',
        ctaText: 'Vezi detalii',
        ctaLink: `${config.path.myRewards.index}/cadou-fizic`,
        image: realConnectionVip,
        tier: '',
        discount: false,
        voucher: false,
        isGift: false,
        isPrize: true,
        backgroundColor: '#F6F4F0',
        textColor: 'dark',
        giftImage: false,
        showDescription: true
    },
    ['provocarea-iqos-aprilie-mai-2024']: {
        title: `Felicitări! Ai câștigat un premiu în Provocarea IQOS!`,
        redeemedTitle: 'Cadoul tău este în drum spre tine!',
        cancelledTitle: 'Acest cadou s-a întors la noi',

        type: 'provocarea-iqos-aprilie-mai-2024',
        ctaText: 'Vezi detalii',
        ctaLink: `${config.path.myRewards.index}/cadou-fizic`,
        image: '',
        tier: '',
        discount: false,
        voucher: false,
        isGift: false,
        isPrize: true,
        giftDescription: true,
        backgroundColor: '#F6F4F0',
        textColor: 'dark',
        giftImage: true,
        showDescription: false
    },

    // PARTNERS DISCOUNTS
    ['discount-elefant-silver']: {
        title: 'Noul tău voucher de la partenerii IQOS Club!',

        redeemedTitle: '',

        partnersTitle: 'Primul tău voucher de la partenerii IQOS Club',

        cancelledTitle: 'Acest cadou s-a întors la noi',

        type: 'discount-elefant-silver',
        ctaText: 'Folosește-l acum',
        ctaLink: config.path.elefant,
        image: elefantCover,
        redeemed: false,
        voucherTag: userData?.tier
            ? tierGiftsElefant.filter(
                  (gift: { id: string; image: string }) => gift.id === 'Silver'
              )[0].image
            : '',
        discountTagProperties: {
            top: '5%',
            right: '28%',
            bottom: 'auto',
            left: 'auto',
            width: 75
        },
        tier: '',
        discount: false,
        voucher: true,
        isGift: false,
        backgroundColor: '#F6F4F0',
        textColor: 'dark',
        value: 0,
        partnersGift: true
    },
    ['discount-elefant-gold']: {
        title: 'Noul tău voucher de la partenerii IQOS Club!',

        redeemedTitle: '',

        partnersTitle: 'Primul tău voucher de la partenerii IQOS Club',

        cancelledTitle: 'Acest cadou s-a întors la noi',

        type: 'discount-elefant-gold',
        ctaText: 'Folosește-l acum',
        ctaLink: config.path.elefant,
        image: elefantCover,
        redeemed: false,
        voucherTag: userData?.tier
            ? tierGiftsElefant.filter(
                  (gift: { id: string; image: string }) => gift.id === 'Gold'
              )[0].image
            : '',
        discountTagProperties: {
            top: '5%',
            right: '28%',
            bottom: 'auto',
            left: 'auto',
            width: 75
        },
        tier: '',
        discount: false,
        voucher: true,
        isGift: false,
        backgroundColor: '#F6F4F0',
        textColor: 'dark',
        value: 0,
        partnersGift: true
    },
    ['discount-elefant-platinum']: {
        title: 'Noul tău voucher de la partenerii IQOS Club!',

        redeemedTitle: '',

        partnersTitle: 'Primul tău voucher de la partenerii IQOS Club',

        cancelledTitle: 'Acest cadou s-a întors la noi',

        type: 'discount-elefant-platinum',
        ctaText: 'Folosește-l acum',
        ctaLink: config.path.elefant,
        image: elefantCover,
        redeemed: false,
        voucherTag: userData?.tier
            ? tierGiftsElefant.filter(
                  (gift: { id: string; image: string }) => gift.id === 'Platinum'
              )[0].image
            : '',
        discountTagProperties: {
            top: '5%',
            right: '28%',
            bottom: 'auto',
            left: 'auto',
            width: 75
        },
        tier: '',
        discount: false,
        voucher: true,
        isGift: false,
        backgroundColor: '#F6F4F0',
        textColor: 'dark',
        value: 0,
        partnersGift: true
    },

    ['samsung-discount-silver']: {
        title: 'Noul tău voucher de la partenerii IQOS Club',

        redeemedTitle: '',

        partnersTitle: 'Noul tău voucher de la partenerii IQOS Club',

        cancelledTitle: 'Acest cadou s-a întors la noi',

        type: 'samsung-discount-silver',
        ctaText: 'Folosește-l acum',
        ctaLink: config.path.samsungProducts,
        image: samsung,
        redeemed: false,
        voucherTag: userData?.tier
            ? tierGiftsSamsung.filter(
                  (gift: { id: string; image: string }) => gift.id === 'Silver'
              )[0].image
            : '',
        discountTagProperties: {
            top: 'auto',
            right: '28%',
            bottom: '36%',
            left: 'auto',
            width: 55
        },
        tier: '',
        discount: false,
        voucher: true,
        isGift: false,
        backgroundColor: '#F6F4F0',
        textColor: 'dark',
        value: 0,
        partnersGift: true
    },
    ['samsung-discount-gold']: {
        title: 'Noul tău voucher de la partenerii IQOS Club',

        redeemedTitle: '',

        partnersTitle: 'Noul tău voucher de la partenerii IQOS Club',

        cancelledTitle: 'Acest cadou s-a întors la noi',

        type: 'samsung-discount-gold',
        ctaText: 'Folosește-l acum',
        ctaLink: config.path.samsungProducts,
        image: samsung,
        redeemed: false,
        voucherTag: userData?.tier
            ? tierGiftsSamsung.filter(
                  (gift: { id: string; image: string }) => gift.id === 'Gold'
              )[0].image
            : '',
        discountTagProperties: {
            top: 'auto',
            right: '28%',
            bottom: '36%',
            left: 'auto',
            width: 55
        },
        tier: '',
        discount: false,
        voucher: true,
        isGift: false,
        backgroundColor: '#F6F4F0',
        textColor: 'dark',
        value: 0,
        partnersGift: true
    },
    ['samsung-discount-platinum']: {
        title: 'Noul tău voucher de la partenerii IQOS Club',

        redeemedTitle: '',

        cancelledTitle: 'Acest cadou s-a întors la noi',

        type: 'samsung-discount-platinum',
        ctaText: 'Folosește-l acum',
        ctaLink: config.path.samsungProducts,
        image: samsung,
        redeemed: false,
        voucherTag: userData?.tier
            ? tierGiftsSamsung.filter(
                  (gift: { id: string; image: string }) => gift.id === 'Platinum'
              )[0].image
            : '',
        discountTagProperties: {
            top: 'auto',
            right: '28%',
            bottom: '36%',
            left: 'auto',
            width: 55
        },
        tier: '',
        discount: false,
        voucher: true,
        isGift: false,
        backgroundColor: '#F6F4F0',
        textColor: 'dark',
        value: 0,
        partnersGift: true
    },
    ['electrolux-discount']: {
        title: 'Noul tău voucher de la partenerii IQOS Club!',
        partnersTitle: '15% REDUCERE LA PRODUSELE ELECTROLUX',

        redeemedTitle: '',

        cancelledTitle: 'Acest cadou s-a întors la noi',

        type: 'electrolux-discount',
        ctaText: 'Folosește-l acum',
        ctaLink: `/recompensele-mele/discount-parteneri/electrolux-discount`,
        externalLink: config.path.electroluxProducts,
        image: electrolux,
        redeemed: false,
        voucherTag: '',
        tier: '',
        discount: false,
        voucher: true,
        isGift: false,
        backgroundColor: '#F6F4F0',
        textColor: 'dark',
        value: 0,
        partnersGift: true
    },

    ['krups-discount']: {
        title: 'Noul tău voucher de la partenerii IQOS Club!',

        redeemedTitle: 'Noul tău voucher de la partenerii IQOS Club!',
        partnersTitle: '300 RON reducere EXTRA* la espressoarele automate Krups',
        partnersDescription: `<p>Poți savura o cafea perfectă exact pe gustul tău, indiferent dacă preferi un espresso intens pentru a-ți revigora dimineața sau un cappuccino cremos pentru a te bucura de pauza de prânz. </br> <b> Folosește acum voucherul de 300 de RON pentru achiziționarea oricărui espressor automat Krups pe <a href="https://shop.tefal.ro/krups/expressoare-krups" target="_blank">shop.tefal.ro</a>.</b> </br> <small>*pentru reducerea de 300 RON, adaugă codul în coș. Participă în campanie doar espressoarele automate Krups, inclusiv cele în promo.</small></p>`,
        cancelledTitle: 'Acest cadou s-a întors la noi',

        type: 'krups-discount',
        ctaText: 'Folosește-l acum',
        ctaLink: `/recompensele-mele/discount-parteneri/krups-discount`,
        externalLink: config.path.krups,
        image: krups,
        redeemed: false,
        tier: '',
        discount: false,
        voucher: true,
        isGift: false,
        backgroundColor: '#F6F4F0',
        textColor: 'dark',
        value: 0,
        partnersGift: true
    },

    ['rowenta-discount']: {
        title: 'Noul tău voucher de la partenerii IQOS Club!',

        redeemedTitle: '',

        partnersTitle: '20% reducere EXTRA* la toate produsele Rowenta',

        partnersDescription: `Ai avut măcar o dată "bad hair day" sau o vizită "last minute" atunci când te așteptai mai puțin? <br/> <b>Acum ai 20% reducere EXTRA pe Shop Rowenta, să ai grijă de tine, dar și de casa ta!</b> <br/> <small>*reducerea de 20% se aplică după ce adaugi codul în coș, suplimentar tuturor produselor.</small>`,

        cancelledTitle: 'Acest cadou s-a întors la noi',

        type: 'rowenta-discount',
        ctaText: 'Folosește-l acum',
        ctaLink: `/recompensele-mele/discount-parteneri/rowenta-discount`,
        externalLink: config.path.rowenta,
        image: rowenta,
        redeemed: false,
        tier: '',
        discount: false,
        voucher: true,
        isGift: false,
        backgroundColor: '#F6F4F0',
        textColor: 'dark',
        value: 0,
        partnersGift: true
    },

    ['tefal-discount']: {
        title: 'Noul tău voucher de la partenerii IQOS Club!',

        redeemedTitle: '',
        partnersTitle: '20% reducere EXTRA* la aparatele de gătit Tefal',
        partnersDescription: `Cine nu a folosit măcar o dată expresia “Iar trebuie
                        să gătesc ceva...și repede!”? Aparatele de gătit
                        inteligente sunt soluția perfectă pentru cei care
                        vor să economisească timp și energie în bucătărie.<br/>
                        <strong>Pentru că ești membru în IQOS CLUB, ai 20%
                        reducere EXTRA pe <a href="https://shop.tefal.ro/aparate-de-gatit" target="_blank">shop Tefal</a>, la aparatele
                        de gătit care-ți vor face viața mai ușoară.</strong> </br> <small>*pentru reducerea de 20%, adaugă codul în coș. Participă în campanie și produsele aflate în promo și pachetele.</small>`,

        cancelledTitle: 'Acest cadou s-a întors la noi',
        type: 'tefal-discount',
        ctaText: 'Folosește-l acum',
        ctaLink: `/recompensele-mele/discount-parteneri/tefal-discount`,
        externalLink: config.path.tefal,
        image: tefal,
        redeemed: false,
        tier: '',
        discount: false,
        voucher: true,
        isGift: false,
        backgroundColor: '#F6F4F0',
        textColor: 'dark',
        value: 0,
        partnersGift: true
    },
    ['november-rowenta-discount']: {
        title: 'Noul tău voucher de la partenerii IQOS Club!',

        redeemedTitle: '',
        partnersTitle: '20% reducere EXTRA* la aspiratoarele verticale și robot Rowenta',
        partnersDescription: `Party? Sună bine! Curățenie după party? Nu sună prea bine...
                      Rowenta got you covered! Pentru delegare completă şi control de la
                      distanţă, alege un robot de aspirare, pentru mobilitate sporită şi
                      curăţenie eficientă în orice colţ al casei, un aspirator vertical fără fir te
                      scapă rapid de treabă.<br/>
                     <strong> În IQOS Club ești mereu câștigător! Ai 20% reducere EXTRA pe
                      <a href="https://shop.rowenta.ro/i/rowenta-iqos" target="_blank">IQOS (rowenta.ro)</a>, la aspiratoarele verticale și roboți.</strong> <br/> <small>*pentru reducerea de 20%, adaugă codul în coș.
                      Participă în campanie și produsele aflate în promo și
                      pachetele.</small>`,

        cancelledTitle: 'Acest cadou s-a întors la noi',

        type: 'november-rowenta-discount',
        ctaText: 'Folosește-l acum',
        ctaLink: '/recompensele-mele/discount-parteneri/november-rowenta-discount',
        externalLink: config.path.rowenta2,
        image: rowentaDiscount2,
        redeemed: false,
        tier: '',
        discount: false,
        voucher: true,
        isGift: false,
        backgroundColor: '#F6F4F0',
        textColor: 'dark',
        value: 0,
        partnersGift: true
    },
    ['december-rowenta-discount']: {
        title: '20% reducere EXTRA* la uscătoarele de păr Rowenta',

        redeemedTitle: '',

        partnersTitle: '20% reducere EXTRA* la uscătoarele de păr Rowenta',

        partnersDescription: `Schimbă-ți look-ul zilnic și arată-ți stilul cu încredere! Fie că vrei părul drept sau bucle fabuloase, cu uscătoarele de păr de la Rowenta, totul devine posibil!<br/><strong>În clubul IQOS ești mereu câștigător! Ai 20% reducere EXTRA pe <a href="https://shop.rowenta.ro" target="_blank">shop.rowenta.ro</a>, la uscătoarele de păr Rowenta</strong><br/><small>*pentru reducerea de 20%, adaugă codul în coș. Participă în campanie și produsele aflate în promo și pachetele.<br/>** nu se aplică la periile rotative</small>`,

        cancelledTitle: 'Acest cadou s-a întors la noi',

        type: 'rowenta-discount',
        ctaText: 'Folosește-l acum',
        ctaLink: `/recompensele-mele/discount-parteneri/december-rowenta-discount`,
        externalLink: config.path.rowenta,
        image: rowentaDiscountDec,
        redeemed: false,
        tier: '',
        discount: false,
        voucher: true,
        isGift: false,
        backgroundColor: '#F6F4F0',
        textColor: 'dark',
        value: 0,
        partnersGift: true
    },

    ['rowenta-discount-martie-aprilie-2024']: {
        title: '20% reducere EXTRA* la plăci și perii de îndreptat părul.',

        redeemedTitle: '',

        partnersTitle: '20% reducere EXTRA* la plăci și perii de îndreptat părul.',

        partnersDescription: `Păr perfect într-o singură trecere! Adio păr încurcat și electrificat! Bun venit păr neted și strălucitor, gata pentru orice coafură! O singură trecere și părul tău va fi perfect îndreptat, neted, strălucitor și gata pentru un look spectaculos.
                            <br/><br/><small>*pentru reducerea de 20%, adaugă codul în coș. Participă în campanie și produsele aflate în promo și pachetele.</small>`,

        cancelledTitle: 'Acest cadou s-a întors la noi',

        type: 'rowenta-discount',
        ctaText: 'Cumpără acum cu -20%',
        ctaLink: `/recompensele-mele/discount-parteneri/rowenta-discount-martie-aprilie-2024`,
        externalLink: config.path.rowentaPlaci,
        image: rowentaDiscountMar,
        redeemed: false,
        tier: '',
        discount: false,
        voucher: true,
        isGift: false,
        backgroundColor: '#F6F4F0',
        textColor: 'dark',
        value: 0,
        partnersGift: true
    },

    ['tefal-discount-martie-aprilie-2024']: {
        title: '20% reducere EXTRA* la fiarele de călcat Tefal.',

        redeemedTitle: '',

        partnersTitle: '20% reducere EXTRA* la fiarele de călcat Tefal.',

        partnersDescription: `Îngrijeşte-ţi hainele cu stil!<br/>
                            Cu <strong>Tefal</strong> îndepărtarea cutelor devine floare la ureche - cu abur puternic şi alunecare de invidiat, vei face minuni într-o clipă! <br/>
                            Explorează gama noastră de fiare de călcat – vei găsi cu siguranţă unul potrivit pentru tine!
                            <br/><br/><small>*pentru reducerea de 20%, adaugă codul în coș. Participă în campanie și produsele aflate în promo și pachetele.</small>`,

        cancelledTitle: 'Acest cadou s-a întors la noi',

        type: 'tefal-discount-martie-aprilie-2024',
        ctaText: 'Cumpără acum cu -20%',
        ctaLink: `/recompensele-mele/discount-parteneri/tefal-discount-martie-aprilie-2024`,
        externalLink: config.path.tefalFierCalcat,
        image: tefalDiscountMar,
        redeemed: false,
        tier: '',
        discount: false,
        voucher: true,
        isGift: false,
        backgroundColor: '#F6F4F0',
        textColor: 'dark',
        value: 0,
        partnersGift: true
    },

    ['oxette-discount-aprilie']: {
        title: 'Bucură-te de 25% reducere la produsele din colecția nouă Oxette.',
        partnersTitle: 'Bucură-te de 25% reducere la produsele din colecția nouă Oxette.',
        redeemedTitle: '',

        cancelledTitle: 'Acest cadou s-a întors la noi',
        partnersDescription: `Descoperă bijuteriile și accesoriile Oxette din colecția de primăvară-vară pe <a href="https://www.oxette.ro/categorie-produs/noutati/" target="_blank">www.oxette.ro</a> și strălucește în orice moment al zilei.
                        Bucură-te de 25% REDUCERE la toate produsele din colecția nouă. 
                        <br/><br/><small>Oferta nu se cumulează cu alte campanii sau reduceri și este valabilă doar la achizițiile online de pe <a href="https://www.oxette.ro/categorie-produs/noutati/" target="_blank">www.oxette.ro</a>. </small>`,

        type: 'oxette-discount-aprilie',
        ctaText: 'Folosește-l acum',
        ctaLink: `/recompensele-mele/discount-parteneri/oxette-discount-aprilie`,
        externalLink: 'https://www.oxette.ro/categorie-produs/noutati/',
        image: oxetteVoucher,
        redeemed: false,
        tier: '',
        discount: false,
        voucher: true,
        isGift: false,
        backgroundColor: '#F6F4F0',
        textColor: 'dark',
        value: 0,
        partnersGift: true
    },
    ['av-store-discount-aprilie-mai']: {
        title: 'REDUCERE de 15% la toate produsele Bowers & Wilkins, Devialet, KEF și Sennheiser.',
        partnersTitle:
            'REDUCERE de 15% la toate produsele Bowers & Wilkins, Devialet, KEF și Sennheiser.',
        redeemedTitle: '',

        cancelledTitle: 'Acest cadou s-a întors la noi',
        partnersDescription: `E momentul să te pregătești pentru sezonul primăvară-vară cu un refresh audio, pentru că ai parte de o REDUCERE de 15% la toate produsele Bowers & Wilkins, Devialet, KEF și Sennheiser achiziționate de pe <a href="https://www.avstore.ro" target="_blank">www.avstore.ro</a>. 
                        Reducerile pot fi obținute exclusiv pentru cumpărături online pe website-ul <a href="https://www.avstore.ro" target="_blank">www.avstore.ro</a>, pentru brand-urile Bowers & Wilkins, Devialet, KEF și Sennheiser. 
                        <br/><br/><small>Codul poate fi utilizat pentru o singură achiziție și se aplică doar produselor individuale, nu și pachetelor ce includ produse de la brand-urile participante. Codul nu poate fi preschimbat în bani și nu se cumulează cu alte campanii sau reduceri. </small>`,

        type: 'av-store-discount-aprilie-mai',
        ctaText: 'Folosește-l acum',
        ctaLink: `/recompensele-mele/discount-parteneri/av-store-discount-aprilie-mai`,
        externalLink: 'https://www.avstore.ro',
        image: avStoreVoucher,
        pageImage: avStoreVoucherPage,
        redeemed: false,
        tier: '',
        discount: false,
        voucher: true,
        isGift: false,
        backgroundColor: '#F6F4F0',
        textColor: 'dark',
        value: 0,
        partnersGift: true
    },
    ['voucher-electrolux']: {
        title: 'Până la 19% OFF la produsele ELECTROLUX',
        partnersTitle:
            'Până la 19% OFF la produsele ELECTROLUX din categoriile Fiare de călcat (17%) și Aspiratoare verticale (19%).',
        redeemedTitle: '',

        cancelledTitle: 'Acest cadou s-a întors la noi',
        partnersDescription: `Foloseşti codul unic* şi ai parte de reducere la produsele disponibile pe <a href="https://www.electrolux.ro" target="_blank">https://www.electrolux.ro</a>
                              în categoriile Fiare de călcat și Aspiratoare verticale.<br/> Doar introduci codul tău unic în câmpul corespunzător din coșul de cumpărături înainte de a finaliza comanda şi te bucuri de discounturi**.
                         <br/><br/> <small>* Codul de reducere este valabil pentru o singură comandă, doar pentru produsele ELECTROLUX din categoriile menţionate, până la data de 30 iunie 2024.</small>
                        <br/><small>** Oferta este valabilă în limita stocului disponibil şi nu poate fi combinată cu nicio altă ofertă.</small>`,

        type: 'voucher-electrolux',
        ctaText: 'Folosește-l acum',
        ctaLink: `/recompensele-mele/discount-parteneri/voucher-electrolux`,
        externalLink: 'https://www.electrolux.ro',
        image: electroluxMai2024,
        pageImage: electroluxMai2024,
        redeemed: false,
        tier: '',
        discount: false,
        voucher: false,
        isGift: false,
        backgroundColor: '#F6F4F0',
        textColor: 'dark',
        value: 0,
        partnersGift: true,
        noDiscountType: false
    }
};

export const carouselSettings = {
    infinite: true,
    speed: 500,
    autoplay: false,
    pauseOnHover: true,
    autoplaySpeed: 5000,
    adaptiveHeight: true,
    arrows: false,
    dots: true,
    draggable: true
};

export const voucherOptions = [
    {
        id: 'online',
        title: 'Magazinele și insulele IQOS sau pe IQOS.RO',
        description: `<span>
                    Voucherul poate fi folosit pentru achiziția de dispozitive, rezerve de tutun și accesorii, atât pe IQOS.ro cât și în magazinele și insulele IQOS. 
                    </span>
                    <span>
                    Asigură-te că valoarea totală a comenzii tale este mai mare sau egală cu cea a voucherelor aplicate în coșul
                     de cumpărături din IQOS.ro pentru a profita integral de valoarea acestora.
                    </span>
                    `
    },
    {
        id: 'offline',
        title: 'Magazinele partenere',
        description: `<span>
                       Voucherul poate fi folosit la achiziția produselor IQOS, lil SOLID, HEETS, Fiit și TEREA din magazinele partenere (Inmedio, Tabac Xpress, Rompetrol)*
                    </span>
                    <span>
                        *În cazul în care alegi voucherul disponibil pentru magazinele partenere nu vei putea să-ți schimbi selecția mai târziu.
                    </span>
                     <span>
                        **În cazul în care alegi să folosești voucherul în Inmedio, 1 Minute, Relay, HUB, Tabac Xpress sau Rompetrol, asigură-te ca valoarea totală a achiziției tale este mai mare sau egală cu cea a voucherelor aplicate la momentul tranzacției pentru a profita integral de valoarea acestora. <br/> În caz contrar, vei pierde diferența. Vezi lista completă de locații <a href="locatii-iqos">aici</a>.
                    </span>
`
    }
];

export const voucherOptions2 = [
    {
        id: 'online',
        title: 'Magazinele și insulele IQOS sau pe IQOS.RO',
        description: `<span>
                    Acest voucher se aplică doar la achiziția următoarelor pachete de HEETS, Fiit și TEREA: Teak, Silver, Russet, Sienna, Amber, Bronze, Yellow, Fiit Regular pe IQOS.ro dar și în insulele și magazinele IQOS.
                    </span>
                    <span>
                    Asigură-te că valoarea totală a comenzii tale este mai mare sau egală cu cea a voucherelor aplicate în coșul
                     de cumpărături din IQOS.ro pentru a profita integral de valoarea acestora. <br/> În caz contrar, vei pierde diferența.
                    </span>
                    `
    },
    {
        id: 'offline',
        title: 'Magazinele partenere',
        description: `<span>
                     Voucherul poate fi folosit la achiziția produselor IQOS, lil SOLID, HEETS, Fiit și TEREA din magazinele partenere (Inmedio, Tabac Xpress, Rompetrol)*
                    </span>
                    <span>
                     *În cazul în care alegi voucherul disponibil pentru magazinele partenere, nu vei putea să-ți schimbi selecția mai târziu. Vezi lista completă de locații <a href="locatii-iqos">aici</a>.
                    </span>
                   
`
    }
];

export const voucherOptions3 = [
    {
        id: 'online',
        title: 'Magazinele și insulele IQOS sau pe IQOS.RO',
        description: `<span>
                     Voucherul poate fi folosit pentru achiziția de dispozitive, rezerve de tutun și accesorii, atât pe IQOS.ro cât și în magazinele și insulele IQOS.
                    </span>
                    <span>
                    Asigură-te că valoarea totală a comenzii tale este mai mare sau egală cu cea a voucherelor aplicate în coșul
                     de cumpărături din IQOS.ro pentru a profita integral de valoarea acestora.
                    </span>
                    `
    },
    {
        id: 'offline',
        title: 'Magazinele partenere',
        description: `<span>
                         Voucherul poate fi folosit la achiziția dispozitivelor IQOS și lil, cât și a pachetelor de TEREA/HEETS/FIIT din magazinele partenere
                         (Inmedio, 1 Minute, Relay, HUB, Tabac Xpress și Rompetrol)*
                    </span>
                    <span>
                        *În cazul în care alegi voucherul disponibil pentru magazinele partenere, nu vei putea să-ți schimbi selecția mai târziu. Vezi lista completă de locații <a href="locatii-iqos">aici</a>.
                    </span>
`
    }
];

export const summerVoucherOptions = [
    {
        id: 'online',
        title: 'Magazinele și insulele IQOS sau pe IQOS.RO',
        description: `<span>
                    Voucherul tău Next Level Pass poate fi folosit la orice comandă de pe IQOS.ro sau la orice achiziție din insulele și magazinele IQOS pentru sortimentele TEREA, accesorii IQOS ILUMA sau pentru upgrade la ILUMA.
                    </span>
                    <span>
                  Asigură-te că valoarea totală a comenzii tale este mai mare sau egală cu cea a voucherelor aplicate în coșul de cumpărături din IQOS.ro pentru a profita integral de valoarea acestora. 
                    </span>
                    `
    },
    {
        id: 'offline',
        title: 'Magazinele partenere',
        description: `<span>
                         Voucherul tău Next Level Pass poate fi folosit la achiziția dispozitivelor IQOS ILUMA și a pachetelor TEREA din magazinele partenere (Inmedio, 1 Minute, Relay, HUB, Tabac Xpress și Rompetrol). 
                    </span>
                    <span>
                        **În cazul în care alegi să folosești voucherul în Inmedio, 1 Minute, Relay, HUB, Tabac Xpress sau Rompetrol, asigură-te ca valoarea totală a achiziției tale este mai mare sau egală cu cea a voucherelor aplicate la momentul tranzacției pentru a profita integral de valoarea acestora. <br/> În caz contrar, vei pierde diferența. Vezi lista completă de locații <a href="locatii-iqos">aici</a>.
                    </span>
`
    }
];

export const flavourBanVoucherOptions = [
    {
        id: 'online',
        title: 'Magazinele și insulele IQOS sau pe IQOS.RO',
        description: `<span>
                        Voucherul poate fi folosit pe www.iqos.ro, în insulele și magazinele IQOS pentru următoarele sortimente de rezerve și dispozitive IQOS ILUMA:
                    </span>
                    <span>
                        TEREA: Russet, Bronze, Silver, Sienna, Teak, Amber, Yellow, Amarelo Fuse, Wind Fuse, Turquoise.
                    </span>
                    <span>
                        HEETS: Russet, Bronze, Sienna, Teak, Amber, Yellow, Silver, Turquoise, Laguna.
                    </span>
                    <span>
                        Fiit: Regular, Marine.
                    </span>
                    <span>
                        Dispozitive: IQOS ILUMA, IQOS ILUMA PRIME și IQOS ILUMA ONE.
                    </span>
                    <span>
                        Asigură-te că valoarea totală a comenzii tale este mai mare sau egală cu cea a voucherelor aplicate în coșul de cumpărături din IQOS.RO.
                    </span>
                    `
    },
    {
        id: 'offline',
        title: 'Magazinele partenere',
        description: `<span>
                         Voucherul poate fi folosit în magazinele partenere: (Inmedio, 1 Minute, Relay, HUB, Tabac Xpress si Rompetrol) pentru achiziția următoarelor sortimente de rezerve și dispozitive IQOS ILUMA:
                    </span>
                    <span>
                        TEREA: Russet, Bronze, Silver, Sienna, Teak, Amber, Yellow, Amarelo Fuse, Wind Fuse, Turquoise.
                    </span>
                    <span>
                        HEETS: Russet, Bronze, Sienna, Teak, Amber, Yellow, Silver, Turquoise, Laguna.
                    </span>
                    <span>
                        Fiit: Regular, Marine.
                    </span>
                    <span>
                        Dispozitive: IQOS ILUMA, IQOS ILUMA PRIME și IQOS ILUMA ONE.
                    </span>
                    <span>
                        *în cazul în care alegi voucherul disponibil pentru magazinele partenere, nu vei putea să-ți schimbi selecția mai târziu.
                    </span>
`
    }
];

export const flavourBanVoucherOptionsVeev = [
    {
        id: 'online',
        title: 'Magazinele și insulele IQOS sau pe IQOS.RO',
        description: `<span>
                        Voucherul poate fi folosit pe www.iqos.ro, în insulele și magazinele IQOS pentru următoarele sortimente de rezerve și dispozitive IQOS ILUMA:
                    </span>
                    <span>
                        TEREA: Russet, Bronze, Silver, Sienna, Teak, Amber, Yellow, Amarelo Fuse, Wind Fuse, Turquoise.
                    </span>
                    <span>
                        HEETS: Russet, Bronze, Sienna, Teak, Amber, Yellow, Silver, Turquoise, Laguna.
                    </span>
                    <span>
                        Fiit: Regular, Marine.
                    </span>
                    <span>
                        Dispozitive: IQOS ILUMA, IQOS ILUMA PRIME și IQOS ILUMA ONE.
                    </span>
                    <span>
                        VEEV NOW: Mauve, Yellow Green, Blue Mint, Peach, Blueberry, Strawberry
                    </span>
                    <span>
                        Asigură-te că valoarea totală a comenzii tale este mai mare sau egală cu cea a voucherelor aplicate în coșul de cumpărături din IQOS.RO.
                    </span>
                    `
    },
    {
        id: 'offline',
        title: 'Magazinele partenere',
        description: `<span>
                         Voucherul poate fi folosit în magazinele partenere: (Inmedio, 1 Minute, Relay, HUB, Tabac Xpress si Rompetrol) pentru achiziția următoarelor sortimente de rezerve și dispozitive IQOS ILUMA:
                    </span>
                    <span>
                        TEREA: Russet, Bronze,Silver, Sienna, Teak, Amber, Yellow, Amarelo Fuse, Wind Fuse, Turquoise.
                    </span>
                    <span>
                        HEETS: Russet, Bronze, Sienna, Teak, Amber, Yellow, Silver, Turquoise, Laguna.
                    </span>
                    <span>
                        Fiit: Regular, Marine.
                    </span>
                    <span>
                        Dispozitive: IQOS ILUMA, IQOS ILUMA PRIME și IQOS ILUMA ONE.
                    </span>
                    <span>
                        VEEV NOW: Mauve, Yellow Green, Blue Mint, Peach, Blueberry, Strawberry
                    </span>
                    <span>
                        *în cazul în care alegi voucherul disponibil pentru magazinele partenere, nu vei putea să-ți schimbi selecția mai târziu.
                    </span>
`
    }
];
export const filterActiveGifts = [
    {
        id: 1,
        value: 'toate',
        label: 'Toate'
    },
    {
        id: 2,
        value: 'vouchers',
        label: 'Vouchere valorice'
    },
    {
        id: 3,
        value: 'gifts',
        label: 'Alte premii și cadouri'
    },
    {
        id: 4,
        value: 'partner_vouchers',
        label: 'Vouchere Parteneri'
    },
    {
        id: 5,
        value: 'offers',
        label: 'Oferte'
    }
];

export const filterInactiveGifts = [
    {
        id: 1,
        value: 'toate',
        label: 'Toate'
    },
    {
        id: 2,
        value: 'vouchers',
        label: 'Vouchere'
    },
    {
        id: 3,
        value: 'gifts',
        label: 'Cadouri și Alte premii'
    },
    {
        id: 4,
        value: 'partner_vouchers',
        label: 'Vouchere Parteneri'
    },
    {
        id: 5,
        value: 'offers',
        label: 'Oferte'
    }
];

export const telcorStatus = [
    {
        id: 1,
        status: 'UnredeemedVoucher'
    },
    {
        id: 2,
        status: 'RedeemedVoucher'
    },
    {
        id: 3,
        status: 'UsedVoucher'
    },
    {
        id: 4,
        status: 'ExpiredVoucher'
    }
];

export const clubStatus = [
    {
        id: 1,
        status: 'Unredeemed'
    },
    {
        id: 2,
        status: 'Redeemed'
    },
    {
        id: 3,
        status: 'Delivered'
    },
    {
        id: 4,
        status: 'Cancelled'
    }
];
