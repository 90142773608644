import { FC } from 'react';
import { ICardDrag } from './interface';
import styles from './CardDrag.module.scss';

// Utils
import classnames from 'classnames';

// DND Kit
import { useDraggable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';

const CardDrag: FC<ICardDrag> = ({ card, id, disabled = false, rotate = false }) => {
    const { attributes, listeners, setNodeRef, transform } = useDraggable({ id: id });
    const { image } = card;

    const style = {
        transform: CSS.Translate.toString(transform),
        cursor: disabled ? 'auto' : 'grab',
        color: disabled ? '#fffdfb' : '#34303d',
        textShadow: disabled ? '0px 0px 6px #000000' : 'none'
    };

    return (
        <div
            ref={setNodeRef}
            className={classnames(styles.container, disabled && styles.fadeOut)}
            style={style}
            {...listeners}
            {...attributes}
        >
            <div
                style={{
                    transform: rotate ? `rotate(${7 - Number(id) * 4}deg)` : 'none'
                }}
                className={styles.images}
            >
                <img src={image} />
            </div>
        </div>
    );
};

export default CardDrag;
