import React, { useState } from 'react';

import styles from './WinterWrapUp.module.scss';

// Assets
import img from './assets/imageMobile.png';
import imgDesktop from './assets/imageDesktop.png';

// Components
import CompletedStateArticle from '@containers/IQOSUniverse/components/CompletedStateArticle/CompletedStateArticle';
import ArticleImage from '@containers/IQOSUniverse/components/ArticleImage';
import ArticleHeader from '@containers/IQOSUniverse/components/ArticleHeader';
import VideoModalQuiz from '@containers/IQOSUniverse/components/VideoModalQuiz';

// Helpers
import { useWindowSize } from '@utils/hooks/windowSize';
import classnames from 'classnames';

// Redux
import { useAppSelector } from '@app/hooks';

const WinterWrapUp = () => {
    const { article } = useAppSelector((state) => state.iqosUniverse);

    const window = useWindowSize();
    const isMobile = window.width < 870;

    const [toggleModalTop, setToggleModalTop] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);

    return (
        <section className={styles.wrapper}>
            <ArticleHeader
                videoQuiz={() => {
                    setToggleModalTop(true);
                }}
            />

            <VideoModalQuiz
                toggleModal={toggleModalTop}
                setToggleModal={() => setToggleModalTop(false)}
                setShowSuccess={() => {
                    setShowSuccess(true);
                }}
            />

            <div className={classnames(styles.winterTopContent, 'content')}>
                <p>
                    IQOS a dus iarna la următorul nivel și distracția la cote maxime, prin
                    experiențe și petreceri inedite pentru comunitate în{' '}
                    <strong> Poiana Brașov!</strong>
                </p>
            </div>

            <div className={styles.backgroundWrapper}>
                <div className={classnames(styles.winterBottomContent, 'content')}>
                    <h3>Experiențe unice pentru comunitatea IQOS</h3>
                    <p>
                        Sezonul distracției a început în <strong> 19 ianuarie</strong>, cu o
                        petrecere de neuitat la <strong> Yager Chalet</strong>, într-o atmosferă
                        electrizantă. Experiența a continuat pe tot parcursul iernii: muzică, dans
                        și <strong> invitați speciali</strong>. Cele mai cool elemente au fost
                        <strong> instalațiile interactive </strong> chiar la baza pârtiei, momentele
                        memorabile la <strong> Rossignol </strong> și <strong> DJ seturile </strong>{' '}
                        care te fac să dansezi non-stop, toate <strong> powered by IQOS! </strong>
                    </p>

                    <ArticleImage image={isMobile ? img : imgDesktop} />
                </div>
            </div>

            {(showSuccess || article.completed_quiz) && (
                <CompletedStateArticle
                    className={styles.completedStateArticle}
                    article={{
                        points: article.points,
                        quiz_points: article.quiz_points,
                        quiz_end_date: article.quiz_end_date
                    }}
                />
            )}
        </section>
    );
};

export default WinterWrapUp;
