export const Star = () => {
    return (
        <svg
            width="32"
            height="30"
            viewBox="0 0 32 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M16 0L19.7045 11.4012H31.6924L21.994 18.4476L25.6985 29.8488L16 22.8024L6.30154 29.8488L10.006 18.4476L0.307568 11.4012H12.2955L16 0Z"
                fill="#C4C3C6"
            />
        </svg>
    );
};
