import React, { useEffect, useState } from 'react';
import styles from './EchoesRDW.module.scss';

// Components
import ArticleHeader from '@containers/IQOSUniverse/components/ArticleHeader';
import MediaGallery from '@containers/IQOSUniverse/components/MediaGallery';
import VideoDisclaimer from '@containers/IQOSUniverse/components/VideoDisclaimer';
import CompletedStateArticle from '@containers/IQOSUniverse/components/CompletedStateArticle/CompletedStateArticle';
import BaseModal from '@components/Layout/BaseModal';
import VimeoPlayer from '@components/VimeoPlayer';

// Assets
import img01 from './assets/image1.jpg';
import img02 from './assets/image2.jpg';
import { HiHeart, HiOutlineHeart } from 'react-icons/hi';

// Helpers
import classnames from 'classnames';
import dayjs from '@utils/dayjs';

// Redux
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { getQuiz, postQuizQuestions } from '@components/Quiz/QuizSlice';
import { likeArticle } from '@containers/IQOSUniverse/IQOSUniverseSlice';

const EchoesRDW = () => {
    const { quizQuestions } = useAppSelector((state) => state.quiz);
    const { article } = useAppSelector((state) => state.iqosUniverse);

    const slug = 'vezi-cum-a-fost-experienta-senzoriala-iqos-x-rdw';

    const dispatch = useAppDispatch();

    const [isPastPercentage, setIsPastPercentage] = useState(false);
    const [showDisclaimer, setShowDisclaimer] = useState(true);
    const [showModal, setShowModal] = useState(false);

    const [isLiked, setIsLiked] = useState(false);

    let playing = false;
    let progress = 0;

    // Handle Trigger
    const triggerMomentPercentage = 75; // 75%

    const handleTimeUpdate = (data: any) => {
        if (playing && data.seconds - progress <= 10) {
            if (data.seconds < progress) {
                //don't update progress if user seeked back
            } else {
                progress = data.seconds;
            }
        }

        const durationPercentage = (triggerMomentPercentage * data.duration) / 100;

        if (progress >= durationPercentage && !isPastPercentage) {
            setIsPastPercentage(true);
        }
    };

    useEffect(() => {
        if (isPastPercentage && !quizQuestions.is_completed) {
            grantPoints();
        }
    }, [isPastPercentage]);

    useEffect(() => {
        dispatch(getQuiz({ slug: slug, statistics: false }));
    }, [slug]);

    const grantPoints = () => {
        let currentStep: any = {
            quiz_slug: slug,
            step_id: quizQuestions?.quiz?.steps[0]?.id,
            answers: [
                {
                    quiz_question_id: quizQuestions?.quiz?.steps[0]?.questions[0]?.id,
                    values: [{ value: `rdw50p` }]
                }
            ]
        };

        dispatch(postQuizQuestions(currentStep));
    };

    useEffect(() => {
        if (article.liked) {
            setIsLiked(article.liked);
        }
    }, [article.liked]);

    const handleLike = () => {
        setIsLiked(!isLiked);
        dispatch(likeArticle({ slug: article.slug }));
    };

    return (
        <section className={styles.wrapper}>
            <div className={styles.headerSectionCustom}>
                <div onClick={() => setShowModal(true)}>
                    <ArticleHeader showPlayIcon />
                </div>

                <div
                    onClick={handleLike}
                    className={classnames(styles.likeButton, isLiked && styles.liked)}
                >
                    {article.liked || isLiked ? <HiHeart /> : <HiOutlineHeart />}
                </div>
            </div>

            <div className="content">
                <p>
                    Timp de 17 zile, în cadrul festivalului Romanian Design Week, ne-am conectat
                    împreună la „A celebration of togetherness” prin una din cele mai spectaculoase
                    instalații senzoriale – „Echoes of Presence”.
                    <br />
                    Instalația, creată de echipa Alina Rizescu, Bogdan Ștefănescu, Alin Tatu și
                    Denis Flueraru și susținută de IQOS, a oferit reprezentarea cea mai creativă a
                    temei festivalului RDW – CONNECTIONS.
                </p>

                <p>
                    Cei care au ales să interacționeze cu instalația au aflat că prezența lor poate
                    influența experiența celorlalți. Astfel, la trecerea prin cele 3 camere care au
                    găzduit „Echoes of Presence”, senzorii de mișcare declanșau efecte care se
                    propagau dintr-o cameră în alta și, astfel, vizualul se îmbina cu semnale
                    sonore, pe care le puteau percepe și cei care nu erau martorii direcți ai
                    trecerii lor prin spațiul expozițional.
                    <br />
                    Oamenii sunt într-o continuă schimbare și acest mesaj a fost evidențiat prin
                    instalația „Echoes of Presence”.
                </p>
            </div>

            <MediaGallery images={[img01, img02]} />

            <div className={classnames('content', styles.customMargin)}>
                <p>
                    RDW a întâmpinat peste 40.000 de participanți, doar în prima săptămână. Plini de
                    curiozitate și gata să imortalizeze totul, aceștia au fost captivați de
                    instalația „Echoes of Presence”.
                </p>

                <p>
                    Ne bucurăm că am putut lua parte în cadrul acestui eveniment emblematic și
                    sperăm că și tu ai reușit să schimbi experiența altora cu prezența ta prin
                    „Echoes of Presence”.
                </p>
            </div>

            <div className={styles.space}>
                <BaseModal
                    size="large"
                    maskClosable={true}
                    isModalOpen={showModal}
                    className={styles.videoModal}
                    closeModal={() => setShowModal(false)}
                    destroyOnClose={true}
                >
                    <div className={styles.modalContent}>
                        {!article.completed_quiz &&
                            dayjs(article.quiz_end_date).isAfter(dayjs()) &&
                            showDisclaimer && (
                                <VideoDisclaimer handleClose={() => setShowDisclaimer(false)} />
                            )}

                        <VimeoPlayer
                            className={styles.player}
                            video="838580269"
                            options={{
                                autoplay: false,
                                responsive: true
                            }}
                            onPlay={() => (playing = true)}
                            onTimeUpdate={(progress) => handleTimeUpdate(progress)}
                            onPause={() => (playing = false)}
                        />
                    </div>
                </BaseModal>

                {article.completed_quiz && (
                    <CompletedStateArticle
                        article={{
                            points: article.quiz_points,
                            quiz_points: article.quiz_points,
                            quiz_end_date: article.quiz_end_date
                        }}
                    />
                )}
            </div>
        </section>
    );
};

export default EchoesRDW;
