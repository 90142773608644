export const QRIcon = () => {
    return (
        <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0.375 6.44924H6.70833V0.115906H0.375V6.44924ZM1.95833 1.69924H5.125V4.86591H1.95833V1.69924Z"
                fill="#34303D"
            />
            <path
                d="M0.375 14.3659H6.70833V8.03253H0.375V14.3659ZM1.95833 9.61587H5.125V12.7825H1.95833V9.61587Z"
                fill="#34303D"
            />
            <path
                d="M8.29163 0.115845V6.44918H14.625V0.115845H8.29163ZM13.0416 4.86584H9.87496V1.69918H13.0416V4.86584Z"
                fill="#34303D"
            />
            <path d="M14.625 12.7825H13.0416V14.3659H14.625V12.7825Z" fill="#34303D" />
            <path d="M9.87496 8.03253H8.29163V9.61587H9.87496V8.03253Z" fill="#34303D" />
            <path d="M11.4583 9.61584H9.875V11.1992H11.4583V9.61584Z" fill="#34303D" />
            <path d="M9.87496 11.1992H8.29163V12.7825H9.87496V11.1992Z" fill="#34303D" />
            <path d="M11.4583 12.7825H9.875V14.3659H11.4583V12.7825Z" fill="#34303D" />
            <path d="M13.0417 11.1992H11.4584V12.7825H13.0417V11.1992Z" fill="#34303D" />
            <path d="M13.0417 8.03253H11.4584V9.61587H13.0417V8.03253Z" fill="#34303D" />
            <path d="M14.625 9.61584H13.0416V11.1992H14.625V9.61584Z" fill="#34303D" />
        </svg>
    );
};
