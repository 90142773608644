import img01 from './assets/slider/1.jpg';
import img02 from './assets/slider/2.jpg';
import img03 from './assets/slider/3.jpg';
import img04 from './assets/slider/4.jpg';
import img05 from './assets/slider/5.jpg';
import img06 from './assets/slider/6.jpg';
import img07 from './assets/slider/7.jpg';
import img08 from './assets/slider/8.jpg';
import img09 from './assets/slider/9.jpg';

export const slides = [
  {
    id: 5,
    image: img05
  },
  {
    id: 1,
    image: img01
  },
  {
    id: 2,
    image: img02
  },
  {
    id: 3,
    image: img03
  },
  {
    id: 4,
    image: img04
  },
  {
    id: 6,
    image: img06
  },
  {
    id: 7,
    image: img07
  },
  {
    id: 8,
    image: img08
  },
  {
    id: 9,
    image: img09
  }
];
