import styles from './HeetsCollection.module.scss';

// Helpers
import { config } from '@utils/config';

// Components
import Button from '@components/Layout/Button';
import HeetsCollectionSlider from './components/HeetsCollectionSlider';
import ArticleHeader from '@containers/IQOSUniverse/components/ArticleHeader';
import ArticleContainer from '@containers/IQOSUniverse/components/ArticleContainer';
import classNames from 'classnames';

const HeetsCollection = () => {
    return (
        <section className={styles.wrapper}>
            <ArticleHeader />

            <div className={classNames(styles.content, 'content')}>
                <p>
                    Făcute din tutun de cea mai bună calitate, rezervele HEETS pentru IQOS sunt
                    disponibile în cincisprezece variante diferite, astfel încât îți găsești cu
                    ușurință aroma și intensitatea care ți se potrivesc perfect.
                </p>

                <p>
                    Pentru că ești membru în IQOS CLUB, poți revendica pachete de rezerve HEETS până
                    la data de 31 mai 2023{' '}
                    <a href={'/club/revendica-heets-offline'}>folosind punctele din cont </a> , însă
                    te bucuri și de <a href={config.path.iqos.shop}>ofertele speciale</a> pe
                    IQOS.ro.
                </p>
            </div>

            <div className={styles.articleContainerWrapper}>
                <ArticleContainer background={'#F6F4F0'}>
                    <div
                        className={classNames(
                            styles.heetsCollectionArticleContainerContent,
                            'content'
                        )}
                    >
                        <h3>Descoperă cele cincisprezece variante HEETS!</h3>

                        <HeetsCollectionSlider />
                    </div>
                </ArticleContainer>
            </div>

            <div className={classNames(styles.content, 'content')}>
                <p>
                    În plus, există AUTO Delivery, cel mai simplu mod în care să acumulezi punctele
                    necesare pentru avansare sau menținere în IQOS Club. Faci o singură comandă și
                    primești HEETS & Fiit lunar, cu livrare gratuită, chiar la ușa ta, la cel mai
                    bun preț de pe <a href={config.path.iqos.index}>IQOS.ro </a> din luna
                    anterioară.
                </p>

                <p>
                    În portofoliul HEETS apar noi arome constant. Urmărește-ne pentru a fi la curent
                    cu cele mai noi apariții.
                </p>

                <Button
                    href={'https://www.iqos-on.ro/'}
                    label="Configurează abonamentul"
                    type="solid"
                    color="dark"
                    hover="dark"
                />
            </div>
        </section>
    );
};
export default HeetsCollection;
