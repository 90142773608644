import { IExternalModal } from './interface';
import styles from './ExternalModal.module.scss';

// Components
import Button from '@components/Layout/Button';
import BaseModal from '@components/Layout/BaseModal';

const ExternalModal = ({ modalOpen, toggleModal, externalRoute }: IExternalModal) => {
    const handleWindowOpen = (url: string) => {
        if (!url.match(/^https?:\/\//i)) {
            url = 'https://' + url;
        }
        return window.open(url, '_blank');
    };

    const goToExternal = () => {
        handleWindowOpen(externalRoute);
        toggleModal();
    };

    return (
        <BaseModal
            lockBodyScroll={false}
            className={styles.modal}
            size="small"
            isModalOpen={modalOpen}
            closeModal={toggleModal}
        >
            <h2>Urmează să mergi pe un alt site</h2>
            <p>
                Linkul accesat este extern și te va trimite către <span>{externalRoute}</span>
            </p>
            <p>
                În cazul rețelelor de socializare, îți recomandăm să te loghezi înainte de accesarea
                linkului.
            </p>

            <div className={styles.buttons}>
                <Button
                    type="solid"
                    color="turquoise"
                    label="Accesează link-ul"
                    onClick={goToExternal}
                />

                <Button color="dark" type="solid" label="Renunță" onClick={toggleModal} />
            </div>
        </BaseModal>
    );
};

export default ExternalModal;
