export const MechanicIcon = (color: string = '#34303D') => {
    return (
        <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_2112_72681)">
                <path
                    d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
                    stroke={color}
                    strokeWidth="1.71"
                />
                <path
                    d="M10.9995 5.375L12.555 8.87284L16.0329 9.43238L13.5164 12.1552L14.1106 16L11 14.1846L7.88944 16L8.48355 12.1552L5.9671 9.43238L9.44495 8.8714L10.9995 5.375Z"
                    stroke={color}
                    strokeWidth="1.53"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_2112_72681">
                    <rect width="22" height="22" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
