export const CollapseClose = () => (
    <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M17.6201 11.2781L10.3823 18.5159"
            stroke="#8C8A90"
            strokeWidth="1.77033"
            strokeLinecap="round"
        />
        <path
            d="M10.3824 11.2781L17.6201 18.5159"
            stroke="#8C8A90"
            strokeWidth="1.77033"
            strokeLinecap="round"
        />
        <rect
            x="0.46875"
            y="1.36572"
            width="27.0625"
            height="27.0625"
            rx="13.5312"
            stroke="#8C8A90"
            strokeWidth="0.9375"
        />
    </svg>
);
