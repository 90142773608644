import { useState } from 'react';
import classnames from 'classnames';
import styles from './HeetsCollectionSlider.module.scss';

// Helpers
import { carouselSettings } from './data';
import { HeetsCollectionList } from '../../list/HeetsCollectionList';

// Components
import { Carousel } from 'antd';

const HeetsCollectionSlider = () => {
    const [activeSlide, setActiveSlide] = useState(0);

    return (
        <div className={styles.slider}>
            <Carousel
                easing={'ease-in-out'}
                {...carouselSettings}
                afterChange={(current) => setActiveSlide(current)}
                className={classnames(styles.sliderContainer)}
            >
                {HeetsCollectionList.map((heetsItem, key) => {
                    return (
                        <div key={heetsItem.id + key} className={styles.heetsItem}>
                            <div className={styles.imgContainer}>
                                <img
                                    alt=""
                                    src={heetsItem.image}
                                    className={
                                        activeSlide === key
                                            ? styles.imgTransparent
                                            : styles.imgOpacity
                                    }
                                />

                                <img
                                    alt=""
                                    src={heetsItem.activeImage}
                                    className={activeSlide === key ? '' : styles.imgTransparent}
                                />

                                <img
                                    alt=""
                                    src={heetsItem.activeTag}
                                    className={
                                        activeSlide === key ? styles.imgTag : styles.imgTagInitial
                                    }
                                />
                            </div>

                            <>
                                <h3 dangerouslySetInnerHTML={{ __html: heetsItem.title }} />
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: heetsItem.description
                                    }}
                                />

                                <h4>Recomandat pentru dispozitivele IQOS.</h4>
                            </>
                        </div>
                    );
                })}
            </Carousel>
        </div>
    );
};

export default HeetsCollectionSlider;
