export const AddCodesIcon = () => {
    return (
        <svg
            width="23"
            height="22"
            viewBox="0 0 23 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.4877 14.0848C14.9644 10.6081 16.9137 8.65878 20.3905 5.182C20.4911 5.08145 20.6543 5.08145 20.7548 5.182L21.8178 6.24504C21.9184 6.34559 21.9184 6.50879 21.8178 6.60934L12.915 15.5122L11.0352 16.3719C10.9062 16.431 10.754 16.3734 10.6942 16.2437C10.6651 16.1796 10.6636 16.1053 10.6898 16.0397L11.4877 14.1754L11.4877 14.0848Z"
                stroke="#C4C3C6"
                strokeMiterlimit="1.03784"
            />
            <path
                d="M17.9001 16.5071H10.71"
                stroke="#C4C3C6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18.9377 7.1231L20.0762 8.25557"
                stroke="#C4C3C6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M20 11.5V18C20 18.5523 19.5523 19 19 19H3C2.44772 19 2 18.5523 2 18V9C2 8.44771 2.44772 8 3 8H14.5"
                stroke="#C4C3C6"
                strokeWidth="1.8"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle cx="5" cy="16" r="1" fill="#C4C3C6" />
            <circle cx="8" cy="16" r="1" fill="#C4C3C6" />
        </svg>
    );
};
