import { createSlice } from '@reduxjs/toolkit';

export const specialGiftModalSlice = createSlice({
    name: 'specialGiftModal',
    initialState: {
        isOpen: false
    },
    reducers: {
        toggleSpecialGiftModal: (state, action) => {
            state.isOpen = action.payload;
        }
    }
});

export const { toggleSpecialGiftModal } = specialGiftModalSlice.actions;
export default specialGiftModalSlice.reducer;
