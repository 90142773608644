import styles from './CityExperienceMilano.module.scss';

// Helpers
import classNames from 'classnames';
import { SliderMilano } from './SliderLists/SliderMilano1';

// Assets
import img1Mobile from './assets/image1.jpg';
import img4Mobile from './assets/image4.jpg';
import image1Desktop from './assets/image1Desktop.png';

import slide2Img1 from './assets/slide2Img1.png';
import slide2Img2 from './assets/slide2Img2.png';
import slide2Img3 from './assets/slide2Img3.png';
import slide2Img4 from './assets/slide2Img4.png';

// Components
import Button from '@components/Layout/Button';
import KeenSlider from '@components/KeenSlider';
import ArticleImage from '@containers/IQOSUniverse/components/ArticleImage';
import ArticleHeader from '@containers/IQOSUniverse/components/ArticleHeader';
import ArticleSlider from '@containers/IQOSUniverse/components/ArticleSlider';

const CityExperienceMilano = () => {
    const sliderImages = [slide2Img1, slide2Img2, slide2Img3, slide2Img4];

    return (
        <div className={styles.wrapper}>
            <ArticleHeader />

            <div className="content">
                <h3>Orașul Milano așa cum nu l-au văzut vreodată. Doar pentru ei.</h3>

                <p>
                    Ultima campanie dedicată exclusiv membrilor Gold & Platinum din IQOS Club le-a
                    oferit câștigătorilor un premiu de neuitat: un citybreak VIP la Milano cu
                    experiențe unice.
                </p>
            </div>

            <ArticleImage cover={true} mobileImage={img1Mobile} image={image1Desktop} />

            <div className={classNames(styles.exclusiveExperience, 'content')}>
                <h3>
                    Experiențe exclusive.
                    <br /> Trăite împreună.
                </h3>

                <p>
                    Milano este mai mult decât un oraș frumos. Este un loc gata să surprindă pe
                    oricine cu noi experiențe senzoriale, cu un întreg stil de viață. Exact asta au
                    trăit și norocoșii câștigători.
                </p>

                <h3 className={styles.contentSubtitle}>
                    Descoperă și tu cum a fost și pregătește-te să participi la următoarele
                    campanii!
                </h3>
            </div>

            <ArticleSlider
                fullWidthSlider
                columnReverse
                backgroundColor={'#00D1D2'}
                slides={SliderMilano}
            />

            <div className="content">
                <h3>Acestea sunt cele mai de preț suveniruri</h3>

                <p>
                    Experiențele despre care dorești să vorbești la finalul unei vacanțe. Descoperă
                    în galeria de mai jos câteva dintre cele mai importante momente trăite de
                    norocoșii câștigători!
                </p>
            </div>

            <KeenSlider withArrows={false} mode={'snap'} origin={'auto'} spacing={10}>
                {sliderImages.map((img) => (
                    <div className={styles.sliderImages}>
                        <img src={img} alt={''} />
                    </div>
                ))}
            </KeenSlider>

            <ArticleImage cover={true} mobileImage={img4Mobile} image={img4Mobile} />

            <div className="content">
                <h3>Milano IQOS friendly, acum și pe TripAdvisor</h3>

                <p>
                    Design care îmbină eleganța cu funcționalitatea. Un vibe pe cât de istoric, pe
                    atât de progresist. O comunitate vibrantă și primitoare. Toate fac din Milano un
                    oraș potrivit pentru utilizatorii IQOS. De aceea, este una dintre destinațiile
                    IQOS friendly, care te așteaptă să o explorezi. Fără fum, fără scrum, doar cu
                    experiențe de neuitat.
                </p>

                <p>
                    Descoperă pe TripAdvisor traseul parcurs de câștigători și inspiră-te pentru un
                    citybreak memorabil!
                </p>

                <Button
                    href={`https://www.tripadvisor.com/Trips/117808252`}
                    label="Află mai multe"
                    type="solid"
                    color="dark"
                    hover="dark"
                />
            </div>
        </div>
    );
};

export default CityExperienceMilano;
