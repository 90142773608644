export const Supporter = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="61"
            height="66"
            viewBox="0 0 61 66"
            fill="none"
        >
            <path
                d="M46.0887 17.5483L58.1561 36.8868C64.2166 46.5983 56.6402 58.7391 44.5161 58.7391H20.3812C8.25714 58.7391 0.680767 46.5983 6.74123 36.8868L18.8118 17.5483C24.8723 7.83678 40.0282 7.83678 46.0918 17.5483"
                fill="#00D1D2"
            />
            <path
                d="M0.807433 41.9058C0.807433 41.9058 5.9138 40.2954 7.93002 39.3899C9.94623 38.4843 18.2733 33.2048 21.413 32.6508C24.5526 32.0969 34.1169 35.3113 35.6452 35.7225C37.1772 36.132 37.8756 38.0318 37.5314 39.202C37.1871 40.3723 36.4599 42.211 32.4409 41.1013C28.4219 39.9916 24.6719 39.4807 23.9635 39.3434C23.2551 39.2061 22.4215 39.6216 22.2341 40.4617C22.0468 41.3019 22.5492 42.5929 23.8057 42.7846C25.0623 42.9762 37.7551 45.6827 38.4597 45.8217C39.1643 45.9607 42.0703 44.6736 46.0965 43.1021C50.1227 41.5305 52.6978 40.6017 53.515 40.312C54.3322 40.0224 55.5624 40.4901 56.1841 41.9552C56.8057 43.4203 56.2949 44.3921 54.2888 45.4116C52.2828 46.431 41.6866 51.4954 40.6127 52.0552C39.5371 52.6112 34.0621 52.3057 31.1018 52.1233C28.1397 51.9371 19.9623 51.5975 17.9741 50.7097C15.9859 49.822 14.3162 49.8159 12.4799 50.8684C10.6435 51.9209 6.44178 54.4503 6.44178 54.4503"
                stroke="#34303D"
                stroke-width="1.54613"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M43.7895 36.1891C43.7895 36.1891 43.2037 34.7125 41.3495 35.3905C39.4952 36.0685 37.4903 36.7458 37.4903 36.7458"
                stroke="#34303D"
                stroke-width="1.54613"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M48.9922 38.3226C48.9922 38.3226 48.542 37.6549 46.9229 38.2683C45.3037 38.8817 40.3483 40.5745 40.3483 40.5745"
                stroke="#34303D"
                stroke-width="1.54613"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M32.2537 2.27637L35.6948 9.60594L43.3884 10.7784L37.8215 16.484L39.1358 24.5406L32.2547 20.7366L25.3736 24.5406L26.6879 16.484L21.1211 10.7784L28.8147 9.60293L32.2537 2.27637Z"
                fill="#FFFDFB"
                stroke="#34303D"
                stroke-width="1.54613"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M41.9756 2.00611V1.12402"
                stroke="#34303D"
                stroke-width="1.58922"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M40.2238 3.62305H39.2734"
                stroke="#34303D"
                stroke-width="1.58922"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M44.4943 3.5166H43.5439"
                stroke="#34303D"
                stroke-width="1.58922"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M41.9756 6.12232V5.24023"
                stroke="#34303D"
                stroke-width="1.58922"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M49.9873 1.00098V1.00554"
                stroke="#34303D"
                stroke-width="1.58922"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M47.6143 8.29102V8.29558"
                stroke="#34303D"
                stroke-width="1.58922"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M21.8477 1.88306V1.00098"
                stroke="#34303D"
                stroke-width="1.58922"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M20.0959 3.39844H19.1455"
                stroke="#34303D"
                stroke-width="1.58922"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M24.3654 3.39844H23.415"
                stroke="#34303D"
                stroke-width="1.58922"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M21.8477 6.00025V5.11816"
                stroke="#34303D"
                stroke-width="1.58922"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};
