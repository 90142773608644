export const CollapsePlus = () => (
    <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect y="0.896973" width="28" height="28" rx="14" fill="#F6F4F0" />
        <g opacity="0.7">
            <path
                d="M18.628 14.8979L9.37402 14.8979"
                stroke="#34303D"
                strokeWidth="1.77033"
                strokeLinecap="round"
            />
            <path
                d="M14.001 10.2709L14.001 19.5249"
                stroke="#34303D"
                strokeWidth="1.77033"
                strokeLinecap="round"
            />
        </g>
    </svg>
);
