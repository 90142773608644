export const NotificationsIcon = () => {
    return (
        <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_133_869)">
                <path
                    d="M11 0.785706C12.7879 0.785706 14.5027 1.49596 15.7669 2.76022C17.0312 4.02449 17.7414 5.7392 17.7414 7.52713C17.7414 15.0228 20.4757 16.5 21.2143 16.5H0.785721C1.54001 16.5 4.25858 15.0071 4.25858 7.52713C4.25858 5.7392 4.96883 4.02449 6.2331 2.76022C7.49736 1.49596 9.21207 0.785706 11 0.785706V0.785706Z"
                    stroke="#C4C3C6"
                    strokeWidth="1.71429"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M8.64287 19.3757C8.7787 19.8975 9.08381 20.3594 9.51038 20.6892C9.93695 21.0189 10.4609 21.1978 11 21.1978C11.5392 21.1978 12.0631 21.0189 12.4896 20.6892C12.9162 20.3594 13.2213 19.8975 13.3572 19.3757"
                    stroke="#C4C3C6"
                    strokeWidth="1.71429"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_133_869">
                    <rect width="22" height="22" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
