export const MGMMenuIcon = () => (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1483_831)">
            <path
                d="M11.0655 10.7267C13.7635 10.7267 15.9506 8.5396 15.9506 5.84164C15.9506 3.14367 13.7635 0.956543 11.0655 0.956543C8.36755 0.956543 6.18042 3.14367 6.18042 5.84164C6.18042 8.5396 8.36755 10.7267 11.0655 10.7267Z"
                stroke="#C4C3C6"
                strokeOpacity="0.9"
                strokeWidth="1.71429"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.8031 13.0067C12.2316 12.9119 11.6505 12.8635 11.0655 12.8635C8.81748 12.8635 6.62751 13.5774 4.81133 14.9023C2.99516 16.2271 1.64662 18.0945 0.960138 20.2352C0.876529 20.4959 0.922505 20.7809 1.08386 21.0021C1.24522 21.2232 1.50254 21.354 1.77634 21.354H12.2652C11.8832 20.836 11.5762 20.2592 11.3603 19.6398H3.03687C3.67016 18.3143 4.62479 17.1603 5.82162 16.2872C7.34442 15.1764 9.18063 14.5778 11.0655 14.5778C11.2716 14.5778 11.477 14.585 11.6816 14.5992C11.9748 14.0123 12.3541 13.4759 12.8031 13.0067Z"
                fill="#C4C3C6"
                fillOpacity="0.9"
                strokeWidth={0.5} //*       Added these lines to make 
                strokeOpacity={0.5} //*     the icon pretty when active
            />
            <line
                x1="12.8955"
                y1="16.2608"
                x2="20.9213"
                y2="16.2608"
                stroke="#C4C3C6"
                strokeOpacity="0.9"
                strokeWidth="1.74713"
                strokeLinecap="round"
            />
            <line
                x1="17.0122"
                y1="20.17"
                x2="17.0122"
                y2="12.1442"
                stroke="#C4C3C6"
                strokeOpacity="0.9"
                strokeWidth="1.74713"
                strokeLinecap="round"
            />
        </g>
        <defs>
            <clipPath id="clip0_1483_831">
                <rect width="22" height="22" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
