import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axiosRequest from '@utils/axios';
import { RootState } from '@app/store';

export interface NotificationsState {
    status: 'idle' | 'loading' | 'failed';
    dataStatus: 'idle' | 'loading' | 'failed';
    notifications: {
        data: Array<{
            id: number;
            data: {
                title: string;
                content: string;
            };
            category: {};
            dates: {
                published_at: string;
            };
        }>;
        links: {};
        meta: {
            current_page: number;
            per_page: number;
            total: number;
            unread_notifications_count: number;
        };
    };
    pinnedNotifications: {
        data: Array<{
            id: number;
            data: {
                title: string;
                content: string;
            };
            category: {};
            dates: {
                published_at: string;
            };
            extra: {
                button_properties: Array<{
                    color: string;
                    cta_label: string;
                    cta_link: string;
                    hover: string;
                    type: string;
                }>;
            };
        }>;
        links: {};
        meta: {
            current_page: number;
            per_page: number;
            total: number;
            unread_notifications_count: number;
        };
    };
    serverPinnedNotifications: {
        data: Array<{
            id: number;
            data: {
                title: string;
                content: string;
            };
            category: {};
            dates: {
                published_at: string;
            };
            extra: {
                button_properties: Array<{
                    color: string;
                    cta_label: string;
                    cta_link: string;
                    hover: string;
                    type: string;
                }>;
            };
        }>;
        links: {};
        meta: {
            current_page: number;
            per_page: number;
            total: number;
            unread_notifications_count: number;
        };
    };
    markNotificationAsPinned: {};
    markAsUnpinned: {};
    markNotificationAsRead: {};
    categories: Array<{ value: any; label: any }>;
    unreadCount: { count: number };
    error: any;
}

interface AxiosError {
    response: {
        data: {
            message: string;
        };
    };
}

const initialState: NotificationsState = {
    status: 'idle',
    dataStatus: 'idle',
    notifications: {
        data: [],
        links: {},
        meta: {
            current_page: 1,
            per_page: 10,
            total: 1,
            unread_notifications_count: 0
        }
    },
    pinnedNotifications: {
        data: [],
        links: {},
        meta: {
            current_page: 1,
            per_page: 10,
            total: 1,
            unread_notifications_count: 0
        }
    },
    serverPinnedNotifications: {
        data: [],
        links: {},
        meta: {
            current_page: 1,
            per_page: 10,
            total: 1,
            unread_notifications_count: 0
        }
    },
    markNotificationAsPinned: {},
    markAsUnpinned: {},
    markNotificationAsRead: {},
    categories: [],
    unreadCount: { count: 0 },
    error: {}
};

//GetNotifications

export const getNotifications = createAsyncThunk(
    'containers/notifications',
    async (values: object) => {
        try {
            const response = await axiosRequest
                .get(`user/notifications`, { params: values })
                .then((res) => {
                    return res;
                });
            return response.data;
        } catch (e) {
            return (e as AxiosError).response.data.message;
        }
    }
);

//getUnreadCount

export const getUnreadCount = createAsyncThunk(
    'containers/notifications/unread-count',
    async () => {
        try {
            const response = await axiosRequest
                .get(`user/notifications/unread-count`)
                .then((res) => {
                    return res;
                });
            return response.data;
        } catch (e) {
            return (e as AxiosError).response.data.message;
        }
    }
);

//getCategories

export const getNotificationsCategories = createAsyncThunk(
    'containers/notifications/categories',
    async () => {
        try {
            const response = await axiosRequest
                .get(`/user/notifications/categories`)
                .then((res) => {
                    return res;
                });

            return response.data.data?.map(({ name }: { name: any }) => ({
                value: name,
                label: name
            }));
        } catch (e) {
            return (e as AxiosError).response.data.message;
        }
    }
);

// getServerPinnedNotifications

export const getServerPinnedNotifications = createAsyncThunk(
    'containers/notifications/serverPinnedNotifications',
    async () => {
        try {
            const response = await axiosRequest
                .get(`/user/notifications/server-pinned`)
                .then((res) => {
                    return res;
                });
            return response.data;
        } catch (e) {
            return (e as AxiosError).response.data.message;
        }
    }
);

//getPinnedNotifications

export const getPinnedNotifications = createAsyncThunk(
    'containers/notifications/pinnedNotifications',
    async () => {
        try {
            const response = await axiosRequest.get(`/user/notifications/pinned`).then((res) => {
                return res;
            });
            return response.data;
        } catch (e) {
            return (e as AxiosError).response.data.message;
        }
    }
);

// Mark notification as pinned
export const postNotificationAsPinned = createAsyncThunk(
    'notifications/pin',
    async (id: any, { rejectWithValue, dispatch }) => {
        try {
            const response = await axiosRequest.post(`/user/notifications/${id}/pin`);
            dispatch(getPinnedNotifications());
            dispatch(getNotifications({ per_page: 10 }));
            return response.data;
        } catch (e) {
            return rejectWithValue((e as AxiosError).response.data.message);
        }
    }
);

// Mark notification as unpinned
export const postAsUnpinned = createAsyncThunk(
    'notifications/unpin',
    async (id: any, { rejectWithValue, dispatch }) => {
        try {
            const response = await axiosRequest.post(`/user/notifications/${id}/unpin`);
            dispatch(getPinnedNotifications());
            dispatch(getNotifications({ per_page: 10 }));
            return response.data;
        } catch (e) {
            return rejectWithValue((e as AxiosError).response.data.message);
        }
    }
);

// Mark notification as read
export const postNotificationAsRead = createAsyncThunk(
    'notifications/read',
    async (id: any, { rejectWithValue }) => {
        try {
            const response = await axiosRequest.post(`/user/notifications/${id}/read`);

            return response.data;
        } catch (e) {
            return rejectWithValue((e as AxiosError).response.data.message);
        }
    }
);

// Mark all notifications as read
export const markAllNotificationsAsRead = createAsyncThunk(
    'notifications/allAsRead',
    async (values: undefined, { rejectWithValue }) => {
        try {
            const response = await axiosRequest.post(`/user/notifications/mark-all-read`);

            return response.data;
        } catch (e) {
            return rejectWithValue((e as AxiosError).response.data.message);
        }
    }
);

// CTR Tracking on notification
export const postNotificationCTR = createAsyncThunk(
    'notifications/ctr',
    async (id: any, { rejectWithValue }) => {
        try {
            const response = await axiosRequest.post(`/user/notifications/notification-ctr`, {
                notifier_id: id
            });

            return response.data;
        } catch (e) {
            return rejectWithValue((e as AxiosError).response.data.message);
        }
    }
);

export const NotificationsSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder

            //getNotifications
            .addCase(getNotifications.pending, (state) => {
                state.dataStatus = 'loading';
            })
            .addCase(getNotifications.fulfilled, (state, action) => {
                state.dataStatus = 'idle';
                state.notifications = action.payload;
            })
            .addCase(getNotifications.rejected, (state, action) => {
                state.dataStatus = 'failed';
                state.error = action.payload;
            })

            //getUnreadCount
            .addCase(getUnreadCount.fulfilled, (state, action) => {
                state.unreadCount = action.payload;
            })
            .addCase(getUnreadCount.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })

            //getNotificationsCategories
            .addCase(getNotificationsCategories.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getNotificationsCategories.fulfilled, (state, action) => {
                state.status = 'idle';
                state.categories = action.payload;
            })
            .addCase(getNotificationsCategories.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })

            //getServerPinnedNotifications
            .addCase(getServerPinnedNotifications.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getServerPinnedNotifications.fulfilled, (state, action) => {
                state.status = 'idle';
                state.serverPinnedNotifications = action.payload;
            })
            .addCase(getServerPinnedNotifications.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })

            //getPinnedNotifications
            .addCase(getPinnedNotifications.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getPinnedNotifications.fulfilled, (state, action) => {
                state.status = 'idle';
                state.pinnedNotifications = action.payload;
            })
            .addCase(getPinnedNotifications.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })

            //postNotificationAsPinned
            .addCase(postNotificationAsPinned.fulfilled, (state, action) => {
                state.status = 'idle';
                state.markNotificationAsPinned = action.payload;
            })
            .addCase(postNotificationAsPinned.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })

            //postAsUnpinned
            .addCase(postAsUnpinned.fulfilled, (state, action) => {
                state.status = 'idle';
                state.markAsUnpinned = action.payload;
            })
            .addCase(postAsUnpinned.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })

            //postNotificationAsRead
            .addCase(postNotificationAsRead.fulfilled, (state, action) => {
                state.status = 'idle';
                state.markNotificationAsRead = action.payload;
            })
            .addCase(postNotificationAsRead.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })

            //postAllNotificationsAsRead
            .addCase(markAllNotificationsAsRead.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(markAllNotificationsAsRead.fulfilled, (state) => {
                state.status = 'idle';
            })
            .addCase(markAllNotificationsAsRead.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(resetState, () => initialState);
    }
});

export const resetState = createAction('REVERT_ALL');

export const ExceededPinnedLimit = (state: RootState) => state.notifications.status === 'failed';
export const selectLoading = (state: RootState) => state.notifications.status === 'loading';

export default NotificationsSlice.reducer;
