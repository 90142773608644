export const MenuBadgeIcon = ({ style }: { style: Object }) => {
    return (
        <svg
            style={style}
            width="20"
            height="24"
            viewBox="0 0 20 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10 0.945177L19.145 5.25463V10.9068C19.1432 13.6043 18.3617 15.9491 16.8122 17.9754C15.3105 19.9392 13.0567 21.643 10 23.0604C6.9821 21.6609 4.74648 19.9821 3.24502 18.0496C1.69515 16.0547 0.896266 13.7504 0.857424 11.1044C0.857419 11.1041 0.857414 11.1037 0.857409 11.1034L0.855 10.9034V5.25463L10 0.945177Z"
                stroke="black"
                strokeWidth="1.71"
                strokeMiterlimit="16"
            />
            <path
                d="M15 7.74888L10 5L5 7.74888V11.3633L5.00125 11.4843C5.0425 14.7801 6.71688 17.2849 10 19C13.3237 17.2638 14.9981 14.7182 15 11.3626V7.74888Z"
                stroke="black"
                strokeMiterlimit="16"
            />
        </svg>
    );
};
