import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axiosRequest from '@utils/axios';

// import { RootState } from '@app/store';

export interface Statistics {
    answer: string;
    statistic: string;
}

export interface Answer {
    created_at?: string;
    deleted_at?: string;
    id?: number;
    quiz_question_id?: number;
    updated_at: string;
    user_quiz_id: string;
    values: { value: string }[];
}

export interface QuizState {
    status: 'idle' | 'loading' | 'failed';
    quizQuestions: {
        quiz: {
            start_date: string;
            end_date: string;
            steps: Array<{
                id: number;
                is_completed?: boolean;
                is_current_step: boolean;
                image: string;
                title: string;
                questions: Array<{
                    id: number;
                    title: string;
                    description: string;
                    quiz_step_id: number;
                    statistics?: Statistics[];
                    answer: Answer;
                    options: Array<{
                        is_correct_answer: '0' | '1';
                        value: string;
                    }>;
                    displayable_type: string;
                }>;
            }>;
            points: number;
            chances: number;
            slug: string;
            campaign_title: string;
        };
        is_completed: boolean;
        telcor_gift_data?: { response: { allocatedFp: number; allocatedVoucher: boolean } };
        isCompletedFinal?: boolean;
    };
    error: any;
}

interface AxiosError {
    response: {
        data: {
            message: string;
        };
    };
}

const initialState: QuizState = {
    status: 'idle',
    quizQuestions: {
        quiz: {
            start_date: '',
            end_date: '',
            steps: [],
            slug: '',
            points: 0,
            chances: 0,
            campaign_title: ''
        },
        is_completed: false,
        telcor_gift_data: { response: { allocatedFp: 0, allocatedVoucher: false } },
        isCompletedFinal: false
    },
    error: {}
};

export const getQuiz = createAsyncThunk(
    'profile/Quiz',
    async (values: { slug: string; statistics: boolean }) => {
        const queryParams =
            values.statistics === true ? { attach_statistics: 1 } : { attach_statistics: 0 };

        try {
            const response = await axiosRequest
                .get(`/user/quizzes/${values.slug}`, { params: queryParams })
                .then((res) => {
                    return res;
                });
            return response.data;
        } catch (e) {
            return (e as AxiosError).response.data.message;
        }
    }
);

export const postQuizQuestions = createAsyncThunk(
    'quiz/question',
    async (
        values: {
            quiz_slug: string;
            step_id: number;
            answers: Array<{
                quiz_question_id: number;
                values: Array<{ value: string | unknown | []; custom_value?: string }>;
            }>;
            image?: string;
        },
        { rejectWithValue }
    ) => {
        const { quiz_slug, step_id, answers, image } = values;

        let data: any = { answers };

        if (image) {
            data.image = image;
        }
        try {
            const response = await axiosRequest.post(`/user/quizzes/${quiz_slug}/${step_id}`, data);

            return response.data;
        } catch (e) {
            return rejectWithValue((e as AxiosError).response.data);
        }
    }
);

export const putAllQuizQuestions = createAsyncThunk(
    'quiz/AllQuestions',
    async (values: {
        quiz_slug: string;
        answers: Array<{
            quiz_question_id: number;
            values: Array<{ value: string | unknown | []; custom_value?: string }>;
        }>;
    }) => {
        const { quiz_slug, answers } = values;
        try {
            const response = await axiosRequest.put(`/user/quizzes/${quiz_slug}`, {
                answers
            });

            return response.data;
        } catch (e) {
            return (e as AxiosError).response.data.message;
        }
    }
);

export const QuizSlice = createSlice({
    name: 'quiz',
    initialState,
    reducers: {
        resetQuiz: () => initialState,

        resetQuizAnswers: (state) => {
            let oldQuiz = { ...state.quizQuestions };

            for (let i = 0, n = oldQuiz.quiz.steps.length; i < n; i++) {
                for (let j = 0, m = oldQuiz.quiz.steps[i].questions.length; j < m; j++) {
                    oldQuiz.quiz.steps[i].questions[j].answer = {
                        updated_at: '',
                        user_quiz_id: '',
                        values: [
                            {
                                value: ' '
                            }
                        ]
                    };
                }
            }

            state.quizQuestions = oldQuiz;

            oldQuiz.isCompletedFinal = true;
        },

        setQuiz: (state, action) => {
            let oldQuiz = state.quizQuestions;

            for (let i = 0, n = oldQuiz.quiz.steps.length; i < n; i++) {
                for (let j = 0, m = oldQuiz.quiz.steps[i].questions.length; j < m; j++) {
                    if (oldQuiz.quiz.steps[i].questions[j].id === action.payload.questionId) {
                        if (oldQuiz.quiz.steps[i].questions[j].answer?.values[0]) {
                            oldQuiz.quiz.steps[i].questions[j].answer.values[0].value =
                                action.payload.questionAnswer;
                        } else {
                            oldQuiz.quiz.steps[i].questions[j].answer = {
                                updated_at: '',
                                user_quiz_id: '',
                                values: [
                                    {
                                        value: action.payload.questionAnswer
                                    }
                                ]
                            };
                        }
                    }
                }
            }

            state.quizQuestions = oldQuiz;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getQuiz.pending, (state) => {
                state.status = 'loading';
            })

            .addCase(getQuiz.fulfilled, (state, action) => {
                state.status = 'idle';
                state.quizQuestions = action.payload;
            })

            .addCase(getQuiz.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })

            //postQuizQuestions
            .addCase(postQuizQuestions.pending, (state) => {
                state.status = 'loading';
            })

            .addCase(postQuizQuestions.fulfilled, (state, action) => {
                state.status = 'idle';
                state.quizQuestions = action.payload;
            })

            .addCase(postQuizQuestions.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })

            //putAllQuizQuestions
            .addCase(putAllQuizQuestions.pending, (state) => {
                state.status = 'loading';
            })

            .addCase(putAllQuizQuestions.fulfilled, (state) => {
                state.status = 'idle';
            })

            .addCase(putAllQuizQuestions.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })

            .addCase(resetState, () => initialState);
    }
});

export const resetState = createAction('REVERT_ALL');

export const { resetQuiz, setQuiz, resetQuizAnswers } = QuizSlice.actions;
// export const quizLoading = (state: RootState) => state.quiz.status === 'loading';

export default QuizSlice.reducer;
