import { useState } from 'react';
import styles from './IqosRomDesignWeek.module.scss';

// Helpers
import classNames from 'classnames';

// Assets
import imgTour from './assets/imgTour.png';
import videoThumbnail2 from './assets/videoThumbnail2.png';

import img01 from '@containers/IQOSUniverse/articles/MaintenanceLilSolid/assets/img01.png';
import img02 from '@containers/IQOSUniverse/articles/MaintenanceLilSolid/assets/img02.png';
import img03 from '@containers/IQOSUniverse/articles/MaintenanceLilSolid/assets/img03.png';
import img04 from '@containers/IQOSUniverse/articles/MaintenanceLilSolid/assets/img04.png';

// Components
import { Modal } from 'antd';
import Button from '@components/Layout/Button';
import VideoModal from '@components/VideoModal';

import ArticleImage from '@containers/IQOSUniverse/components/ArticleImage';
import MediaGallery from '@containers/IQOSUniverse/components/MediaGallery';
import ArticleHeader from '@containers/IQOSUniverse/components/ArticleHeader';
import ArticleContainer from '@containers/IQOSUniverse/components/ArticleContainer';

const IqosRomDesignWeek = () => {
    const [toggleModal, setToggleModal] = useState(false);
    const [showExploreModal, setExploreModal] = useState(false);

    return (
        <div className={styles.wrapper}>
            <ArticleHeader />

            <div className="content">
                <h3>
                    Cel mai amplu festival multidisciplinar dedicat industriilor creative locale
                </h3>

                <p>
                    A zecea ediție a Romanian Design Week a transformat din nou Bucureștiul într-o
                    oază a creativității. Expozițiile și evenimentele de la Combinatul Fondului
                    Plastic au primit, timp de 10 zile, 35.000 de vizitatori îndrăgostiți de design,
                    creativitate și artă, care s-au bucurat de instalații și activări inedite care
                    au avut tema #Forward.
                </p>

                <p>
                    IQOS a fost prezent în festival cu instalația Explorers of the Multiverse,
                    realizată împreună cu studioul H3, o echipă de artiști vizionari curajoși, care
                    îmbină creativitatea cu tehnologia.
                </p>
            </div>

            <ArticleContainer background={'#34303D'} textColor={'#F6F4F0'}>
                <div className={classNames(styles.content, 'content')}>
                    <ArticleImage
                        isVideo
                        mobileImage={videoThumbnail2}
                        image={videoThumbnail2}
                        onClick={() => setToggleModal(true)}
                    />

                    <VideoModal
                        toggleModal={toggleModal}
                        setToggleModal={() => setToggleModal(false)}
                        video="720666931"
                    />

                    <h3 className={styles.title}>
                        Cum a luat naștere instalația Explorers of the Multiverse by H3{' '}
                    </h3>

                    <p>
                        Explorers of the Multiverse este o instalație care te teleportează într-o
                        matrice infinită, la limita dintre lumea reală și universul digital. Află de
                        la însuși creatorul instalației cum s-a cristalizat ideea și cum arată, în
                        viziunea lui, viitorul artei și al tehnologiei.
                    </p>

                  
                </div>
            </ArticleContainer>

            <div className="content">
                <h3>
                    Vizitează virtual Explorers of the Multiverse by H3: o instalație care te
                    transportă în toate instanțele simultane ale universului, în toate „universurile
                    paralele” în care existăm.
                </h3>

                <div className={styles.videoModal}>
                    <img src={imgTour} className={styles.thumbVideo} alt={''} />
                    <Button
                        className={styles.playBtn}
                        type="outlined"
                        hover="light"
                        color="light"
                        onClick={() => {
                            setExploreModal(true);
                        }}
                        label="Începe turul virtual"
                    />
                </div>

                <Modal
                    footer={null}
                    destroyOnClose
                    open={showExploreModal}
                    onCancel={() => setExploreModal(false)}
                    mask
                >
                    <iframe
                        src="https://my.matterport.com/show/?m=NJ5sVPe42Fx"
                        title={'modal video'}
                    />
                </Modal>
            </div>

            <div className="content">
                <MediaGallery images={[img01, img02, img03, img04]} />
            </div>
        </div>
    );
};
export default IqosRomDesignWeek;
