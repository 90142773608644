import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@app/store';
import axiosRequest from '@utils/axios';

export interface CountiesState {
    counties: Array<Object>;
    cities: Array<Object>;
    countiesRedeem: Array<Object>;
    citiesRedeem: Array<Object>;
    status: 'idle' | 'loading' | 'failed';
    error: any;
}

interface AxiosError {
    response: {
        data: {
            message: string;
        };
    };
}

const initialState: CountiesState = {
    counties: [],
    cities: [],
    countiesRedeem: [],
    citiesRedeem: [],
    status: 'idle',
    error: {}
};

export const countiesAsync = createAsyncThunk('counties/getCounties', async () => {
    try {
        const response = await axiosRequest.get('/counties');
        return response.data.response.map(({ id, county }: { id: any; county: any }) => ({
            value: id,
            label: county
        }));
    } catch (e) {
        return (e as AxiosError).response.data.message;
    }
});

export const citiesAsync = createAsyncThunk(
    'counties/getCities',
    async (countryID: string, { rejectWithValue }) => {
        try {
            const response = await axiosRequest.get('/cities', {
                params: { county_id: countryID }
            });
            return response.data.response[0].cities.map(({ id, city }: { id: any; city: any }) => ({
                value: id,
                label: city
            }));
        } catch (e) {
            return rejectWithValue((e as AxiosError).response.data.message);
        }
    }
);

export const redeemCounties = createAsyncThunk('counties/redeem/getCounties', async () => {
    try {
        const response = await axiosRequest.get('/dcs/counties');
        return response.data.map(({ name, short_name }: { name: string; short_name: string }) => ({
            value: short_name,
            label: name
        }));
    } catch (e) {
        return (e as AxiosError).response.data.message;
    }
});

export const redeemCities = createAsyncThunk('counties/redeem/getCities', async (value: string) => {
    try {
        const response = await axiosRequest.get(`/dcs/cities?county_short_name=${value}`);
        return response.data.map(({ name }: { name: string }) => ({
            value: name,
            label: name
        }));
    } catch (e) {
        return (e as AxiosError).response.data.message;
    }
});

export const countiesSlice = createSlice({
    name: 'counties',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder

            // Counties
            .addCase(countiesAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(countiesAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.counties = action.payload;
            })
            .addCase(countiesAsync.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })

            // Cities
            .addCase(citiesAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(citiesAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.cities = action.payload;
            })
            .addCase(citiesAsync.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })

            // Counties Redeem
            .addCase(redeemCounties.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(redeemCounties.fulfilled, (state, action) => {
                state.status = 'idle';
                state.countiesRedeem = action.payload;
            })
            .addCase(redeemCounties.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })

            // Cities Redeem
            .addCase(redeemCities.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(redeemCities.fulfilled, (state, action) => {
                state.status = 'idle';
                state.citiesRedeem = action.payload;
            })
            .addCase(redeemCities.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    }
});

export const selectLoading = (state: RootState) => state.counties.status === 'loading';
export const selectFailed = (state: RootState) => state.counties.status === 'failed';
export const selectError = (state: RootState) => state.counties.error;

export const selectCounties = (state: RootState) => state.counties.counties;
export const selectCities = (state: RootState) => state.counties.cities;

export default countiesSlice.reducer;
