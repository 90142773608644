export const StarSmall = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
        >
            <path
                d="M4.53037 1.01865C4.71922 0.616329 5.29144 0.61631 5.48031 1.01862L6.42978 3.04102C6.50416 3.19946 6.65266 3.31038 6.82569 3.33675L8.98985 3.66656C9.41214 3.73092 9.58467 4.24595 9.28636 4.5517L7.68771 6.1902C7.57141 6.3094 7.5186 6.47673 7.54542 6.6411L7.91833 8.92708C7.98885 9.35937 7.52994 9.68268 7.14661 9.47076L5.25962 8.42758C5.10165 8.34024 4.90987 8.34024 4.7519 8.42758L2.86491 9.47076C2.48158 9.68268 2.02267 9.35937 2.09319 8.92708L2.4661 6.6411C2.49292 6.47673 2.44011 6.3094 2.32381 6.1902L0.724822 4.55135C0.426577 4.24567 0.59896 3.73075 1.02113 3.66625L3.18595 3.33548C3.35892 3.30905 3.50733 3.19815 3.58168 3.03975L4.53037 1.01865Z"
                fill="#FFD657"
                stroke="#F6F4F0"
                stroke-width="0.524702"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};
