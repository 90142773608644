import { IMemoryCard } from './interface';

// Styles
import styles from './MemoryCard.module.scss';

// Assets
import BackCard from './assets/backcard.jpg';

// Utils
import classNames from 'classnames';

const MemoryCard = ({
    onClick,
    image,
    index,
    isInactive,
    isFlipped,
    isDisabled,
    godMode = false
}: IMemoryCard) => {
    const handleClick = () => {
        !isFlipped && !isDisabled && onClick(index);
    };

    return (
        <div
            className={classNames(
                styles.container,
                isFlipped && styles.isFlipped,
                isInactive && styles.isInactive
            )}
            onClick={handleClick}
        >
            <div className={classNames(styles.cardFace, godMode && styles.godMode)}>
                <img src={godMode ? image : BackCard} />
            </div>
            <div className={classNames(styles.cardFace, styles.cardBackFace)}>
                <img src={image} />
            </div>
        </div>
    );
};

export default MemoryCard;
