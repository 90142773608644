import React, { useEffect, useState } from 'react';

import styles from './StardriftQuiz.module.scss';

// Components
import Button from '@components/Layout/Button';
import Radio from '@components/Quiz/Radio';
import BaseLoader from '@components/Layout/BaseLoader';
import { Form, Progress } from 'antd';
import BaseModal from '@components/Layout/BaseModal';

// Assets
import finishedQuizImage from '../assets/finishedQuizImage.png';
import mobileBackground from '../assets/quizCardBackgroundMobile.png';
import desktopBackground from '../assets/desktopBackground.png';
import { FPIcon } from '@assets/icons';

// Redux
import { getQuiz, postQuizQuestions, resetQuizAnswers } from '@components/Quiz/QuizSlice';
import { useAppDispatch, useAppSelector } from '@app/hooks';

// Helpers
import { useWindowSize } from '@utils/hooks/windowSize';
import transformAnswers from '@components/Quiz/transformAnswers';
import {
    stardriftQuizAnswers,
    stepImages
} from '@containers/Campaigns/IlumaStardriftPrelaunch/StardriftQuiz/data';
import { scroller } from 'react-scroll';

const QuizComponents: { [key: string]: React.JSXElementConstructor<any> } = {
    Radio: Radio
};

const StardriftQuiz = () => {
    const { article } = useAppSelector((state) => state.iqosUniverse);

    const dispatch = useAppDispatch();
    const [form] = Form.useForm();

    const window = useWindowSize();
    const isSmallMobile = window.width <= 360;
    const isMobile = window.width <= 400;
    const isDesktop = window.width > 1240;

    const { quizQuestions, status } = useAppSelector((state) => state.quiz);

    const [currentStep, setCurrentStep] = useState(0);
    const [disableButton, setDisableButton] = useState(true);
    const [isCorrectAnswer, setIsCorrectAnswer] = useState(false);
    const [showAnswer, setShowAnswer] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    // const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        getData();
    }, []);

    // const openSuccessModal = () => {
    //     if (quizQuestions.is_completed && !quizQuestions.isCompletedFinal) {
    //         setIsModalOpen(true);
    //     }
    // };

    const getData = async () => {
        let quizData = await dispatch(
            getQuiz({ slug: article?.quiz_slug || ' ', statistics: false })
        )
            .then((r) => {
                if (r.payload.quiz?.steps[currentStep]?.questions[0]?.answer !== null) {
                    setDisableButton(false);
                } else {
                    setDisableButton(true);
                }
                return r.payload;
            })
            .catch((error) => {
                return error;
            });

        if (quizData) {
            if (quizData.is_completed) {
                dispatch(resetQuizAnswers());
            }
        }
    };

    const nextStep = () => {
        setShowAnswer(false);
        if (currentStep + 1 < quizQuestions.quiz?.steps?.length) {
            if (quizQuestions.quiz?.steps[currentStep + 1]?.questions[0]?.answer !== null) {
                setDisableButton(false);
            } else {
                setDisableButton(true);
            }
            setCurrentStep((prevState) => prevState + 1);
        } else {
            setShowSuccess(true);
            // openSuccessModal();
        }
        scrollTo();
    };

    const answerAgain = () => {
        setShowAnswer(false);
    };

    const onValuesChange = (allValues: any) => {
        if (allValues !== undefined) {
            setDisableButton(false);
        } else {
            setDisableButton(true);
        }
    };

    const percentProgress = ((currentStep + 1) * 100) / quizQuestions?.quiz?.steps.length;
    const progressTooltipStyle = isSmallMobile ? 6 : isMobile ? 5 : isDesktop ? 8 : 5;

    const answerQuestion = () => {
        form.validateFields().then((values) => {
            let step: {
                quiz_slug: string;
                step_id: number;
                answers: Array<{
                    quiz_question_id: number;
                    values: Array<{ value: string | unknown | []; custom_value?: string }>;
                }>;
            } = {
                quiz_slug: article?.quiz_slug || ' ',
                step_id: quizQuestions?.quiz?.steps[currentStep]?.id,
                answers: transformAnswers(values)
            };

            let correctAnswers = quizQuestions?.quiz?.steps[currentStep]?.questions
                .map((q) => {
                    let answeredValues = Array.isArray(values[q.id])
                        ? values[q.id]
                        : values[q.id].split();

                    if (
                        q.options.filter((o: any) => Number(o.is_correct_answer) === 1).length ===
                        answeredValues.length
                    ) {
                        let qOptions = q.options
                            .filter((opt: any) => Number(opt?.is_correct_answer) === 1)
                            .map((option: any) => {
                                return option.value;
                            });

                        for (let i = 0, n = qOptions.length; i < n; i++) {
                            let idx = answeredValues.indexOf(qOptions[i]);
                            if (idx === -1) {
                                return false;
                            }
                        }
                        return true;
                    } else {
                        return false;
                    }
                })
                .filter((q: boolean) => !q);

            setIsCorrectAnswer(correctAnswers.length === 0);

            setShowAnswer(true);

            if (!quizQuestions.isCompletedFinal) {
                if (correctAnswers.length === 0) {
                    dispatch(postQuizQuestions(step));
                }
            }
        });

        scrollTo();
    };

    const scrollTo = () => {
        scroller.scrollTo('stardriftIqosUniverseQuiz', {
            smooth: true,
            duration: 500,
            offset: -110
        });
    };

    if (status === 'loading') {
        return <BaseLoader size={'normal'} />;
    }

    const showAnswerOrCompletedQuiz = () => {
        if (showSuccess) {
            return (
                <div className={styles.correctOrWrongAnswers}>
                    <h3 className={styles.answerTitle}>Felicitări!</h3>
                    <p className={styles.answerSubtitle}>
                        Știi totul despre noul IQOS ILUMA STARDRIFT. Află și despre colecția de căni
                        în ediție limitată inspirată de noul dispozitiv, din articolul de mai jos,
                        pentru a aduna încă 100 de Forward Points.
                    </p>
                </div>
            );
        } else if (showAnswer) {
            if (isCorrectAnswer) {
                return (
                    <div
                        key={stardriftQuizAnswers[currentStep].id}
                        className={styles.correctOrWrongAnswers}
                    >
                        <h3 className={styles.answerTitle}>
                            {stardriftQuizAnswers[currentStep].correctAnswerTitle}
                        </h3>
                        <p className={styles.answerSubtitle}>
                            {stardriftQuizAnswers[currentStep].correctAnswerSubtitle}
                        </p>
                    </div>
                );
            } else {
                return (
                    <div
                        key={stardriftQuizAnswers[currentStep].id}
                        className={styles.correctOrWrongAnswers}
                    >
                        <h3 className={styles.answerTitle}>
                            {stardriftQuizAnswers[currentStep].wrongAnswerTitle}
                        </h3>
                        <p className={styles.answerSubtitle}>
                            {stardriftQuizAnswers[currentStep].wrongAnswerSubtitle}
                        </p>
                    </div>
                );
            }
        } else {
            return (
                <div className={styles.quizQuestionsContainer}>
                    {(!quizQuestions?.is_completed || quizQuestions.isCompletedFinal) &&
                        quizQuestions?.quiz?.steps[currentStep]?.questions?.map((q) => {
                            return (
                                <div key={q.id} className={styles.quizQuestionsDetails}>
                                    <h3>{q.title}</h3>
                                    <div className={styles.form}>
                                        <Form form={form} onValuesChange={onValuesChange}>
                                            {typeof QuizComponents[q.displayable_type] !==
                                            'undefined'
                                                ? React.createElement(
                                                      QuizComponents[q.displayable_type],
                                                      {
                                                          key: q.id,
                                                          data: q,
                                                          noTitle: true,
                                                          radioBackground: 'light'
                                                      }
                                                  )
                                                : null}
                                        </Form>
                                    </div>
                                </div>
                            );
                        })}
                </div>
            );
        }
    };

    const answerQuizAgain = () => {
        document.location.reload();
    };

    // const handleCloseModal = () => {
    //     setIsModalOpen(false);
    // };

    return (
        <section id={'stardriftIqosUniverseQuiz'} className={styles.stardriftQuizContainer}>
            <div className={styles.quizCard}>
                <img
                    className={styles.quizCardBackground}
                    src={isDesktop ? desktopBackground : mobileBackground}
                    alt={'quiz card background'}
                />

                {isDesktop && (
                    <div className={styles.desktopImage}>
                        <img
                            src={showSuccess ? finishedQuizImage : stepImages[currentStep].image}
                            alt={'quiz card visual'}
                        />
                    </div>
                )}

                <div className={styles.cardContent}>
                    <div className={styles.progressContainer}>
                        <div
                            style={{
                                left: `${percentProgress - progressTooltipStyle}%`
                            }}
                            className={styles.tooltipContainer}
                        >
                            <div className={styles.tooltip}>{percentProgress}%</div>
                            <div className={styles.point}></div>
                        </div>

                        <Progress percent={percentProgress} showInfo={false} />
                    </div>

                    {!isDesktop && (
                        <div className={styles.cardImageWrapper}>
                            <img
                                src={
                                    showSuccess ? finishedQuizImage : stepImages[currentStep].image
                                }
                                alt={'quiz card visual'}
                            />
                        </div>
                    )}

                    {showAnswerOrCompletedQuiz()}

                    {showSuccess ? (
                        <div className={styles.successButtonsWrapper}>
                            <Button
                                type="solid"
                                color="light"
                                hover="turquoise"
                                label="Citește articolul"
                                href={
                                    '/universul-iqos/inspiratie-inovatie/iqos-iluma-stardrift-iti-aduce-inspiratie-pentru-dorintele-tale'
                                }
                            />

                            <Button
                                type="solid"
                                color="dark"
                                hover="turquoise"
                                onClick={answerQuizAgain}
                                label="Completează din nou"
                            />
                        </div>
                    ) : (
                        <div className={styles.buttonWrapper}>
                            {showAnswer ? (
                                isCorrectAnswer ? (
                                    <Button
                                        type="solid"
                                        color="light"
                                        hover="turquoise"
                                        onClick={nextStep}
                                        label="Mai departe"
                                    />
                                ) : (
                                    <Button
                                        type="solid"
                                        color="light"
                                        hover="turquoise"
                                        onClick={answerAgain}
                                        label="Încearcă din nou"
                                    />
                                )
                            ) : (
                                <Button
                                    disabled={disableButton}
                                    type="solid"
                                    color="light"
                                    hover="turquoise"
                                    onClick={answerQuestion}
                                    label="Mai departe"
                                />
                            )}
                        </div>
                    )}
                </div>
            </div>

            {/*<BaseModal*/}
            {/*    size="small"*/}
            {/*    isModalOpen={isModalOpen}*/}
            {/*    className={styles.successModal}*/}
            {/*    closeModal={() => handleCloseModal()}*/}
            {/*    destroyOnClose*/}
            {/*>*/}
            {/*    <div className={styles.stardriftQuizCompletedModal}>*/}
            {/*        <h3>WOW!</h3>*/}

            {/*        <div className={styles.modalQuizReward}>*/}
            {/*            <FPIcon />*/}
            {/*            <span>*/}
            {/*                <b>+{quizQuestions?.quiz?.points}</b> <br />*/}
            {/*                Forward Points*/}
            {/*            </span>*/}
            {/*        </div>*/}

            {/*        <p>*/}
            {/*            Ai completat cu succes quiz-ul despre IQOS ILUMA STARDRIFT și ai acumulat{' '}*/}
            {/*            {quizQuestions?.quiz?.points}FP!*/}
            {/*        </p>*/}
            {/*    </div>*/}
            {/*</BaseModal>*/}
        </section>
    );
};

export default StardriftQuiz;
