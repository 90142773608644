// Assets Slide 1
import img1 from '../assets/slider1/img1.jpg';
import img2 from '../assets/slider1/img2.jpg';
import img3 from '../assets/slider1/img3.jpg';
import img4 from '../assets/slider1/img4.jpg';
import img5 from '../assets/slider1/img5.jpg';
import img6 from '../assets/slider1/img6.jpg';
import img7 from '../assets/slider1/img7.jpg';

import img21 from '../assets/slider2/img21.jpg';
import img22 from '../assets/slider2/img22.jpg';
import img23 from '../assets/slider2/img23.jpg';
import img24 from '../assets/slider2/img24.jpg';
import img25 from '../assets/slider2/img25.jpg';
import img26 from '../assets/slider2/img26.jpg';
import img27 from '../assets/slider2/img27.jpg';
import img28 from '../assets/slider2/img28.jpg';
import img210 from '../assets/slider2/img210.jpg';
import img211 from '../assets/slider2/img211.jpg';
import img212 from '../assets/slider2/img212.jpg';
import img213 from '../assets/slider2/img213.jpg';
import img214 from '../assets/slider2/img214.jpg';
import img215 from '../assets/slider2/img215.jpg';
import img216 from '../assets/slider2/img216.jpg';

export interface Experience {
    title: string;
    image: string;
    titleDark: boolean;
    belowDescription: { title?: string; subtitle: string };
}

export const SliderExperience1: Experience[] = [
    {
        title: `Experiență culinară rafinată în Siena`,
        belowDescription: {
            subtitle:
                'Câștigătorii noștri s-au delectat cu o experiență gastronomică desăvârșită și au ciocnit un pahar de vin în piața centrală din Siena. Cina rafinată, într-o locație de vis, cu preparate specifice Toscanei a fost, cu siguranță, o experiență memorabilă pentru toți cei prezenți.'
        },
        image: img1,
        titleDark: true
    },
    {
        title: `Pallio di Siena, un eveniment cu tradiție de secole`,
        belowDescription: {
            subtitle:
                'Orașul Siena este renumit pentru tradiționalele curse de cai iar câștigătorii concursului au avut privilegiul să exploreze domeniul lui Giovanni Atzeni, unde au admirat îndeaproape caii săi de curse. Ca experiența să fie completă, l-au întâlnit și pe Giovanni, unul dintre campionii competiției Palio di Siena, care le-a oferit șansa unică de a asista la o demonstrație impresionantă de călărie.'
        },
        image: img2,
        titleDark: true
    },
    {
        title: `Degustare de vinuri într-o locație de excepție`,
        belowDescription: {
            subtitle:
                'Câștigătorii au avut ocazia să deguste sortimentele locale de vinuri și să descopere istoria și tehnicile de vinificație specifice zonei, totul într-o locație cu o arhitectură deosebită, înconjurată de vii în care cresc cele mai populare soiuri de struguri din Toscana.'
        },
        image: img3,
        titleDark: true
    },
    {
        title: `Relaxare la malul mării Tireniene`,
        belowDescription: {
            subtitle:
                'O zi întreagă petrecută la plajă, pe malul mării Tireniene, renumită pentru nisipul ei fin și auriu, unde câștigătorii s-au bucurat de soare, de apa mării și de un prânz bogat în pește și fructe de mare.'
        },
        image: img4,
        titleDark: true
    },
    {
        title: `Workshop culinar cu preparate tradiționale`,
        belowDescription: {
            subtitle:
                'O experiență cu adevărat specială în care câștigătorii au avut ocazia să învețe să prepare câteva rețete cu tradiție în zona Toscanei, totul într-un peisaj pitoresc, înconjurați de plantații de măslini și viță de vie.'
        },
        image: img5,
        titleDark: true
    },
    {
        title: `Florența, capitala artei și arhitecturii renascentiste`,
        belowDescription: {
            subtitle:
                'O întreagă zi a fost dedicată explorării faimoaselor galerii de artă din oraș, a celor mai fascinante obiective ale Florenței, dar și bucătăriei toscane.'
        },
        image: img6,
        titleDark: true
    },
    {
        title: `O petrecere cu distracție și mister `,
        belowDescription: {
            subtitle:
                'Am marcat finalul experienței în Toscana cu o petrecere memorabilă la piscină, cu muzică live, delicii culinare și open bar. Pentru a adăuga un strop de mister, au avut și un invitat surpriză: un magician celebru în Italia, finalist al unuia dintre renumitele show-uri de talent, care i-a încântat cu trucuri spectaculoase de magie.'
        },
        image: img7,
        titleDark: true
    }
];

export const SliderExperience2: { id: number; image: string }[] = [
    {
        id: 1,
        image: img27
    },
    {
        id: 2,
        image: img21
    },
    {
        id: 3,
        image: img22
    },
    {
        id: 4,
        image: img23
    },
    {
        id: 5,
        image: img24
    },
    {
        id: 6,
        image: img25
    },
    {
        id: 7,
        image: img26
    },
    {
        id: 8,
        image: img211
    },
    {
        id: 9,
        image: img28
    },

    {
        id: 10,
        image: img212
    },
    {
        id: 11,
        image: img213
    },
    {
        id: 12,
        image: img214
    },
    {
        id: 13,
        image: img215
    },
    {
        id: 14,
        image: img216
    },
    {
        id: 15,
        image: img210
    }
];
