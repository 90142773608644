export const SearchIcon = () => {
    return (
        <svg
            width="23"
            height="22"
            viewBox="0 0 23 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_133_903)">
                <path
                    d="M10.16 17.82C14.8639 17.82 18.6772 14.0067 18.6772 9.30285C18.6772 4.59896 14.8639 0.785706 10.16 0.785706C5.45612 0.785706 1.64287 4.59896 1.64287 9.30285C1.64287 14.0067 5.45612 17.82 10.16 17.82Z"
                    stroke="#C4C3C6"
                    strokeWidth="1.71429"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M22.0714 21.2143L16.1786 15.3214"
                    stroke="#C4C3C6"
                    strokeWidth="1.71429"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_133_903">
                    <rect width="22" height="22" fill="white" transform="translate(0.857147)" />
                </clipPath>
            </defs>
        </svg>
    );
};
