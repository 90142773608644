import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@app/store';
import axiosRequest from '@utils/axios';

export interface Activity {
    activity: string;
    daysToFulfill: number;
    fulfillDate?: string;
    fulfillNb: number;
    fulfilledNb: number;
}

export interface Condition {
    activities: Activity[];
    fulfillUntil: string;
    fulfilledDate: string;
}

export interface Badge {
    availableFrom: string;
    availableTo: string;
    badgeId: number;
    bcId: number;
    claimedDescription: string;
    fpToAwardOnClaim: number;
    isExclusive: boolean;
    isPromoted: boolean;
    label: string;
    levels: number[];
    name: string;
    progress: {
        conditions?: Condition[];
        fulfillNb: number;
        fulfilledNb: number;
        maxFulfullUntil: string;
    };
    shortDescription: string;
    status: {
        badgeStatus: number;
        claimUntil?: string;
        claimedDate: string;
        holdUntil: string;
        startingDate: string;
        statusDate: string;
    };
}

export interface PreviousYearBadge {
    badgeId: number;
    bcId: number;
    label: string;
    name: string;
    collectionName: string;
    shortDescription: string;
    claimedDescription: string;
    ownedFrom: string;
    ownUntil: string;
}

export interface aboutBadgesState {
    status: 'idle' | 'loading' | 'success' | 'failed';
    yearBadgeCollectionStatus: 'idle' | 'loading' | 'success' | 'failed';
    badges: Badge[];
    badgesUserProfile: Badge[];
    badgesDetails: Badge[];
    badgesCollection: Badge[];
    yearBadgeCollection: PreviousYearBadge[];
    selectedBadge: Badge;
    error: null | any;
    isModalOpen: boolean;
    unclaimedBadgesCount: number;
    hasIluma: null | boolean;
}

interface AxiosError {
    response: {
        data: {
            errors: object;
            message: string;
        };
    };
}

const initialState: aboutBadgesState = {
    status: 'idle',
    yearBadgeCollectionStatus: 'idle',
    badges: [],
    badgesUserProfile: [],
    badgesDetails: [],
    badgesCollection: [],
    yearBadgeCollection: [],
    selectedBadge: {
        availableFrom: '',
        availableTo: '',
        badgeId: 0,
        bcId: 0,
        claimedDescription: '',
        fpToAwardOnClaim: 0,
        isExclusive: false,
        isPromoted: false,
        label: '',
        levels: [],
        name: '',
        progress: {
            conditions: [],
            fulfillNb: 0,
            fulfilledNb: 0,
            maxFulfullUntil: ''
        },
        shortDescription: '',
        status: {
            badgeStatus: 0,
            claimUntil: '',
            claimedDate: '',
            holdUntil: '',
            startingDate: '',
            statusDate: ''
        }
    },
    error: null,
    isModalOpen: false,
    unclaimedBadgesCount: 0,
    hasIluma: null
};

//redeem badges
export const redeemBadges = createAsyncThunk(
    'containers/redeembadges',
    async (bcId: number, { rejectWithValue }) => {
        try {
            const response = await axiosRequest.post(`badges/${bcId}/claim`);

            return response.data;
        } catch (e) {
            return rejectWithValue((e as AxiosError).response.data.message);
        }
    }
);

//getBadges
export const getBadges = createAsyncThunk('containers/aboutbadges', async (values: object) => {
    try {
        const response = await axiosRequest.get('badges', { params: values }).then((res) => {
            return res;
        });
        return response.data.data.response.content;
    } catch (e) {
        return (e as AxiosError).response.data.message;
    }
});

//getYearBadgeCollection
export const getYearBadgeCollection = createAsyncThunk(
    'containers/getYearBadgeCollection',
    async (values: { page: number; per_page: number; year: number }) => {
        try {
            const response = await axiosRequest
                .get('badges/collections', { params: values })
                .then((res) => {
                    return res;
                });

            return response.data[0].response.content;
        } catch (e) {
            return (e as AxiosError).response.data.message;
        }
    }
);

export const AboutBadgesSlice = createSlice({
    name: 'aboutBadges',
    initialState,
    reducers: {
        toggleModal: (state, action: PayloadAction<boolean>) => {
            state.isModalOpen = action.payload;
        },

        setBadge: (state, action: PayloadAction<Badge>) => {
            state.selectedBadge = action.payload;
        },

        resetError: (state) => {
            state.error = null;
        }
    },
    extraReducers: (builder) => {
        builder
            //getBadges
            .addCase(getBadges.pending, (state) => {
                state.status = 'loading';
            })

            .addCase(getBadges.fulfilled, (state, action) => {
                let badgesSorted: Badge[] = [...action.payload.badges];

                state.status = 'idle';

                state.unclaimedBadgesCount = action.payload.unclaimedBadgesCount;

                state.badgesDetails = action.payload.badges;

                state.badges = badgesSorted.sort((a: Badge, b: Badge) => {
                    if (
                        b.status.badgeStatus === 2 ||
                        new Date(a.status.statusDate) < new Date(b.status.statusDate)
                    ) {
                        return 1;
                    }

                    if (
                        a.status.badgeStatus === 2 ||
                        new Date(a.status.statusDate) > new Date(b.status.statusDate)
                    ) {
                        return -1;
                    }

                    return 0;
                });

                state.badgesCollection = action.payload.badges.filter(
                    (item: Badge) => item.status.badgeStatus !== 0
                );

                state.badgesUserProfile = action.payload.badges.sort((a: Badge, b: Badge) => {
                    if (
                        b.status.badgeStatus === 2 ||
                        new Date(a.status.claimedDate) < new Date(b.status.claimedDate)
                    ) {
                        return 1;
                    }

                    if (
                        a.status.badgeStatus === 2 ||
                        new Date(a.status.claimedDate) > new Date(b.status.claimedDate)
                    ) {
                        return -1;
                    }

                    return 0;
                });

                if (state.selectedBadge.badgeId > 0) {
                    state.selectedBadge = badgesSorted.filter(
                        (bdg: Badge) => bdg.badgeId === state.selectedBadge.badgeId
                    )[0];
                }

                state.hasIluma = action.payload.badges.some(
                    ({ label, status }: Badge) =>
                        label === 'BDG-ILUMA-CONNOISSEUR' && status.badgeStatus === 3
                );
            })
            .addCase(getBadges.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })

            //previousYearsBadges
            .addCase(getYearBadgeCollection.pending, (state) => {
                state.yearBadgeCollectionStatus = 'loading';
            })
            .addCase(getYearBadgeCollection.fulfilled, (state, action) => {
                state.yearBadgeCollectionStatus = 'idle';

                let badges = [...action.payload.badges];

                badges = badges.map((badge: any) => {
                    badge.status = {
                        badgeStatus: 3,
                        claimUntil: '',
                        claimedDate: '',
                        holdUntil: '',
                        startingDate: '',
                        statusDate: ''
                    };
                    return badge;
                });

                state.yearBadgeCollection = badges;
            })
            .addCase(getYearBadgeCollection.rejected, (state, action) => {
                state.yearBadgeCollectionStatus = 'failed';
                state.error = action.payload;
            })

            //redeemBadges
            .addCase(redeemBadges.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })

            .addCase(redeemBadges.fulfilled, (state) => {
                state.status = 'idle';
                state.error = null;
            })

            .addCase(redeemBadges.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    }
});

export const { toggleModal, setBadge, resetError } = AboutBadgesSlice.actions;

export const selectLoading = (state: RootState) => state.aboutBadges.status === 'loading';
export const selectFailed = (state: RootState) => state.aboutBadges.status === 'failed';
export const selectError = (state: RootState) => state.aboutBadges.error;

export default AboutBadgesSlice.reducer;
