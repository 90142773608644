import { useEffect } from 'react';
import styles from './RDW2024.module.scss';

// Utils
import { listBadges } from '@containers/IQOSUniverse/articles/RDW2024/data';

// Components
import ArticleHeader from '@containers/IQOSUniverse/components/ArticleHeader';
import ArticleImage from '@containers/IQOSUniverse/components/ArticleImage';
import KeenSlider from '@components/KeenSlider';

// Assets
import img1 from './assets/img1Mobile.jpg';
import img1Mobile from './assets/img1Mobile.jpg';
import img2 from './assets/img2.jpg';
import img2Mobile from './assets/img2Mobile.jpg';
import img3 from './assets/img3.jpg';
import img3Mobile from './assets/img3Mobile.jpg';
// Redux
import {
    getBadges,
    setBadge,
    toggleModal
} from '@containers/AboutBadges/pages/AboutBadges/AboutBadgesSlice';
import { useAppDispatch, useAppSelector } from '@app/hooks';

const RDW2024 = () => {
    const dispatch = useAppDispatch();

    const { badges } = useAppSelector((state) => state.aboutBadges);

    useEffect(() => {
        dispatch(
            getBadges({
                page: 1,
                per_page: 50
            })
        );
    }, []);

    const openBadge = (badgeString: string) => {
        let myBadge = badges.filter((badge: (typeof badges)[0]) => badge.label === badgeString)[0];
        dispatch(setBadge(myBadge));
        dispatch(toggleModal(true));
    };

    return (
        <section className={styles.wrapper}>
            <ArticleHeader classnameImage={styles.mobileHeight} />

            <div className="content">
                <p>
                    <strong>Între 24 mai și 2 iunie</strong>, se defăşoară a douăsprezecea ediţie a
                    Romanian Design Week, cel mai complex eveniment dedicat industriilor
                    creativ-culturale din România. Pentru al patrulea an consecutiv, IQOS susține
                    festivalul care contribuie la reconectarea bucureștenilor cu orașul în care
                    locuiesc și reafirmă potențialul industriilor creative locale.
                </p>
            </div>

            <ArticleImage mobileImage={img1Mobile} image={img1} cover />

            <div className="content">
                <p>
                    Cu tema „Unlock The City”, ediţia din 2024 a Romanian Design Week își propune să
                    investigheze metodele prin care creativitatea și inovația pot modela orașele de
                    mâine și să contribuie la explorarea Bucureștiului și la descoperirea
                    potențialului și straturilor acestuia, a spațiilor creative din oraș și a
                    organizațiilor sau proiectelor culturale care le animă și le definesc.
                </p>

                <p>
                    IQOS se alătură acestui demers prin două colaborări cu artiști români, alături
                    de care celebrează sinergia simțurilor în spiritul unei comunități reunite sub
                    semnul designului. Atfel, IQOS îşi continuă misiunea declarată de susţinere a
                    progresului şi spiritului creativ.
                </p>
            </div>

            <ArticleImage mobileImage={img2Mobile} image={img2} cover />
            <div className="content">
                <p>
                    Prima colaborare este  „SynergyX” - A decade of Enjoyment, Innovation and
                    Community, o instalație semnată de Adrian Damian. Cu ajutorul interacțiunii
                    tactile, vizitatorii pot experimenta o simfonie hipnotizantă de imagini și
                    sunete generate dinamic de contribuția lor colectivă. Instalaţia „SynergyX” este
                    o fuziune între artă, tehnologie și conexiune umană, în care tehnologiile de
                    ultimă oră se îmbină și creează o experiență senzorială și emoțională.
                </p>
            </div>
            <ArticleImage mobileImage={img3Mobile} image={img3} cover />

            <div className="content">
                <p>
                    Cunoscută pentru creații colorate şi pline de viaţă, Lana Dumitru este al doilea
                    designer și artist digital care completează experiența IQOS la Romanian Design
                    Week 2024.​ Inspirată de pasiunea comunității IQOS pentru artă şi design,
                    artista a creat o colecție în ediție limitată, special pentru Romanian Design
                    Week. Culorile vii, texturile și modelul simbolizează comuniunea cu cei din jur
                    și legăturile puternice pe care indivizii le dezvoltă în comunitate.
                </p>
            </div>

            <div className={styles.badges}>
                <div className={styles.descriptionContainer}>
                    <p className={styles.badgesTitle}>
                        Vino la RDW și ai șansa să adaugi la colecția ta badge-uri speciale.
                    </p>
                    <p className={styles.badgesSubtitle}>
                        Interacționează cu reprezentanții IQOS în cadrul evenimentului și
                        completează-ți activitățile pentru badge-urile de mai jos.
                    </p>
                </div>

                <KeenSlider withArrows={false} spacing={8}>
                    {listBadges.map((item) => {
                        return (
                            <div
                                onClick={() => openBadge(item.slug)}
                                className={styles.card}
                                key={item.id}
                            >
                                <div className={styles.cardImage}>
                                    <img src={item.image} alt={''} />
                                </div>

                                <p
                                    className={styles.title}
                                    dangerouslySetInnerHTML={{ __html: item.title }}
                                />
                            </div>
                        );
                    })}

                    <div className={styles.emptyCard}></div>
                </KeenSlider>
            </div>
        </section>
    );
};

export default RDW2024;
