// Live Music Slider Assets
import liveMusicImg1 from '../assets/liveMusicImg1.png';
import liveMusicImg2 from '../assets/liveMusicImg2.png';
import liveMusicImg3 from '../assets/liveMusicImg3.png';

// Moments Slider Assets
import momentImg1 from '../assets/momentImg1.png';
import momentImg2 from '../assets/momentImg2.png';
import momentImg3 from '../assets/momentImg3.png';

// Concert Slider Assets
import concertImg1 from '../assets/concertImg1.png';
import concertImg2 from '../assets/concertImg2.png';
import concertImg3 from '../assets/concertImg3.png';
import concertImg4 from '../assets/concertImg4.png';

export interface SecondSummerExperienceSliderInterface {
    title?: string;
    image?: string;
    titleDark: boolean;
    belowDescription?: { title?: string; subtitle?: string };
}

export const liveMusicSlider: SecondSummerExperienceSliderInterface[] = [
    {
        image: liveMusicImg1,
        titleDark: true
    },
    {
        image: liveMusicImg2,
        titleDark: true
    },
    {
        image: liveMusicImg3,
        titleDark: true
    }
];

export const momentsSlider: SecondSummerExperienceSliderInterface[] = [
    {
        belowDescription: {
            title: 'Fine dining',
            subtitle:
                'Câștigătorii au savurat adevărate opere de artă culinară pregătite de chef Alex Petricean, au explorat lumea cafelei de specialitate prezentată de un barista experimentat și s-au delectat cu cocktailuri spectaculoase, preparate special pentru ei.'
        },
        image: momentImg1,
        titleDark: true
    },
    {
        belowDescription: {
            title: 'Degustare de cafea la nivelul următor',
            subtitle:
                'Au explorat lumea cafelei de specialitate prezentată de un barista experimentat.'
        },
        image: momentImg2,
        titleDark: true
    },
    {
        belowDescription: {
            title: 'Cocktailuri personalizate',
            subtitle: 'Au savurat cocktailuri spectaculoase,  pregătite special pentru ei. '
        },
        image: momentImg3,
        titleDark: true
    }
];

export const concertSlider: SecondSummerExperienceSliderInterface[] = [
    {
        image: concertImg1,
        titleDark: true
    },
    {
        image: concertImg2,
        titleDark: true
    },
    {
        image: concertImg3,
        titleDark: true
    },
    {
        image: concertImg4,
        titleDark: true
    }
];
