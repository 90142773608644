import { RootState } from '@app/store';
import axiosRequest from '@utils/axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import store from 'store';
import { config } from '@utils/config';
import { userTierByStatus } from '@utils/userTierByStatus';
import * as Sentry from '@sentry/react';

export interface UserState {
    user: {
        id: number;
        avatars: {
            thumbnail: string;
            full: string;
        };
        fullname: string;
        first_name: string;
        last_name: string;
        telcor: Object;
        shows_birthday?: number;
        is_anonymous?: number;
    };
    status: 'idle' | 'loading' | 'failed' | 'success';
    response: {
        message: string;
    };
    error: any;
}

interface AxiosError {
    response: {
        data: {
            message: string;
        };
    };
}

const initialState: UserState = {
    user: {
        id: 0,
        fullname: '',
        first_name: '',
        last_name: '',
        avatars: {
            thumbnail: '',
            full: ''
        },
        telcor: {}
    },
    status: 'idle',
    response: {
        message: ''
    },
    error: {}
};

export const updateUserAsync = createAsyncThunk('profile/user', async () => {
    try {
        const response = await axiosRequest.get('/user');

        const userData = store.get(config.user.user);

        const newUserData = { ...response.data };

        newUserData.tier = userTierByStatus(newUserData.telcor?.userTier);
        newUserData.tutorial_session = userData.tutorial_session || 0;

        store.set(config.user.user, newUserData); // Update local storage
        store.set(config.user.profileHasEligibleDevice, userData.has_eligible_device);
        store.set(config.user.hasVeevDevice, userData.has_veev_device);

        if (newUserData.id) {
            Sentry.setUser({ id: newUserData.id });
        }

        return newUserData;
    } catch (e) {
        return (e as AxiosError).response.data.message;
    }
});

export const updateUserPrivacy = createAsyncThunk(
    'profile/user/privacy',
    async (values: { is_anonymous: number; shows_birthday: number }) => {
        try {
            const response = await axiosRequest.post('user/privacy', values);

            store.set(config.user.user, response.data.data); // Update local storage

            return response.data.data;
        } catch (e) {
            return (e as AxiosError).response.data.message;
        }
    }
);

export const seenTutorial = createAsyncThunk('profile/tutorial', async () => {
    try {
        await axiosRequest.post('user/complete-tutorial');
    } catch (e) {
        return (e as AxiosError).response.data.message;
    }
});

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(updateUserAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateUserAsync.fulfilled, (state, action) => {
                state.status = 'success';
                state.response = action.payload;
                state.user = action.payload;
            })
            .addCase(updateUserAsync.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })

            // User Privacy

            .addCase(updateUserPrivacy.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateUserPrivacy.fulfilled, (state, action) => {
                state.status = 'success';
                state.response = action.payload;
                state.user = action.payload;
            })
            .addCase(updateUserPrivacy.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })

            // Tutorial Seen

            .addCase(seenTutorial.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(seenTutorial.fulfilled, (state) => {
                state.status = 'success';
            })
            .addCase(seenTutorial.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    }
});

export const selectLoading = (state: RootState) => state.user.status === 'loading';
export const selectSuccess = (state: RootState) => state.user.status === 'success';
export const selectFailed = (state: RootState) => state.user.status === 'failed';
export const selectError = (state: RootState) => state.user.error;

export default userSlice.reducer;
