export const CheckIconTransparent = () => {
    return (
        <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6.99902 11.9199L9.49806 14.5346L15.8352 8.02393"
                stroke="#34303D"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
                stroke="#34303D"
                stroke-miterlimit="10"
                stroke-linecap="round"
            />
        </svg>
    );
};
