import { Children, FC, isValidElement } from 'react';
import { IRegionDrag } from './interface';
import styles from './RegionDrag.module.scss';

// DND Kit
import { useDroppable } from '@dnd-kit/core';

const RegionDrag: FC<IRegionDrag> = ({ region, id, children }) => {
    const { isOver, setNodeRef } = useDroppable({ id });
    const { image, imageGuessed, imageNotGuessed } = region;

    const hasChildren =
        Children.toArray(children).filter((child) => isValidElement(child)).length > 0;

    const style = {
        backgroundImage: hasChildren ? `url(${imageGuessed})` : `url(${image})`,
        outline: isOver ? '2px solid #00d1d2' : 'none'
    };

    return (
        <div ref={setNodeRef} className={styles.container}>
            <div className={styles.image} style={style}>
                {children}
            </div>
        </div>
    );
};

export default RegionDrag;
