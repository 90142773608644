export const MenuGiftIcon = ({ style }: { style: Object }) => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={style}
        >
            <path
                d="M10 6.90454V22.6535M14 6.90454V22.6535V6.90454Z"
                stroke="#34303D"
                strokeWidth="1.71429"
                strokeLinecap="round"
            />
            <path
                d="M22.3333 6.83887H1.66667C1.29848 6.83887 1 7.13266 1 7.49507V11.8812C1 12.2436 1.29848 12.5374 1.66667 12.5374H22.3333C22.7015 12.5374 23 12.2436 23 11.8812V7.49507C23 7.13266 22.7015 6.83887 22.3333 6.83887Z"
                stroke="#34303D"
                strokeWidth="1.71429"
            />
            <path
                d="M20.6827 12.6465H3.31608C2.94789 12.6465 2.64941 12.9403 2.64941 13.3027V22.3439C2.64941 22.7063 2.94789 23.0001 3.31608 23.0001H20.6827C21.0509 23.0001 21.3494 22.7063 21.3494 22.3439V13.3027C21.3494 12.9403 21.0509 12.6465 20.6827 12.6465Z"
                stroke="#34303D"
                strokeWidth="1.71429"
            />
            <path
                d="M9.05018 1.15228C8.72885 0.990854 8.39352 0.958044 8.04352 1.05254C7.61352 1.16869 7.17352 1.45348 6.71018 1.88198C6.15085 2.39907 5.84552 2.88729 5.79818 3.35845C5.75018 3.83354 5.98552 4.25614 6.35685 4.62755L6.48218 4.74566C7.60352 5.76016 9.71218 6.38421 11.7122 6.42949L11.9322 6.43146L11.9655 6.58698C11.8455 5.97736 11.6269 5.28113 11.3209 4.50024C10.6289 2.73308 9.90352 1.58144 9.05018 1.15228Z"
                stroke="#34303D"
                strokeWidth="1.71429"
            />
            <path
                d="M15.7842 1.05319C15.4295 0.9587 15.0915 0.990198 14.7709 1.15228C13.9249 1.57947 13.2235 2.72914 12.5689 4.48974L12.4742 4.74895C12.2622 5.34609 12.1029 5.89009 12.0042 6.38027L11.9942 6.4308L11.9422 6.43146C14.0915 6.43146 16.4322 5.74835 17.5555 4.62033C17.9215 4.2522 18.1529 3.83354 18.0982 3.36238C18.0435 2.89188 17.7268 2.40432 17.1535 1.88789C16.6748 1.45676 16.2229 1.17 15.7842 1.05319Z"
                stroke="#34303D"
                strokeWidth="1.71429"
            />
        </svg>
    );
};
