import { IButtonProps } from '@components/Layout/Button/interface';
import styles from '@components/Layout/Button/Button.module.scss';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

const BaseLink = (props: IButtonProps) => {
    const {
        type = 'solid',
        color = 'dark',
        size = 'normal',
        disabled = false,
        fullwidth = false,
        noStyle = false,
        children,
        href,
        label,
        hover,
        className,
        icon,
        loading,
        onTrack
    } = props;

    return href ? (
        <Link
            to={href}
            type="button"
            onClick={onTrack}
            className={classnames(
                styles.button,
                styles[type],
                styles[size],
                styles[color],
                styles[`${hover}--hover`],
                fullwidth && styles.fullwidth,
                disabled ? styles.disabled : '',
                loading ? styles.loading : '',
                noStyle ? styles.noStyle : '',
                className
            )}
        >
            {children ? (
                children
            ) : (
                <>
                    {label && <span dangerouslySetInnerHTML={{ __html: label }} />}
                    {icon}
                </>
            )}
        </Link>
    ) : null;
};

export default BaseLink;
