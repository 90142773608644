export const ExtraChances = () => {
    return (
        <svg
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M17 33C25.8366 33 33 25.8366 33 17C33 8.16344 25.8366 1 17 1C8.16344 1 1 8.16344 1 17C1 25.8366 8.16344 33 17 33Z"
                stroke="#34303D"
            />
            <path
                d="M17 3.23438C20.6494 3.23438 24.1493 4.68409 26.7298 7.26459C29.3103 9.84509 30.76 13.345 30.76 16.9944C30.76 20.6438 29.3103 24.1437 26.7298 26.7242C24.1493 29.3047 20.6494 30.7544 17 30.7544V3.23438Z"
                fill="#00D1D2"
            />
            <path
                d="M17.3865 8.27026L10.7734 16.9085L14.9066 16.9085L14.9066 26.0703L19.8664 26.0703L19.8664 16.9085L23.9995 16.9085L17.3865 8.27026Z"
                fill="#FFFDFB"
                stroke="#0C0B0E"
                strokeWidth="0.936845"
                strokeMiterlimit="10"
                strokeLinecap="square"
            />
        </svg>
    );
};
