import { useState } from 'react';
import styles from './UpgradeHiro.module.scss';

// Assets
import img1 from './assets/hiroVideo.png';

// Components
import VideoModal from '@components/VideoModal';
import ArticleImage from '@containers/IQOSUniverse/components/ArticleImage';
import ArticleHeader from '@containers/IQOSUniverse/components/ArticleHeader';
import ArticleContainer from '@containers/IQOSUniverse/components/ArticleContainer';

const UpgradeHiro = () => {
    const [toggleModal, setToggleModal] = useState(false);

    return (
        <section className={styles.wrapper}>
            <ArticleHeader />

            <div className="content">
                <h3>Vara e o stare de spirit. Pe care o trăim zilnic.</h3>

                <p>
                    Orice zi din acest anotimp are un farmec aparte. Fie că ești în vacanță, în oraș
                    sau acasă; vara simți totul mai intens. Te încarci cu energie, indiferent de ce
                    alegi să faci: seri la terasă, petreceri, întâlniri cu cei dragi sau chiar
                    reprize de relaxare doar pentru tine.
                </p>
                <p>
                    Inspirați de tot ce face din vară cea mai frumoasă perioadă a anului, am
                    petrecut la Hiro în București o seară plină de distracție.
                </p>
            </div>

            <ArticleContainer background={'#34303D'}>
                <div className="content">
                    <ArticleImage isVideo image={img1} onClick={() => setToggleModal(true)} />
                </div>
            </ArticleContainer>

            <VideoModal
                toggleModal={toggleModal}
                setToggleModal={() => setToggleModal(false)}
                video="736095715"
            />
        </section>
    );
};

export default UpgradeHiro;
