import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@app/store';

import { notification } from 'antd';
import axiosRequest from '@utils/axios';

export interface Option {
    id: number;
    packs: number;
}

export interface ActiveCode {
    canBeRefunded: number;
    code: string;
    expirationDate: string;
    prizeType: 'string';
    quantity: number;
    refundSplitOfPoints: { refund: number; expire: number };
    refundedDate: any;
    status: string;
}

export interface InactiveCode {
    canBeRefunded: number;
    code: string;
    expirationDate: string;
    prizeType: 'string';
    quantity: number;
    refundedDate: any;
    status: string;
}

export interface RefundedCode {
    canBeRefunded: number;
    code: string;
    expirationDate: string;
    prizeType: string;
    quantity: number;
    refundedDate: string;
    refundedPoints: { expired: string; refunded: string };
    status: string;
}
export interface offlineRedemptionState {
    status: 'idle' | 'loading' | 'success' | 'failed';
    codesData: {
        availablePacks: number;
        availablePoints: number | null;
        claimedCodes: InactiveCode[];
        expiredCodes: InactiveCode[];
        refundedCodes: RefundedCode[];
        unclaimedCodes: ActiveCode[];
    };
    options: Option[];
    error: any;
}

interface AxiosError {
    response: {
        data: {
            errors: object;
            message: string;
        };
    };
}

const initialState: offlineRedemptionState = {
    status: 'idle',
    codesData: {
        availablePacks: 0,
        availablePoints: null,
        claimedCodes: [],
        expiredCodes: [],
        refundedCodes: [],
        unclaimedCodes: []
    },
    options: [],
    error: {}
};

export const newCode = createAsyncThunk(
    'containers/offlineRedemptionNewCode',
    async (values: object, { rejectWithValue }) => {
        try {
            const response = await axiosRequest.post('user/offline-redemptions/new-code', values);

            notification.success({
                message: 'Felicitări!',
                description: 'Codul tău de revendicare a fost generat cu succes.'
            });

            return response.data.data;
        } catch (e: any) {
            notification.error({
                message: 'Eroare!',
                description: e.response.data.message
            });

            return rejectWithValue((e as AxiosError).response.data.message);
        }
    }
);

export const getCodes = createAsyncThunk('containers/offlineRedemptionGetCodes', async () => {
    try {
        const response = await axiosRequest.get('user/offline-redemptions');
        return response.data.content;
    } catch (e) {
        return (e as AxiosError).response.data.message;
    }
});

export const refundCode = createAsyncThunk(
    'containers/offlineRedemptionRefundCode',
    async (values: object, { rejectWithValue }) => {
        try {
            const response = await axiosRequest.post('user/offline-redemptions/refund', values);
            return response.data.content;
        } catch (e) {
            return rejectWithValue((e as AxiosError).response.data.message);
        }
    }
);

export const offlineRedemption = createSlice({
    name: 'offlineRedemption',
    initialState,
    reducers: {
        generateOptions: (state, action: PayloadAction<number>) => {
            state.options = Array.from({
                length: action.payload <= 10 ? action.payload : 10
            }).map((i, key) => {
                return { id: key + 1, packs: key + 1 };
            });
        }
    },

    extraReducers: (builder) => {
        builder

            //generateCode
            .addCase(newCode.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(newCode.fulfilled, (state) => {
                state.status = 'success';
            })
            .addCase(newCode.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })

            //getCodes
            .addCase(getCodes.pending, (state) => {
                state.status = 'loading';
            })

            .addCase(getCodes.fulfilled, (state, action) => {
                state.status = 'success';
                if (action.payload !== 'Nu a fost găsit un user conform id-ului de user') {
                    state.codesData = action.payload;
                }
            })

            .addCase(getCodes.rejected, (state) => {
                state.status = 'failed';
                state.codesData = initialState.codesData;
            })

            // refundCode
            .addCase(refundCode.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(refundCode.fulfilled, (state) => {
                state.status = 'success';
            })
            .addCase(refundCode.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    }
});

export const { generateOptions } = offlineRedemption.actions;
export const selectLoading = (state: RootState) => state.offlineRedemption.status === 'loading';
export const selectSuccess = (state: RootState) => state.offlineRedemption.status === 'success';
export const selectFailed = (state: RootState) => state.offlineRedemption.status === 'failed';

export default offlineRedemption.reducer;
