const transformAnswers = (values: any) => {
    let answers = [];
    const withInputKeys = Object.keys(values['withInput'] || {});

    for (const key in values) {
        if (key === 'selectFields') {
            const questionId = parseInt(Object.keys(values.selectFields)[0]);
            answers.push({
                quiz_question_id: questionId,
                values: values.selectFields[questionId].map((item: any) => {
                    return { value: item !== undefined ? item.toString() : '' };
                })
            });
        } else if (key === 'withInput') {
            for (const field in values[key]) {
                answers.push({
                    quiz_question_id: parseInt(field),
                    values: [
                        {
                            value: values[field],
                            custom_value: values['withInput'][field]
                        }
                    ]
                });
            }
        } else {
            if (!withInputKeys.includes(key)) {
                answers.push({
                    quiz_question_id: parseInt(key),
                    values: [{ value: values[key] !== undefined ? values[key].toString() : '' }]
                });
            }
        }
    }

    return answers;
};

export default transformAnswers;
