export const UniverseIcon = () => {
    return (
        <svg
            width="22"
            height="20"
            viewBox="0 0 22 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10.4536 16.1063C10.6306 16.016 10.8431 16.016 11.0201 16.1063L16.7613 19.045L15.6672 12.8347C15.6318 12.6346 15.6991 12.4311 15.8478 12.2912L20.4737 7.90076L14.0722 6.99435C13.8721 6.9678 13.7004 6.84388 13.6083 6.66507L10.7368 1L7.86536 6.66507C7.77507 6.84388 7.60158 6.96603 7.40153 6.99435L1 7.90076L5.62589 12.2912C5.77459 12.4328 5.84187 12.6346 5.80646 12.8347L4.71239 19.045L10.4536 16.1063V16.1063Z"
                stroke="#C4C3C6"
                strokeWidth="1.77033"
                strokeLinejoin="round"
            />
        </svg>
    );
};
