export const PWAContestIcon = () => {
    return (
        <svg
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_8916_9380)">
                <path
                    d="M39.4522 33.6205L44.9758 43.1876L38.6736 42.3496L36.2493 48.2259L29.8085 37.2848"
                    stroke="white"
                    strokeWidth="2.33333"
                    strokeLinejoin="round"
                />
                <path
                    d="M16.7799 33.6195L11.2563 43.1866L17.5584 42.3486L19.9838 48.2255L26.6518 37.5139"
                    stroke="white"
                    strokeWidth="2.33333"
                    strokeLinejoin="round"
                />
                <path
                    d="M27.9995 37.8978C36.2095 37.8978 42.8651 31.2423 42.8651 23.0322C42.8651 14.8221 36.2095 8.1665 27.9995 8.1665C19.7894 8.1665 13.1338 14.8221 13.1338 23.0322C13.1338 31.2423 19.7894 37.8978 27.9995 37.8978Z"
                    stroke="white"
                    strokeWidth="2.33333"
                />
                <path
                    d="M28.1137 33.8822C34.2484 33.8822 39.2215 28.909 39.2215 22.7743C39.2215 16.6396 34.2484 11.6665 28.1137 11.6665C21.979 11.6665 17.0059 16.6396 17.0059 22.7743C17.0059 28.909 21.979 33.8822 28.1137 33.8822Z"
                    fill="#00D1D2"
                />
                <path
                    d="M21.5859 20.8564H34.4123M24.7919 25.4368H31.2051H24.7919Z"
                    stroke="#34303D"
                    strokeWidth="2.33333"
                    strokeLinecap="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_8916_9380">
                    <rect width="56" height="56" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
