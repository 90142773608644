export const DevicesIcon = () => {
    return (
        <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.8084 21.0001L4.99987 5.18315L8.51653 4.46702C8.90747 4.48657 9.26109 4.65361 9.52053 4.91482C9.79952 5.19203 9.97011 5.57586 9.97011 5.99879L9.97011 18.7398C9.97011 19.5696 10.178 20.3249 10.6045 21.0001H5.80663H5.8084Z"
                stroke="#C4C3C6"
                strokeWidth="1.7"
                strokeLinejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.1027 21.0001C11.6105 21.0001 11.1662 20.8011 10.8464 20.4795C10.5247 20.1596 10.3257 19.7154 10.3257 19.2231L10.3257 1.76956L15.6353 1.00012C16.3461 1.01611 16.9912 1.31109 17.4603 1.78022C17.9419 2.26178 18.2422 2.92815 18.2422 3.66383L18.2422 20.1116L12.1027 21.0001Z"
                stroke="#C4C3C6"
                strokeWidth="1.7"
                strokeLinejoin="round"
            />
        </svg>
    );
};
