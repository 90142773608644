import styles from './FestivalDuBonheureSecond.module.scss';
import React, { useState } from 'react';

// Components
import ArticleHeader from '@containers/IQOSUniverse/components/ArticleHeader';
import VideoModalQuiz from '@containers/IQOSUniverse/components/VideoModalQuiz';
import CompletedStateArticle from '@containers/IQOSUniverse/components/CompletedStateArticle/CompletedStateArticle';
import MediaGallery from '@containers/IQOSUniverse/components/MediaGallery';

// Assets
import img3 from './assets/gallery3.jpg';
import img5 from './assets/gallery5.jpg';
import img8 from './assets/gallery8.jpg';
import img10 from './assets/gallery10.jpg';
import img12 from './assets/gallery12.jpg';
import img15 from './assets/gallery15.jpg';
import img17 from './assets/gallery17.jpg';

// Redux
import { useAppSelector } from '@app/hooks';

const FestivalDuBonheureSecond = () => {
    const { article } = useAppSelector((state) => state.iqosUniverse);

    const [toggleModal, setToggleModal] = useState(false);

    const [showSuccess, setShowSuccess] = useState(false);

    return (
        <section className={styles.wrapper}>
            <ArticleHeader
                videoQuiz={() => {
                    setToggleModal(true);
                }}
            />

            <VideoModalQuiz
                customClass={styles.customClassFestivalModal}
                customClassVideo={styles.customClassVideo}
                videoResponsive={false}
                toggleModal={toggleModal}
                setToggleModal={() => setToggleModal(false)}
                setShowSuccess={() => {
                    setShowSuccess(true);
                }}
            />
            <div className="content">
                <p>
                    Noi am făcut check-in-ul la Festival du Bonheure din Mamaia, acolo unde am dat
                    startul distracției, împreună cu cea mai cool comunitate.
                </p>
                <p>
                    Pe timp de zi ne-am relaxat și ne-am bucurat de preparate culinare, iar seara am
                    făcut teasing verii cu o serie de petreceri memorabile.
                </p>
            </div>

            <MediaGallery
                darkArrows={true}
                sliderClassName={styles.slider}
                images={[img3, img5, img8, img10, img12, img15, img17]}
            />

            {(showSuccess || article.completed_quiz) && (
                <CompletedStateArticle
                    className={styles.completedStateArticle}
                    article={{
                        points: article.points,
                        quiz_points: article.quiz_points,
                        quiz_end_date: article.quiz_end_date
                    }}
                />
            )}
        </section>
    );
};

export default FestivalDuBonheureSecond;
