import styles from './HeetsRubyFuse.module.scss';

// Components
import ArticleHeader from '@containers/IQOSUniverse/components/ArticleHeader';

const HeetsRubyFuse = () => {
    return (
        <section className={styles.wrapper}>
            <ArticleHeader />

            <div className="content">
                <h3>Împreună ducem povestea mai departe.</h3>

                <p>
                    Povestea noastră continuă. Cu fiecare pas înainte, ne îndreptăm către noi
                    experiențe care ne fac mai buni. Progresăm împreună.
                </p>

                <p>
                    De aceea deschidem pentru tine un nou capitol plin de savoare cu HEETS Ruby Fuse
                    – primul sortiment HEETS aromatic fără mentol. Descoperă un amestec de tutun
                    plăcut aromat cu note de fructe roșii de pădure.
                </p>

                <p>Începe un nou capitol plin de savoare, alături de HEETS Ruby Fuse.</p>
            </div>
        </section>
    );
};

export default HeetsRubyFuse;
