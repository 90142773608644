// Assets
import img1 from './assets/slider/slide1.jpg';
import img2 from './assets/slider/slide2.jpg';
import img3 from './assets/slider/slide3.jpg';
import img4 from './assets/slider/slide4.jpg';
import img5 from './assets/slider/slide5.jpg';
import img6 from './assets/slider/slide6.jpg';
import img7 from './assets/slider/slide7.jpg';
import img9 from './assets/slider/slide9.jpg';

export const sliderData = [
    {
        id: 1,
        image: img1
    },
    {
        id: 2,
        image: img2
    },
    {
        id: 3,
        image: img3
    },
    {
        id: 4,
        image: img4
    },
    {
        id: 5,
        image: img5
    },
    {
        id: 6,
        image: img6
    },
    {
        id: 7,
        image: img7
    },

    {
        id: 8,
        image: img9
    }
];
