// Assets
import header1 from './assets/puzzleHeader1.svg';
import header2 from './assets/puzzleHeader2.svg';
import header3 from './assets/puzzleHeader3.svg';

import puzzle1 from './assets/puzzleimgs/puzzle1.jpg';
import puzzle1m from './assets/puzzleimgs/puzzle1Mobile.jpg';

import puzzle2 from './assets/puzzleimgs/puzzle2.jpg';
import puzzle2m from './assets/puzzleimgs/puzzle2Mobile.jpg';

import puzzle3 from './assets/puzzleimgs/puzzle3.jpg';
import puzzle3m from './assets/puzzleimgs/puzzle3Mobile.jpg';

export const gameData = [
    {
        id: 1,
        slug: 'iqos-category-motivation-game-1-puzzle-game',
        header: header1,
        puzzleImg: puzzle1,
        puzzleImgMobile: puzzle1m,
        description:
            'Mută piesele de puzzle și <strong>recompune în maxim 90 secunde imaginea</strong> care îți dezvăluie beneficiile tutunului încălzit.',
        failText: '',
        successTitle: 'Felicitări!',
        successText: `Ai rezolvat puzzle-ul și ai descoperit beneficiile tutunului încălzit.
                      <br/> <br/>
                      Descoperă mai multe despre beneficiile utilizării IQOS în comparație cu țigările convenționale pe iqos.ro.
                      <br/> <br/>
                      Adaugă o notă specială legăturii tale cu cei dragi. <a rel="noopener noreferrer" href="/club/recomandari">Recomandă experiența</a> IQOS celor interesați și, dacă achiziționează un dispozitiv, vei avea parte de beneficii speciale.
                      `
    },
    {
        id: 2,
        slug: 'iqos-category-motivation-game-2-puzzle-game',
        header: header2,
        puzzleImg: puzzle2,
        puzzleImgMobile: puzzle2m,
        description:
            'Mută piesele de puzzle și <strong>recompune în maxim 90 secunde imaginea</strong> care îți dezvăluie beneficiile tutunului încălzit.',
        failText: '',
        successTitle: 'Bravo!',
        successText: `Ai descoperit cu succes unul dintre beneficiile IQOS.
                      <br/> <br/>
                      Spune-le și celor dragi despre asta! <a rel="noopener noreferrer" href="/club/recomandari">Recomandă </a> experiența IQOS celor interesați, iar, dacă achiziționează un dispozitiv, vei avea parte de beneficii speciale.`
    },
    {
        id: 3,
        slug: 'iqos-category-motivation-game-3-puzzle-game',
        header: header3,
        puzzleImg: puzzle3,
        puzzleImgMobile: puzzle3m,
        description:
            'Mută piesele de puzzle și <strong>recompune în maxim 90 secunde imaginea</strong> care îți dezvăluie beneficiile tutunului încălzit.',
        failText: 'Nu ai reușit să recompui imaginea.',
        successTitle: 'Felicitări!',
        successText: `Ai descoperit cu succes un beneficiu al tutunului încălzit, comparativ cu cel ars.
                      <br/> <br/>
                    Spune-le și celor dragi despre asta! <a rel="noopener noreferrer" href="/club/recomandari">Recomandă </a> experiența IQOS celor interesați, iar, dacă achiziționează un dispozitiv, vei avea parte de beneficii speciale.`
    }
];
