export const ForwardArrow = ({ color = '#34303D' }: { color?: string }) => {
    return (
        <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0.857147 12.1818H23.1429"
                stroke={color}
                strokeWidth="1.71429"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17.1429 18.1818L23.1429 12.1818L17.1429 6.18182"
                stroke={color}
                strokeWidth="1.71429"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
