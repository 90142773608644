import { RootState } from '@app/store';
import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosRequest from '@utils/axios';
import { shuffle } from 'lodash';

export interface InitialState {
    status: 'idle' | 'success' | 'loading' | 'failed';
    maxExperiences: 3;
    gameID: number;
    played?: boolean;
    experiences: string[];
    activeExperiences: string[];
    topExperiences: Array<{
        value: string;
        votes: number;
    }>;
    chances: number;
    voted: boolean;
    error: any;
}

interface AxiosError {
    response: {
        data: {
            message: string;
        };
    };
}

const initialState: InitialState = {
    status: 'idle',
    maxExperiences: 3,
    gameID: 0,
    experiences: [],
    activeExperiences: [],
    topExperiences: [],
    chances: 0,
    voted: false,
    error: {}
};

export const getExperiences = createAsyncThunk('experiences/getExperiences', async () => {
    try {
        const response = await axiosRequest.get('/real-people-second-phase/current-game');
        return response.data;
    } catch (e) {
        return (e as AxiosError).response.data;
    }
});

export const setExperiences = createAsyncThunk(
    'experiences/setExperiences',
    async (values: string[], { rejectWithValue, getState }) => {
        try {
            const { callForDreamsPhaseTwo } = getState() as RootState;
            const response = await axiosRequest.post('/real-people-second-phase/store', {
                game_id: callForDreamsPhaseTwo.gameID,
                votes: values
            });
            return response.data;
        } catch (e) {
            return rejectWithValue((e as AxiosError).response.data);
        }
    }
);

export const getTopExperiences = createAsyncThunk('experiences/getTopExperiences', async () => {
    try {
        const response = await axiosRequest.get('/real-people-second-phase/votes');
        return response.data;
    } catch (e) {
        return (e as AxiosError).response.data;
    }
});

export const getChances = createAsyncThunk('experiences/getChances', async () => {
    try {
        const response = await axiosRequest.get('/real-people-second-phase/chances');
        return response.data;
    } catch (e) {
        return (e as AxiosError).response.data;
    }
});

export const experiencesSlice = createSlice({
    name: 'experiences',
    initialState,
    reducers: {
        setActiveExperiences: (state, action) => {
            const selectedExperience = action.payload;
            const activeExperiences = [...state.activeExperiences];

            const index = activeExperiences.indexOf(selectedExperience);
            if (index !== -1) {
                // If the item exists, remove it
                activeExperiences.splice(index, 1);
                state.activeExperiences = activeExperiences;
            } else {
                // If the item doesn't exist and we're below limit, push it to the array
                if (activeExperiences.length < state.maxExperiences) {
                    state.activeExperiences = [...activeExperiences, selectedExperience];
                }
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getExperiences.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getExperiences.fulfilled, (state, action) => {
                state.status = 'idle';
                state.gameID = action.payload.id;
                state.played = action.payload.played;
                state.experiences = shuffle(action.payload.properties);
            })
            .addCase(getExperiences.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })

            .addCase(setExperiences.pending, (state) => {
                state.status = 'loading';
                state.voted = false;
            })
            .addCase(setExperiences.fulfilled, (state) => {
                state.status = 'idle';
                state.voted = true;
            })
            .addCase(setExperiences.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
                state.voted = false;
            })

            .addCase(getTopExperiences.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getTopExperiences.fulfilled, (state, action) => {
                state.status = 'success';
                state.topExperiences = action.payload;
            })
            .addCase(getTopExperiences.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })

            .addCase(getChances.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getChances.fulfilled, (state, action) => {
                state.status = 'idle';
                state.chances = action.payload.chances;
            })
            .addCase(getChances.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })

            .addCase(resetState, () => initialState);
    }
});

export const resetState = createAction('REVERT_ALL');

export const { setActiveExperiences } = experiencesSlice.actions;

export default experiencesSlice.reducer;
