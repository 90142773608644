export const Stars = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="36"
            height="40"
            viewBox="0 0 36 40"
            fill="none"
        >
            <path
                d="M14.7883 10.1807C15.4063 8.86395 17.2791 8.86389 17.8972 10.1806L21.0046 16.7994C21.248 17.318 21.734 17.681 22.3003 17.7673L29.383 18.8467C30.765 19.0574 31.3297 20.7429 30.3534 21.7436L25.1214 27.1061C24.7408 27.4962 24.568 28.0439 24.6557 28.5818L25.8762 36.0634C26.107 37.4782 24.6051 38.5363 23.3505 37.8427L17.175 34.4286C16.658 34.1428 16.0303 34.1428 15.5133 34.4286L9.33776 37.8427C8.0832 38.5363 6.5813 37.4782 6.81209 36.0634L8.03254 28.5818C8.12029 28.0439 7.94747 27.4962 7.56685 27.1061L2.33374 21.7424C1.35768 20.742 1.92185 19.0568 3.30351 18.8457L10.3884 17.7632C10.9545 17.6767 11.4402 17.3137 11.6835 16.7953L14.7883 10.1807Z"
                fill="#FFD657"
                stroke="#F6F4F0"
                stroke-width="1.71723"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M27.5715 1.55946C27.8626 0.939392 28.7445 0.939364 29.0356 1.55941L30.4989 4.67637C30.6135 4.92056 30.8424 5.09151 31.1091 5.13215L34.4444 5.64047C35.0953 5.73966 35.3612 6.53343 34.9014 7.00465L32.4376 9.52998C32.2583 9.71369 32.1769 9.97158 32.2183 10.2249L32.793 13.7481C32.9017 14.4144 32.1944 14.9127 31.6036 14.5861L28.6954 12.9783C28.452 12.8437 28.1564 12.8437 27.9129 12.9783L25.0047 14.5861C24.414 14.9127 23.7067 14.4144 23.8154 13.7481L24.3901 10.2249C24.4314 9.97159 24.35 9.71369 24.1708 9.52998L21.7064 7.00412C21.2468 6.533 21.5125 5.73941 22.1631 5.63999L25.4995 5.13021C25.7661 5.08947 25.9948 4.91854 26.1094 4.67442L27.5715 1.55946Z"
                fill="#FFFDFB"
                stroke="#FFD657"
                stroke-width="0.808675"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};
