export const MegaSupporter = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="62"
            height="66"
            viewBox="0 0 62 66"
            fill="none"
        >
            <path
                d="M47.1394 17.2836L59.2069 36.6222C65.2674 46.3337 57.691 58.4745 45.5669 58.4745H21.432C9.30792 58.4745 1.73155 46.3337 7.79201 36.6222L19.8626 17.2836C25.9231 7.57213 41.079 7.57213 47.1426 17.2836"
                fill="#FFD657"
            />
            <path
                d="M1.75568 41.9048C1.75568 41.9048 6.86205 40.2945 8.87826 39.3889C10.8945 38.4833 19.2215 33.2038 22.3612 32.6499C25.5009 32.0959 35.0652 35.3103 36.5934 35.7215C38.1255 36.131 38.8239 38.0308 38.4796 39.2011C38.1354 40.3713 37.4081 42.21 33.3892 41.1003C29.3702 39.9906 25.6201 39.4797 24.9117 39.3424C24.2034 39.2052 23.3697 39.6206 23.1824 40.4608C22.995 41.301 23.4974 42.5919 24.754 42.7836C26.0105 42.9752 38.7033 45.6817 39.4079 45.8207C40.1125 45.9597 43.0186 44.6726 47.0447 43.1011C51.0709 41.5296 53.646 40.6007 54.4632 40.3111C55.2805 40.0214 56.5107 40.4891 57.1323 41.9542C57.7539 43.4194 57.2431 44.3912 55.2371 45.4106C53.231 46.43 42.6349 51.4944 41.561 52.0542C40.4853 52.6102 35.0104 52.3047 32.05 52.1223C29.088 51.9361 20.9106 51.5965 18.9223 50.7088C16.9341 49.821 15.2645 49.8149 13.4281 50.8674C11.5917 51.9199 7.39003 54.4493 7.39003 54.4493"
                stroke="#34303D"
                stroke-width="1.54613"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M44.7378 36.1881C44.7378 36.1881 44.152 34.7115 42.2977 35.3895C40.4434 36.0675 38.4385 36.7448 38.4385 36.7448"
                stroke="#34303D"
                stroke-width="1.54613"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M49.9405 38.3216C49.9405 38.3216 49.4903 37.6539 47.8711 38.2673C46.252 38.8807 41.2965 40.5736 41.2965 40.5736"
                stroke="#34303D"
                stroke-width="1.54613"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M33.202 8.27539L36.643 15.605L44.3366 16.7775L38.7698 22.483L40.0841 30.5396L33.203 26.7356L26.3219 30.5396L27.6362 22.483L22.0693 16.7775L29.7629 15.6019L33.202 8.27539Z"
                fill="#FFFDFB"
                stroke="#34303D"
                stroke-width="1.54613"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M24.5427 4.49316L25.594 6.73241L27.9444 7.09062L26.2437 8.83373L26.6453 11.2951L24.543 10.1329L22.4408 11.2951L22.8423 8.83373L21.1416 7.09062L23.4921 6.73149L24.5427 4.49316Z"
                fill="#FFFDFB"
                stroke="#34303D"
                stroke-width="0.773064"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M42.4784 1L44.4857 5.27559L48.9736 5.95954L45.7263 9.2878L46.493 13.9875L42.479 11.7684L38.465 13.9875L39.2317 9.2878L35.9844 5.95954L40.4723 5.27383L42.4784 1Z"
                fill="#FFFDFB"
                stroke="#34303D"
                stroke-width="0.927677"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M52.5273 11.1171V10.1895"
                stroke="#34303D"
                stroke-width="1.54613"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M50.824 12.8174H49.8994"
                stroke="#34303D"
                stroke-width="1.54613"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M55.1531 12.8174H54.2285"
                stroke="#34303D"
                stroke-width="1.54613"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M52.5273 15.4472V14.5195"
                stroke="#34303D"
                stroke-width="1.54613"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M60.3232 10.0596V10.0629"
                stroke="#34303D"
                stroke-width="1.54613"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M58.3564 18.0488V18.0522"
                stroke="#34303D"
                stroke-width="1.54613"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M16.3477 10.9872V10.0596"
                stroke="#34303D"
                stroke-width="1.54613"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M14.6443 12.6885H13.7197"
                stroke="#34303D"
                stroke-width="1.54613"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M18.9734 12.6885H18.0488"
                stroke="#34303D"
                stroke-width="1.54613"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M16.3477 15.3173V14.3896"
                stroke="#34303D"
                stroke-width="1.54613"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};
