const dotFormat = (num: number | string): string => {
    // Create a new Intl.NumberFormat object with options for formatting
    const formatter = new Intl.NumberFormat('ro');

    // Convert input to a number if it's a string
    const inputNum = typeof num === 'string' ? Number(num) : num;

    // Return the formatted number as a string
    return formatter.format(inputNum);
};

export default dotFormat;
