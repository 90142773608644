import styles from './RealConnectionGames.module.scss';
import { useEffect, useState } from 'react';
import useToggle from '@utils/hooks/useToggle';
import { useNavigate, useParams } from 'react-router-dom';

// Helpers
import axios from '@utils/axios';
import { config } from '@utils/config';
import setHeaderTitle from '@utils/setHeaderTitle';
import dayjs from '@utils/dayjs';
import * as Sentry from '@sentry/react';

// Components
import Games from './gamesConfig';
import CardWrapper from './components/CardWrapper';
import Button from '@components/Layout/Button';
import BaseLoader from '@components/Layout/BaseLoader';
import IntroCard from './components/IntroCard';

// Assets
import headerGif from './assets/cardGif.gif';
import { GiftIcon, ChancesIcon, ForwardPointsIcon, VoucherIcon } from './icons';
import useDateParam from '@utils/hooks/useDateParam';
import FinalAdvice from '@containers/Campaigns/RealPeopleRealConnections/Components/RealConnectionsGames/components/FinalAdvice';

const RealConnectionGames = () => {
    const navigate = useNavigate();
    const { slugGame, gameIdx } = useParams();
    const goBack = () => navigate(config.path.campaigns.realPeopleRealConnectionsCampaignPage);

    const [currentGame, setCurrentGame] = useState<any>();
    const [gameID, setGameID] = useState(0);
    const [gameStarted, toggleStartGame] = useToggle();
    const [remainingChances, setRemainingChances] = useState<number>(3);
    const [noChances, toggleNoChances] = useToggle();
    const [winned, setWinned] = useState<'chance' | 'prize' | false>();

    const [loading, setLoading] = useState(true);
    const [rewardHash, setRewardHash] = useState();
    const [isAccessory, setIsAccessory] = useState(false);
    const [accessoryId, setAccessoryId] = useState();

    const [chancesAwarded, setChancesAwarded] = useState(0);
    const [fpAwarded, setFpAwarded] = useState(0);

    useEffect(() => {
        // console.log('slugGame', slugGame);
        // let games = { ...Games(1) };
        // if (slugGame && slugGame in games) {
        // setCurrentGame(games['real-connection-memory-game']);
        // setLoading(false);
        // setWinned('chance');

        //     console.log('requestDate', requestDate);
        // } else {
        axios
            .get(`/real-connection/current/game`)
            .then(({ data }) => {
                const { id, end_date, lost_games, slug, week } = data;
                const now = dayjs(dayjs(), 'YYYY-MM-DD HH:mm:ss');

                if (now.isAfter(end_date)) {
                    goBack();
                } else if (lost_games >= 3) {
                    goBack();
                }
                let games = { ...Games(week - 1) }; //! CHANGE this to ...Games(week -1) to get index
                setRemainingChances(3 - lost_games);
                setCurrentGame(games[slug as keyof typeof games]);
                setGameID(id);
                setLoading(false);
            })
            .catch((err) => {
                console.error(err);
                goBack();
            });
    }, []);
    // For Testing
    // const gameConfiguration = (slug: string) => {
    //     let game = { ...Games[slug] };
    //     if (gameIdx) {
    //         if (slug === 'hangman') {
    //             game.gameConfig = reverseHangmanGames[Number(gameIdx)];
    //         } else if (slug === 'video-quiz') {
    //             game.gameConfig = quizGamesVideo[Number(gameIdx)];
    //         } else if (slug === 'puzzle') {
    //             game.gameConfig = {
    //                 image: puzzleGames[Number(gameIdx)],
    //                 time: 2 * 30 * 1000,
    //                 rowsAndColumns: [3, 3],
    //                 onWin: () => {},
    //                 onLose: () => {}
    //             };
    //         }
    //     }
    //
    //     return game;
    // };

    // We substract -1 from remaining chances after the user plays. If it goes to zero, toggle the card with no chances copy.
    useEffect(() => {
        if (remainingChances === 0) {
            toggleNoChances();
        }
    }, [remainingChances]);

    useEffect(() => {
        if (currentGame) {
            setHeaderTitle(currentGame.title);
        }
    }, [currentGame]);

    const onFinishGame = (hasWinned: boolean) => {
        setTimeout(() => {
            setLoading(true);

            // if (slugGame) {
            // if (hasWinned) {
            //     setWinned('prize');
            // } else {
            //     setRemainingChances((prevChances) => prevChances! - 1);
            //     setWinned(false);
            // }
            // setLoading(false);
            // // } else {
            axios
                .post(`/real-connection/store/game`, {
                    game_id: gameID,
                    winner: hasWinned ? 1 : 0
                })
                .then(({ data }) => {
                    const { has_won_game, lost_games, telcor_response } = data;

                    if (telcor_response?.content?.instantPrize?.won === 1) {
                        setWinned('prize');

                        // Calc standard + bonus
                        setChancesAwarded(
                            telcor_response?.content?.receivedChance +
                                telcor_response?.content?.extraBonus.chances
                        );
                        setFpAwarded(
                            telcor_response?.content?.receivedFp +
                                telcor_response?.content?.extraBonus?.fp
                        );

                        if (telcor_response?.content?.instantPrize?.prize === 'accessory') {
                            setIsAccessory(true);
                            setAccessoryId(data.user_redemption.id);
                        } else {
                            setRewardHash(telcor_response?.content?.instantPrize?.rewardHash);
                        }
                    } else if (has_won_game) {
                        setWinned('chance');

                        setChancesAwarded(
                            telcor_response?.content?.receivedChance +
                                telcor_response?.content?.extraBonus.chances
                        );
                        setFpAwarded(
                            telcor_response?.content?.receivedFp +
                                telcor_response?.content?.extraBonus?.fp
                        );
                    } else {
                        setRemainingChances((prevChances) => prevChances! - 1);
                        setWinned(false);
                    }

                    setLoading(false);
                })
                .catch((err) => {
                    if (err.response?.status === 429) {
                        Sentry.captureMessage('Rate limiting on real-connection/store/game');
                        Sentry.captureException(err);
                    }
                    goBack();
                });
            // }
        }, 3 * 1000);
    };

    if (loading) {
        return <BaseLoader size={'normal'} />;
    }

    if (noChances) {
        return (
            <div className={styles.container}>
                <CardWrapper>
                    <img src={headerGif} alt="headerGif" className="headerGif" />
                    <h3>Rundă încheiată!</h3>
                    <p>
                        <strong>Numărul de încercări a fost epuizat.</strong>
                    </p>

                    <p>
                        <strong>Încearcă din nou și mâine!</strong>
                    </p>
                    <FinalAdvice />
                </CardWrapper>
            </div>
        );
    }

    // Until we set the current game in useEffects, show loading state
    if (!currentGame) {
        return <BaseLoader size="large" />;
    }

    // User can play, here we show the intro card with the start button
    if (!gameStarted) {
        return (
            <div className={styles.container}>
                <IntroCard introText={currentGame.introText} onStart={toggleStartGame} />
            </div>
        );
    }

    // User won a chance
    if (winned === 'chance') {
        return (
            <div className={styles.container}>
                <CardWrapper winner largeText customBackground>
                    <h3>
                        {' '}
                        BRAVO!
                        <br />
                        Ai primit:
                    </h3>
                    <div className={styles.badgesContainer}>
                        <div className={styles.badgeItem}>
                            <ForwardPointsIcon />
                            <p>{fpAwarded} Forward Points</p>
                        </div>

                        <div className={styles.badgeItem}>
                            <ChancesIcon />
                            <p>{chancesAwarded} Șanse la premiile cu tragere la sorți </p>
                        </div>
                    </div>
                    <FinalAdvice />

                    <Button
                        className={styles.button}
                        label="Înapoi în campanie"
                        size="normal"
                        color="dark"
                        hover="light"
                        href={`${config.path.campaigns.realPeopleRealConnectionsCampaignPage}`}
                    />
                </CardWrapper>
            </div>
        );
    }

    // User won a prize
    if (winned === 'prize') {
        return (
            <div className={styles.container}>
                <CardWrapper winnerVoucher largeText customBackground>
                    <h3>
                        <strong>
                            Felicitări!
                            <br />
                            Ai câștigat:
                        </strong>
                    </h3>

                    <div className={styles.badgesContainer}>
                        <div className={styles.badgeItem}>
                            {isAccessory ? <GiftIcon /> : <VoucherIcon />}
                            {isAccessory ? (
                                <p>Accesoriu IQOS</p>
                            ) : (
                                <p>Next Level Pass în valoare de 50&nbsp;RON</p>
                            )}
                        </div>
                        <div className={styles.badgeItem}>
                            <ForwardPointsIcon />
                            <p>{fpAwarded} Forward Points</p>
                        </div>

                        <div className={styles.badgeItem}>
                            <ChancesIcon />
                            <p>{chancesAwarded} Șanse la premiile cu tragere la sorți </p>
                        </div>
                    </div>

                    <Button
                        className={styles.button}
                        label="Revendică"
                        size="normal"
                        color="dark"
                        hover="light"
                        href={
                            isAccessory
                                ? `${config.path.myRewards.index}/cadou-fizic/${accessoryId}`
                                : `${config.path.myRewards.index}/voucher/${rewardHash}`
                        }
                    />
                </CardWrapper>
            </div>
        );
    }

    // User lost the game, show how many tries has left
    if (winned === false) {
        return (
            <div className={styles.container}>
                <CardWrapper largeText squareContainer>
                    <img src={headerGif} alt="headerGif" className="headerGif" />
                    <p>
                        <strong>
                            {remainingChances === 1
                                ? `Hmmm... mai ai la dispoziție o încercare pentru a încheia jocul cu succes.`
                                : `Mai ai ${remainingChances} încercări astăzi.`}
                        </strong>
                    </p>
                    <Button
                        label="Mai încearcă o dată"
                        size="normal"
                        color="dark"
                        hover="light"
                        onClick={() => window.location.reload()}
                    />
                </CardWrapper>
            </div>
        );
    }

    // Here the game is started, show the current game with reskin
    const gameConfig = currentGame.gameConfig;
    const CurrentGame = currentGame.game;

    return (
        <div className={styles.container}>
            {/* <Select defaultActiveFirstOption={false}
                options={getReskins()}
                defaultValue={getReskins()[currentReskin!].value}
                onChange={(value) => setCurrentReskin(value)}
            /> */}

            <CurrentGame
                {...gameConfig}
                key={gameConfig.slug}
                onWin={() => onFinishGame(true)}
                onLose={() => onFinishGame(false)}
            />
        </div>
    );
};

export default RealConnectionGames;
