//Styles
import styles from './HeetsPearlCollection.module.scss';

//Components
import ArticleHeader from '@containers/IQOSUniverse/components/ArticleHeader';

const HeetsPearlCollection = () => {
    return (
        <section className={styles.wrapper}>
            <ArticleHeader />

            <div className="content">
                <h3>Alege un boost de savoare. Cu o simplă apăsare.</h3>

                <p>
                    Noua colecție HEETS Pearl îți face cunoștință cu o nouă dinamică a gustului în
                    care tu deții controlul, printr-o simplă apăsare a capsulei.
                </p>

                <p>
                    Alege să descoperi, alături de HEETS Amelia Pearl, un amestec de tutun rafinat
                    pe care îl poți îmbogăți cu note fructate de vară, în timp ce HEETS Abora Pearl
                    îți va dezvălui un amestec de tutun intens și plăcut amărui, pe care îl poți
                    completa cu o aromă suculentă de fructe verzi din livadă.
                </p>

                <p>
                    Descoperă chiar acum noua colecție HEETS Pearl și savurează noile arome,
                    îmbogățite cu note fructate.
                </p>
            </div>
        </section>
    );
};

export default HeetsPearlCollection;
