import styles from './ZeroBill2024.module.scss';

// Components
import ArticleHeader from '@containers/IQOSUniverse/components/ArticleHeader';
import ArticleContainer from '@containers/IQOSUniverse/components/ArticleContainer';
import ArticleImage from '@containers/IQOSUniverse/components/ArticleImage';
import KeenSlider from '@components/KeenSlider';

// Helpers
import { useWindowSize } from '@utils/hooks/windowSize';

// Assets
import mobile from './assets/mobile3.jpg';
import desktop from './assets/desktop3.jpg';

// Local data
import { zeroBillSliderPhotos } from '@containers/IQOSUniverse/articles/ZeroBill2024/data';

const ZeroBill2024 = () => {
    const windowSize = useWindowSize();
    const isMobile = windowSize.width < 870;

    return (
        <section className={styles.wrapper}>
            <ArticleHeader />

            <div className="content">
                <p>
                    Ce poate fi mai bun decât o seară de distracție cu prietenii, în care nu trebuie
                    să vă faceți griji pentru consumație? <br /> <br />
                    Primăvara aceasta am surprins o parte din membrii IQOS CLUB pe care i-am
                    întâlnit în locațiile IQOS din țară: le-am achitat integral nota de plată!
                    Pentru că atunci când alegi IQOS, primești mereu mai mult.
                </p>
            </div>

            <ArticleContainer className={styles.zeroBillSlider} background={'#00D1D2'}>
                <KeenSlider
                    withArrows
                    mode={'snap'}
                    origin={'auto'}
                    spacing={10}
                    darkArrows
                    dotColor={'#FFFDFB'}
                    perView={!isMobile ? 1 : 'auto'}
                >
                    {zeroBillSliderPhotos.map(({ id, image, desktopImage }) => {
                        return (
                            <div className={styles.slideContainer} key={id}>
                                <img src={isMobile ? image : desktopImage} alt={''} />
                            </div>
                        );
                    })}
                </KeenSlider>

                <h3>Vezi cum au primit vestea norocoșii</h3>
            </ArticleContainer>

            <div className={styles.bottomSection}>
                <ArticleImage image={isMobile ? mobile : desktop} />

                <p className={styles.bottomParagraph}>
                    Când ești membru IQOS CLUB, te așteaptă câte o surpriză de fiecare dată când te
                    întâlnim. Ce spui, ne vedem în oraș?
                </p>
            </div>
        </section>
    );
};

export default ZeroBill2024;
