export const PWAQRCodeIcon = () => {
    return (
        <svg
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M18 29.8889H27.8889V20H18V29.8889ZM20.4722 22.4722H25.4167V27.4167H20.4722V22.4722Z"
                fill="#00D1D2"
            />
            <path
                d="M18 42.2498H27.8889V32.3608H18V42.2498ZM20.4722 34.8331H25.4167V39.7775H20.4722V34.8331Z"
                fill="#00D1D2"
            />
            <path
                d="M30.3613 20V29.8889H40.2502V20H30.3613ZM37.778 27.4167H32.8336V22.4722H37.778V27.4167Z"
                fill="#00D1D2"
            />
            <path d="M40.2496 39.7773H37.7773V42.2496H40.2496V39.7773Z" fill="#FFFDFB" />
            <path d="M32.8336 32.3608H30.3613V34.8331H32.8336V32.3608Z" fill="#FFFDFB" />
            <path d="M35.3052 34.8335H32.833V37.3057H35.3052V34.8335Z" fill="#FFFDFB" />
            <path d="M32.8336 37.3052H30.3613V39.7774H32.8336V37.3052Z" fill="#FFFDFB" />
            <path d="M35.3052 39.7773H32.833V42.2496H35.3052V39.7773Z" fill="#FFFDFB" />
            <path d="M37.7769 37.3052H35.3047V39.7774H37.7769V37.3052Z" fill="#FFFDFB" />
            <path d="M37.7769 32.3608H35.3047V34.8331H37.7769V32.3608Z" fill="#FFFDFB" />
            <path d="M40.2496 34.8335H37.7773V37.3057H40.2496V34.8335Z" fill="#FFFDFB" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M38.776 11.14C41.1339 11.2485 42.4149 11.6417 43.2677 11.9742C44.397 12.4128 45.202 12.9367 46.049 13.7837C46.896 14.6307 47.4199 15.4357 47.8585 16.565C48.1899 17.4178 48.5842 18.6988 48.6915 21.0567C48.8082 23.6082 48.8327 24.3735 48.8327 30.8333C48.8327 37.2932 48.8082 38.0585 48.6927 40.61C48.5842 42.9678 48.191 44.2488 47.8585 45.1017C47.4199 46.231 46.896 47.036 46.049 47.883C45.202 48.73 44.397 49.2538 43.2677 49.6925C42.4149 50.0238 41.1339 50.4182 38.776 50.5255C36.2245 50.6422 35.4592 50.6667 28.9994 50.6667C22.5395 50.6667 21.7742 50.6422 19.2227 50.5267C16.8648 50.4182 15.5838 50.025 14.731 49.6925C13.6017 49.2538 12.7967 48.73 11.9497 47.883C11.1027 47.036 10.5788 46.231 10.1402 45.1017C9.80768 44.2488 9.41452 42.9667 9.30602 40.61C9.18935 38.0573 9.16602 37.292 9.16602 30.8333C9.16602 24.3735 9.19052 23.6082 9.30602 21.0567C9.41568 18.7 9.80768 17.419 10.1413 16.565C10.58 15.4357 11.1038 14.6307 11.9508 13.7837C12.7978 12.9367 13.6017 12.4128 14.731 11.9742C15.5838 11.6417 16.866 11.2485 19.2227 11.14C21.7753 11.0233 22.5407 11 28.9994 11C35.4592 11 36.2245 11.0245 38.776 11.14V11.14Z"
                stroke="white"
                strokeWidth="2.33333"
            />
        </svg>
    );
};
