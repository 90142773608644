import styles from './QuoteWrapper.module.scss';
import { IQuoteWrapperProps } from "./interface";
import { QuoteIcon } from "@assets/icons";

const QuoteWrapper = (props: IQuoteWrapperProps) => {
    const { image, quote } = props;

    return(
        <section className={styles.wrapper}>
            <div className={styles.image}>
                <img src={image} alt="" />
            </div>

            <div className={styles.content}>
                <QuoteIcon />
                <h4>{quote}</h4>
            </div>
        </section>
    )
};

export default QuoteWrapper;