export interface DeviceCleaningListItem {
    id: number;
    title: string;
    description: string;
}


export const DeviceCleaningImportanceList: DeviceCleaningListItem[] = [
   
    {
        id:1,
        title:`Simplitate în utilizare`,
        description:`Este mai simplu să utilizezi dispozitivul pentru că rezervele HEETS sunt
        introduse mai ușor în „stilou”.`
    },
    {
        id:2,
        title:`O experiență optimă`,
        description:` Fiecare sesiune va dura mai mult pentru că aceasta nu va mai fi întreruptă
        de reziduurile de tutun.`
    },
    {
        id:3,
        title:`Calitate constantă`,
        description:`Calitatea experienței va rămâne constant una bună, pentru că, prin curățare,
        dispozitivul poate produce mai mult aerosol.`
    },
    {
        id:4,
        title:`Fără miros neplăcut`,
        description:`Prin curățarea dispozitivului, vei evita ca mirosul aerosolului generat să
        devină neplăcut.`
    }
]