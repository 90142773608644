import React from 'react';
import styles from './LampDinners.module.scss';

// Components
import ArticleHeader from '@containers/IQOSUniverse/components/ArticleHeader';
import ArticleImage from '@containers/IQOSUniverse/components/ArticleImage';
import ArticleContainer from '@containers/IQOSUniverse/components/ArticleContainer';

// Assets
import img1 from './assets/img01.jpg';
import KeenSlider from '@components/KeenSlider';
import { sliderData } from './sliderData';

const LampDinners = () => {
    return (
        <section className={styles.wrapper}>
            <ArticleHeader />

            <div className="content">
                <p>
                    Concursul „Câștigă o experiență IQOS alături de oamenii speciali din viața ta!”
                    și-a desemnat câștigătorii. Aceștia s-au bucurat, împreună cu 3 oameni dragi, de
                    o cină cu multe surprize la un restaurant din orașul lor.
                </p>
            </div>

            <div className={styles.articleWrapper}>
                <ArticleContainer background={'#F6F4F0'}>
                    <div className={styles.fullWidthImage}>
                        <ArticleImage image={img1} />
                        <h3>Cum a decurs experiența?</h3>
                        <p>
                            Câștigătorii au savurat o cină cu preparate și cocktail-uri speciale,
                            într-o atmosferă plăcută și relaxată. A fost o seară plină de momente de
                            conexiune autentică.
                        </p>
                    </div>
                </ArticleContainer>
            </div>

            <div className={styles.articleWrapper}>
                <ArticleContainer background={'#F6F4F0'}>
                    <div className={styles.customContainer}>
                        <h3>Premiile concursului</h3>

                        <p>
                            Membrii IQOS CLUB care au participat la concurs ne-au împărtășit cine
                            sunt oamenii care i-au inspirat să creadă în visul lor și ne-au trimis o
                            fotografie cu aceștia. Cele mai inspiraționale înscrieri au câștigat
                            experiența unei cine IQOS în orașul lor.{' '}
                        </p>

                        <h3>Galerie Foto</h3>

                        <KeenSlider
                            className={styles.prizesSlider}
                            withArrows={true}
                            darkArrows={true}
                            autoSwitch={true}
                            infinite={true}
                            timeoutBetweenSlides={5000}
                            spacing={16}
                            mode={'snap'}
                            perView={1}
                        >
                            {sliderData.map((sl: any) => (
                                <section key={sl.id} className={styles.slide}>
                                    <img src={sl.image} alt={sl.title} />
                                    {/*<h3>{sl.title}</h3>*/}
                                    {/*<p>{sl.subtitle}</p>*/}
                                </section>
                            ))}
                        </KeenSlider>
                    </div>
                </ArticleContainer>
            </div>

            <ArticleContainer background={'#00D1D2'}>
                <div className={styles.customContainer}>
                    <h3 className={styles.noMargin}>
                        Sperăm că te-am făcut și pe tine să te gândești la oamenii speciali din
                        viața ta. Abia așteptăm să ne cunoaștem la viitoarele evenimente și
                        concursuri marca IQOS.
                    </h3>
                </div>
            </ArticleContainer>
        </section>
    );
};

export default LampDinners;
