import styles from './MediaGallery.module.scss';
import { IMediaGalleryProps } from './interface';

// Components
import { Image } from 'antd';
import KeenSlider from '@components/KeenSlider';
import { useWindowSize } from '@utils/hooks/windowSize';

const MediaGallery = ({
    images,
    sliderClassName,
    showTitle = true,
    customTitle,
    darkArrows = false,
    arrowsMobile = true,
    infinite = false
}: IMediaGalleryProps) => {
    const window = useWindowSize();
    const isMobile = window.width < 870;

    return (
        <section className={styles.wrapper}>
            {customTitle ? <h3>{customTitle}</h3> : showTitle && <h3>Galerie media</h3>}

            <Image.PreviewGroup>
                <KeenSlider
                    spacing={8}
                    withArrows={arrowsMobile ? isMobile : true}
                    darkArrows={darkArrows}
                    className={sliderClassName}
                    infinite={infinite}
                >
                    {images.map((img, idx) => (
                        <Image key={idx} width={300} src={img} />
                    ))}
                </KeenSlider>
            </Image.PreviewGroup>
        </section>
    );
};

export default MediaGallery;
