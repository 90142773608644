export const CheckmarkIcon = () => (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11.1825 19.3094C15.8174 19.3094 19.5747 15.6057 19.5747 11.037C19.5747 6.46831 15.8174 2.76465 11.1825 2.76465C6.54756 2.76465 2.79022 6.46831 2.79022 11.037C2.79022 15.6057 6.54756 19.3094 11.1825 19.3094Z"
            fill="#00D1D2"
        />
        <path
            d="M7.08572 11.9199L9.62098 14.5346L16.05 8.02393"
            stroke="#34303D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M11.1449 21C16.7478 21 21.2899 16.5228 21.2899 11C21.2899 5.47715 16.7478 1 11.1449 1C5.54204 1 1 5.47715 1 11C1 16.5228 5.54204 21 11.1449 21Z"
            stroke="#34303D"
            strokeMiterlimit="10"
            strokeLinecap="round"
        />
    </svg>
);
