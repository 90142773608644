import { useEffect } from 'react';
import classnames from 'classnames';
import { IModalProps } from './interface';
import styles from './BaseModal.module.scss';

// Assets
import { ReactComponent as CloseModal } from './assets/closeModal.svg';

// Components
import { Modal } from 'antd';

// Utils
import useLockedBody from '@utils/hooks/useLockedBody';

const BaseModal = (props: IModalProps) => {
    const [_locked, setLocked] = useLockedBody();
    const {
        children,
        className,
        closeModal,
        isModalOpen,
        size = 'normal',
        maskClosable = true,
        destroyOnClose = false,
        autoHeightMobile,
        lockBodyScroll = true
    } = props;

    useEffect(() => {
        if (lockBodyScroll) {
            setLocked(isModalOpen);
        }

        return () => {
            setLocked(false);
        };
    }, [isModalOpen]);

    return (
        <Modal
            centered
            footer={false}
            open={isModalOpen}
            onCancel={closeModal}
            closable={!!closeModal}
            closeIcon={<CloseModal />}
            maskClosable={maskClosable}
            destroyOnClose={destroyOnClose}
            className={classnames(
                className,
                styles[size],
                autoHeightMobile && styles.autoHeightMobile
            )}
        >
            {children}
        </Modal>
    );
};
export default BaseModal;
