import React, { useEffect, useState } from 'react';
import { IQuiz } from './interface';

// Styles
import styles from './QuizGame.module.scss';

// Components
import Button from '@components/Layout/Button';
import { RadioChangeEvent, Radio, Progress } from 'antd';

// Utils
import useToggle from '@utils/hooks/useToggle';

// Redux
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { getQuiz, postQuizQuestions } from '@components/Quiz/QuizSlice';
import classnames from 'classnames';

const QuizGame = ({ image, quizSlug = null, onWin, onLose, multiple = false }: IQuiz) => {
    const dispatch = useAppDispatch();
    const { quizQuestions } = useAppSelector((state) => state.quiz);
    const { steps } = quizQuestions.quiz;

    const [loading, toggleLoading] = useToggle();
    const [selectedAnswer, setSelectedAnswer] = useState();
    const [correctAnswer, setCorrectAnswer] = useState('');

    const [success, setSuccess] = useState(false);

    let sum = 0;
    let statisticsLength = quizQuestions?.quiz?.steps[0]?.questions[0]?.statistics?.length;
    // Get quiz question
    useEffect(() => {
        console.log(quizSlug);
        if (quizSlug) {
            dispatch(getQuiz({ slug: quizSlug, statistics: false }));
        }
    }, [quizSlug]);

    // Set right answer
    useEffect(() => {
        if (!multiple && quizQuestions.quiz.slug) {
            const { value } = steps[0].questions[0].options.find(
                ({ is_correct_answer }) => is_correct_answer === '1'
            )!;
            setCorrectAnswer(value);
        }
    }, [quizQuestions]);

    const onChange = ({ target }: RadioChangeEvent) => {
        setSelectedAnswer(target.value);
    };

    const nextStep = () => {
        if (!loading) {
            toggleLoading();
            onWin();
        }
    };

    const onSubmit = () => {
        const currentStep = {
            quiz_slug: quizQuestions?.quiz?.slug,
            step_id: quizQuestions?.quiz?.steps[0]?.id,
            answers: [
                {
                    quiz_question_id: quizQuestions?.quiz?.steps[0]?.questions[0]?.id,
                    values: [
                        {
                            value: selectedAnswer
                        }
                    ]
                }
            ]
        };

        toggleLoading();

        dispatch(postQuizQuestions(currentStep)).then((r) => {
            if (multiple) {
                setSuccess(true);
                toggleLoading();
                // setTimeout(() => {
                //     onWin();
                // }, 5000);
            } else {
                if (selectedAnswer === correctAnswer) {
                    onWin();
                } else {
                    onLose();
                }
            }
        });
    };

    return (
        <div className={styles.container}>
            <img src={image} />

            {quizQuestions.quiz.slug && !success && (
                <div className={styles.quiz}>
                    <div className={styles.title}>{steps[0].questions[0].title}</div>
                    <div className={styles.radioContainer}>
                        <Radio.Group onChange={onChange} value={selectedAnswer}>
                            {steps[0].questions[0].options.map(({ value }) => (
                                <Radio key={value} value={value}>
                                    {value}
                                </Radio>
                            ))}
                        </Radio.Group>
                    </div>
                </div>
            )}

            {success && (
                <div className={styles.completed}>
                    <h3>Cele mai votate variante</h3>
                    <div className={styles.poll}>
                        {quizQuestions?.quiz?.steps[0]?.questions[0]?.statistics
                            ?.map(({ answer, statistic }, i) => {
                                if (i === (statisticsLength || 0) - 1) {
                                    return { answer, statistic: 100 - sum };
                                }
                                statistic = Math.round(Number(statistic)).toString();
                                sum = sum + Number(statistic);

                                return { answer, statistic };
                            })
                            .map((r, key) => (
                                <div key={key} className={styles.result}>
                                    <span
                                        className={classnames(
                                            styles.name,
                                            r?.answer ===
                                                quizQuestions?.quiz?.steps[0]?.questions[0]?.answer
                                                    ?.values[0]?.value && styles.mostVoted
                                        )}
                                    >
                                        {r.answer}
                                    </span>

                                    <Progress
                                        percent={Math.round(Number(r.statistic))}
                                        status="active"
                                        strokeColor={
                                            r?.answer ===
                                            quizQuestions?.quiz?.steps[0]?.questions[0]?.answer
                                                ?.values[0]?.value
                                                ? '#00D1D2'
                                                : '#C1C0C4'
                                        }
                                    />
                                </div>
                            ))}
                    </div>
                </div>
            )}

            {!success ? (
                <Button
                    label="Trimite"
                    color="dark"
                    hover="turquoise"
                    type="solid"
                    disabled={!selectedAnswer}
                    onClick={onSubmit}
                    loading={loading}
                />
            ) : (
                <Button
                    label="Mai departe"
                    color="dark"
                    hover="turquoise"
                    type="solid"
                    loading={loading}
                    onClick={nextStep}
                />
            )}
        </div>
    );
};

export default QuizGame;
