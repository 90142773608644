import styles from './GameModal.module.scss';
import { IGameModalProps } from './interface';

// Helpers
import { GameData, GameDataInterface } from './data';

// Components
import HangManGame from './HangManGame';
import BaseModal from '@components/Layout/BaseModal';

const GameModal = ({ isVisible, handleClose, slug }: IGameModalProps) => {
    return (
        <section className={styles.wrapper}>
            <BaseModal
                size="large"
                isModalOpen={isVisible}
                closeModal={handleClose}
                className={styles.gameModal}
            >
                {GameData.filter((data: GameDataInterface) => data.slug === slug).map(
                    (game: GameDataInterface) => {
                        return (
                            <HangManGame
                                chances={game.tries}
                                answer={game.correctAnswer}
                                title="Ghicește cuvântul"
                                hint={game.hint}
                                slug={slug}
                                finalMessage={game.finalMessage}
                                handleClose={handleClose}
                            />
                        );
                    }
                )}
            </BaseModal>
        </section>
    );
};

export default GameModal;
