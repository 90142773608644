import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axiosRequest from '@utils/axios';

export interface DiscoverArticlesState {
    status: 'idle' | 'loading' | 'failed';
    articles: Array<{
        id: number;
        picture: string;
        created_at: string;
        title: string;
        subtitle: string;
        points: number;
        slug: string;
        seen: boolean;
        quiz_points: number;
        quiz_id?: number;
        quiz_end_date?: string | null;
        display_badge_icon?: boolean | null;
        badges?: Array<string>;
        episode: string;
        category: {
            slug: string;
        };
        completed_quiz: boolean;
    }>;
    error: any;
}

interface AxiosError {
    response: {
        data: {
            message: string;
        };
    };
}

const initialState: DiscoverArticlesState = {
    status: 'idle',
    articles: [],
    error: {}
};

export const getDiscoverArticles = createAsyncThunk('homepage/discoverArticles', async () => {
    try {
        const response = await axiosRequest.get('/articles').then((res) => {
            return res;
        });

        return response.data.data.slice(0, 2);
    } catch (e) {
        return (e as AxiosError).response.data.message;
    }
});

export const discoverArticlesSlice = createSlice({
    name: 'discoverArticles',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getDiscoverArticles.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getDiscoverArticles.fulfilled, (state, action) => {
                state.status = 'idle';
                state.articles = action.payload;
            })
            .addCase(getDiscoverArticles.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    }
});

export default discoverArticlesSlice.reducer;
