import { useState } from 'react';
import styles from './DeviceCleaningImportance.module.scss';

// Helpers
import classNames from 'classnames';
import { config } from '@utils/config';
import {
    DeviceCleaningImportanceList,
    DeviceCleaningListItem
} from './list/DeviceCleaningImportanceList';

// Assets
import image1 from './assets/img-03.png';
import image2 from './assets/img-05.png';
import image1Desktop from './assets/image1Desktop.png';
import image2Desktop from './assets/image2Desktop.png';
import videoThumbnail from './assets/videoThumbnail.png';

// Components
import Button from '@components/Layout/Button';
import VideoModal from '@components/VideoModal';
import ArticleImage from '@containers/IQOSUniverse/components/ArticleImage';
import ArticleHeader from '@containers/IQOSUniverse/components/ArticleHeader';
import ArticleContainer from '@containers/IQOSUniverse/components/ArticleContainer';

const DeviceCleaningImportance = () => {
    const [toggleModal, setToggleModal] = useState(false);

    return (
        <section className={styles.wrapper}>
            <ArticleHeader />

            <div className="content">
                <h3>Un obicei simplu pentru o experiență optimă</h3>

                <p>
                    Gesturile mici contează. Acest lucru este valabil și când vorbim de curățarea
                    dispozitivului IQOS – un proces simplu, care nu îți ocupă mult timp și care face
                    diferența pentru a te bucura de experiența IQOS. Află de ce este important să nu
                    neglijezi acest aspect, cum să-ți cureți simplu și repede dispozitivul și
                    amintește-ți să îți cureți dispozitivul conform recomandărilor.
                </p>
            </div>

            <ArticleContainer background={'#34303D'} textColor={'#FFFDFB'}>
                <ArticleImage cover={true} mobileImage={image1} image={image1Desktop} />

                <div className={styles.listContainer}>
                    <div className="content">
                        <h3>Care sunt beneficiile curățării?</h3>
                        <p>
                            Curățarea regulată a dispozitivului tău IQOS, după fiecare 20 de
                            utilizări, îți aduce mari beneficii:
                        </p>
                    </div>

                    <div className="content">
                        {DeviceCleaningImportanceList.map((item: DeviceCleaningListItem) => {
                            return (
                                <div key={item.id} className={styles.deviceListItem}>
                                    <h3>{item.title}</h3>
                                    <p>{item.description}</p>
                                </div>
                            );
                        })}
                    </div>
                </div>

                <div className={classNames(styles.expertAdvice, 'content')}>
                    <p className={styles.description}>
                        <strong>Sfatul experților IQOS: </strong> Pentru o experiență optimă cu
                        dispozitivul tău IQOS, verifică regulat căpăcelul. Un căpăcel care nu se
                        închide bine poate contribui la acumularea mai rapidă de reziduuri. Vezi
                        colecția de căpăcele.
                    </p>

                    <Button
                        href={config.path.iqos.capsCollection}
                        label="Vezi colecția de căpăcele"
                        type="text"
                        color="light"
                        hover="turquoise"
                        size="small"
                    />
                </div>
            </ArticleContainer>

            <div className={styles.space}>
                <ArticleContainer background={'#00D1D2'}>
                    <div className="content">
                        <h3>Amintește-ți chiar acum pașii de curățare:</h3>

                        <ArticleImage
                            isVideo
                            mobileImage={videoThumbnail}
                            image={videoThumbnail}
                            onClick={() => setToggleModal(true)}
                        />

                        <VideoModal
                            toggleModal={toggleModal}
                            setToggleModal={() => setToggleModal(false)}
                            video="663311893"
                        />
                    </div>
                </ArticleContainer>
            </div>

            <ArticleImage cover={true} mobileImage={image2} image={image2Desktop} />

            <div className="content">
                <h3>Bețisoarele de curățare sunt prietenoase cu mediul</h3>

                <p>
                    Conform Directivei Europene privind articolele din plastic de unică folosință și
                    pentru a promova principiile noastre de sustenabilitate, bețișoarele pentru
                    curățarea dispozitivului IQOS cu tijă din plastic au fost înlocuite cu bețișoare
                    cu tijă din carton.
                </p>

                <p>
                    Ca urmare a acestei modificări, soluția de izopropanol a fost eliminată pentru a
                    preveni umezirea tijei bețișorului din carton.
                </p>

                <p>
                    Pentru o experiență optimă cu IQOS, recomandăm, în continuare curățarea
                    „stiloului” cu bețișoarele și peria pentru curățat IQOS.
                </p>
            </div>

            <ArticleContainer background={'#F6F4F0'}>
                <div className="content">
                    <h3>Beneficiază de o curățare profesională</h3>

                    <p>
                        De asemenea, îți reamintim că te poti bucura, gratuit, de curățarea
                        profesională a dispozitivului tău. Colegii noștri te așteaptă în locațiile
                        IQOS oricând!
                    </p>
                </div>
            </ArticleContainer>
        </section>
    );
};
export default DeviceCleaningImportance;
