export const FiltersIcon = () => {
    return (
        <svg
            width="23"
            height="22"
            viewBox="0 0 23 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M3.64285 5.49999C4.94466 5.49999 5.99999 4.44466 5.99999 3.14285C5.99999 1.84103 4.94466 0.785706 3.64285 0.785706C2.34103 0.785706 1.28571 1.84103 1.28571 3.14285C1.28571 4.44466 2.34103 5.49999 3.64285 5.49999Z"
                stroke="#34303D"
                strokeWidth="1.71429"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6 3.14282H21.7143"
                stroke="#34303D"
                strokeWidth="1.71429"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.5 13.3571C12.8018 13.3571 13.8571 12.3018 13.8571 11C13.8571 9.69815 12.8018 8.64282 11.5 8.64282C10.1982 8.64282 9.14282 9.69815 9.14282 11C9.14282 12.3018 10.1982 13.3571 11.5 13.3571Z"
                stroke="#34303D"
                strokeWidth="1.71429"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M1.28571 11H9.14285"
                stroke="#34303D"
                strokeWidth="1.71429"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.8571 11H21.7143"
                stroke="#34303D"
                strokeWidth="1.71429"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M19.3571 21.2143C20.659 21.2143 21.7143 20.159 21.7143 18.8571C21.7143 17.5553 20.659 16.5 19.3571 16.5C18.0553 16.5 17 17.5553 17 18.8571C17 20.159 18.0553 21.2143 19.3571 21.2143Z"
                stroke="#34303D"
                strokeWidth="1.71429"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M19.3571 21.2143C20.659 21.2143 21.7143 20.159 21.7143 18.8571C21.7143 17.5553 20.659 16.5 19.3571 16.5C18.0553 16.5 17 17.5553 17 18.8571C17 20.159 18.0553 21.2143 19.3571 21.2143Z"
                stroke="#34303D"
                strokeWidth="1.71429"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17 18.8571H1.28571"
                stroke="#34303D"
                strokeWidth="1.71429"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
