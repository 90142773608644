import { useState } from 'react';

//Styles
import styles from './WeEditionIqos3Duo.module.scss';

//Components
import ArticleHeader from '@containers/IQOSUniverse/components/ArticleHeader';
import ArticleImage from '@containers/IQOSUniverse/components/ArticleImage';
import ArticleContainer from '@containers/IQOSUniverse/components/ArticleContainer';
import VideoModal from '@components/VideoModal';

//Assets
import image1 from './assets/img-01.png';
import image2 from './assets/img-03.png';
import videoThumbnail from './assets/videoThumbnail.png';

const WeEditionIqos3Duo = () => {
    const [toggleModal, setToggleModal] = useState(false);

    return (
        <div className={styles.wrapper}>
            <ArticleHeader />

            <div className="content">
                <p>
                    Inspirat de frumusețea diversității, noul dispozitiv IQOS 3 DUO în ediție
                    limitată a fost lansat într-un moment perfect: în weekendul de 1 Mai, în clubul
                    Nuba din Mamaia. Mii de oameni care cred în puterea schimbării și a empatiei
                    față de cei din jurul nostru au petrecut împreună până în zori și au făcut
                    cunoștință cu noul dispozitiv.
                </p>
            </div>

            <ArticleImage
                imgPosition="top center"
                cover={true}
                mobileImage={image1}
                image={image1}
            />

            <div className="content">
                <h3>Comunitatea IQOS Club a fost prezentă</h3>

                <p>
                    Membrii IQOS Club au avut șansa să câștige o experiență VIP la petrecerea de
                    lansare. Ne-am dorit să le oferim un weekend unic, așa că am gândit totul până
                    în cel mai mic detaliu: de la drumul spre mare cu Black Cab până la masa
                    rezervată special special în clubul Nuba. Ne-am dorit să sărbătorească noua
                    ediție limitată IQOS 3 DUO ca un VIP. Și bineînțeles, câștigătorii au primit
                    cadou și dispozitivul.
                </p>
            </div>

            <ArticleImage cover={true} mobileImage={image2} image={image2} />

            <div className="content">
                <h3>Trăim diferit, simțim diferit. Însă dorința de schimbare ne aduce împreună.</h3>

                <p>
                    Odată cu lansarea noului dispozitiv IQOS 3 DUO în ediție limitată, am celebrat
                    diversitatea comunității puternice IQOS Club. Ne-am lăsat inspirați de
                    frumusețea diferențelor dintre noi și am creat un eveniment de lansare unic.
                    Ritmurile energizante au captivat mulțimea, iar Emil Rengle și trupa de dans au
                    creat cadrul perfect pentru a prezenta în premieră dispozitivul, care a cucerit
                    publicul instant.
                </p>

                <p>
                    Inspirați de IQOS 3 DUO, am creat și cocktailuri personalizate, pe care le-am
                    oferit invitaților. Și pentru că orice petrecere memorabilă merită imortalizată,
                    am amplasat o cameră pe un braț robotic cu ajutorul căreia invitații și-au putut
                    crea clipuri video numai bune de trimis prietenilor.
                </p>
            </div>

            <ArticleContainer background={'#00D1D2'}>
                <div className="content">
                    <h3>Am creat un clip și pentru tine!</h3>

                    <ArticleImage
                        isVideo
                        mobileImage={videoThumbnail}
                        image={videoThumbnail}
                        onClick={() => setToggleModal(true)}
                    />

                    <VideoModal
                        toggleModal={toggleModal}
                        setToggleModal={() => setToggleModal(false)}
                        video="716406433"
                    />
                </div>
            </ArticleContainer>
        </div>
    );
};

export default WeEditionIqos3Duo;
