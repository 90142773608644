import image1 from '../assets/slide1Img1.jpg';
import image2 from '../assets/slide1Img2.png';
import image3 from '../assets/slide1Img3.png';
import image4 from '../assets/slide1Img4.png';

export interface Milano1 {
    title: string;
    description: string;
    image: string;
    titleDark: boolean;
}

export const SliderMilano: Milano1[] = [
    {
        title: `Give back to Milan`,
        description: `Un atelier de gătit preparate specific italienești cu ingrediente proaspăt culese și alături de un chef inspirat. Câștigătorii s-au bucurat atât de procesul prin care au pregătit gnocchi și pesto, dar și de satisfacția de a împărți preparatele alături de alte comunități din Milano.`,
        image: image1,
        titleDark: true
    },
    {
        title: `Călătorie în lumea gustului`,
        description: `După paste, câștigătorii au învățat totul și despre sortimentele de cafea precum și cum să le asocieze perfect cu aromele de HEETS preferate. Atelierul a fost ținut de un expert local în arta cafelei și a latte-ului, iar participanții au învățat cât de multe se află în spatele unei cești de cafea.`,
        image: image2,
        titleDark: true
    },
    {
        title: `Echilibru de arome. O experiență culinară fusion.`,
        description: `Alături de un chef cu stele Michelin, câștigătorii au învățat să gătească un preparat surprinzător, care a îmbinat arome intense locale cu accente japoneze.`,
        image: image3,
        titleDark: true
    },
    {
        title: `O petrecere cu noi toți. Împreună.`,
        description: `Într-o clădire emblematică a orașului, la Bullona, câștigătorii s-au bucurat de o seară de neuitat, în care au descoperit ce i-a adus împreună în aceeași comunitate. Înclinația spre progres, plăcerea de a trăi experiențe diverse și de a descoperi noi oameni.`,
        image: image4,
        titleDark: true
    }
];
