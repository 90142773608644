export const FPIcon = () => {
    return (
        <svg
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M17 33C25.8366 33 33 25.8366 33 17C33 8.16344 25.8366 1 17 1C8.16344 1 1 8.16344 1 17C1 25.8366 8.16344 33 17 33Z"
                stroke="#34303D"
            />
            <path
                d="M17 3.23999C20.6494 3.23999 24.1493 4.6897 26.7298 7.2702C29.3103 9.8507 30.76 13.3506 30.76 17C30.76 20.6494 29.3103 24.1493 26.7298 26.7298C24.1493 29.3103 20.6494 30.76 17 30.76V3.23999Z"
                fill="#00D1D2"
            />
            <path
                d="M16.9532 9.32251L19.1433 13.9875L24.0399 14.7337L20.4969 18.3651L21.3333 23.4927L16.9538 21.0716L12.5743 23.4927L13.4108 18.3651L9.86774 14.7337L14.7644 13.9855L16.9532 9.32251Z"
                fill="#FFFDFB"
                stroke="#34303D"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
