import React from 'react';
import styles from './IqosStardrift.module.scss';
import classnames from 'classnames';

import KeenSlider from '@components/KeenSlider';
import ArticleHeader from '@containers/IQOSUniverse/components/ArticleHeader';
import ArticleImage from '@containers/IQOSUniverse/components/ArticleImage';

// import cups from './assets/cups.jpg';
import cupsGif from './assets/cani.gif';
import curaj from './assets/curaj.png';
import conexiune from './assets/conexiune.png';
import echilibru from './assets/echilibru.png';

import { useWindowSize } from '@utils/hooks/windowSize';

const slides = [
    {
        id: 1,
        title: 'Cana Curajului',
        subtitle:
            'Un design care te îndeamnă în fiecare dimineață să îți găsești curajul pentru a îți transforma visurile în realitate.',
        image: curaj
    },
    {
        id: 3,
        title: 'Cana Conexiunii',
        subtitle:
            'Un design inspirat de conexiunea dintre oameni, de acei oameni care îți sunt alături și care te susțin să îți urmezi dorințele.',
        image: conexiune
    },
    {
        id: 2,
        title: 'Cana Echilibrului',
        subtitle:
            'O cană care reprezintă echilibrul și pacea interioară de care ai nevoie în fiecare început de zi pentru a-ți transforma dorințele în realitate.',
        image: echilibru
    }
];

const IqosStardrift = () => {
    const { width } = useWindowSize();
    const isMobile = width < 768;

    return (
        <section className={styles.wrapper}>
            <ArticleHeader hideTitle />
            <div className="content">
                <h3>
                    Descoperă colecția de căni IQOS X VICTORIA FINALĂ, în ediție limitată inspirată
                    de noul IQOS ILUMA STARDRIFT
                </h3>

                <p>
                    Dorințele se nasc noaptea, când privim către stele, dar încep să prindă contur
                    dimineața alături de o ceașcă de cafea.
                </p>

                <p>
                    Cele 3 căni au fost create în atelierul brandului românesc Victoria Finală și
                    îți vor aminti mereu să ai încredere în tine și-n dorințele tale:{' '}
                    <strong>Cana Conexiunii, Cana Curajului și Cana Echilibrului.</strong>
                </p>
            </div>

            <div className={classnames('content', styles.sliderWrapper)}>
                <div className={styles.slider}>
                    <KeenSlider withArrows={true} mode="snap" spacing={isMobile ? 0 : 10}>
                        {slides.map(({ id, title, subtitle, image }) => {
                            return (
                                <div className={styles.slide} key={id}>
                                    <img src={image} alt={title} />

                                    <div className={styles.details}>
                                        <h3 className={styles.title}>{title}</h3>
                                        <p>{subtitle}</p>
                                    </div>
                                </div>
                            );
                        })}
                    </KeenSlider>
                </div>
            </div>

            <div className={styles.imageWrapper}>
                <img src={cupsGif} alt="cups-animation" />
            </div>
            <div className="content">
                <h3>Cănile din colecție pot fi ale tale</h3>
                <p>
                    Cănile sunt disponibile în limita stocului disponibil și una dintre ele poate fi
                    a ta pe loc, în urma achiziției unui dispozitiv din colecția IQOS ILUMA
                    STARDRIFT PRIME și IQOS ILUMA STARDRIFT din următoarele locații:
                </p>
                <p>IQOS STORE CONSTANȚA CITY PARK</p>
                <p>IQOS STORE PALAS IAȘI</p>
                <p>IQOS STORE BUCUREȘTI AFI</p>
                <p>IQOS STORE CLUJ IULIUS</p>
                <p>IQOS STORE TIMIȘOARA IULIUS MALL</p>
            </div>
        </section>
    );
};

export default IqosStardrift;
