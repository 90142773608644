export const BadgesIcon = (color: string = '#34303D') => {
    return (
        <svg
            width="20"
            height="22"
            viewBox="0 0 20 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M16.8156 15.8146C15.3149 17.8456 13.0613 19.6047 10 21.0666C6.97754 19.6231 4.74215 17.8899 3.24174 15.8913C1.69413 13.8298 0.896449 11.4441 0.857429 8.70218C0.857424 8.70183 0.857419 8.70147 0.857414 8.70112L0.855 8.49441V2.80217L9.9386 0.87382L19.145 2.80396V8.49762C19.1432 11.2928 18.3629 13.7205 16.8156 15.8146Z"
                stroke={color}
                strokeWidth="1.71"
                strokeLinejoin="bevel"
            />
        </svg>
    );
};
