export const FPIconWhite = () => {
    return (
        <svg
            width="29"
            height="28"
            viewBox="0 0 29 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="14.5" cy="14" r="14" fill="#FFFDFB" />
            <path
                d="M14.3739 3.90698C17.0507 3.90698 19.6179 4.97035 21.5107 6.86316C23.4036 8.75597 24.4669 11.3232 24.4669 14C24.4669 16.6768 23.4036 19.244 21.5107 21.1369C19.6179 23.0297 17.0507 24.093 14.3739 24.093V3.90698Z"
                fill="#00D1D2"
            />
            <path
                d="M14.339 7.36853L16.2545 11.4486L20.5372 12.1013L17.4384 15.2774L18.17 19.7622L14.3395 17.6446L10.5091 19.7622L11.2407 15.2774L8.14185 12.1013L12.4246 11.447L14.339 7.36853Z"
                fill="#FFFDFB"
                stroke="#34303D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
