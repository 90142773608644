import { useEffect, useState } from 'react';
import { IDragCardGame } from './interface';
import styles from './DragCardGame.module.scss';

// Components
import Region from './components/Region';
import Card from './components/Card';

// Utils
import { shuffle } from 'lodash';

// DND Kit
import {
    DndContext,
    DragEndEvent,
    MouseSensor,
    TouchSensor,
    useSensor,
    useSensors
} from '@dnd-kit/core';
import { restrictToWindowEdges } from '@dnd-kit/modifiers';
import classnames from 'classnames';

const DragCardGame = ({
    data,
    onWin,
    onLose,
    maxGuesses = 6,
    pairs = 4,
    cardsType = 'circle',
    notGuessed = false
}: IDragCardGame) => {
    const [wrongCount, setWrongCount] = useState(0);
    const [gameEnded, setGameEnded] = useState(false);

    const fourRandomPairs = shuffle(data).slice(0, pairs);
    const regions = fourRandomPairs.map(({ region }, key) => ({ region, id: key.toString() }));
    const cards = fourRandomPairs.map(({ card }, key) => ({ card, id: key.toString() }));

    const [shuffledRegions, setShuffledRegions] = useState<typeof regions>(shuffle(regions));
    const [shuffledCards, setShuffledCards] = useState<typeof cards>(shuffle(cards));
    const [guessedCards, setGuessedCards] = useState<typeof cards>([]);

    const mouseSensor = useSensor(MouseSensor);
    const touchSensor = useSensor(TouchSensor);
    const sensors = useSensors(mouseSensor, touchSensor);

    const showGuessedCardInRegion = (regionID: string) =>
        guessedCards.map(({ id, card }) => {
            if (id === regionID) {
                return <Card cardsType={cardsType} disabled key={id} card={card} id={id} />;
            }

            return null;
        });

    const handleDragEnd = ({ active, over }: DragEndEvent) => {
        const cardID = active.id.toString();
        const regionID = over?.id.toString();

        if (cardID && regionID) {
            // If card is dropped in the correct region, add it to guessed cards list and remove it from card's deck
            if (cardID === regionID) {
                const guessedCard = shuffledCards.find((card) => card.id === cardID);

                if (guessedCard) {
                    setGuessedCards((prevCards) => [...prevCards, guessedCard]);
                    setShuffledCards((prevCards) => prevCards.filter((card) => card.id !== cardID));
                }
                return;
            } else if (notGuessed) {
                let regionIndex = shuffledRegions.findIndex((region) => region.id === regionID);
                let newShuffledRegions = JSON.parse(JSON.stringify(shuffledRegions));
                let oldChuggledCards = [...shuffledCards];

                if (regionIndex !== -1) {
                    newShuffledRegions[regionIndex].region.notGuessed = true;

                    setShuffledRegions(newShuffledRegions);

                    setShuffledCards((prevCards) => prevCards.filter((card) => card.id !== cardID));

                    setTimeout(() => {
                        setShuffledRegions(shuffledRegions);
                        setShuffledCards(oldChuggledCards);
                    }, 2000);
                }
            }
            setWrongCount((wrongCount) =>
                wrongCount !== maxGuesses ? wrongCount + 1 : maxGuesses
            );
        }
    };

    useEffect(() => {
        if (wrongCount >= maxGuesses && !gameEnded) {
            setGameEnded(true);
            onLose();
        }
    }, [wrongCount]);

    useEffect(() => {
        if (guessedCards.length === pairs && !gameEnded) {
            setGameEnded(true);
            onWin();
        }
    }, [guessedCards]);

    const [soundName, setSoundName] = useState('');
    const [isPlaying, setIsPlaying] = useState(false);

    const onSoundPlay = (value: boolean, soundName: string) => {
        setSoundName(soundName);
        setIsPlaying(value);
    };

    return (
        <DndContext onDragEnd={handleDragEnd} modifiers={[restrictToWindowEdges]} sensors={sensors}>
            <div className={styles.container}>
                <div
                    className={classnames(styles.regions, cardsType === 'square' && styles.square)}
                >
                    {shuffledRegions.map(({ region, id }) => (
                        <Region
                            cardsType={cardsType}
                            soundName={soundName}
                            key={id}
                            region={region}
                            id={id}
                            playing={region.soundName === soundName ? isPlaying : false}
                            setIsPlaying={(val) =>
                                onSoundPlay(val, region.soundName ? region.soundName : '')
                            }
                        >
                            {showGuessedCardInRegion(id)}
                        </Region>
                    ))}
                </div>

                <div className={classnames(styles.cards, cardsType === 'square' && styles.square)}>
                    {shuffledCards.length > 0 && (
                        <Card
                            cardsType={cardsType}
                            key={shuffledCards[0].id}
                            card={shuffledCards[0].card}
                            id={shuffledCards[0].id}
                        />
                    )}
                </div>

                <div className={styles.wrongCount}>
                    Număr încercări: {wrongCount} din {maxGuesses}
                </div>
            </div>
        </DndContext>
    );
};

export default DragCardGame;
