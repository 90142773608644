import { useState } from 'react';
import styles from './AerWear.module.scss';

// Assets
import videoThumbnail from './assets/videoThumbnail.jpg';

// Components
import VideoModal from '@components/VideoModal';
import ArticleImage from '@containers/IQOSUniverse/components/ArticleImage';
import ArticleHeader from '@containers/IQOSUniverse/components/ArticleHeader';
import ArticleContainer from '@containers/IQOSUniverse/components/ArticleContainer';

const AerWear = () => {
    const [toggleModal, setToggleModal] = useState(false);

    return (
        <section className={styles.wrapper}>
            <ArticleHeader />

            <div className="content">
                <h3>Originalul reinterpretat</h3>

                <p>
                    Face parte din filozofia noastră să evoluăm continuu. De aceea căutăm
                    întotdeauna moduri surprinzătoare prin care să ne reinterpretăm. Așa s-au născut
                    noile dispozitive ORIGINALS, dar și colecția de accesorii versatile, inspirate
                    de acestea, creată alături de brandul local AerWear.
                </p>

                <p>
                    Fie că e vorba de o nouă culoare, un nou stil sau o nouă funcție, atunci când
                    reinterpretezi un obiect la care ții, creezi de fapt o variantă mai bună, cu mai
                    mult sens în viața ta. De aceea accesoriile din colecția pregătită alături
                    AerWear au fost create astfel încât să te ajute să dai un aer nou obiectelor
                    tale preferate.
                </p>

                <p>
                    Află întreaga poveste din spatele accesoriilor în ediție limitată în interviul
                    cu Dana Păun, creatoarea brandului AerWear, și descoperă moduri surprinzătoare
                    prin care te poți reinventa în fiecare zi.
                </p>
            </div>

            <div className={styles.articleWrapper}>
                <ArticleContainer background={'#00D1D2'}>
                    <div className="content">
                        <h3>Urmărește interviul„Originalul, reinterpretat de AerWear”</h3>

                        <ArticleImage
                            isVideo
                            mobileImage={videoThumbnail}
                            image={videoThumbnail}
                            onClick={() => setToggleModal(true)}
                        />

                        <VideoModal
                            toggleModal={toggleModal}
                            setToggleModal={() => setToggleModal(false)}
                            video="778846312"
                        />
                    </div>
                </ArticleContainer>
            </div>
        </section>
    );
};

export default AerWear;
