import Icon1 from './assets/icon-1.svg';
import Icon2 from './assets/icon-2.svg';
import Icon3 from './assets/icon-3.svg';

const CarouselItems: Array<{
    id: number;
    icon: string;
    title: string;
    description: string;
}> = [
    {
        id: 1,
        icon: Icon1,
        title: 'Rămâi conectat mai mult',
        description:
            'Dacă adaugi IQOS Club în ecranul de pornire rămâi conectat cu datele tale personale cel puțin 60 de zile.'
    },
    {
        id: 2,
        icon: Icon2,
        title: 'Ai acces rapid la platformă',
        description:
            'Când ai aplicația adăugată pe ecranul de pornire, accesezi IQOS Club fără să te mai autentifici.'
    },
    {
        id: 3,
        icon: Icon3,
        title: 'Ai totul la îndemână',
        description: 'Găsești toate acțiunile din IQOS Club, acum într-o formă simplificată.'
    }
];

export default CarouselItems;
