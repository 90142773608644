import React, { useState } from 'react';
import styles from './JazzInTheParkWrapUp.module.scss';

// Components
import ArticleHeader from '@containers/IQOSUniverse/components/ArticleHeader';
import ArticleImage from '@containers/IQOSUniverse/components/ArticleImage';
import VideoModalQuiz from '@containers/IQOSUniverse/components/VideoModalQuiz';
import CompletedStateArticle from '@containers/IQOSUniverse/components/CompletedStateArticle/CompletedStateArticle';

// Assets
import img from './assets/img.png';
import imgMobile from './assets/imgMobile.png';

// Helpers
import classnames from 'classnames';

// Redux
import { useAppSelector } from '@app/hooks';

const JazzInTheParkWrapUp = () => {
    const { article } = useAppSelector((state) => state.iqosUniverse);

    const [toggleModal, setToggleModal] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);

    return (
        <section className={styles.wrapper}>
            <ArticleHeader
                videoQuiz={() => {
                    setToggleModal(true);
                }}
            />

            <VideoModalQuiz
                toggleModal={toggleModal}
                setToggleModal={() => setToggleModal(false)}
                setShowSuccess={() => {
                    setShowSuccess(true);
                }}
            />

            <div className="content">
                <h3>Îți poți reimagina experiența muzicii jazz?</h3>
                <p>
                    Am fost alături de comunitatea prezentă la ediția de concurs a Jazz in the Park
                    cu noul Home of IQOS, locul în care vizitatorii au aflat totul despre
                    dispozitivul nostru proaspăt lansat, IQOS ILUMA. În plus, membrii IQOS Club au
                    avut parte de multe surprize, precum un photo booth cu premii oferite prin
                    tragere la sorți, un coș de picnic pentru membrii Platinum și un bar plin cu
                    prosecco. Pe cei care au achiziționat IQOS ILUMA i-am surprins cu o cină
                    delicioasă în doi.
                </p>
            </div>

            <div className={classnames('content', styles.noMargin)}>
                <ArticleImage cover image={img} mobileImage={imgMobile} />
            </div>

            <div className={classnames('content', styles.marginTop)}>
                <p>
                    Te așteptăm la cea de-a XI-a ediție a festivalului care va avea loc în
                    septembrie în Parcul Etnografic din Cluj. Vom descoperi împreună artiști de
                    renume internațional și vom duce din nou experiența de festival la nivelul
                    următor.
                </p>
            </div>

            {(showSuccess || article.completed_quiz) && (
                <CompletedStateArticle
                    className={styles.completedStateArticle}
                    article={{
                        points: article.points,
                        quiz_points: article.quiz_points,
                        quiz_end_date: article.quiz_end_date
                    }}
                />
            )}
        </section>
    );
};

export default JazzInTheParkWrapUp;
