export const Quotes = () => {
    return (
        <svg
            width="32"
            height="27"
            viewBox="0 0 32 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0 17.9003C0 24.1163 3.774 26.2623 6.956 26.2623C10.508 26.2623 13.098 23.5983 13.098 20.2683C13.098 17.0123 10.582 14.9403 7.696 14.3483C8.584 10.8703 11.766 6.87428 14.8 4.21028L11.84 0.0662842C5.328 3.91428 0 11.1663 0 17.9003ZM28.564 0.0662842C22.052 3.91428 16.798 11.1663 16.798 17.9003C16.798 24.1163 20.498 26.2623 23.68 26.2623C27.306 26.2623 29.896 23.5983 29.896 20.2683C29.896 17.0123 27.306 14.9403 24.494 14.3483C25.382 10.8703 28.564 6.87428 31.524 4.21028L28.564 0.0662842Z"
                fill="#34303D"
            />
        </svg>
    );
};
