import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosRequest from '@utils/axios';

interface Activity {
    title: string;
    points: number;
    action: string;
    object: {
        title: string;
        id: number;
    };
    summary: string;
    extra: {};
    created_at: string;
}

interface ActivityJournalState {
    status: 'idle' | 'loading' | 'failed';
    activities: {
        data: Activity[];
        links: {
            first: null | string;
            last: null | string;
            prev: null | string;
            next: null | string;
        };
        meta: {
            current_page: number;
            total: number;
            path: string;
            per_page: number;
        };
    };
}

const initialState: ActivityJournalState = {
    status: 'idle',
    activities: {
        data: [],
        links: {
            first: null,
            last: null,
            prev: null,
            next: null
        },
        meta: {
            current_page: 0,
            total: 0,
            path: '',
            per_page: 0
        }
    }
};

interface AxiosError {
    response: {
        data: {
            message: string;
        };
    };
}

export const getActivityJournal = createAsyncThunk(
    'activities/activityJournal',
    async (values: object) => {
        try {
            const response = await axiosRequest.get('/user/activity-log', { params: values });
            return response.data;
        } catch (e) {
            return (e as AxiosError).response.data.message;
        }
    }
);

export const activityJournalSlice = createSlice({
    name: 'activityJournal',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getActivityJournal.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getActivityJournal.fulfilled, (state, action) => {
                state.status = 'idle';
                state.activities = action.payload;
            })
            .addCase(getActivityJournal.rejected, (state) => {
                state.status = 'failed';
            });
    }
});

export default activityJournalSlice.reducer;
