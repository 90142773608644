// Games
import MatchThreeGame from '@components/Games/MatchThreeGame';
import ReverseHangman from '@components/Games/ReverseHangman';
import Puzzle from '@components/Games/Puzzle';
import MemoryGame from '@components/Games/MemoryGame';
import DragCardGame from '@components/Games/DragCardGame';
import QuizGame from '@components/Games/QuizGame';
import QuizGameVideo from '@components/Games/QuizGameVideo/QuizGameVideo';

// Interfaces
import { IReverseHangmanCallForDreamsGame } from '@components/Games/ReverseHangman/interface';
import { IPuzzleCallForDreamsGame } from '@components/Games/Puzzle/interface';
import { IMatchThreeCallForDreamsGame } from '@components/Games/MatchThreeGame/interface';
import { IMemoryCallForDreamsGame } from '@components/Games/MemoryGame/interface';
import { IDragCardGameRealPeople } from '@components/Games/DragCardGame/interface';
import { IQuizConnectionsGame } from '@components/Games/QuizGame/interface';
import { IQuizGameVideoRealPeople } from '@components/Games/QuizGameVideo/interface';

//Assets
import headerGif from './assets/cardGif.gif';
//Hangman
import hg1 from './assets/hangman/hg1.jpg';
import hg2 from './assets/hangman/hg2.jpg';
import hg3 from './assets/hangman/hg3.jpg';
import hg4 from './assets/hangman/hg4.jpg';
import hg5 from './assets/hangman/hg5.jpg';
import hg6 from './assets/hangman/hg6.jpg';
import hg7 from './assets/hangman/hg7.jpg';
import hg8 from './assets/hangman/hg8.jpg';
import hg9 from './assets/hangman/hg9.jpg';
import hg10 from './assets/hangman/hg10.jpg';

//Puzzle
import puzzle1 from './assets/puzzle/puzzle1.jpg';
import puzzle2 from './assets/puzzle/puzzle2.jpg';
import puzzle3 from './assets/puzzle/puzzle3.jpg';
import puzzle4 from './assets/puzzle/puzzle4.jpg';
import puzzle5 from './assets/puzzle/puzzle5.jpg';
import puzzle6 from './assets/puzzle/puzzle6.jpg';
import puzzle7 from './assets/puzzle/puzzle7.jpg';
import puzzle8 from './assets/puzzle/puzzle8.jpg';
import puzzle9 from './assets/puzzle/puzzle9.jpg';
import puzzle10 from './assets/puzzle/puzzle10.jpg';

//Memory
import memory11 from './assets/memory/memory1.1.jpg';
import memory12 from './assets/memory/memory1.2.jpg';
import memory13 from './assets/memory/memory1.3.jpg';
import memory14 from './assets/memory/memory1.4.jpg';
import memory21 from './assets/memory/memory2.1.jpg';
import memory22 from './assets/memory/memory2.2.jpg';
import memory23 from './assets/memory/memory2.3.jpg';
import memory24 from './assets/memory/memory2.4.jpg';
import memory31 from './assets/memory/memory3.1.jpg';
import memory32 from './assets/memory/memory3.2.jpg';
import memory33 from './assets/memory/memory3.3.jpg';
import memory34 from './assets/memory/memory3.4.jpg';
import memory41 from './assets/memory/memory4.1.jpg';
import memory42 from './assets/memory/memory4.2.jpg';
import memory43 from './assets/memory/memory4.3.jpg';
import memory44 from './assets/memory/memory4.4.jpg';
import memory51 from './assets/memory/memory5.1.jpg';
import memory52 from './assets/memory/memory5.2.jpg';
import memory53 from './assets/memory/memory5.3.jpg';
import memory54 from './assets/memory/memory5.4.jpg';
import memory61 from './assets/memory/memory6.1.jpg';
import memory62 from './assets/memory/memory6.2.jpg';
import memory63 from './assets/memory/memory6.3.jpg';
import memory64 from './assets/memory/memory6.4.jpg';
import memory71 from './assets/memory/memory7.1.jpg';
import memory72 from './assets/memory/memory7.2.jpg';
import memory73 from './assets/memory/memory7.3.jpg';
import memory74 from './assets/memory/memory7.4.jpg';
import memory81 from './assets/memory/memory8.1.jpg';
import memory82 from './assets/memory/memory8.2.jpg';
import memory83 from './assets/memory/memory8.3.jpg';
import memory84 from './assets/memory/memory8.4.jpg';
import memory91 from './assets/memory/memory9.1.jpg';
import memory92 from './assets/memory/memory9.2.jpg';
import memory93 from './assets/memory/memory9.3.jpg';
import memory94 from './assets/memory/memory9.4.jpg';
import memory101 from './assets/memory/memory10.1.jpg';
import memory102 from './assets/memory/memory10.2.jpg';
import memory103 from './assets/memory/memory10.3.jpg';
import memory104 from './assets/memory/memory10.4.jpg';

export const puzzleGames = [
    puzzle1,
    puzzle2,
    puzzle3,
    puzzle4,
    puzzle5,
    puzzle6,
    puzzle7,
    puzzle8,
    puzzle9,
    puzzle10
];

export const memoryGames = [
    {
        id: 1,
        assets: [memory11, memory12, memory13, memory14]
    },
    {
        id: 2,
        assets: [memory21, memory22, memory23, memory24]
    },
    {
        id: 3,
        assets: [memory31, memory32, memory33, memory34]
    },
    {
        id: 4,
        assets: [memory41, memory42, memory43, memory44]
    },
    {
        id: 5,
        assets: [memory51, memory52, memory53, memory54]
    },
    {
        id: 6,
        assets: [memory61, memory62, memory63, memory64]
    },
    {
        id: 7,
        assets: [memory71, memory72, memory73, memory74]
    },
    {
        id: 8,
        assets: [memory81, memory82, memory83, memory84]
    },
    {
        id: 9,
        assets: [memory91, memory92, memory93, memory94]
    },
    {
        id: 10,
        assets: [memory101, memory102, memory103, memory104]
    }
];

export const reverseHangmanGames = [
    {
        image: hg1,
        title: '',
        clue: `______ sunt mult mai plăcute când nu se simte miros de fum. `,
        word: 'îmbrățișările',
        maxGuesses: 3,
        onWin: () => {},
        onLose: () => {}
    },
    {
        image: hg2,
        title: '',
        clue: `Întâlnirile sunt mult mai interesante atunci când nu sunt ________ de pauzele pentru fumat.`,
        word: 'întrerupte',
        maxGuesses: 3,
        onWin: () => {},
        onLose: () => {}
    },
    {
        image: hg3,
        title: '',
        clue: `Introdu-ți prietenii fumători în lumea IQOS și oferă-le _____ schimbării.`,
        word: 'darul',
        maxGuesses: 3,
        onWin: () => {},
        onLose: () => {}
    },
    {
        image: hg4,
        title: '',
        clue: `Un gest de empatie față de cei din jur e să _______ la fumatul tradițional.`,
        word: 'renunți',
        maxGuesses: 3,
        onWin: () => {},
        onLose: () => {}
    },
    {
        image: hg5,
        title: '',
        clue: `Îmbrățișările sunt mult mai plăcute când nu se simte _____ de fum.`,
        word: 'miros',
        maxGuesses: 3,
        onWin: () => {},
        onLose: () => {}
    },
    {
        image: hg6,
        title: '',
        clue: `Un gest pe care cei dragi îl vor aprecia cu siguranță este să renunți la _______ tradițional.`,
        word: 'fumatul',
        maxGuesses: 3,
        onWin: () => {},
        onLose: () => {}
    },
    {
        image: hg7,
        title: '',
        clue: `E mult mai ușor să ________ cu ceilalți atunci când elimini bariera mirosului de fum.`,
        word: 'comunici',
        maxGuesses: 3,
        onWin: () => {},
        onLose: () => {}
    },
    {
        image: hg8,
        title: '',
        clue: `Sesiunile de lucru în echipă sunt mai __________ când nu sunt întrerupte de pauzele pentru fumat.`,
        word: 'productive',
        maxGuesses: 3,
        onWin: () => {},
        onLose: () => {}
    },
    {
        image: hg9,
        title: '',
        clue: `Introdu-ți _________ fumători în lumea IQOS și oferă-le darul schimbării.`,
        word: 'prietenii',
        maxGuesses: 3,
        onWin: () => {},
        onLose: () => {}
    },
    {
        image: hg10,
        title: '',
        clue: `Întâlnirile sunt mult mai interesante atunci când nu sunt întrerupte de _______ pentru fumat.`,
        word: 'pauzele',
        maxGuesses: 3,
        onWin: () => {},
        onLose: () => {}
    }
];

const dragCardGameData = [
    //week 1
    [
        {
            region: {
                image: require('./assets/dragcardgame/cadouri.jpg'),
                imageGuessed: require('./assets/dragcardgame/cadouriCorrect.jpg'),
                text: ''
            },
            card: {
                image: require('./assets/dragcardgame/draggable/cadouriCircle.png'),
                text: 'Cadouri'
            }
        },
        {
            region: {
                image: require('./assets/dragcardgame/imbratisari.jpg'),
                imageGuessed: require('./assets/dragcardgame/imbratisariCorrect.jpg'),
                text: ''
            },
            card: {
                image: require('./assets/dragcardgame/draggable/imbratisari.png'),
                text: 'Îmbrățișări'
            }
        },
        {
            region: {
                image: require('./assets/dragcardgame/mesaje.jpg'),
                imageGuessed: require('./assets/dragcardgame/mesajeCorrect.jpg'),
                text: ''
            },
            card: {
                image: require('./assets/dragcardgame/draggable/mesajeRomantice.png'),
                text: 'Mesaje romantice'
            }
        },
        {
            region: {
                image: require('./assets/dragcardgame/timpImpreuna.jpg'),
                imageGuessed: require('./assets/dragcardgame/timpImpreunaCorrect.jpg'),
                text: ''
            },
            card: {
                image: require('./assets/dragcardgame/draggable/timpImpreuna.png'),
                text: 'Timp petrecut împreună'
            }
        }
    ],
    //week 2
    [
        {
            region: {
                image: require('./assets/dragcardgame/micdejun.jpg'),
                imageGuessed: require('./assets/dragcardgame/micdejunCorrect.jpg'),
                text: ''
            },
            card: {
                image: require('./assets/dragcardgame/draggable/micDejun.png'),
                text: 'Mic dejun în pat'
            }
        },
        {
            region: {
                image: require('./assets/dragcardgame/saruturi.jpg'),
                imageGuessed: require('./assets/dragcardgame/saruturiCorrect.jpg'),
                text: ''
            },
            card: {
                image: require('./assets/dragcardgame/draggable/saruturi.png'),
                text: 'Săruturi'
            }
        },
        {
            region: {
                image: require('./assets/dragcardgame/picnic.jpg'),
                imageGuessed: require('./assets/dragcardgame/picnicCorrect.jpg'),
                text: ''
            },
            card: {
                image: require('./assets/dragcardgame/draggable/picnic.png'),
                text: 'Picnic în natură'
            }
        },
        {
            region: {
                image: require('./assets/dragcardgame/complimente.jpg'),
                imageGuessed: require('./assets/dragcardgame/complimenteCorrect.jpg'),
                text: ''
            },
            card: {
                image: require('./assets/dragcardgame/draggable/complimente.png'),
                text: 'Complimente'
            }
        }
    ],
    //week 3
    [
        {
            region: {
                image: require('./assets/dragcardgame/week3/petreceri.jpg'),
                imageGuessed: require('./assets/dragcardgame/week3/petreceriCorrect.jpg'),
                text: ''
            },
            card: {
                image: require('./assets/dragcardgame/draggable/week3/petreceri.png'),
                text: 'Petreceri'
            }
        },
        {
            region: {
                image: require('./assets/dragcardgame/week3/drumetii.jpg'),
                imageGuessed: require('./assets/dragcardgame/week3/drumetiiCorrect.jpg'),
                text: ''
            },
            card: {
                image: require('./assets/dragcardgame/draggable/week3/drumetii.png'),
                text: 'Drumeții'
            }
        },
        {
            region: {
                image: require('./assets/dragcardgame/week3/seriDeFilm.jpg'),
                imageGuessed: require('./assets/dragcardgame/week3/seriDeFilmCorrect.jpg'),
                text: ''
            },
            card: {
                image: require('./assets/dragcardgame/draggable/week3/seriDeFilm.png'),
                text: 'Seri de film'
            }
        },
        {
            region: {
                image: require('./assets/dragcardgame/week3/cafea.jpg'),
                imageGuessed: require('./assets/dragcardgame/week3/cafeaCorrect.jpg'),
                text: ''
            },
            card: {
                image: require('./assets/dragcardgame/draggable/week3/cafea.png'),
                text: 'Discuții la cafea'
            }
        }
    ],
    //week 4
    [
        {
            region: {
                image: require('./assets/dragcardgame/week4/dans.jpg'),
                imageGuessed: require('./assets/dragcardgame/week4/dansCorrect.jpg'),
                text: ''
            },
            card: {
                image: require('./assets/dragcardgame/draggable/week4/dans.png'),
                text: 'Dans'
            }
        },
        {
            region: {
                image: require('./assets/dragcardgame/week4/calatorii.jpg'),
                imageGuessed: require('./assets/dragcardgame/week4/calatoriiCorrect.jpg'),
                text: ''
            },
            card: {
                image: require('./assets/dragcardgame/draggable/week4/calatorii.png'),
                text: 'Călătorii'
            }
        },
        {
            region: {
                image: require('./assets/dragcardgame/week4/imbratisari.jpg'),
                imageGuessed: require('./assets/dragcardgame/week4/imbratisariCorrect.jpg'),
                text: ''
            },
            card: {
                image: require('./assets/dragcardgame/draggable/week4/imbratisari.png'),
                text: 'Îmbrățișări'
            }
        },
        {
            region: {
                image: require('./assets/dragcardgame/week4/concerte.jpg'),
                imageGuessed: require('./assets/dragcardgame/week4/concerteCorrect.jpg'),
                text: ''
            },
            card: {
                image: require('./assets/dragcardgame/draggable/week4/concerte.png'),
                text: 'Concerte'
            }
        }
    ],
    //week5
    [
        {
            region: {
                image: require('./assets/dragcardgame/week5/cafea.jpg'),
                imageGuessed: require('./assets/dragcardgame/week5/cafeaCorrect.jpg'),
                text: ''
            },
            card: {
                image: require('./assets/dragcardgame/draggable/week5/cafea.png'),
                text: 'Discuții la cafea'
            }
        },
        {
            region: {
                image: require('./assets/dragcardgame/week5/imbratisari.jpg'),
                imageGuessed: require('./assets/dragcardgame/week5/imbratisariCorrect.jpg'),
                text: ''
            },
            card: {
                image: require('./assets/dragcardgame/draggable/week5/imbratisari.png'),
                text: 'Îmbrățișări'
            }
        },
        {
            region: {
                image: require('./assets/dragcardgame/week5/mesaje.jpg'),
                imageGuessed: require('./assets/dragcardgame/week5/mesajeCorrect.jpg'),
                text: ''
            },
            card: {
                image: require('./assets/dragcardgame/draggable/week5/mesaje.png'),
                text: 'Mesaje'
            }
        },
        {
            region: {
                image: require('./assets/dragcardgame/week5/relaxare.jpg'),
                imageGuessed: require('./assets/dragcardgame/week5/relaxareCorrect.jpg'),
                text: ''
            },
            card: {
                image: require('./assets/dragcardgame/draggable/week5/relaxare.png'),
                text: 'Relaxare'
            }
        }
    ],

    // week 6

    [
        {
            region: {
                image: require('./assets/dragcardgame/week6/cadouri.jpg'),
                imageGuessed: require('./assets/dragcardgame/week6/cadouriCorrect.jpg'),
                text: ''
            },
            card: {
                image: require('./assets/dragcardgame/draggable/week6/cadouri.png'),
                text: 'Cadouri'
            }
        },
        {
            region: {
                image: require('./assets/dragcardgame/week6/excursii.jpg'),
                imageGuessed: require('./assets/dragcardgame/week6/excursiiCorrect.jpg'),
                text: ''
            },
            card: {
                image: require('./assets/dragcardgame/draggable/week6/excursii.png'),
                text: 'Excursii împreună'
            }
        },
        {
            region: {
                image: require('./assets/dragcardgame/week6/film.jpg'),
                imageGuessed: require('./assets/dragcardgame/week6/filmCorrect.jpg'),
                text: ''
            },
            card: {
                image: require('./assets/dragcardgame/draggable/week6/film.png'),
                text: 'Seri de film'
            }
        },
        {
            region: {
                image: require('./assets/dragcardgame/week6/natura.jpg'),
                imageGuessed: require('./assets/dragcardgame/week6/naturaCorrect.jpg'),
                text: ''
            },
            card: {
                image: require('./assets/dragcardgame/draggable/week6/natura.png'),
                text: 'Ieșiri în natură'
            }
        }
    ],

    // week 7

    [
        {
            region: {
                image: require('./assets/dragcardgame/week7/design.jpg'),
                imageGuessed: require('./assets/dragcardgame/week7/designCorrect.jpg'),
                text: ''
            },
            card: {
                image: require('./assets/dragcardgame/draggable/week7/design.png'),
                text: 'Design'
            }
        },
        {
            region: {
                image: require('./assets/dragcardgame/week7/pictura.jpg'),
                imageGuessed: require('./assets/dragcardgame/week7/picturaCorrect.jpg'),
                text: ''
            },
            card: {
                image: require('./assets/dragcardgame/draggable/week7/pictura.png'),
                text: 'Pictură'
            }
        },
        {
            region: {
                image: require('./assets/dragcardgame/week7/muzica.jpg'),
                imageGuessed: require('./assets/dragcardgame/week7/muzicaCorrect.jpg'),
                text: ''
            },
            card: {
                image: require('./assets/dragcardgame/draggable/week7/muzica.png'),
                text: 'Muzică'
            }
        },
        {
            region: {
                image: require('./assets/dragcardgame/week7/fotografie.jpg'),
                imageGuessed: require('./assets/dragcardgame/week7/fotografieCorrect.jpg'),
                text: ''
            },
            card: {
                image: require('./assets/dragcardgame/draggable/week7/fotografie.png'),
                text: 'Fotografie'
            }
        }
    ],

    // week 8
    [
        {
            region: {
                image: require('./assets/dragcardgame/week8/incurajari.jpg'),
                imageGuessed: require('./assets/dragcardgame/week8/incurajariCorrect.jpg'),
                text: ''
            },
            card: {
                image: require('./assets/dragcardgame/draggable/week8/incurajari.png'),
                text: 'Încurajări'
            }
        },
        {
            region: {
                image: require('./assets/dragcardgame/week8/timpImpreuna.jpg'),
                imageGuessed: require('./assets/dragcardgame/week8/timpImpreunaCorrect.jpg'),
                text: ''
            },
            card: {
                image: require('./assets/dragcardgame/draggable/week8/timp.png'),
                text: 'Timp împreună'
            }
        },
        {
            region: {
                image: require('./assets/dragcardgame/week8/cafea.jpg'),
                imageGuessed: require('./assets/dragcardgame/week8/cafeaCorrect.jpg'),
                text: ''
            },
            card: {
                image: require('./assets/dragcardgame/draggable/week8/cafea.png'),
                text: 'Întâlniri la cafea'
            }
        },
        {
            region: {
                image: require('./assets/dragcardgame/week8/reusite.jpg'),
                imageGuessed: require('./assets/dragcardgame/week8/reusiteCorrect.jpg'),
                text: ''
            },
            card: {
                image: require('./assets/dragcardgame/draggable/week8/reusite.png'),
                text: 'Sărbătorirea reușitelor'
            }
        }
    ],

    // week 9

    [
        {
            region: {
                image: require('./assets/dragcardgame/week9/cadou.png'),
                imageGuessed: require('./assets/dragcardgame/week9/cadouriSpecialeCorrect.jpg'),
                text: ''
            },
            card: {
                image: require('./assets/dragcardgame/draggable/week9/cadouIcon.png'),
                text: 'Cadouri speciale'
            }
        },
        {
            region: {
                image: require('./assets/dragcardgame/week9/citit.png'),
                imageGuessed: require('./assets/dragcardgame/week9/experienteCorrect.jpg'),
                text: ''
            },
            card: {
                image: require('./assets/dragcardgame/draggable/week9/experiente.png'),
                text: 'Experiențe'
            }
        },
        {
            region: {
                image: require('./assets/dragcardgame/week9/concert.png'),
                imageGuessed: require('./assets/dragcardgame/week9/evenimenteCorrect.jpg'),
                text: ''
            },
            card: {
                image: require('./assets/dragcardgame/draggable/week9/evenimenteExclusive.png'),
                text: 'Evenimente exclusive'
            }
        },
        {
            region: {
                image: require('./assets/dragcardgame/week9/stardrift.png'),
                imageGuessed: require('./assets/dragcardgame/week9/editiiCorrect.jpg'),
                text: ''
            },
            card: {
                image: require('./assets/dragcardgame/draggable/week9/editiiLimitate.png'),
                text: 'Ediții limitate'
            }
        }
    ],

    // week 10

    [
        {
            region: {
                image: require('./assets/dragcardgame/week10/degete.jpg'),
                imageGuessed: require('./assets/dragcardgame/week10/legaturiCorrect.jpg'),
                text: ''
            },
            card: {
                image: require('./assets/dragcardgame/draggable/week10/noiLegaturi.png'),
                text: 'Noi legături'
            }
        },
        {
            region: {
                image: require('./assets/dragcardgame/week10/telefon.jpg'),
                imageGuessed: require('./assets/dragcardgame/week10/concursuriCorrect.jpg'),
                text: ''
            },
            card: {
                image: require('./assets/dragcardgame/draggable/week10/concursuriDedicate.png'),
                text: 'Concursuri dedicate'
            }
        },
        {
            region: {
                image: require('./assets/dragcardgame/week10/pachet.jpg'),
                imageGuessed: require('./assets/dragcardgame/week10/noutatiCorrect.jpg'),
                text: ''
            },
            card: {
                image: require('./assets/dragcardgame/draggable/week10/prioritateNoutati.png'),
                text: 'Prioritate la descoperirea noutăților'
            }
        },
        {
            region: {
                image: require('./assets/dragcardgame/week10/bagaje.png'),
                imageGuessed: require('./assets/dragcardgame/week10/experienteCorrect.jpg'),
                text: ''
            },
            card: {
                image: require('./assets/dragcardgame/draggable/week10/experienteUnice.png'),
                text: 'Experiențe unice'
            }
        }
    ]
];

const choiceGameData = [
    {
        image: require('./assets/choicegame/week1.png'),
        slug: 'connections-game-week-1',
        multiple: false
    },
    {
        image: require('./assets/choicegame/week2.png'),
        slug: 'connections-game-week-2',
        multiple: false
    },
    {
        image: require('./assets/choicegame/week3.png'),
        slug: 'connections-game-week-3',
        multiple: true
    },
    {
        image: require('./assets/choicegame/week4.png'),
        slug: 'connections-game-week-4',
        multiple: true
    },
    {
        image: require('./assets/choicegame/week5.png'),
        slug: 'connections-game-week-5',
        multiple: true
    },
    {
        image: require('./assets/choicegame/week6.png'),
        slug: 'connections-game-week-6',
        multiple: true
    },
    {
        image: require('./assets/choicegame/week7.png'),
        slug: 'connections-game-week-7',
        multiple: true
    },
    {
        image: require('./assets/choicegame/week8.png'),
        slug: 'connections-game-week-8',
        multiple: true
    },
    {
        image: require('./assets/choicegame/week9.png'),
        slug: 'connections-game-week-9',
        multiple: true
    },
    {
        image: require('./assets/choicegame/week10.png'),
        slug: 'connections-game-week-10',
        multiple: true
    }
];

const quizGamesVideo = [
    {
        id: 26,
        video: '910712032',
        title: 'Maria Danciu',
        quizQuestions: {
            question: 'IQOS a făcut ca toate momentele noastre împreună să fie mult mai:',
            correct_answer: 'Plăcute',
            options: [
                {
                    id: 1,
                    value: 'Memorabile'
                },
                {
                    id: 2,
                    value: 'Plăcute'
                },
                {
                    id: 3,
                    value: 'Distractive'
                }
            ]
        },
        onWin: () => {},
        onLose: () => {}
    },
    {
        id: 28,
        video: '910712032',
        title: 'Maria Danciu',
        quizQuestions: {
            question:
                'Îmbrățișările, în lipsa mirosului neplăcut de fum, au devenit un moment de __________ autentică de care ne bucurăm în fiecare zi.',
            correct_answer: 'conexiune',
            options: [
                {
                    id: 1,
                    value: 'inspirație'
                },
                {
                    id: 2,
                    value: 'conexiune'
                },
                {
                    id: 3,
                    value: 'relaxare'
                }
            ]
        },
        onWin: () => {},
        onLose: () => {}
    },

    {
        id: 29,
        video: '912607078',
        title: 'Alina Mareș',
        quizQuestions: {
            question:
                'Nu mai întrerup momentele în care suntem împreună și nu-mi deranjez prietenii nefumători cu:',
            correct_answer: 'fum de țigară',
            options: [
                {
                    id: 1,
                    value: 'muzica'
                },
                {
                    id: 2,
                    value: 'fum de țigară'
                },
                {
                    id: 3,
                    value: 'povești plictisitoare'
                }
            ]
        },
        onWin: () => {},
        onLose: () => {}
    },

    {
        id: 29,
        video: '912607078',
        title: 'Alina Mareș',
        quizQuestions: {
            question:
                'Cu IQOS nu ne mai punem problema locului în care ne vedem și nici nu mai întrerupem experiența cu:',
            correct_answer: 'Pauzele de fumat',
            options: [
                {
                    id: 1,
                    value: 'Apelurile telefonice'
                },
                {
                    id: 2,
                    value: 'Pauzele de fumat'
                },
                {
                    id: 3,
                    value: 'Dezbaterile inutile'
                }
            ]
        },
        onWin: () => {},
        onLose: () => {}
    },
    //week5
    {
        id: 30,
        video: '916748747',
        title: 'Cristina Băneș',
        quizQuestions: {
            question: 'Ea îmi povestește aventuri din tinerețe, iar eu o ascult fără:',
            correct_answer: 'Întreruperi',
            options: [
                {
                    id: 1,
                    value: 'Probleme'
                },
                {
                    id: 2,
                    value: 'Întreruperi'
                },
                {
                    id: 3,
                    value: 'Comentarii'
                }
            ]
        },
        onWin: () => {},
        onLose: () => {}
    },
    //week 6
    {
        id: 31,
        video: '916748747',
        title: 'Cristina Băneș',
        quizQuestions: {
            question: 'IQOS îmi permite să mă bucur mai mult de fiecare vizită:',
            correct_answer: 'Pe care i-o fac bunicii',
            options: [
                {
                    id: 1,
                    value: 'La muzeu'
                },
                {
                    id: 2,
                    value: 'Pe care i-o fac bunicii'
                },
                {
                    id: 3,
                    value: 'Neașteptată'
                }
            ]
        },
        onWin: () => {},
        onLose: () => {}
    },
    //week 7
    {
        id: 32,
        video: '920080493',
        title: 'Ioana Barbu',
        quizQuestions: {
            question: 'IQOS mi-a permis să creez momente de conexiune:',
            correct_answer: 'Cu mentorul meu',
            options: [
                {
                    id: 1,
                    value: 'În IQOS CLUB'
                },
                {
                    id: 2,
                    value: 'Cu mentorul meu'
                },
                {
                    id: 3,
                    value: 'Autentică'
                }
            ]
        },
        onWin: () => {},
        onLose: () => {}
    },
    //week 8
    {
        id: 33,
        video: '920080493',
        title: 'Ioana Barbu',
        quizQuestions: {
            question: 'Mă bucur de IQOS-ul meu, iar uneori mentorul meu:',
            correct_answer: 'Mi se alătură',
            options: [
                {
                    id: 1,
                    value: 'Mă evită'
                },
                {
                    id: 2,
                    value: 'Mi se alătură'
                },
                {
                    id: 3,
                    value: 'Îmi oferă feedback'
                }
            ]
        },
        onWin: () => {},
        onLose: () => {}
    },
    //week 9
    {
        id: 34,
        video: '924911911',
        title: 'Adrian Acatrini',
        quizQuestions: {
            question: 'Dacă nu treceam la IQOS, nu aveam ocazia să întâlnesc atâția:',
            correct_answer: 'Oameni minunați',
            options: [
                {
                    id: 1,
                    value: 'Specialiști în domeniu'
                },
                {
                    id: 2,
                    value: 'Oameni minunați'
                },
                {
                    id: 3,
                    value: 'Actori talentați'
                }
            ]
        },
        onWin: () => {},
        onLose: () => {}
    },
    //week 10
    {
        id: 35,
        video: '924911911',
        title: 'Adrian Acatrini',
        quizQuestions: {
            question: '_______ și ne revedem cu fiecare ocazie pe care ne-o oferă IQOS.',
            correct_answer: 'Păstrăm legătura',
            options: [
                {
                    id: 1,
                    value: 'Ne distrăm'
                },
                {
                    id: 2,
                    value: 'Păstrăm legătura'
                },
                {
                    id: 3,
                    value: 'Ne scriem scrisori'
                }
            ]
        },
        onWin: () => {},
        onLose: () => {}
    }
];

const matchThreeGameSkin = [5, 5, 6, 6, 7, 7, 8, 8, 9, 9, 10, 10]; // each item is a week skin [we have one skin per two weeks (per phase)]

const Games: (week: number) => {
    'real-connection-guess-the-word': IReverseHangmanCallForDreamsGame;
    'real-connection-puzzle': IPuzzleCallForDreamsGame;
    'real-connection-candy-crush': IMatchThreeCallForDreamsGame;
    'real-connection-memory-game': IMemoryCallForDreamsGame;
    'real-connection-match-game': IDragCardGameRealPeople;
    'real-connection-multiple-choice': IQuizConnectionsGame;
    'real-connection-video-quiz': IQuizGameVideoRealPeople;
} = (week: number) => {
    return {
        'real-connection-guess-the-word': {
            title: 'Sfaturi de conexiune',
            introText: (
                <>
                    <img src={headerGif} alt="headerGif" className="headerGif" />
                    <p>
                        <strong>
                            Completează cuvântul lipsă din propoziție și descoperă un sfat de
                            conexiune.
                        </strong>
                        <br /> Ai la dispoziție 3 încercări/zi.
                    </p>
                    <p>
                        <strong>Mult succes!</strong>
                    </p>
                </>
            ),
            game: ReverseHangman,
            gameConfig: reverseHangmanGames[week]
        },
        'real-connection-puzzle': {
            title: 'Puzzle-ul conexiunii',
            introText: (
                <>
                    <img src={headerGif} alt="headerGif" className="headerGif" />
                    <p>
                        <strong>
                            Așază corect piesele de puzzle pentru a compune imaginea unui moment de
                            conexiune.
                        </strong>
                        <br /> Ai la dispoziție 3 minute și 3 încercări în fiecare zi.
                    </p>
                    <p>
                        <strong>Mult succes!</strong>
                    </p>
                </>
            ),
            game: Puzzle,
            gameConfig: {
                image: puzzleGames[week],
                time: 3 * 60 * 1000,
                rowsAndColumns: [3, 3],
                onWin: () => {},
                onLose: () => {}
            }
        },
        'real-connection-memory-game': {
            title: 'Conexiuni pereche',
            introText: (
                <>
                    <img src={headerGif} alt="headerGif" className="headerGif" />
                    <p>
                        <strong>Descoperă perechile celor 4 momente de conexiune.</strong>
                        <br /> Ai la dispoziție 3 încercări în fiecare zi.
                    </p>

                    <p>
                        <strong>Mult succes!</strong>
                    </p>
                </>
            ),
            game: MemoryGame,
            gameConfig: {
                cards: memoryGames[week].assets,
                maxTries: 10,
                onWin: () => {},
                onLose: () => {}
            }
        },
        'real-connection-candy-crush': {
            title: 'Momente de conexiune',
            introText: (
                <>
                    <img src={headerGif} alt="headerGif" className="headerGif" />
                    <p>
                        <strong>Obține linii de minimum 3 momente de conexiune identice.</strong>
                        <br />
                        Grupează cât mai multe seturi de 3 pentru a obține un scor mai bun.
                    </p>
                    <p>
                        <strong>Mult succes!</strong>
                    </p>
                </>
            ),
            game: MatchThreeGame,
            gameConfig: {
                winningScore: 1500,
                time: 2 * 60 * 1000,
                onWin: () => {},
                onLose: () => {},
                game: matchThreeGameSkin[week] as any
            }
        },
        'real-connection-match-game': {
            title: 'Potriviri perfecte',
            introText: (
                <>
                    <img src={headerGif} alt="headerGif" className="headerGif" />
                    <p>
                        <strong>Asociază momentul de conexiune cu imaginea potrivită.</strong>
                        <br /> Doar o singură variantă este corectă. <br />
                        Ai la dispoziție 3 încercări/zi.
                    </p>
                    <p>
                        <strong>Mult succes!</strong>
                    </p>
                </>
            ),
            game: DragCardGame,
            gameConfig: {
                data: dragCardGameData[week],
                onWin: () => {},
                onLose: () => {}
            }
        },
        //! Pending :D
        'real-connection-multiple-choice': {
            title: 'Conexiuni pe placul tău',
            introText: (
                <>
                    <img src={headerGif} alt="headerGif" className="headerGif" />
                    {choiceGameData[week]?.multiple ? (
                        <p>
                            <strong>Răspunde sincer la întrebarea zilei</strong> și vezi cât de mult
                            te asemeni cu alți membri din comunitate.
                        </p>
                    ) : (
                        <p>
                            <strong>Alege răspunsul corect la întrebare.</strong> <br /> Ai la
                            dispoziție 3 încercări/zi.
                        </p>
                    )}
                    <p>
                        <strong>Mult succes!</strong>
                    </p>
                </>
            ),
            previousWeekIntroText: (
                <>
                    <p>
                        <strong>Alege răspunsul corect la întrebare.</strong> <br /> Ai la
                        dispoziție o încercare. Succes!
                    </p>
                </>
            ),
            game: QuizGame,
            gameConfig: {
                image: choiceGameData[week]?.image,
                quizSlug: choiceGameData[week]?.slug,
                multiple: choiceGameData[week]?.multiple,
                onWin: () => {},
                onLose: () => {}
            }
        },
        'real-connection-video-quiz': {
            title: 'Quizul conexiunii',
            introText: (
                <>
                    <img src={headerGif} alt="headerGif" className="headerGif" />
                    <p>
                        <strong>
                            Urmărește clipul video și răspunde corect la întrebarea de la final.
                        </strong>
                        <br />
                        Ai 3 încercări la dispoziție.
                    </p>
                    <p>
                        <strong>Mult succes!</strong>
                    </p>
                </>
            ),
            game: QuizGameVideo,
            gameConfig: quizGamesVideo[week]
        }
    };
};

export default Games;
