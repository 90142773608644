import React, { useState } from 'react';
import styles from './IqosSeaSide.module.scss';
import classnames from 'classnames';

// Components
import ArticleHeader from '@containers/IQOSUniverse/components/ArticleHeader';
import VideoModalQuiz from '@containers/IQOSUniverse/components/VideoModalQuiz';
import Button from '@components/Layout/Button';
import BaseModal from '@components/Layout/BaseModal';
import MediaGallery from '@containers/IQOSUniverse/components/MediaGallery';

// Assets
import pins from './assets/pins.svg';
import img01 from './assets/gallery/img1.jpg';
import img02 from './assets/gallery/img2.jpg';
import img03 from './assets/gallery/img3.jpg';
import img04 from './assets/gallery/img4.jpg';
import img05 from './assets/gallery/img5.jpg';
import img06 from './assets/gallery/img6.jpg';
import img07 from './assets/gallery/img7.jpg';
import img08 from './assets/gallery/img8.jpg';
import img09 from './assets/gallery/img9.jpg';
import img10 from './assets/gallery/img10.jpg';
import img11 from './assets/gallery/img11.jpg';
import img12 from './assets/gallery/img12.jpg';
import img13 from './assets/gallery/img13.jpg';
import img14 from './assets/gallery/img14.jpg';
import img15 from './assets/gallery/img15.jpg';
import img16 from './assets/gallery/img16.jpg';
import img17 from './assets/gallery/img17.jpg';
import img18 from './assets/gallery/img18.jpg';
import img19 from './assets/gallery/img19.jpg';
import img20 from './assets/gallery/img20.jpg';
import img21 from './assets/gallery/img21.jpg';
import img22 from './assets/gallery/img22.jpg';

// Redux
import { useAppSelector } from '@app/hooks';
import CompletedStateArticle from '@containers/IQOSUniverse/components/CompletedStateArticle/CompletedStateArticle';

const IqosSeaSide = () => {
    const { article } = useAppSelector((state) => state.iqosUniverse);

    const [toggleModal, setToggleModal] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showMapModal, setMapModal] = useState(false);

    return (
        <section className={styles.wrapper}>
            <ArticleHeader
                videoQuiz={() => {
                    setToggleModal(true);
                }}
            />
            <VideoModalQuiz
                toggleModal={toggleModal}
                setToggleModal={() => setToggleModal(false)}
                setShowSuccess={() => {
                    setShowSuccess(true);
                }}
            />

            <div className="content">
                <p>
                    Vara a început, iar odată cu ea și surprizele IQOS pe care le meriți, chiar și
                    în vacanță.
                </p>
                <p>
                    Oriunde ai merge pe litoralul românesc, noi te vom ajuta să trăiești vara la
                    următorul nivel.
                </p>

                <p>
                    Mamaia, Costinești, Vama Veche, 2 Mai, dar și Olimp, Neptun și Eforie sunt
                    stațiunile în care te așteaptă surprize atât pe plajă, cât și în locațiile
                    partenere.
                </p>
            </div>

            <div className={styles.mapContainer}>
                <div className={styles.imageContainer}>
                    <img src={pins} alt="pins" />
                </div>

                <div className={styles.details}>
                    <h3>Verifică harta de mai jos ca să descoperi unde ne găsești:</h3>
                    <Button
                        type="solid"
                        color="dark"
                        hover="turquoise"
                        label="Deschide harta"
                        onClick={() => setMapModal(true)}
                    />
                </div>
            </div>

            <MediaGallery
                images={[
                    img01,
                    img02,
                    img03,
                    img04,
                    img05,
                    img06,
                    img07,
                    img08,
                    img09,
                    img10,
                    img11,
                    img12,
                    img13,
                    img14,
                    img15,
                    img16,
                    img17,
                    img18,
                    img19,
                    img20,
                    img21,
                    img22
                ]}
            />

            <div className={classnames('content', styles.lastContent)}>
                <p>
                    <strong>Nu uita! </strong>
                    Atunci când ești membru în comunitatea IQOS Club, poți fi oriunde și oricând
                    surprins cu un beneficiu exclusiv.
                </p>
            </div>

            {(showSuccess || article.completed_quiz) && (
                <CompletedStateArticle
                    className={styles.completedStateArticle}
                    article={{
                        points: article.points,
                        quiz_points: article.quiz_points,
                        quiz_end_date: article.quiz_end_date
                    }}
                />
            )}

            <BaseModal
                size="large"
                className={styles.mapModal}
                isModalOpen={showMapModal}
                closeModal={() => setMapModal(false)}
                destroyOnClose
                lockBodyScroll={false}
            >
                <section className={styles.mapModalContainer}>
                    <iframe
                        src="https://www.google.com/maps/d/u/0/embed?mid=14TjFrSK8cViC2aodJ1jz4I1HM3kO3f0&ehbc=2E312F%22&ll=44.02727215407445%2C28.606457150000008&z=10"
                        width="640"
                        height="480"
                    ></iframe>
                </section>
            </BaseModal>
        </section>
    );
};

export default IqosSeaSide;
