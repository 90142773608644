import styles from './FiitOptions.module.scss';

// Helpers
import classNames from 'classnames';

// Assets
import marine from './assets/marine.svg';
import regular from './assets/regular.svg';

// Components
import ArticleSlider from '@containers/IQOSUniverse/components/ArticleSlider';
import ArticleHeader from '@containers/IQOSUniverse/components/ArticleHeader';
import ArticleContainer from '@containers/IQOSUniverse/components/ArticleContainer';

const FiitOptions = () => {
    const slides = [
        {
            image: regular,
            belowDescription: {
                title: 'Fiit Regular',
                subtitle: 'Amestec echilibrat de tutun aromatic.',
                paragraph: 'Gust clasic de tutun cu note subtile de nucă.'
            }
        },
        {
            image: marine,
            belowDescription: {
                title: 'Fiit Marine',
                subtitle: 'Amestec de tutun cu note de mentol.',
                paragraph: 'Amestec de tutun cu note răcoritoare de mentol'
            }
        }
    ];

    return (
        <section className={styles.wrapper}>
            <ArticleHeader />

            <div className="content">
                <p>
                    Făcute din tutun adevărat, rezervele Fiit pentru lil SOLID 2.0 sunt disponibile
                    în două variante diferite: cea din amestec de tutun aromatic și cea cu note de
                    mentol.
                </p>

                <p>
                    Pentru că ești membru în IQOS Club, poți revendica pachete de rezerve Fiit
                    folosind punctele din cont. În plus, te poti bucura și de ofertele speciale din
                    IQOS Shop.
                </p>
            </div>

            <div className={styles.articleWrapper}>
                <ArticleContainer background={'#F6F4F0'}>
                    <div className={classNames(styles.contentSubtitle, 'content')}>
                        <h3>Descoperă cele două variante Fiit!</h3>

                        <ArticleSlider
                            fullWidthImage
                            imageBackgroundColor="transparent"
                            imageObjectFit="contain"
                            belowDescription
                            bottomButtons
                            turquoiseArrows
                            slides={slides}
                        />

                        <p className={styles.bottomText}>
                            În portofoliul Fiit vor fi adăugate noi arome. Urmărește-ne pentru a fi
                            la curent cu cele mai noi apariții.
                        </p>
                    </div>
                </ArticleContainer>
            </div>
        </section>
    );
};

export default FiitOptions;
