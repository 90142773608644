import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosRequest from '@utils/axios';
import { keyBy, merge, shuffle } from 'lodash';

export interface ChallengeWinner {
    winner_id: number;
    winner_dcs_id: number;
    content: { image: string; title: string; video: string; description: string };
    activity_campaign_id: number;
    activity_campaign_slug: string;
    full_name: string;
    avatars: { full: string; thumbnail: string };
    stars: number;
}

interface Selections {
    created_at: string;
    selection_id: number;
    winner: ChallengeWinner;
    winner_id: number;
    has_voted: boolean;
}

interface Stars {
    winner_id: number;
    stars: number;
}
export interface InitialState {
    status: 'idle' | 'success' | 'loading' | 'failed';
    winnersStatus: 'idle' | 'success' | 'loading' | 'failed';
    selectionsStatus: 'idle' | 'success' | 'loading' | 'failed';
    winners: {
        progres: [ChallengeWinner] | [];
        pasiuni: [ChallengeWinner] | [];
    };
    selections: {
        progres: [Selections] | [];
        pasiuni: [Selections] | [];
    };
    played: {
        current_game_slug:
            | 'puzzle'
            | 'memory-game'
            | 'candy-crush'
            | 'hangman'
            | 'video-quiz'
            | 'match-the-dream'
            | 'falling-stars'
            | null;
        has_played_current_game: boolean;
        has_played_this_week: boolean;
    };
    givenStars: {
        progres: number;
        pasiuni: number;
    };
    activeDream: number | undefined;
    chances: {
        final: {
            chances: number;
            eligible: number;
        };
        weekly: {
            chances: number;
            eligible: number;
        };
    };
    error: any;
}

interface AxiosError {
    response: {
        data: {
            message: string;
        };
    };
}

const initialState: InitialState = {
    status: 'idle',
    winnersStatus: 'idle',
    selectionsStatus: 'idle',
    winners: {
        progres: [],
        pasiuni: []
    },
    selections: {
        progres: [],
        pasiuni: []
    },
    played: {
        current_game_slug: null,
        has_played_current_game: false,
        has_played_this_week: false
    },
    givenStars: {
        progres: 0,
        pasiuni: 0
    },
    activeDream: undefined,
    chances: {
        final: { chances: 0, eligible: 0 },
        weekly: { chances: 0, eligible: 0 }
    },
    error: {}
};

const match = (users: ChallengeWinner[], stars: Stars[]) => {
    const starsMap = keyBy(stars, 'winner_id');
    const usersWithStars = users.map((user) =>
        merge({}, user, starsMap[user.winner_id], { stars: starsMap[user.winner_id]?.stars || 0 })
    );

    return usersWithStars || [];
};

export const setDream = createAsyncThunk('callForDreamsPhaseThree/setDream', async (id: number) => {
    try {
        const setDream = await axiosRequest.post(`/real-people/selections/${id}`, {
            game_campaign: 'real-people-third-phase-games'
        });

        return setDream.data;
    } catch (e) {
        return (e as AxiosError).response.data;
    }
});

export const getGivenStars = createAsyncThunk(
    'callForDreamsPhaseThree/getGivenStars',
    async ({ progressID, passionID }: { progressID: number; passionID: number }) => {
        try {
            const [progress, passion] = await Promise.all([
                axiosRequest.get(`/real-people/given-stars/${progressID}`),
                axiosRequest.get(`/real-people/given-stars/${passionID}`)
            ]);

            return {
                progres: progress.data.given_stars,
                pasiuni: passion.data.given_stars
            } as any;
        } catch (e) {
            return (e as AxiosError).response.data;
        }
    }
);

export const getWinners = createAsyncThunk('callForDreamsPhaseThree/getWinners', async () => {
    try {
        const [progress, passion, passionStars, progressStars] = await Promise.all([
            axiosRequest.get('/real-people/winners/real-people-third-phase-progress'),
            axiosRequest.get('/real-people/winners/real-people-third-phase-passion'),
            axiosRequest.get('/real-people/winners/real-people-third-phase-passion/stars'),
            axiosRequest.get('/real-people/winners/real-people-third-phase-progress/stars')
        ]);

        const allStars = [...passionStars.data, ...progressStars.data];

        return {
            progres: shuffle(match(progress.data, allStars)),
            pasiuni: shuffle(match(passion.data, allStars))
        } as any;
    } catch (e) {
        return (e as AxiosError).response.data;
    }
});

export const getSelections = createAsyncThunk('callForDreamsPhaseThree/getSelections', async () => {
    try {
        const [progress, passion] = await Promise.all([
            axiosRequest.get('/real-people/selections/real-people-third-phase-progress'),
            axiosRequest.get('/real-people/selections/real-people-third-phase-passion')
        ]);

        return {
            progres: progress.data,
            pasiuni: passion.data
        } as any;
    } catch (e) {
        return (e as AxiosError).response.data;
    }
});

export const getPlayed = createAsyncThunk('callForDreamsPhaseThree/getPlayed', async () => {
    try {
        const played = await axiosRequest.get(
            '/real-people/games/real-people-third-phase-games/status'
        );

        return played.data;
    } catch (e) {
        return (e as AxiosError).response.data;
    }
});

export const getChances = createAsyncThunk('callForDreamsPhaseThree/getChances', async () => {
    try {
        const played = await axiosRequest.get('/real-people/chances-status');
        const luckyDrawChances = played.data.response.luckyDrawChances;

        return luckyDrawChances;
    } catch (e) {
        return (e as AxiosError).response.data;
    }
});

export const ctrAction = createAsyncThunk(
    'callForDreamsPhaseThree/ctrAction',
    async (dcs_id: number) => {
        try {
            await axiosRequest.post(`user/ctr-action`, {
                action_uuid: `real_people_user_dream_${dcs_id}`
            });
        } catch (e) {
            return (e as AxiosError).response.data;
        }
    }
);

export const callForDreamsPhaseThreeSlice = createSlice({
    name: 'callForDreamsPhaseThree',
    initialState,
    reducers: {
        setActiveDream: (state, action) => {
            state.activeDream = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getWinners.pending, (state) => {
                state.winnersStatus = 'loading';
            })
            .addCase(getWinners.fulfilled, (state, action) => {
                state.winnersStatus = 'idle';
                state.winners = action.payload;
            })
            .addCase(getWinners.rejected, (state, action) => {
                state.winnersStatus = 'failed';
                state.error = action.payload;
            })

            .addCase(getSelections.pending, (state) => {
                state.selectionsStatus = 'loading';
            })
            .addCase(getSelections.fulfilled, (state, action) => {
                state.selectionsStatus = 'idle';
                state.selections = action.payload;
            })
            .addCase(getSelections.rejected, (state, action) => {
                state.selectionsStatus = 'failed';
                state.error = action.payload;
            })

            .addCase(getPlayed.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getPlayed.fulfilled, (state, action) => {
                state.status = 'idle';
                state.played = action.payload;
            })
            .addCase(getPlayed.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })

            .addCase(getGivenStars.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getGivenStars.fulfilled, (state, action) => {
                state.status = 'idle';
                state.givenStars = action.payload;
            })
            .addCase(getGivenStars.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })

            .addCase(setDream.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(setDream.fulfilled, (state) => {
                state.status = 'idle';
            })
            .addCase(setDream.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })

            .addCase(getChances.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getChances.fulfilled, (state, action) => {
                state.status = 'idle';
                state.chances = action.payload;
            })
            .addCase(getChances.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })

            .addCase(ctrAction.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(ctrAction.fulfilled, (state) => {
                state.status = 'idle';
            })
            .addCase(ctrAction.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })

            .addCase(resetState, () => initialState);
    }
});

export const resetState = createAction('REVERT_ALL');

export const { setActiveDream } = callForDreamsPhaseThreeSlice.actions;

export default callForDreamsPhaseThreeSlice.reducer;
