import styles from './ToscanaExperience.module.scss';
import ArticleHeader from '@containers/IQOSUniverse/components/ArticleHeader';
import React, { useState } from 'react';
import VideoModalQuiz from '@containers/IQOSUniverse/components/VideoModalQuiz';
import { useWindowSize } from '@utils/hooks/windowSize';
import ArticleContainer from '@containers/IQOSUniverse/components/ArticleContainer';
import KeenSlider from '@components/KeenSlider';

import {
    SliderExperience1,
    Experience,
    SliderExperience2
} from '@containers/IQOSUniverse/articles/ToscanaExperience/SliderList/SliderToscana';
import classNames from 'classnames';
import Button from '@components/Layout/Button';
import { config } from '@utils/config';

const ToscanaExperience = () => {
    const [toggleModal, setToggleModal] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);

    const windowSize = useWindowSize();
    const isMobile = windowSize.width < 650;
    return (
        <section className={styles.wrapper}>
            <ArticleHeader
                videoQuiz={() => {
                    setToggleModal(true);
                }}
                showBadges={false}
            />

            <VideoModalQuiz
                quizVideo={false}
                toggleModal={toggleModal}
                setToggleModal={() => setToggleModal(false)}
                setShowSuccess={() => {
                    setShowSuccess(true);
                }}
            />
            <ArticleContainer background={'#00d1d2'}>
                <div className="content">
                    <h3>Experiențe la următorul nivel, într-un peisaj de poveste.</h3>
                    <p>
                        Câștigătorii concursului „Imaginea comunității IQOS!” din IQOS Club s-au
                        bucurat de un premiu binemeritat: o experiență de neuitat într-una dintre
                        cele mai frumoase zone ale Italiei, Toscana.
                    </p>
                    <p>
                        Cu această ocazie au explorat, timp de o săptămână, tradițiile, cultura și
                        preparatele care au transformat Toscana într-una dintre cele mai fascinante
                        destinații turistice.
                    </p>
                </div>
            </ArticleContainer>

            <ArticleContainer background={'#F6F4F0'}>
                <KeenSlider
                    className={styles.experienceSlider}
                    spacing={isMobile ? 8 : 16}
                    mode={'free-snap'}
                    withDots={true}
                    dotColor={'#8C8A90'}
                    dotsClass={styles.sliderDots}
                    withArrows={false}
                >
                    {SliderExperience1.map((exp: Experience) => {
                        return (
                            <div className={styles.slideItem}>
                                <h3>{exp.title}</h3>
                                <img src={exp.image} alt={exp.title} />
                                <h3></h3>
                                <p>{exp.belowDescription.subtitle}</p>
                            </div>
                        );
                    })}
                </KeenSlider>
            </ArticleContainer>

            <ArticleContainer background={'#00d1d2'}>
                <div
                    className={classNames(
                        'content',
                        styles.noMarginbottom,
                        styles.fullWidthContent
                    )}
                >
                    <h3>Amintiri din „IQOS Toscana Experience”</h3>
                    <p>
                        Fiecare moment special dintr-o călătorie merită împărtășit. De aceea, te
                        invităm să descoperi în galeria de mai jos experiențele inedite de care s-au
                        bucurat câștigătorii care au luat parte la „IQOS Toscana Experience”!
                        Participă la concursurile din IQOS Club și sperăm ca în următoarea
                        experiență să fii și tu alături de noi!
                    </p>
                </div>

            <KeenSlider
                    className={styles.experienceSlider}
                    spacing={isMobile ? 8 : 16}
                    mode={'free-snap'}
                    withDots={false}
                    dotColor={'#8C8A90'}
                    withArrows={isMobile ? false : true}
                    darkArrows={true}
                    dotsClass={styles.leftDots}
                >
                    {SliderExperience2.map((exp: (typeof SliderExperience2)[0]) => {
                        return (
                            <div className={styles.slideItem}>
                                <img src={exp.image} alt={`${exp.id}`} />
                            </div>
                        );
                    })}
                </KeenSlider>

                <div className={classNames('content', styles.noMarginTop, styles.fullWidthContent)}>
                    <Button
                        type={'text'}
                        color={'dark'}
                        hover={'turquoise'}
                        label={'Participă acum'}
                        href={config.path.contests}
                    />
                </div>
            </ArticleContainer>
        </section>
    );
};

export default ToscanaExperience;
