export const StarGold = () => {
    return (
        <svg
            width="33"
            height="33"
            viewBox="0 0 33 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="1.66666"
                y="1.40616"
                width="29.6665"
                height="29.6665"
                rx="14.8333"
                fill="#FFFDFB"
            />
            <rect
                x="1.66666"
                y="1.40616"
                width="29.6665"
                height="29.6665"
                rx="14.8333"
                stroke="#00D1D2"
                stroke-width="2.33332"
            />
            <path
                d="M15.9061 9.72265C16.1414 9.22129 16.8544 9.22127 17.0898 9.72261L18.513 12.7546C18.6057 12.9521 18.7907 13.0903 19.0064 13.1232L22.2401 13.616C22.7663 13.6962 22.9813 14.338 22.6096 14.7189L20.2289 17.1594C20.084 17.3079 20.0182 17.5164 20.0516 17.7212L20.608 21.1326C20.6959 21.6713 20.124 22.0741 19.6464 21.8101L16.8149 20.2445C16.618 20.1356 16.379 20.1356 16.1821 20.2445L13.3506 21.8101C12.873 22.0741 12.3011 21.6713 12.389 21.1326L12.9454 17.7212C12.9788 17.5164 12.913 17.3079 12.7681 17.1594L10.3869 14.7185C10.0154 14.3376 10.2302 13.696 10.7562 13.6157L13.9908 13.1214C14.2063 13.0884 14.3913 12.9502 14.4839 12.7528L15.9061 9.72265Z"
                fill="#FFD657"
                stroke="#34303D"
                stroke-width="0.777774"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};
