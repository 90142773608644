export const Link = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1.2em"
            height="1.2em"
            fill="none"
            stroke="#f1f5fe"
            strokeWidth={0}
            viewBox="0 0 25 25"
        >
            <path
                fill="#f1f5fe"
                stroke="none"
                d="M11.183 7.683a.75.75 0 0 0 1.06 1.06l-1.06-1.06Zm2.802-1.742.53.53-.53-.53Zm4.544 0-.53.53.53-.53Zm0 4.544-.53-.53.53.53Zm-2.802 1.742a.75.75 0 1 0 1.06 1.06l-1.06-1.06Zm-1.969 4.091a.75.75 0 1 0-1.06-1.06l1.06 1.06Zm-2.802 1.741.524.537.006-.006-.53-.53Zm-4.515-.028-.53.53.53-.53Zm-.029-4.515-.53-.53-.007.007.537.523Zm2.802-1.742a.75.75 0 1 0-1.06-1.06l1.06 1.06Zm6.06-1.517a.75.75 0 1 0-1.06-1.06l1.06 1.06ZM9.67 13.741a.75.75 0 1 0 1.06 1.06l-1.06-1.06Zm2.573-4.998 2.272-2.272-1.06-1.06-2.272 2.272 1.06 1.06Zm2.272-2.272a2.463 2.463 0 0 1 3.484 0l1.06-1.06a3.963 3.963 0 0 0-5.604 0l1.06 1.06Zm3.484 0a2.463 2.463 0 0 1 0 3.484l1.06 1.06a3.963 3.963 0 0 0 0-5.604L18 6.47Zm0 3.484-2.272 2.272 1.06 1.06 2.272-2.272L18 9.955Zm-5.301 5.303-2.272 2.27 1.06 1.062 2.272-2.271-1.06-1.061Zm-2.266 2.264a2.463 2.463 0 0 1-3.46-.021L5.91 18.56a3.963 3.963 0 0 0 5.569.035l-1.048-1.074Zm-3.46-.021a2.463 2.463 0 0 1-.023-3.461l-1.074-1.047a3.963 3.963 0 0 0 .036 5.568l1.06-1.06Zm-.03-3.455 2.272-2.272-1.06-1.06-2.272 2.272 1.06 1.06Zm7.272-4.85L9.67 13.742l1.06 1.06 4.544-4.544-1.06-1.06Z"
            />
        </svg>
    );
};
