import { useEffect, useState } from 'react';
import styles from './EpisodeHeader.module.scss';
import { IEpisodeHeaderProps } from './interface';

// Helpers
import classnames from 'classnames';
import { useWindowSize } from '@utils/hooks/windowSize';

// Assets
import { BadgeIcon, PlayIcon } from '@assets/icons';
import { HiHeart, HiOutlineHeart } from 'react-icons/hi';

// Components
import RatingQuiz from '../RatingQuiz';
import VimeoPlayer from '@components/VimeoPlayer';
import RewardBadge from '@components/RewardBadge';
import BaseModal from '@components/Layout/BaseModal';
import VideoDisclaimer from '@containers/IQOSUniverse/components/VideoDisclaimer';

// Redux
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { likeArticle } from '@containers/IQOSUniverse/IQOSUniverseSlice';
import dayjs from '@utils/dayjs';
import { getQuiz } from '@components/Quiz/QuizSlice';

const EpisodeHeader = (props: IEpisodeHeaderProps) => {
    const window = useWindowSize();
    const dispatch = useAppDispatch();

    const isMobile = window.width < 460;

    const { showSubtitle, handleSuccess } = props;
    const { article } = useAppSelector((state) => state.iqosUniverse);
    const quiz = useAppSelector((state) => state.quiz?.quizQuestions?.quiz);

    const [isLiked, setIsLiked] = useState(false);

    let playing = false;
    let progress = 0;

    const [isPastPercentage, setIsPastPercentage] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showDisclaimer, toggleDisclaimer] = useState(false);

    useEffect(() => {
        dispatch(getQuiz({ slug: article?.quiz_slug || '', statistics: false }));
    }, [article?.quiz_slug]);

    useEffect(() => {
        if (article.liked) {
            setIsLiked(article.liked);
        }

        if (!article.completed_quiz && (article.quiz_points || article.points)) {
            toggleDisclaimer(true);
        }
    }, [article]);

    const handleLike = () => {
        setIsLiked(!isLiked);
        dispatch(likeArticle({ slug: article.slug }));
    };

    // Handle IQOS Answers
    const triggerMomentPercentage = 75; // 75%

    const handleTimeUpdate = (data: any) => {
        if (playing && data.seconds - progress <= 10) {
            if (data.seconds < progress) {
                //don't update progress if user seeked back
            } else {
                progress = data.seconds;
            }
        }

        const durationPercentage = (triggerMomentPercentage * data.duration) / 100;

        if (progress >= durationPercentage && !isPastPercentage) {
            setIsPastPercentage(true);
        }
    };

    return (
        <section className={styles.wrapper}>
            <div className={styles.content}>
                <h1>{article.title}</h1>
                {showSubtitle && article.subtitle && <h2>{article.subtitle}</h2>}
            </div>

            <div className={styles.image}>
                {isPastPercentage && article.quiz_slug && (
                    <RatingQuiz
                        iqosAnswers
                        handleSuccess={handleSuccess}
                        quizSlug={article.quiz_slug}
                    />
                )}

                {article.video_link ? (
                    <div className={styles.isVideo} onClick={() => setIsModalOpen(true)}>
                        <PlayIcon />

                        <img
                            src={
                                isMobile && article.picture_mobile
                                    ? article.picture_mobile
                                    : article.picture
                            }
                            alt={article.slug}
                            onClick={() => setIsModalOpen(true)}
                        />
                    </div>
                ) : (
                    <img src={article.picture} alt={article.slug} />
                )}

                {!article.completed_quiz ? (
                    <div className={styles.badges}>
                        {dayjs(quiz?.end_date).isAfter(dayjs()) && (
                            <RewardBadge
                                absolute={false}
                                points={article.quiz_points || article.points}
                            />
                        )}

                        {article.display_badge_icon ? (
                            <div className={styles.badgeIcon}>
                                <BadgeIcon />
                            </div>
                        ) : null}
                    </div>
                ) : null}

                <div
                    onClick={handleLike}
                    className={classnames(styles.likeButton, isLiked && styles.liked)}
                >
                    {article.liked || isLiked ? <HiHeart /> : <HiOutlineHeart />}
                </div>
            </div>

            <BaseModal
                size="large"
                maskClosable={true}
                isModalOpen={isModalOpen}
                className={styles.videoModal}
                closeModal={() => setIsModalOpen(false)}
                destroyOnClose={true}
            >
                {!article.completed_quiz &&
                    showDisclaimer &&
                    dayjs(quiz?.end_date).isAfter(dayjs()) && (
                        <VideoDisclaimer handleClose={() => toggleDisclaimer(false)} />
                    )}

                <VimeoPlayer
                    className={styles.player}
                    video={article.video_link}
                    options={{
                        autoplay: false,
                        responsive: true
                    }}
                    onPlay={() => (playing = true)}
                    onTimeUpdate={(progress) => handleTimeUpdate(progress)}
                    onPause={() => (playing = false)}
                    onEnd={() => setIsModalOpen(false)}
                />
            </BaseModal>
        </section>
    );
};

export default EpisodeHeader;
