export const HowItWorksIcon = () => {
    return (
        <svg
            width="22"
            height="20"
            viewBox="0 0 22 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11 18.5643L2.32571 10.7071C-2.38857 5.99286 4.54143 -3.05856 11 4.26429C17.4586 -3.05856 24.3571 6.02429 19.6743 10.7071L11 18.5643Z"
                stroke="#C4C3C6"
                strokeWidth="1.71429"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
