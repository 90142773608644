import React, { useState } from 'react';
import styles from './TiffWrapUp2023.module.scss';

//Components
import ArticleHeader from '@containers/IQOSUniverse/components/ArticleHeader';
import VideoModalQuiz from '@containers/IQOSUniverse/components/VideoModalQuiz';
import MediaGallery from '@containers/IQOSUniverse/components/MediaGallery';
import CompletedStateArticle from '@containers/IQOSUniverse/components/CompletedStateArticle/CompletedStateArticle';

// Assets
import img1 from './assets/tiff-1.jpg';
import img2 from './assets/tiff-2.jpg';
import img3 from './assets/tiff-3.jpg';
import img4 from './assets/tiff-4.jpg';
import img5 from './assets/tiff-5.jpg';
import img6 from './assets/tiff-6.jpg';
import img7 from './assets/tiff-7.jpg';
import img8 from './assets/tiff-8.jpg';
import img9 from './assets/tiff-9.jpg';

// Redux
import { useAppSelector } from '@app/hooks';

const TiffWrapUp2023: React.FC = () => {
    const { article } = useAppSelector((state) => state.iqosUniverse);

    const [toggleModal, setToggleModal] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);

    return (
        <section className={styles.wrapper}>
            <ArticleHeader
                videoQuiz={() => {
                    setToggleModal(true);
                }}
            />
            <VideoModalQuiz
                toggleModal={toggleModal}
                setToggleModal={() => setToggleModal(false)}
                setShowSuccess={() => {
                    setShowSuccess(true);
                }}
            />
            <div className="content">
                <p>
                    Festivalul Internațional de Film Transilvania, cel mai mare festival din România
                    dedicat filmului de lungmetraj, a devenit brandul cultural care anual surprinde
                    comunitatea creativă cu cele mai noi proiecții, dar și cu evenimente memorabile.
                </p>

                <p>
                    Cu 110.000 de bilete vândute, TIFF a reușit să înregistreze o creștere de 8%
                    față de anul trecut. Toate filmele din cadrul festivalului pot fi văzute și
                    online, pe platforma TIFF Unlimited, iar când vine vorba de petreceri, îți
                    arătăm noi care a fost atmosfera:
                </p>
            </div>

            <MediaGallery images={[img1, img2, img3, img4, img5, img6, img7, img8, img9]} />

            {(showSuccess || article.completed_quiz) && (
                <CompletedStateArticle
                    className={styles.completedStateArticle}
                    article={{
                        points: article.points,
                        quiz_points: article.quiz_points,
                        quiz_end_date: article.quiz_end_date
                    }}
                />
            )}
        </section>
    );
};

export default TiffWrapUp2023;
