export const InfoIconBlue = () => {
    return (
        <svg
            width="29"
            height="30"
            viewBox="0 0 29 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M28.9655 14.9828C28.9655 22.9814 22.4814 29.4655 14.4828 29.4655C6.48415 29.4655 0 22.9814 0 14.9828C0 6.98415 6.48415 0.5 14.4828 0.5C22.4814 0.5 28.9655 6.98415 28.9655 14.9828Z"
                fill="#287BAF"
            />
            <path
                d="M14.5721 22.4827C13.7037 22.4827 12.9997 23.1867 12.9997 24.0551C12.9997 24.9236 13.7037 25.6276 14.5721 25.6276C15.4405 25.6276 16.1445 24.9236 16.1445 24.0551C16.1445 23.1867 15.4405 22.4827 14.5721 22.4827Z"
                fill="#FFFDFB"
            />
            <path
                d="M13.225 18.5236C13.225 19.2677 13.8282 19.8709 14.5723 19.8709C15.3164 19.8709 15.9196 19.2677 15.9196 18.5236L15.9196 6.84726C15.9196 6.10318 15.3164 5.49999 14.5723 5.49999C13.8282 5.49999 13.225 6.10318 13.225 6.84726L13.225 18.5236Z"
                fill="#FFFDFB"
            />
        </svg>
    );
};
