export interface BurshClean {
    id: number;
    description: string;
}

export const BrushCleanList: BurshClean[] = [
    {
        id: 1,
        description: `Deschide ușor partea marcată cu ⊙ - ca să poți utiliza capătul special conceput pentru ștergere.`
    },
    {
        id: 2,
        description: `Deschide ușor partea pe care se află semnul cu 5 cercuri -
        ca să folosești periuța pentru curățarea dispozitivului.`
    },
    {
        id: 3,
        description: `Îndepărtează
        căpăcelul dispozitivului`
    },
    {
        id: 4,
        description: `Introdu capătul pentru ștergere în dispozitiv și
        curăță totul, prin răsucire.`
    },
    {
        id: 5,
        description: `Folosește periuța de curățare și șterge
        dispozitivul, tot prin răsucire.`
    }
];
