// Electro Rouge assets
import cardBackgroundRouge from '@containers/IQOSUniverse/articles/NewLevia/assets/cardBackgroundRouge.png';
import leviaPackElectroRouge from '@containers/IQOSUniverse/articles/NewLevia/assets/leviaPackElectroRouge.png';
import flavourElectroRouge from '@containers/IQOSUniverse/articles/NewLevia/assets/flavourElectroRouge.png';

// Island Beat assets
import cardBackgroundIsland from '@containers/IQOSUniverse/articles/NewLevia/assets/cardBackgroundIsland.png';
import leviaPackIslandBeat from '@containers/IQOSUniverse/articles/NewLevia/assets/leviaPackIslandBeat.png';
import flavourIslandBeat from '@containers/IQOSUniverse/articles/NewLevia/assets/flavourIslandBeat.png';

export const slides = [
    {
        id: 1,
        cardBackground: cardBackgroundRouge,
        packImage: leviaPackElectroRouge,
        flavourImage: flavourElectroRouge,
        title: 'ELECTRO ROUGE',
        aroma: 'Aroma: Afine',
        contentTitle:
            'Experiență aromatică cu nicotină <br/> Un amestec echilibrat între prospețimea mentei și aroma dulce-acrișoară a afinelor.',
        titleDescription: 'Fresh & Fructat',
        sound: 'electro'
    },
    {
        id: 2,
        cardBackground: cardBackgroundIsland,
        packImage: leviaPackIslandBeat,
        flavourImage: flavourIslandBeat,
        title: 'ISLAND BEAT',
        aroma: 'Aroma: Mentă',
        contentTitle:
            'Experiență răcoritoare cu nicotină. <br/> Un mix de senzații fresh, cu note proaspete de mentă.',
        titleDescription: 'Fresh & Mentolat',
        sound: 'island'
    }
];
