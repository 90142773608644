import { CarouselArrow } from '@assets/icons';
import classnames from 'classnames';

const ArrowNext = (props: any) => {
    return (
        <div
            className={classnames(
                props.className,
                props.currentSlide === props.slideCount - 1 && 'disabled'
            )}
            onClick={props.onClick}
        >
            <CarouselArrow />
        </div>
    );
};
const ArrowPrev = (props: any) => {
    return (
        <div
            className={classnames(props.className, props.currentSlide === 0 && 'disabled')}
            onClick={props.onClick}
        >
            <CarouselArrow />
        </div>
    );
};

export const carouselSettings = {
    pauseOnHover: true,
    autoplaySpeed: 5000,
    arrows: true,
    dots: false,
    nextArrow: <ArrowNext />,
    prevArrow: <ArrowPrev />,
    responsive: [
        {
            breakpoint: 570,
            settings: {
                arrows: true,
                dots: false
            }
        }
    ]
};
