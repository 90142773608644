export const ContestIconWhite = () => {
    return (
        <svg
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M17 33C25.8366 33 33 25.8366 33 17C33 8.16344 25.8366 1 17 1C8.16344 1 1 8.16344 1 17C1 25.8366 8.16344 33 17 33Z"
                stroke="#34303D"
                stroke-width="0.6875"
            />
            <path
                d="M17 3.23999C20.6494 3.23999 24.1493 4.6897 26.7298 7.2702C29.3103 9.8507 30.76 13.3506 30.76 17C30.76 20.6494 29.3103 24.1493 26.7298 26.7298C24.1493 29.3103 20.6494 30.76 17 30.76V3.23999Z"
                fill="#F6F4F0"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M21.5431 18.6624L24.542 23.6694L21.5431 23.2714L20.3883 26.0675L17.324 20.8613"
                fill="#F6F4F0"
            />
            <path
                d="M21.5431 18.6624L24.542 23.6694L21.5431 23.2714L20.3883 26.0675L17.324 20.8613"
                stroke="#34303D"
                stroke-width="0.6875"
                stroke-linejoin="round"
            />
            <path
                d="M11.3601 18.4703L8.49712 23.67L11.4946 23.2711L12.6493 26.0673L15.8229 20.969"
                stroke="#34303D"
                stroke-width="0.6875"
                stroke-linejoin="round"
            />
            <path
                d="M16.465 20.8643C20.2126 20.8643 23.2506 17.8262 23.2506 14.0786C23.2506 10.331 20.2126 7.29297 16.465 7.29297C12.7174 7.29297 9.67935 10.331 9.67935 14.0786C9.67935 17.8262 12.7174 20.8643 16.465 20.8643Z"
                fill="#FFFDFB"
                stroke="#34303D"
                stroke-width="0.6875"
            />
            <path
                d="M13.4127 13.0807H19.5161M14.9394 15.2609H17.9911H14.9394Z"
                stroke="#34303D"
                stroke-width="0.6875"
                stroke-linecap="round"
            />
        </svg>
    );
};
