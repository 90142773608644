export interface HowItWorks {
    id: number;
    description: string;
}

export const HowItWorksList: HowItWorks[] = [
    {
        id: 1,
        description: `Te loghezi pe IQOS-ON.ro cu credențialele pe care le folosești deja în IQOS.ro sau IQOS CLUB.`
    },
    {
        id: 2,
        description: `Din AUTO Delivery, selectezi consumabilele preferate (TEREA, LEVIA, HEETS sau Fiit) și numărul de cartușe (minimum 2, maximum 5).`
    },
    {
        id: 3,
        description: `Plasezi comanda, apoi o primești prin curier în 2 zile lucrătoare.`
    },
    {
        id: 4,
        description: `Vei primi lunar aceeași comandă, la data selectată.`
    },
    {
        id: 5,
        description: `Durează aproximativ 5 minute să îți configurezi și activezi abonamentul. Apoi, primești consumabilele preferate la ușa ta, iar punctele se acumulează în cont cu fiecare comandă.`
    }
];

export interface Benefits {
    id: number;
    title: string;
    description?: string;
    list?: Array<{
        id: number;
        content: string;
    }>;
}

export const BenefitsList: Benefits[] = [
    {
        id: 1,
        title: `Ofertă specială`,
        description: `Discount de 15 RON/cartuș reprezentând o reducere de 1.5 RON/pachet la prețul de vânzare cu amănuntul per pachet unitar practicat pe <a href="https://www.iqos.ro/"> www.iqos.ro </a> la momentul debitării cardului.`
    },
    // {
    //     id: 2,
    //     title: `Cel mai simplu mod de a avansa sau de a rămâne la același nivel.`,
    //     description: `Cu abonamentul AUTO Delivery, primești puncte și momente prin care poți avansa sau prin care poți rămâne la același nivel:`,
    //     list: [
    //         {
    //             id: 1,
    //             content: `5 puncte după fiecare livrare;`
    //         },
    //         {
    //             id: 2,
    //             content: `Un punct pentru fiecare pachet de rezerve din tutun HEETS sau Fiit, în limita a 30 de pachete lunar, alocate automat;`
    //         },
    //         {
    //             id: 3,
    //             content: `Primești un moment la fiecare a treia* livrare.`
    //         }
    //     ]
    // },
    // {
    //     id: 3,
    //     title: `Curățarea zilnică asigurată`,
    //     description: `Primești gratuit 10 bețișoare de curățare la fiecare comandă.`
    // },
    {
        id: 4,
        title: `Transport gratuit`,
        description: `Ai transport gratuit pentru orice livrare.`
    },
    {
        id: 5,
        title: `Beneficii extra`,
        description: `Prin AUTO Delivery te bucuri de un preț special pentru achiziția unui kit IQOS ILUMA de pe iqos.ro la 149 lei.`
    }
    // {
    //     id: 5,
    //     title: `Încerci gratuit noi variante HEETS și Fiit`,
    //     description: `Prin abonamentul AUTO Delivery, la fiecare lansare a unei noi variante de rezerve HEETS sau Fiit, vei primi un pachet gratuit din noul sortiment.`
    // }
];

export interface Preferences {
    id: number;
    description: string;
}

export const PreferencesList: Preferences[] = [
    {
        id: 1,
        description: `Poți să modifici adresa și data livrării.`
    },
    {
        id: 2,
        description: `Poți să pui pauză, să anulezi sau să reactivezi serviciul.`
    },
    {
        id: 3,
        description: `Poți alege variantele preferate TEREA, LEVIA, HEETS & Fiit și numărul de cartușe.`
    },
    {
        id: 4,
        description: `Poți schimba pe parcurs consumabilele preferate și numărul de cartușe.`
    }
];
