export const PWAMoreIcon = () => {
    return (
        <svg
            width="54"
            height="54"
            viewBox="0 0 54 54"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect x="8" y="8" width="38" height="38" rx="19" fill="#00d1d2" />
            <path d="M36.9933 27L17 27" stroke="#F6F4F0" strokeWidth="1.71" strokeLinecap="round" />
            <path
                d="M27.0039 17.0057L27.0039 36.999"
                stroke="#F6F4F0"
                strokeWidth="1.71"
                strokeLinecap="round"
            />
        </svg>
    );
};
