export const MenuDropdownIcon = () => {
    return (
        <svg
            width="14"
            height="10"
            viewBox="0 0 14 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0.5 1.8501L6.65 8.0001C6.69489 8.04793 6.74911 8.08605 6.80931 8.11211C6.8695 8.13817 6.9344 8.15162 7 8.15162C7.0656 8.15162 7.1305 8.13817 7.1907 8.11211C7.25089 8.08605 7.30511 8.04793 7.35 8.0001L13.5 1.8501"
                stroke="#00D1D2"
                strokeWidth="1.71429"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
