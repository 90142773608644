export const UserIcon = () => {
    return (
        <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_133_933)">
                <path
                    d="M11 11C13.8206 11 16.1071 8.71345 16.1071 5.89285C16.1071 3.07225 13.8206 0.785706 11 0.785706C8.1794 0.785706 5.89286 3.07225 5.89286 5.89285C5.89286 8.71345 8.1794 11 11 11Z"
                    stroke="#C4C3C6"
                    strokeWidth="1.71429"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M20.7114 21.2143C20.0517 19.157 18.7557 17.3625 17.0104 16.0893C15.265 14.8161 13.1604 14.13 11 14.13C8.83959 14.13 6.73499 14.8161 4.98963 16.0893C3.24426 17.3625 1.94829 19.157 1.28857 21.2143H20.7114Z"
                    stroke="#C4C3C6"
                    strokeWidth="1.71429"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_133_933">
                    <rect width="22" height="22" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
