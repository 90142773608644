export const CollapsePinnedDownIcon = () => {
    return (
        <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="28" height="28" rx="14" fill="#34303D" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.8001 11.9V8.4H17.5001C17.8851 8.4 18.2001 8.085 18.2001 7.7C18.2001 7.315 17.8851 7 17.5001 7H10.5001C10.1151 7 9.8001 7.315 9.8001 7.7C9.8001 8.085 10.1151 8.4 10.5001 8.4H11.2001V11.9C11.2001 13.062 10.2621 14 9.1001 14V15.4H13.2791V20.3L13.9791 21L14.6791 20.3V15.4H18.9001V14C17.7381 14 16.8001 13.062 16.8001 11.9Z"
                fill="#FFFDFB"
            />
        </svg>
    );
};
