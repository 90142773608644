export const VoucherIconWhite = () => {
    return (
        <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="14" cy="14" r="14" fill="#FFFDFB" />
            <path
                d="M14.0226 3.9071C16.6994 3.9071 19.2666 4.97047 21.1594 6.86328C23.0522 8.75609 24.1156 11.3233 24.1156 14.0001C24.1156 16.677 23.0522 19.2442 21.1594 21.137C19.2666 23.0298 16.6994 24.0932 14.0226 24.0932V3.9071Z"
                fill="#00D1D2"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.765 9.10938L21.3402 18.5914L7.53482 19.1915L7.11647 16.9887C7.61849 16.8527 8.06821 16.5873 8.42511 16.2291C8.9572 15.697 9.28795 14.961 9.28795 14.1491C9.28795 13.3373 8.9572 12.6013 8.42511 12.0692C8.04076 11.6835 7.5492 11.405 7.00012 11.2809V9.70944L20.765 9.10938Z"
                fill="#F6F4F0"
                stroke="#34303D"
            />
            <path
                d="M14.2978 12.4518C14.2865 11.8049 13.753 11.2896 13.1061 11.3009C12.4592 11.3122 11.944 11.8458 11.9553 12.4927C11.9666 13.1395 12.5001 13.6548 13.147 13.6435C13.7939 13.6322 14.3091 13.0987 14.2978 12.4518Z"
                fill="#34303D"
            />
            <path
                d="M17.5613 15.7316C17.55 15.0847 17.0164 14.5694 16.3695 14.5807C15.7226 14.592 15.2074 15.1256 15.2187 15.7724C15.23 16.4193 15.7635 16.9346 16.4104 16.9233C17.0573 16.912 17.5725 16.3784 17.5613 15.7316Z"
                fill="#34303D"
            />
            <path d="M13.0664 15.7955L16.5459 12.316" stroke="#34303D" strokeLinecap="round" />
        </svg>
    );
};
