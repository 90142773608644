import styles from './SingFestWrapUp.module.scss';

// Components
import ArticleHeader from '@containers/IQOSUniverse/components/ArticleHeader';
import MediaGallery from '@containers/IQOSUniverse/components/MediaGallery';

// Assets
import img1 from './assets/img1.jpg';
import img2 from './assets/img2.jpg';
import img3 from './assets/img3.jpg';
import img4 from './assets/img4.jpg';
import img5 from './assets/img5.jpg';
import img6 from './assets/img6.jpg';
import img7 from './assets/img7.jpg';
import img8 from './assets/img8.jpg';
import VideoModalQuiz from '@containers/IQOSUniverse/components/VideoModalQuiz';
import React, { useState } from 'react';
import { useAppSelector } from '@app/hooks';
import CompletedStateArticle from '@containers/IQOSUniverse/components/CompletedStateArticle/CompletedStateArticle';

const SingFestWrapUp = () => {
    const { article } = useAppSelector((state) => state.iqosUniverse);

    const [toggleModal, setToggleModal] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);

    return (
        <section className={styles.wrapper}>
            <ArticleHeader
                videoQuiz={() => {
                    setToggleModal(true);
                }}
            />
            <VideoModalQuiz
                toggleModal={toggleModal}
                setToggleModal={() => setToggleModal(false)}
                setShowSuccess={() => {
                    setShowSuccess(true);
                }}
            />
            <div className="content">
                <p>
                    Am fost în Singureni, la SING FEST 2023 și ne-a plăcut.
                    <br />
                    Muzica în mijlocul naturii ne-a încărcat bateriile, iar tradițiile românești
                    care au animat întregul festival ne-au amintit de valoarea moștenirii noastre.
                </p>

                <p>
                    Cele 3 zile de festival au fost pline de surprize plăcute. Aeroclubul României a
                    oferit participanților un show aerian de acrobații și parașutism, iar artiștii
                    de pe scenă au făcut o super atmosferă, indiferent de genul muzical.
                </p>

                <p>
                    Echipa IQOS a fost prezentă la distracție cu noul dispozitiv revoluționar IQOS
                    ILUMA. <br /> Uite cât de bine ne-am simțit:
                </p>
            </div>

            <MediaGallery images={[img1, img2, img3, img4, img5, img6, img7, img8]} />

            {(showSuccess || article.completed_quiz) && (
                <CompletedStateArticle
                    className={styles.completedStateArticle}
                    article={{
                        points: article.points,
                        quiz_points: article.quiz_points,
                        quiz_end_date: article.quiz_end_date
                    }}
                />
            )}
        </section>
    );
};

export default SingFestWrapUp;
