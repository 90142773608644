import React, { useState } from 'react';
import styles from './SummerSecondExperience.module.scss';

// Components
import ArticleHeader from '@containers/IQOSUniverse/components/ArticleHeader';
import VideoModalQuiz from '@containers/IQOSUniverse/components/VideoModalQuiz';
import CompletedStateArticle from '@containers/IQOSUniverse/components/CompletedStateArticle/CompletedStateArticle';
import ArticleContainer from '@containers/IQOSUniverse/components/ArticleContainer';
import Button from '@components/Layout/Button';
import KeenSlider from '@components/KeenSlider';

// Local Data
import {
    concertSlider,
    momentsSlider,
    liveMusicSlider,
    SecondSummerExperienceSliderInterface
} from './slidersData/slidersData';

// Helpers
import { useWindowSize } from '@utils/hooks/windowSize';
import { config } from '@utils/config';
import classnames from 'classnames';
import dayjs from '@utils/dayjs';

// Redux
import { useAppSelector } from '@app/hooks';
import { FPIcon } from '@assets/icons';

const SummerSecondExperience = () => {
    const { article } = useAppSelector((state) => state.iqosUniverse);

    const windowSize = useWindowSize();
    const isMobile = windowSize.width < 650;

    const [toggleModal, setToggleModal] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);

    return (
        <section className={styles.wrapper}>
            <ArticleHeader
                videoQuiz={() => {
                    setToggleModal(true);
                }}
            />
            <VideoModalQuiz
                toggleModal={toggleModal}
                setToggleModal={() => setToggleModal(false)}
                setShowSuccess={() => {
                    setShowSuccess(true);
                }}
            />

            <div className={classnames('content', styles.title)}>
                <h3>O nouă experiență la nivelul următor pentru membrii IQOS CLUB!</h3>

                <p>
                    Câștigătorii s-au bucurat de premiul marca Together X - o experiență
                    exclusivistă la malul mării, în perioada: 25-27 august.
                </p>
            </div>
            <div className={styles.liveMusicContainer}>
                <ArticleContainer background={'#F6F4F0'}>
                    <KeenSlider
                        className={styles.liveMusicSlider}
                        spacing={isMobile ? 8 : 16}
                        mode={'free-snap'}
                        withDots={true}
                        dotColor={'#8C8A90'}
                        dotsClass={styles.sliderDots}
                        withArrows={false}
                    >
                        {liveMusicSlider.map((slide: SecondSummerExperienceSliderInterface) => {
                            return (
                                <div className={styles.slideItem}>
                                    <img src={slide.image} alt={slide.title} />
                                </div>
                            );
                        })}
                    </KeenSlider>
                    <h3>Muzică live în timpul plimbării cu velierul</h3>
                    <p>
                        Pe lângă atmosfera plăcută din timpul plimbării, câștigătorii au avut parte
                        de un moment surpriză: un concert live la saxofon.
                    </p>
                </ArticleContainer>
            </div>

            <div className={styles.momentsContainer}>
                <ArticleContainer background={'#00D1D2'}>
                    <h3>
                        Mai multe momente memorabile, petrecute alături de comunitatea IQOS CLUB
                    </h3>

                    <KeenSlider
                        className={styles.momentsSlider}
                        spacing={isMobile ? 8 : 16}
                        mode={'free-snap'}
                        withDots={true}
                        dotColor={'#FFFDFB'}
                        dotsClass={styles.sliderDots}
                        withArrows={false}
                    >
                        {momentsSlider.map((moment: SecondSummerExperienceSliderInterface) => {
                            return (
                                <div className={styles.slideItem}>
                                    <img src={moment.image} alt={moment.title} />
                                    <h3 className={styles.title}>
                                        {moment.belowDescription?.title}
                                    </h3>
                                    <p>{moment.belowDescription?.subtitle}</p>
                                </div>
                            );
                        })}
                    </KeenSlider>
                </ArticleContainer>
            </div>

            <div className={styles.liveMusicContainer}>
                <ArticleContainer background={'#F6F4F0'}>
                    <h3 className={styles.topTitle}>
                        Șuie Paparude a susținut un concert pe plaja H2O
                    </h3>
                    <p className={styles.topSubtitle}>
                        La finalul experienței câștigătorii au asistat la un concert special Șuie
                        Paparude. În plus, ei s-au bucurat și de alte surprize oferite de IQOS.
                    </p>
                    <KeenSlider
                        className={styles.liveMusicSlider}
                        spacing={isMobile ? 8 : 16}
                        mode={'free-snap'}
                        withDots={true}
                        dotColor={'#8C8A90'}
                        dotsClass={styles.sliderDots}
                        withArrows={false}
                    >
                        {concertSlider.map((slide: SecondSummerExperienceSliderInterface) => {
                            return (
                                <div className={styles.slideItem}>
                                    <img src={slide.image} alt={slide.title} />
                                </div>
                            );
                        })}
                    </KeenSlider>
                    <p>
                        Participă mai departe la concursurile din IQOS CLUB și te poți număra
                        printre viitorii câștigători ai unei experiențe marca Together X!
                    </p>
                    <div className={styles.button}>
                        <Button
                            href={config.path.contests}
                            label={'Participă'}
                            type={'solid'}
                            color={'dark'}
                            hover={'turquoise'}
                        />
                    </div>
                </ArticleContainer>
            </div>

            {dayjs(article.quiz_end_date).isAfter(dayjs()) && (
                <>
                    {showSuccess || article.completed_quiz ? (
                        <CompletedStateArticle
                            className={styles.completedStateArticle}
                            article={{
                                points: article.points,
                                quiz_points: article.quiz_points,
                                quiz_end_date: article.quiz_end_date
                            }}
                        />
                    ) : (
                        <section className={classnames(styles.congratsContainer)}>
                            <div className={styles.completed}>
                                <h3>
                                    Urmărește video-ul celei de-a doua experiențe Together X de la
                                    mare și acumulezi 100 FP!
                                </h3>

                                <div className={styles.reward}>
                                    <FPIcon />
                                    <span>
                                        <b>+100</b> <br />
                                        Forward Points
                                    </span>
                                </div>
                            </div>
                        </section>
                    )}
                </>
            )}
        </section>
    );
};

export default SummerSecondExperience;
