// Assets
import bronze from '../assets/bronze.png';
import sienna from '../assets/sienna.png';
import amber from '../assets/amber.png';
import yellow from '../assets/yellow.png';
import silver from '../assets/silver.png';
import siennaCaps from '../assets/sienna-caps.png';
import turquoise from '../assets/turquoise.png';
import kelly from '../assets/kelly.png';
import blue from '../assets/blue.png';
import russet from '../assets/russet.png';
import teak from '../assets/teak.png';
import mauve from '../assets/mauve.png';
import laguna from '../assets/laguna.png';
import amelia from '../assets/amelia.png';
import abora from '../assets/abora.png';

import bronzeActive from '../assets/bronze_a.png';
import siennaActive from '../assets/sienna_a.png';
import amberActive from '../assets/amber_a.png';
import yellowActive from '../assets/yellow_a.png';
import silverActive from '../assets/silver_a.png';
import siennaCapsActive from '../assets/sienna-caps_a.png';
import turquoiseActive from '../assets/turquoise_a.png';
import kellyActive from '../assets/kelly_a.png';
import blueActive from '../assets/blue_a.png';
import russetActive from '../assets/russet_a.png';
import teakActive from '../assets/teak_a.png';
import mauveActive from '../assets/mauve_a.png';
import lagunaActive from '../assets/laguna_a.png';
import ameliaActive from '../assets/amelia_a.png';
import aboraActive from '../assets/abora_a.png';

import bronzeActiveTag from '../assets/bronze_a_tag.png';
import siennaActiveTag from '../assets/sienna_a_tag.png';
import amberActiveTag from '../assets/amber_a_tag.png';
import yellowActiveTag from '../assets/yellow_a_tag.png';
import silverActiveTag from '../assets/silver_a_tag.png';
import siennaCapsActiveTag from '../assets/sienna-caps_a_tag.png';
import turquoiseActiveTag from '../assets/turquoise_a_tag.png';
import kellyActiveTag from '../assets/kelly_a_tag.png';
import blueActiveTag from '../assets/blue_a_tag.png';
import russetActiveTag from '../assets/russet_a_tag.png';
import teakActiveTag from '../assets/teak_a_tag.png';
import mauveActiveTag from '../assets/mauve_a_tag.png';
import lagunaActiveTag from '../assets/laguna_a_tag.png';
import ameliaActiveTag from '../assets/amelia_a_tag.png';
import aboraActiveTag from '../assets/abora_a_tag.png';

export interface HeetsCollectionItem {
    id: number;
    activeImage: string;
    activeTag: string;
    image: string;
    title: string;
    description: string;
}

export const HeetsCollectionList: HeetsCollectionItem[] = [
    {
        id: 1,
        activeImage: lagunaActive,
        activeTag: lagunaActiveTag,
        image: laguna,
        title: 'HEETS Laguna Swift',
        description:
            'Fresh și exotic. <br />' +
            'Amestec de tutun echilibrat cu aromă de fructe tropicale și note răcoritoare de mentă.'
    },
    {
        id: 2,
        activeImage: mauveActive,
        activeTag: mauveActiveTag,
        image: mauve,
        title: 'HEETS Mauve',
        description:
            'Fresh și aromatic. <br />' +
            'Amestec de tutun infuzat cu aromă intensă de fructe de pădure și note răcoritoare de mentă.'
    },
    {
        id: 3,
        activeImage: teakActive,
        activeTag: teakActiveTag,
        image: teak,
        title: 'HEETS Teak',
        description:
            'Echilibrat și catifelat.<br />' +
            'Amestec de tutun bogat și echilibrat cu note catifelate de nuci.'
    },
    {
        id: 4,
        activeImage: russetActive,
        activeTag: russetActiveTag,
        image: russet,
        title: 'HEETS Russet',
        description:
            'Intens și plăcut amărui.<br />' +
            'Amestec de tutun intens cu note plăcute și echilibrate de malț.'
    },
    {
        id: 5,
        activeImage: bronzeActive,
        activeTag: bronzeActiveTag,
        image: bronze,
        title: 'HEETS Bronze',
        description:
            'Intens și savuros.<br />' +
            'Amestec de tutun bogat cu note calde și catifelate de cacao și fructe uscate.'
    },
    {
        id: 6,
        activeImage: siennaActive,
        activeTag: siennaActiveTag,
        image: sienna,
        title: 'HEETS Sienna',
        description:
            'Robust și lemnos.<br />' +
            'Amestec robust de tutun cu nuanțe lemnoase și note subtile de ceai.'
    },
    {
        id: 7,
        activeImage: amberActive,
        activeTag: amberActiveTag,
        image: amber,
        title: 'HEETS Amber',
        description:
            'Bogat și intens.<br />' +
            'Amestec de tutun bogat cu note catifelate de nuci de pădure.'
    },
    {
        id: 8,
        activeImage: ameliaActive,
        activeTag: ameliaActiveTag,
        image: amelia,
        title: 'HEETS Amelia Pearl',
        description:
            'Subtil sau fructat.<br />' +
            'Amestec de tutun fin și aromat. Prin activarea capsulei, ai parte de o aromă fresh cu note de fructe de vară.'
    },
    {
        id: 9,
        activeImage: aboraActive,
        activeTag: aboraActiveTag,
        image: abora,
        title: 'HEETS Abora Pearl',
        description:
            'Plăcut amărui sau fructat.<br />' +
            'Amestec de tutun intens cu note echilibrate de malț. Prin activarea capsulei, se dezvăluie note de fructe verzi, de livadă.'
    },
    {
        id: 10,
        activeImage: yellowActive,
        activeTag: yellowActiveTag,
        image: yellow,
        title: 'HEETS Yellow',
        description:
            'Rafinat și echilibrat.<br />' +
            'Amestec de tutun rafinat și aromatic cu note subtile de citrice.'
    },
    {
        id: 11,
        activeImage: silverActive,
        activeTag: silverActiveTag,
        image: silver,
        title: 'HEETS Silver',
        description:
            'Subtil și rafinat.<br />' +
            'Un amestec de tutun rafinat și echilibrat cu note subtile de ierburi aromatice.'
    },
    {
        id: 12,
        activeImage: siennaCapsActive,
        activeTag: siennaCapsActiveTag,
        image: siennaCaps,
        title: 'HEETS Sienna Caps',
        description:
            'Robust sau răcoritor.<br />' +
            'Amestec robust de tutun cu nuanțe lemnoase și note subtile de ceai. Prin activarea capsulei, aroma devine răcoritoare grație notelor fresh de mentol.'
    },
    {
        id: 13,
        activeImage: turquoiseActive,
        activeTag: turquoiseActiveTag,
        image: turquoise,
        title: 'HEETS Turquoise',
        description:
            'Fin și răcoritor.<br />' +
            'Amestec de tutun fin îmbogățit cu note de mentol pentru o senzație răcoritoare.'
    },
    {
        id: 14,
        activeImage: kellyActive,
        activeTag: kellyActiveTag,
        image: kelly,
        title: 'HEETS Kelly',
        description:
            'Fresh și răcoritor.<br />' +
            'Amestec de tutun îmbogățit cu note de mentol, citrice și ierburi aromatice, pentru o senzație fresh.'
    },
    {
        id: 15,
        activeImage: blueActive,
        activeTag: blueActiveTag,
        image: blue,
        title: 'HEETS Blue Selection',
        description:
            'îndrăzneț și răcoritor.<br />' +
            'Amestec de tutun cu note pronunțate de mentol ce îți oferă răcorire intensă.'
    }
];
