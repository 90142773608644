import React, { useState } from 'react';

//Styles
import styles from './BenefitsCommunityMembers.module.scss';

//Components
import ArticleHeader from '@containers/IQOSUniverse/components/ArticleHeader';
import { Carousel } from 'antd';
import VideoModal from '@components/VideoModal';

//Assets
import videoThumbnail from './assets/video_thumbnail.png';
import quotations from './assets/quotations.png';

//Helpers
import {
    sliderOneSettings,
    sliderTwoSettings,
    membersData,
    sectionsData
} from './BenefitsCommunitySliderData';
import { useWindowSize } from '@utils/hooks/windowSize';
import Vimeo from '@u-wave/react-vimeo';

const BenefitsCommunityMembers = () => {
    const [toggleModal, setToggleModal] = useState(false);
    const [visibleVideo, setVisibleVideo] = useState('');

    const window = useWindowSize();
    const isMobile = window.width < 570;

    const handleToggleModal = (id: string) => {
        setVisibleVideo(id);
        setToggleModal(true);
    };

    return (
        <div className={styles.wrapper}>
            <ArticleHeader mobileVideo="820488470" noModalVideo={true} />

            <div className="content">
                <h3>De vorbă cu primii membri care au descoperit noile beneficii din IQOS Club.</h3>

                <p>
                    O parte din comunitatea IQOS a avut șansa să exploreze noile beneficii și
                    funcționalități înainte de lansarea noului IQOS Club. Află chiar de la ei ce
                    anume i-a impresionat cel mai mult.
                </p>
            </div>

            <div className={styles.slidersContainer}>
                <Carousel {...sliderOneSettings}>
                    {membersData.map((member: any) => {
                        return (
                            <div className={styles.memberContainer} key={member.id}>
                                <img
                                    src={isMobile ? member.mobileProfileImg : member.profileImg}
                                    className={styles.profileImg}
                                    alt="member-profile"
                                />
                                <img
                                    src={quotations}
                                    className={styles.quotations}
                                    alt="quotations"
                                />
                                <p className={styles.description}>{member.description}</p>
                                <div className={styles.extraDetails}>
                                    <div>
                                        <img
                                            src={member.signatureImg}
                                            className={styles.signature}
                                            alt="signature"
                                        />
                                    </div>
                                    <p>Membru IQOS Club din {member.year}</p>
                                </div>
                            </div>
                        );
                    })}
                </Carousel>
            </div>

            <div>
                <Carousel {...sliderTwoSettings}>
                    {sectionsData.map((section: any) => {
                        return (
                            <div key={section.id} className={styles.sectionWrapper}>
                                <div
                                    className={styles.sectionContainer}
                                    style={{ backgroundColor: section.bgColor }}
                                >
                                    <h3 style={{ color: section.textColor }}>{section.title}</h3>
                                    {section.videoId ? (
                                        <div className={styles.videoContainer}>
                                            {/*<ArticleImage
                                                isVideo
                                                mobileImage={videoThumbnail}
                                                image={videoThumbnail}
                                                onClick={() => {
                                                    handleToggleModal(isMobile
                                                        ? section.videoIdMobile
                                                        : section.videoId);
                                                }}
                                            />*/}

                                            <Vimeo
                                                autoplay={false}
                                                responsive={true}
                                                className={styles.videoPlayer}
                                                video={
                                                    isMobile
                                                        ? section.videoIdMobile
                                                        : section.videoId
                                                }
                                            />
                                        </div>
                                    ) : (
                                        <div className={styles.imageSection}>
                                            {' '}
                                            <img src={section.image} />
                                        </div>
                                    )}
                                    <p
                                        className={styles.description}
                                        style={{ color: section.textColor }}
                                        dangerouslySetInnerHTML={{ __html: section.description }}
                                    />
                                </div>
                            </div>
                        );
                    })}
                </Carousel>
                <VideoModal
                    toggleModal={toggleModal}
                    setToggleModal={() => setToggleModal(false)}
                    video={visibleVideo}
                />
            </div>
        </div>
    );
};

export default BenefitsCommunityMembers;
