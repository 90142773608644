import { FC } from 'react';
import { ICard } from './interface';
import styles from './Card.module.scss';

// Assets
import { Stars } from '@assets/icons';

// Utils
import classNames from 'classnames';

// DND Kit
import { useDraggable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';

const Card: FC<ICard> = ({ card, id, disabled = false, rotate = false, cardsType }) => {
    const { attributes, listeners, setNodeRef, transform } = useDraggable({ id, disabled });
    const { image, text, notGuessed } = card;

    const style = {
        transform: CSS.Translate.toString(transform),
        cursor: disabled ? 'auto' : 'grab',
        color: disabled ? '#fffdfb' : '#34303d',
        textShadow: disabled ? '0px 0px 6px #000000' : 'none'
    };

    return (
        <div
            ref={setNodeRef}
            className={classNames(
                cardsType === 'square' ? styles.square : styles.container,
                disabled && styles.fadeOut
            )}
            style={style}
            {...listeners}
            {...attributes}
        >
            <div
                style={{
                    transform: rotate ? `rotate(${7 - Number(id) * 4}deg)` : 'none'
                }}
                className={styles.images}
            >
                <img src={image} />
                {/* {disabled && <Stars />} This shows a star on completion */}
            </div>
            {text && <span>{text}</span>}
        </div>
    );
};

export default Card;
