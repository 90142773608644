import MatchThreeGame from "./MatchThreeGame";
import { IMatchThree } from "./interface";
import { ReduxProvider } from "./components/ReduxProvider";

const MatchThreeWrappedWithRedux = (props:IMatchThree) => {
  return (
    <ReduxProvider>
      <MatchThreeGame {...props} />
    </ReduxProvider>
  )
}

export default MatchThreeWrappedWithRedux;
