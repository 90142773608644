export const MenuRecommendIcon = () => {
    return (
        <svg
            width="23"
            height="23"
            viewBox="0 0 23 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_8931_46282)">
                <path
                    d="M9.36417 10.7061C11.648 10.7061 13.5018 8.85233 13.5018 6.56852C13.5018 4.28471 11.648 2.43091 9.36417 2.43091C7.08037 2.43091 5.22656 4.28471 5.22656 6.56852C5.22656 8.85233 7.08037 10.7061 9.36417 10.7061Z"
                    stroke="#34303D"
                    strokeWidth="1.71"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M17.2287 21.8386C16.6935 19.4019 15.6424 17.2806 14.2282 15.7708C12.8139 14.261 11.113 13.4487 9.36433 13.4487C7.61564 13.4487 5.91473 14.261 4.50049 15.7708C3.08624 17.2806 2.03512 19.4019 1.5 21.8386H17.2287Z"
                    stroke="#34303D"
                    strokeWidth="1.71"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M17.4543 21.8385H21.6492C21.1714 19.6024 20.2254 17.6531 18.9545 16.2675C18.0085 15.2355 16.9191 14.557 15.7725 14.2799"
                    stroke="#34303D"
                    strokeWidth="1.71"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M14.1577 11.4897C14.3011 11.5088 14.4444 11.5183 14.5877 11.5183C16.6422 11.5183 18.3049 9.85563 18.3049 7.80116C18.3049 6.16714 17.2442 4.77201 15.7726 4.28467"
                    stroke="#34303D"
                    strokeWidth="1.71"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_8931_46282">
                    <rect width="22" height="22" fill="white" transform="translate(0.5 0.235352)" />
                </clipPath>
            </defs>
        </svg>
    );
};
