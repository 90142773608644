export const ButtonStarIconBlue = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="22"
            viewBox="0 0 21 22"
            fill="none"
        >
            <rect
                x="0.964355"
                y="1.46387"
                width="19.0714"
                height="19.0714"
                rx="9.53571"
                fill="#FFFDFB"
            />
            <rect
                x="0.964355"
                y="1.46387"
                width="19.0714"
                height="19.0714"
                rx="9.53571"
                stroke="#00D1D2"
                stroke-width="1.5"
            />
            <path
                d="M10.1191 6.81063C10.2704 6.48833 10.7288 6.48832 10.8801 6.81061L11.7951 8.75977C11.8546 8.8867 11.9736 8.97555 12.1122 8.99668L14.1911 9.31354C14.5293 9.3651 14.6675 9.77763 14.4286 10.0226L12.8981 11.5914C12.805 11.6869 12.7627 11.8209 12.7841 11.9526L13.1419 14.1457C13.1983 14.492 12.8307 14.7509 12.5237 14.5811L10.7034 13.5747C10.5768 13.5047 10.4232 13.5047 10.2966 13.5747L8.47635 14.5811C8.16928 14.7509 7.80166 14.492 7.85815 14.1457L8.21586 11.9526C8.23733 11.8209 8.19504 11.6869 8.10189 11.5914L6.57112 10.0223C6.33225 9.77741 6.47033 9.36496 6.80849 9.31329L8.88789 8.99553C9.02645 8.97436 9.14534 8.88551 9.20489 8.75862L10.1191 6.81063Z"
                fill="#FFD657"
                stroke="#34303D"
                stroke-width="0.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};
