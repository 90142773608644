import styles from './ArticleSlider.module.scss';
import { IArticleSlideProps, IArticleSliderProps } from './interface';

// Helpers
import classnames from 'classnames';
import { carouselSettings } from './data';
import { useWindowSize } from '@utils/hooks/windowSize';

// Components
import { Carousel } from 'antd';

const ArticleSlider = ({
    slides,
    backgroundColor,
    fullWidthImage,
    fullWidthSlider,
    columnReverse,
    imageBackgroundColor,
    imageObjectFit,
    belowDescription,
    bottomButtons,
    turquoiseArrows,
    className
}: IArticleSliderProps) => {
    const windowSize = useWindowSize();
    const isMobile = windowSize.width < 750;

    return (
        <section
            className={classnames(
                styles.wrapper,
                fullWidthSlider && styles.fullWidth,
                bottomButtons && styles.bottomButtons,
                turquoiseArrows && styles.turquoiseArrows,
                className
            )}
        >
            {slides && slides.length ? (
                <Carousel {...carouselSettings}>
                    {slides.map((slide: IArticleSlideProps) => (
                        <div
                            key={slide.image}
                            className={classnames(
                                styles.slide,
                                isMobile && columnReverse && styles.columnReverse,
                                belowDescription && styles.belowDescription
                            )}
                        >
                            {slide.title && (
                                <div
                                    style={{ backgroundColor: backgroundColor }}
                                    className={classnames(
                                        styles.content,
                                        slides.length === 1 && styles.single
                                    )}
                                >
                                    <div className={styles.details}>
                                        <h3
                                            className={slide.titleDark ? styles.darkTitle : ''}
                                            dangerouslySetInnerHTML={{
                                                __html: slide.title
                                            }}
                                        />

                                        {slide.description && (
                                            <p
                                                className={
                                                    slide.descriptionDark
                                                        ? styles.darkDescription
                                                        : ''
                                                }
                                                dangerouslySetInnerHTML={{
                                                    __html: slide.description
                                                }}
                                            />
                                        )}
                                    </div>
                                </div>
                            )}

                            <div
                                style={{ width: fullWidthImage ? '100%' : '' }}
                                className={styles.visual}
                            >
                                <img
                                    src={slide.image}
                                    alt={slide.image}
                                    style={{
                                        backgroundColor: imageBackgroundColor ? 'transparent' : '',
                                        objectFit: imageObjectFit ? 'contain' : 'cover'
                                    }}
                                />
                            </div>

                            {slide.belowDescription && (
                                <div className={styles.belowDescription}>
                                    {slide.belowDescription.title && (
                                        <h3>{slide.belowDescription.title}</h3>
                                    )}

                                    {slide.belowDescription.subtitle && (
                                        <p className={styles.bold}>
                                            {slide.belowDescription.subtitle}
                                        </p>
                                    )}

                                    {slide.belowDescription.paragraph && (
                                        <p>{slide.belowDescription.paragraph}</p>
                                    )}
                                </div>
                            )}
                        </div>
                    ))}
                </Carousel>
            ) : null}
        </section>
    );
};

export default ArticleSlider;
