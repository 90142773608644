import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@app/store';

import store from 'store';
import history from '@utils/history';
import { config } from '@utils/config';
import axiosRequest from '@utils/axios';
import * as Sentry from '@sentry/react';

// Tracking
import { TagManager, TagEvents } from '@utils/GTM';
import { togglePhoneValidationModal } from '../PhoneValidation/PhoneValidationSlice';

export interface LoginState {
    username: string;
    password: string;
    status: 'idle' | 'loading' | 'failed';
    response: object;
    error: any;
}

interface AxiosError {
    response: {
        data: {
            message: string;
            phone_validated: boolean;
        };
    };
}

const initialState: LoginState = {
    username: '',
    password: '',
    status: 'idle',
    response: {},
    error: {}
};

export const loginAsync = createAsyncThunk(
    'login/postLogin',
    async (values: object, { rejectWithValue, dispatch }) => {
        try {
            const response = await axiosRequest.get('/sanctum/csrf-cookie').then(() => {
                return axiosRequest.post('/login', values);
            });
            commonOnSuccessfulLogin(response.data);
            return response.data;
        } catch (e) {
            if ((e as AxiosError).response?.data?.phone_validated === false) {
                dispatch(togglePhoneValidationModal());
            }

            TagManager.dataLayer(TagEvents.loginError);
            return rejectWithValue((e as AxiosError).response.data.message);
        }
    }
);

export const loginSSOAsync = createAsyncThunk(
    'login/postLoginSSO',
    async (token: String, { rejectWithValue, dispatch }) => {
        try {
            const response = await axiosRequest.get('/sanctum/csrf-cookie').then(() => {
                return axiosRequest.post('/token-login', { token });
            });
            commonOnSuccessfulLogin(response.data);
            return response.data;
        } catch (e) {
            if ((e as AxiosError).response?.data?.phone_validated === false) {
                dispatch(togglePhoneValidationModal());
            }

            history.push(`${process.env.REACT_APP_BASENAME}`);
            window.location.reload();
            return rejectWithValue((e as AxiosError).response.data.message);
        }
    }
);

const commonOnSuccessfulLogin = (data: {
    user: {
        id: number;
        email: string;
        profile_activated_at: string;
        has_eligible_device: boolean;
        terms_and_conditions: boolean;
        has_veev_device: boolean;
        firstLogin: boolean;
    };
    authToken: string;
    authToken_expires_at: string;
    refreshToken: string;
    refreshToken_expires_at: string;
    activityFeedToken: string;
    profile_activated_at: string;
    terms_and_conditions: boolean;
    has_veev_device: boolean;
}) => {
    const user = data?.user;
    const authToken = data?.authToken;
    const authTokenExpiresAt = data?.authToken_expires_at;
    const refreshToken = data?.refreshToken;
    const refreshTokenExpiresAt = data?.refreshToken_expires_at;
    const getstreamFeedToken = data?.activityFeedToken;
    const profileHasEligibleDevice = data?.user.has_eligible_device;
    const profileActivatedAt = data?.user.profile_activated_at;
    const termsAndConditions = data?.user.terms_and_conditions;
    const hasVeevDevice = data?.user.has_veev_device;

    if (user.id) {
        Sentry.setUser({ id: user.id });
    }

    store.set(config.user.user, user);
    store.set(config.user.authToken, authToken);
    store.set(config.user.authTokenExpiresAt, authTokenExpiresAt);
    store.set(config.user.refreshToken, refreshToken);
    store.set(config.user.refreshTokenExpiresAt, refreshTokenExpiresAt);
    store.set(config.user.getstreamFeedToken, getstreamFeedToken);
    store.set(config.user.profileActivatedAt, profileActivatedAt);
    store.set(config.user.termsAndConditions, termsAndConditions);
    store.set(config.user.profileHasEligibleDevice, profileHasEligibleDevice);
    store.set(config.user.hasVeevDevice, hasVeevDevice);

    TagManager.dataLayer(TagEvents.loginSuccess);

    const previousRoute = store.get(config.storage.previousRoute);
    if (previousRoute) {
        history.push(previousRoute);
        store.remove(config.storage.previousRoute);
    } else {
        history.push(`${process.env.REACT_APP_BASENAME}`);
    }

    window.location.reload();
};

export const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(loginAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(loginAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.response = action.payload;
            })
            .addCase(loginAsync.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    }
});

export const selectLoading = (state: RootState) => state.login.status === 'loading';
export const selectFailed = (state: RootState) => state.login.status === 'failed';
export const selectError = (state: RootState) => state.login.error;

export default loginSlice.reducer;
