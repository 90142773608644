import { BurshClean } from "./BrushCleanList";


export const HeatCleanList:BurshClean[] = [
    {
        id:1,
        description:`Apasă butonul de 5 ori timp de 2 secunde.`
    },
    {
        id:2,
        description:`Se aprinde LED-ul de stare portocaliu, timp de 90 de secunde. Procesul de curățare începe automat.`
    },
    {
        id:3,
        description:`LED-ul de stare se oprește automat, iar dispozitivul vibrează de 2 ori.`
    }
]