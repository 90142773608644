import step1Image from '../assets/step1Image.png';
import step2Image from '../assets/step2Image.png';
import step3Image from '../assets/step3Image.png';
import step4Image from '../assets/step4Image.png';

export const stardriftQuizAnswers = [
    {
        id: 1,
        correctAnswerTitle: 'Așa este!',
        correctAnswerSubtitle:
            'IQOS ILUMA STARDRIFT a fost inspirat de albastrul infinit al cerului înstelat și sclipirea aurie a prafului de stele pentru o experiență IQOS unică.',
        wrongAnswerTitle: 'Nu știi încă totul despre această ediție limitată, dar nu-i nimic.',
        wrongAnswerSubtitle:
            'Designul IQOS ILUMA STARDRIFT în ediție limitată a fost inspirat de albastrul infinit al cerului înstelat și sclipirea aurie a prafului de stele.'
    },
    {
        id: 2,
        correctAnswerTitle: 'Așa este!',
        correctAnswerSubtitle:
            'Cu toții ne punem dorințe când privim stelele. Acestea ne încurajează să visăm și ne fac să simțim că putem realiza tot ce ne propunem. Inspirat de acest gând, te invităm să descoperi toată gama IQOS ILUMA STARDRIFT în ediție limitată: IQOS ILUMA Prime, IQOS ILUMA și IQOS ILUMA One.',
        wrongAnswerTitle: 'Ai fost aproape!',
        wrongAnswerSubtitle:
            'Gama IQOS ILUMA STARDRIFT în ediție limitată include IQOS ILUMA Prime, IQOS ILUMA și IQOS ILUMA One.'
    },
    {
        id: 3,
        correctAnswerTitle: 'Bravo, așa este!',
        correctAnswerSubtitle:
            'Accesoriile care fac parte din universul IQOS ILUMA STARDRIFT sunt: Clapeta IQOS ILUMA PRIME STARDRIFT, Ușița IQOS ILUMA STARDRIFT, Setul de 3 inele IQOS ILUMA STARDRIFT și Accesoriul autoadeziv IQOS ILUMA ONE STARDRIFT.',
        wrongAnswerTitle: 'Încă mai ai de explorat universul IQOS ILUMA STARDRIFT!',
        wrongAnswerSubtitle:
            'Accesoriile care fac parte din universul IQOS ILUMA STARDRIFT sunt: Clapeta IQOS ILUMA PRIME STARDRIFT, Ușița IQOS ILUMA STARDRIFT, Setul de 3 inele IQOS ILUMA STARDRIFT și Accesoriul autoadeziv IQOS ILUMA ONE STARDRIFT.'
    },
    {
        id: 4,
        correctAnswerTitle: 'Bravo, așa este!',
        correctAnswerSubtitle:
            'Sărbătorește fiecare vis împlinit, cu noul IQOS ILUMA STARDRIFT în ediție limitată.',
        wrongAnswerTitle: 'Nu e chiar așa...',
        wrongAnswerSubtitle: 'Toate variantele sunt corecte.'
    }
];

export const stepImages = [
    { id: 1, image: step1Image },
    { id: 2, image: step2Image },
    { id: 3, image: step3Image },
    { id: 4, image: step4Image }
];
