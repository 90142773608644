export const CollapseExpandLess = () => {
    return (
        <svg
            width="13"
            height="7"
            viewBox="0 0 13 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12.2958 6.51965L6.92214 1.14602C6.88292 1.10422 6.83555 1.07092 6.78295 1.04814C6.73035 1.02537 6.67364 1.01363 6.61632 1.01363C6.55901 1.01363 6.5023 1.02537 6.4497 1.04814C6.3971 1.07092 6.34973 1.10422 6.31051 1.14602L0.936873 6.51965"
                stroke="#000001"
                strokeWidth="0.873762"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
