import styles from './TereaFuseLine.module.scss';

// Components
import ArticleHeader from '@containers/IQOSUniverse/components/ArticleHeader';
import Button from '@components/Layout/Button';

// Assets
import amarelo from './assets/amarelo.png';
import amareloMobile from './assets/amareloMobile.png';
import wind from './assets/wind.png';
import windMobile from './assets/windMobile.png';
import amareloIndicatorMobile from './assets/amareloIndicatorMobile.png';
import windIndicatorMobile from './assets/windIndicatorMobile.png';
import iqosFlower from './assets/iqosFlower.svg';

// Helpers
import { useWindowSize } from '@utils/hooks/windowSize';
import classnames from 'classnames';

const TereaFuseLine = () => {
    const windowSize = useWindowSize();
    const isMobile = windowSize.width < 850;

    const navigate = () => window.open('https://www.iqos.ro/iluma/iluma-terea', '_blank');

    return (
        <section className={styles.wrapper}>
            <ArticleHeader whiteBorderLikeButton isCustomPhotoDimension />

            <div className={classnames('content', styles.topParagraph)}>
                <p>
                    Portofoliul TEREA se completează cu două noi sortimente din gama TEREA Fuse,
                    pentru o nouă experiență a tutunului încălzit infuzat cu note subtile: TEREA
                    Amarelo Fuse, cu note ușor efervescente și TEREA Wind Fuse, cu gust rafinat și
                    note fresh discrete.
                </p>
            </div>

            <div className={styles.cardContainer}>
                <div className={styles.presentationCards}>
                    <div className={styles.cardWrapper}>
                        <div className={styles.image}>
                            <img
                                src={isMobile ? amareloMobile : amarelo}
                                alt={'Terea Amarelo Fuse'}
                                className={styles.terea}
                            />
                            <img src={iqosFlower} alt={'Iqos flower'} className={styles.flower} />
                        </div>

                        <div className={styles.card}>
                            <h3>TEREA Amarelo Fuse</h3>
                            <img src={amareloIndicatorMobile} alt={'amarelo fuse indicator'} />
                            {isMobile ? (
                                <p>
                                    Efervescent <br /> Un amestec de <br /> tutun <br /> echilibrat,
                                    cu <br /> note ușor <br /> efervescente.
                                </p>
                            ) : (
                                <p>
                                    Efervescent <br /> Un amestec de tutun <br /> echilibrat, cu
                                    note <br /> ușor efervescente.
                                </p>
                            )}
                        </div>
                    </div>

                    <div className={styles.cardWrapper}>
                        <div className={styles.image}>
                            <img
                                src={isMobile ? windMobile : wind}
                                alt={'Terea Wind Fuse'}
                                className={styles.terea}
                            />
                            <img src={iqosFlower} alt={'Iqos flower'} className={styles.flower} />
                        </div>

                        <div className={styles.card}>
                            <h3>TEREA Wind Fuse</h3>
                            <img src={windIndicatorMobile} alt={'wind fuse indicator'} />
                            {isMobile ? (
                                <p>
                                    Discret <br /> Amestec <br /> delicat de <br /> tutun, cu gust
                                    <br /> rafinat și note <br /> fresh discrete.
                                </p>
                            ) : (
                                <p>
                                    Discret <br /> Amestec delicat de <br /> tutun, cu gust rafinat{' '}
                                    <br /> și note fresh discrete.
                                </p>
                            )}
                        </div>
                    </div>
                </div>

                <p className={styles.description}>
                    Împreună mergem mai departe și redescoperim noi senzații ale gustului TEREA,
                    alături de cele două noi sortimente. Sperăm să le savurezi și să te bucuri de
                    acestea.
                </p>

                <Button
                    label={'Comandă acum'}
                    color={'dark'}
                    hover={'light'}
                    type={'solid'}
                    onClick={() => navigate()}
                />
            </div>
        </section>
    );
};

export default TereaFuseLine;
