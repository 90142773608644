import { createSlice } from '@reduxjs/toolkit';

export const phoneValidationModalSlice = createSlice({
    name: 'phoneValidationModal',
    initialState: {
        isOpen: false
    },
    reducers: {
        togglePhoneValidationModal: (state) => {
            state.isOpen = !state.isOpen;
        }
    }
});

export const { togglePhoneValidationModal } = phoneValidationModalSlice.actions;
export default phoneValidationModalSlice.reducer;
