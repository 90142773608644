import React, { useEffect, useRef, useState } from 'react';

import { IQuizGameVideo } from '@components/Games/QuizGameVideo/interface';

// Styles
import styles from './QuizGameVideo.module.scss';

// Components
import Button from '@components/Layout/Button';
import { RadioChangeEvent, Radio } from 'antd';
import VimeoPlayer from '@components/VimeoPlayer';

// Utils
import useToggle from '@utils/hooks/useToggle';

const QuizGameVideo = ({ video, title, quizQuestions, onWin, onLose }: IQuizGameVideo) => {
    const [loading, toggleLoading] = useToggle();
    const [selectedAnswer, setSelectedAnswer] = useState();
    const [correctAnswer, setCorrectAnswer] = useState('');

    // Set right answer
    useEffect(() => {
        setCorrectAnswer(quizQuestions.correct_answer);
    }, [quizQuestions]);

    const onChange = ({ target }: RadioChangeEvent) => {
        setSelectedAnswer(target.value);
    };

    const onSubmit = () => {
        if (loading) return;
        
        toggleLoading();

        if (selectedAnswer === correctAnswer) {
            onWin();
        } else {
            onLose();
        }
    };

    const onVideoEnd = () => {
        const elementToScroll = document.getElementById('dream-title');

        elementToScroll?.scrollIntoView({ block: 'center', inline: 'nearest', behavior: 'smooth' });
    };

    return (
        <div className={styles.container}>
            <div className={styles.videoContainer}>
                <VimeoPlayer
                    className={styles.vimeo}
                    video={video}
                    options={{
                        portrait: false,
                        autoplay: false,
                        autopause: false,
                        responsive: true,
                        muted: false,
                        controls: true,
                        loop: false
                    }}
                    onEnd={onVideoEnd}
                />
            </div>

            <div className={styles.quiz}>
                <div id={'dream-title'} className={styles.title}>
                    <h3>{title}</h3>
                    <p>{quizQuestions.question}</p>
                </div>
                <div className={styles.radioContainer}>
                    <Radio.Group onChange={onChange} value={selectedAnswer}>
                        {quizQuestions.options.map(({ value }) => (
                            <Radio key={value} value={value}>
                                {value}
                            </Radio>
                        ))}
                    </Radio.Group>
                </div>
            </div>

            <Button
                label="Trimite"
                color="dark"
                hover="turquoise"
                type="solid"
                disabled={!selectedAnswer}
                onClick={onSubmit}
                loading={loading}
            />
        </div>
    );
};

export default QuizGameVideo;
