export const LoveIcon = () => {
    return (
        <svg
            width="12"
            height="11"
            viewBox="0 0 12 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.79177 9.22353C5.81209 9.2091 5.82344 9.20299 5.8324 9.19466C7.19648 7.92593 8.56712 6.66276 9.92224 5.38459C10.4624 4.87488 10.6715 4.24135 10.5843 3.52842C10.405 2.07424 8.80493 1.14476 7.32135 1.62949C6.94493 1.75275 6.62229 1.95098 6.34146 2.21138L5.76369 2.75108C5.54441 2.54397 5.34545 2.33909 5.12676 2.15308C4.48326 1.60617 3.72564 1.3974 2.87541 1.58341C1.96424 1.78163 1.35778 2.31577 1.10325 3.15363C0.852304 3.98261 1.06202 4.73385 1.69716 5.35794C2.46972 6.11862 3.26438 6.86098 4.05426 7.60667C4.62786 8.14747 5.20802 8.68106 5.79117 9.22298L5.79177 9.22353Z"
                stroke="#34303D"
                strokeWidth="2"
            />
        </svg>
    );
};
