import styles from './ReConnectWinner.module.scss';
import classNames from 'classnames';

// Components
import KeenSlider from '@components/KeenSlider';
import ArticleImage from '@containers/IQOSUniverse/components/ArticleImage';
import ArticleHeader from '@containers/IQOSUniverse/components/ArticleHeader';
import ArticleContainer from '@containers/IQOSUniverse/components/ArticleContainer';

// Assets
import slide1 from './assets/reconnectSlide1.png';
import slide2 from './assets/reconnectSlide2.png';
import slide3 from './assets/reconnectSlide3.png';
import winners from './assets/winnerTeam.png';

const ReConnectWinner = () => {
    return (
        <section className={styles.wrapper}>
            <ArticleHeader />

            <div className="content">
                <h3>Să fim împreună. Cea mai mare inspirație.</h3>

                <p>
                    Tema competiției RE-CONNECT „A celebration of togetherness”, susținută de IQOS
                    în cadrul Romanian Design Week 2023, a inspirat comunitatea locală de creatori
                    să exploreze diferitele feluri în care ne putem conecta unii cu alții.
                </p>

                <p>
                    Și pe lângă ei, ai devenit și tu parte din poveste. Pentru că ești parte din
                    arte zi de zi, votul tău și al celor ca tine a avut puterea să aleagă marele
                    câștigător, cel care ne va aduce împreună la Romanian Design Week 2023.
                </p>

                <h3>
                    Proiectul câștigător, susținut de IQOS:
                    <span className={styles.blockText}>
                        Echoes of presence, înscris de Alina Rizescu.
                    </span>
                </h3>
                <p>
                    Dintre cele trei proiecte finaliste selectate de juriu, cele mai multe voturi
                    le-a primit instalația înscrisă de Alina Rizescu în numele echipei – Echoes of
                    presence. Conceptul instalației se bazează pe ideea că a fi împreună nu înseamnă
                    neapărat a ne afla în același loc. Este vorba despre mai mult decât atât: despre
                    felurile în care ne conectăm unii cu alții, chiar și de la distanță.
                </p>

                <div className={styles.winnerTeamTag}>
                    Echipa câștigătoare - "Echoes of presence"
                </div>
            </div>

            <ArticleContainer background={'#00D1D2'} textColor={'#F6F4F0'}>
                <ArticleImage cover={true} image={winners} />

                <div className={classNames(styles.contentMargin, 'content')}>
                    <p>
                        „Parcursul este o descoperire a celuilalt, din ce în ce mai profundă. A
                        auzi, a simți și a cunoaște sunt temele celor 3 camere în care omul este
                        surprins de un ambient în continuă schimbare.”
                    </p>
                    <p>
                        <strong>Alina Rizescu</strong>
                    </p>
                </div>
            </ArticleContainer>

            <div className="content">
                <p>
                    Planșele proiectului te pot ajuta să-ți faci o idee despre ce vei descoperi la
                    RDW. Descoperă-le mai jos!
                </p>
            </div>

            <ArticleContainer background={'transparent'}>
                <KeenSlider withArrows={false} mode={'snap'} spacing={10}>
                    <div className={styles.slideContainer}>
                        <ArticleImage image={slide1} />
                    </div>

                    <div className={styles.slideContainer}>
                        <ArticleImage image={slide2} />
                    </div>

                    <div className={styles.slideContainer}>
                        <ArticleImage image={slide3} />
                    </div>
                </KeenSlider>
            </ArticleContainer>
        </section>
    );
};

export default ReConnectWinner;
