// Assets DragGame 1
import drag1 from './assets/drag1.1.png';
import drag2 from './assets/drag1.2.png';
import drag3 from './assets/drag1.3.png';
import drag4 from './assets/drag1.4.png';

import drag1G from './assets/drag1.1-guessed.png';
import drag2G from './assets/drag1.2-guessed.png';
import drag3G from './assets/drag1.3-guessed.png';
import drag4G from './assets/drag1.4-guessed.png';

import drag1NG from './assets/drag1.1-notguessed.png';
import drag2NG from './assets/drag1.2-notguessed.png';
import drag3NG from './assets/drag1.3-notguessed.png';
import drag4NG from './assets/drag1.4-notguessed.png';

import message1 from './assets/message1.png';
import message2 from './assets/message2.png';
import message3 from './assets/message3.png';
import message4 from './assets/message4.png';

import header from './assets/header.png';

// Slide assets

import slide1 from './assets/slider/slide1.png';
import slide2 from './assets/slider/slide2.png';
import slide3 from './assets/slider/slide3.png';
import slide4 from './assets/slider/slide4.png';

export const dragGameData = [
    {
        id: 1,
        slug: 'category-motivation-joc-6-card-drag-quiz',
        header: header,
        title: 'Ești pregătit pentru o nouă provocare interactivă?',
        description: `E simplu! Explorează beneficiile IQOS și
                      potrivește-le cu imaginile despre riscurile fumatului asupra sănătății.
                      <br/>
                      Pe locuri, fii gata, START!`,
        successTitle: 'Bravo!',
        successSubTitle: 'Ai asociat corect afirmațiile cu beneficiile IQOS.',
        successText: `Iată cele mai importante informații pe care trebuie să le reții:`,
        failTitle: 'Mai încearcă',
        failSubTitle: 'Din păcate, nu ai asociat corect afirmațiile cu beneficiile IQOS.',
        failText: `Iată cele mai importante informații pe care trebuie să le reții:`,
        data: [
            {
                region: {
                    image: message1,
                    imageGuessed: drag1G,
                    imageNotGuessed: drag1NG
                },
                card: {
                    image: drag1,
                    imageNotGuessed: drag1NG
                }
            },
            {
                region: {
                    image: message2,
                    imageGuessed: drag2G,
                    imageNotGuessed: drag2NG
                },
                card: {
                    image: drag2,
                    imageNotGuessed: drag2NG
                }
            },
            {
                region: {
                    image: message3,
                    imageGuessed: drag3G,
                    imageNotGuessed: drag3NG
                },
                card: {
                    image: drag3,
                    imageNotGuessed: drag3NG
                }
            },
            {
                region: {
                    image: message4,
                    imageGuessed: drag4G,
                    imageNotGuessed: drag4NG
                },
                card: {
                    image: drag4,
                    imageNotGuessed: drag4NG
                }
            }
        ]
    }
];

export const sliderData = [
    {
        id: 1,
        title: 'Pentru că IQOS încălzește tutunul, în loc să îl ardă, nu mai trebuie să îți faci griji din cauza scrumului.',
        subtitle: `Astfel vei avea parte de o experiență mai curată de fiecare dată. <br/><br/>
                   Descoperă mai multe pe <a href="https://www.iqos.ro/" target="_blank" rel="noopener noreferrer" >iqos.ro.</a>`,
        image: slide1
    },
    {
        id: 2,
        title: '“Gudron” este reziduul produs în urma arderii țigării.',
        subtitle: `IQOS nu produce gudron, pentru că încălzește tutunul, în loc să îl ardă. În  schimb, reziduul din aerosolul generat este fundamental diferit de gudron și conține un nivel mai redus de substan’e nocive.* <br/><br/>
                   Descoperă mai multe pe <a href="https://www.iqos.ro/" target="_blank" rel="noopener noreferrer" >iqos.ro.</a> <br/><br/>
                   <span>*Reduceri medii ale nivelurilor unei game de substanțe nocive (excluzând nicotina), comparativ cu fumul de la o țigară de referință.</span>
                    `,
        image: slide2
    },
    {
        id: 3,
        title: 'IQOS nu produce fum.',
        subtitle: `Astfel, lasă mai puțin miros pe mâini, în păr sau pe haine și e mai puțin probabil să îi deranjeze pe ceilalți din jur în comparație cu fumul de țigară. <br/><br/>
                   Descoperă mai multe pe <a href="https://www.iqos.ro/" target="_blank" rel="noopener noreferrer" >iqos.ro.</a>`,
        image: slide3
    },
    {
        id: 4,
        title: 'IQOS nu arde tutunul, ci îl încălzește, și eliberează, astfel, în medie cu 98% mai puțin monoxid de carbon (CO) și niveluri semnificativ mai reduse ale altor substanțe nocive, în comparație cu țigările.*',
        subtitle: `*Sursă: Am măsurat nivelurile anumitor subtanțe nocive, prioritizate pentru raportare de către organizații de sănătate renumite, iar nivelurile acestor substanțe sunt reduse, în medie, cu 95% comparat cu fumul de țigară (excluzând nicotina). <br/><br/>
                   Descoperă mai multe pe <a href="https://www.iqos.ro/" target="_blank" rel="noopener noreferrer" >iqos.ro.</a>`,
        image: slide4
    }
];
