// New instance of Axios
import axios, { AxiosError } from 'axios';

// Utils
import { config } from '@utils/config';
import store from 'store';

// Errors
import Errors, { logoutUser } from '@utils/errorsHandler';
import { isRefreshTokenExpired } from '@utils/axios';

const refreshTokenAsync = async () => {
    const refreshToken = store.get(config.user.refreshToken);
    const refreshTokenExpired = isRefreshTokenExpired();
    const isPWA = !!store.get(config.user.isPWA);

    if (refreshTokenExpired) {
        logoutUser('refreshTokenExpired is expired in refreshTokenAsync');
    } else if (refreshToken) {
        try {
            const { data } = await axios.get('/token/create', {
                baseURL: config.apiUrl,
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    'X-API-KEY': `Bearer ${refreshToken}`,
                    isPWA: isPWA
                }
            });
            store.set(config.user.authToken, data.token);
            store.set(config.user.authTokenExpiresAt, data.authToken_expires_at);
        } catch (e) {
            Errors.errorSwitch((e as AxiosError).response?.status);
        }
    }
};

export default refreshTokenAsync;
