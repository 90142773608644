export const PWABadgesIcon = () => {
    return (
        <svg
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M37.61 8.14C39.9679 8.2485 41.2489 8.6417 42.1017 8.9742C43.231 9.4128 44.036 9.9367 44.883 10.7837C45.73 11.6307 46.2539 12.4357 46.6925 13.565C47.0239 14.4178 47.4182 15.6988 47.5255 18.0567C47.6422 20.6082 47.6667 21.3735 47.6667 27.8333C47.6667 34.2932 47.6422 35.0585 47.5267 37.61C47.4182 39.9678 47.025 41.2488 46.6925 42.1017C46.2539 43.231 45.73 44.036 44.883 44.883C44.036 45.73 43.231 46.2538 42.1017 46.6925C41.2489 47.0238 39.9679 47.4182 37.61 47.5255C35.0585 47.6422 34.2932 47.6667 27.8334 47.6667C21.3735 47.6667 20.6082 47.6422 18.0567 47.5267C15.6988 47.4182 14.4178 47.025 13.565 46.6925C12.4357 46.2538 11.6307 45.73 10.7837 44.883C9.93668 44.036 9.41278 43.231 8.97418 42.1017C8.64166 41.2488 8.2485 39.9667 8.14 37.61C8.02333 35.0573 8 34.292 8 27.8333C8 21.3735 8.0245 20.6082 8.14 18.0567C8.24966 15.7 8.64166 14.419 8.97528 13.565C9.41398 12.4357 9.93778 11.6307 10.7848 10.7837C11.6318 9.9367 12.4357 9.4128 13.565 8.9742C14.4178 8.6417 15.7 8.2485 18.0567 8.14C20.6093 8.0233 21.3747 8 27.8334 8C34.2932 8 35.0585 8.0245 37.61 8.14Z"
                stroke="white"
                strokeWidth="2.33333"
            />
            <path
                d="M27.9263 15L39.2466 20.3014V27.2707C39.2424 33.7423 35.5017 38.6516 28.0233 42L27.9966 41.92L27.9699 42C20.5828 38.6923 16.8422 33.8617 16.7494 27.5053L16.7466 27.2721V20.3014L27.9263 15Z"
                fill="#FFFDFB"
            />
            <mask
                id="mask0_150_2170"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="16"
                y="15"
                width="24"
                height="27"
            >
                <path
                    d="M27.9263 15L39.2466 20.3014V27.2707C39.2424 33.7423 35.5017 38.6516 28.0233 42L27.9966 41.92L27.9699 42C20.5828 38.6923 16.8422 33.8617 16.7494 27.5053L16.7466 27.2721V20.3014L27.9263 15Z"
                    fill="#FFFDFB"
                />
            </mask>
            <g mask="url(#mask0_150_2170)">
                <path
                    d="M28.0002 15C32.0097 15 35.8549 16.4866 38.69 19.1326C41.5251 21.7787 43.1178 25.3676 43.1178 29.1097C43.1178 32.8519 41.5251 36.4408 38.69 39.0868C35.8549 41.7329 32.0097 43.2195 28.0002 43.2195V15Z"
                    fill="#00D1D2"
                />
                <path
                    d="M32.0539 32.515C31.1568 33.8231 29.8142 34.9514 27.9974 35.8877C26.2036 34.9632 24.872 33.8515 23.9752 32.5645C23.047 31.2322 22.5664 29.6863 22.543 27.9087C22.543 27.9085 22.543 27.9083 22.5429 27.9081L22.5415 27.7748V23.9788L27.9616 21.123L33.4533 23.9805V27.7764C33.4522 29.5882 32.9821 31.1614 32.0539 32.515Z"
                    fill="#00D1D2"
                    stroke="#34303D"
                    strokeLinejoin="round"
                />
            </g>
        </svg>
    );
};
