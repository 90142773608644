import classnames from 'classnames';
import styles from './ArticleImage.module.scss';
import { IArticleImageProps } from './interface';

// Helpers
import { useWindowSize } from '@utils/hooks/windowSize';
import { PlayIcon } from '@assets/icons';

const ArticleImage = (props: IArticleImageProps) => {
    const windowSize = useWindowSize();
    const isMobile = windowSize.width < 750;

    const {
        image,
        title,
        subtitle,
        cover,
        onClick,
        mobileImage,
        isVideo,
        imgPosition,
        fullHeight = false,
        noMargin = false
    } = props;

    return (
        <div
            onClick={onClick}
            className={classnames(
                styles.container,
                cover && styles.cover,
                onClick && styles.pointer,
                fullHeight && styles.fullHeight,
                noMargin && styles.noMargin
            )}
        >
            {title && <h3 className={styles.title}>{title}</h3>}

            <img
                style={{ objectPosition: imgPosition }}
                src={mobileImage && isMobile ? mobileImage : image}
                alt=""
            />

            {subtitle && <p className={styles.subtitle}>{subtitle}</p>}

            {isVideo && (
                <div className={styles.playButton}>
                    <PlayIcon />
                </div>
            )}
        </div>
    );
};

export default ArticleImage;
