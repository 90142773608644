import { useEffect, useState } from 'react';
import { ICardDragGame } from './interface';
import styles from './CardDragGame.module.scss';

// Components
import CardDrag from '@containers/IQOSUniverse/articles/MotivationCardDragGame/components/CardDrag';

// Utils
import { shuffle } from 'lodash';

// DND Kit
import {
    DndContext,
    DragEndEvent,
    MouseSensor,
    TouchSensor,
    useSensor,
    useSensors
} from '@dnd-kit/core';
import RegionDrag from '@containers/IQOSUniverse/articles/MotivationCardDragGame/components/RegionDrag';

const CardDragGame = ({ data, onWin, onLose }: ICardDragGame) => {
    const maxGuesses = 6;
    const pairs = 4;
    const [wrongCount, setWrongCount] = useState(0);
    const [gameEnded, setGameEnded] = useState(false);

    const fourRandomPairs = shuffle(data).slice(0, pairs);
    const regions = fourRandomPairs.map(({ region }, key) => ({ region, id: key.toString() }));
    const cards = fourRandomPairs.map(({ card }, key) => ({ card, id: key.toString() }));

    const [shuffledRegions] = useState<typeof regions>(shuffle(regions));
    const [shuffledCards, setShuffledCards] = useState<typeof cards>(shuffle(cards));
    const [guessedCards, setGuessedCards] = useState<typeof cards>([]);

    const mouseSensor = useSensor(MouseSensor);
    const touchSensor = useSensor(TouchSensor);
    const sensors = useSensors(mouseSensor, touchSensor);

    const showGuessedCardInRegion = (regionID: string) =>
        guessedCards.map(({ id, card }) => {
            if (id === regionID) {
                return <CardDrag disabled key={id} card={card} id={id} />;
            }
        });

    const handleDragEnd = ({ active, over }: DragEndEvent) => {
        const cardID = active.id.toString();
        const regionID = over?.id.toString();

        if (cardID && regionID) {
            // If card is dropped in the correct region, add it to guessed cards list and remove it from card's deck
            if (cardID === regionID) {
                const guessedCard = shuffledCards.find((card) => card.id === cardID);

                if (guessedCard) {
                    setGuessedCards((prevCards) => [...prevCards, guessedCard]);
                    setShuffledCards((prevCards) => prevCards.filter((card) => card.id !== cardID));
                }
            } else {
                setWrongCount((wrongCount) =>
                    wrongCount !== maxGuesses ? wrongCount + 1 : maxGuesses
                );
            }
        }
    };

    useEffect(() => {
        if (wrongCount >= maxGuesses && !gameEnded) {
            setGameEnded(true);
            onLose();
        }
    }, [wrongCount]);

    useEffect(() => {
        if (guessedCards.length === pairs && !gameEnded) {
            setGameEnded(true);
            onWin();
        }
    }, [guessedCards]);

    return (
        <DndContext onDragEnd={handleDragEnd} sensors={sensors}>
            <div className={styles.container}>
                <div className={styles.regions}>
                    {shuffledRegions.length > 0 &&
                        shuffledRegions.map(({ region, id }) => (
                            <RegionDrag key={id} region={region} id={id}>
                                {showGuessedCardInRegion(id)}
                            </RegionDrag>
                        ))}
                </div>

                <div className={styles.cards}>
                    {shuffledCards.length > 0 &&
                        shuffledCards.map((item) => (
                            <CardDrag rotate={true} key={item.id} card={item.card} id={item.id} />
                        ))}
                </div>
                <div className={styles.wrongCount}>
                    Număr încercări: {wrongCount} din {maxGuesses}
                </div>
            </div>
        </DndContext>
    );
};

export default CardDragGame;
