import { useState } from 'react';
import styles from './NewLoveRubyFuse.module.scss';

// Helpers
import classNames from 'classnames';

// Assets
import image1 from './assets/image22.jpg';
import image2 from './assets/image2.jpg';
import videoThumbnail from './assets/thumb2.jpg';

// Components
import VideoModal from '@components/VideoModal';
import ArticleImage from '@containers/IQOSUniverse/components/ArticleImage';
import ArticleHeader from '@containers/IQOSUniverse/components/ArticleHeader';
import ArticleContainer from '@containers/IQOSUniverse/components/ArticleContainer';

const NewLoveRubyFuse = () => {
    const [toggleModal, setToggleModal] = useState(false);

    return (
        <section className={styles.wrapper}>
            <ArticleHeader />

            <div className="content">
                <h3>Credem în tipurile de iubire în care crezi și tu.</h3>

                <p>
                    Inspirați de aceste tipuri de iubire am creat și prima colecție de accesorii
                    reciclate pentru IQOS Originals DUO. Astfel, filosofia IQOS de a merge înainte
                    și de a îmbrățișa transformarea capătă noi dimensiuni.
                </p>

                <p>
                    Credem în iubirea care evoluează continuu, dar mai ales credem în tipurile de
                    iubire care ni se potrivesc.
                </p>
            </div>

            <ArticleContainer background={'#f6f4f0'}>
                <div className="content">
                    <ArticleImage image={image1} />

                    <h3>Explorează tipurile de iubire care ți se potrivesc</h3>

                    <p>
                        Deschidem seria de discuții despre 4 tipuri de iubire alături de
                        psihoterapeuții Domnica Petrovai și Raul Lupaș. Ei ne vor împărtăși
                        secretele fiecărui tip de iubire, prin prisma iubirii de sine, pentru că
                        aceasta este fundația pe care toate celelalte iubiri cresc și se
                        reinventează continuu.
                    </p>
                </div>
            </ArticleContainer>

            <ArticleContainer background={'#FFF4A2'}>
                <div className={classNames(styles.contentMarginTop, 'content')}>
                    <h3>Iubirea pentru comunitate</h3>

                    <ArticleImage image={image2} />

                    <p className={styles.rubyMarginTop}>
                        Ești pregătit să iubești matur atunci când știi ce înseamnă împreună, când
                        poți să iubești oamenii din jur, în ciuda imperfecțiunilor și
                        vulnerabilităților lor. Încearcă să iei ce e mai bun din felul lor de a fi
                        și asumă-ți că orice conflict este, de fapt, o ocazie perfectă să-ți testezi
                        autocontrolul și să-ți conștientizezi propriile orgolii și nesiguranțe.
                    </p>

                    <p>
                        Și, poate cel mai important, nu ezita să-ți exprimi recunoștința, atât față
                        de tine, cât și față de oamenii din viața ta, pentru că așa creezi acceptare
                        și încredere.
                    </p>
                </div>
            </ArticleContainer>

            <ArticleContainer background={'#00D1D2'}>
                <div className="content">
                    <ArticleImage
                        isVideo
                        mobileImage={videoThumbnail}
                        image={videoThumbnail}
                        onClick={() => setToggleModal(true)}
                    />

                    <VideoModal
                        toggleModal={toggleModal}
                        setToggleModal={() => setToggleModal(false)}
                        video="796930001"
                    />
                </div>
            </ArticleContainer>
        </section>
    );
};

export default NewLoveRubyFuse;
