export const MenuBadgesIcon = () => {
    return (
        <svg
            width="23"
            height="23"
            viewBox="0 0 23 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_5185_195463)">
                <path
                    d="M18.3156 16.05C16.8149 18.081 14.5613 19.84 11.5 21.3019C8.47754 19.8585 6.24215 18.1253 4.74174 16.1266C3.19413 14.0651 2.39645 11.6795 2.35743 8.93753C2.35742 8.93718 2.35742 8.93683 2.35741 8.93647L2.355 8.72976V3.03752L11.4386 1.10917L20.645 3.03931V8.73297C20.6432 11.5281 19.8629 13.9558 18.3156 16.05Z"
                    stroke="#34303D"
                    strokeWidth="1.71"
                    strokeLinejoin="bevel"
                />
            </g>
            <defs>
                <clipPath id="clip0_5185_195463">
                    <rect width="22" height="22" fill="white" transform="translate(0.5 0.235352)" />
                </clipPath>
            </defs>
        </svg>
    );
};
