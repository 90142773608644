import { useEffect, useState } from 'react';
import styles from './Progress.module.scss';

// Components
import { Collapse } from 'antd';
import KeenSlider from '@components/KeenSlider';
import { CheckedIconFull } from '@containers/Campaigns/IlumaSummer/components/Icons';
import DateBadge from '@containers/Campaigns/CallForDreams/components/DateBadge';
import CustomDisclaimer from '@components/CustomDisclaimer';
import ReactPlayer from 'react-player';

import videoThumbnail from './thumbnail.jpg';

// Utils
import classNames from 'classnames';
import { useWindowSize } from '@utils/hooks/windowSize';
import dayjs from '@utils/dayjs';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { progressData } from '../../progressData';
import classnames from 'classnames';
import { seenVideo } from '../../realPeopleRealConnectionsSlice';

const Progress = () => {
    const window = useWindowSize();
    const isMini = window.width <= 650;

    const { videos, current_video } = useAppSelector((state) => state.realPeopleRealConnections);

    const [activeKey, setActiveKey] = useState(current_video?.has_seen_video ? 0 : 1);

    //! videos[idx]?.games_played === 0 pentru culoarea de la carduri
    // daca e expirat si nu a vazut video-ul de folosit logica de mai jos
    // videos[idx]?.has_seen_video ? (
    //     <CheckedIconFull white={slide.id === 2} />
    // )

    return (
        <Collapse
            className={styles.collapse}
            defaultActiveKey={[activeKey]}
            ghost
            expandIconPosition={'right'}
        >
            <Collapse.Panel header={<Header data={progressData} videos={videos} />} key="1">
                <div id={'dreamsSlider'} className={styles.dreamsSlider}>
                    <KeenSlider
                        origin={isMini ? 'center' : 'auto'}
                        mode="snap"
                        navigateToSlide={videos.findIndex((video: any) => video.current_video)}
                        spacing={16}
                        withArrows={false}
                        perView={isMini ? 1 : 'auto'}
                    >
                        {progressData.map((slide: (typeof progressData)[0], idx) => (
                            <div
                                key={slide.id}
                                className={classNames(
                                    dayjs().isAfter(dayjs(videos[idx]?.start_date)) &&
                                        styles.notDisabled,
                                    styles.dreamsSliderItem,
                                    dayjs().isAfter(dayjs(videos[idx]?.start_date)) &&
                                        videos[idx]?.games_played === 0 &&
                                        dayjs().isAfter(videos[idx]?.end_date) &&
                                        styles.notPlayed
                                )}
                                style={{
                                    backgroundColor: slide.background_color_ended_campaign
                                }}
                            >
                                <div
                                    className={classnames(
                                        styles.details,
                                        dayjs().isAfter(dayjs(videos[idx]?.start_date)) &&
                                            slide.text_color === 'light' &&
                                            styles.light
                                    )}
                                >
                                    <div
                                        className={classnames(
                                            styles.title,
                                            styles.endedCampaignCardTitle
                                        )}
                                    >
                                        <h3>{slide.title}</h3>
                                    </div>

                                    <DateBadge date={slide.date} disabled />
                                    {dayjs().isAfter(videos[idx]?.end_date) ? (
                                        <CustomDisclaimer
                                            className={styles.customDisclaimer}
                                            error
                                            lightBkg={false}
                                            label={slide.expired_subtitle}
                                        />
                                    ) : (
                                        <p>{slide.subtitle}</p>
                                    )}
                                </div>
                                <div className={styles.imageContainer}>
                                    <div className={styles.vimeoContainer}>
                                        {dayjs().isBefore(dayjs(videos[idx]?.start_date)) && (
                                            <div className={styles.blurVideo}></div>
                                        )}
                                        {videos[idx] && (
                                            <ReactPlayerComponent
                                                slide={slide}
                                                video={videos[idx]}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </KeenSlider>
                </div>
            </Collapse.Panel>
        </Collapse>
    );
};

const Header = ({ data, videos }: { data: any[]; videos: any[] }) => {
    const currentStage = videos.findIndex((video: any) => video.current_video);

    return (
        <div className={styles.progressContainer}>
            <div className={styles.progressBar}>
                {data.map((item, idx) => (
                    <div
                        key={item.id}
                        className={classNames(
                            styles.progressItem,
                            currentStage + 1 > item.id && styles.inactive
                        )}
                        style={{
                            background:
                                item.id < currentStage + 1
                                    ? item.background_color_inactive
                                    : item.id === currentStage + 1
                                    ? item.background_color
                                    : ''
                        }}
                    >
                        <div className={styles.tooltipContainer}>
                            <div className={styles.tooltip}>{`Etapa ${item.id}`}</div>
                            <div className={styles.point}></div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

const ReactPlayerComponent = ({ slide, video }: { slide: any; video: any }) => {
    const dispatch = useAppDispatch();

    const [playing, setPlaying] = useState(false);
    const [progress, setProgress] = useState(0);
    const [duration, setDuration] = useState(0);
    const [isPastPercentage, setIsPastPercentage] = useState(false);

    const triggerPercentage = 75; // 75%

    const handleTimeUpdate = ({ playedSeconds }: any) => {
        setIsPastPercentage(false);

        if (playing) {
            if (playedSeconds < progress) {
                //don't update progress if user seeked back
            } else {
                setProgress((prevProgress) => prevProgress + 1);
            }
        }

        const durationPercentage = (triggerPercentage * duration) / 100;

        if (progress >= durationPercentage) {
            setIsPastPercentage(true);
        }
    };

    useEffect(() => {
        if (!video.has_seen_video && isPastPercentage && video.current_video) {
            dispatch(seenVideo(video?.id || 0));
        }
    }, [isPastPercentage]);

    return (
        <ReactPlayer
            playing={playing}
            url={slide.videoLink}
            width="100%"
            height="100%"
            controls={dayjs().isAfter(dayjs(video?.start_date))}
            playsinline
            light={<img src={videoThumbnail} alt="Phase VideoThumbnail" />}
            config={{
                file: {
                    attributes: {
                        disablePictureInPicture: true,
                        disableRemotePlayback: true,
                        controlsList: 'nodownload noplaybackrate'
                    }
                }
            }}
            onClickPreview={() => setPlaying(true)}
            onPlay={() => setPlaying(true)}
            onPause={() => setPlaying(false)}
            onDuration={(d) => setDuration(d)}
            onProgress={handleTimeUpdate}
        />
    );
};

export default Progress;
