export const PlayIcon = () => {
    return (
        <svg
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="50" height="50" rx="25" fill="#00D1D2" />
            <path
                d="M19.3492 35.0351C19.7989 35.0351 20.1812 34.8552 20.631 34.5966L33.7407 27.0185C34.6739 26.4676 35 26.1078 35 25.5119C35 24.916 34.6739 24.5562 33.7407 24.0165L20.631 16.4272C20.1812 16.1687 19.7989 16 19.3492 16C18.5172 16 18 16.6296 18 17.6078V33.416C18 34.3942 18.5172 35.0351 19.3492 35.0351Z"
                fill="white"
            />
        </svg>
    );
};
