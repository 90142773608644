import { useState } from 'react';
import styles from './Hangman.module.scss';

// Components
import Button from '@components/Layout/Button';
import ArticleHeader from '@containers/IQOSUniverse/components/ArticleHeader';
import ArticleContainer from '@containers/IQOSUniverse/components/ArticleContainer';

// Redux
import { useAppSelector } from '@app/hooks';
import GameModal from '@containers/IQOSUniverse/components/GameModal';

const Hangman = () => {
    const [isVisible, setIsVisible] = useState(false);

    const { article } = useAppSelector((state) => state.iqosUniverse);

    return (
        <section className={styles.wrapper}>
            <ArticleHeader backgroundColor="#f6f4f0" notFullWidth />

            <ArticleContainer background={'#f6f4f0'}>
                <div className="content">
                    <h3 dangerouslySetInnerHTML={{ __html: article.title }} />
                    <p dangerouslySetInnerHTML={{ __html: article.subtitle }} />

                    <Button
                        type="text"
                        color="dark"
                        label="Începe acum"
                        onClick={() => setIsVisible(true)}
                    />
                </div>
            </ArticleContainer>

            <GameModal
                slug={article.slug}
                isVisible={isVisible}
                handleClose={() => setIsVisible(false)}
            />
        </section>
    );
};

export default Hangman;
