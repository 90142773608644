import axiosRequest from '@utils/axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import qs from 'qs';

interface AxiosError {
    response: {
        data: {
            message: string;
        };
    };
}

export interface ListStoreLocations {
    status: 'idle' | 'loading' | 'failed';
    listLocations: {
        data: Array<{
            name: string | null;
            address: string | null;
            city: null | string;
            county: null | string;
        }>;
        meta: {
            current_page: number;
            per_page: number;
            total: number;
        };
    };
    error: any;
    counties: Array<string>;
    cities: [];
}

export interface Options {
    filter?: {
        county?: string | null;
        city?: string | null;
        type?: string | null;
    };
    per_page?: number;
    page?: number;
}

const initialState: ListStoreLocations = {
    status: 'idle',
    listLocations: {
        data: [],
        meta: {
            current_page: 1,
            per_page: 5,
            total: 1
        }
    },
    error: {},
    counties: [],
    cities: []
};

export const getLocationStores = createAsyncThunk('stores-locations', async (values: Options) => {
    const options = qs.stringify(values, { skipNulls: true });

    try {
        const response = await axiosRequest
            .get(`location-stores?${decodeURI(options)}`)
            .then((res) => {
                return res;
            });
        return response.data;
    } catch (e) {
        return (e as AxiosError).response.data.message;
    }
});

export const getLocationStoresCounties = createAsyncThunk('stores-locations/counties', async () => {
    try {
        const response = await axiosRequest.get('location-stores/counties').then((response) => {
            return response;
        });
        return response.data.counties;
    } catch (err) {
        return (err as AxiosError).response.data.message;
    }
});

export const getLocationStoresCities = createAsyncThunk(
    'stores-locations/cities',
    async (values: { county: string }) => {
        try {
            const response = await axiosRequest
                .get(`location-stores/cities`, { params: values })
                .then((response) => {
                    return response;
                });
            return response.data.cities;
        } catch (err) {
            return (err as AxiosError).response.data.message;
        }
    }
);

export const locationStoresSlice = createSlice({
    name: 'listStoreLocations',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getLocationStores.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getLocationStores.fulfilled, (state, action) => {
                state.status = 'idle';
                state.listLocations = action.payload;
            })
            .addCase(getLocationStores.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })

            .addCase(getLocationStoresCounties.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getLocationStoresCounties.fulfilled, (state, action) => {
                state.status = 'idle';
                state.counties = action.payload;
            })
            .addCase(getLocationStoresCounties.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })

            .addCase(getLocationStoresCities.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getLocationStoresCities.fulfilled, (state, action) => {
                state.status = 'idle';
                state.cities = action.payload;
            })
            .addCase(getLocationStoresCities.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    }
});

export default locationStoresSlice.reducer;
