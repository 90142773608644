import { ForwardArrow } from '@assets/icons';

//profile images
import ProfileImg1 from './assets/ProfileImg1.jpg';
import ProfileImg2 from './assets/ProfileImg2.jpg';
import ProfileImg3 from './assets/ProfileImg3.jpg';
import ProfileImg4 from './assets/ProfileImg4.jpg';
import ProfileImg5 from './assets/ProfileImg5.jpg';

//profile images - mobile
import ProfileImg1Mobile from './assets/ProfileImg1Mobile.jpg';
import ProfileImg2Mobile from './assets/ProfileImg2Mobile.jpg';
import ProfileImg3Mobile from './assets/ProfileImg3Mobile.jpg';
import ProfileImg4Mobile from './assets/ProfileImg4Mobile.jpg';
import ProfileImg5Mobile from './assets/ProfileImg5Mobile.jpg';

//signatures
import Signature1 from './assets/Signature1.png';
import Signature2 from './assets/Signature2.png';
import Signature3 from './assets/Signature3.png';
import Signature4 from './assets/Signature4.png';
import Signature5 from './assets/Signature5.png';

//extras
import MyProfile from './assets/MyProfile.png';
import Badges from './assets/Badges.png';

const CarouselArrow = (props: any) => {
    return (
        <div className={props.className} onClick={props.onClick}>
            <ForwardArrow />
        </div>
    );
};

const sliderOneSettings = {
    infinite: true,
    autoplay: true,
    speed: 300,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    pauseOnHover: true,
    arrows: true,
    dots: false,
    nextArrow: <CarouselArrow />,
    prevArrow: <CarouselArrow />,
    draggable: true
};

const sliderTwoSettings = {
    infinite: true,
    autoplay: false,
    speed: 300,
    autoplaySpeed: 6000,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    pauseOnHover: true,
    arrows: true,
    dots: false,
    nextArrow: <CarouselArrow />,
    prevArrow: <CarouselArrow />,
    draggable: true
};
const sectionsData: Array<{
    id: number;
    bgColor: string;
    title: string;
    videoId?: string;
    videoIdMobile?: string;
    image?: any;
    description: string;
    textColor: string;
}> = [
    {
        id: 1,
        bgColor: '#34303D',
        title: 'Homepage',
        videoId: '820488497',
        videoIdMobile: '820488521',
        textColor: '#F6F4F0',
        description:
            'Poți să-l consideri turnul de control al contului tău, fiindcă din Homepage poți să accesezi orice dorești, într-un mod foarte intuitiv. Fie că vrei să navighezi spre cele mai recente concursuri, să vezi ce s-a mai întâmplat în comunitate sau să afli situația din Leaderboard, noul Homepage este aici ca să îți ofere libertate deplină.'
    },
    {
        id: 2,
        bgColor: '#00D1D2',
        title: 'Profilul meu',
        image: MyProfile,
        textColor: '#34303D',
        description:
            'Pentru că e mult mai frumos să poți să îți personalizezi experiența exact așa cum vrei. Mai mult decât atât, vei vedea că până și ofertele sau recompensele sunt personalizate pe gustul tău! În felul acesta, îți pui inspirația la treabă ca să creezi un spațiu al tău, la care te conectezi oricând alegi tu, direct din <b>Profilul meu</b>.'
    },
    {
        id: 3,
        bgColor: '#FFD657',
        title: 'Activity feed',
        videoId: '820488380',
        videoIdMobile: '820488413',
        textColor: '#34303D',
        description:
            'Activity Feed te ține la curent cu lucrurile pe care le fac ceilalți, fie că sărbătoresc o reușită sau pur și simplu aleg să își personalizeze profilul. Este o fucționalitate care îți permite să vezi ce se mai întâmplă în comunitatea IQOS Club. Mai mult decât atât, poți chiar să interacționezi cu ei atunci când le vezi activitatea, prin reacții precum <b>Love, Like</b> sau <b>Clap</b>.'
    },
    {
        id: 4,
        bgColor: '#00D1D2',
        title: 'Badges',
        image: Badges,
        textColor: '#34303D',
        description:
            'Badge-urile sunt niște medalii virtuale cu care ești decorat pentru devotamentul pe care-l arăți față de IQOS Club. Ca să le obții, e nevoie să faci diferite activități, iar majoritatea te vor ajuta să progresezi mai repede, pentru că vin la pachet și cu FP.'
    },
    {
        id: 5,
        bgColor: '#34303D',
        title: 'Leaderboard',
        videoId: '820488541',
        videoIdMobile: '820488565',
        textColor: '#F6F4F0',
        description:
            'FP sunt acele puncte cu ajutorul cărora vei ajunge, în cele din urmă, la statutul de membru Platinum, dacă nu ești deja acolo. Noua bară de progres, situată chiar sub fotografia ta de profil din <b>Homepage</b>, îți arată în ce stadiu te afli acum și cât mai ai până la următorul nivel. Tot acolo vei observa și un <b>Leaderboard</b> care se resetează în fiecare lună – acesta e clasamentul în care poți vedea cine sunt cei mai activi membri ai comunității, în funcție de numărul de <b>FP pe care au reușit să le strângă</b>.'
    }
];

const membersData: Array<{
    id: number;
    profileImg: any;
    mobileProfileImg: any;
    description: string;
    year: string;
    signatureImg: any;
}> = [
    {
        id: 1,
        profileImg: ProfileImg1,
        mobileProfileImg: ProfileImg1Mobile,
        description:
            'Noul Homepage mi se pare foarte ușor de folosit, mai ales că găsești toate informațiile chiar pe prima pagină.',
        year: '2022',
        signatureImg: Signature1
    },
    {
        id: 2,
        profileImg: ProfileImg2,
        mobileProfileImg: ProfileImg2Mobile,
        description:
            ' Îmi place faptul că poți să-ți personalizezi profilul, poți să adaugi o fotografie de profil. De fapt, ai posibilitatea să-ți personalizezi tot contul.',
        year: '2021',
        signatureImg: Signature2
    },
    {
        id: 3,
        profileImg: ProfileImg3,
        mobileProfileImg: ProfileImg3Mobile,
        description:
            'E foarte plăcut să poți să vezi ce fac ceilalți membri, simți că nu ești singur, simți că faci parte dintr-o comunitate globală.',
        year: '2019',
        signatureImg: Signature3
    },
    {
        id: 4,
        profileImg: ProfileImg4,
        mobileProfileImg: ProfileImg4Mobile,
        description:
            'Îmi place că pot colecționa badge-uri, e o funcție foarte drăguță și îmi place că pot să-mi urmăresc progresul.',
        year: '2022',
        signatureImg: Signature4
    },
    {
        id: 5,
        profileImg: ProfileImg5,
        mobileProfileImg: ProfileImg5Mobile,
        description: 'FP te ajută să avansezi și îți aduc foarte multe beneficii.',
        year: '2022',
        signatureImg: Signature5
    }
];

export { sliderOneSettings, sliderTwoSettings, membersData, sectionsData };
