// Utils
import { FC, ReactNode } from 'react';
import { config } from '@utils/config';
import store from 'store';
import { Navigate, useLocation } from 'react-router-dom';

// Interface
interface IProtectedRoute {
    redirectPath?: string;
    children: ReactNode;
}

const ProtectedRoute: FC<IProtectedRoute> = ({
    redirectPath = config.path.login,
    children
}: IProtectedRoute) => {
    const { pathname } = useLocation();
    const token = store.get(config.user.authToken);
    const profileHasEligibleDevice = store.get(config.user.profileHasEligibleDevice);
    const termsAndConditions = store.get(config.user.termsAndConditions);

    const saveRoute = (path: string) => {
        const currentPrevRoute = store.get(config.storage.previousRoute);
        const forbiddenPreviousRoutes = [
            `${process.env.REACT_APP_BASENAME}/404`,
            `${process.env.REACT_APP_BASENAME}${config.path.firstLogin}`,
            `${process.env.REACT_APP_BASENAME}${config.path.noDevice}`
        ];

        if (path === currentPrevRoute) {
            return;
        }

        if (!forbiddenPreviousRoutes.includes(path)) {
            store.set(config.storage.previousRoute, path);
        }
    };

    if (token) {
        if (pathname !== config.path.noDevice && pathname !== config.path.firstLogin) {
            if (termsAndConditions === false) {
                return <Navigate to={config.path.firstLogin} replace />;
            } else if (profileHasEligibleDevice === false) {
                return <Navigate to={config.path.noDevice} replace />;
            }
        }
    } else {
        saveRoute(window.location.pathname + window.location.search);
        return <Navigate to={redirectPath} replace />;
    }

    return <>{children}</>;
};

export default ProtectedRoute;
