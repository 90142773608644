export const MenuBenefitsIcon = () => {
    return (
        <svg
            width="23"
            height="23"
            viewBox="0 0 23 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_5185_195461)">
                <rect width="22" height="22" transform="translate(0.5 0.235352)" fill="white" />
                <path
                    d="M11.5 21.2354C17.0228 21.2354 21.5 16.7582 21.5 11.2354C21.5 5.7125 17.0228 1.23535 11.5 1.23535C5.97715 1.23535 1.5 5.7125 1.5 11.2354C1.5 16.7582 5.97715 21.2354 11.5 21.2354Z"
                    stroke="#34303D"
                    strokeWidth="1.71"
                />
                <path
                    d="M11.4995 5.61035L13.055 9.10819L16.5328 9.66773L14.0164 12.3906L14.6105 16.2354L11.4999 14.42L8.38938 16.2354L8.98349 12.3906L6.46704 9.66773L9.94488 9.10675L11.4995 5.61035Z"
                    stroke="#34303D"
                    strokeWidth="1.53"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_5185_195461">
                    <rect width="22" height="22" fill="white" transform="translate(0.5 0.235352)" />
                </clipPath>
            </defs>
        </svg>
    );
};
