export const StarCircledTurqouise = () => {
    return (
        <svg
            width="21"
            height="22"
            viewBox="0 0 21 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="0.963867"
                y="1.46436"
                width="19.0714"
                height="19.0714"
                rx="9.53571"
                fill="#FFFDFB"
            />
            <rect
                x="0.963867"
                y="1.46436"
                width="19.0714"
                height="19.0714"
                rx="9.53571"
                stroke="#00D1D2"
                stroke-width="1.5"
            />
            <path
                d="M10.1191 6.81112C10.2704 6.48882 10.7288 6.48881 10.8801 6.8111L11.7951 8.76026C11.8546 8.88719 11.9736 8.97604 12.1122 8.99717L14.1911 9.31403C14.5293 9.36559 14.6675 9.77812 14.4286 10.023L12.8981 11.5919C12.805 11.6874 12.7627 11.8214 12.7841 11.9531L13.1419 14.1461C13.1983 14.4924 12.8307 14.7514 12.5237 14.5816L10.7034 13.5752C10.5768 13.5052 10.4232 13.5052 10.2966 13.5752L8.47635 14.5816C8.16928 14.7514 7.80166 14.4924 7.85815 14.1461L8.21586 11.9531C8.23733 11.8214 8.19504 11.6874 8.10189 11.5919L6.57112 10.0228C6.33225 9.7779 6.47033 9.36545 6.80849 9.31378L8.88789 8.99602C9.02645 8.97485 9.14534 8.886 9.20489 8.75911L10.1191 6.81112Z"
                fill="#FFD657"
                stroke="#34303D"
                stroke-width="0.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};
