import { useState, useEffect } from 'react';

// Styles
import styles from './PWAPage.module.scss';

// Componets
import RewardBadge from '@components/RewardBadge';
import KeenSlider from '@components/KeenSlider';
import Button from '@components/Layout/Button';
import { Tabs } from 'antd';

// Utils
import { scroller } from 'react-scroll';
import useMobileOS, { MobileOS } from '@utils/hooks/useMobileOS';
import { useWindowSize } from '@utils/hooks/windowSize';

// Assets
import Img1 from './assets/img-1.jpg';
import Img2 from './assets/img-2.jpg';
import CarouselItems from './carousel';
import Android from './assets/android.mp4';
import iOS from './assets/ios.mp4';
import Samsung from './assets/samsung.mp4';

const PWAPage = ({ forwardPoints = 200 }) => {
    const [activeKey, setActiveKey] = useState<string>(MobileOS.Android);
    const window = useWindowSize();
    const mobileOS = useMobileOS();
    const isMobile = window.width < 640;

    useEffect(() => {
        if (mobileOS !== MobileOS.Unknown) {
            setActiveKey(mobileOS);
        }
    }, [mobileOS]);

    const scrollToTutorial = () => {
        scroller.scrollTo('tutorial', {
            duration: 1500,
            smooth: true,
            offset: isMobile ? -150 : 0
        });
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <img src={Img1} alt="Aplicația web IQOS Club" />
                <div className={styles.text}>
                    <h2>Aplicația web IQOS Club</h2>
                    <p>
                        Adaugă aplicația în ecranul principal al smartphone-ului tău și colecționezi{' '}
                        *{forwardPoints} FP! <br />{' '}
                        <p className={styles.disclaimer}>
                            *dacă nu ai mai instalat anterior aplicația
                        </p>
                    </p>
                    <div className={styles.badge}>
                        <RewardBadge
                            className={styles.rewardBadge}
                            absolute={false}
                            points={forwardPoints}
                        />
                    </div>
                </div>
            </div>

            <div className={styles.benefits}>
                <h2>
                    Fă un upgrade experienței tale de utilizare a platformei IQOS Club și descoperă
                    beneficiile!
                </h2>

                <KeenSlider withArrows={false} animated={true}>
                    {CarouselItems.map(({ id, icon, title, description }) => (
                        <div key={id} className={styles.benefitContainer}>
                            <div className={styles.benefit}>
                                <img src={icon} alt="" />
                                <h2>{title}</h2>
                                <p>{description}</p>
                            </div>
                        </div>
                    ))}
                </KeenSlider>
            </div>

            <div className={styles.container}>
                <img className={styles.withPadding} src={Img2} alt="Câștigă FP!" />
                <div className={styles.text}>
                    <h2>Câștigă {forwardPoints} FP!</h2>
                    <p>
                        După ce adaugi aplicația web pe ecranul principal, accesează IQOS Club prin
                        intermediul ei pentru a acumula {forwardPoints} FP în contul tău.
                    </p>
                    <Button
                        type="text"
                        color="light"
                        hover="light"
                        label="Adaugă acum"
                        onClick={scrollToTutorial}
                    />
                </div>
            </div>

            <div id="tutorial" className={styles.tabs}>
                <Tabs
                    type="card"
                    centered
                    activeKey={activeKey}
                    destroyInactiveTabPane
                    onChange={(clickedTab) => {
                        setActiveKey(clickedTab);
                    }}
                    items={[
                        {
                            label: MobileOS.Android,
                            key: MobileOS.Android,
                            children: (
                                <div className={styles.tutorial}>
                                    <video
                                        disableRemotePlayback={true}
                                        muted
                                        autoPlay
                                        playsInline
                                        loop
                                        preload="auto"
                                    >
                                        <source src={Android} />
                                    </video>
                                </div>
                            )
                        },
                        {
                            label: MobileOS.iOS,
                            key: MobileOS.iOS,
                            children: (
                                <div className={styles.tutorial}>
                                    <video
                                        disableRemotePlayback={true}
                                        muted
                                        autoPlay
                                        playsInline
                                        loop
                                        preload="auto"
                                    >
                                        <source src={iOS} />
                                    </video>
                                </div>
                            )
                        },
                        {
                            label: MobileOS.Samsung,
                            key: MobileOS.Samsung,
                            children: (
                                <div className={styles.tutorial}>
                                    <video
                                        disableRemotePlayback={true}
                                        muted
                                        autoPlay
                                        playsInline
                                        loop
                                        preload="auto"
                                    >
                                        <source src={Samsung} />
                                    </video>
                                </div>
                            )
                        }
                    ]}
                />
            </div>

            <div className={styles.instructions}>
                <div className={styles.instruction}>
                    <span>1</span>Accesează meniul din dreapta sus
                </div>
                <div className={styles.instruction}>
                    <span>2</span>Selectează “Adaugă pe ecran pornire”
                </div>
                <div className={styles.instruction}>
                    <span>3</span>În ultimul și penultimul pas selectează “Adaugă“ și “Adăugare”
                </div>
            </div>
        </div>
    );
};

export default PWAPage;
