import React, { useEffect, useState } from 'react';
import styles from './FestivalDuBonheure.module.scss';

// Helpers
import classnames from 'classnames';
import dayjs from '@utils/dayjs';

// Components
import ArticleHeader from '@containers/IQOSUniverse/components/ArticleHeader';
import BaseModal from '@components/Layout/BaseModal';
import VideoDisclaimer from '@containers/IQOSUniverse/components/VideoDisclaimer';
import VimeoPlayer from '@components/VimeoPlayer';
import RatingQuiz from '@containers/IQOSUniverse/components/RatingQuiz';
import CompletedStateArticle from '@containers/IQOSUniverse/components/CompletedStateArticle/CompletedStateArticle';
import ArticleImage from '@containers/IQOSUniverse/components/ArticleImage';
import ArticleContainer from '@containers/IQOSUniverse/components/ArticleContainer';
import MediaGallery from '@containers/IQOSUniverse/components/MediaGallery';

// Assets
import img01 from './assets/img1.jpg';
import img02 from './assets/img2.jpg';
import img03 from './assets/img3.jpg';
import img04 from './assets/img4.jpg';
import img05 from './assets/img5.jpg';
import img06 from './assets/img6.jpg';
import img07 from './assets/img7.jpg';
import video2Thumbnail from './assets/video2Bonheur.png';

// Redux
import { useAppSelector } from '@app/hooks';

const FestivalDuBonheure = () => {
    const [toggleModal, setToggleModal] = useState(false);
    const [toggleModal2, setToggleModal2] = useState(false);
    const [showDisclaimer, toggleDisclaimer] = useState(false);
    const [isPastPercentage, setIsPastPercentage] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [hidePlayer, setHidePlayer] = useState(false);
    const [videoEnded, setVideoEnded] = useState(false);

    const { article } = useAppSelector((state) => state.iqosUniverse);

    useEffect(() => {
        if (!article.completed_quiz && (article.quiz_points || article.points)) {
            toggleDisclaimer(true);
        }
    }, [article]);

    let playing = false;
    let progress = 0;
    const triggerMomentPercentage = 75; // 75%

    const handleTimeUpdate = (data: any) => {
        if (playing && data.seconds - progress <= 10) {
            if (data.seconds < progress) {
                //don't update progress if user seeked back
            } else {
                progress = data.seconds;
            }
        }

        const durationPercentage = (triggerMomentPercentage * data.duration) / 100;

        if (progress >= durationPercentage && !isPastPercentage) {
            setIsPastPercentage(true);
        }
    };

    useEffect(() => {
        if (videoEnded) {
            if (isPastPercentage && !article.completed_quiz) {
                setHidePlayer(true);
            } else {
                setTimeout(() => {
                    handleCloseModal();
                }, 1000);
            }
        }
    }, [videoEnded, isPastPercentage, article]);

    const handleCloseModal = () => {
        setToggleModal(false);
        setHidePlayer(false);
        setIsPastPercentage(false);
        toggleDisclaimer(true);
        setVideoEnded(false);
    };

    return (
        <section className={styles.wrapper}>
            <ArticleHeader
                videoQuiz={() => {
                    setToggleModal(true);
                }}
            />

            <div className="content">
                <p>
                    Între 27 aprilie și 1 mai ne-am întâlnit cu cei din comunitatea IQOS Club la
                    Mamaia, acolo unde a avut loc Festival du Bonheur. Printre peste 40 de
                    foodtrucks cu fel de fel de delicii, ne-am așezat IQOS Lounge-ul, acolo unde
                    i-am invitat pe vizitatori la o porție de relaxare.
                </p>

                <p>
                    În plus, membrii IQOS Club au putut să-și revendice badge-ul „Socialite”. Iar
                    pentru cei care nu au ajuns la Mamaia, între <strong>6-7 mai</strong> am
                    pregătit surprize pe Bulevardul Kiseleff din București. Vezi mai jos cum a fost.
                </p>
            </div>

            <div className={styles.space}>
                <ArticleContainer background={'#00D1D2'}>
                    <div className={styles.videoContainer}>
                        <ArticleImage
                            isVideo
                            mobileImage={video2Thumbnail}
                            image={video2Thumbnail}
                            onClick={() => setToggleModal2(true)}
                        />

                        <BaseModal
                            size="large"
                            maskClosable={true}
                            isModalOpen={toggleModal2}
                            className={styles.videoModal}
                            closeModal={() => setToggleModal2(false)}
                            destroyOnClose={true}
                        >
                            <div className={styles.modalContent}>
                                <VimeoPlayer
                                    className={styles.player}
                                    video="827603134"
                                    options={{
                                        autoplay: false,
                                        responsive: true
                                    }}
                                    onPlay={() => (playing = true)}
                                    onPause={() => (playing = false)}
                                    onEnd={() => setToggleModal2(false)}
                                />
                            </div>
                        </BaseModal>
                    </div>
                </ArticleContainer>
            </div>
            <MediaGallery images={[img01, img02, img03, img04, img05, img06, img07]} />

            <div className={classnames(styles.disclaimerContent, 'content')}>
                <p>
                    <strong>Nu uita!</strong> Atunci când ești membru în comunitatea IQOS Club, poți
                    fi oriunde și oricând surprins cu un beneficiu exclusiv.
                </p>
            </div>

            <div className={styles.space}>
                <BaseModal
                    size="large"
                    maskClosable={true}
                    isModalOpen={toggleModal}
                    className={styles.videoModal}
                    closeModal={() => handleCloseModal()}
                    destroyOnClose={true}
                >
                    <div className={styles.modalContent}>
                        {!article.completed_quiz &&
                            dayjs(article.quiz_end_date).isAfter(dayjs()) &&
                            showDisclaimer && (
                                <VideoDisclaimer handleClose={() => toggleDisclaimer(false)} />
                            )}

                        {isPastPercentage && article.quiz_slug && (
                            <div
                                className={classnames(styles.rating, hidePlayer && styles.padding)}
                            >
                                <RatingQuiz
                                    handleSuccess={() => setShowSuccess(true)}
                                    quizSlug={article.quiz_slug}
                                />
                            </div>
                        )}

                        {!hidePlayer && (
                            <VimeoPlayer
                                className={styles.player}
                                video="827603100"
                                options={{
                                    autoplay: false,
                                    responsive: true
                                }}
                                onPlay={() => (playing = true)}
                                onTimeUpdate={(progress) => handleTimeUpdate(progress)}
                                onPause={() => (playing = false)}
                                onEnd={() => setVideoEnded(true)}
                            />
                        )}
                    </div>
                </BaseModal>

                {(showSuccess || article.completed_quiz) && (
                    <CompletedStateArticle
                        article={{
                            points: article.points,
                            quiz_points: article.quiz_points,
                            quiz_end_date: article.quiz_end_date
                        }}
                    />
                )}
            </div>
        </section>
    );
};

export default FestivalDuBonheure;
