import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axiosRequest from '@utils/axios';
import {message} from "antd";

export interface InitialState {
    status: 'idle' | 'loading' | 'failed';
    error: any;
    data: [
        {
            id: number;
            title: string;
            picture: string;
            description: string;
            tag_name: string;
            slug: string;
        }
    ];
    pager: {
        current_page: number;
        last_page: number;
        per_page: number;
        total: number;
    };
}

interface AxiosError {
    response: {
        data: {
            message: string;
        };
    };
}

const initialState: InitialState = {
    status: 'loading',
    data: [
        {
            id: 0,
            title: '',
            picture: '',
            description: '',
            tag_name: '',
            slug: ''
        }
    ],
    error: {},
    pager: {
        current_page: 0,
        last_page: 0,
        per_page: 0,
        total: 0
    }
};

export const getPreviousChallenges = createAsyncThunk('monthlyChallenge/getPreviousChallenges', async (values: object) => {
    try {
        const response = await axiosRequest.get('/challenges/previous', { params: values }).then((res) => {
            return res;
        });

        return response.data;
    } catch (e) {
        return (e as AxiosError).response.data.message;
    }
});

export const previousChallengesSlice = createSlice({
    name: 'previousChallenges',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getPreviousChallenges.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getPreviousChallenges.fulfilled, (state, action) => {
                state.status = 'idle';
                state.pager = action.payload.meta;
                state.data = action.payload.data;
            })
            .addCase(getPreviousChallenges.rejected, (state, action) => {
                state.status = 'failed';
                message.error(`${action.payload}`, 10);
                state.error = action.payload;
            });
    }
});

export default previousChallengesSlice.reducer;
