import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axiosRequest from '@utils/axios';
import { config } from '@utils/config';
import { message } from 'antd';
import { QuizSlice } from '@components/Quiz/QuizSlice';

export interface ChallengeState {
    status: 'idle' | 'loading' | 'failed';

    challenge: {
        id: string;
        title: string;
        description: string;
        picture: string;
        start_date: string;
        end_date: string;
        tag_name: string;
        forward_points: number;
        slug: string;
        register_until_date: string;
        registration_options: [
            {
                option: [];
            }
        ];
        has_liked: number;
        has_registered: number;
    };
    entries: Array<{
        id: number;
        challenge_id: number;
        user_id: number;
        option: string[];
        content: {
            title: string;
            description: string;
            tags: any[];
        };
        published_at: Date | null;
        image: string;
        video: string;
        getstream_id: string;
        user: {
            dcs_id: number;
            fullname: string;
            first_name: string;
            last_name: string;
            avatars: {
                full: string;
                thumbnail: string;
            };
        };
        likes_count: number;
        liked: boolean;
    }> | null;
    winnerEntries: {
        amarelo_fuse: {
            id: number;
            challenge_id: number;
            user_id: number;
            option: string[];
            content: {
                title: string;
                description: string;
                tags: any[];
            };
            published_at: Date | null;
            image: string;
            video: string;
            getstream_id: string;
            user: {
                dcs_id: number;
                fullname: string;
                first_name: string;
                last_name: string;
                avatars: {
                    full: string;
                    thumbnail: string;
                };
            };
            likes_count: number;
            liked: boolean;
        }[];
        wind_fuse: {
            id: number;
            challenge_id: number;
            user_id: number;
            option: string[];
            content: {
                title: string;
                description: string;
            };
            published_at: Date | null;
            image: string;
            video: string;
            getstream_id: string;
            user: {
                dcs_id: number;
                fullname: string;
                first_name: string;
                last_name: string;
                avatars: {
                    full: string;
                    thumbnail: string;
                };
            };
            likes_count: number;
            liked: boolean;
        }[];
    } | null;
    error: any;
    pager: {
        current_page: number;
        last_page: number;
        per_page: number;
        total: number;
    };
    hasApprovedRegistrations: boolean;
}

interface AxiosError {
    response: {
        data: {
            message: string;
        };
    };
}

const initialState: ChallengeState = {
    status: 'loading',
    challenge: {
        id: '',
        title: '',
        description: '',
        slug: '',
        tag_name: '',
        start_date: '',
        end_date: '',
        picture: '',
        forward_points: 0,
        register_until_date: '',
        registration_options: [
            {
                option: []
            }
        ],
        has_liked: 0,
        has_registered: 0
    },
    entries: null,
    winnerEntries: null,
    error: {},
    pager: {
        current_page: 0,
        last_page: 0,
        per_page: 0,
        total: 0
    },
    hasApprovedRegistrations: false
};

export const getChallengeDetails = createAsyncThunk(
    'monthlyChallenge/getChallenge',
    async (slug: String | undefined, { rejectWithValue }) => {
        try {
            let response;
            if (slug) {
                response = await axiosRequest.get(`/challenges/${slug}`);
            } else {
                response = await axiosRequest.get('/challenges/current');
            }

            return response.data;
        } catch (e) {
            // window.location.href = `.${config.path.monthlyChallenge.endedCampaign}`;
            return rejectWithValue(e);
        }
    }
);

export const getChallengeWinners = createAsyncThunk(
    'monthlyChallenge/getChallengeWinners',
    async (values: { challenge_id: number; type: string }) => {
        try {
            const response = await axiosRequest.get(
                `/challenges/${values.challenge_id}/registrations/${values.type}`
            );
            return response.data;
        } catch (e) {
            return (e as AxiosError).response.data.message;
        }
    }
);

export const getChallengeGallery = createAsyncThunk(
    'monthlyChallenge/getChallengeGallery',
    async (values: {
        challenge_id: number;
        page: number;
        per_page: number;
        order_type: string;
    }) => {
        try {
            const response = await axiosRequest.get(
                `/challenges/${values.challenge_id}/registrations`,
                {
                    params: {
                        page: values.page,
                        per_page: values.per_page,
                        order_type: values.order_type
                    }
                }
            );

            return response.data;
        } catch (e) {
            return (e as AxiosError).response.data.message;
        }
    }
);

export const getHasApprovedRegistrations = createAsyncThunk(
    'monthlyChallenge/hasApprovedRegistrations',
    async () => {
        try {
            const response = await axiosRequest.get(
                'challenges/current/has-approved-registrations'
            );

            return response.data.has_approved_registration;
        } catch (e) {
            return (e as AxiosError).response.data.message;
        }
    }
);

export const getChallengeGallerySlice = createSlice({
    name: 'challengeGallery',
    initialState,
    reducers: {
        resetState: () => initialState
    },
    extraReducers: (builder) => {
        builder
            // Get challenge details
            .addCase(getChallengeDetails.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getChallengeDetails.fulfilled, (state, action) => {
                state.status = 'idle';
                state.challenge = action.payload.data;
            })
            .addCase(getChallengeDetails.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })

            // Get challenge winners

            .addCase(getChallengeWinners.pending, (state) => {
                state.status = 'loading';
            })

            .addCase(getChallengeWinners.fulfilled, (state, action) => {
                state.status = 'idle';
                state.winnerEntries = action.payload;
            })
            .addCase(getChallengeWinners.rejected, (state, action) => {
                state.status = 'failed';
                message.error(`${action.payload}`, 10);
                state.error = action.payload;
            })

            // Get gallery
            .addCase(getChallengeGallery.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getChallengeGallery.fulfilled, (state, action) => {
                state.status = 'idle';
                state.entries = action.payload.data;
                state.pager = action.payload.meta;
            })
            .addCase(getChallengeGallery.rejected, (state, action) => {
                state.status = 'failed';
                message.error(`${action.payload}`, 10);
                state.error = action.payload;
            })

            // hasApprovedRegistrations
            .addCase(getHasApprovedRegistrations.pending, (state) => {
                state.status = 'loading';
            })

            .addCase(getHasApprovedRegistrations.fulfilled, (state, action) => {
                state.status = 'idle';
                state.hasApprovedRegistrations = action.payload;
            })

            .addCase(getHasApprovedRegistrations.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    }
});

export const { resetState } = getChallengeGallerySlice.actions;

export default getChallengeGallerySlice.reducer;
