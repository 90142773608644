import styles from './WeUpgradeSummer.module.scss';

// Helpers
import classNames from 'classnames';
import { config } from '@utils/config';

// Assets
import img1 from './assets/weUpgradeSummer1.png';
import img2 from './assets/upgradeSummer2.png';
import image1Desktop from './assets/image1Desktop.png';

// Components
import Button from '@components/Layout/Button';
import ArticleImage from '@containers/IQOSUniverse/components/ArticleImage';
import ArticleHeader from '@containers/IQOSUniverse/components/ArticleHeader';
import ArticleContainer from '@containers/IQOSUniverse/components/ArticleContainer';

const WeUpgradeSummer = () => {
    return (
        <section className={styles.wrapper}>
            <ArticleHeader />

            <div className="content">
                <h3>Trăim cu bucurie vara!</h3>

                <p>
                    Vara asta suntem gata să ne bucurăm din plin de fiecare moment! Diminețile încep
                    cu un frappe, zilele ne prind la soare, iar serile le petrecem alături de
                    prieteni vechi și noi.
                </p>

                <p>
                    Pe scurt, e sezonul cu cea mai mare distracție din an, iar noi suntem gata să
                    primim tot ce ne oferă.
                </p>
            </div>

            <ArticleContainer background={'#F6F4F0'}>
                <div className={classNames(styles.content, 'content')}>
                    <h3>Bucură-te de vară împreună cu IQOS.</h3>

                    <ArticleImage image={img1} />

                    <p className={styles.marginTop}>
                        Ne-am gândit la tot ce se putea pentru ca vara ta să nu semene cu niciuna de
                        până acum. Vrem ca împreună să creăm povești noi și unice, din zori și
                        până-n seară. De aceea, IQOS te așteaptă pe litoral, cu pop-up store-uri
                        speciale și instalații menite să te răcorească sau să te binedispună.
                    </p>

                    <p>
                        Așadar, vara asta ne găsești la Shut Up Beach, club Expirat, Nuba Beach Club
                        sau Alezzi Yacht.
                    </p>

                    <Button
                        href={config.path.iqos.map}
                        label="Vezi harta locațiilor"
                        type="text"
                        color="turquoise"
                        hover={'dark'}
                        inline
                    />
                </div>
            </ArticleContainer>

            <div className="content">
                <h3>Din orice unghi am privi, vara e cel mai tare sezon.</h3>

                <p>
                    Prioritatea fiecărei zilei: bucură-te de soare! Încarcă-te cu energie,
                    înconjoară-te de oamenii la care ții și privește cu încredere înainte. Pentru a
                    susține această idee, am creat un simbol pe care îl puteți găsi la Shut Up
                    Beach, în Mamaia - Prisma IQOS.
                </p>
            </div>

            <ArticleImage cover={true} image={image1Desktop} mobileImage={img2} />

            <div className="content">
                <h3>Ofertele speciale sunt și ele aici</h3>
                <p>
                    Ne dorim ca experiența ta cu IQOS să fie la înălțimea verii tale, așa că ți-am
                    pregătit oferte speciale în toate magazinele și insulele noastre.
                </p>
                <p>Descoperă-le și bucură-te de cadouri și surprize!</p>
            </div>
        </section>
    );
};

export default WeUpgradeSummer;
