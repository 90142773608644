export const ArrowRight = () => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_3566_96715)">
                <path
                    d="M0.571426 8H15.4286"
                    stroke="#DB3624"
                    strokeWidth="1.24675"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M11.4286 12L15.4286 8L11.4286 4"
                    stroke="#DB3624"
                    strokeWidth="1.24675"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_3566_96715">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
