import { useState } from 'react';
import styles from './IQOSCommunity2022.module.scss';

// Helpers
import classNames from 'classnames';
import { monthsButtonContent, monthContent } from './data';
import { animateScroll as scroll } from 'react-scroll/modules';

// Components
import Button from '@components/Layout/Button';
import KeenSlider from '@components/KeenSlider';
import VideoModal from '@components/VideoModal';
import ArticleImage from '@containers/IQOSUniverse/components/ArticleImage';
import ArticleHeader from '@containers/IQOSUniverse/components/ArticleHeader';
import ArticleContainer from '@containers/IQOSUniverse/components/ArticleContainer';

const IQOSCommunity2022 = () => {
    const [toggleModal, setToggleModal] = useState(false);

    const [isMonthsVideos, setIsMonthsVideos] = useState(false);
    const [isMonthId, setIsMonthId] = useState(0);

    const handleClickedMonthId = (monthId: number) => {
        setIsMonthId(monthId);
        scroll.scrollToTop();
    };

    return (
        <>
            {!isMonthsVideos ? (
                <section className={styles.wrapper}>
                    <div className={styles.articleWrapper}>
                        <ArticleContainer background={'#00D1D2'}>
                            <div className="content">
                                <ArticleHeader hideTitle />
                            </div>

                            <div className={classNames(styles.communityContent, 'content')}>
                                <h3 className={styles.title}>
                                    În 2022 comunitatea IQOS Club a devenit și mai puternică.
                                </h3>

                                <p>Hai să retrăim cele mai frumoase momente!</p>

                                <Button
                                    onClick={() => setIsMonthsVideos(true)}
                                    label="Explorează 2022"
                                    type="solid"
                                    color="dark"
                                    hover="dark"
                                />
                            </div>
                        </ArticleContainer>
                    </div>
                </section>
            ) : (
                <>
                    <section className={styles.wrapper}>
                        <ArticleContainer textColor={'#F6F4F0'} background={'#34303D'}>
                            <div className="content">
                                {monthContent[isMonthId]?.month?.map(
                                    ({ id, photo, title, paragraph, secondParagraph, videoId }) => {
                                        return (
                                            <section key={id}>
                                                {!videoId && <ArticleImage image={photo} />}
                                                {videoId && photo && (
                                                    <>
                                                        <ArticleImage
                                                            isVideo
                                                            mobileImage={photo}
                                                            image={photo}
                                                            onClick={() => setToggleModal(true)}
                                                        />

                                                        <VideoModal
                                                            toggleModal={toggleModal}
                                                            setToggleModal={() =>
                                                                setToggleModal(false)
                                                            }
                                                            video={videoId}
                                                        />
                                                    </>
                                                )}

                                                <div className={styles.communityIntroContent}>
                                                    <h3>{title}</h3>
                                                    <p>{paragraph}</p>
                                                    <p>{secondParagraph}</p>
                                                </div>
                                            </section>
                                        );
                                    }
                                )}
                            </div>
                        </ArticleContainer>
                    </section>

                    <div className={styles.buttonBackgroundIqosCommunity}>
                        <KeenSlider withArrows={false} origin={'auto'}>
                            {monthsButtonContent?.map(({ id, label }) => {
                                return (
                                    <div className={styles.buttonWrapper} key={id}>
                                        <Button
                                            label={label}
                                            type={isMonthId === id ? 'solid' : 'outlined'}
                                            color="dark"
                                            hover="dark"
                                            onClick={() => handleClickedMonthId(id)}
                                        />
                                    </div>
                                );
                            })}
                        </KeenSlider>
                    </div>
                </>
            )}
        </>
    );
};
export default IQOSCommunity2022;
