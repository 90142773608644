import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import axiosRequest from '@utils/axios';

// Helpers
import cookies from '@utils/cookies';
import dayjs from '@utils/dayjs';

export interface addPackCodesState {
    status: 'idle' | 'loading' | 'success' | 'failed';
    remainingCodesState: number;
    modalTitle: string;
    modalSubtitle: string;
    disclaimer: string;
    addNewCode: boolean;
    codes: Object;
    code: string;
    sectionId: string;
    error: any;
}

const initialState: addPackCodesState = {
    status: 'idle',
    remainingCodesState: 0,
    code: '',
    sectionId: '',
    modalTitle: '',
    modalSubtitle: '',
    disclaimer: '',
    addNewCode: false,
    codes: {
        givenPoints: ''
    },
    error: {}
};

interface AxiosError {
    response: {
        data: {
            errors: object;
            message: string;
        };
    };
}

export const addCode = createAsyncThunk(
    'containers/addPackCode',
    async (values: { code: string }, { rejectWithValue }) => {
        try {
            const response = await axiosRequest.post('user/insert-code', values);
            return response.data.remainingCodes;
        } catch (e) {
            return rejectWithValue((e as AxiosError).response.data);
        }
    }
);

// CTR Tracking on notification
export const postAddCodeMenuCTR = createAsyncThunk(
    'menu/addCodeCtr',
    async (value: undefined, { rejectWithValue }) => {
        try {
            const response = await axiosRequest.post('/user/ctr-action', {
                action_uuid: 'MainMenuInsertCodeTop'
            });

            return response.data;
        } catch (e) {
            return rejectWithValue((e as AxiosError).response.data.message);
        }
    }
);

export const addPackCodesSlice = createSlice({
    name: 'addPackCodesSlice',
    initialState,
    reducers: {
        setCode: (state, action: PayloadAction<string>) => {
            state.code = action.payload;
        },
        scrollToSection: (state, action: PayloadAction<string>) => {
            state.sectionId = action.payload;
        }
    },

    extraReducers: (builder) => {
        builder

            //generateCode
            .addCase(addCode.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(addCode.fulfilled, (state, action) => {
                state.status = 'success';
                state.remainingCodesState = action.payload;
                state.modalTitle = 'Felicitări! Codul a fost înregistrat cu succes!';
                // state.modalSubtitle =
                //     'Trăiește vara la următorul nivel cu IQOS ILUMA! Intră <a href="/club/traieste-vara-la-urmatorul-nivel"> aici</a>, rezolvă probele și poți câștiga peste 50.000 de premii și experiențe!';
                state.disclaimer = `<b>${30 - state.remainingCodesState}/30</b> coduri introduse`;
                state.addNewCode = true;
            })
            .addCase(addCode.rejected, (state, action: any) => {
                state.status = 'failed';

                if (action.payload.error === 'Cod greșit') {
                    state.modalTitle = 'Ne pare rău! Codul nu a fost înregistrat cu succes!';
                    state.modalSubtitle = '';
                    state.disclaimer = 'Te rugăm să introduci codul corect.';
                    state.addNewCode = true;
                } else if (action.payload.error.includes('Codul a mai fost utilizat')) {
                    state.modalTitle = 'Ne pare rău! Codul nu este valabil.';
                    state.modalSubtitle = '';
                    state.disclaimer =
                        'Codul a fost deja înregistrat. <br />Te rugăm să introduci un alt cod.';
                    state.addNewCode = true;
                } else if (
                    action.payload.error.includes(
                        'Ai depășit numărul maxim de coduri pe care le poți înscrie lunar.'
                    )
                ) {
                    state.modalTitle = 'Ne pare rău!';
                    state.modalSubtitle = '';
                    state.disclaimer =
                        'Ai atins limita de coduri care pot fi înregistrate în această lună. Poți înregistra noi coduri începând de luna viitoare.';
                    state.addNewCode = false;
                    cookies.createWithExpirationDate(
                        'add-pack-code-number',
                        0,
                        dayjs().endOf('month').toDate()
                    );
                } else if (action.payload.error.includes('Ai înscris deja acest cod')) {
                    state.modalTitle = 'Ne pare rău! Codul nu este valabil.';
                    state.modalSubtitle = '';
                    state.disclaimer =
                        'Codul a fost deja înregistrat. <br />Te rugăm să introduci un alt cod.';
                    state.addNewCode = true;
                } else {
                    state.modalTitle =
                        'Pentru moment a apărut o eroare, vă rugăm încercați din nou.';
                    state.modalSubtitle = '';
                    state.disclaimer = '';
                    state.addNewCode = false;
                }
            });
    }
});

export const {setCode, scrollToSection} = addPackCodesSlice.actions;

export default addPackCodesSlice.reducer;
