export const InfoIcon = () => {
    return (
        <svg
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M25 49C38.2548 49 49 38.2548 49 25C49 11.7452 38.2548 1 25 1C11.7452 1 1 11.7452 1 25C1 38.2548 11.7452 49 25 49Z"
                stroke="#34303D"
                strokeWidth="2"
            />
            <path
                d="M25.0001 44.8537C35.965 44.8537 44.8538 35.9649 44.8538 25C44.8538 14.0351 35.965 5.14636 25.0001 5.14636C14.0353 5.14636 5.14648 14.0351 5.14648 25C5.14648 35.9649 14.0353 44.8537 25.0001 44.8537Z"
                fill="#00D1D2"
            />
            <path
                d="M25.0018 33.3729C23.9781 33.3729 23.1482 34.2028 23.1482 35.2266C23.1482 36.2503 23.9781 37.0802 25.0018 37.0802C26.0256 37.0802 26.8555 36.2503 26.8555 35.2266C26.8555 34.2028 26.0256 33.3729 25.0018 33.3729Z"
                fill="#34303D"
            />
            <path
                d="M23.4134 28.7056C23.4134 29.5827 24.1245 30.2938 25.0016 30.2938C25.8788 30.2938 26.5898 29.5827 26.5898 28.7056L26.5898 14.9409C26.5898 14.0637 25.8788 13.3526 25.0016 13.3526C24.1245 13.3526 23.4134 14.0637 23.4134 14.9409L23.4134 28.7056Z"
                fill="#34303D"
            />
        </svg>
    );
};
