export const VoucherIconBorder = () => {
    return (
        <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
                stroke="#34303D"
            />
            <path
                d="M11 2.40039C13.2809 2.40039 15.4683 3.30646 17.0811 4.91927C18.6939 6.53208 19.6 8.71953 19.6 11.0004C19.6 13.2813 18.6939 15.4687 17.0811 17.0815C15.4683 18.6943 13.2809 19.6004 11 19.6004V2.40039Z"
                fill="#00D1D2"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.3276 6.625L16.8376 15.0309L4.59901 15.5629L4.22815 13.61C4.67319 13.4895 5.07187 13.2542 5.38826 12.9367C5.85995 12.465 6.15317 11.8125 6.15317 11.0928C6.15317 10.3731 5.85995 9.72057 5.38826 9.24887C5.04753 8.90698 4.61176 8.66012 4.125 8.55002V7.15696L16.3276 6.625Z"
                fill="#F6F4F0"
                stroke="#34303D"
            />
            <path
                d="M10.5947 9.58752C10.5847 9.01405 10.1117 8.55729 9.53823 8.5673C8.96477 8.57731 8.508 9.0503 8.51801 9.62376C8.52802 10.1972 9.00102 10.654 9.57448 10.644C10.1479 10.634 10.6047 10.161 10.5947 9.58752Z"
                fill="#34303D"
            />
            <path
                d="M13.4883 12.4957C13.4782 11.9223 13.0052 11.4655 12.4318 11.4755C11.8583 11.4855 11.4016 11.9585 11.4116 12.532C11.4216 13.1054 11.8946 13.5622 12.468 13.5522C13.0415 13.5422 13.4983 13.0692 13.4883 12.4957Z"
                fill="#34303D"
            />
            <path d="M9.50354 12.552L12.5882 9.4674" stroke="#34303D" strokeLinecap="round" />
        </svg>
    );
};
