export const MGMIcon = (color: string = '#34303D') => {
    return (
        <svg
            width="23"
            height="23"
            viewBox="0 0 23 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.0686 11.2143C13.8892 11.2143 16.1757 8.92774 16.1757 6.10714C16.1757 3.28655 13.8892 1 11.0686 1C8.24797 1 5.96143 3.28655 5.96143 6.10714C5.96143 8.92774 8.24797 11.2143 11.0686 11.2143Z"
                stroke={color}
                strokeWidth="1.71429"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.6014 22.2856C12.0675 21.7946 11.616 21.2155 11.27 20.5713H2.61341C3.27799 19.1564 4.29012 17.9247 5.56335 16.9959C7.12136 15.8594 8.99187 15.2337 10.9177 15.2026C11.1488 14.5909 11.4695 14.0233 11.8639 13.5157C11.5999 13.4967 11.3346 13.4871 11.0686 13.4871C8.7266 13.4871 6.44512 14.2308 4.55306 15.611C2.661 16.9912 1.25612 18.9366 0.540949 21.1667C0.45734 21.4274 0.503316 21.7124 0.664673 21.9336C0.826031 22.1548 1.08335 22.2856 1.35715 22.2856H12.6014Z"
                fill={color}
            />
            <line
                x1="12.9422"
                y1="17.0398"
                x2="21.4122"
                y2="17.0398"
                stroke={color}
                strokeWidth="1.74713"
                strokeLinecap="round"
            />
            <line
                x1="17.3253"
                y1="21.1267"
                x2="17.3253"
                y2="12.6566"
                stroke={color}
                strokeWidth="1.74713"
                strokeLinecap="round"
            />
        </svg>
    );
};
