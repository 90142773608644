import { useEffect } from 'react';
import { ICardWrapper } from './interface';

// Styles
import styles from './CardWrapper.module.scss';
import classNames from 'classnames';

// Utils
import { animateScroll as scroll } from 'react-scroll/modules';

const CardWrapper = ({
    winner,
    children,
    winnerVoucher,
    largeText,
    customBackground,
    squareContainer
}: ICardWrapper) => {
    useEffect(() => {
        scroll.scrollToTop();
    }, []);

    return (
        <div
            className={classNames(
                styles.container,
                winner && styles.winner,
                winnerVoucher && styles.winnerVoucher,
                largeText && styles.largeText,
                customBackground && styles.customBackground,
                squareContainer && styles.square
            )}
        >
            {/*<div className={styles.backdropFilter}></div>*/}
            <div className={styles.childrenContainer}>{children}</div>
        </div>
    );
};

export default CardWrapper;
