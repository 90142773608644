import img1 from './assets/img1.jpg';
import img2 from './assets/img2.jpg';
import img3 from './assets/img3.jpg';
import img4 from './assets/img4.jpg';
import img5 from './assets/img5.jpg';
import img6 from './assets/img6.jpg';

import img1Mobile from './assets/img1-mobile.jpg';
import img2Mobile from './assets/img2-mobile.jpg';
import img3Mobile from './assets/img3-mobile.jpg';
import img4Mobile from './assets/img4-mobile.jpg';
import img5Mobile from './assets/img5-mobile.jpg';
import img6Mobile from './assets/img6-mobile.jpg';

export const galleryData = [
    {
        id: 1,
        img: img1,
        imgMobile: img1Mobile
    },
    {
        id: 2,
        img: img2,
        imgMobile: img2Mobile
    },
    {
        id: 3,
        img: img3,
        imgMobile: img3Mobile
    },
    {
        id: 4,
        img: img4,
        imgMobile: img4Mobile
    },
    {
        id: 5,
        img: img5,
        imgMobile: img5Mobile
    },
    {
        id: 6,
        img: img6,
        imgMobile: img6Mobile
    }
];
