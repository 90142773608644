export const CloseIconRounded = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none">
            <rect
                width={28}
                height={28}
                viewBox="0 0 28 28"
                x={20}
                fill="#FFFDFB"
                rx={14}
                transform="rotate(45 20 0)"
            />
            <g stroke="#34303D" strokeLinecap="round" strokeWidth={1.77} opacity={0.7}>
                <path d="m23.272 23.072-6.544-6.543M23.272 16.529l-6.544 6.543" />
            </g>
        </svg>
    );
};
