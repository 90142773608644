import React, { useState, useEffect } from 'react';
import styles from './HowToCleanDevice.module.scss';

// Helpers
import classnames from 'classnames';
import { config } from '@utils/config';
import { useAppSelector } from '@app/hooks';
import dayjs from '@utils/dayjs';

// Assets
import image1 from './assets/image1.jpg';
import image2 from './assets/image2.jpg';
import image3 from './assets/image3.jpg';
import image1Mobile from './assets/image1Mobile.jpg';
import image2Mobile from './assets/image2Mobile.jpg';
import image3Mobile from './assets/image3Mobile.jpg';
import videoThumbnail from './assets/videoThumbnail.jpg';
import videoThumbnailMobile from './assets/videoThumbnailMobile.jpg';
import { FPIcon } from '@assets/icons';

// Components
import ArticleImage from '@containers/IQOSUniverse/components/ArticleImage';
import ArticleHeader from '@containers/IQOSUniverse/components/ArticleHeader';
import ArticleContainer from '@containers/IQOSUniverse/components/ArticleContainer';
import BaseModal from '@components/Layout/BaseModal';
import VideoDisclaimer from '@containers/IQOSUniverse/components/VideoDisclaimer';
import VimeoPlayer from '@components/VimeoPlayer';
import RatingQuiz from '@containers/IQOSUniverse/components/RatingQuiz';

const HowToCleanDevice = () => {
    const [toggleModal, setToggleModal] = useState(false);
    const [showDisclaimer, toggleDisclaimer] = useState(false);
    const [isPastPercentage, setIsPastPercentage] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [hidePlayer, setHidePlayer] = useState(false);
    const [videoEnded, setVideoEnded] = useState(false);

    const { article } = useAppSelector((state) => state.iqosUniverse);

    useEffect(() => {
        if (!article.completed_quiz && (article.quiz_points || article.points)) {
            toggleDisclaimer(true);
        }
    }, [article]);

    let playing = false;
    let progress = 0;
    const triggerMomentPercentage = 75; // 75%

    const handleTimeUpdate = (data: any) => {
        if (playing && data.seconds - progress <= 10) {
            if (data.seconds < progress) {
                //don't update progress if user seeked back
            } else {
                progress = data.seconds;
            }
        }

        const durationPercentage = (triggerMomentPercentage * data.duration) / 100;

        if (progress >= durationPercentage && !isPastPercentage) {
            setIsPastPercentage(true);
        }
    };

    React.useEffect(() => {
        if (videoEnded) {
            if (isPastPercentage && !article.completed_quiz) {
                setHidePlayer(true);
            } else {
                setTimeout(() => {
                    handleCloseModal();
                }, 1000);
            }
        }
    }, [videoEnded, isPastPercentage, article]);

    const handleCloseModal = () => {
        setToggleModal(false);
        setHidePlayer(false);
        setIsPastPercentage(false);
        toggleDisclaimer(true);
        setVideoEnded(false);
    };

    // const CompletedState = () => {
    //     return (
    //         <section className={styles.congratsContainer}>
    //             {(article.points !== 0 || article.quiz_points !== 0) && (
    //                 <div className={styles.completed}>
    //                     <h3>
    //                         Felicitări, ai acumulat {article.points || article.quiz_points} FP{' '}
    //                         pentru că ai urmărit videoclipul.
    //                     </h3>

    //                     <div className={styles.reward}>
    //                         <FPIcon />
    //                         <span>
    //                             <b>+{article.points || article.quiz_points}</b> <br />
    //                             FP
    //                         </span>
    //                     </div>
    //                 </div>
    //             )}
    //         </section>
    //     );
    // };

    return (
        <section className={styles.wrapper}>
            <ArticleHeader />

            <div className="content">
                <h3>Un obicei simplu și rapid pentru o experiență optimă</h3>
                <p>
                    Curățarea dispozitivului IQOS este un proces simplu, pe care îl poți integra în
                    rutina ta zilnică, nu îți ia mult timp, însă face diferența când vine vorba de
                    experiența IQOS.
                    <br />
                    <br />
                    Îți prezentăm recomandările specialiștilor cu privire la curățarea
                    dispozitivului, astfel încât să te poți bucura de o experiență optimă și să
                    menții funcționalitatea dispozitivului la capacitate maximă.
                </p>

                <h3 className={styles.cleaningStepsTitle}>
                    Care sunt pașii curățării dispozitivului?
                </h3>

                <p>
                    <b>Pregătește dispozitivul</b>
                </p>

                <p>Curăță după fiecare 20 de utilizări pentru o experiență optimă.</p>

                <p>
                    Așteaptă ca dispozitivul să se răcească cel puțin 30 de secunde și asigură-te că
                    peria cu două capete de curățare este curată.
                </p>

                <p>
                    <b>Utilizează peria cu două capete de curățare</b>
                </p>
            </div>

            <div className={styles.stepsImage}>
                <ArticleImage cover={true} mobileImage={image1Mobile} image={image1} />
            </div>

            <div className="content">
                <p>1. Utilizează capătul de curățare al periei.</p>
                <ul>
                    <li>
                        Scoate capacul și scutură ușor stiloul, apoi deschide capacul rotativ al
                        periuței de curățare și glisează-l în sus. Introdu în stilou, fără să
                        răsucești, după introducere rotește de 3 ori, după care scoate periuța fară
                        să răsucești.
                    </li>
                </ul>
                <p className={styles.stepDisclaimer}>
                    Acest capăt al periuței te ajută să cureți prin rotire, rezidurile de tutun
                    rămase în jurul lamelei. Pntru a proteja lamela dispozitivului, e foarte
                    important ca în momentul în care introduci și, respectiv scoți periuța să nu o
                    răsucești.
                </p>
                <ul>
                    <li>
                        Scutură din nou stiloul pentru a elimina orice reziduu. Aproape ai terminat!
                    </li>
                </ul>
            </div>

            <div className={styles.stepsImage}>
                <ArticleImage cover={true} mobileImage={image2Mobile} image={image2} />
            </div>

            <div className="content">
                <p>2. Utilizează capătul de ștergere al periei</p>
                <ul>
                    <li>
                        Deschide cel de-al doilea capac și asigură-te că periuța este aliniată cu
                        lamela dispozitivului. Introdu peria și mișcă ușor de la stânga la dreapta,
                        fără să rotești.
                    </li>
                </ul>
                <p className={styles.stepDisclaimer}>
                    Acest capăt al periuței te ajută să cureți rezidurile de tutun depuse la baza
                    lamelei. E foarte important să nu rotești în momentul în care folosești această
                    parte a periuței pentru a nu accidenta lamela.
                </p>
                <ul>
                    <li>Scutură din nou stiloul pentru a elimina orice reziduu de tutun.</li>
                </ul>
            </div>

            <div className={styles.stepsImage}>
                <ArticleImage cover={true} mobileImage={image3Mobile} image={image3} />
            </div>

            <div className="content">
                <p>
                    <b>Utilizează bețisoarele de curățare</b>
                </p>
                <ul>
                    <li>Folosește bețisoarele de curățare în jurul lamelei, fără să o atingi.</li>
                    <br />
                    <li>Curăță interiorul capacului de jos și de sus.</li>
                </ul>

                <h3 className={styles.benefitsTitle}>Care sunt beneficiile curățării?</h3>
                <p>
                    Curățarea regulată a dispozitivului tău IQOS, după fiecare 20 de utilizări, îți
                    aduce multiple beneficii:
                </p>

                <p>
                    <b>Simplitate în utilizare</b>
                </p>

                <p>
                    Este mai simplu să utilizezi dispozitivul pentru că rezervele HEETS sunt
                    introduse mai ușor în „stilou”.
                </p>

                <p>
                    <b>O experiență optimă</b>
                </p>

                <p>
                    Fiecare sesiune va dura mai mult pentru că aceasta nu va mai fi întreruptă de
                    reziduurile de tutun.
                </p>

                <p>
                    <b>Calitate constantă</b>
                </p>

                <p>
                    Calitatea experienței va rămâne constant una bună, pentru că, prin curățare,
                    dispozitivul poate produce mai mult aerosol.
                </p>

                <p>
                    <b>Fără miros neplăcut</b>
                </p>

                <p>
                    Prin curățarea dispozitivului, vei evita ca mirosul aerosolului generat să
                    devină neplăcut.
                </p>

                <div className={styles.tipsContainer}>
                    <p>
                        <b>Sfatul experților IQOS:</b>
                        <br />
                        Pentru o experiență optimă cu dispozitivul tău IQOS, verifică regulat
                        căpăcelul. Un căpăcel care nu se închide bine poate contribui la acumularea
                        mai rapidă de reziduuri.
                    </p>
                </div>

                <a
                    href={config.path.iqos.capsCollection}
                    className={styles.capsCollection}
                    target="_blank"
                >
                    Vezi colecția de căpăcele
                </a>
            </div>

            <div className={styles.space}>
                <ArticleContainer background={'#00D1D2'}>
                    <div className={styles.videoContainer}>
                        {/* <h3>
                            Urmărește videoclipul integral, răspunde la întrebarea de la sfârșit și
                            obții 100 FP.
                        </h3> */}

                        <ArticleImage
                            isVideo
                            mobileImage={videoThumbnailMobile}
                            image={videoThumbnail}
                            onClick={() => setToggleModal(true)}
                        />

                        <BaseModal
                            size="small"
                            maskClosable={true}
                            isModalOpen={toggleModal}
                            className={styles.videoModal}
                            closeModal={() => handleCloseModal()}
                            destroyOnClose={true}
                        >
                            <div className={styles.modalContent}>
                                {!article.completed_quiz &&
                                    dayjs(article.quiz_end_date).isAfter(dayjs()) &&
                                    showDisclaimer && (
                                        <VideoDisclaimer
                                            handleClose={() => toggleDisclaimer(false)}
                                        />
                                    )}

                                {isPastPercentage && article.quiz_slug && (
                                    <div
                                        className={classnames(
                                            styles.rating,
                                            !!hidePlayer && styles.padding
                                        )}
                                    >
                                        <RatingQuiz
                                            handleSuccess={() => setShowSuccess(true)}
                                            quizSlug={article.quiz_slug}
                                        />
                                    </div>
                                )}

                                {!hidePlayer && (
                                    <VimeoPlayer
                                        className={styles.player}
                                        video="816936681"
                                        options={{
                                            autoplay: false,
                                            responsive: true
                                        }}
                                        onPlay={() => (playing = true)}
                                        onTimeUpdate={(progress) => handleTimeUpdate(progress)}
                                        onPause={() => (playing = false)}
                                        onEnd={() => setVideoEnded(true)}
                                    />
                                )}
                            </div>
                        </BaseModal>
                    </div>
                </ArticleContainer>

            </div>
        </section>
    );
};
export default HowToCleanDevice;
