import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { capitalizeFirstLetter } from '@utils/capitalizeFirstLetter';
import axiosRequest from '@utils/axios';

export interface DashboardBenefitsCount {
    status: 'idle' | 'loading' | 'failed';
    count: number;
    error: any;
}

interface AxiosError {
    response: {
        data: {
            message: string;
        };
    };
}

const initialState: DashboardBenefitsCount = {
    status: 'idle',
    count: 0,
    error: {}
};

export const getBenefitsDashboardCount = createAsyncThunk('dashboard/listBenefits', async () => {
    const action_uuid = 'BenefitsSectionShow';

    try {
        const response = await axiosRequest
            .get(`user/ctr-action-count?action_uuid=${action_uuid}`)
            .then((res) => {
                return res;
            });
        return response.data.count[0].count;
    } catch (e) {
        return (e as AxiosError).response.data.message;
    }
});

export const postBenefitsDashboardCount = createAsyncThunk(
    'dashboard/postListBenefts',
    async () => {
        const action_uuid = 'BenefitsSectionShow';

        try {
            const response = await axiosRequest
                .post(`user/ctr-action?action_uuid=${action_uuid}`)
                .then((res) => {
                    return res;
                });
            return response;
        } catch (e) {
            return (e as AxiosError).response.data.message;
        }
    }
);

// Post CTR action with params
export const postActionCTR = createAsyncThunk(
    'dashboard/ctrAction',
    async (values:{platform: string, location:string, label: string}, { rejectWithValue }) => {
        const { platform, location, label } = values;

        try {
            const response = await axiosRequest.post('/user/ctr-action', {
                action_uuid: `${capitalizeFirstLetter(label)}_${location}_${platform}`
            });
            return response.data;
        } catch (e) {
            return rejectWithValue((e as AxiosError).response.data.message);
        }
    }
);

export const benefitsEntryCount = createSlice({
    name: 'benefitsDashboardCount',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getBenefitsDashboardCount.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getBenefitsDashboardCount.fulfilled, (state, action) => {
                state.status = 'idle';
                state.count = action.payload;
            })
            .addCase(getBenefitsDashboardCount.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })

            .addCase(postBenefitsDashboardCount.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(postBenefitsDashboardCount.fulfilled, (state, action) => {
                state.status = 'idle';
            })
            .addCase(postBenefitsDashboardCount.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })

            .addCase(resetState, () => initialState);
    }
});

export const resetState = createAction('REVERT_ALL');

export default benefitsEntryCount.reducer;
