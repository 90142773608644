import { useEffect } from 'react';
import styles from './ArticlePage.module.scss';

// Helpers
import { useParams } from 'react-router-dom';
import { components } from '../../articles/index';

// Components
import BaseLoader from '@components/Layout/BaseLoader';
import SocialMedia from '@containers/IQOSUniverse/components/SocialMedia';
import ArticleHeader from '@containers/IQOSUniverse/components/ArticleHeader';
import RelatedArticles from '@containers/IQOSUniverse/components/RelatedArticles';
import ArticleQuiz from '@containers/IQOSUniverse/components/ArticleQuiz';

// Redux
import { useAppDispatch, useAppSelector } from '@app/hooks';
import {
    getArticle,
    markArticleAsRead,
    resetArticle,
    getRelatedArticles
} from '@containers/IQOSUniverse/IQOSUniverseSlice';

const ArticlePage = () => {
    const dispatch = useAppDispatch();
    const { article_slug } = useParams();

    const { article, status } = useAppSelector((state) => state.iqosUniverse);

    useEffect(() => {
        if (article_slug) {
            dispatch(getArticle({ slug: article_slug }));

            dispatch(getRelatedArticles({ slug: article_slug, limit: 5 }));
        }

        return () => {
            dispatch(resetArticle());
        };
    }, [article_slug]);

    useEffect(() => {
        if (article.id) {
            if (
                article.seen === false &&
                (article.quiz_id === null || article.quiz_id.length < 1)
            ) {
                setTimeout(() => {
                    dispatch(markArticleAsRead({ slug: article.slug }));
                }, 1000 * 10);
            } else if (article.quiz_id && article.completed_quiz) {
                // required by backend
                dispatch(markArticleAsRead({ slug: article.slug }));
            }
        }
    }, [article]);

    const Article = components[article.component];

    if (!article || status === 'loading') {
        return <BaseLoader size="large" />;
    }

    return (
        <section className={styles.wrapper}>
            {Article ? <Article /> : <ArticleHeader />}

            {article.quiz_slug &&
                article.video_position !== 'BeforeQuiz' &&
                article.quiz_slug !== 'ce-stii-despre-iqos-iluma-stardrift' && (
                    <ArticleQuiz slug={article.quiz_slug} />
                )}

            <RelatedArticles />
            <SocialMedia />
        </section>
    );
};

export default ArticlePage;
