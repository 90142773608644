import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './IqosQuizGame.module.scss';

import QuizGame from '@components/Games/QuizGame';
import Button from '@components/Layout/Button';

// Redux
import { useAppSelector } from '@app/hooks';
import setHeaderTitle from '@utils/setHeaderTitle';

import { config } from '@utils/config';

const IqosQuizGame = () => {
    const navigate = useNavigate();
    const { article } = useAppSelector((state) => state.iqosUniverse);

    const [gameNotStarted, setGameNotStarted] = useState(true);
    const [gameEnded, setGameEnded] = useState(false);
    const [isWinner, setIsWinner] = useState(false);

    useEffect(() => {
        setHeaderTitle('IQOS Quiz');
    }, []);

    const FinalCard = () => {
        return isWinner ? (
            <div className={styles.endCard}>
                <div className={styles.text}>
                    <h3>Felicitări! Ai răspuns corect!</h3>
                    <p>Vezi dacă poți rezolva și celelalte quizuri despre IQOS ILUMA!</p>
                    <div>
                        <Button
                            color="dark"
                            hover="light"
                            size="normal"
                            onClick={() => navigate(`${config.path.iqosUniverse.iqosHub}/ce-poti-face-daca-intampini-o-eroare-la-iqos-iluma`)}
                        >
                            Înapoi
                        </Button>
                    </div>
                </div>
            </div>
        ) : (
            <div className={styles.endCard}>
                <div className={styles.text}>
                    <h3>Hmm..mai încearcă</h3>
                    <div>
                        <Button
                            color="dark"
                            hover="light"
                            size="normal"
                            onClick={() => {setGameEnded(false)}}
                        >
                            Încearcă din nou
                        </Button>
                    </div>
                </div>
            </div>
        );
    };

    const StartCard = () => {
        return (
            <div className={styles.endCard}>
                <div className={styles.text}>
                    <h3>Știi totul despre dispozitivul tău IQOS ILUMA?</h3>
                    <p>Testează-ți cunoștințele cu un scurt quiz!</p>
                    <div>
                        <Button
                            color="dark"
                            hover="light"
                            size="normal"
                            onClick={() => {setGameNotStarted(false)}}
                        >
                            Începe 
                        </Button>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <section className={styles.wrapper}>
            {gameNotStarted && <StartCard />}
            {gameEnded && <FinalCard />}
            {(article?.quiz_slug && !gameEnded && !gameNotStarted) && (
                <QuizGame
                    quizSlug={article.quiz_slug}
                    onWin={() => {setGameEnded(true); setIsWinner(true);}}
                    onLose={() => {setGameEnded(true); setIsWinner(false);}}
                    multiple={false}
                    image={article.picture}
                />
            )}
        </section>
    );
};

export default IqosQuizGame;
