// Assets
import stage1 from './Components/Dashboard/assets/connectionAssets/stage1.png';
import stage2 from './Components/Dashboard/assets/connectionAssets/stage2.png';
import stage3 from './Components/Dashboard/assets/connectionAssets/stage3.png';
import stage4 from './Components/Dashboard/assets/connectionAssets/stage4.png';
import stage5 from './Components/Dashboard/assets/connectionAssets/stage5.png';

export const progressData = [
    {
        id: 1,
        title: 'Legătura cu partenerul tău',
        start_date: '2023-09-03',
        end_date: '2023-10-30 09:00',
        end_date_subscribe: '2023-10-29 23:59',
        date: '5 februarie - 18 februarie',
        subtitle:
            'În această etapă sărbătorim legătura autentică pe care o avem în relația de cuplu. Urmărește clipul video și deblochează activitățile zilnice care te vor ajuta să-ți găsești inspirația să creezi cât mai multe momente de conexiune cu partenerul tău.',

        expired_subtitle:
            'Această etapă a campaniei a luat sfârșit. Dacă ai nevoie de inspirație pentru a crea noi momente de conexiune cu partenerul tău, urmărește clipul video de mai jos.',
        vimeoLink: '909062137',
        videoLink: 'https://storage.gft-dev.ro/media/videos/RPRC-E1-cuplu.mp4',
        background_color: '#DB3624',
        background_color_inactive: '#DB362480',
        background_color_ended_campaign: '#f6f4f0',
        text_color: 'light',
        success: true
    },
    {
        id: 2,
        title: 'Legătura cu prietenii tăi',
        start_date: '2023-10-30 09:01',
        end_date: '2023-11-28 23:59',
        end_date_subscribe: '2023-11-28 23:59',
        date: '19 februarie - 3 martie',
        subtitle:
            'În a doua etapă sărbătorim legăturile reale pe care le formăm cu prietenii. Descoperă o poveste autentică despre prietenie în clipul video și deblochează activitățile zilnice pentru și mai multă inspirație care-ți va duce relația cu prietenii la următorul nivel.',
        expired_subtitle:
            'Această etapă a campaniei s-a încheiat. Dacă vrei să-ți duci relațiile de prietenie la următorul nivel, poți găsi inspirație în clipul video de mai jos.',
        vimeoLink: '909062498',
        videoLink: 'https://storage.gft-dev.ro/media/videos/RPRC-E2-prieteni.mp4',
        background_color: '#00D1D2',
        background_color_inactive: '#00D1D280',
        background_color_ended_campaign: '#f6f4f0',
        text_color: 'dark',
        success: true
    },
    {
        id: 3,
        title: 'Legătura cu familia ta',
        start_date: '2023-11-29 00:00',
        end_date: '2024-01-07 23:59',
        end_date_subscribe: '2024-01-07 23:59',
        date: '4 martie - 17 martie',
        subtitle:
            'A treia etapă celebrează relațiile cu familia. Vizionează clipul video și vei afla de la un membru al comunității IQOS cât de importantă este această conexiune. Rezolvă activitățile zilnice și găsește noi moduri de a crea astfel de momente în relațiile tale.',
        expired_subtitle:
            'A treia etapă a campaniei a luat sfârșit. Urmărește clipul video de mai jos și află cum te poți bucura de mai multe momente de conexiune cu cei din familie.',
        vimeoLink: '909061536',
        videoLink: 'https://storage.gft-dev.ro/media/videos/RPRC-E3-familie.mp4',
        background_color: '#FFD657',
        background_color_inactive: '#FFD65780',
        background_color_ended_campaign: '#f6f4f0',
        text_color: 'dark',
        success: true
    },
    {
        id: 4,
        title: 'Legătura cu mentorii tăi',
        start_date: '2024-01-08 00:00',
        end_date: '2024-01-21 23:59',
        end_date_subscribe: '2024-01-21 23:59',
        date: '18 martie - 31 martie',
        subtitle:
            'În a patra etapă sărbătorim relațiile pe care le-am creat cu mentorii noștri, cu ajutorul cărora reușim să ne atingem potențialul maxim. Descoperă o poveste reală despre relația de mentorat și deblochează inspirația zilnică pentru momente de conexiune.',
        expired_subtitle:
            'Această etapă a campaniei s-a încheiat. Urmărește clipul video și descoperă povestea unei relații autentice de mentorat, de la un membru al comunității.',
        vimeoLink: '909061749',
        videoLink: 'https://storage.gft-dev.ro/media/videos/RPRC-E4-mentor.mp4',
        background_color: '#287BAF',
        background_color_inactive: '#287BAF80',
        background_color_ended_campaign: '#f6f4f0',
        text_color: 'light',
        success: true
    },
    {
        id: 5,
        title: 'Legătura cu comunitatea ta',
        start_date: '2024-01-08 00:00',
        end_date: '2024-01-21 23:59',
        end_date_subscribe: '2024-01-21 23:59',
        date: '1 aprilie - 14 aprilie',
        subtitle:
            'În ultima etapă sărbătorim legăturile autentice care s-au format în comunitatea IQOS și aflăm de la un membru IQOS CLUB despre experiența lui în această comunitate. Urmărește clipul video și deblochează activitățile zilnice din această etapă.',
        expired_subtitle:
            'Ultima etapă a campaniei a luat sfârșit. Găsește noi moduri prin care să te bucuri de mai multe momente de conexiune în comunitatea IQOS în clipul de mai jos.',
        vimeoLink: '909062322',
        videoLink: 'https://storage.gft-dev.ro/media/videos/RPRC-E5-comunitate.mp4',
        background_color: '#E3813B',
        background_color_inactive: '#E3813B80',
        background_color_ended_campaign: '#f6f4f0',
        text_color: 'light',
        success: true
    }
];

export const WeeklyPrizes = [
    {
        id: 1,
        image: require('./assets/weeklyPrizes/prize1.gif'),
        title: '120x  Experiențe VIP<br/> la concertul anului',
        subtitle: 'Premii săptămânale',
        content:
            'Adună cât mai multe șanse în concurs și poți câștiga una dintre cele 120 de invitații VIP la concertul anului, unde te așteaptă o experiență de neuitat, beneficii unice și multe alte surprize.',
        dateLabel: '12 iunie - 13 iunie',
        endCampaignSubtitle:
            'Câștigătorii vor fi anunțați în curând. Biletele nominale și toate detaliile vor fi transmise câștigătorilor prin email cu 10 zile înainte de data concertului.'
    },
    {
        id: 2,
        image: require('./assets/weeklyPrizes/prize2.gif'),
        title: '1000x  Dispozitive<br/> IQOS ILUMA PRIME',
        subtitle: 'Premii săptămânale',

        content:
            'Participă în campanie și ai șansa să câștigi un IQOS ILUMA PRIME, cel mai rafinat și avansat dispozitiv IQOS de până acum, care aduce experiența tutunului încălzit la următorul nivel.',
        endCampaignSubtitle:
            'Câștigătorii dispozitivelor IQOS ILUMA PRIME, cel mai rafinat și avansat dispozitiv IQOS de până acum, vor fi anunțați în curând. '
    }
];

export const dailyPrizes = [
    {
        id: 1,
        image: require('./assets/dailyPrizes/prize1.gif'),
        title: '15.000 x Accesorii IQOS',
        subtitle: 'Premii instant',
        content:
            'Participă la activitatea zilnică și poți câștiga accesorii IQOS care te vor ajuta să–ți personalizezi dispozitivul.'
    },
    {
        id: 2,
        image: require('./assets/dailyPrizes/prize2.gif'),
        title: '25.000 x Vouchere Next Level Pass',
        subtitle: 'Premii instant',

        content:
            'Poți câștiga vouchere Next Level Pass în valoare de 50 de RON, care te vor ajuta să explorezi universul IQOS ILUMA.'
    }
];

export const Stages = [
    {
        title: `<p>Conexiunea</p>
              Romantică`,
        image: stage1,
        campaign_slug: 'real-connection-partner-video'
    },
    {
        title: `<p>Conexiunea cu</p>
                Prietenii`,
        image: stage2,
        campaign_slug: 'real-connection-friends-video'
    },
    {
        title: `<p>Conexiunea cu</p>
                Familia`,
        image: stage3,
        campaign_slug: 'real-connection-family-video'
    },
    {
        title: `<p>Conexiunea cu</p>
                Mentorii`,
        image: stage4,
        campaign_slug: 'real-connection-mentors-video'
    },
    {
        title: `<p>Conexiunea în</p>
                Comunitate`,
        image: stage5,
        campaign_slug: 'real-connection-community-video'
    }
];
