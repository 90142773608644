import React, { useState, useRef, useEffect } from 'react';
import styles from './HeroSlider.module.scss';
import { IHeroSliderProps } from './interface';

// Helpers
import classnames from 'classnames';
import { useWindowSize } from '@utils/hooks/windowSize';

// Assets
import { carouselSettings } from './data';

// Components
import { Carousel } from 'antd';
import IqosLogo from './IqosLogo';
import Button from '@components/Layout/Button';
import RewardBadge from '@components/RewardBadge';
import VimeoPlayer from '@components/VimeoPlayer';

interface Play {
    onClick?: () => void;
}

const PlayIcon: React.FC<Play> = ({ onClick }) => {
    return (
        <div onClick={onClick} className={styles.play}>
            <svg
                width="46"
                height="46"
                viewBox="0 0 46 46"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect width="46" height="46" rx="23" fill="#00D1D2" />
                <path
                    d="M17.8013 32.2322C18.215 32.2322 18.5667 32.0667 18.9805 31.8288L31.0415 24.857C31.9 24.3502 32.2 24.0192 32.2 23.4709C32.2 22.9227 31.9 22.5917 31.0415 22.0952L18.9805 15.113C18.5667 14.8751 18.215 14.72 17.8013 14.72C17.0358 14.72 16.56 15.2992 16.56 16.1992V30.7427C16.56 31.6426 17.0358 32.2322 17.8013 32.2322Z"
                    fill="#FFFDFB"
                />
            </svg>
        </div>
    );
};

const renderButton = (slide: any) => {
    return slide.button_properties?.type ? (
        slide.button_properties?.href ? (
            <Button
                href={slide.button_properties.href}
                label={slide.cta_text}
                type={slide.button_properties.type ? slide.button_properties.type : 'text'}
                color={slide.button_properties.color ? slide.button_properties.color : 'light'}
                hover={slide.button_properties.hover ? slide.button_properties.hover : 'turquoise'}
                target={slide.button_properties.target ? slide.button_properties.target : '_self'}
            />
        ) : (
            <Button
                onClick={slide.cta_link}
                label={slide.cta_text}
                type={slide.button_properties.type ? slide.button_properties.type : 'text'}
                color={slide.button_properties.color ? slide.button_properties.color : 'light'}
                hover={slide.button_properties.hover ? slide.button_properties.hover : 'turquoise'}
                target={slide.button_properties.target ? slide.button_properties.target : '_self'}
            />
        )
    ) : (
        <Button
            type="text"
            color="light"
            hover="turquoise"
            onClick={slide.cta_link}
            label={slide.cta_text}
        />
    );
};

const HeroSlider: React.FC<IHeroSliderProps> = ({
    slides,
    contests,
    autoplay = true,
    infinite = true,
    speed = 500,
    showLogo = false,
    showTagline = false,
    ctaInsideDetails = false,
    contentStyle = false,
    centerSingleSlideContent = false,
    mutedVideo = false,
    autoplayVideo = false,
    disclaimer,
    fullWidth = false,
    fullWidthVideo = true,
    customDetailsClass,
    className,
    componentClassName,
    detailsClassName,
    visualClassName,
    customLogo,
    ctaAfterLogo = false,
    autoplaySpeed = 3000,
    showLogoImage = true,
    showLogoForced = false,
    loopVideo = false,
    pauseOnHover = true
}) => {
    const videoPlayer = useRef<HTMLVideoElement>(null);
    const [isPlaying, setPlaying] = useState(false);

    const window = useWindowSize();
    const isMobile = window.width < 870;

    useEffect(() => {
        if (isPlaying && videoPlayer) {
            videoPlayer.current && videoPlayer.current.play();
        } else {
            videoPlayer.current && videoPlayer.current.pause();
        }
    }, [videoPlayer, isPlaying]);

    // AutoPlay Mobile
    useEffect(() => {
        if (videoPlayer && autoplayVideo) {
            videoPlayer.current && videoPlayer.current.play();
        }
    }, []);

    return (
        <section
            className={classnames(
                componentClassName,
                styles.wrapper,
                contests && styles.columnReverse,
                fullWidth && styles.fullWidth
            )}
        >
            {slides && slides.length ? (
                <Carousel
                    {...carouselSettings}
                    pauseOnHover={pauseOnHover}
                    autoplay={autoplay}
                    infinite={infinite}
                    speed={speed}
                    autoplaySpeed={autoplaySpeed}
                >
                    {slides.map((slide: any) => (
                        <div
                            key={slide.title}
                            className={classnames(
                                styles.slide,
                                styles[slide.text_mode],
                                styles[slide.class_name],
                                className
                            )}
                        >
                            <div
                                style={{ backgroundColor: slide.background_color }}
                                className={classnames(
                                    styles.content,
                                    slides.length === 1 &&
                                        !centerSingleSlideContent &&
                                        styles.single,
                                    contentStyle && styles.contentStyleChange,
                                    detailsClassName
                                )}
                            >
                                <div className={classnames(styles.details, customDetailsClass)}>
                                    {/*<div dangerouslySetInnerHTML={{ __html: slide.postTitle }} />*/}
                                    {slide.postTitle}
                                    <h3
                                        dangerouslySetInnerHTML={{
                                            __html: slide.title
                                        }}
                                    />
                                    {slide.slideMiddleImage && (
                                        <img src={slide.slideMiddleImage} alt="slideMiddleImage" />
                                    )}
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: slide.subtitle
                                        }}
                                    />

                                    {ctaInsideDetails && !ctaAfterLogo && renderButton(slide)}
                                </div>

                                {!ctaInsideDetails && renderButton(slide)}
                                {/*{showLogo && (showLogoForced || !isMobile) && (*/}
                                {/*    <IqosLogo*/}
                                {/*        showTagline={showTagline}*/}
                                {/*        tagLine={*/}
                                {/*            customLogo*/}
                                {/*                ? customLogo*/}
                                {/*                : `Oameni reali.*/}
                                {/*                    <br />O comunitate reală.`*/}
                                {/*        }*/}
                                {/*    />*/}
                                {/*)}*/}

                                {ctaAfterLogo && renderButton(slide)}

                                {disclaimer ||
                                    (slide.disclaimer && (
                                        <div className={styles.details}>
                                            <p
                                                className={styles.disclaimer}
                                                dangerouslySetInnerHTML={{
                                                    __html: disclaimer || slide.disclaimer
                                                }}
                                            />
                                        </div>
                                    ))}
                            </div>

                            <div
                                className={classnames(
                                    styles.visual,
                                    fullWidthVideo && styles.fullWidthVisual,
                                    visualClassName
                                )}
                                style={{ backgroundColor: slide.background_color }}
                            >
                                <RewardBadge points={slide.forward_points} contest={!!contests} />

                                {showLogo && showLogoImage && (
                                    <IqosLogo showTagline={showTagline} />
                                )}

                                {slide.picture && <img src={slide.picture} alt={slide.title} />}
                                {slide.video && (
                                    <video
                                        ref={videoPlayer}
                                        onPause={() => setPlaying(false)}
                                        controls={isPlaying || autoplayVideo}
                                        controlsList="nodownload"
                                        disablePictureInPicture
                                        disableRemotePlayback
                                        preload="metadata"
                                        src={isMobile ? slide.videoMobile : slide.video}
                                        playsInline
                                        muted={mutedVideo}
                                        autoPlay={autoplayVideo}
                                        loop={autoplayVideo}
                                    />
                                )}
                                {slide.video && !isPlaying && !autoplayVideo && (
                                    <PlayIcon onClick={() => setPlaying(true)} />
                                )}
                                {slide.vimeoLink && (
                                    <div className={styles.vimeoContainer}>
                                        <VimeoPlayer
                                            className={styles.vimeo}
                                            video={slide.vimeoLink}
                                            options={{
                                                autoplay: autoplayVideo,
                                                responsive: true,
                                                muted: autoplayVideo,
                                                autopause: false,
                                                loop: loopVideo
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                </Carousel>
            ) : null}
        </section>
    );
};

export default HeroSlider;
