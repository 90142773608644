export const CheckIcoLightBlue = () => {
    return (
        <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M32.34 9.70005C29.881 8.25405 27.02 7.42505 23.961 7.42505C14.83 7.42505 7.42603 14.829 7.42603 23.962C7.42603 33.096 14.83 40.5 23.963 40.5C33.097 40.5 40.501 33.096 40.501 23.963"
                stroke="#34303D"
                strokeWidth="2"
                strokeLinecap="round"
            />
            <path
                d="M30.895 12.363C28.884 11.179 26.54 10.5 24.037 10.5C16.561 10.5 10.5 16.56 10.5 24.037C10.5 31.514 16.56 37.575 24.037 37.575C31.514 37.575 37.575 31.515 37.575 24.037"
                stroke="#00D1D2"
                strokeWidth="2"
                strokeLinecap="round"
            />
            <path
                d="M15.9109 22.602L22.5969 29.6L39.5559 12.178"
                stroke="#34303D"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
