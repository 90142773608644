export const CardIconGift = () => {
    return (
        <svg
            width="41"
            height="40"
            viewBox="0 0 41 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="20.5" cy="20" r="20" fill="#F6F4F0" />
            <path
                d="M20.5 36C29.3366 36 36.5 28.8366 36.5 20C36.5 11.1634 29.3366 4 20.5 4C11.6634 4 4.5 11.1634 4.5 20C4.5 28.8366 11.6634 36 20.5 36Z"
                fill="#FFFDFB"
                stroke="#34303D"
            />
            <path
                d="M20.5 34C28.232 34 34.5 27.732 34.5 20C34.5 12.268 28.232 6 20.5 6C12.768 6 6.5 12.268 6.5 20C6.5 27.732 12.768 34 20.5 34Z"
                fill="#00D1D2"
                stroke="#FFFDFB"
            />
            <path
                d="M30.4345 13.5195H10.5705C10.2166 13.5195 9.92969 13.8009 9.92969 14.148V18.3484C9.92969 18.6955 10.2166 18.9769 10.5705 18.9769H30.4345C30.7884 18.9769 31.0753 18.6955 31.0753 18.3484V14.148C31.0753 13.8009 30.7884 13.5195 30.4345 13.5195Z"
                fill="#FFFDFB"
                stroke="#34303D"
                strokeWidth="1.05813"
            />
            <path
                d="M28.8486 19.082H12.1564C11.8025 19.082 11.5156 19.3634 11.5156 19.7105V28.369C11.5156 28.7161 11.8025 28.9975 12.1564 28.9975H28.8486C29.2025 28.9975 29.4894 28.7161 29.4894 28.369V19.7105C29.4894 19.3634 29.2025 19.082 28.8486 19.082Z"
                fill="#FFFDFB"
                stroke="#34303D"
                strokeWidth="1.05813"
            />
            <path d="M20.5 13.5195V28.5554" stroke="#00D1D2" strokeWidth="4.00139" />
            <path
                d="M18.5781 13.5859V28.6684M22.4228 13.5859V28.6684V13.5859Z"
                stroke="#34303D"
                strokeLinecap="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.7031 7.98C17.0396 7.88951 17.3619 7.92093 17.6707 8.07552C18.4909 8.48652 19.1881 9.58942 19.8532 11.2818C20.1473 12.0296 20.3575 12.6964 20.4728 13.2802L20.4408 13.1313L20.2293 13.1294C18.307 13.086 16.2802 12.4884 15.2024 11.5168L15.082 11.4037C14.7251 11.048 14.4989 10.6433 14.545 10.1883C14.5905 9.7371 14.884 9.26955 15.4216 8.77434C15.8669 8.36398 16.2898 8.09123 16.7031 7.98ZM23.1692 8.07552C23.4774 7.9203 23.8023 7.89014 24.1432 7.98063C24.5648 8.09249 24.9993 8.36712 25.4593 8.78C26.0104 9.27458 26.3148 9.7415 26.3673 10.1921C26.4199 10.6433 26.1975 11.0442 25.8457 11.3968C24.766 12.4771 22.5163 13.1313 20.4504 13.1313L20.5004 13.1306L20.51 13.0823C20.6048 12.6128 20.758 12.0918 20.9617 11.52L21.0527 11.2717C21.682 9.58565 22.3561 8.48463 23.1692 8.07552Z"
                fill="#00D1D2"
                stroke="#34303D"
                strokeWidth="1.05813"
            />
        </svg>
    );
};
