import React, { useState } from 'react';
import styles from './IqosIllumaJazzInThePark.module.scss';

// Components
import ArticleHeader from '@containers/IQOSUniverse/components/ArticleHeader';
import VideoModalQuiz from '@containers/IQOSUniverse/components/VideoModalQuiz';
import CompletedStateArticle from '@containers/IQOSUniverse/components/CompletedStateArticle/CompletedStateArticle';
import MediaGallery from '@containers/IQOSUniverse/components/MediaGallery';

// Assets
import img1 from './assets/img1.png';
import img2 from './assets/img2.png';
import img3 from './assets/img3.png';
import img4 from './assets/img4.png';
import img5 from './assets/img5.png';
import img6 from './assets/img6.png';
import img7 from './assets/img7.png';
import img8 from './assets/img8.png';
import img9 from './assets/img9.jpg';

// Redux
import { useAppSelector } from '@app/hooks';

const IqosIllumaJazzInThePark = () => {
    const { article } = useAppSelector((state) => state.iqosUniverse);

    const [toggleModal, setToggleModal] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);

    return (
        <section className={styles.wrapper}>
            <ArticleHeader
                videoQuiz={() => {
                    setToggleModal(true);
                }}
                showHardcodedSubtitle
                hardcodedSubtitle={
                    'Urmărește aftermovie-ul ca să vezi cum a fost la festival și câștigă 100 Forward Points!'
                }
            />

            <VideoModalQuiz
                toggleModal={toggleModal}
                setToggleModal={() => setToggleModal(false)}
                setShowSuccess={() => {
                    setShowSuccess(true);
                }}
            />

            <div className="content">
                <p>
                    Între 1 și 3 septembrie a avut loc mult așteptatul festival Jazz in the Park din
                    Cluj. Cea de-a 11-a ediție a fost plină de surprize plăcute, atât pe scenă, cât
                    și la Home of IQOS.
                </p>

                <p>
                    Acolo ne-am întâlnit cu o parte din comunitatea noastră și am marcat momentul la
                    cornerul foto, cu o băutură specială de la bar și multe surprize pentru membrii
                    IQOS Club.
                </p>
            </div>

            <MediaGallery
                images={[img1, img2, img3, img4, img5, img6, img7, img8, img9]}
                customTitle={'Uite care a fost vibe-ul:'}
            />

            {(showSuccess || article.completed_quiz) && (
                <CompletedStateArticle
                    className={styles.completedStateArticle}
                    article={{
                        points: article.points,
                        quiz_points: article.quiz_points,
                        quiz_end_date: article.quiz_end_date
                    }}
                />
            )}
        </section>
    );
};

export default IqosIllumaJazzInThePark;
