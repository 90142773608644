export const Heart = () => {
    return (
        <svg
            width="26"
            height="26"
            viewBox="0 0 26 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.4003 6.76637C15.7026 6.30755 17.0565 6.51578 18.1245 7.18425C19.1911 7.85272 19.9697 8.97931 20.1264 10.3523C20.2803 11.7047 19.8963 12.8991 18.9476 13.8626C18.3673 14.4506 17.624 15.1988 16.717 16.108L15.3116 17.5134C14.9198 17.9045 14.5061 18.3174 14.0692 18.7515L13.0803 19.737L12.0821 18.7572C11.5513 18.2334 11.0981 17.7852 10.7219 17.409L10.4551 17.1421C10.285 16.972 10.1339 16.8203 10.0033 16.6868L9.54661 16.2238C8.75814 15.424 7.96896 14.625 7.19814 13.8075C6.08567 12.6301 5.72426 11.2134 6.16402 9.65202C6.38849 8.85578 6.77955 8.21202 7.30261 7.71225C7.82638 7.21249 8.48849 6.85249 9.2932 6.66402C10.6478 6.34637 11.8718 6.63225 12.9313 7.46731L12.9991 7.52378L13.16 7.41225C13.5087 7.17649 13.8391 6.99014 14.1885 6.84684L14.4003 6.76637Z"
                fill="#00D1D2"
                stroke="#00D1D2"
                strokeWidth="4"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.0614 17.7521C13.0854 17.7337 13.0988 17.726 13.1094 17.7154C14.721 16.1024 16.3403 14.4966 17.9412 12.8716C18.5793 12.2236 18.8264 11.4182 18.7233 10.5118C18.5115 8.66314 16.6212 7.48149 14.8685 8.09773C14.4238 8.25443 14.0426 8.50643 13.7108 8.83749L13.0283 9.52361C12.7692 9.26032 12.5341 8.99985 12.2758 8.76338C11.5155 8.06808 10.6205 7.80267 9.61602 8.03914C8.53955 8.29114 7.82308 8.9702 7.52237 10.0354C7.2259 11.0893 7.47367 12.0443 8.22402 12.8377C9.13672 13.8048 10.0755 14.7486 11.0087 15.6966C11.6864 16.3841 12.3718 17.0624 13.0607 17.7514L13.0614 17.7521Z"
                stroke="#34303D"
                strokeWidth="2"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.9153 1.08471C20.3419 1.15035 21.1169 1.38824 21.6329 1.58941C22.3162 1.85482 22.8033 2.17176 23.3158 2.68424C23.8282 3.19671 24.1452 3.68376 24.4106 4.36706C24.6111 4.88306 24.8496 5.65812 24.9146 7.08471C24.9852 8.62847 25 9.09153 25 13C25 16.9085 24.9852 17.3715 24.9153 18.9153C24.8496 20.3419 24.6118 21.1169 24.4106 21.6329C24.1452 22.3162 23.8282 22.8033 23.3158 23.3158C22.8033 23.8282 22.3162 24.1452 21.6329 24.4106C21.1169 24.6111 20.3419 24.8496 18.9153 24.9146C17.3715 24.9852 16.9085 25 13 25C9.09153 25 8.62847 24.9852 7.08471 24.9153C5.65812 24.8496 4.88306 24.6118 4.36706 24.4106C3.68376 24.1452 3.19671 23.8282 2.68424 23.3158C2.17176 22.8033 1.85482 22.3162 1.58941 21.6329C1.38824 21.1169 1.15035 20.3412 1.08471 18.9153C1.01412 17.3708 1 16.9078 1 13C1 9.09153 1.01482 8.62847 1.08471 7.08471C1.15106 5.65882 1.38824 4.88376 1.59012 4.36706C1.85553 3.68376 2.17247 3.19671 2.68494 2.68424C3.19741 2.17176 3.68376 1.85482 4.36706 1.58941C4.88306 1.38824 5.65882 1.15035 7.08471 1.08471C8.62918 1.01412 9.09224 1 13 1C16.9085 1 17.3715 1.01482 18.9153 1.08471V1.08471Z"
                stroke="#34303D"
                strokeWidth="2"
            />
        </svg>
    );
};
