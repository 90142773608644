import { IMemoryGame } from './interface';
import { useEffect, useRef, useState } from 'react';

// Styles
import styles from './MemoryGame.module.scss';

// Components
import MemoryCard from './components/MemoryCard';

const shuffleCards = (array: string[]) => {
    const length = array.length;
    for (let i = length; i > 0; i--) {
        const randomIndex = Math.floor(Math.random() * i);
        const currentIndex = i - 1;
        const temp = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temp;
    }
    return array;
};

const MemoryGame = ({ cards, maxTries, onWin, onLose }: IMemoryGame) => {
    const extractCards = shuffleCards(cards).slice(0, 4);
    const [shuffledCards] = useState<string[]>((): string[] =>
        shuffleCards(extractCards.concat(extractCards))
    );
    const [openCards, setOpenCards] = useState<number[]>([]);
    const [shouldDisableAllCards, setShouldDisableAllCards] = useState(false);
    const [tries, setTries] = useState(0);
    const [clearedCards, setClearedCards] = useState<Record<string, boolean>>({});
    const timeout = useRef<NodeJS.Timeout>(setTimeout(() => {}));
    const [gameEnded, setGameEnded] = useState(false);
    // const [godMode, setGodMode] = useState(false); // TODO: Remove me

    const evaluate = () => {
        const [first, second] = openCards;
        setShouldDisableAllCards(false);

        if (shuffledCards[first] === shuffledCards[second]) {
            setClearedCards((prev) => ({ ...prev, [shuffledCards[first]]: true }));
            setOpenCards([]);
        }

        // This is to flip the cards back after 500ms duration
        timeout.current = setTimeout(() => {
            setOpenCards([]);
        }, 500);
    };

    const handleCardClick = (index: number) => {
        if (!gameEnded) {
            if (openCards.length === 1) {
                setOpenCards((prev) => [...prev, index]);
                setTries((moves) => moves !== maxTries ? moves + 1 : maxTries); // Rewroked this so tries never exceed maxTries
                setShouldDisableAllCards(true);
            } else {
                clearTimeout(timeout.current);
                setOpenCards([index]);
            }
        }
    };

    const checkIsInactive = (card: string) => {
        return Boolean(clearedCards[card]);
    };

    const checkIsFlipped = (index: number) => {
        return openCards.includes(index);
    };

    useEffect(() => {
        if (openCards.length === 2) {
            evaluate();
        }
    }, [openCards]);

    useEffect(() => {
        if (!gameEnded && openCards.length === 0) {
            if (Object.keys(clearedCards).length === extractCards.length) {
                onWin();
                setGameEnded(true);
            } else {
                if (tries >= maxTries) {
                    onLose();
                    setGameEnded(true);
                }
            }
        }
    }, [openCards]);

    return (
        <div className={styles.container}>
            {/* TODO: Remove me */}
            {/* <button onClick={() => setGodMode(!godMode)}>God Mode: {godMode ? 'ON' : 'OFF'}</button> */}
            <div className={styles.cards}>
                {shuffledCards.map((image, index) => (
                    <MemoryCard
                        key={index}
                        image={image}
                        index={index}
                        isDisabled={shouldDisableAllCards}
                        isInactive={checkIsInactive(image)}
                        isFlipped={checkIsFlipped(index)}
                        onClick={handleCardClick}
                        // godMode={godMode}
                    />
                ))}
            </div>

            <div className={styles.tries}>
                Număr încercări: {tries} din {maxTries}
            </div>
        </div>
    );
};

export default MemoryGame;
