export const cardData = [
    {
        id: 1,
        title: '5 decembrie',
        description:
            ', în care lumina și sunetul interacționează pentru a crea instanțe spațiu-timp care suscită imaginația',
        boldDescription: 'Instalația WONDER'
    },
    {
        id: 2,
        title: '6 decembrie',
        description:
            ' de autocunoaștere prin intermediul muzicii și dansului, ghidată de coregrafa Judith State și de muzicianul Bogdan Dumitriu',
        boldDescription: 'O călătorie'
    },
    {
        id: 3,
        title: '7 decembrie',
        description:
            ', mixat live în format sonor tridimensional de Bogdan Moroșanu, unul dintre membrii trupei',
        boldDescription: 'Cel mai recent album Rockabella'
    },
    {
        id: 4,
        title: '8 decembrie',
        description:
            ', sound artist, vor prezenta o parte dintre rezultatele proiectului If we vanish – În search of natural silence, axat pe sunetele zonelor sălbatice din India unde poluarea sonică încă nu a ajuns',
        boldDescription: 'Felix Deufel, inițiatorul ZiMMT, și Nikhil Nagaraj'
    }
];
