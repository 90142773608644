import axiosRequest from '@utils/axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

interface LiveReactionsState {
    status: 'idle' | 'loading' | 'failed';
    error: any;
}

interface AxiosError {
    response: {
        data: {
            message: string;
        };
    };
}

const initialState: LiveReactionsState = {
    status: 'idle',
    error: {}
};

export const liveReacts = createAsyncThunk(
    'livereactions/reacts',
    async ({ slug, react_name }: { slug: string; react_name: string }) => {
        try {
            const response = await axiosRequest.put(`/article-reacts/${slug}`, {
                react_name: react_name
            });
            return response.data;
        } catch (e) {
            return (e as AxiosError).response.data.message;
        }
    }
);

export const getStatistics = createAsyncThunk(
    'livereactions/getstatistics',
    async (slug: string) => {
        try {
            const response = await axiosRequest.get(`/article-reacts/${slug}`);
            return response.data;
        } catch (e) {
            return (e as AxiosError).response.data.message;
        }
    }
);

export const grantPointsVideo = createAsyncThunk(
    'livereactions/grantpoints',
    async (slug: string) => {
        try {
            const response = await axiosRequest.post(`/articles/grant-points/${slug}`);
            return response.data;
        } catch (e) {
            return (e as AxiosError).response.data.message;
        }
    }
);

export const LiveReactionsSlice = createSlice({
    name: 'LiveReactionsSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder

            // Get Statistics
            .addCase(getStatistics.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getStatistics.fulfilled, (state) => {
                state.status = 'loading';
            })
            .addCase(getStatistics.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })

            // Live Reaction

            .addCase(liveReacts.pending, (state) => {
                state.status = 'loading';
            })

            .addCase(liveReacts.fulfilled, (state) => {
                state.status = 'idle';
            })

            .addCase(liveReacts.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })

            // Mark article as read
            .addCase(grantPointsVideo.pending, (state) => {
                state.status = 'loading';
            })

            .addCase(grantPointsVideo.fulfilled, (state) => {
                state.status = 'idle';
            })

            .addCase(grantPointsVideo.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    }
});

export default LiveReactionsSlice.reducer;
