export const ErrorRedeemIcon = () => {
    return (
        <svg
            width="246"
            height="194"
            viewBox="0 0 246 194"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M93.5236 98.7239C99.8139 106.162 107.302 109.734 115.866 109.734C124.399 109.734 132.055 106.167 138.695 98.7239H152.174C158.437 98.7239 164.11 101.265 168.222 105.373C172.325 109.48 174.866 115.153 174.866 121.416V143.541C174.866 147.304 173.342 150.707 170.877 153.172C168.413 155.632 165.009 157.157 161.251 157.157H70.4818C66.724 157.157 63.3201 155.632 60.8558 153.172C58.3914 150.707 56.8665 147.304 56.8665 143.541V121.416C56.8665 115.153 59.408 109.48 63.5108 105.373C67.6181 101.265 73.2911 98.7239 79.5588 98.7239H93.5236Z"
                stroke="#34303D"
                strokeWidth="9.07692"
            />
            <path
                d="M115.866 83.4565C136.703 83.4565 153.595 66.565 153.595 45.7282C153.595 24.8915 136.703 8 115.866 8C95.0296 8 78.1381 24.8915 78.1381 45.7282C78.1381 66.565 95.0296 83.4565 115.866 83.4565Z"
                stroke="#34303D"
                strokeWidth="9.07692"
            />
            <path
                d="M97.2482 66.9775C100.184 58.5178 108.111 52.6495 116.949 52.6495C125.379 52.6495 131.847 59.1305 135.047 66.9775"
                stroke="#00D1D2"
                strokeWidth="7.94231"
                strokeLinecap="round"
            />
            <path
                d="M184.866 77C190.389 77 194.866 72.5228 194.866 67C194.866 61.4772 190.389 57 184.866 57C179.344 57 174.866 61.4772 174.866 67C174.866 72.5228 179.344 77 184.866 77Z"
                stroke="#34303D"
                strokeWidth="2"
            />
            <path
                d="M184.837 62.5614V68.4665L187.685 65.6183"
                stroke="#34303D"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M184.845 60.1764C185.345 60.1764 185.751 59.7705 185.751 59.2699C185.751 58.7692 185.345 58.3633 184.845 58.3633C184.344 58.3633 183.938 58.7692 183.938 59.2699C183.938 59.7705 184.344 60.1764 184.845 60.1764Z"
                fill="#00D1D2"
            />
            <path
                d="M184.845 75.5931C185.345 75.5931 185.751 75.1872 185.751 74.6865C185.751 74.1858 185.345 73.7799 184.845 73.7799C184.344 73.7799 183.938 74.1858 183.938 74.6865C183.938 75.1872 184.344 75.5931 184.845 75.5931Z"
                fill="#00D1D2"
            />
            <path
                d="M192.553 67.8848C193.053 67.8848 193.459 67.4789 193.459 66.9782C193.459 66.4775 193.053 66.0717 192.553 66.0717C192.052 66.0717 191.646 66.4775 191.646 66.9782C191.646 67.4789 192.052 67.8848 192.553 67.8848Z"
                fill="#00D1D2"
            />
            <path
                d="M177.136 67.8848C177.637 67.8848 178.043 67.4789 178.043 66.9782C178.043 66.4775 177.637 66.0717 177.136 66.0717C176.636 66.0717 176.23 66.4775 176.23 66.9782C176.23 67.4789 176.636 67.8848 177.136 67.8848Z"
                fill="#00D1D2"
            />
            <path
                d="M21.8665 148C27.3893 148 31.8665 143.523 31.8665 138C31.8665 132.477 27.3893 128 21.8665 128C16.3436 128 11.8665 132.477 11.8665 138C11.8665 143.523 16.3436 148 21.8665 148Z"
                stroke="#34303D"
                strokeWidth="2"
            />
            <path
                d="M21.8369 133.561V139.466L24.6851 136.618"
                stroke="#34303D"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M21.8447 131.176C22.3454 131.176 22.7513 130.771 22.7513 130.27C22.7513 129.769 22.3454 129.363 21.8447 129.363C21.344 129.363 20.9381 129.769 20.9381 130.27C20.9381 130.771 21.344 131.176 21.8447 131.176Z"
                fill="#00D1D2"
            />
            <path
                d="M21.8447 146.593C22.3454 146.593 22.7513 146.187 22.7513 145.686C22.7513 145.186 22.3454 144.78 21.8447 144.78C21.344 144.78 20.9381 145.186 20.9381 145.686C20.9381 146.187 21.344 146.593 21.8447 146.593Z"
                fill="#00D1D2"
            />
            <path
                d="M29.5528 138.885C30.0535 138.885 30.4594 138.479 30.4594 137.978C30.4594 137.478 30.0535 137.072 29.5528 137.072C29.0521 137.072 28.6462 137.478 28.6462 137.978C28.6462 138.479 29.0521 138.885 29.5528 138.885Z"
                fill="#00D1D2"
            />
            <path
                d="M14.1364 138.885C14.6371 138.885 15.043 138.479 15.043 137.978C15.043 137.478 14.6371 137.072 14.1364 137.072C13.6357 137.072 13.2299 137.478 13.2299 137.978C13.2299 138.479 13.6357 138.885 14.1364 138.885Z"
                fill="#00D1D2"
            />
            <path
                d="M217.156 111.763H236.844C239.105 111.763 240.938 113.596 240.938 115.857C240.938 118.118 239.105 119.951 236.844 119.951H217.156C214.895 119.951 213.062 118.118 213.062 115.857C213.062 113.596 214.895 111.763 217.156 111.763Z"
                fill="#00D1D2"
                stroke="#34303D"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M241.133 104C242.79 104 244.133 105.343 244.133 107V124.884C244.133 126.541 242.79 127.884 241.133 127.884H238.839L238.678 136.165L229.668 127.884H212.866C211.209 127.884 209.866 126.541 209.866 124.884V107C209.866 105.343 211.209 104 212.866 104H241.134H241.133Z"
                stroke="#34303D"
                strokeWidth="2"
                strokeLinejoin="round"
            />
            <path
                d="M234.409 114.764C235.024 114.764 235.529 115.268 235.529 115.897C235.529 116.527 235.024 117.03 234.409 117.03C233.793 117.03 233.288 116.527 233.288 115.897C233.288 115.268 233.794 114.764 234.408 114.764H234.409ZM226.767 114.764C227.383 114.764 227.888 115.268 227.888 115.897C227.888 116.527 227.383 117.03 226.768 117.03C226.152 117.03 225.647 116.527 225.647 115.897C225.647 115.268 226.153 114.764 226.767 114.764ZM219.277 114.764C219.824 114.764 220.317 115.244 220.317 115.897C220.317 116.551 219.824 117.03 219.277 117.03C218.73 117.03 218.237 116.552 218.237 115.897C218.237 115.244 218.731 114.764 219.277 114.764Z"
                fill="#34303D"
                stroke="#34303D"
            />
            <path
                d="M49.8665 19C53.7324 19 56.8665 15.866 56.8665 12C56.8665 8.13401 53.7324 5 49.8665 5C46.0005 5 42.8665 8.13401 42.8665 12C42.8665 15.866 46.0005 19 49.8665 19Z"
                fill="#00D1D2"
            />
            <path
                d="M52.3878 14.923C52.3878 14.5618 52.0944 14.2684 51.7339 14.2684H49.2037L50.1459 13.3166C50.5757 12.9207 50.9421 12.5595 51.2458 12.2314C51.5496 11.9041 51.7818 11.5731 51.9418 11.2384C52.1025 10.9044 52.1829 10.5358 52.1829 10.1325C52.1829 9.75726 52.0892 9.42182 51.9012 9.12472C51.7132 8.82761 51.4471 8.59244 51.1028 8.41845C50.7578 8.24446 50.3472 8.15747 49.8694 8.15747C49.4323 8.15747 49.0585 8.20687 48.7488 8.30565C48.4377 8.40444 48.1627 8.5342 47.9239 8.69491C47.8907 8.71703 47.8538 8.74283 47.8148 8.77085C47.5228 8.98022 47.4557 9.38643 47.6651 9.67838C47.6747 9.69312 47.6858 9.70639 47.6968 9.71966C47.9187 9.98211 48.3021 10.0374 48.5896 9.84794C48.7319 9.75431 48.8476 9.68575 48.9376 9.64152C49.2037 9.51176 49.4765 9.44689 49.7559 9.44689C50.0294 9.44689 50.2462 9.5184 50.4069 9.66216C50.5668 9.80518 50.6472 10.0234 50.6472 10.3176C50.6472 10.5564 50.5956 10.7798 50.4939 10.9884C50.3907 11.1956 50.2277 11.4278 50.0021 11.6836C49.7773 11.9395 49.4802 12.2587 49.1116 12.6413L47.657 14.1121C47.4152 14.3568 47.2795 14.6871 47.2795 15.0314C47.2795 15.3285 47.5199 15.5688 47.817 15.5688H51.7413C52.0981 15.5688 52.3878 15.2791 52.3878 14.9223V14.923Z"
                fill="#34303D"
            />
            <circle cx="139" cy="161" r="22" fill="#F6F4F0" />
            <path
                d="M139 178.6C148.72 178.6 156.6 170.72 156.6 161C156.6 151.28 148.72 143.4 139 143.4C129.28 143.4 121.4 151.28 121.4 161C121.4 170.72 129.28 178.6 139 178.6Z"
                fill="#FFFDFB"
                stroke="#34303D"
                strokeWidth="1.83333"
            />
            <path
                d="M139 145.864C143.014 145.864 146.864 147.459 149.703 150.297C152.541 153.136 154.136 156.986 154.136 161C154.136 165.014 152.541 168.864 149.703 171.703C146.864 174.541 143.014 176.136 139 176.136V145.864Z"
                fill="#00D1D2"
            />
            <path
                d="M144.736 166.517C143.357 168.328 141.281 169.906 138.45 171.223C135.655 169.922 133.596 168.368 132.217 166.586C130.789 164.742 130.055 162.612 130.019 160.161C130.019 160.161 130.019 160.16 130.019 160.16L130.017 159.974V154.766L138.394 150.793L146.883 154.769V159.978C146.882 162.477 146.163 164.644 144.736 166.517Z"
                fill="#FFFDFB"
                stroke="#34303D"
                strokeWidth="1.83333"
                strokeLinejoin="round"
            />
            <path
                d="M138.419 154.4L143.4 156.992V160.399C143.398 163.563 141.752 165.963 138.462 167.6L138.45 167.561L138.438 167.6C135.188 165.983 133.542 163.621 133.501 160.514L133.5 160.4V156.992L138.419 154.4Z"
                fill="#00D1D2"
            />
            <path
                d="M15.7957 92.2581C16.0565 92.125 16.3695 92.125 16.6304 92.2581L25.0896 96.5881L23.4776 87.4376C23.4254 87.1429 23.5246 86.8429 23.7437 86.6368L30.5596 80.1678L21.1274 78.8323C20.8326 78.7931 20.5796 78.6106 20.444 78.3471L16.213 70L11.9821 78.3471C11.8491 78.6106 11.5934 78.7905 11.2987 78.8323L1.86646 80.1678L8.68238 86.6368C8.90149 86.8455 9.00062 87.1429 8.94845 87.4376L7.33641 96.5881L15.7957 92.2581Z"
                fill="#00D1D2"
                stroke="#00D1D2"
                strokeWidth="1.77033"
                strokeLinejoin="round"
            />
            <path
                d="M209.148 12.6359C209.285 12.5664 209.448 12.5664 209.585 12.6359L214.007 14.8995L213.164 10.1159C213.137 9.96182 213.189 9.805 213.303 9.69727L216.866 6.31545L211.936 5.61727C211.781 5.59682 211.649 5.50136 211.578 5.36364L209.366 1L207.155 5.36364C207.085 5.50136 206.951 5.59545 206.797 5.61727L201.866 6.31545L205.43 9.69727C205.544 9.80636 205.596 9.96182 205.569 10.1159L204.726 14.8995L209.148 12.6359Z"
                fill="#00D1D2"
                stroke="#00D1D2"
                strokeWidth="1.77033"
                strokeLinejoin="round"
            />
        </svg>
    );
};
