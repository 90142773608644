export const JournalIcon = () => {
    return (
        <svg
            width="22"
            height="24"
            viewBox="0 0 22 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M19.4612 20.3187C19.4612 20.7267 19.2991 21.118 19.0106 21.4065C18.722 21.695 18.3307 21.8571 17.9227 21.8571H4.07655C3.66852 21.8571 3.27721 21.695 2.98869 21.4065C2.70017 21.118 2.53809 20.7267 2.53809 20.3187V3.39558C2.53809 2.98755 2.70017 2.59624 2.98869 2.30772C3.27721 2.0192 3.66852 1.85712 4.07655 1.85712H11.7689L19.4612 9.54942V20.3187Z"
                stroke="#C4C3C6"
                strokeWidth="1.71429"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.15332 8.01099H10.2302"
                stroke="#C4C3C6"
                strokeWidth="1.71429"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.15332 12.6263H14.8456"
                stroke="#C4C3C6"
                strokeWidth="1.71429"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.15332 17.2418H14.8456"
                stroke="#C4C3C6"
                strokeWidth="1.71429"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
