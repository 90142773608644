import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosRequest from '@utils/axios';
import { RootState } from '@app/store';

interface AxiosError {
    response: {
        data: {
            message: string;
        };
    };
}

export interface InitialState {
    status: 'idle' | 'success' | 'loading' | 'failed';
    userVotesDataAndEligibility: {
        end_date: string;
        played: boolean;
        properties: string[];
        votes: string[];
        message?: string;
    };
    userVotes: any;
    userChances: any;
    activeExperiences: string[];
    maxExperiences: 3;
    voteId: number;
    userHasVoted: boolean;
    error: any;
}

const initialState: InitialState = {
    status: 'idle',
    userVotesDataAndEligibility: {
        end_date: ' ',
        played: false,
        properties: [],
        votes: [],
        message: ''
    },
    userVotes: {},
    userChances: {},
    activeExperiences: [],
    maxExperiences: 3,
    voteId: 0,
    userHasVoted: false,
    error: {}
};

export const getTicketingUserEligibilityAndVotesData = createAsyncThunk(
    'ticketingVoting/getTicketingUserEligibilityToVoteAndVotesData',
    async () => {
        try {
            const response = await axiosRequest.get('/iqos-event/menu-of-choice/status');
            return response.data;
        } catch (e) {
            return (e as AxiosError).response.data;
        }
    }
);

export const getTicketingUserVotes = createAsyncThunk(
    'ticketingVoting/getTicketingUserVotes',
    async () => {
        try {
            const response = await axiosRequest.get('/iqos-event/menu-of-choice/votes');
            return response.data;
        } catch (e) {
            return (e as AxiosError).response.data;
        }
    }
);

export const getTicketingChances = createAsyncThunk(
    'ticketingVoting/getTicketingChances',
    async () => {
        try {
            const response = await axiosRequest.get('/iqos-event/menu-of-choice/chances');
            return response.data;
        } catch (e) {
            return (e as AxiosError).response.data;
        }
    }
);

export const setUserVotesTicketing = createAsyncThunk(
    'ticketingVoting/setUserVotesTicketing',
    async (values: { values: string[] }, { rejectWithValue, getState }) => {
        try {
            const { ticketingVoting } = getState() as RootState;
            const response = await axiosRequest.post(`/iqos-event/menu-of-choice/store`, {
                game_id: ticketingVoting.voteId,
                votes: values.values
            });
            return response.data;
        } catch (e) {
            return rejectWithValue((e as AxiosError).response.data);
        }
    }
);

export const ticketingVotingSlice = createSlice({
    name: 'ticketingVoting',
    initialState,
    reducers: {
        setActiveExperiences: (state, action) => {
            const selectedExperience = action.payload;

            const activeExperiences = [...state.activeExperiences];

            const index = activeExperiences.indexOf(selectedExperience);

            if (index !== -1) {
                // If the item exists, remove it
                activeExperiences.splice(index, 1);
                state.activeExperiences = activeExperiences;
            } else {
                // If the item doesn't exist and we're below limit, push it to the array
                if (activeExperiences.length < state.maxExperiences) {
                    state.activeExperiences = [...activeExperiences, selectedExperience];
                }
            }
        }
    },
    extraReducers: (builder) => {
        builder
            // User is eligible to vote and votes data
            .addCase(getTicketingUserEligibilityAndVotesData.pending, (state) => {
                state.status = 'loading';
            })

            .addCase(getTicketingUserEligibilityAndVotesData.fulfilled, (state, action) => {
                state.status = 'idle';
                state.userVotesDataAndEligibility = action.payload;
                state.voteId = action.payload.id;
            })

            .addCase(getTicketingUserEligibilityAndVotesData.rejected, (state) => {
                state.status = 'failed';
            })

            // User votes
            .addCase(getTicketingUserVotes.pending, (state) => {
                state.status = 'loading';
            })

            .addCase(getTicketingUserVotes.fulfilled, (state, action) => {
                state.status = 'idle';
                state.userVotes = action.payload;
            })

            .addCase(getTicketingUserVotes.rejected, (state) => {
                state.status = 'failed';
            })

            // User chances
            .addCase(getTicketingChances.pending, (state) => {
                state.status = 'loading';
            })

            .addCase(getTicketingChances.fulfilled, (state, action) => {
                state.status = 'idle';
                state.userChances = action.payload;
            })

            .addCase(getTicketingChances.rejected, (state) => {
                state.status = 'failed';
            })

            // Send user votes
            .addCase(setUserVotesTicketing.pending, (state) => {
                state.status = 'loading';
                state.userHasVoted = false;
            })

            .addCase(setUserVotesTicketing.fulfilled, (state) => {
                state.status = 'idle';
                state.userHasVoted = true;
            })

            .addCase(setUserVotesTicketing.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
                state.userHasVoted = false;
            })

            .addCase(resetState, () => initialState);
    }
});

export const resetState = createAction('REVERT_ALL');

export const { setActiveExperiences } = ticketingVotingSlice.actions;

export default ticketingVotingSlice.reducer;
