import styles from './Winter2023.module.scss';

// Helpers
import classnames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { config } from '@utils/config';

// Components
import ArticleHeader from '@containers/IQOSUniverse/components/ArticleHeader';
import ArticleContainer from '@containers/IQOSUniverse/components/ArticleContainer';
import ArticleImage from '@containers/IQOSUniverse/components/ArticleImage';

// Assets
import img1 from './assets/mapIqos.jpg';
import img1Mobile from './assets/mapIqosMobile.jpg';
import img2 from './assets/winterimg2.jpg';
import { MapPinIcon } from '@assets/icons';
import { useWindowSize } from '@utils/hooks/windowSize';

//Redux
import { toggleQRCodeModal } from '@components/QRCodeModal/QRCodeModalSlice';
import { useAppDispatch } from '@app/hooks';

const Winter2023 = () => {
    const dispatch = useAppDispatch();

    const navigate = useNavigate();

    const windowSize = useWindowSize();
    const isMobile = windowSize.width < 870;
    const isMini = windowSize.width < 650;

    const goToParking = () => {
        window.open(
            'https://www.google.com/maps/d/u/0/embed?mid=1Cs-ulviiZ1Tjnfg2l8TrAVoLrqXwFxU&ehbc=2E312F&noprof=1&ll=45.58986114674393%2C25.55156944444444&z=17'
        );
    };

    return (
        <section className={styles.wrapper}>
            <ArticleHeader />

            <div className="content">
                <p>
                    Sezonul de iarnă a început! Te așteptăm la munte, pentru experiențe de neuitat.
                    Mai mult, din 18 ianuarie, am rezervat 5 locuri de parcare exclusiv pentru
                    membrii comunității IQOS CLUB, de care poți beneficia de 2 ori pe săptămână.
                </p>
            </div>

            <ArticleContainer background={!isMobile ? '#F6F4F0' : ''}>
                <ArticleImage
                    image={isMini ? img1Mobile : img1}
                    onClick={goToParking}
                    fullHeight={true}
                    noMargin={true}
                />
                <div className={classnames('content', styles.pinTitle)}>
                    <h3 onClick={goToParking}>
                        <MapPinIcon /> Parcare Sulinar
                    </h3>
                    <p>
                        <strong>Adresă:</strong> Strada Drumul Sulinar nr. 1, Poiana Brașov 500001
                    </p>
                </div>
            </ArticleContainer>

            <div className={'content'}>
                <h3>Vezi ce ai de făcut pentru a te bucura de parcare gratuită</h3>
                <p>
                    Atunci când ajungi în Poiana Brașov, mergi spre Rossignol sau spre Aftăr Ski,
                    locațiile partenere IQOS din zonă.
                </p>
                <p>
                    În apropiere se găsesc și cele{' '}
                    <a
                        className={styles.hyperlink}
                        href={
                            'https://www.google.com/maps/d/u/0/embed?mid=1Cs-ulviiZ1Tjnfg2l8TrAVoLrqXwFxU&ehbc=2E312F&noprof=1&ll=45.58986114674393%2C25.55156944444444&z=17'
                        }
                        target={'_blank'}
                        rel={'noreferrer noopener'}
                    >
                        5 locuri de parcare
                    </a>{' '}
                    destinate membrilor IQOS CLUB.
                </p>
                <p>
                    Dacă unul dintre acestea este liber, arată{' '}
                    <strong
                        onClick={() => dispatch(toggleQRCodeModal())}
                        className={styles.redirectToMyProfile}
                    >
                        codul QR
                    </strong>{' '}
                    pe care îl găsești în{' '}
                    <strong
                        onClick={() => navigate(config.path.userProfile)}
                        className={styles.redirectToMyProfile}
                    >
                        secțiunea Profilul meu
                    </strong>{' '}
                    din aplicația IQOS CLUB și locul de parcare va fi al tău.
                </p>
            </div>

            <ArticleContainer>
                <ArticleImage image={img2} noMargin={true} />
                <div className={'content'}>
                    <h3>
                        Merită să vii în Poiana Brașov sezonul acesta, pentru și mai multe surprize
                        marca IQOS
                    </h3>
                    <p>
                        Mai mult, în perioada 18 ianuarie – 17 martie, de joi până duminică, în
                        intervalul orar 11 – 17 te poți întâlni cu reprezentanții IQOS pentru a te
                        bucura de beneficii și surprize speciale.
                        <br /> Ne vedem în Poiana Brașov!
                    </p>
                </div>
            </ArticleContainer>
        </section>
    );
};

export default Winter2023;
