import { createSlice } from "@reduxjs/toolkit";

export const miniProfileModalSlice = createSlice({
    name: "miniProfileModal",
    initialState: {
        isOpen: false,
    },
    reducers: {
      toggleMiniProfileModal: (state) => {
        state.isOpen = !state.isOpen;
      }
    }
});

export const { toggleMiniProfileModal } = miniProfileModalSlice.actions;
export default miniProfileModalSlice.reducer;