import styles from './ArticleContainer.module.scss';
import { IArticleContainerProps } from './interface';
import classnames from 'classnames';

const ArticleContainer = (props: IArticleContainerProps) => {
    const { children, background, textColor, className } = props;

    return (
        <div
            className={classnames(styles.articleContainer, className)}
            style={{ backgroundColor: background, color: textColor }}
        >
            {children}
        </div>
    );
};

export default ArticleContainer;
