import { useEffect } from 'react';
import { IPuzzle } from './interface';
import headbreaker from 'headbreaker';

// Styles
import styles from './Puzzle.module.scss';

// Utils
import useCountdown from '@utils/hooks/useCountdown';
import useLockedBody from '@utils/hooks/useLockedBody';
import { animateScroll as scroll } from 'react-scroll/modules';

// Keep in mind that this works best if rows = columns and the number is not too high
// Otherwhise it will need changes for responsiveness to kick in
const Puzzle = ({ image, time, rowsAndColumns, onWin, onLose }: IPuzzle) => {
    const puzzle = 'puzzle';
    const [rows, columns] = rowsAndColumns;
    const [countdown, togglePauseCountdown] = useCountdown(time, 'mm:ss', onLose);
    const [_locked, setLocked] = useLockedBody();

    // Load the puzzle instance after Image is loaded
    useEffect(() => {
        let newImage = new Image();
        newImage.src = image;

        newImage.onload = () => {
            setTimeout(() => {
                loadPuzzle(newImage);
            }, 100);
        };
    }, []);

    // Scroll to top and lock the body
    useEffect(() => {
        scroll.scrollToTop();
        setLocked(true);

        return () => {
            setLocked(false);
        };
    }, []);

    const loadPuzzle = (image: HTMLImageElement) => {
        const container = document.getElementById(puzzle);
        const size = container?.offsetWidth! * 1.25; // Play with this and...

        // Init the puzzle
        const puzzleInstance = new headbreaker.Canvas(puzzle, {
            width: size,
            height: size,
            pieceSize: (size * 0.65) / rows, // ...and this to ajust responsiveness
            outline: new headbreaker.outline.Rounded(),
            strokeWidth: 0.3, // this used to be .5
            strokeColor: '#34303d',
            borderFill: 0,
            preventOffstageDrag: true,
            fixed: true,
            image: image,
            painter: new headbreaker.painters.Konva()
        });

        // Ajust, generate, shuffle and draw the puzzle
        puzzleInstance.adjustImagesToPuzzleHeight();
        puzzleInstance.autogenerate({
            horizontalPiecesCount: rows,
            verticalPiecesCount: columns
        });
        puzzleInstance.shuffleGrid();
        puzzleInstance.draw();

        // Check if it's solved to trigger callback
        puzzleInstance.attachSolvedValidator();
        puzzleInstance.onValid(() => {
            togglePauseCountdown();
            onWin();
        });

        // Here the responsiveness kicks in
        const redraw = () => {
            if (container) {
                puzzleInstance.resize(container.offsetWidth, container.offsetWidth);
                puzzleInstance.scale(container.offsetWidth / size);
                puzzleInstance.redraw();
            }
        };
        redraw();
        window.addEventListener('resize', redraw);
    };

    return (
        <div className={styles.container}>
            <div id={puzzle} />
            <div className={styles.countdown}>
                Timp rămas: <span>{countdown}</span>
            </div>
        </div>
    );
};

export default Puzzle;
