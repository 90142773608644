// React
import {createRoot} from 'react-dom/client';
import {StrictMode, Suspense} from 'react';

// React Redux
import {Provider} from 'react-redux';
import {store} from '@app/store';

// React Router
import {RouterProvider} from 'react-router-dom';
import router from '@components/Routes';
import NProgress from '@components/NProgress';

// Ant Design
import {ConfigProvider} from 'antd';
import ro_RO from 'antd/es/locale/ro_RO';

// Web Vitals
import reportWebVitals from './reportWebVitals';
import {TagManager} from '@utils/GTM';

// Service Worker
import {registerServiceWorker} from 'serviceWorkerRegistration';

// Sentry
import * as Sentry from '@sentry/react';

const container = document.getElementById('root')!;
const root = createRoot(container);

Sentry.init({
    enabled: process.env.NODE_ENV === 'production',
    dsn: 'https://6836b1ff60e181bf0b2cf02f6e8b376e@o4506275216556032.ingest.sentry.io/4506535734935552',
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    // Performance Monitoring
    tracesSampleRate: 0.1,
    profilesSampleRate: 0.1,
    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    // Ignore check-access errors
    beforeBreadcrumb: (breadcrumb) => {
        const requestURL = breadcrumb?.data?.url;
        const statusCode = breadcrumb?.data?.status_code;

        if (!requestURL || !statusCode) {
            return breadcrumb;
        }

        if (requestURL.includes('check-access') && statusCode === 422) {
            return null;
        }

        return breadcrumb;
    }
});

root.render(
    <Provider store={store}>
        <ConfigProvider locale={ro_RO}>
            <Suspense fallback={<NProgress/>}>
                <RouterProvider router={router}/>
            </Suspense>
        </ConfigProvider>
    </Provider>
);

// Init the serviceWorker
registerServiceWorker();

// Measure performance of the app and send it to GA (https://web.dev/vitals/)
reportWebVitals(({id, name, value}) => {
    TagManager.dataLayer({
        dataLayer: {
            eventCategory: 'Web Vitals',
            eventAction: name,
            eventValue: Math.round(name === 'CLS' ? value * 1000 : value),
            eventLabel: id,
            nonInteraction: true
        }
    });
});
