import React, { useState, useEffect } from 'react';
import classnames from 'classnames';

// Assets
import styles from './WrapupFlightFestival.module.scss';
import img02 from './assets/hero2.jpg';
import img02Mobile from './assets/hero2Mobile.jpg';
import playerImg from './assets/playerImg.jpg';
import playerImgMobile from './assets/playerImgMobile.jpg';

// Components
import ArticleHeader from '@containers/IQOSUniverse/components/ArticleHeader';
import ArticleImage from '@containers/IQOSUniverse/components/ArticleImage';
import CompletedStateArticle from '@containers/IQOSUniverse/components/CompletedStateArticle/CompletedStateArticle';
import BaseModal from '@components/Layout/BaseModal';
import RatingQuiz from '@containers/IQOSUniverse/components/RatingQuiz';
import VimeoPlayer from '@components/VimeoPlayer';
import VideoDisclaimer from '@containers/IQOSUniverse/components/VideoDisclaimer';

// Redux
import { useAppSelector } from '@app/hooks';
import dayjs from '@utils/dayjs';

const WrapupFlightFestival: React.FC = () => {
    const { article } = useAppSelector((state) => state.iqosUniverse);

    useEffect(() => {
        if (!article.completed_quiz && (article.quiz_points || article.points)) {
            toggleDisclaimer(true);
        }
    }, [article]);

    const [toggleModal, setToggleModal] = useState(false);
    const [showDisclaimer, toggleDisclaimer] = useState(false);
    const [isPastPercentage, setIsPastPercentage] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [hidePlayer, setHidePlayer] = useState(false);
    const [videoEnded, setVideoEnded] = useState(false);

    let playing = false;
    let progress = 0;

    // Handle Trigger
    const triggerMomentPercentage = 75; // 75

    const handleTimeUpdate = (data: any) => {
        if (playing && data.seconds - progress <= 10) {
            if (data.seconds < progress) {
                //don't update progress if user seeked back
            } else {
                progress = data.seconds;
            }
        }

        const durationPercentage = (triggerMomentPercentage * data.duration) / 100;

        if (progress >= durationPercentage && !isPastPercentage) {
            setIsPastPercentage(true);
        }
    };

    useEffect(() => {
        if (videoEnded) {
            if (isPastPercentage && !article.completed_quiz) {
                setHidePlayer(true);
            } else {
                setTimeout(() => {
                    handleCloseModal();
                }, 1000);
            }
        }
    }, [videoEnded, isPastPercentage, article]);

    const handleCloseModal = () => {
        setToggleModal(false);
        setHidePlayer(false);
        setIsPastPercentage(false);
        toggleDisclaimer(true);
        setVideoEnded(false);
    };

    return (
        <section className={styles.wrapper}>
            <ArticleHeader />

            <div className="content">
                <h3>IQOS x Flight Festival</h3>

                <p>
                    Flight Festival ne-a surprins cu un line-up divers și o atmosferă memorabilă.
                    Acestea ne-au inspirat să creăm o experiență unică pentru membrii comunității
                    IQOS Club: le-am oferit șansa să câștige o invitație dublă, pentru a se bucura
                    de toate surprizele de acolo.
                </p>
            </div>

            <ArticleImage cover={true} image={img02} mobileImage={img02Mobile} />

            <div className={classnames(styles.align, 'content')}>
                <h3>O experiență inspirată de IQOS ILUMA</h3>

                <p>
                    La cornerul IQOS, toți cei care au venit la Flight Festival au putut să
                    descopere dispozitivele IQOS ILUMA, să-și facă poze la photo booth și să se
                    relaxeze între concerte.
                </p>

                {dayjs(article.quiz_end_date).isAfter(dayjs()) && (
                    <>
                        <h3 className={styles.marginTop}>Play și câștigă 50 FP!</h3>

                        <p>
                            Urmărește clipul video de mai jos, vezi și tu care a fost vibe-ul de la
                            festival și câștigi 50 FP!
                        </p>
                    </>
                )}
            </div>

            <ArticleImage
                cover
                isVideo
                mobileImage={playerImgMobile}
                image={playerImg}
                onClick={() => setToggleModal(true)}
            />

            {(showSuccess || article.completed_quiz) && (
                <CompletedStateArticle
                    className={styles.completedStateArticle}
                    article={{
                        points: article.points,
                        quiz_points: article.quiz_points,
                        quiz_end_date: article.quiz_end_date
                    }}
                />
            )}

            <BaseModal
                size="large"
                maskClosable={true}
                isModalOpen={toggleModal}
                className={styles.videoModal}
                closeModal={() => handleCloseModal()}
                destroyOnClose={true}
            >
                <div className={styles.modalContent}>
                    {!article.completed_quiz &&
                        dayjs(article.quiz_end_date).isAfter(dayjs()) &&
                        showDisclaimer && (
                            <VideoDisclaimer handleClose={() => toggleDisclaimer(false)} />
                        )}

                    {isPastPercentage && article.quiz_slug && (
                        <div className={classnames(styles.rating, hidePlayer && styles.padding)}>
                            <RatingQuiz
                                handleSuccess={() => setShowSuccess(true)}
                                quizSlug={article.quiz_slug}
                            />
                        </div>
                    )}

                    {!hidePlayer && (
                        <VimeoPlayer
                            className={styles.player}
                            video="842109725"
                            options={{
                                autoplay: false,
                                responsive: true
                            }}
                            onPlay={() => (playing = true)}
                            onTimeUpdate={(progress) => handleTimeUpdate(progress)}
                            onPause={() => (playing = false)}
                            onEnd={() => setVideoEnded(true)}
                        />
                    )}
                </div>
            </BaseModal>
        </section>
    );
};

export default WrapupFlightFestival;
