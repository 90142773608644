import {CarouselArrow} from "@assets/icons";

const Arrow = (props: any) => {
    return (
        <div className={props.className} onClick={props.onClick}>
            <CarouselArrow />
        </div>
    );
};

export const carouselSettings = {
    infinite: true,
    speed: 500,
    autoplay: false,
    pauseOnHover: true,
    autoplaySpeed: 5000,
    arrows: true,
    dots: false,
    nextArrow: <Arrow />,
    prevArrow: <Arrow />,
    responsive: [
        {
            breakpoint: 570,
            settings: {
                arrows: false,
                dots: true,
            }
        }
    ]
};