import { config } from './config';

const create = (name, value, days) => {
    let expires = '';

    if (days) {
        let date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = `; expires=  ${date.toGMTString()}`;
    }

    document.cookie = `${name} = ${value}; ${expires} ${config.storage.cookieConfig}`;
};

const erase = (name) => {
    create(name, '', -1);
};

const createWithExpirationDate = (name, value, expirationDate) => {
    const expires = `; expires=  ${expirationDate.toGMTString()}`;
    document.cookie = `${name} = ${value}; ${expires} ${config.storage.cookieConfig}`;
};

const read = (name) => {
    let result;
    const nameEQ = `${name}=`;

    const cookieArray = document.cookie.split(';');

    // eslint-disable-next-line array-callback-return
    cookieArray.map((item) => {
        item = item.trim();

        if (item.indexOf(nameEQ) === 0) {
            result = item.substring(nameEQ.length, item.length);
        }
    });
    return result;
};

const cookies = {
    create,
    createWithExpirationDate,
    erase,
    read
};

export default cookies;
