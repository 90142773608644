export const CupIcon = () => {
    return (
        <svg
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M24.9999 48.9998C38.2547 48.9998 48.9998 38.2547 48.9998 24.9999C48.9998 11.7451 38.2547 1 24.9999 1C11.7451 1 1 11.7451 1 24.9999C1 38.2547 11.7451 48.9998 24.9999 48.9998Z"
                stroke="#34303D"
                strokeWidth="2"
            />
            <path
                d="M25 4.35938C30.474 4.35938 35.7239 6.53393 39.5946 10.4047C43.4654 14.2754 45.6399 19.5253 45.6399 24.9993C45.6399 30.4734 43.4654 35.7232 39.5946 39.5939C35.7239 43.4647 30.474 45.6392 25 45.6392V4.35938Z"
                fill="#00D1D2"
            />
            <path
                d="M18.7391 22.0954H17.6959C16.8659 22.0954 16.0699 21.7657 15.483 21.1788C14.8961 20.5919 14.5664 19.7959 14.5664 18.9659V13.75H18.7391"
                stroke="#34303D"
                strokeWidth="2"
                strokeMiterlimit="10"
            />
            <path
                d="M31.2578 22.0954H32.301C33.131 22.0954 33.927 21.7657 34.5139 21.1788C35.1008 20.5919 35.4305 19.7959 35.4305 18.9659V13.75H31.2578"
                stroke="#34303D"
                strokeWidth="2"
                strokeMiterlimit="10"
            />
            <path
                d="M25 32.5272V28.3545"
                stroke="#34303D"
                strokeWidth="1.93"
                strokeMiterlimit="10"
            />
            <path
                d="M24.9973 28.3544C23.3373 28.3544 21.7453 27.695 20.5715 26.5212C19.3977 25.3474 18.7383 23.7554 18.7383 22.0954V13.75H31.2564V22.0954C31.2564 23.7554 30.5969 25.3474 29.4231 26.5212C28.2493 27.695 26.6573 28.3544 24.9973 28.3544Z"
                fill="#FFFDFB"
                stroke="#34303D"
                strokeWidth="2"
                strokeMiterlimit="10"
                strokeLinecap="square"
            />
            <path
                d="M31.2564 36.7H18.7383C18.7383 35.5934 19.1779 34.532 19.9604 33.7495C20.743 32.967 21.8043 32.5273 22.911 32.5273H27.0837C28.1903 32.5273 29.2517 32.967 30.0342 33.7495C30.8167 34.532 31.2564 35.5934 31.2564 36.7Z"
                fill="#FFFDFB"
                stroke="#34303D"
                strokeWidth="2"
                strokeMiterlimit="10"
                strokeLinecap="square"
            />
        </svg>
    );
};
