export const SuccessRedeem = () => {
    return (
        <svg
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M26.34 3.70005C23.881 2.25405 21.02 1.42505 17.961 1.42505C8.83003 1.42505 1.42603 8.82905 1.42603 17.962C1.42603 27.096 8.83003 34.5 17.963 34.5C27.097 34.5 34.501 27.096 34.501 17.963"
                stroke="#34303D"
                strokeWidth="2"
                strokeLinecap="round"
            />
            <path
                d="M24.895 6.363C22.884 5.179 20.54 4.5 18.037 4.5C10.561 4.5 4.5 10.56 4.5 18.037C4.5 25.514 10.56 31.575 18.037 31.575C25.514 31.575 31.575 25.515 31.575 18.037"
                stroke="#00D1D2"
                strokeWidth="2"
                strokeLinecap="round"
            />
            <path
                d="M9.91101 16.602L16.597 23.6L33.556 6.17798"
                stroke="#34303D"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
