import styles from './IqosNova.module.scss';

// Helpers
import classNames from 'classnames';
import { cardData } from '@containers/IQOSUniverse/articles/IqosNova/IqosNovaCardData';

// Assets
import image1 from './assets/image.jpg';

//Components
import KeenSlider from '@components/KeenSlider';
import ArticleImage from '@containers/IQOSUniverse/components/ArticleImage';
import ArticleHeader from '@containers/IQOSUniverse/components/ArticleHeader';
import ArticleContainer from '@containers/IQOSUniverse/components/ArticleContainer';

const IqosNova = () => {
    return (
        <section className={styles.wrapper}>
            <ArticleHeader />

            <div className={classNames(styles.contentNova, 'content')}>
                <h3>Credem în puterea de exprimare a artei și tehnologiei.</h3>

                <p>
                    Și de aceea, anul acesta ne alăturăm, în calitate de parteneri, festivalului
                    NOVA ce va avea loc între 22 noiembrie - 17 decembrie, la București.
                </p>

                <p>
                    Aceasta deoarece noi punem întotdeauna inovația și creativitatea pe primul loc.
                    Prin îmbinarea acestora, reușim să ne apropiem de comunitatea noastră și să
                    oferim experiențe neașteptate.
                </p>

                <p>
                    Exact asta vom face și la festival. Construită ca o experiență de conectare,
                    expoziția de la NOVA de anul acesta creează un context în care fiecare își
                    descoperă diferite dimensiuni ale sinelui.
                </p>
            </div>

            <div className={styles.novaArticleWrapper}>
                <ArticleContainer background={'#34303D'} textColor={'#FFFDFB'}>
                    <div className={classNames(styles.novaArticleImage, 'content')}>
                        <ArticleImage mobileImage={image1} image={image1} />

                        <h3 className={styles.midArticleTitle}>
                            Între 5 – 8 decembrie te așteptăm în spațiul de la Platforma Wolff
                        </h3>

                        <p>
                            Vom organiza o serie de evenimente și workshop-uri care explorează
                            relația strânsă dintre creativitate și inovație, artă și tehnologie.
                            Experiențele sunt gândite astfel încât să îți cucerească simțurile la
                            fața locului. Iată pe scurt care sunt elementele care te așteaptă în
                            spațiul nostru :
                        </p>

                        <KeenSlider withArrows={false} mode={'snap'} origin={'auto'} spacing={10}>
                            {cardData.map(({ title, description, id, boldDescription }) => {
                                return (
                                    <div className={styles.cardContainer} key={id}>
                                        <h4>{title}</h4>
                                        <p>
                                            <strong>{boldDescription}</strong>
                                            {description}
                                        </p>
                                    </div>
                                );
                            })}
                        </KeenSlider>
                    </div>
                </ArticleContainer>
            </div>
        </section>
    );
};

export default IqosNova;
