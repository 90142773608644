import React, { useEffect, useState } from 'react';
import styles from './ClubZeroBill.module.scss';

// Helpers
import classnames from 'classnames';
import dayjs from '@utils/dayjs';

// Components
import ArticleHeader from '@containers/IQOSUniverse/components/ArticleHeader';
import CompletedStateArticle from '@containers/IQOSUniverse/components/CompletedStateArticle/CompletedStateArticle';
import ArticleContainer from '@containers/IQOSUniverse/components/ArticleContainer';
import ArticleImage from '@containers/IQOSUniverse/components/ArticleImage';
import BaseModal from '@components/Layout/BaseModal';
import VideoDisclaimer from '@containers/IQOSUniverse/components/VideoDisclaimer';
import RatingQuiz from '@containers/IQOSUniverse/components/RatingQuiz';
import VimeoPlayer from '@components/VimeoPlayer';

// Assets
import videoThumbnailMobile from './assets/videoThumbnailMobile.png';
import videoThumbnail from './assets/videoThumbnail.png';

// Redux
import { useAppSelector } from '@app/hooks';

const ClubZeroBill = () => {
    const [toggleModal, setToggleModal] = useState(false);
    const [showDisclaimer, toggleDisclaimer] = useState(false);
    const [isPastPercentage, setIsPastPercentage] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [hidePlayer, setHidePlayer] = useState(false);
    const [videoEnded, setVideoEnded] = useState(false);

    const { article } = useAppSelector((state) => state.iqosUniverse);

    useEffect(() => {
        if (!article.completed_quiz && (article.quiz_points || article.points)) {
            toggleDisclaimer(true);
        }
    }, [article]);

    let playing = false;
    let progress = 0;
    const triggerMomentPercentage = 75; // 75%

    const handleTimeUpdate = (data: any) => {
        if (playing && data.seconds - progress <= 10) {
            if (data.seconds < progress) {
                //don't update progress if user seeked back
            } else {
                progress = data.seconds;
            }
        }

        const durationPercentage = (triggerMomentPercentage * data.duration) / 100;

        if (progress >= durationPercentage && !isPastPercentage) {
            setIsPastPercentage(true);
        }
    };

    useEffect(() => {
        if (videoEnded) {
            if (isPastPercentage && !article.completed_quiz) {
                setHidePlayer(true);
            } else {
                setTimeout(() => {
                    handleCloseModal();
                }, 1000);
            }
        }
    }, [videoEnded, isPastPercentage, article]);

    const handleCloseModal = () => {
        setToggleModal(false);
        setHidePlayer(false);
        setIsPastPercentage(false);
        toggleDisclaimer(true);
        setVideoEnded(false);
    };

    return (
        <section className={styles.wrapper}>
            <ArticleHeader />

            <div className="content">
                <p>
                    În această primăvară am vrut să le facem o surpriză membrilor IQOS Club cu care
                    ne-am întâlnit întâmplător în locațiile IQOS din țară. Pentru că atunci când
                    ești membru IQOS Club, primești mereu mai mult.
                </p>
                <p>
                    Mai exact, i-am surprins pe aceștia cu cel mai frumos cadou pe care îl poți
                    primi atunci când ieși cu prietenii în oraș: le-am achitat nota de plată!
                </p>
            </div>

            <div className={styles.space}>
                <ArticleContainer background={'#00D1D2'}>
                    <div className={styles.videoContainer}>
                        <h3>
                            Cum au primit această veste? Urmărește video-ul de mai jos și vezi care
                            a fost reacția lor:
                        </h3>

                        <ArticleImage
                            isVideo
                            mobileImage={videoThumbnailMobile}
                            image={videoThumbnail}
                            onClick={() => setToggleModal(true)}
                        />

                        <BaseModal
                            size="large"
                            maskClosable={true}
                            isModalOpen={toggleModal}
                            className={styles.videoModal}
                            closeModal={() => handleCloseModal()}
                            destroyOnClose={true}
                        >
                            <div className={styles.modalContent}>
                                {!article.completed_quiz &&
                                    dayjs(article.quiz_end_date).isAfter(dayjs()) &&
                                    showDisclaimer && (
                                        <VideoDisclaimer
                                            handleClose={() => toggleDisclaimer(false)}
                                        />
                                    )}

                                {isPastPercentage && article.quiz_slug && (
                                    <div
                                        className={classnames(
                                            styles.rating,
                                            hidePlayer && styles.padding
                                        )}
                                    >
                                        <RatingQuiz
                                            handleSuccess={() => setShowSuccess(true)}
                                            quizSlug={article.quiz_slug}
                                        />
                                    </div>
                                )}

                                {!hidePlayer && (
                                    <VimeoPlayer
                                        className={styles.player}
                                        video="824669934"
                                        options={{
                                            autoplay: false,
                                            responsive: true
                                        }}
                                        onPlay={() => (playing = true)}
                                        onTimeUpdate={(progress) => handleTimeUpdate(progress)}
                                        onPause={() => (playing = false)}
                                        onEnd={() => setVideoEnded(true)}
                                    />
                                )}
                            </div>
                        </BaseModal>
                    </div>
                </ArticleContainer>
            </div>

            <div className={classnames('content', styles.contentDisclaimer)}>
                <p>
                    <strong>Nu uita!</strong> Atunci când ești membru în comunitatea IQOS Club, poți
                    fi oriunde și oricând surprins cu un beneficiu exclusiv.
                </p>
            </div>

            {(showSuccess || article.completed_quiz) && (
                <CompletedStateArticle
                    className={styles.completedStateArticle}
                    article={{
                        points: article.points,
                        quiz_points: article.quiz_points,
                        quiz_end_date: article.quiz_end_date
                    }}
                />
            )}
        </section>
    );
};

export default ClubZeroBill;
