import styles from './LilSolidEntersIqosClub.module.scss';

// Helpers
import classNames from 'classnames';

// Assets
import img1 from './assets/image1.jpg';
import imageDesktop from './assets/imageDesktop.png';
import regular from './assets/regular.svg';
import marine from './assets/marine.svg';

// Components
import ArticleImage from '@containers/IQOSUniverse/components/ArticleImage';
import ArticleHeader from '@containers/IQOSUniverse/components/ArticleHeader';
import ArticleSlider from '@containers/IQOSUniverse/components/ArticleSlider';
import ArticleContainer from '@containers/IQOSUniverse/components/ArticleContainer';

const LilSolidEntersIqosClub = () => {
    return (
        <section className={styles.wrapper}>
            <ArticleHeader />

            <div className="content">
                <h3>Descoperă beneficiile lil SOLID 2.0</h3>
                <p>
                    Îți dorești să ai parte de o nouă experiență fără fum? lil SOLID 2.0 este un
                    dispozitiv cu un preț accesibil și un design compact, care îl face foarte ușor
                    de folosit.
                </p>
                <p>
                    lil SOLID 2.0 are la bază un element de încălzire, care îți asigură o experiență
                    fără fum, foc sau scrum.
                </p>
                <p>
                    În plus, cu lil SOLID 2.0 te bucuri de 3 utilizări consecutive, fără
                    reîncărcare.
                </p>
            </div>

            <ArticleContainer background={'#F6F4F0'}>
                <div className="content">
                    <h3>Fiit, rezervele din tutun pentru lil SOLID 2.0</h3>

                    <ArticleSlider
                        fullWidthImage
                        imageBackgroundColor={'transparent'}
                        imageObjectFit={'contain'}
                        belowDescription
                        bottomButtons
                        turquoiseArrows
                        slides={[
                            {
                                image: regular,
                                belowDescription: {
                                    title: 'Fiit Regular',
                                    paragraph: 'Intens și aromat'
                                }
                            },
                            {
                                image: marine,
                                belowDescription: {
                                    title: 'Fiit Marine',
                                    paragraph: 'Proaspăt și răcoritor'
                                }
                            }
                        ]}
                    />
                </div>
            </ArticleContainer>

            <div className={styles.marginUp}>
                <ArticleContainer background={'#34303D'} textColor={'#F6F4F0'}>
                    <ArticleImage cover={true} mobileImage={img1} image={imageDesktop} />

                    <div className={classNames(styles.contentMargin, 'content')}>
                        <h3 className={styles.white}>Invită-ți prietenii utilizatori de lil SOLID 2.0 în IQOS Club!</h3>

                        <p className={styles.white}>
                            Acum, prietenii tăi care au un dispozitiv lil SOLID 2.0 pot intra în
                            IQOS Club și se pot bucura de toate beneficiile platformei. E momentul
                            să îi inviți să facă parte, alături de tine, din această comunitate
                            vibrantă.
                        </p>
                    </div>
                </ArticleContainer>
            </div>
        </section>
    );
};

export default LilSolidEntersIqosClub;
