import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import store from 'store';
import { config } from '@utils/config';
import axiosRequest from '@utils/axios';
import { RootState } from '@app/store';

const userAvatar = store.get(config.user.user)?.avatars;
const userFullName = store.get(config.user.user)?.fullname;
export interface LeaderBoardOption {
    position: number;
    dcsId: string;
    privacy: string;
    county: string;
    countyId: string;
    fpNb?: string;
    lastActivity?: string;
    lastActivityDate?: string;
    lastFpNb?: string;
    name: string;
    badges?: string;
    avatar: {
        full: string;
        thumbnail: string;
    };
}

export interface LeaderboardState {
    status: 'idle' | 'loading' | 'failed';
    updatedAt: string;
    leaderboardType: string;
    myPlace: LeaderBoardOption;
    leaderboard: LeaderBoardOption[];
    feedLeaderboard: LeaderBoardOption[];
    myPlaceFeed: LeaderBoardOption;
    error: any;
}

interface AxiosError {
    response: {
        data: {
            message: string;
        };
    };
}

const initialState: LeaderboardState = {
    status: 'idle',
    updatedAt: '',
    leaderboardType: '',
    myPlace: {
        position: 0,
        dcsId: '',
        privacy: '',
        county: '',
        countyId: '0',
        name: userFullName,
        fpNb: '',
        avatar: userAvatar
    },
    myPlaceFeed: {
        position: 0,
        dcsId: '',
        privacy: '',
        county: '',
        countyId: '',
        name: userFullName,
        fpNb: '',
        avatar: userAvatar
    },
    leaderboard: [],
    feedLeaderboard: [],
    error: {}
};

export const getLeaderboard = createAsyncThunk('dashboard/Leaderboard', async (values: object) => {
    try {
        const response = await axiosRequest.get('/leaderboard', { params: values }).then((res) => {
            return res;
        });

        return response.data;
    } catch (e) {
        return (e as AxiosError).response.data.message;
    }
});

export const getLeaderboardFeed = createAsyncThunk(
    'dashboard/LeaderboardFeed',
    async (values: object) => {
        try {
            const response = await axiosRequest
                .get('/leaderboard', { params: values })
                .then((res) => {
                    return res;
                });

            return response.data;
        } catch (e) {
            return (e as AxiosError).response.data.message;
        }
    }
);

export const leaderboardSlice = createSlice({
    name: 'leaderboard',
    initialState,
    reducers: {
        resetSmallLeaderboard: (state) => {
            state.feedLeaderboard = [];
        },
        resetLeaderboard: (state) => {
            state.leaderboard = [];
        }
    },
    extraReducers: (builder) => {
        builder
            // LeaderBoard
            .addCase(getLeaderboard.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getLeaderboard.fulfilled, (state, action) => {
                state.status = 'idle';

                state.myPlace = {
                    ...action.payload.consumerLeaderboard,
                    name:
                        action.payload.consumerLeaderboard.privacy === 'ON'
                            ? 'ANONIM'
                            : userFullName,
                    avatar: action.payload.consumerLeaderboard.privacy === 'ON' ? {} : userAvatar,
                    county: ''
                };

                state.leaderboard = action.payload.leaderboard.slice(0, 50);
                state.leaderboardType = action.payload.leaderboardType;
            })
            .addCase(getLeaderboard.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })

            // LeaderBoard Feed
            .addCase(getLeaderboardFeed.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getLeaderboardFeed.fulfilled, (state, action) => {
                state.status = 'idle';

                state.myPlaceFeed = {
                    ...action.payload.consumerLeaderboard,
                    name:
                        action.payload.consumerLeaderboard.privacy === 'ON'
                            ? 'ANONIM'
                            : userFullName,
                    avatar: action.payload.consumerLeaderboard.privacy === 'ON' ? {} : userAvatar
                };
                state.feedLeaderboard = action.payload.leaderboard.slice(0, 5);
                state.updatedAt = action.payload.updatedAt;
                state.leaderboardType = action.payload.leaderboardType;
            })
            .addCase(getLeaderboardFeed.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    }
});

export const { resetSmallLeaderboard, resetLeaderboard } = leaderboardSlice.actions;
export const selectLoading = (state: RootState) => state.leaderboard.status === 'loading';
export const selectFailed = (state: RootState) => state.leaderboard.status === 'failed';
export const selectError = (state: RootState) => state.leaderboard.error;

export default leaderboardSlice.reducer;
