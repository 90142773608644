import styles from './IqosXDiploma.module.scss';

// Assets
import img1 from './assets/iqosXDiploma1.png';
import img2 from './assets/iqosXDiploma2.png';

// Components
import ArticleImage from '@containers/IQOSUniverse/components/ArticleImage';
import ArticleHeader from '@containers/IQOSUniverse/components/ArticleHeader';
import ArticleContainer from '@containers/IQOSUniverse/components/ArticleContainer';

const IqosXDiploma = () => {
    return (
        <section className={styles.wrapper}>
            <ArticleHeader />
            <div className="content">
                <h3>IQOS a primit distincția de Superbrand</h3>

                <p>
                    Pentru al doilea an consecutiv, IQOS a fost partener Diploma, festivalul dedicat
                    noii generații de artiști, arhitecți și designeri, care a avut loc anul acesta
                    în perioada 8-17 octombrie, la Combinatul Fondului Plastic. Sub umbrela Together
                    We Art, IQOS a prezentat două proiecte artistice: o pictură murală realizată de
                    PREN, artist grafic și ilustrator, și o expoziție-concept ce a cuprins creațiile
                    unor artiști multidisciplinari care și-au propus să prezinte viziunea lor despre
                    un viitor mai bun, o temă care se numără printre valorile IQOS.
                </p>
            </div>

            <ArticleImage cover image={img1} />

            <ArticleContainer background={'#F6F4F0'}>
                <div className="content">
                    <h3>PREN pentru Together We Art</h3>

                    <p>
                        Curtea interioară a Combinatului Fondului Plastic este locul unde ai putut
                        vedea muralul realizat de artistul PREN, care a folosit simbolurile și
                        formele geometrice caracteristice lucrărilor sale pentru a oferi o
                        perspectivă optimistă asupra viitorului, axată pe colaborare și schimbare.
                    </p>

                    <ArticleImage image={img2} />

                    <h3>Expoziție-concept multidisciplinară</h3>

                    <p>
                        Sub aceeași umbrelă, Together We Art, ai putut explora expoziția pregătită
                        de 8 artiști multidisciplinari din modă, pictură și ceramică. Cu acest
                        prilej, ai putut descoperi temele propuse de artiști și să explorezi
                        tehnicile de lucru inovatoare prin care aceștia și-au imaginat viitorul.
                    </p>
                </div>
            </ArticleContainer>
        </section>
    );
};

export default IqosXDiploma;
