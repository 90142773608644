export const advices = [
    {
        id: 1,
        text: `<strong>Sfat de conexiune:</strong> Îmbrățișările sunt mult mai plăcute
                când nu se simte miros de fum.`
    },
    {
        id: 2,
        text: `<strong>Sfat de conexiune:</strong> Introdu-ți prietenii fumători în lumea IQOS și oferă-le darul schimbării.`
    },
    {
        id: 3,
        text: `<strong>Sfat de conexiune:</strong> Un gest pe care cei dragi îl vor aprecia cu siguranță este să renunți la fumatul tradițional.`
    },
    {
        id: 4,
        text: `<strong>Sfat de conexiune:</strong> Întâlnirile sunt mult mai interesante atunci când nu sunt întrerupte de pauzele pentru fumat.`
    },
    {
        id: 5,
        text: `<strong>Sfat de conexiune:</strong> Fără mirosul de fum, cei din jurul tău îți vor putea aprecia și complimenta parfumul.`
    },
    {
        id: 6,
        text: `<strong>Sfat de conexiune:</strong> E mult mai ușor să comunici cu ceilalți atunci când elimini bariera mirosului de fum.`
    },
    {
        id: 7,
        text: `<strong>Sfat de conexiune:</strong> Sesiunile de lucru în echipă sunt mai productive când nu sunt întrerupte de pauzele pentru fumat.`
    },
    {
        id: 7,
        text: `<strong>Sfat de conexiune:</strong> Un gest de empatie față de cei din jur e să renunți la fumatul tradițional.`
    }
];
