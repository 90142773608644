export const QuoteIcon = () => {
    return (
        <svg
            width="24"
            height="21"
            viewBox="0 0 24 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0 14.0359C0 12.3786 0.394366 10.6837 1.1831 8.95112C2.00939 7.21854 3.09859 5.61778 4.4507 4.14886C5.84038 2.64226 7.3615 1.39933 9.01408 0.420044L11.2676 3.58389C10.0282 4.67616 8.88263 5.93793 7.83099 7.36919C6.8169 8.76279 6.15963 10.0811 5.85916 11.324C7.02347 11.55 8 12.0773 8.78873 12.9059C9.57747 13.6969 9.97183 14.6762 9.97183 15.8438C9.97183 16.6724 9.76526 17.4445 9.35211 18.1602C8.93897 18.8381 8.37559 19.3843 7.66197 19.7986C6.98592 20.2129 6.19718 20.42 5.29577 20.42C4.43192 20.42 3.58685 20.2129 2.76056 19.7986C1.97183 19.3466 1.31455 18.6498 0.788732 17.7082C0.262911 16.7666 0 15.5425 0 14.0359ZM24 3.58389C22.7606 4.71383 21.6338 5.9756 20.6197 7.36919C19.6056 8.76279 18.9484 10.0811 18.6479 11.324C19.8122 11.55 20.7887 12.0773 21.5775 12.9059C22.3662 13.6969 22.7606 14.6762 22.7606 15.8438C22.7606 16.6724 22.554 17.4445 22.1408 18.1602C21.7277 18.8381 21.1643 19.3843 20.4507 19.7986C19.7371 20.2129 18.9296 20.42 18.0282 20.42C17.1643 20.42 16.3193 20.2129 15.493 19.7986C14.7042 19.3466 14.0469 18.6498 13.5211 17.7082C13.0329 16.7666 12.7887 15.5425 12.7887 14.0359C12.7887 12.3786 13.1831 10.6837 13.9718 8.95112C14.7606 7.21854 15.831 5.61778 17.1831 4.14886C18.5728 2.64226 20.0939 1.39933 21.7465 0.420044L24 3.58389Z"
                fill="#FFFDFB"
            />
        </svg>
    );
};
