import styles from './RelatedArticles.module.scss';

// Helpers
import { useWindowSize } from '@utils/hooks/windowSize';

// Components
import KeenSlider from '@components/KeenSlider';
import ArticleCard from '@components/ArticleCard';
import BaseLoader from '@components/Layout/BaseLoader';

// Redux
import { useAppSelector } from '@app/hooks';

const RelatedArticles = () => {
    const windowSize = useWindowSize();

    const { relatedArticles, status } = useAppSelector((state) => state.iqosUniverse);

    const isMobile = windowSize.width < 650;

    if (status === 'loading') {
        return <BaseLoader size="normal" />;
    }

    return (
        <>
            {relatedArticles.length > 0 && (
                <section className={styles.wrapper}>
                    <h3>Recomandări</h3>

                    <div className={styles.listArticles}>
                        <KeenSlider reset={true} withArrows={true} spacing={isMobile ? 8 : 16}>
                            {relatedArticles.map((item) => (
                                <ArticleCard
                                    key={item.id}
                                    seen={item.seen}
                                    image={item.picture}
                                    points={item.points}
                                    quiz_points={item.quiz_points}
                                    date={item.created_at}
                                    title={item.title}
                                    description={item.subtitle}
                                    episode={item.episode}
                                    articleSlug={item.slug}
                                    categorySlug={item.category.slug}
                                    completed_quiz={item.completed_quiz}
                                    quiz_end_date={item.quiz_end_date}
                                    quiz_id={item.quiz_id}
                                    display_badge_icon={item.badges?.includes('BADGE')}
                                />
                            ))}
                        </KeenSlider>
                    </div>
                </section>
            )}
        </>
    );
};

export default RelatedArticles;
