import { useState, useEffect } from 'react';
import { IIntroCard } from './interface';

// Styles
import styles from './IntroCard.module.scss';

// Components
import Button from '@components/Layout/Button';
import CardWrapper from '../CardWrapper';

const IntroCard = ({ transitionDuration = 2000, introText, onStart }: IIntroCard) => {
    const Texts = [
        <>
            <div>{introText}</div>
            <Button label="Start" size="normal" color="dark" hover="light" onClick={onStart} />
        </>
    ];
    const [currentText, setCurrentText] = useState(0);

    useEffect(() => {
        Texts.forEach((_, index) => {
            setTimeout(() => {
                setCurrentText(index);
            }, transitionDuration * index);
        });
    }, []);

    return (
        <CardWrapper>
            <div
                key={currentText}
                className={styles.introText}
                style={{ animationDuration: `${transitionDuration}ms` }}
            >
                {Texts[currentText]}
            </div>
        </CardWrapper>
    );
};

export default IntroCard;
