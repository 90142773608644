import classnames from 'classnames';
import styles from './ArticleCard.module.scss';
import { IArticleCardProps } from './interface';

// Utils
import dayjs from '@utils/dayjs';
import { config } from '@utils/config';

// Assets
import arrow from './assets/arrow.svg';
import { BadgeIcon, ExtraChances, SeenArticleIcon } from '@assets/icons';

// Components
import { Typography } from 'antd';
import { Link } from 'react-router-dom';
import Button from '@components/Layout/Button';
import RewardBadge from '@components/RewardBadge';
import React from 'react';

const { Paragraph } = Typography;

const ArticleCard = (props: IArticleCardProps) => {
    const {
        image,
        points,
        quiz_points,
        date,
        title,
        description,
        className,
        articleSlug,
        categorySlug,
        seen,
        quiz_id,
        completed_quiz,
        quiz_end_date,
        display_badge_icon,
        tagEvent,
        quiz_chances
    } = props;

    const articleURL = `${config.path.iqosUniverse.index}/${categorySlug}/${articleSlug}`;

    return (
        <div className={classnames('card', styles.wrapper, className)}>
            <div className={styles.thumbnail}>
                <Link to={articleURL}>
                    <img src={image} alt={title} />
                </Link>

                {categorySlug === 'iqos-quiz' && completed_quiz ? (
                    <SeenArticleIcon />
                ) : !quiz_id ? (
                    seen && <SeenArticleIcon />
                ) : (
                    seen && completed_quiz && <SeenArticleIcon />
                )}

                {!completed_quiz && dayjs(quiz_end_date).isAfter(dayjs()) && (
                    <RewardBadge absolute points={quiz_points || points} small={true} />
                )}
                {categorySlug === 'iqos-quiz' && points && !seen && (
                    <RewardBadge absolute points={points} small={true} />
                )}

                <div
                    className={classnames(
                        styles.badgeContainer,
                        (completed_quiz ||
                            dayjs(quiz_end_date).isBefore(dayjs()) ||
                            !quiz_points) &&
                            styles.badgeNoFp
                    )}
                >
                    {!completed_quiz && dayjs(quiz_end_date).isAfter(dayjs()) && quiz_chances ? (
                        <div className={styles.badge}>
                            <ExtraChances />
                        </div>
                    ) : null}

                    {!completed_quiz && display_badge_icon ? (
                        <div className={styles.badge}>
                            <BadgeIcon />
                        </div>
                    ) : null}
                </div>
            </div>

            <section className={styles.details}>
                {categorySlug !== 'iqos-quiz' && (
                    <p className={styles.date}>{dayjs(date, 'DD/MM/YYYY').format('D MMMM YYYY')}</p>
                )}

                <Link to={articleURL}>
                    <p className={styles.title} dangerouslySetInnerHTML={{ __html: title }} />
                </Link>

                <Paragraph ellipsis={{ rows: 3 }} className={styles.description}>
                    <div dangerouslySetInnerHTML={{ __html: description }} />
                </Paragraph>
            </section>

            <div className={styles.arrow}>
                <Button
                    noStyle={categorySlug !== 'iqos-quiz'}
                    color={categorySlug === 'iqos-quiz' ? 'dark' : 'light'}
                    type="text"
                    hover={categorySlug === 'iqos-quiz' ? 'light' : 'turquoise'}
                    label={categorySlug === 'iqos-quiz' ? 'Începe acum' : ''}
                    href={articleURL}
                    tagEvent={tagEvent && tagEvent(articleURL)}
                >
                    {categorySlug !== 'iqos-quiz' && <img src={arrow} alt={title} />}
                </Button>
            </div>
        </div>
    );
};

export default ArticleCard;
