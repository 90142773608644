import React, { useEffect, useState } from 'react';
import classnames from 'classnames';

//Styles
import styles from './Radio.module.scss';

//Components
import { Form } from 'antd';
import { Radio as AntdRadio, Input } from 'antd';

//interfaceProps
import { IRadioProps } from '@components/Quiz/Radio/interface';

const Radio = (props: IRadioProps) => {
    const { data, noTitle, radioBackground } = props;

    const [isInput, setIsInput] = useState(false);

    useEffect(() => {
        if (data?.answer?.values[0]?.custom_value) {
            setIsInput(true);
        }
    }, [data]);

    const handleShowInput = (customValue: boolean | string | null) => {
        if (customValue === true || customValue === '1') {
            setIsInput(true);
        } else if (customValue === false || customValue === '0' || customValue === null) {
            setIsInput(false);
        } else {
        }
    };

    return (
        <section
            className={classnames(
                styles.radioContainer,
                radioBackground === 'light' && styles.lightRadio
            )}
        >
            {!noTitle && <h3 className={styles.title}>{data?.title}</h3>}

            <Form.Item
                name={`${data?.id}`}
                rules={[
                    {
                        required: data.client_validation === 'required',
                        message: 'Acest câmp este obligatoriu'
                    }
                ]}
                initialValue={data?.answer?.values[0]?.value}
            >
                <AntdRadio.Group>
                    {data?.options?.map((item: any) => {
                        return (
                                <AntdRadio
                                    key={item.value}
                                    value={item.value}
                                    onChange={() => handleShowInput(item.is_custom_value_input)}
                                >
                                    {item.value}
                                </AntdRadio>
                        );
                    })}
                    {isInput && (
                        <Form.Item
                            name={['withInput', `${data?.id}`]}
                            rules={[
                                {
                                    required: data.client_validation === 'required',
                                    message: 'Acest câmp este obligatoriu'
                                }
                            ]}
                            initialValue={data?.answer?.values[0]?.custom_value}
                        >
                            <Input placeholder={'Scrie aici răspunsul tău...'} />
                        </Form.Item>
                    )}
                </AntdRadio.Group>
            </Form.Item>
        </section>
    );
};

export default Radio;
