export const ExtraChancesWhite = () => {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clip-path="url(#clip0_33232_27621)">
                <circle
                    cx="15.6422"
                    cy="16.0089"
                    r="14.3449"
                    fill="#FFFDFB"
                    stroke="#00D1D2"
                    stroke-width="1.88757"
                />
                <path
                    d="M15.6611 4.98242C18.5844 4.98242 21.3879 6.14368 23.4549 8.21072C25.522 10.2778 26.6832 13.0813 26.6832 16.0045C26.6832 18.9278 25.522 21.7313 23.4549 23.7983C21.3879 25.8654 18.5844 27.0266 15.6611 27.0266V4.98242Z"
                    fill="#00D1D2"
                />
                <path
                    d="M17.5584 23.1994H14.2754V15.6228H10.4688L15.9143 8.36035L21.3598 15.6228H18.0768H17.5532V23.1994H17.5584Z"
                    fill="#FFFDFB"
                />
                <path
                    d="M17.0371 22.6693V15.0927H20.3202L15.9218 9.22828L11.5235 15.0927H14.8066V22.6693H17.0424M18.0896 23.7165H13.7593V16.1399H10.4763C10.047 16.1399 9.80086 15.6477 10.0574 15.3021L15.503 8.03969C15.7124 7.76218 16.1313 7.75694 16.3407 8.03969L21.7863 15.3021C22.0428 15.6477 21.7967 16.1399 21.3674 16.1399H18.0844V23.7165H18.0896Z"
                    fill="#34303D"
                />
            </g>
            <defs>
                <clipPath id="clip0_33232_27621">
                    <rect
                        width="31.9997"
                        height="30.559"
                        fill="white"
                        transform="translate(0 0.720459)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};
