import styles from './HarmonyWithIqos.module.scss';

// Assets
import img1Desktop from './assets/image1Desktop.png';
import img1Mobile from './assets/harmony1Mobile.png';

// Components
import ArticleImage from '@containers/IQOSUniverse/components/ArticleImage';
import ArticleHeader from '@containers/IQOSUniverse/components/ArticleHeader';
import ArticleContainer from '@containers/IQOSUniverse/components/ArticleContainer';

const HarmonyWithIqos = () => {
    return (
        <section className={styles.wrapper}>
            <ArticleHeader />

            <div className="content">
                <h3>Ne vedem la Jazz in the Park!</h3>

                <p>
                    Prin muzică poți lega noi prietenii, înveți să apreciezi culturi diferite și să
                    cunoști stiluri de viață diferite. Prin muzică devii din ce în ce mai bun. Iar
                    dacă înainte erai fidel unui singur stil de muzică, acum ești deschis să
                    descoperi noi genuri muzicale, care îți extind orizonturile.
                </p>

                <p>
                    Un moment bun să faci acest lucru este la festivalul Jazz in the Park, între 1
                    și 4 septembrie. Evenimentul are loc în Parcul Etnografic din Cluj-Napoca iar
                    printre invitați îi vei regăsi pe The Comet is Coming, Asaf Avidan sau Avishai
                    Cohen Quartet.
                </p>
            </div>

            <div className="content">
                <h3>Împreună, pentru un viitor fără fum.</h3>

                <p>
                    În cadrul festivalului Jazz in the park, utilizarea țigărilor convenționale este
                    interzisă în afara zonelor special amenajate. Astfel, vei avea parte de o
                    experiență fără fum și fără scrum. Pentru că IQOS este un dispozitiv care
                    încălzește tutunul și nu generează fum sau scrum, te poți bucura de dispozitivul
                    tău oriunde în festival.
                </p>
            </div>

            <div className={styles.articleWrapper}>
                <ArticleContainer background={'#F6F4F0'}>
                    <div className="content">
                        <h3>Te așteptăm la IQOS Lounge.</h3>

                        <ArticleImage image={img1Desktop} mobileImage={img1Mobile} />

                        <p>
                            Dacă ajungi la Jazz in the park, nu uita să vizitezi spațiul amenajat de
                            noi: IQOS Lounge. Aici te vei putea relaxa între performance-urile
                            artiștilor tăi preferați de la Main Stage cu muzica DJ-ilor locali.
                        </p>

                        <p>
                            În plus, ți-am pregătit un Photo Corner foarte instagramabil, proiecții
                            interactive, o experiență senzorială muzicală și multe alte surprize.
                        </p>

                        <p>Așadar, ne vedem pe 1-4 septembrie la Cluj-Napoca!</p>

                        <p className={styles.important}>
                            <strong>Important:</strong>
                            IQOS nu oferă invitații sau cazare la acest festival. Participarea se
                            face pe propria cheltuială.
                        </p>
                    </div>
                </ArticleContainer>
            </div>
        </section>
    );
};

export default HarmonyWithIqos;
