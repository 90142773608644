export const LogoutIcon = () => {
    return (
        <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M15.793 1.43029L16.8812 1.43029C18.6836 1.43029 20.1458 2.89072 20.1458 4.69488L20.1458 17.7354C20.1458 19.5396 18.6854 21 16.8812 21L15.7948 21"
                stroke="#34303D"
                strokeWidth="2.01048"
                strokeLinecap="round"
            />
            <path
                d="M1.00005 11.062L15.371 11.062"
                stroke="#34303D"
                strokeWidth="2.01048"
                strokeLinecap="round"
            />
            <path
                d="M11.2302 16.1022L16.2348 11.0976L11.2302 6.09297"
                stroke="#34303D"
                strokeWidth="2.01048"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
