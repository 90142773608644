export const TurquoiseArrow = () => {
    return (
        <svg
            width="24"
            height="14"
            viewBox="0 0 24 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0.857117 6.90918H23.1428"
                stroke="#00D1D2"
                strokeWidth="1.71429"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17.1428 12.9092L23.1428 6.90918L17.1428 0.90918"
                stroke="#00D1D2"
                strokeWidth="1.71429"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
