export const IqosCocktailsSliderData = [
    {
        id: 1,
        videoId: '781073931',
        subtitle: 'Pink Gin & Tonic'
    },
    {
        id: 2,
        videoId: '781076675',
        subtitle: 'Cherry Negroni'
    },
    {
        id: 3,
        videoId: '781078888',
        subtitle: 'Passion Whiskey Sour'
    },
    {
        id: 4,
        videoId: '781081314',
        subtitle: 'Cubanana Libre'
    }
];
