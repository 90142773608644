export const config = {
    apiUrl: process.env.REACT_APP_API_URL as string,
    socketUrl: process.env.REACT_APP_SOCKET_URL as string,
    proxiedApiUrl: (process.env.REACT_APP_PROXIED_API_URL ??
        process.env.REACT_APP_API_URL) as string,
    storage: {
        previousRoute: 'previousRoute',
        cookieConfig:
            window.location.hostname === '.iqos.ro' ||
            window.location.hostname === 'localhost' ||
            window.location.hostname === '127.0.0.1'
                ? ';path=/'
                : `;secure;path=/`,
        googleMapsKey: process.env.REACT_APP_GOOGLE_MAPS_KEY as string,
        marketingOptIn: process.env.REACT_APP_MARKETING_OPT_IN as string,
        gtmId: 'GTM-PLBRD26',
        appId: '1401488693436528',
        turnstileKey: process.env.REACT_APP_TURNSTILE_KEY as string,
        oldPoints: 'oldPoints',
        getStream: {
            apiKey: process.env.REACT_APP_GETSTREAM_API_KEY as string,
            appId: process.env.REACT_APP_GETSTREAM_APP_ID as string,
            feedGroup: 'timeline',
            feedGroupSticky: 'sticky',
            userId: 'global'
        },
        licenseKey: process.env.REACT_APP_LICENSE_KEY as string,
        scrollHorizontallyKey: process.env.REACT_APP_SCROLL_HORIZONTALL_KEY as string,
        fadingEffectKey: process.env.REACT_APP_FADING_EFFECT_KEY as string,
        slackKey: process.env.REACT_APP_SLACK_HOOK as string,

        //Entry UserName call for dreams
        entryUserName: 'entryUserName'
    },
    user: {
        authToken: 'authToken',
        authTokenExpiresAt: 'authTokenExpiresAt',
        refreshToken: 'refreshToken',
        refreshTokenExpiresAt: 'refreshTokenExpiresAt',
        autoRefreshToken: 'autoRefreshToken',
        profileHasEligibleDevice: 'profileHasEligibleDevice',
        profileActivatedAt: 'profileActivatedAt',
        termsAndConditions: 'termsAndConditions',
        userId: 'userId',
        isPWA: 'isPWA',
        isAdult: 'isAdult',
        user: 'user',
        getstreamFeedToken: 'getstreamFeedToken',
        appVersion: 'appVersion',
        completed_tutorial: 0,
        tutorial_session: 0,
        selectedIqosAnswersSeason: 'selectedIqosAnswersSeason',
        insertedCodes: 'insertedCodes',
        storyMuted: 'storyMuted',
        postListBenefts: 'postListBenefts',
        flavourBanModalLastShow: 'flavourBanModalLastShow',
        tradeInModal: 'tradeInModal',
        hasVeevDevice: 'hasVeevDevice',
        tasteExplorationLastShow: 'tasteExplorationLastShow',
        realConnectionsModalShow: 'realConnectionsModalShow'
    },
    path: {
        index: '/',
        auth: '/auth',
        example: '/example',
        dashboard: '/dashboard',
        login: '/login',
        firstLogin: '/onboarding',
        contact: '/contact',
        contests: '/concursuri',
        activityJournal: '/jurnal-activitate',
        activitiesJournal: 'jurnal-activitate',
        chancesJournal: 'jurnal-sanse',
        notFound: '/404',
        community: {
            index: '/comunitate',
            activityFeed: 'activity-feed',
            leaderboard: 'leaderboard'
        },
        regulation: {
            index: '/regulament',
            promotion: '/regulament/promotie/:promotion_id'
        },
        FAQs: '/intrebari-frecvente',
        howItWorks: '/cum-functioneaza',
        notifications: '/notificari',
        termsAndConditions: '/termeni-si-conditii',
        termsConditionsTradeIn: '/termeni-si-conditii-trade-in',
        userDataNotification: '/notificare-date-utilizator',
        termsConditionsUGC: '/termeni-si-conditii-ugc',
        cookiesPolicy: '/politica-cookies',
        userProfile: '/profilul-meu',
        userProfileHash: '/profilul-meu/:hashScroll?',
        noDevice: '/inregistrare-dispozitiv',
        profile: '/profil',
        myDevices: '/profilul-meu/dispozitivele-mele',
        map: '/harta',
        locations: '/locatii-iqos',
        listWinners: '/lista-castigatori',
        discoverIQOS: '/descopera-iqos',
        offlineRedemption: '/revendica-heets-offline',
        aboutBadges: '/despre-badge-uri',
        badgesCollectionYear: '/colectia-mea-de-badge-uri/:year?',
        badgesCollection: '/colectia-mea-de-badge-uri/',
        error: '/eroare',
        iqos: {
            index: 'https://www.iqos.ro/',
            iqosOn: 'https://www.iqos-on.ro/',
            map: 'https://www.iqos.ro/store/ro/search',
            shop: 'https://www.iqos.ro/iqos3-duo/heets-iqos-3',
            register: 'https://www.iqos.ro/profiles-add',
            forgotPassword: 'https://www.iqos.ro/auth-loginform?loyalty=1',
            latestNew: 'https://www.iqos.ro/noutati',
            tutorial: 'https://www.iqos.ro/asistenta/',
            warranty: 'https://www.iqos.ro/garantie-iqos',
            termsConditions: 'https://www.iqos.ro/conditii-de-utilizare',
            termsConditionsTradeIn: 'https://www.iqos.ro/termeni-si-conditii-trade-in',
            privacy: 'https://www.iqos.ro/privacy',
            dataProtection: 'https://www.iqos.ro/privacy-business-partners',
            cookiesPolicy: 'https://www.iqos.ro/cookies',
            termsConditionsUGC: 'https://www.iqos.ro/continut-generat-de-utilizatori',
            heetsCollection: 'https://www.iqos.ro/iqos3-duo/heets-iqos-3?login_overlay=1',
            autoDelivery: 'https://www.iqos-on.ro/product/autodelivery',
            capsCollection:
                'https://www.iqos.ro/iqos-originals-duo/accesorii-iqos-originals/duo-personalizare/capacele-colorate-iqos-duo',
            iqosContact: 'https://www.iqos.ro/contact-iqos',
            ilumaPrime: 'https://www.iqos.ro/iluma-prime',
            iluma: 'https://www.iqos.ro/iluma',
            discoverIluma: 'https://www.iqos.ro/despre-iqos/descopera-iluma',
            shopList: 'https://win.heatnotburn.ro/ListaMagazine',
            tradeIn: 'https://www.iqos.ro/trade-in',
            tradeInAbout: 'https://www.iqos.ro/myiqos/asistenta/programul-iqos-trade-in-schimb'
        },
        elefant: 'https://www.elefant.ro',
        samsung: 'https://www.samsung.com/ro',
        samsungProducts: 'https://www.samsung.com/ro/offer/samsung-iqos/',
        electroluxProducts: 'https://www.electrolux.ro/',
        rowenta: 'https://shop.rowenta.ro/',
        rowenta2: 'https://shop.rowenta.ro/i/rowenta-iqos',
        rowentaPlaci:
            'https://shop.rowenta.ro/ingrijirea-parului/placi-si-perii-de-indreptat-parul',
        krups: 'https://shop.tefal.ro/krups/expressoare-krups',
        tefal: 'https://shop.tefal.ro/aparate-de-gatit',
        tefalFierCalcat: 'https://shop.tefal.ro/ingrijirea-tesaturilor/fiare-de-calcat-cu-abur',
        mgm: {
            index: '/recomandari',
            benefits: 'beneficii',
            recommend: 'recomanda',
            history: 'istoric',
            invite: 'recomanda'
        },
        monthlyChallenge: {
            index: '/provocarea-iqos',
            gallery: '/provocarea-iqos/galerie',
            enroll: '/provocarea-iqos/inscriere/:type?',
            oldChallenges: '/provocari-anterioare',
            oldChallengesDetails: '/provocari-anterioare/:challenge_slug',
            endedCampaign: '/provocarea-iqos/incheiata',
            entry: '/provocarea-iqos/:entry_id',
            oldChallengesEntry: '/provocari-anterioare/:challenge_slug/:entry_id'
        },
        iqosUniverse: {
            index: '/universul-iqos',
            article: '/universul-iqos/:category_slug/:article_slug',

            // Categories
            inspiration: '/universul-iqos/inspiratie-inovatie',
            iqosHub: '/universul-iqos/iqos-hub',
            iqosAnswers: '/universul-iqos/iqos-raspunde',
            iqosQuiz: '/universul-iqos/iqos-quiz'
        },
        myRewards: {
            index: '/recompensele-mele/',
            indexId: '/recompensele-mele/:id?',
            giftPage: '/recompensele-mele/cadou-fizic/:hash',
            voucherPage: '/recompensele-mele/voucher/:hash',
            staticDiscount: '/recompensele-mele/discount-accesorii',
            offerVoucher: '/recompensele-mele/offer-voucher/:slug',
            partnersGift: '/recompensele-mele/discount-parteneri/:slug'

            //
            // mgm: '/recompensele-mele/:slug/:hash',
            // tierAdvance: '/recompensele-mele/:slug/:hash/',
            // anniversary: '/recompensele-mele/aniversare/:hash',
            // anniversaryVoucher: '/recompensele-mele/aniversare-voucher/:hash',
            // aniversare: '/recompensele-mele/aniversare-platinum/:hash',
            // bdayGiftVoucher: '/recompensele-mele/cadou-de-ziua-ta/:hash',
            // bdayGift: '/recompensele-mele/cadou-de-ziua-ta-fizic/:hash',
            // accessoriesVoucher: '/recompensele-mele/voucher-accesorii/:hash',
            // mop: '/recompensele-mele/promotii-pentru-membri/:hash',
            // welcomeVoucher: '/recompensele-mele/voucher-de-bun-venit/:hash',
            // yearlyVoucher: '/recompensele-mele/voucher-anual/:hash',
            // giftDropping: '/recompensele-mele/cadou-special/:hash',
            // campaignPrize: '/recompensele-mele/premiu-campanie/:slug/:hash',
            // instantGift: '/recompensele-mele/cadou-instant/:hash/:giftId?',
            // tasteExploration: '/recompensele-mele/exploreaza-gustul/:hash',
            // castingCallsVoucher: '/recompensele-mele/casting-calls-voucher/:hash',
            // weeklyPrizeCastingCalls:
            //     '/recompensele-mele/casting-calls-premiu-saptamanal/:hash/:giftId?',
            // summerVoucher: '/recompensele-mele/voucher-campanie-vara/:hash',
            // summerWeeklyPrizes: '/recompensele-mele/premiu-saptamanal-campanie-vara/:hash/:giftId?'
        },
        socialMedia: {
            facebook: 'https://www.facebook.com/iqos.ro',
            twitter: 'https://twitter.com/iqos_support_ro',
            instagram: 'https://www.instagram.com/iqos_ro',
            youtube: 'https://www.youtube.com/channel/UCO3xTgEvUw7iwwvL-s81s0w'
        },
        campaigns: {
            launch: '/lansare-test', // example campaign to test out fullwidth layout
            newFeatures: '/noul-iqos-club',
            newFeaturesGamepage: '/noul-iqos-club/joc',
            castingCall: '/arata-ne-cine-esti',
            castingCallGame: '/arata-ne-cine-esti/joc',
            castingCallEnroll: '/arata-ne-cine-esti/inscriere',
            castingCallEntries: '/arata-ne-cine-esti/inscrieri',
            castingCallEntry: '/arata-ne-cine-esti/inscrieri/:entry_id',
            castingCallEditProfile: '/arata-ne-cine-esti/editeaza-profil',
            newIqosClubBenefits: '/beneficii-noul-club-iqos',
            quizWithSurprize: '/quiz-cu-surprize',
            ilumaTeasingIndex: '/teasing-concurs-lamp',
            ilumaTeasingIndexNoContest: '/teasing-event-live',
            illumaPrelaunch: '/prelansare-illuma',
            ilumaTeasingLiveStream: '/experienta-revolutionara',
            summerEngagement: '/traieste-vara-la-urmatorul-nivel',
            summerEngagementUGCGallery: '/postari',
            summerEngagementUGCEntry: '/postari/:entry_id',
            ilumaLaunch: '/lansare-iluma',
            ilumaOnePrelaunch: 'prelansare-iluma-one',
            flavourBanQuiz: '/quiz-ban-arome',
            prelaunchTereaFuse: '/prelaunch-terea-fuse',
            prelaunchTereaFuseRedeem: '/prelaunch-terea-fuse/incearca',
            platinum5: '/platinum-5',
            addStory: '/adauga-story',
            callForDreamsTeasing: '/visurile-tale-devin-realitate',
            flavourBan: '/exploratorii-gustului/:goTo?',
            callForDreamsCampaignPage: '/oameni-reali-visuri-reale',
            callForDreamsGallery: '/oameni-reali-visuri-reale/postari',
            callForDreamsEntry: '/oameni-reali-visuri-reale/postari/:entry_id',
            callForDreamsPhaseTwo: '/oameni-reali-visuri-reale/alege-experienta',
            callForDreamsPhaseTwoTop: '/oameni-reali-visuri-reale/topul-preferintelor',
            ilumaStardriftPrelaunch: '/iluma-stardrift',
            callForDreamsAllDreams: '/oameni-reali-visuri-reale/visuri',
            callForDreamsProgress: '/oameni-reali-visuri-reale/visuri/progres',
            callForDreamsPassions: '/oameni-reali-visuri-reale/visuri/pasiuni',
            callForDreamsEnroll: '/oameni-reali-visuri-reale/visuri/inscriere',
            prelaunchLevia: '/fii-primul-care-incearca-noile-levia',
            prelaunchLeviaRedeem: '/fii-primul-care-incearca-noile-levia/incearca',
            realPeopleRealConnectionsCampaignPage: '/oameni-reali-legaturi-reale',
            realConnectionsVotingExperience: '/oameni-reali-legaturi-reale/alege-experienta',
            realConnectionsTopVotedExperiences: '/oameni-reali-legaturi-reale/topul-preferintelor',
            auroraExperience: '/aurora-program',
            liveTicketing: '/togetherx',
            liveTicketingPublic: '/events/:inviteCode?',
            liveTicketingBucharestConfirmation: '/events/confirmare-bucuresti',
            tasteExploration: '/exploreaza-gustul',
            ticketingVoting: '/togetherx/alege-experienta',
            ticketingTopVoted: '/togetherx/topul-preferintelor',
            prelaunchIlumaNeonPurple: '/prelansare-iluma-neon-purple'
        },

        campaignsStandardLayout: {
            leadfamlyQuiz: '/chestionar-iqos-x-rdw',
            summerGame: '/traieste-vara-la-urmatorul-nivel/play/:slug',
            summerCampaignLeadFamlyQuiz: '/alege-experienta',
            brizoParty: '/brizo-party',
            toscanaExperienceFeedback: '/feedback-toscana-experience',
            jazzInTheParkUGC: '/jip-togetherx',
            summerExperienceFeedback: '/feedback-summer-experience',
            goTechWorld: '/go-tech-world',
            callForDreamsGame: 'oameni-reali-visuri-reale/play/:slugGame?/:gameIdx?',
            iqosExperienceContest: '/castiga-o-experienta-iqos',
            iqosExperienceBrunch: '/castiga-un-brunch-fabulos',
            feedbackLevia: '/feedback-levia',
            realPeopleRealConnectionsGame: '/oameni-reali-legaturi-reale/play' // /:slugGame?/:gameIdx?
        },

        // Iluma contest page ( not full width)
        ilumaTeasingPages: {
            quizPage: '/iluma-teasing-quiz',
            quizPagePromotion: 'teasing-device-lamp',
            quizSubmit: '/iluma-teasing-quiz-raspunsuri',
            quizSubmitPromotion: '/iluma-teasing-quiz-raspunsuri-lamp'
        },

        luckyDrawRDW: '/cu-iqos-la-romanian-design-week-2023',
        luckyDrawTiff: '/hai-impreuna-la-tiff',
        luckyDrawSingFest: '/hai-cu-noi-la-sing-fest-2023',
        luckyDrawFlightFestival: '/cu-iqos-la-flight-festival-2023',
        luckyDrawJazz2: '/noi-universuri-muzicale',
        conversionSurvey: '/completeaza-chestionarul/:slug',
        taskforceSurvey: '/completeaza-formularul/:slug',
        pwaPage: '/aplicatia-web-iqos-club',
        ilumaStardrift: '/universul-iqos/inspiratie-inovatie/colectia-de-cani-IQOS-ILUMA-STARDRIFT',

        // /comunitate/descopera-iqos/voteaza-proiectul-preferat
        // Old IQOS Club redirects
        oldRdwArticle: '/comunitate/descopera-iqos/voteaza-proiectul-preferat',
        stardriftSurvey: '/chestionar-stardrift',
        kirunaQuiz: '/chestionar-kiruna'
    },
    constants: {
        dateFormat: 'D MMM YYYY',
        pointsPerPack: 24 // 24 points = 1 pack of HEETS/Fiit
    }
};

export const validationMessages = {
    required: 'Acest câmp este obligatoriu.',
    types: {
        email: 'Adresa de email nu este validă.',

        phone: 'Numărul de telefon nu este valid.',
        phone_only_numbers: 'Numărul de telefon trebuie să conțină doar cifre',
        phone_max_characters: 'Numărul de telefon trebuie să conțină cel mult 10 cifre',

        letters_only: 'Câmpul trebuie să conțină doar litere'
    }
};

declare global {
    interface Window {
        io: any;
        Echo: any;
    }
}

window.io = window.io || {};
window.Echo = window.Echo || {};
