export interface GameDataInterface {
    slug: string;
    hint: string;
    tries: number;
    correctAnswer: string;
    finalMessage: string;
}

export const GameData: GameDataInterface[] = [
    {
        slug: 'fii-pe-faza-si-gaseste-cuvantul',
        hint: 'Procesul prin care se produc peste 6000 de compuși chimici, atunci când vorbim de fumatul traditional.',
        tries: 3,
        correctAnswer: 'arderea',
        finalMessage: `Arderea este principala problemă asociată fumatului, procesul de ardere a tutunului produce peste 6.000 de substanțe nocive. 
        Dintre acestea, autoritățile de sănătate publică au constatat că în jur de 100 sunt legate de bolile asociate fumatului. 
        Printre cele mai cunoscute substanțe nocive sunt monoxidul de carbon, dar și gudronul care este reziduul din fumul produs în urma arderii țigării.`
    },
    {
        slug: 'e-timpul-pentru-un-joc',
        hint: 'Sunt generate în cantități mari în urma procesului de ardere.',
        tries: 3,
        correctAnswer: 'substante nocive',
        finalMessage: `<p>IQOS nu arde tutunul, ci îl încălzește. Prin acest proces se eliberează nicotina și aromele din tutun, dar fără ardere și fără fum. 
        Astfel, IQOS generează, în medie, niveluri cu 95% mai reduse ale unor substanțe nocive în comparație cu țigările.*Printre cele mai cunoscute sunt monoxidul de carbon, dar si gudronul, reziduul din fumul produs în urma arderii țigării.</p>

        <p>De aceea, deși eliberează nicotină, IQOS reprezintă o alegere mai bună decât continuarea fumatului</p>
        <span>*INFORMAȚII IMPORTANTE: Aceasta nu echivalează neapărat cu o reducere a riscului cu 95%. IQOS nu este lipsit de riscuri.</span>

        <span>*„Cu 95% mai puține” reprezintă reduceri medii ale nivelurilor unei game de substanțe nocive (excluzând nicotina), față de fumul de la o țigară de referință (3R4F).</span>
        
        `
    },
    {
        slug:'gaseste-termenul',
        hint:'Reziduul din fumul produs în urma arderii țigării. Nu se regăsește în IQOS.',
        tries: 3,
        correctAnswer: 'gudron',
        finalMessage:`Gudronul este reziduul din fumul produs în urma arderii țigării. IQOS nu produce gudron, pentru că IQOS încălzește tutunul, nu îl arde. Reziduul din aerosolul generat de IQOS este fundamental diferit de gudron și conține niveluri semnificativ mai reduse  de substanțe nocive*. Gudronul este o modalitate de a măsura ce rămâne în urma arderii unei țigări.  Privește filtrul unei țigări după ce ai fumat-o. Culoarea maro indică prezența gudronului.
        Dacă privești filtrul unei rezerve HEETS, după utilizare, nu vei vedea nicio urmă de gudron. Astfel, IQOS este o alegere mai bună față de continuarea fumatului.
        <span><strong>Informații importante</strong>: Absența gudronului nu înseamnă că IQOS este lipsit de riscuri. Acesta eliberează nicotină, care provoacă dependență.</span>
        <span>* Reduceri medii ale nivelurilor unei game de substanțe nocive (excluzând nicotina), comparat cu fumul de la o țigară de referință (3R4F).</span>
        `
    }
];
