export const userTierByStatus = (status: number) => {
    switch (status) {
        case 1:
            return 'Silver';
        case 2:
            return 'Gold';
        case 3:
            return 'Platinum';
        default:
            return 'Silver';
    }
};
