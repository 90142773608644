import styles from './Winter2023X2.module.scss';
import ArticleHeader from '@containers/IQOSUniverse/components/ArticleHeader';
import VimeoPlayer from '@components/VimeoPlayer';

import React, { useState } from 'react';
import ArticleContainer from '@containers/IQOSUniverse/components/ArticleContainer';
import KeenSlider from '@components/KeenSlider';
import { galleryData } from '@containers/IQOSUniverse/articles/Winter2023X2/data';
import { useWindowSize } from '@utils/hooks/windowSize';
import VideoModal from '@components/VideoModal';
import { Image } from 'antd';
import ArticleImage from '@containers/IQOSUniverse/components/ArticleImage';

// Assets
import seenVideoThumbnail from './assets/seenVideoThumbnail.png';

const Winter2023X2 = () => {
    const window = useWindowSize();
    const isMobile = window.width < 650;

    const [toggleVideoModal, setToggleVideoModal] = useState(false);

    return (
        <section className={styles.wrapper}>
            <ArticleHeader />
            <div className="content">
                <p>
                    IQOS a deschis sezonul de iarnă, în 19 ianuarie, la{' '}
                    <strong>Cabana Yager Chalet</strong>, „epicentrul” distracției pentru amatorii
                    sporturilor de iarnă din Poiana Brașov. Membrii comunității noastre s-au bucurat
                    de un sfârșit de săptămână de excepție, într-un cadru de vis, și au fost
                    încântați să descopere surprizele marca IQOS.
                </p>
            </div>

            <div className={styles.textVideoContainer}>
                <div className={styles.details}>
                    <h3>Momente speciale inspirate de noile sortimente LEVIA</h3>
                    <p>
                        Chef Alexandru Petricean a creat un meniu de fine dining, cu preparate pe
                        bază de plante, inspirat de noile sortimente LEVIA. Sâmbătă, distracția a
                        continuat cu o zi plină, în inima munților, alături de băuturi inspirate de
                        aromele noilor sortimente LEVIA și de mixurile DJ-ilor prezenți la pupitru.
                    </p>
                </div>

                <ArticleImage
                    isVideo
                    mobileImage={seenVideoThumbnail}
                    image={seenVideoThumbnail}
                    onClick={() => setToggleVideoModal(true)}
                    cover
                    fullHeight
                    noMargin
                />

                <VideoModal
                    modalSize='small'
                    toggleModal={toggleVideoModal}
                    setToggleModal={() => setToggleVideoModal(false)}
                    video="912512355"
                />

                {/*<VimeoPlayer className={styles.vimeoPlayer} video={'912512355'} />*/}
            </div>

            <div className={styles.customContainer}>
                {!isMobile && (
                    <>
                        <h3>Galerie Foto</h3>

                        <p>Descoperă weekend-ul memorabil din Poiană în galeria foto!</p>
                    </>
                )}

                <Image.PreviewGroup>
                    <KeenSlider
                        className={styles.prizesSlider}
                        withArrows={true}
                        darkArrows={false}
                        autoSwitch={true}
                        infinite={true}
                        timeoutBetweenSlides={5000}
                        spacing={16}
                        mode={'snap'}
                        perView={1}
                    >
                        {galleryData.map((sl: any) => (
                            <section key={sl.id} className={styles.slide}>
                                <Image src={isMobile ? sl.imgMobile : sl.img} />
                            </section>
                        ))}
                    </KeenSlider>
                </Image.PreviewGroup>
                {isMobile && (
                    <p>
                        Descoperă weekend-ul memorabil din <br /> Poiană în galeria foto!
                    </p>
                )}
            </div>
        </section>
    );
};

export default Winter2023X2;
