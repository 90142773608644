import { useState } from 'react';
import styles from './IqosOriginalsChanges.module.scss';

// Helpers
import classNames from 'classnames';

// Assets
import image1 from './assets/image1.jpg';
import videoThumbnail from './assets/videoThumbnail.jpg';
import videoThumbnail2 from './assets/videThumbnail2.jpg';

// Components
import VideoModal from '@components/VideoModal';
import ArticleImage from '@containers/IQOSUniverse/components/ArticleImage';
import ArticleHeader from '@containers/IQOSUniverse/components/ArticleHeader';
import ArticleContainer from '@containers/IQOSUniverse/components/ArticleContainer';

const IqosOriginalsChanges = () => {
    const [toggleModal, setToggleModal] = useState(false);
    const [toggleModal1, setToggleModal1] = useState(false);

    return (
        <section className={styles.wrapper}>
            <ArticleHeader />

            <div className="content">
                <p>
                    Am sărbătorit schimbările care ne ajută să evoluăm. Vezi cum a fost atmosfera de
                    lansare IQOS ORIGINALS în magazinele noastre și la petrecere!
                </p>
            </div>

            <ArticleContainer background={'#34303D'} textColor={'#F6F4F0'}>
                <div className={classNames(styles.contentOriginals, 'content')}>
                    <ArticleImage
                        isVideo
                        mobileImage={videoThumbnail}
                        image={videoThumbnail}
                        onClick={() => setToggleModal(true)}
                    />

                    <VideoModal
                        toggleModal={toggleModal}
                        setToggleModal={() => setToggleModal(false)}
                        video="783269140"
                    />

                    <h3 className={styles.title}>Un nou capitol în culori vibrante.</h3>

                    <p>
                        Am sărbătorit împreună în magazinele IQOS din toată țara tot ceea ce ne face
                        originali: de la decoruri de sărbătoare inspirate de noua colecție
                        ORIGINALS, la sesiuni de muzică live în tonuri și la instrumente
                        reinterpretate.
                    </p>

                    <p>
                        In plus, în completarea noilor dispozitive, membrii Club au fost întâmpinați
                        cu accesorii-cadou din colecția creată în colaborare cu designerul local
                        AerWear.
                    </p>
                </div>
            </ArticleContainer>

            <ArticleImage cover={true} mobileImage={image1} image={image1} />

            <div className="content">
                <h3>O petrecere cu experiențe unice.</h3>
                <p>
                    Inspirați de IQOS ORIGINALS, am pregătit o petrecere de lansare cu adevărat
                    unică. Cel mai important moment a fost dansul balerinei Merritt Moore alături de
                    un robot industrial, care a îmbinat perfect emoția și arta cu progresul și
                    tehnologia.
                </p>
                <p>
                    Pe lângă asta, ne-am bucurat împreună de muzica lui Allen Husley și evident, am
                    descoperit noile dispozitive IQOS ORIGINALS.
                </p>
            </div>

            <div className={styles.articleWrapper}>
                <ArticleContainer background={'#00D1D2'}>
                    <div className="content">
                        <h3>Play și descoperă cum a fost!</h3>

                        <p>
                            Dacă nu ai reușit să fii alături de noi la live-ul de sămbătă din IQOS
                            Club, urmărește clipul video de mai jos și poți vedea momentele speciale
                            de la eveniment.
                        </p>

                        <ArticleImage
                            isVideo
                            mobileImage={videoThumbnail2}
                            image={videoThumbnail2}
                            onClick={() => setToggleModal1(true)}
                        />

                        <VideoModal
                            toggleModal={toggleModal1}
                            setToggleModal={() => setToggleModal1(false)}
                            video="777656472"
                        />
                    </div>
                </ArticleContainer>
            </div>
        </section>
    );
};

export default IqosOriginalsChanges;
