export const GiftIcon = () => {
    return (
        <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
                stroke="#34303D"
            />
            <path
                d="M11 2.40039C13.2809 2.40039 15.4683 3.30646 17.0811 4.91927C18.6939 6.53208 19.6 8.71953 19.6 11.0004C19.6 13.2813 18.6939 15.4687 17.0811 17.0815C15.4683 18.6943 13.2809 19.6004 11 19.6004V2.40039Z"
                fill="#00D1D2"
            />
            <path
                d="M16.1647 8.10449H5.3489C5.15621 8.10449 5 8.2607 5 8.45339V10.7854C5 10.9781 5.15621 11.1343 5.3489 11.1343H16.1647C16.3574 11.1343 16.5136 10.9781 16.5136 10.7854V8.45339C16.5136 8.2607 16.3574 8.10449 16.1647 8.10449Z"
                stroke="#34303D"
                strokeWidth="0.697794"
            />
            <path
                d="M15.3009 11.1924H6.21218C6.01949 11.1924 5.86328 11.3486 5.86328 11.5413V16.3484C5.86328 16.5411 6.01949 16.6973 6.21218 16.6973H15.3009C15.4936 16.6973 15.6498 16.5411 15.6498 16.3484V11.5413C15.6498 11.3486 15.4936 11.1924 15.3009 11.1924Z"
                fill="#F6F4F0"
                stroke="#34303D"
                strokeWidth="0.697794"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.68614 5.02793C8.86931 4.97769 9.0448 4.99514 9.21297 5.08097C9.65956 5.30914 10.0392 5.92146 10.4013 6.86104C10.5615 7.27623 10.6759 7.64641 10.7387 7.97053L10.7213 7.88784L10.6061 7.8868C9.55943 7.86272 8.45587 7.53092 7.86902 6.99153L7.80343 6.92872C7.60909 6.73125 7.48593 6.50656 7.51105 6.25396C7.53582 6.00345 7.69562 5.74387 7.98834 5.46894C8.23083 5.24111 8.4611 5.08969 8.68614 5.02793ZM12.2069 5.08097C12.3747 4.99479 12.5516 4.97804 12.7372 5.02828C12.9668 5.09039 13.2033 5.24285 13.4538 5.47208C13.7539 5.74666 13.9196 6.00589 13.9482 6.25605C13.9768 6.50656 13.8557 6.72916 13.6642 6.92489C13.0763 7.52464 11.8513 7.88784 10.7265 7.88784L10.7537 7.88749L10.7589 7.86063C10.8106 7.6 10.894 7.31077 11.0049 6.99327L11.0545 6.85546C11.3971 5.91937 11.7641 5.3081 12.2069 5.08097Z"
                fill="#4C4D67"
                stroke="#34303D"
                strokeWidth="0.697794"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.2324 10.3176C11.6838 10.1586 12.1531 10.2308 12.5232 10.4625C12.8929 10.6942 13.1628 11.0847 13.2171 11.5606C13.2705 12.0293 13.1374 12.4433 12.8085 12.7773C12.6074 12.9811 12.3498 13.2405 12.0354 13.5556L11.5482 14.0427C11.4124 14.1783 11.2691 14.3214 11.1176 14.4719L10.7748 14.8134L10.4289 14.4738C10.2449 14.2923 10.0878 14.1369 9.95739 14.0065L9.86491 13.914C9.80595 13.8551 9.75359 13.8025 9.70832 13.7562L9.55002 13.5957C9.27673 13.3185 9.00319 13.0415 8.73601 12.7582C8.35041 12.3501 8.22514 11.8591 8.37757 11.3178C8.45537 11.0419 8.59092 10.8187 8.77222 10.6455C8.95376 10.4723 9.18326 10.3475 9.46219 10.2822C9.9317 10.1721 10.356 10.2712 10.7232 10.5606L10.7467 10.5802L10.8025 10.5415C10.9233 10.4598 11.0379 10.3952 11.159 10.3455L11.2324 10.3176Z"
                fill="#4C4D67"
                stroke="#4C4D67"
                strokeWidth="0.523346"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.7688 14.1261C10.7772 14.1197 10.7818 14.117 10.7855 14.1133C11.3441 13.5543 11.9053 12.9976 12.4602 12.4344C12.6814 12.2098 12.767 11.9306 12.7313 11.6165C12.6579 10.9757 12.0027 10.5661 11.3952 10.7797C11.241 10.834 11.1089 10.9214 10.9939 11.0361L10.7573 11.2739C10.6675 11.1827 10.5861 11.0924 10.4965 11.0104C10.233 10.7694 9.92277 10.6774 9.57461 10.7594C9.20149 10.8468 8.95315 11.0821 8.84892 11.4513C8.74616 11.8166 8.83204 12.1477 9.09212 12.4227C9.40848 12.7579 9.73389 13.085 10.0573 13.4136C10.2922 13.6519 10.53 13.8873 10.7688 14.1261Z"
                stroke="#FFFDFB"
                strokeWidth="0.5"
            />
        </svg>
    );
};
