import { useEffect, useState } from 'react';
import styles from './MotivationPuzzleGame.module.scss';

// Helpers
import { gameData } from '@containers/IQOSUniverse/articles/MotivationPuzzleGame/data';
import classnames from 'classnames';

// Components
import Button from '@components/Layout/Button';
import Puzzle from '@components/Games/Puzzle';

// Redux
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { useWindowSize } from '@utils/hooks/windowSize';
import {
    getGame,
    postArticlesActionCTR,
    postGameActionCTR,
    resetGame
} from '@containers/IQOSUniverse/IQOSUniverseSlice';
import BaseLoader from '@components/Layout/BaseLoader';

const MotivationPuzzleGame = () => {
    const dispatch = useAppDispatch();
    const { article, articleGame, status } = useAppSelector((state) => state.iqosUniverse);

    const [showGame, setShowGame] = useState(false);
    const [success, setSuccess] = useState<boolean | null>(null);

    const windowSize = useWindowSize();
    const isMobile = windowSize.width < 850;

    useEffect(() => {
        if (!articleGame) {
            dispatch(getGame(article.cta_link || ' '));
        }

        return () => {
            dispatch(resetGame());
        };
    }, [articleGame?.activity_campaign_id]);

    const onLose = () => {
        dispatch(postGameActionCTR({ gameId: articleGame?.id || 0, winner: 0 }));
        setSuccess(false);
        setShowGame(false);
    };

    const onSuccess = () => {
        setTimeout(() => {
            dispatch(postGameActionCTR({ gameId: articleGame?.id || 0, winner: 1 }));
            setSuccess(true);
            setShowGame(false);
        }, 3000);
    };

    const startGame = () => {
        if (articleGame) {
            dispatch(postArticlesActionCTR(`user_motivation_game_${articleGame.id}`));
        }

        setShowGame(true);
    };

    if (status === 'loading') {
        return <BaseLoader size={'normal'} />;
    }

    return (
        <section className={styles.wrapper}>
            {showGame ? (
                <div className={styles.gameContainer} style={{ touchAction: 'none' }}>
                    <Puzzle
                        image={
                            isMobile
                                ? gameData.filter((game: any) => game.slug === article.cta_link)[0]
                                      .puzzleImgMobile
                                : gameData.filter((game: any) => game.slug === article.cta_link)[0]
                                      .puzzleImg
                        }
                        time={3 * 30 * 1000}
                        rowsAndColumns={[3, 3]}
                        onWin={onSuccess}
                        onLose={onLose}
                    />
                </div>
            ) : (
                <div className={styles.gameContainer}>
                    <img
                        src={
                            gameData.filter((game: any) => game.slug === article.cta_link)[0].header
                        }
                        alt={'headerImg'}
                    />
                    <h3 className={classnames(success === false ? styles.red : styles.turquoise)}>
                        {success === null
                            ? 'Rezolvă un puzzle!'
                            : success
                            ? gameData.filter((game: any) => game.slug === article.cta_link)[0]
                                  .successTitle
                            : 'Din păcate, timpul s-a terminat'}
                    </h3>
                    <p
                        dangerouslySetInnerHTML={{
                            __html:
                                success === null
                                    ? gameData.filter(
                                          (game: any) => game.slug === article.cta_link
                                      )[0].description
                                    : success
                                    ? gameData.filter(
                                          (game: any) => game.slug === article.cta_link
                                      )[0].successText
                                    : gameData.filter(
                                          (game: any) => game.slug === article.cta_link
                                      )[0].failText
                        }}
                    />

                    {!success && (
                        <Button
                            type={'solid'}
                            label={success === null ? 'ÎNCEPE' : 'Încearcă din nou'}
                            color={'dark'}
                            hover={'turquoise'}
                            onClick={startGame}
                        />
                    )}
                </div>
            )}
        </section>
    );
};

export default MotivationPuzzleGame;
