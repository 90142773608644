export const CheckedLight = () => {
    return (
        <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect y="-0.000305176" width="12.0003" height="12.0006" rx="6.00015" fill="#F6F4F0" />
            <path
                d="M2.51862 5.64503C2.63815 5.64009 2.73444 5.70514 2.82566 5.78578C3.42686 6.31662 4.02933 6.84622 4.63078 7.37706C4.70912 7.44632 4.791 7.50643 4.90042 7.51707C5.05003 7.53166 5.16931 7.47823 5.26913 7.36964C5.91 6.67282 6.55189 5.97675 7.19352 5.28067C7.75505 4.67142 8.31607 4.06217 8.87809 3.45366C9.0507 3.26665 9.29709 3.24884 9.47222 3.40715C9.64204 3.56052 9.6433 3.80095 9.47373 3.98524C8.07776 5.50206 6.68153 7.01888 5.28505 8.5357C5.09274 8.74448 4.85696 8.75511 4.64973 8.5644C3.85117 7.82874 3.05336 7.09235 2.25429 6.35744C2.13829 6.25058 2.07764 6.12788 2.10771 5.96982C2.14436 5.77787 2.30786 5.64503 2.51837 5.64528L2.51862 5.64503Z"
                fill="#00D1D2"
            />
            <rect
                x="0.150004"
                y="0.149699"
                width="11.7003"
                height="11.7006"
                rx="5.85015"
                stroke="#FFFDFB"
                strokeOpacity="0.8"
                strokeWidth="0.300008"
            />
        </svg>
    );
};
