import React, { Children, FC, isValidElement, useEffect, useState } from 'react';
import { IRegion } from './interface';
import styles from './Region.module.scss';

// Utils
import classnames from 'classnames';
import useSound from 'use-sound';

// Components
import { Progress } from 'antd';

// DND Kit
import { useDroppable } from '@dnd-kit/core';

// Assets
import pause from './assets/pause.png';
import play from './assets/play.png';

const Region: FC<IRegion> = ({
    region,
    id,
    children,
    playing,
    soundName,
    cardsType,
    setIsPlaying = () => {
        return;
    }
}) => {
    const { isOver, setNodeRef } = useDroppable({ id });
    const { image, imageGuessed, imageNotGuessed, text, sound = '', notGuessed = false } = region;

    const [progress, setProgress] = useState(0);

    const hasChildren =
        Children.toArray(children).filter((child) => isValidElement(child)).length > 0;

    const style = {
        backgroundImage: notGuessed
            ? `url(${imageNotGuessed})`
            : hasChildren
            ? `url(${imageGuessed})`
            : `url(${image})`,
        outline: notGuessed ? 'none' : isOver ? '2px solid #00d1d2' : 'none'
    };

    const [playMusic, selectedSound] = useSound(sound ? sound : '', {
        onplay: () => setIsPlaying(true),
        onend: () => setIsPlaying(false),
        interrupt: true,
        html5: true
    });

    useEffect(() => {
        let intervalTimer: any = null;

        if (sound) {
            if (soundName === region.soundName) {
                if (hasChildren) {
                    selectedSound.stop();
                    clearInterval(intervalTimer);
                    setProgress(0);
                }

                if (selectedSound?.duration) {
                    if (playing) {
                        intervalTimer = setInterval(() => {
                            setProgress(
                                (selectedSound.sound.seek() / selectedSound.sound.duration()) * 100
                            );
                        }, 300);
                    } else {
                        clearInterval(intervalTimer);
                        setProgress(0);
                    }
                }
            } else {
                selectedSound.stop();
                clearInterval(intervalTimer);
                setProgress(0);
            }
        }

        return () => {
            if (intervalTimer) {
                clearInterval(intervalTimer);
                setProgress(0);
            }
        };
    }, [playing, hasChildren, soundName]);

    return (
        <div
            ref={setNodeRef}
            className={classnames(cardsType === 'square' ? styles.square : styles.container)}
        >
            <div className={styles.image} style={style}>
                {sound && !hasChildren && !notGuessed && (
                    <div className={styles.pack}>
                        {!playing ? (
                            <img
                                src={play}
                                onClick={() => {
                                    playMusic();
                                }}
                            />
                        ) : (
                            <div
                                onClick={() => {
                                    selectedSound.stop();
                                    setIsPlaying(false);
                                }}
                            >
                                <Progress
                                    size={80}
                                    strokeWidth={10}
                                    strokeColor={'#00D1D2'}
                                    trailColor={'transparent'}
                                    percent={progress}
                                    format={(number) => <img src={pause} />}
                                    type="circle"
                                    className={styles.intensity}
                                />
                            </div>
                        )}
                    </div>
                )}
                {children}
            </div>
            <span>{text}</span>
        </div>
    );
};

export default Region;
