export const IQOSro = () => {
    return (
        <svg
            width="23"
            height="22"
            viewBox="0 0 23 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g>
                <path
                    fill="none"
                    stroke="#C4C3C6"
                    strokeWidth="1.71429"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4.6,5.7h14.8c0.2,0,0.4,0.2,0.4,0.4V18c0,1.9-1.5,3.4-3.4,3.4H7.5c-1.9,0-3.4-1.5-3.4-3.4V6.2C4.2,5.9,4.3,5.7,4.6,5.7z"
                />
                <path
                    fill="none"
                    stroke="#C4C3C6"
                    strokeWidth="1.71429"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M8.6,8.5V4.7c0-1.2,0.9-2.1,2.1-2.1h2.5c1.2,0,2.1,0.9,2.1,2.1v3.8l0,0"
                />
            </g>
        </svg>
    );
};
