export const HomeIcon = () => {
    return (
        <svg
            width="20"
            height="22"
            viewBox="0 0 20 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M19 10.9057C19.0013 10.6876 18.9625 10.4716 18.8863 10.2714C18.81 10.0712 18.6978 9.89116 18.5569 9.74285L9.99999 0.785706L1.4431 9.74285C1.30218 9.89116 1.19001 10.0712 1.11373 10.2714C1.03745 10.4716 0.998724 10.6876 1.00002 10.9057V19.6428C1.00002 20.0596 1.1459 20.4593 1.40557 20.754C1.66523 21.0487 2.01741 21.2143 2.38463 21.2143H17.6154C17.9826 21.2143 18.3348 21.0487 18.5944 20.754C18.8541 20.4593 19 20.0596 19 19.6428V10.9057Z"
                stroke="#C4C3C6"
                strokeWidth="1.71429"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
