import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

//Styles
import styles from './RdwVoting.module.scss';

//Components
import ArticleHeader from '@containers/IQOSUniverse/components/ArticleHeader';
import ProjectCard from './components/ProjectCard';
import Button from '@components/Layout/Button';

//Helpers
import { getQuiz } from '@components/Quiz/QuizSlice';
import { useAppDispatch } from '@app/hooks';
import projectsInfo from './projects';

const RdwVoting = () => {
    const dispatch = useAppDispatch();
    const { article_slug } = useParams();

    useEffect(() => {
        dispatch(getQuiz({ slug: article_slug || '', statistics: true }));
    }, [article_slug]);

    return (
        <section className={styles.rdwWrapper}>
            <ArticleHeader />

            <div className="content">
                <h3>Finaliștii RE-CONNECT sunt aici.</h3>

                <p>
                    Conexiunile dintre noi și felul în care suntem împreună o comunitate puternică
                    au inspirat artiștii aflați la început de drum să propună instalații imersive
                    sub tema ”A celebration of togetherness”.
                </p>

                <p>
                    Juriul RE-CONNECT a ales cele trei proiecte finaliste, înscrise în platforma
                    partedinarte.ro, iar acum decizia finală revine comunității. Adică vouă. Pentru
                    că voi sunteți parte din arte zi de zi și aveți puterea să alegeți acel proiect
                    care ne va aduce cu adevărat împreună.
                </p>

                <p>
                    Descoperă finaliștii și oferă votul tău celui care crezi că a reușit să
                    surprindă cel mai bine modul în care o comunitate se poate inspira să fie mai
                    puternică și mai unită.
                </p>

                <p>
                    Proiectul cu cele mai multe voturi va fi desemnat câștigător, iar instalația va
                    fi produsă și expusă la Romanian Design Week.
                </p>

                <div className={styles.rewardMessage}>
                    <h3>
                        <b>
                            Votează proiectul care te inspiră până pe 13 aprilie pe{' '}
                            <Button
                                type="text"
                                color="dark"
                                hover="turquoise"
                                href={'https://partedinarte.ro/finalisti'}
                                label="partedinarte.ro"
                            />
                            !
                        </b>
                    </h3>
                </div>
            </div>

            <div className={styles.rdwCards}>
                {projectsInfo.map((project) => (
                    <ProjectCard project={project} key={project.id} />
                ))}
            </div>
        </section>
    );
};

export default RdwVoting;
