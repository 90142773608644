export const DashboardIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="18"
            viewBox="0 0 17 18"
            fill="none"
        >
            <path
                d="M4.32311 0.75H2.75557C0.948774 0.75 0 1.69877 0 3.49732V5.06486C0 6.8634 0.948774 7.81218 2.74732 7.81218H4.31486C6.1134 7.81218 7.06218 6.8634 7.06218 5.06486V3.49732C7.07043 1.69877 6.12165 0.75 4.32311 0.75Z"
                fill="#00d1d2"
            />
            <path
                d="M13.7524 0.75H12.1848C10.3863 0.75 9.4375 1.69877 9.4375 3.49732V5.06486C9.4375 6.8634 10.3863 7.81218 12.1848 7.81218H13.7524C15.5509 7.81218 16.4997 6.8634 16.4997 5.06486V3.49732C16.4997 1.69877 15.5509 0.75 13.7524 0.75Z"
                fill="#00d1d2"
            />
            <path
                d="M13.7524 10.1797H12.1848C10.3863 10.1797 9.4375 11.1285 9.4375 12.927V14.4945C9.4375 16.2931 10.3863 17.2419 12.1848 17.2419H13.7524C15.5509 17.2419 16.4997 16.2931 16.4997 14.4945V12.927C16.4997 11.1285 15.5509 10.1797 13.7524 10.1797Z"
                fill="#00d1d2"
            />
            <path
                d="M4.32311 10.1797H2.75557C0.948774 10.1797 0 11.1285 0 12.927V14.4945C0 16.3013 0.948774 17.2501 2.74732 17.2501H4.31486C6.1134 17.2501 7.06218 16.3013 7.06218 14.5028V12.9353C7.07043 11.1285 6.12165 10.1797 4.32311 10.1797Z"
                fill="#00d1d2"
            />
        </svg>
    );
};
