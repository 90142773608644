export const PWALeaerboardIcon = () => {
    return (
        <svg
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <mask id="path-1-inside-1_223_2611" fill="white">
                <path d="M8 33.6C8 33.0399 8 32.7599 8.10899 32.546C8.20487 32.3578 8.35785 32.2049 8.54601 32.109C8.75992 32 9.03995 32 9.6 32H20V46H9.6C9.03995 46 8.75992 46 8.54601 45.891C8.35785 45.7951 8.20487 45.6422 8.10899 45.454C8 45.2401 8 44.9601 8 44.4V33.6Z" />
            </mask>
            <path
                d="M8 33.6C8 33.0399 8 32.7599 8.10899 32.546C8.20487 32.3578 8.35785 32.2049 8.54601 32.109C8.75992 32 9.03995 32 9.6 32H20V46H9.6C9.03995 46 8.75992 46 8.54601 45.891C8.35785 45.7951 8.20487 45.6422 8.10899 45.454C8 45.2401 8 44.9601 8 44.4V33.6Z"
                fill="#00D1D2"
                stroke="white"
                strokeWidth="3.42"
                mask="url(#path-1-inside-1_223_2611)"
            />
            <mask id="path-2-inside-2_223_2611" fill="white">
                <path d="M22 27.6C22 27.0399 22 26.7599 22.109 26.546C22.2049 26.3578 22.3578 26.2049 22.546 26.109C22.7599 26 23.0399 26 23.6 26H32.4C32.9601 26 33.2401 26 33.454 26.109C33.6422 26.2049 33.7951 26.3578 33.891 26.546C34 26.7599 34 27.0399 34 27.6V46H22V27.6Z" />
            </mask>
            <path
                d="M22 27.6C22 27.0399 22 26.7599 22.109 26.546C22.2049 26.3578 22.3578 26.2049 22.546 26.109C22.7599 26 23.0399 26 23.6 26H32.4C32.9601 26 33.2401 26 33.454 26.109C33.6422 26.2049 33.7951 26.3578 33.891 26.546C34 26.7599 34 27.0399 34 27.6V46H22V27.6Z"
                fill="#00D1D2"
                stroke="white"
                strokeWidth="3.42"
                mask="url(#path-2-inside-2_223_2611)"
            />
            <mask id="path-3-inside-3_223_2611" fill="white">
                <path d="M36 36H46.4C46.9601 36 47.2401 36 47.454 36.109C47.6422 36.2049 47.7951 36.3578 47.891 36.546C48 36.7599 48 37.0399 48 37.6V44.4C48 44.9601 48 45.2401 47.891 45.454C47.7951 45.6422 47.6422 45.7951 47.454 45.891C47.2401 46 46.9601 46 46.4 46H36V36Z" />
            </mask>
            <path
                d="M36 36H46.4C46.9601 36 47.2401 36 47.454 36.109C47.6422 36.2049 47.7951 36.3578 47.891 36.546C48 36.7599 48 37.0399 48 37.6V44.4C48 44.9601 48 45.2401 47.891 45.454C47.7951 45.6422 47.6422 45.7951 47.454 45.891C47.2401 46 46.9601 46 46.4 46H36V36Z"
                fill="#00D1D2"
                stroke="white"
                strokeWidth="3.42"
                mask="url(#path-3-inside-3_223_2611)"
            />
            <path
                d="M27.7964 17.8829C27.9236 17.8178 28.0764 17.8178 28.2036 17.8829L32.3311 20L31.5445 15.5259C31.5191 15.3818 31.5675 15.2352 31.6744 15.1344L35 11.9715L30.3978 11.3185C30.254 11.2993 30.1305 11.21 30.0644 11.0812L28 7L25.9356 11.0812C25.8707 11.21 25.746 11.298 25.6022 11.3185L21 11.9715L24.3256 15.1344C24.4325 15.2364 24.4809 15.3818 24.4555 15.5259L23.6689 20L27.7964 17.8829Z"
                stroke="white"
                strokeWidth="1.71"
                strokeLinejoin="round"
            />
        </svg>
    );
};
