import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit';

import axiosRequest from '@utils/axios';
import { removeDuplicates } from '@utils/removeDuplicates';

export interface ICategory {
    id: number;
    name: string;
    slug: string;
    articles: any[];
    meta: {
        last_page: number;
        total: number;
    };
}

export interface IQOSUniverseState {
    status: 'idle' | 'loading' | 'failed';
    categories: {
        [key: string]: ICategory;
    };
    article: {
        id: number | null;
        quiz_id: string | null;
        title: string;
        subtitle: string;
        slug: string;
        quiz_slug: string | null;
        quiz_points: number | null;
        quiz_end_date: string | null;
        picture: string;
        picture_mobile: string;
        liked: boolean;
        points: number | null;
        component: string;
        episode: string;
        video_link: string;
        video_position: string | null;
        completed_quiz: boolean;
        is_completed: boolean;
        display_badge_icon: boolean;
        seen: boolean;
        hasActivity: boolean;
        cta_link: string | null;
        badges: Array<string>;
    };
    relatedArticles: any[];
    articleGame: {
        activity_campaign_id: number;
        created_at: string;
        deleted_at: string | null;
        end_date: string;
        id: number;
        name: string;
        properties: any;
        slug: string;
        start_date: string;
        updated_at: string;
    } | null;
    error: any;
}

const initialState: IQOSUniverseState = {
    status: 'idle',
    categories: {
        slug: {
            id: 0,
            name: '',
            slug: '',
            articles: [],
            meta: {
                last_page: 0,
                total: 0
            }
        }
    },
    article: {
        id: null,
        quiz_id: '',
        title: '',
        subtitle: '',
        slug: '',
        picture: '',
        picture_mobile: '',
        liked: false,
        quiz_slug: null,
        quiz_points: null,
        quiz_end_date: null,
        points: null,
        component: '',
        video_link: '',
        video_position: null,
        episode: '',
        completed_quiz: false,
        is_completed: false,
        display_badge_icon: false,
        seen: false,
        hasActivity: false,
        cta_link: '',
        badges: []
    },
    relatedArticles: [],
    articleGame: null,
    error: {}
};

interface AxiosError {
    response: {
        data: {
            message: string;
        };
    };
}

export const getCategories = createAsyncThunk('iqos-universe/categories', async () => {
    try {
        const response = await axiosRequest.get(`/category-articles`);
        return response.data.data.reduce(
            (acc: IQOSUniverseState['categories'], category: ICategory) => {
                return {
                    ...acc,
                    [category.slug]: category
                };
            },
            {}
        );
    } catch (e) {
        return (e as AxiosError).response.data.message;
    }
});

export const getCategoryArticles = createAsyncThunk(
    'iqos-universe/category-articles',
    async ({
        slug,
        page = 1,
        per_page = 6,
        offset = 5,
        iqosAnswersSeason
    }: {
        slug: string;
        page: number;
        per_page: number;
        offset: number;
        iqosAnswersSeason?: number;
    }) => {
        try {
            let response;

            if (iqosAnswersSeason) {
                response = await axiosRequest.get(
                    `/category-articles/${slug}?page=${page}&per_page=${per_page}&offset=${offset}&season=${iqosAnswersSeason}`
                );
            } else {
                response = await axiosRequest.get(
                    `/category-articles/${slug}?page=${page}&per_page=${per_page}&offset=${offset}`
                );
            }

            return {
                slug: slug,
                data: response.data
            };
        } catch (e) {
            return (e as AxiosError).response.data.message;
        }
    }
);

export const getArticle = createAsyncThunk(
    'iqos-universe/get-article',
    async ({ slug }: { slug: string }) => {
        try {
            const response = await axiosRequest.get(`/articles/${slug}`);
            return response.data;
        } catch (e) {
            return (e as AxiosError).response.data.message;
        }
    }
);

export const likeArticle = createAsyncThunk(
    'iqos-universe/like-article',
    async ({ slug }: { slug: string }) => {
        try {
            const response = await axiosRequest.get(`/articles/like/${slug}`);
            return response.data;
        } catch (e) {
            return (e as AxiosError).response.data.message;
        }
    }
);

export const getRelatedArticles = createAsyncThunk(
    'iqos-universe/get-related-articles',
    async ({ slug, limit }: { slug: string; limit: number }) => {
        try {
            const response = await axiosRequest.get(`/related-articles/${slug}?limit=${limit}`);
            return response.data;
        } catch (e) {
            return (e as AxiosError).response.data.message;
        }
    }
);

export const markArticleAsRead = createAsyncThunk(
    'iqos-universe/mark-article-as-read',
    async ({ slug }: { slug: string }) => {
        try {
            const response = await axiosRequest.get(`/articles/seen/${slug}`);
            return response.data;
        } catch (e) {
            return (e as AxiosError).response.data.message;
        }
    }
);

// CTR Tracking on start Game
export const postArticlesActionCTR = createAsyncThunk(
    'iqos-universe/ctrAction',
    async (actionUuid: string, { rejectWithValue }) => {
        try {
            const response = await axiosRequest.post('/user/ctr-action', {
                action_uuid: actionUuid
            });
            return response.data;
        } catch (e) {
            return rejectWithValue((e as AxiosError).response.data.message);
        }
    }
);
// Game tracking
export const postGameActionCTR = createAsyncThunk(
    'iqos-universe/gameCtrAction',
    async ({ gameId, winner }: { gameId: number; winner: number }, { rejectWithValue }) => {
        try {
            const response = await axiosRequest.post('/user/trivia-game', {
                game_id: gameId,
                winner: winner
            });
            return response.data;
        } catch (e) {
            return rejectWithValue((e as AxiosError).response.data.message);
        }
    }
);
// Get Game
export const getGame = createAsyncThunk(
    'iqos-universe/getGame',
    async (gameSlug: string, { rejectWithValue }) => {
        try {
            const response = await axiosRequest.get(`/user/trivia-game/${gameSlug}`);
            return response.data;
        } catch (e) {
            return rejectWithValue((e as AxiosError).response.data.message);
        }
    }
);

export const IQOSUniverseSlice = createSlice({
    name: 'IQOSUniverse',
    initialState,
    reducers: {
        resetArticle: () => initialState,

        resetGame: (state) => {
            state.articleGame = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCategories.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getCategories.fulfilled, (state, action) => {
                state.status = 'idle';
                state.categories = action.payload;
            })
            .addCase(getCategories.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })

            // Category articles
            .addCase(getCategoryArticles.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(
                getCategoryArticles.fulfilled,
                (state, action: PayloadAction<string | { slug: string; data: any }>) => {
                    const { slug, data } = action.payload as { slug: string; data: any };

                    state.status = 'idle';

                    if (state.categories[slug]) {
                        state.categories[slug].meta = data?.meta;
                        state.categories[slug].articles = removeDuplicates([
                            ...(current(state).categories?.[slug]?.articles || []),
                            ...data?.data
                        ]);
                    }
                }
            )
            .addCase(getCategoryArticles.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })

            // Articles
            .addCase(getArticle.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getArticle.fulfilled, (state, action) => {
                state.status = 'idle';
                state.article = action.payload.data;
            })
            .addCase(getArticle.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })

            // Get related articles
            .addCase(getRelatedArticles.fulfilled, (state, action) => {
                state.status = 'idle';
                state.relatedArticles = action.payload.data;
            })
            .addCase(getRelatedArticles.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })

            // Mark article as read
            .addCase(markArticleAsRead.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })

            // CTR ACTIONS
            .addCase(postArticlesActionCTR.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            // GAME ACTIONS
            .addCase(postGameActionCTR.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            // Get Game
            .addCase(getGame.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getGame.fulfilled, (state, action) => {
                state.status = 'idle';
                state.articleGame = action.payload;
            })
            .addCase(getGame.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    }
});

export const { resetArticle, resetGame } = IQOSUniverseSlice.actions;
export default IQOSUniverseSlice.reducer;
