import styles from './IqosSuperBrand.module.scss';

// Helpers
import { Change, ChangeList } from './lists/ChangeList';

// Assets
import { Heart } from '@assets/icons';
import img1 from './assets/superBrand1.png';
import image1Desktop from './assets/image1Desktop.png';

// Components
import ArticleImage from '@containers/IQOSUniverse/components/ArticleImage';
import ArticleHeader from '@containers/IQOSUniverse/components/ArticleHeader';
import ArticleContainer from '@containers/IQOSUniverse/components/ArticleContainer';

const IqosSuperBrand = () => {
    return (
        <section className={styles.wrapper}>
            <ArticleHeader />

            <div className="content">
                <h3>IQOS a primit distincția de Superbrand</h3>

                <p>
                    Este prima dată când primim această distincție și suntem tare bucuroși de asta.
                </p>

                <p>
                    Distincția Superbrands, oferită de organizația cu același nume, este o
                    confirmare a susținerii pe care o primim de la voi, utilizatorii IQOS, și o
                    inspirație să facem lucrurile și mai bine în continuare.
                </p>
            </div>

            <ArticleContainer background={'#F6F4F0'}>
                <div className="content">
                    <h3>Cine poate deveni un Superbrand</h3>

                    <p>
                        Pentru a face parte din lista brandurilor cu statusul de Superbrand este
                        necesară parcurgerea unui proces de evaluare de către Consiliul Superbrands,
                        alcătuit din aproximativ 30 de specialiști din diferite sectoare (marketing,
                        management, comunicare, branding), care a evaluat 1.857 de branduri din 65
                        de categorii. În cea de-a doua etapă, din 1.857 de branduri au rămas 1.039,
                        iar acestea au fost incluse în cercetarea de piață*, realizată de către
                        Superbrands România și Ipsos. În cadrul cercetării de piață, 1.500 de
                        consumatori români au evaluat aceste 1.039 de branduri, pe baza unor
                        criterii cum ar fi încredere, calitate și diferențierea lor pe piața din
                        România.
                    </p>

                    <p>
                        La finalul acestui proces, IQOS este unul dintre cele 50 branduri din
                        România care a primit distincția de Superbrand.
                    </p>
                </div>
            </ArticleContainer>

            <ArticleContainer background={'#00D1D2'}>
                <div className="content">
                    <h3>Suntem tare încântați că am devenit un SUPERBRAND*</h3>

                    <p>
                        Acum, pentru că am primit această distincție și pentru că ne-am urcat pe
                        „scenă”, e timpul pentru mulțumiri.
                    </p>

                    <ArticleImage image={image1Desktop} mobileImage={img1} />
                </div>
            </ArticleContainer>

            <ArticleContainer background={'#F6F4F0'}>
                <div className="content">
                    <h3>Vă mulțumim vouă, pentru că ați vrut o schimbare!</h3>

                    {ChangeList.map((l: Change) => {
                        return (
                            <div key={l.id} className={styles.iconList}>
                                <div>
                                    <Heart />
                                </div>
                                <p>{l.description}</p>
                            </div>
                        );
                    })}
                </div>
            </ArticleContainer>

            <ArticleContainer background={'#34303D'} textColor={'#F6F4F0'}>
                <div className="content">
                    <p className={styles.white}>
                        *Distincția Superbrands, acordată brandului IQOS, conform studiului de piață
                        derulat de IPSOS în perioada februarie-aprilie 2021, în cadrul programului
                        Superbrands pentru România. Interviurile au fost organizate de Ipsos și s-au
                        desfășurat în mediul online, consumatorii care au participat sunt din mediul
                        urban, iar vârstele acestora variau intre 18 și 54 de ani.
                    </p>
                </div>
            </ArticleContainer>
        </section>
    );
};

export default IqosSuperBrand;
