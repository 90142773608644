import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axiosRequest from '@utils/axios';

export interface RegulationsState {
    status: 'idle' | 'loading' | 'failed';
    regulations: Array<{
        categories: [];
        description: string;
        id: number;
        status: string;
        title: string;
    }>;

    promotion: {
        title: string;
        description: string;
    };

    error: any;
}

interface AxiosError {
    response: {
        data: {
            message: string;
        };
    };
}

const initialState: RegulationsState = {
    status: 'idle',
    regulations: [],
    promotion: {
        title: '',
        description: '',
    },
    error: {}
};

export const getRegulations = createAsyncThunk('containers/Regulations', async () => {
    try {
        const response = await axiosRequest.get('/regulations').then((res) => {
            return res;
        });
        return response.data.data;
    } catch (e) {
        return (e as AxiosError).response.data.message;
    }
});

export const getPromotion = createAsyncThunk('containers/Regulations/Promotion', async (values: {
    id: string;
}) => {
    try {
        const response = await axiosRequest.get(`/regulations/promotions/${values.id}`).then((res) => {
            return res;
        });
        return response.data.data;
    } catch (e) {
        return (e as AxiosError).response.data.message;
    }
});

export const regulationsSlice = createSlice({
    name: 'regulations',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getRegulations.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getRegulations.fulfilled, (state, action) => {
                state.status = 'idle';
                state.regulations = action.payload;
            })
            .addCase(getRegulations.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(getPromotion.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getPromotion.fulfilled, (state, action) => {
                state.status = 'idle';
                state.promotion = action.payload;
            })
            .addCase(getPromotion.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    }
});

export default regulationsSlice.reducer;
