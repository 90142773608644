export const ButtonStarIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="22"
            viewBox="0 0 21 22"
            fill="none"
        >
            <rect
                x="0.214355"
                y="0.713867"
                width="20.5714"
                height="20.5714"
                rx="10.2857"
                fill="#FFFDFB"
            />
            <path
                d="M10.4995 6L12.0449 9.29208L15.5 9.81871L13 12.3814L13.5902 16L10.5 14.2914L7.40977 16L8 12.3814L5.5 9.81871L8.95511 9.29073L10.4995 6Z"
                stroke="#34303D"
                stroke-width="0.956522"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};
