import React, { useState } from 'react';
import styles from './KirunaWrapup.module.scss';

// Helpers
import classnames from 'classnames';
import { config } from '@utils/config';
// Components
import ArticleHeader from '@containers/IQOSUniverse/components/ArticleHeader';
import ArticleContainer from '@containers/IQOSUniverse/components/ArticleContainer';
import ArticleImage from '@containers/IQOSUniverse/components/ArticleImage';
import { Image } from 'antd';
import VideoModal from '@components/VideoModal';
import KeenSlider from '@components/KeenSlider';
import VideoModalQuiz from '@containers/IQOSUniverse/components/VideoModalQuiz';
import Button from '@components/Layout/Button';
import CompletedStateArticle from '@containers/IQOSUniverse/components/CompletedStateArticle/CompletedStateArticle';

// Assets
import contestImage from './assets/big-prize.gif';

import {
    IqosKirunaImgSliderData,
    IqosKirunaVideoSliderData
} from '@containers/IQOSUniverse/articles/KirunaWrapup/data';

// Redux
import { useAppSelector } from '@app/hooks';

const KirunaWrapup = () => {
    const { article } = useAppSelector((state) => state.iqosUniverse);

    const [toggleModal, setToggleModal] = useState(false);
    const [currentVideo, setCurrentVideo] = useState('');

    const [toggleModalTop, setToggleModalTop] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);

    const handleShowModal = (videoId: string) => {
        setCurrentVideo(videoId);
        setToggleModal(true);
    };

    const handleCloseModal = () => {
        setCurrentVideo('');
        setToggleModal(false);
    };

    return (
        <section className={styles.wrapper}>
            <ArticleHeader
                videoQuiz={() => {
                    setToggleModalTop(true);
                }}
            />

            <VideoModalQuiz
                toggleModal={toggleModalTop}
                setToggleModal={() => setToggleModalTop(false)}
                setShowSuccess={() => {
                    setShowSuccess(true);
                }}
                lockScroll={false}
            />

            <div className="content">
                <h3>Cum a fost în IQOS Aurora Experience?</h3>
                <p>
                    Câștigătorii campaniei „Oameni reali. Visuri reale.” s-au întors din experiența
                    marca IQOS Together X cu o nouă colecție de amintiri unice! Participarea lor în
                    campanie i-a trimis în Kiruna, Suedia, locul în care stelele ating pământul.
                </p>
                <p>
                    Timp de aproape o săptămână, câștigătorii din IQOS CLUB au explorat tărâmul
                    lapon și au făcut cunoștință cu tradițiile acestei regiuni de la granița magiei.
                </p>
            </div>

            <ArticleContainer background={'#00D1D2'}>
                <div className={classnames('content', styles.sliderWrapper)}>
                    <KeenSlider
                        withArrows={true}
                        darkArrows
                        perView={1}
                        mode={'snap'}
                        origin={'center'}
                        spacing={10}
                        infinite={true}
                    >
                        {IqosKirunaVideoSliderData.map(
                            ({ id, videoId, subtitle, title, videoThumbnail }) => {
                                return (
                                    <div className={styles.slideContainer} key={id}>
                                        <ArticleImage
                                            isVideo
                                            image={videoThumbnail}
                                            onClick={() => handleShowModal(videoId)}
                                        />

                                        <h3>{title}</h3>
                                        <p>{subtitle}</p>
                                    </div>
                                );
                            }
                        )}
                    </KeenSlider>
                </div>
            </ArticleContainer>

            <ArticleContainer
                className={styles.articleContainerMarginBottom}
                background={'#34303D'}
            >
                <div className={classnames('content', styles.sliderWrapper)}>
                    <Image.PreviewGroup>
                        <KeenSlider
                            withArrows={true}
                            perView={1}
                            mode={'snap'}
                            origin={'center'}
                            spacing={10}
                            infinite={true}
                        >
                            {IqosKirunaImgSliderData.map(({ id, image }) => {
                                return (
                                    <div
                                        className={classnames(styles.slideContainer, styles.light)}
                                        key={id}
                                    >
                                        <Image src={image} />

                                        <h3>Galeria IQOS Aurora Experience</h3>
                                        <p>
                                            Poate că experiențele din Kiruna nu pot fi replicate,
                                            dar meritau imortalizate. Vezi cum au arătat câteva
                                            dintre aceste momente pentru câștigători.
                                        </p>
                                    </div>
                                );
                            })}
                        </KeenSlider>
                    </Image.PreviewGroup>
                </div>
            </ArticleContainer>

            <ArticleContainer background={'#F6F4F0'}>
                <div className={classnames('content', styles.sliderWrapper)}>
                    <ArticleImage image={contestImage} />

                    <h3>Personalizează următoarea experiență IQOS Together X!</h3>
                    <p>
                        Preferințele tale și ale celorlalți membri ai comunității IQOS CLUB pot
                        influența următoarea experiență IQOS Together X! Participă în campania{' '}
                        <strong>„Oameni reali. Legături reale.”</strong> și votează activitățile
                        preferate pentru experiența IQOS din Mykonos care va avea loc vara aceasta.
                    </p>

                    <Button
                        type={'solid'}
                        color={'dark'}
                        hover={'turquoise'}
                        label={'Votează acum'}
                        href={config.path.campaigns.realPeopleRealConnectionsCampaignPage}
                    />
                </div>
            </ArticleContainer>

            <VideoModal
                toggleModal={toggleModal}
                setToggleModal={handleCloseModal}
                video={currentVideo}
                lockScroll={false}
            />

            {(showSuccess || article.completed_quiz) && (
                <CompletedStateArticle
                    className={styles.completedStateArticle}
                    article={{
                        points: article.points,
                        quiz_points: article.quiz_points,
                        quiz_end_date: article.quiz_end_date
                    }}
                />
            )}
        </section>
    );
};

export default KirunaWrapup;
