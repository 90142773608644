export const PWACommunityIcon = () => {
    return (
        <svg
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <mask
                id="mask0_8898_9350"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="56"
                height="56"
            >
                <path d="M0 0H56V56H0V0Z" fill="white" />
            </mask>
            <g mask="url(#mask0_8898_9350)">
                <mask
                    id="mask1_8898_9350"
                    style={{ maskType: 'alpha' }}
                    maskUnits="userSpaceOnUse"
                    x="7"
                    y="8"
                    width="35"
                    height="37"
                >
                    <path
                        d="M7.21875 8.96924L41.2819 9.1874V17.7041L19.4466 17.9222V44.1256L7.21875 41.5052V8.96924Z"
                        fill="white"
                    />
                </mask>
                <g mask="url(#mask1_8898_9350)">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M10.4982 12.2437C9.5299 12.2437 8.74707 13.0277 8.74707 13.996V30.432C8.74707 31.4003 9.53107 32.1843 10.4971 32.1843H13.2002L13.3356 39.0968L20.9142 32.1832H35.8196C36.7867 32.1832 37.5696 31.4003 37.5696 30.4332V13.9937C37.5696 13.0265 36.7867 12.2437 35.8196 12.2437H10.4982V12.2437Z"
                        stroke="white"
                        strokeWidth="2.33333"
                        strokeLinejoin="round"
                    />
                </g>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M46.0002 17.7026C46.9674 17.7026 47.7502 18.4866 47.7502 19.4538V35.8921C47.7502 36.8581 46.9674 37.6421 46.0002 37.6421H43.2971L43.1617 44.5558L35.5842 37.6421H20.6789C19.7106 37.6421 18.9277 36.8581 18.9277 35.8921V19.4526C18.9277 18.4855 19.7117 17.7026 20.6777 17.7026H46.0014H46.0002Z"
                    stroke="white"
                    strokeWidth="2.33333"
                    strokeLinejoin="round"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M43.6274 20.6968C44.2714 20.6968 44.7941 21.2183 44.7941 21.8634V33.4939C44.7941 34.1379 44.2714 34.6606 43.6274 34.6606H40.9721L40.8881 38.9411L36.1561 34.6594H23.3717C22.7277 34.6594 22.2051 34.1379 22.2051 33.4928V21.8634C22.2051 21.2194 22.7277 20.6968 23.3717 20.6968H43.6274Z"
                    fill="#00D1D2"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M37.7894 27.4193C37.7894 28.3234 38.5232 29.0573 39.4274 29.0573C40.3315 29.0573 41.0654 28.3234 41.0654 27.4193C41.0654 26.5151 40.3315 25.7812 39.4274 25.7812C38.5232 25.7812 37.7894 26.5151 37.7894 27.4193ZM31.6749 27.4193C31.6749 28.3234 32.4099 29.0573 33.3129 29.0573C34.2182 29.0573 34.9509 28.3234 34.9509 27.4193C34.9509 26.5151 34.2182 25.7812 33.3129 25.7812C32.4087 25.7812 31.676 26.5151 31.676 27.4193H31.6749ZM25.5615 27.4193C25.5615 28.3234 26.2942 29.0573 27.1995 29.0573C28.1037 29.0573 28.8364 28.3234 28.8364 27.4193C28.8364 26.5151 28.1049 25.7812 27.1995 25.7812C26.2942 25.7812 25.5615 26.5151 25.5615 27.4193Z"
                    fill="#34303D"
                />
            </g>
        </svg>
    );
};
