import styles from './DateBadge.module.scss';
import { IDateBadgeProps } from '@containers/Campaigns/CallForDreams/components/DateBadge/interface';
import classnames from 'classnames';

// Components
import RewardBadge from '@components/RewardBadge';

// Assets
import { DateIcon } from '@assets/icons';

const DateBadge = ({ date, disabled = false, notFullWidth = false }: IDateBadgeProps) => {
    return (
        <div
            className={classnames(
                styles.dateBadgeContainer,
                disabled && styles.disabled,
                notFullWidth && styles.notFullWidth
            )}
        >
            <RewardBadge
                className={classnames(styles.dateBadge, styles.longText)}
                absolute={false}
                custom={date}
                badge={<DateIcon />}
            />
        </div>
    );
};

export default DateBadge;
