import {createAction, createAsyncThunk, createSlice} from '@reduxjs/toolkit';

import axiosRequest from '@utils/axios';
import {resetState} from "@containers/MGM/components/RecommendForm/recommendFormSlice";

export interface FormState {
    status: 'idle' | 'loading' | 'failed' | 'success';
    errors: any;
}

interface AxiosError {
    response: {
        data: {
            message: string;
            errors: object;
        };
    };
}

const initialState: FormState = {
    status: 'idle',
    errors: {
        data: {}
    }
};

export const enrollChallenge = createAsyncThunk('monthlyChallenge/enrollChallenge', async (values: {
    challenge_id: number;
}, { rejectWithValue }) => {
    const challenge_id = values['challenge_id'];

    try {
        const response = await axiosRequest.post(`/challenges/${challenge_id}/registrations`, values).then((res) => {
            return res;
        });

        return response.data;
    } catch (e) {
        return rejectWithValue((e as AxiosError).response.data);
    }
});

export const EnrollChallengeSlice = createSlice({
    name: 'enrollChallenge',
    initialState,
    reducers: {
        reset: () => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(enrollChallenge.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(enrollChallenge.fulfilled, (state) => {
                state.status = 'success';
            })
            .addCase(enrollChallenge.rejected, (state, action) => {
                state.status = 'failed';
                state.errors = action.payload;
            })
            .addCase(resetState, () => initialState);
    }
});

export const resetEnrollFormState = createAction('REVERT_ALL');

export default EnrollChallengeSlice.reducer;
