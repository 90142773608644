export const QRIconQuickMenu = () => {
    return (
        <svg
            width="23"
            height="23"
            viewBox="0 0 23 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_5170_123223)">
                <rect
                    x="1.355"
                    y="1.09035"
                    width="7.29"
                    height="7.29"
                    stroke="#34303D"
                    strokeWidth="1.71"
                />
                <rect
                    x="14.355"
                    y="1.09035"
                    width="7.29"
                    height="7.29"
                    stroke="#34303D"
                    strokeWidth="1.71"
                />
                <rect
                    x="1.355"
                    y="14.0904"
                    width="7.29"
                    height="7.29"
                    stroke="#34303D"
                    strokeWidth="1.71"
                />
                <path d="M22.5 19.9854H20.25V22.2354H22.5V19.9854Z" fill="#34303D" />
                <path d="M15.75 13.2354H13.5V15.4854H15.75V13.2354Z" fill="#34303D" />
                <path d="M18 15.4854H15.75V17.7354H18V15.4854Z" fill="#34303D" />
                <path d="M15.75 17.7354H13.5V19.9854H15.75V17.7354Z" fill="#34303D" />
                <path d="M18 19.9854H15.75V22.2354H18V19.9854Z" fill="#34303D" />
                <path d="M20.2499 17.7354H17.9999V19.9854H20.2499V17.7354Z" fill="#34303D" />
                <path d="M20.2499 13.2354H17.9999V15.4854H20.2499V13.2354Z" fill="#34303D" />
                <path d="M22.5 15.4854H20.25V17.7354H22.5V15.4854Z" fill="#34303D" />
            </g>
            <defs>
                <clipPath id="clip0_5170_123223">
                    <rect width="22" height="22" fill="white" transform="translate(0.5 0.235352)" />
                </clipPath>
            </defs>
        </svg>
    );
};
