import styles from './TriviaGame9.module.scss';
import classnames from 'classnames';
import {
    getGame,
    postArticlesActionCTR,
    postGameActionCTR,
    resetGame
} from '@containers/IQOSUniverse/IQOSUniverseSlice';

import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import BaseLoader from '@components/Layout/BaseLoader';
import Button from '@components/Layout/Button';
import { dragGameData } from '@containers/IQOSUniverse/articles/TriviaGame9/data';

// Assets
import footerImage from './assets/leviaFooterImage.gif';
import congratsImg from './assets/headerCongrats.png';
import setHeaderTitle from '@utils/setHeaderTitle';
import DragCardGame from '@components/Games/DragCardGame';

const TriviaGame9 = () => {
    const dispatch = useAppDispatch();
    const { article, articleGame, status } = useAppSelector((state) => state.iqosUniverse);

    const [showGame, setShowGame] = useState(false);
    const [success, setSuccess] = useState<boolean | null>(null);

    useEffect(() => {
        if (!articleGame) {
            dispatch(getGame(article.cta_link || ' '));
            setHeaderTitle('IQOS Quiz');
        }
        return () => {
            dispatch(resetGame());
        };
    }, [articleGame?.activity_campaign_id]);

    const onLose = () => {
        dispatch(postGameActionCTR({ gameId: articleGame?.id || 0, winner: 0 }));
        setSuccess(false);
        setShowGame(false);
    };

    const onSuccess = () => {
        setTimeout(() => {
            dispatch(postGameActionCTR({ gameId: articleGame?.id || 0, winner: 1 }));
            setSuccess(true);
            setShowGame(false);
        }, 3000);
    };

    const startGame = () => {
        if (success) {
            dispatch(postArticlesActionCTR(`Link_rezerve_levia`));
            window.open('https://www.iqos.ro/iluma-all/rezerve-iluma', '_blank');
        } else {
            if (articleGame) {
                dispatch(postArticlesActionCTR(`trivia_game_${articleGame.id}`));
            }
            setShowGame(true);
        }
    };

    const trackLink = (link: string) => {
        dispatch(postArticlesActionCTR(link));
    };

    if (status === 'loading') {
        return <BaseLoader size={'normal'} />;
    }

    return (
        <section className={classnames(styles.wrapper)}>
            {showGame ? (
                <DragCardGame
                    data={
                        dragGameData.filter((game: any) => game.slug === article.cta_link)[0]?.data
                    }
                    maxGuesses={3}
                    pairs={2}
                    cardsType={'square'}
                    notGuessed
                    onWin={onSuccess}
                    onLose={onLose}
                />
            ) : (
                <>
                    <div className={styles.gameContainer}>
                        {success === null && (
                            <img
                                className={classnames(success && styles.congratsImg)}
                                src={
                                    dragGameData.filter(
                                        (game: any) => game.slug === article.cta_link
                                    )[0]?.header
                                }
                                alt={'headerImg'}
                            />
                        )}

                        <h3>
                            {success === null
                                ? dragGameData.filter(
                                      (game: any) => game.slug === article.cta_link
                                  )[0]?.title
                                : success
                                ? dragGameData.filter(
                                      (game: any) => game.slug === article.cta_link
                                  )[0]?.successTitle
                                : dragGameData.filter(
                                      (game: any) => game.slug === article.cta_link
                                  )[0]?.failTitle}
                        </h3>

                        <p
                            dangerouslySetInnerHTML={{
                                __html:
                                    success === null
                                        ? dragGameData.filter(
                                              (game: any) => game.slug === article.cta_link
                                          )[0]?.description
                                        : success
                                        ? dragGameData.filter(
                                              (game: any) => game.slug === article.cta_link
                                          )[0]?.successText
                                        : dragGameData.filter(
                                              (game: any) => game.slug === article.cta_link
                                          )[0]?.failText
                            }}
                        />
                    </div>

                    {!showGame && (
                        <Button
                            type={'solid'}
                            label={
                                success === null
                                    ? 'Start'
                                    : success
                                    ? 'Încearcă LEVIA'
                                    : 'Mai încearcă'
                            }
                            color={'dark'}
                            hover={'turquoise'}
                            onClick={startGame}
                        />
                    )}

                    {success === false && (
                        <Button
                            className={styles.buttonLink}
                            type={'text'}
                            label={'Află mai multe despre LEVIA'}
                            color={'dark'}
                            hover={'turquoise'}
                            target={'_blank'}
                            onClick={() => {
                                trackLink('Link_descopera_levia');
                            }}
                            href={'https://www.iqos.ro/descopera-levia'}
                        />
                    )}

                    {success !== null && <img src={footerImage} alt={'levia'} />}
                </>
            )}
        </section>
    );
};

export default TriviaGame9;
