import React, { useState } from 'react';

// Assets
import styles from './TogetherXAtena.module.scss';
import { FPIcon } from '@assets/icons';

import secondVideoThumbnail from './assets/secondVideoThumbnail.jpg';
import img1 from './assets/1.jpg';
import img2 from './assets/2.jpg';
import img3 from './assets/3.jpg';
import img4 from './assets/4.jpg';
import img5 from './assets/5.jpg';
import img6 from './assets/6.jpg';
import img7 from './assets/7.jpg';
import img8 from './assets/8.jpg';
import img9 from './assets/9.jpg';
import img10 from './assets/10.jpg';
import img11 from './assets/11.jpg';
import img12 from './assets/12.jpg';
import img13 from './assets/13.jpg';
import img14 from './assets/14.jpg';
import img15 from './assets/15.jpg';

// Components
import ArticleHeader from '@containers/IQOSUniverse/components/ArticleHeader';
import VideoModalQuiz from '@containers/IQOSUniverse/components/VideoModalQuiz';
import ArticleContainer from '@containers/IQOSUniverse/components/ArticleContainer';
import ArticleImage from '@containers/IQOSUniverse/components/ArticleImage';
import VideoModal from '@components/VideoModal';
import MediaGallery from '@containers/IQOSUniverse/components/MediaGallery';
import CompletedStateArticle from '@containers/IQOSUniverse/components/CompletedStateArticle/CompletedStateArticle';

// Redux
import { useAppSelector } from '@app/hooks';

import dayjs from '@utils/dayjs';

const TogetherXAtena = () => {
    const { article } = useAppSelector((state) => state.iqosUniverse);

    const [toggleModal, setToggleModal] = useState(false);
    const [toggleVideoModal, setToggleVideoModal] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);

    return (
        <section className={styles.wrapper}>
            <ArticleHeader
                videoQuiz={() => {
                    setToggleModal(true);
                }}
            />
            <VideoModalQuiz
                toggleModal={toggleModal}
                setToggleModal={() => setToggleModal(false)}
                setShowSuccess={() => {
                    setShowSuccess(true);
                }}
            />
            <ArticleContainer background={'#fffdfb'}>
                <div className="content">
                    <p>
                        Câștigătorii campaniei „Trăiește vara la următorul nivel. Cu IQOS ILUMA”,
                        „Jazz in the Park” și „Imaginea comunității IQOS” au participat între 28 –
                        30 septembrie la cel mai așteptat eveniment global IQOS Together X din
                        Atena.
                    </p>
                    <p>
                        În cadrul experienței, participanții au avut parte de o serie de activități
                        surpriză prin care au descoperit cultura și obiectivele turistice din
                        centrul istoric al Atenei. Apoi au degustat cele mai faimoase preparate
                        culinare locale și au socializat cu membrii comunității IQOS din întreaga
                        lume.
                    </p>
                </div>
            </ArticleContainer>

            <ArticleContainer background={'#00D1D2'}>
                <div className={styles.customArticleImage}>
                    <ArticleImage
                        isVideo
                        mobileImage={secondVideoThumbnail}
                        image={secondVideoThumbnail}
                        onClick={() => setToggleVideoModal(true)}
                    />
                </div>

                <VideoModal
                    toggleModal={toggleVideoModal}
                    setToggleModal={() => setToggleVideoModal(false)}
                    video="875941949"
                />
            </ArticleContainer>

            <div className={styles.customArticleContainer}>
                <ArticleContainer background={'#F6F4F0'}>
                    <div className={'content'}>
                        <p>
                            Evenimentul a marcat un moment important pentru mii de membri ai
                            comunității globale IQOS din 19 țări. Participanții s-au bucurat de
                            experiențe de neuitat într-o locație emblematică, Ellinikon Experience
                            Park, cu artiști locali și internaționali de excepție.
                        </p>
                    </div>
                </ArticleContainer>
            </div>

            <ArticleContainer background="#00D1D2">
                <div className="content">
                    <h3>Te-am făcut curios?</h3>
                    <p>
                        Ți-am pregatit și o galerie cu momentele noastre preferate de la evenimentul
                        global Together X din Atena, alături de comunitatea IQOS. Sperăm să te
                        cunoaștem și pe tine la următorul nostru eveniment marca Together X!
                    </p>
                    <MediaGallery
                        showTitle={false}
                        darkArrows={true}
                        sliderClassName={styles.slider}
                        images={[
                            img1,
                            img2,
                            img3,
                            img4,
                            img5,
                            img6,
                            img7,
                            img8,
                            img9,
                            img10,
                            img11,
                            img12,
                            img13,
                            img14,
                            img15
                        ]}
                    />
                </div>
            </ArticleContainer>

            {dayjs(article.quiz_end_date).isAfter(dayjs()) && (
                <>
                    {showSuccess || article.completed_quiz ? (
                        <CompletedStateArticle
                            className={styles.completedStateArticle}
                            article={{
                                points: article.points,
                                quiz_points: article.quiz_points,
                                quiz_end_date: article.quiz_end_date
                            }}
                        />
                    ) : (
                        <section className={styles.congratsContainer}>
                            <div className={styles.completed}>
                                <h3>
                                    Urmărește video-ul Together X din Atena și acumulezi 100 FP!
                                </h3>

                                <div className={styles.reward}>
                                    <FPIcon />
                                    <span>
                                        <b>+100</b> <br />
                                        Forward Points
                                    </span>
                                </div>
                            </div>
                        </section>
                    )}
                </>
            )}
        </section>
    );
};

export default TogetherXAtena;
