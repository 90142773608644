import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classnames from 'classnames';

// Assets
import styles from './TipsTricksIluma.module.scss';
import { CheckmarkIcon } from './icons';
import img1 from './assets/img1.jpg';
import img2 from './assets/img2.jpg';
import img3 from './assets/img3.jpg';
import img4 from './assets/img4.jpg';
import q1 from './assets/q1.png';
import q1Mobile from './assets/q1mobile.png';
import q2 from './assets/q2.png';
import q2Mobile from './assets/q2mobile.png';
import q3 from './assets/q3.png';
import q3Mobile from './assets/q3mobile.png';
import q4 from './assets/q4.png';
import q4Mobile from './assets/q4mobile.png';

// Components
import ArticleHeader from '@containers/IQOSUniverse/components/ArticleHeader';
import ArticleContainer from '@containers/IQOSUniverse/components/ArticleContainer';
import ArticleImage from '@containers/IQOSUniverse/components/ArticleImage';
import Button from '@components/Layout/Button';
import KeenSlider from '@components/KeenSlider';
import RewardBadge from '@components/RewardBadge';
import { SeenArticleIcon } from '@assets/icons';

import { config } from '@utils/config';
import axios from '@utils/axios';

// Redux
import { useAppSelector } from '@app/hooks';
import { useWindowSize } from '@utils/hooks/windowSize';

const TipsTricksIluma: React.FC = () => {
    const navigate = useNavigate();
    const { width } = useWindowSize();
    const isMobile = width < 768;
    const { article } = useAppSelector((state) => state.iqosUniverse);
    const [completedQuizzes, setCompletedQuizzes] = useState<any>([]);

    const articles = [
        'stii-cum-sa-rezolvi-aceasta-eroare-la-iqos-iluma',
        'cat-dureaza-o-experienta-de-utilizare-iqos-iluma',
        'utilizezi-iqos-iluma-in-mod-corect',
        'ce-acopera-garantia-dispozitivului'
    ];

    const quizzes = [
        'stii-cum-sa-rezolvi-aceasta-eroare-la-iqos-iluma',
        'cat-dureaza-o-experienta-de-utilizare-iqos-iluma',
        'utilizezi-iqos-iluma-in-mod-corect',
        'ce-acopera-garantia-dispozitivului'
    ];

    const getCompletedQuiz = async () => {
        try {
            const requestPromises = quizzes.map(async (quiz) => {
                const response = await axios.get(`/user/quizzes/${quiz}`);
                if (response.data.is_completed) {
                    return quiz;
                }
            });

            const completedQuizzesArray = await Promise.all(requestPromises);

            const filteredCompletedQuizzes = completedQuizzesArray.filter((quiz) => quiz);
            setCompletedQuizzes(filteredCompletedQuizzes);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getCompletedQuiz();
    }, []);

    return (
        <section className={styles.wrapper}>
            <div className={classnames('content', styles.title)}>
                <h3>Tips & Tricks IQOS ILUMA</h3>
            </div>
            <ArticleHeader isCustomPhotoDimension hideTitle />

            <ArticleContainer background={'#fffdfb'}>
                <div className="content">
                    <h3>Ce poți face dacă întâmpini o eroare la IQOS ILUMA</h3>
                    <p>
                        Dispozitivele din gama <strong>IQOS ILUMA</strong> oferă o experiență
                        superioară cu fiecare utilizare. Însă uneori pot apărea și probleme, dar
                        nu-ți face griji! Află cum poți rezolva{' '}
                        <strong>
                            4 dintre cele mai frecvent întâlnite erori la aceste dispozitive.
                        </strong>
                        <br />
                        Răspunde la cele patru quiz-uri și acumulezi 40 FP.
                    </p>
                </div>
            </ArticleContainer>

            <div className={styles.sliderWrapper}>
                <KeenSlider
                    arrowsShouldDisable
                    mode="snap"
                    spacing={8}
                    autoSwitch
                    timeoutBetweenSlides={5000}
                >
                    <div className={styles.slide}>
                        <div className={styles.quizCard}>
                            <div className={styles.container}>
                                <div className={styles.visual}>
                                    {!completedQuizzes.includes(quizzes[0]) ? (
                                        <RewardBadge
                                            className={styles.rewardBadge}
                                            backgroundColor={'#FFFDFB'}
                                            points={10}
                                            absolute={true}
                                            small
                                            smaller
                                        />
                                    ) : (
                                        <div className={styles.seenIcon}>
                                            <SeenArticleIcon />
                                        </div>
                                    )}

                                    <img src={isMobile ? q1Mobile : q1} alt="Quiz 1" />
                                </div>
                                <div className={styles.content}>
                                    <h3>Știi cum să rezolvi această eroare la IQOS ILUMA?</h3>
                                    <p>
                                        Răspunde la quiz și află dacă modul în care îți folosești
                                        dispozitivul este cel corect.
                                    </p>
                                    <Button
                                        type="text"
                                        color="dark"
                                        hover="light"
                                        onClick={() =>
                                            navigate(
                                                `${config.path.iqosUniverse.iqosQuiz}/${articles[0]}`
                                            )
                                        }
                                    >
                                        Începe quiz
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div>
                            <h3 className={styles.title}>
                                Ledul dispozitivului pulsează roșu intermitent
                            </h3>
                            <ArticleImage noMargin image={img1} />

                            <ArticleContainer background={'transparent'}>
                                <div className={classnames('content', styles.fullWidthContent)}>
                                    <p>
                                        Culorile ledului de pe dispozitiv sunt indicii despre starea
                                        de funcționare a aparatului tău. Ce poți face când acesta
                                        pulsează roșu?
                                    </p>

                                    <div className={styles.steps}>
                                        <h4>
                                            <span>1.</span> Resetează-ți dispozitivul:
                                        </h4>
                                        <div className={styles.step}>
                                            <div className={styles.icon}>
                                                <CheckmarkIcon />
                                            </div>
                                            <p>
                                                Introdu stiloul în încărcătorul portabil. Ține
                                                apăsat butonul de pornire timp de{' '}
                                                <strong>10 secunde</strong> până când ledurile se
                                                sting.
                                            </p>
                                        </div>
                                        <div className={styles.step}>
                                            <div className={styles.icon}>
                                                <CheckmarkIcon />
                                            </div>
                                            <p>
                                                Atunci când se reaprind, iar dispozitivul vibrează,
                                                resetarea este confirmată.
                                            </p>
                                        </div>
                                    </div>
                                    <div className={styles.steps}>
                                        <h4>
                                            <span>2.</span> Actualizează-ți dispozitivul:
                                        </h4>
                                        <div className={styles.step}>
                                            <div className={styles.icon}>
                                                <CheckmarkIcon />
                                            </div>
                                            <p>
                                                Ca orice dispozitiv electronic, IQOS ILUMA e compus
                                                din elemente fizice plus{' '}
                                                <strong>
                                                    instrucțiuni digitale de funcționare denumite
                                                    firmware
                                                </strong>
                                                . Acesta trebuie actualizat periodic.
                                            </p>
                                        </div>
                                        <div className={styles.step}>
                                            <div className={styles.icon}>
                                                <CheckmarkIcon />
                                            </div>
                                            <p>
                                                Poți face actualizarea la cea mai nouă versiune de
                                                firmware prin aplicația{' '}
                                                <Button
                                                    href="https://www.iqos.ro/iqos-connect-benefits"
                                                    type="text"
                                                    color="dark"
                                                    hover="turquoise"
                                                >
                                                    <strong>IQOS Connect</strong>
                                                </Button>{' '}
                                                sau vizitând o locație IQOS.
                                            </p>
                                        </div>
                                    </div>
                                    <a
                                        rel="noreferrer"
                                        href="https://www.iqos.ro/myiqos/asistenta/probleme-dispozitiv-iqos"
                                        target="_blank"
                                    >
                                        Află mai multe: Probleme dispozitiv IQOS | Asistență IQOS |
                                        IQOS România
                                    </a>
                                </div>
                            </ArticleContainer>
                        </div>
                    </div>

                    <div className={styles.slide}>
                        <div className={styles.quizCard}>
                            <div className={styles.container}>
                                <div className={styles.visual}>
                                    {!completedQuizzes.includes(quizzes[1]) ? (
                                        <RewardBadge
                                            className={styles.rewardBadge}
                                            backgroundColor={'#FFFDFB'}
                                            points={10}
                                            absolute={true}
                                            small
                                            smaller
                                        />
                                    ) : (
                                        <div className={styles.seenIcon}>
                                            <SeenArticleIcon />
                                        </div>
                                    )}

                                    <img src={isMobile ? q2Mobile : q2} alt="Quiz 2" />
                                </div>
                                <div className={styles.content}>
                                    <h3>Cât durează o experiență de utilizare IQOS ILUMA?</h3>
                                    <p>
                                        Răspunde la întrebare și vezi cât de multe știi despre
                                        dispozitivul tău.
                                    </p>
                                    <Button
                                        type="text"
                                        color="dark"
                                        hover="light"
                                        onClick={() =>
                                            navigate(
                                                `${config.path.iqosUniverse.iqosQuiz}/${articles[1]}`
                                            )
                                        }
                                    >
                                        Începe quiz
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div>
                            <h3 className={styles.title}>
                                Experiența de utilizare durează mai puțin de 6 minute
                            </h3>
                            <ArticleImage noMargin image={img2} />

                            <ArticleContainer background={'transparent'}>
                                <div className={classnames('content', styles.fullWidthContent)}>
                                    <p>
                                        Dacă durata unei sesiuni este mai scurtă, există mai multe
                                        explicații posibile:
                                    </p>

                                    <div className={styles.steps}>
                                        <div className={styles.step}>
                                            <div className={styles.icon}>
                                                <CheckmarkIcon />
                                            </div>
                                            <p>
                                                Este posibil ca tutunul să nu fie încălzit corect.
                                                Asigură-te că aștepți <strong>20 de secunde</strong>{' '}
                                                după ce ai apăsat pe buton până la aprinderea
                                                ledului alb și vibrația dispozitivului, care te
                                                anunță că dispozitivul este gata de a fi folosit.
                                            </p>
                                        </div>
                                        <div className={styles.step}>
                                            <div className={styles.icon}>
                                                <CheckmarkIcon />
                                            </div>
                                            <p>
                                                Asigură-te că faci pauze între inhalări, pentru a-i
                                                permite dispozitivului să încălzească în mod corect
                                                tutunul și să genereze aerosol.
                                            </p>
                                        </div>
                                        <div className={styles.step}>
                                            <div className={styles.icon}>
                                                <CheckmarkIcon />
                                            </div>
                                            <p>
                                                Uneori este posibil ca experiența să se termine mai
                                                repede, în funcție de{' '}
                                                <strong>modul de utilizare</strong>. Dacă pufurile
                                                sunt mai intense sau mai frecvente, dispozitivul
                                                poate percepe mai multe inhalări și se poate opri
                                                mai repede.
                                            </p>
                                        </div>
                                    </div>
                                    <a
                                        rel="noreferrer"
                                        href="https://www.iqos.ro/myiqos/asistenta/probleme-dispozitiv-iqos"
                                        target="_blank"
                                    >
                                        Află mai multe: Probleme dispozitiv IQOS | Asistență IQOS |
                                        IQOS România
                                    </a>
                                </div>
                            </ArticleContainer>
                        </div>
                    </div>

                    <div className={styles.slide}>
                        <div className={styles.quizCard}>
                            <div className={styles.container}>
                                <div className={styles.visual}>
                                    {!completedQuizzes.includes(quizzes[2]) ? (
                                        <RewardBadge
                                            className={styles.rewardBadge}
                                            backgroundColor={'#FFFDFB'}
                                            points={10}
                                            absolute={true}
                                            small
                                            smaller
                                        />
                                    ) : (
                                        <div className={styles.seenIcon}>
                                            <SeenArticleIcon />
                                        </div>
                                    )}

                                    <img src={isMobile ? q3Mobile : q3} alt="Quiz 3" />
                                </div>
                                <div className={styles.content}>
                                    <h3>Utilizezi IQOS ILUMA în mod corect?</h3>
                                    <p>
                                        Verifică dacă îți utilizezi dispozitivul în mod corect cu un
                                        scurt quiz.
                                    </p>
                                    <Button
                                        type="text"
                                        color="dark"
                                        hover="light"
                                        onClick={() =>
                                            navigate(
                                                `${config.path.iqosUniverse.iqosQuiz}/${articles[2]}`
                                            )
                                        }
                                    >
                                        Începe quiz
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div>
                            <h3 className={styles.title}>
                                O încărcare completă oferă mai puțin de 20 de utilizări
                            </h3>
                            <ArticleImage noMargin image={img3} />

                            <ArticleContainer background={'transparent'}>
                                <div className={classnames('content', styles.fullWidthContent)}>
                                    <p>
                                        În timp, performanța bateriei oricărui dispozitiv electronic
                                        se poate reduce. Aceasta este o situație normală. Există,
                                        totuși, câteva lucruri pe care le poți face pentru a o
                                        preveni:
                                    </p>

                                    <div className={styles.steps}>
                                        <div className={styles.step}>
                                            <div className={styles.icon}>
                                                <CheckmarkIcon />
                                            </div>
                                            <p>
                                                Utilizează și încarcă IQOS ILUMA doar la temperaturi
                                                între <strong>0°C</strong> și <strong>40°C</strong>.
                                                Folosirea în afara acestui interval îi afectează
                                                bateria și poate opri experiența.
                                            </p>
                                        </div>
                                        <div className={styles.step}>
                                            <div className={styles.icon}>
                                                <CheckmarkIcon />
                                            </div>
                                            <p>
                                                Asigură-te că ai lăsat dispozitivul să se încarce
                                                complet, utilizând{' '}
                                                <strong>accesoriile originale IQOS</strong>. Evită
                                                să îl deconectezi de la priză în timpul încărcării.
                                            </p>
                                        </div>
                                        <div className={styles.step}>
                                            <div className={styles.icon}>
                                                <CheckmarkIcon />
                                            </div>
                                            <p>
                                                În concordanță cu termenii standard ai industriei
                                                electronice, degradarea bateriei este{' '}
                                                <strong>în afara garanției</strong>.
                                            </p>
                                        </div>
                                    </div>
                                    <a
                                        rel="noreferrer"
                                        href="https://www.iqos.ro/myiqos/asistenta/probleme-dispozitiv-iqos"
                                        target="_blank"
                                    >
                                        Află mai multe: Probleme dispozitiv IQOS | Asistență IQOS |
                                        IQOS România
                                    </a>
                                </div>
                            </ArticleContainer>
                        </div>
                    </div>

                    <div className={styles.slide}>
                        <div className={styles.quizCard}>
                            <div className={styles.container}>
                                <div className={styles.visual}>
                                    {!completedQuizzes.includes(quizzes[3]) ? (
                                        <RewardBadge
                                            className={styles.rewardBadge}
                                            backgroundColor={'#FFFDFB'}
                                            points={10}
                                            absolute={true}
                                            small
                                            smaller
                                        />
                                    ) : (
                                        <div className={styles.seenIcon}>
                                            <SeenArticleIcon />
                                        </div>
                                    )}

                                    <img src={isMobile ? q4Mobile : q4} alt="Quiz 4" />
                                </div>
                                <div className={styles.content}>
                                    <h3>Ce acoperă garanția dispozitivului?</h3>
                                    <p>
                                        Răspunde la întrebare și vezi cât de multe știi despre
                                        garanția dispozitivului tău.
                                    </p>
                                    <Button
                                        type="text"
                                        color="dark"
                                        hover="light"
                                        onClick={() =>
                                            navigate(
                                                `${config.path.iqosUniverse.iqosQuiz}/${articles[3]}`
                                            )
                                        }
                                    >
                                        Începe quiz
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div>
                            <h3 className={styles.title}>Dispozitivul meu IQOS ILUMA e defect</h3>
                            <ArticleImage noMargin image={img4} />

                            <ArticleContainer background={'transparent'}>
                                <div className={classnames('content', styles.fullWidthContent)}>
                                    <p>
                                        Pe lângă garanția legală de conformitate, beneficiezi și de
                                        garanția pentru defecțiuni accidentale (garanția voluntară),
                                        ce îți oferă dreptul la o înlocuire în primele 12 luni de la
                                        achiziție pentru fiecare componentă din kitul tău.
                                    </p>

                                    <div className={styles.steps}>
                                        <div className={styles.step}>
                                            <div className={styles.icon}>
                                                <CheckmarkIcon />
                                            </div>
                                            <p>
                                                Acest beneficiu se aplică doar în cazul acelor
                                                defecțiuni fizice accidentale în urma cărora
                                                dispozitivul nu mai funcționează.
                                            </p>
                                        </div>
                                        <div className={styles.step}>
                                            <div className={styles.icon}>
                                                <CheckmarkIcon />
                                            </div>
                                            <p>
                                                Urmele de uzură a design-ului precum loviturile,
                                                zgârieturile nu sunt acoperite de garanție.
                                            </p>
                                        </div>
                                        <div className={styles.step}>
                                            <div className={styles.icon}>
                                                <CheckmarkIcon />
                                            </div>
                                            <p>
                                                <a
                                                    rel="noreferrer"
                                                    href="https://www.iqos.ro/myiqos/asistenta/garantie-iqos-si-metode-de-inlocuire"
                                                    target="_blank"
                                                >
                                                    <strong>Află mai multe aici</strong>
                                                </a>{' '}
                                                sau consultă certificatul de garanție pentru mai
                                                multe detalii.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </ArticleContainer>
                        </div>
                    </div>
                </KeenSlider>
            </div>
        </section>
    );
};

export default TipsTricksIluma;
