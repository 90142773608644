import { useState } from 'react';
import styles from './ArtForAirTiff.module.scss';

// Assets
import image1 from './assets/img-01.png';
import quotes from './assets/quotes.svg';
import videoThumbnail from './assets/videoThumbnail.png';
import videoThumbnailDesktop from './assets/videoThumbnailDesktop.png';

// Components
import VideoModal from '@components/VideoModal';
import ArticleImage from '@containers/IQOSUniverse/components/ArticleImage';
import ArticleHeader from '@containers/IQOSUniverse/components/ArticleHeader';
import ArticleContainer from '@containers/IQOSUniverse/components/ArticleContainer';

const ArtForAirTiff = () => {
    const [toggleModal, setToggleModal] = useState(false);

    return (
        <section className={styles.wrapper}>
            <ArticleHeader />

            <div className="content">
                <h3>
                    Art for Air, proiectul nostru special realizat în colaborare cu Obie Platon, a
                    ajuns la Cluj-Napoca
                </h3>
                <p>
                    Anul acesta IQOS a propus proiectul Art for Air realizat în colaborare cu Obie
                    Platon, un artist contemporan român, cunoscut pentru stilul său suprarealist și
                    implicarea în proiecte de regenerare culturală prin artă a zonelor suburbane.
                </p>

                <p>
                    În cadrul festivalului, artistul a creat un nou mural, cu ajutorul vopselurilor
                    inovatoare Airlite, la Casa TIFF. Inspirația pentru mural a fost MIRA, semnalul
                    de test emis de televiziuni înaintea sau după încheierea emisiei propriu-zise.
                </p>
            </div>

            <ArticleImage cover={true} mobileImage={image1} image={image1} />

            <div className="content">
                <h3>O discuție despre progres și inovație, între utilizatorii de IQOS</h3>

                <p>
                    Complementar muralului realizat la Casa TIFF, am amenajat un loc în care
                    posesorii de IQOS și-au putut exersa creativitatea și continua dialogul despre
                    progres și inovație.
                </p>

                <p>
                    Prin cuvinte cheie, aceștia au putut să-și exprime propriile păreri despre ce
                    reprezintă pentru ei trecerea la IQOS.
                </p>

                <p>
                    În plus, cei prezenți au putut admira elemente reprezentative din muralul Art
                    for Air, au putut participa la activări inspirate din ediția limitată a
                    dispozitivului IQOS 3 DUO Moonlight Silver și s-au putut relaxa în pauza dintre
                    proiecții.
                </p>
            </div>

            <ArticleContainer background={'#34303D'} textColor={'#FFFDFB'}>
                <div className="content">
                    <img src={quotes} alt={'quotes'} className={styles.quotes} />

                    <p className={styles.text}>
                        Pentru muralul realizat în cadrul TIFF am vrut să surprind ceea ce ne place
                        la Cluj și ceea ce îl reprezintă cel mai bine în perioada aceasta:
                        festivalul TIFF și lumea filmului. Elementele care se regăsesc în mural spun
                        o poveste despre trecut și viitor, progres și inovație și despre cum,
                        împreună, putem modela viitorul. <br /> <br /> <br /> Obie Platon
                    </p>
                </div>
            </ArticleContainer>

            <div className={styles.articleWrapper}>
                <ArticleContainer background={'#00D1D2'}>
                    <div className="content">
                        <h3>Urmărește Crearea Muralului Art for Air București</h3>

                        <ArticleImage
                            isVideo
                            mobileImage={videoThumbnail}
                            image={videoThumbnailDesktop}
                            onClick={() => setToggleModal(true)}
                        />

                        <VideoModal
                            toggleModal={toggleModal}
                            setToggleModal={() => setToggleModal(false)}
                            video="588824008"
                        />
                    </div>
                </ArticleContainer>
            </div>
        </section>
    );
};
export default ArtForAirTiff;
