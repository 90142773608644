import React from 'react';
import styles from './CompletedStateArticle.module.scss';
import { ICompletedStateArticleProps } from '@containers/IQOSUniverse/components/CompletedStateArticle/interface';
import classnames from 'classnames';
import dayjs from '@utils/dayjs';

// Assets
import { ExtraChances, FPIcon } from '@assets/icons';

const CompletedStateArticle = ({
    article,
    className,
    chances,
    campaignTitle
}: ICompletedStateArticleProps) => {
    return (
        <>
            {(article.points !== 0 || article.quiz_points !== 0) &&
            dayjs(article.quiz_end_date).isAfter(dayjs()) ? (
                <section className={classnames(styles.congratsContainer, className)}>
                    <div className={styles.completed}>
                        <h3>
                            Felicitări, ai acumulat {article.points || article.quiz_points} FP
                            {chances
                                ? `, plus ${chances} șanse în campania ${campaignTitle} `
                                : ' '}
                            pentru că ai urmărit videoclipul.
                        </h3>

                        <div className={styles.reward}>
                            <FPIcon />
                            <span>
                                <b>+{article.points || article.quiz_points}</b> <br />
                                Forward Points
                            </span>
                        </div>
                        {chances && (
                            <div className={styles.badgeIcon}>
                                <ExtraChances />
                            </div>
                        )}
                    </div>
                </section>
            ) : null}
        </>
    );
};

export default CompletedStateArticle;
