import dayjs from '@utils/dayjs';
import { RootState } from '@app/store';
import axiosRequest from '@utils/axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export interface HistoryState {
    status: 'idle' | 'loading' | 'success' | 'failed';
    history: {
        converted: number;
        recommendations: number;
        recommendationsArray: Array<{
            addDate: string;
            isPending: number;
            name: string;
            shortName: string;
            status: string;
        }>;
    };
    error: any;
}

interface AxiosError {
    response: {
        data: {
            errors: object;
            message: string;
        };
    };
}

const initialState: HistoryState = {
    status: 'idle',
    history: {
        converted: 0,
        recommendations: 0,
        recommendationsArray: []
    },
    error: {}
};

const getFilterRange = (months?: number) => {
    if (months) {
        const end_date = dayjs();
        const start_date = end_date.subtract(months, 'months');

        return {
            end_date: end_date.format('YYYY-MM-DD'),
            start_date: start_date.format('YYYY-MM-DD')
        };
    } else {
        return null;
    }
};

export const historyAsync = createAsyncThunk(
    'history/getHistory',
    async (months: number | undefined, { rejectWithValue }) => {
        try {
            const response = await axiosRequest.get('/referrals', {
                params: getFilterRange(months)
            });
            return response.data.response;
        } catch (e) {
            return rejectWithValue((e as AxiosError).response.data);
        }
    }
);

export const history = createSlice({
    name: 'history',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(historyAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(historyAsync.fulfilled, (state, action) => {
                state.status = 'success';
                if (action.payload) {
                    state.history = action.payload;
                }
            })
            .addCase(historyAsync.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    }
});

export const selectHistoryLoading = (state: RootState) => state.history.status === 'loading';
export const selectHistorySuccess = (state: RootState) => state.history.status === 'success';
export const selectHistoryFailed = (state: RootState) => state.history.status === 'failed';
export const selectHistoryError = (state: RootState) => state.history.error;

export const selectHistory = (state: RootState) => state.history.history;

export default history.reducer;
