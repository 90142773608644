export const OutlinedBang = ({ dark = false }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
        >
            <path
                d="M15 29C22.732 29 29 22.732 29 15C29 7.26801 22.732 1 15 1C7.26801 1 1 7.26801 1 15C1 22.732 7.26801 29 15 29Z"
                stroke={dark ? '#34303D' : '#00D1D2'}
                stroke-width="1.16667"
            />
            <path
                d="M26.0806 15.0002C26.0806 21.1203 21.1193 26.0815 14.9993 26.0815C8.87923 26.0815 3.91797 21.1203 3.91797 15.0002C3.91797 8.88021 8.87923 3.91895 14.9993 3.91895C21.1193 3.91895 26.0806 8.88021 26.0806 15.0002Z"
                fill={dark ? '#00D1D2' : '#34303D'}
                stroke="#FFFDFB"
            />
            <path
                d="M15.0007 19.8843C14.4036 19.8843 13.9194 20.3684 13.9194 20.9656C13.9194 21.5628 14.4036 22.0469 15.0007 22.0469C15.5979 22.0469 16.082 21.5628 16.082 20.9656C16.082 20.3684 15.5979 19.8843 15.0007 19.8843Z"
                fill={dark ? '#34303D' : '#FFFDFB'}
            />
            <path
                d="M14.0748 17.1614C14.0748 17.6731 14.4896 18.0879 15.0013 18.0879C15.5129 18.0879 15.9277 17.6731 15.9277 17.1614L15.9277 9.13201C15.9277 8.62033 15.5129 8.20554 15.0013 8.20554C14.4896 8.20554 14.0748 8.62033 14.0748 9.13201L14.0748 17.1614Z"
                fill={dark ? '#34303D' : '#FFFDFB'}
            />
        </svg>
    );
};
