import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './AddPackCodes.module.scss';

// Helpers
import classnames from 'classnames';
import { useWindowSize } from '@utils/hooks/windowSize';

import cookies from '@utils/cookies';
import dayjs from '@utils/dayjs';
import { config } from '@utils/config';

// Assets
import codesImage from './assets/codesImage.png';
import whereCode from './assets/code.png';
import { BadgeIcon, ExtraChances } from '@assets/icons';
import failedCode from './assets/failedCode.svg';
import { ReactComponent as TooltipIcon } from '@containers/MGM/assets/tooltip-icon.svg';

// Components
import { Form, Input, InputRef, Tooltip } from 'antd';
import Button from '@components/Layout/Button';
import RewardBadge from '@components/RewardBadge';
import BaseModal from '@components/Layout/BaseModal';

// Redux
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { addCode, scrollToSection, setCode } from './AddPackCodesSlice';

// Tracking
import { TagEvents } from '@utils/GTM';
import { updateUserAsync } from '@slices/userSlice';

export const addPackCodesCookie = 'add-pack-code-number';

const AddPackCodes = () => {
    const dispatch = useAppDispatch();
    const windowSize = useWindowSize();
    const navigate = useNavigate();

    const inputRef = useRef<InputRef>(null);
    const isMobile = windowSize.width < 750;

    const scrollTo = () => {
        setTimeout(() => {
            let codesContainer = document.getElementById('addPackCodes');

            codesContainer?.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center'
            });
            dispatch(scrollToSection('noId'));
        }, 1000);
    };

    const [isActive, setIsActive] = useState<boolean>(false);
    const [isVisible, setIsVisible] = useState<boolean>(false);

    const { code, modalTitle, modalSubtitle, disclaimer, status, addNewCode, sectionId } =
        useAppSelector((state) => state.addPackCodes);

    useEffect(() => {
        if (sectionId === 'addPackCodes') {
            scrollTo();
            setIsActive(true);
        }
        return () => {
            dispatch(scrollToSection('noId'));
        };
    }, [dispatch, sectionId]);

    useEffect(() => {
        handleGetInsertedCodes();
    }, []);

    useEffect(() => {
        inputRef.current?.focus();
    }, [isActive]);

    //* Listen for Enter key to close "Enter another code" modal so every code can be inserted via keyboard only
    useEffect(() => {
        const enterCloseModal = (evt: any) => {
            if (evt.key === 'Enter' && isVisible) closeModalAndAddNewCode();
        };

        document.addEventListener('keydown', enterCloseModal);

        return () => {
            document.removeEventListener('keydown', enterCloseModal);
        };
    }, [isVisible]);

    const handleGetInsertedCodes = () => {
        const getNumberFromLocalStorage = cookies.read(addPackCodesCookie);

        if (getNumberFromLocalStorage) {
            return 30 - getNumberFromLocalStorage;
        } else return null;
    };

    const insertCode = async () => {
        await dispatch(addCode({ code: code })).then((response) => {
            dispatch(updateUserAsync());

            if (response.meta.requestStatus === 'fulfilled') {
                cookies.erase(addPackCodesCookie);
                cookies.createWithExpirationDate(
                    addPackCodesCookie,
                    Number(response.payload),
                    dayjs().endOf('month').toDate()
                );
            }
        });

        setIsVisible(true);
    };

    const closeModal = () => {
        dispatch(setCode(''));
        setIsActive(false);
        setIsVisible(false);
    };

    const closeModalAndAddNewCode = () => {
        dispatch(setCode(''));
        setIsVisible(false);
        inputRef.current?.select();
        scrollTo();
    };

    const navigateToCampaign = () => {
        closeModal();
        navigate(`${config.path.campaigns.liveTicketing}`);
    };

    const showBottomDisclaimer = () => {
        if (isActive) {
            if (handleGetInsertedCodes() === 30) {
                return (
                    <div className={classnames(styles.disclaimer, styles.red)}>
                        Ups! Ai atins limita maximă de coduri care pot fi introduse într-o lună.
                        Revino luna următoare.
                    </div>
                );
            } else
                return (
                    <div className={classnames(styles.disclaimer, isMobile && styles.fullWidth)}>
                        Poți introduce maximum 30 de coduri pe lună.
                    </div>
                );
        } else return null;
    };

    return (
        <section
            id="addPackCodes"
            className={classnames(styles.wrapper, isActive && styles.isActive)}
        >
            <div className={styles.thumbnail}>
                {!isMobile && <img src={codesImage} alt="img1" />}

                {showBottomDisclaimer()}
            </div>

            <div className={classnames(styles.details, isActive && styles.active)}>
                <div className={styles.icons}>
                    <RewardBadge points={10} absolute={false} backgroundColor="#FFFDFB" />

                    <div className={styles.badgeIcon}>
                        <BadgeIcon />
                    </div>
                </div>

                <h3 id="addPackCodesMobile">
                    Înregistrează codurile de pe pachetele HEETS, Fiit sau TEREA și câștigă FP!
                </h3>

                {!isActive ? (
                    <Button
                        type="text"
                        hover="dark"
                        color="turquoise"
                        label="Înregistrează codurile"
                        onClick={() => setIsActive(true)}
                        tagEvent={TagEvents.insertCode('')}
                    />
                ) : (
                    <>
                        {handleGetInsertedCodes() !== null ? (
                            <div
                                className={classnames(
                                    styles.remainingCodesDisclaimer,
                                    handleGetInsertedCodes() === 30 && styles.redDisclaimer
                                )}
                            >
                                <p className={styles.text}>
                                    {`Luna aceasta ai introdus ${handleGetInsertedCodes()} ${
                                        handleGetInsertedCodes() === 1 ? 'cod' : 'coduri'
                                    }.`}
                                </p>
                            </div>
                        ) : null}

                        <p>
                            Verifică pachetele pentru a introduce codul de 9 caractere în câmpul de
                            mai jos.
                        </p>

                        <Form.Item name="code">
                            <Input
                                value={code.toUpperCase()}
                                onInput={(ev) =>
                                    (ev.currentTarget.value = ev.currentTarget.value.toUpperCase())
                                }
                                onChange={(ev) => dispatch(setCode(ev.target.value.toUpperCase()))}
                                placeholder="Introdu codul"
                                color="gray"
                                maxLength={10}
                                autoFocus={!isActive}
                                ref={inputRef}
                                onPressEnter={() => (code.length >= 9 ? insertCode() : null)}
                            />

                            <span className={styles.warning}>
                                <Tooltip
                                    color={'#fffdfb'}
                                    trigger={['hover', 'click']}
                                    overlayClassName={styles.findCode}
                                    title={
                                        <>
                                            <img src={whereCode} alt="pack code" />
                                            <p>Codul se găsește în interiorul pachetului.</p>
                                        </>
                                    }
                                >
                                    <TooltipIcon />
                                </Tooltip>
                                Unde se găsește codul în pachet?
                            </span>
                        </Form.Item>

                        <Button
                            type="solid"
                            hover="turquoise"
                            color="dark"
                            loading={status === 'loading'}
                            label="Înregistrează codul"
                            disabled={code.length < 9 || status === 'loading'}
                            onClick={insertCode}
                        />
                    </>
                )}
            </div>

            <BaseModal
                size="normal"
                destroyOnClose
                closeModal={closeModal}
                isModalOpen={isVisible}
                className={styles.codesModal}
                lockBodyScroll={false}
            >
                <div className={styles.content}>
                    {status === 'failed' && <img src={failedCode} alt="failedCode" />}
                    {status === 'success' && (
                        <div className={styles.successIcons}>
                            <RewardBadge absolute={false} points={10} />

                            <div className={styles.badgeIcon}><BadgeIcon /></div>
                        </div>
                    )}

                    <h2 className={classnames(status === 'success' && styles.order)}>
                        {modalTitle}
                    </h2>

                    {modalSubtitle.length > 0 && (
                        <p dangerouslySetInnerHTML={{ __html: modalSubtitle }} />
                    )}

                    {disclaimer.length > 0 && (
                        <div
                            className={classnames(
                                styles.disclaimer,
                                status === 'failed' && styles.red,
                                status === 'success' && styles.successStyle
                            )}
                            dangerouslySetInnerHTML={{ __html: disclaimer }}
                        />
                    )}

                    {addNewCode && (
                        <Button
                            type="solid"
                            hover="turquoise"
                            color="dark"
                            loading={status === 'loading'}
                            label="Introdu alt cod"
                            onClick={closeModalAndAddNewCode}
                        />
                    )}

                    {status === 'success' && (
                        <div className={styles.bordered}>
                            <p>
                                Vrei să câștigi o invitație dublă la evenimentul aniversar IQOS sau
                                500 de alte premii?
                            </p>

                            <Button
                                onClick={() => navigateToCampaign()}
                                type="text"
                                color="turquoise"
                                className={styles.successButton}
                            >
                                Înscrie-te acum
                            </Button>
                        </div>
                    )}
                </div>
            </BaseModal>
        </section>
    );
};

export default AddPackCodes;
