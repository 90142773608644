export const ContestIcon = () => {
    return (
        <svg
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M17 33.4546C25.8366 33.4546 33 26.2911 33 17.4546C33 8.61803 25.8366 1.45459 17 1.45459C8.16344 1.45459 1 8.61803 1 17.4546C1 26.2911 8.16344 33.4546 17 33.4546Z"
                stroke="#34303D"
            />
            <path
                d="M17 3.69455C20.6494 3.69455 24.1493 5.14426 26.7298 7.72476C29.3103 10.3053 30.76 13.8052 30.76 17.4545C30.76 21.1039 29.3103 24.6038 26.7298 27.1843C24.1493 29.7648 20.6494 31.2145 17 31.2145V3.69455Z"
                fill="#00D1D2"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.5448 19.117L24.5438 24.124L21.5448 23.726L20.3901 26.5222L17.3258 21.3159"
                fill="#F6F4F0"
            />
            <path
                d="M21.5448 19.117L24.5438 24.124L21.5448 23.726L20.3901 26.5222L17.3258 21.3159"
                stroke="#34303D"
                strokeLinejoin="round"
            />
            <path
                d="M11.3618 18.925L8.49889 24.1247L11.4963 23.7258L12.6511 26.522L15.8247 21.4237"
                stroke="#34303D"
                strokeLinejoin="round"
            />
            <path
                d="M16.466 21.3189C20.2136 21.3189 23.2517 18.2808 23.2517 14.5332C23.2517 10.7856 20.2136 7.74756 16.466 7.74756C12.7184 7.74756 9.68036 10.7856 9.68036 14.5332C9.68036 18.2808 12.7184 21.3189 16.466 21.3189Z"
                fill="#FFFDFB"
                stroke="#34303D"
            />
            <path
                d="M13.4141 13.5351H19.5175M14.9408 15.7154H17.9925H14.9408Z"
                stroke="#34303D"
                strokeLinecap="round"
            />
        </svg>
    );
};
