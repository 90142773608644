export const PWAReferralIcon = () => {
    return (
        <svg
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M30.313 17.6969C32.4655 16.9386 34.7032 17.2827 36.4684 18.3876C38.2312 19.4924 39.518 21.3544 39.777 23.6236C40.0314 25.8589 39.3967 27.8329 37.8287 29.4254C36.8697 30.3972 35.6412 31.6339 34.142 33.1365L31.8192 35.4594C31.1717 36.1057 30.488 36.7882 29.7659 37.5057L28.1314 39.1344L26.4817 37.515C25.6044 36.6494 24.8554 35.9086 24.2335 35.2867L23.7925 34.8457C23.5114 34.5645 23.2617 34.3137 23.0459 34.0932L22.291 33.3279C20.9879 32.0061 19.6835 30.6854 18.4095 29.3344C16.5709 27.3884 15.9735 25.0469 16.7004 22.4662C17.0714 21.1502 17.7177 20.0862 18.5822 19.2602C19.4479 18.4342 20.5422 17.8392 21.8722 17.5277C24.111 17.0027 26.134 17.4752 27.8852 18.8554L27.9972 18.9487L28.2632 18.7644C28.8395 18.3747 29.3855 18.0667 29.963 17.8299L30.313 17.6969Z"
                fill="#00D1D2"
                stroke="#00D1D2"
                strokeWidth="4.66667"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M28.1006 35.8536C28.1402 35.8233 28.1624 35.8104 28.1799 35.7929C30.8434 33.1271 33.5197 30.4729 36.1657 27.7873C37.2204 26.7163 37.6288 25.3851 37.4584 23.8871C37.1084 20.8316 33.9841 18.8786 31.0872 19.8971C30.3522 20.1561 29.7222 20.5726 29.1739 21.1198L28.0457 22.2538C27.6176 21.8186 27.2291 21.3881 26.8021 20.9973C25.5456 19.8481 24.0662 19.4094 22.4061 19.8003C20.6269 20.2168 19.4427 21.3391 18.9457 23.0996C18.4557 24.8414 18.8652 26.4199 20.1054 27.7313C21.6139 29.3296 23.1656 30.8894 24.7079 32.4563C25.8279 33.5926 26.9607 34.7138 28.0994 35.8524L28.1006 35.8536Z"
                stroke="#34303D"
                strokeWidth="2.33333"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M37.777 8.3065C40.1348 8.415 41.4158 8.80817 42.2687 9.14067C43.398 9.57934 44.203 10.1032 45.05 10.9502C45.897 11.7972 46.4208 12.6022 46.8595 13.7315C47.1908 14.5843 47.5852 15.8653 47.6925 18.2232C47.8092 20.7747 47.8337 21.54 47.8337 27.9998C47.8337 34.4597 47.8092 35.225 47.6937 37.7765C47.5852 40.1343 47.192 41.4153 46.8595 42.2682C46.4208 43.3975 45.897 44.2025 45.05 45.0495C44.203 45.8965 43.398 46.4203 42.2687 46.859C41.4158 47.1903 40.1348 47.5847 37.777 47.692C35.2255 47.8087 34.4602 47.8332 28.0003 47.8332C21.5405 47.8332 20.7752 47.8087 18.2237 47.6932C15.8658 47.5847 14.5848 47.1915 13.732 46.859C12.6027 46.4203 11.7977 45.8965 10.9507 45.0495C10.1037 44.2025 9.57983 43.3975 9.14116 42.2682C8.80866 41.4153 8.41549 40.1332 8.30699 37.7765C8.19033 35.2238 8.16699 34.4585 8.16699 27.9998C8.16699 21.54 8.19149 20.7747 8.30699 18.2232C8.41666 15.8665 8.80866 14.5855 9.14233 13.7315C9.58099 12.6022 10.1048 11.7972 10.9518 10.9502C11.7988 10.1032 12.6027 9.57934 13.732 9.14067C14.5848 8.80817 15.867 8.415 18.2237 8.3065C20.7763 8.18984 21.5417 8.1665 28.0003 8.1665C34.4602 8.1665 35.2255 8.191 37.777 8.3065V8.3065Z"
                stroke="white"
                strokeWidth="2.33333"
            />
        </svg>
    );
};
