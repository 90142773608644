import { useEffect, useState, useLayoutEffect } from 'react';

type UseLockedBodyOutput = [boolean, (locked: boolean) => void];

function useLockedBody(initialLocked = false, rootId = 'root'): UseLockedBodyOutput {
    const [locked, setLocked] = useState(initialLocked);

    // Do the side effect before render
    useLayoutEffect(() => {
        if (!locked) {
            return;
        }

        // Save initial body style
        const originalOverflow = document.body.style.overflow;
        const originalPaddingRight = document.body.style.paddingRight;
        const originalTouchAction = document.body.style.touchAction;
        const originalPositiond = document.body.style.position;

        // Lock body scroll
        document.body.style.overflow = 'hidden';
        document.body.style.touchAction = 'none';
        document.body.style.position = 'fixed';

        // Get the scrollBar width
        const root = document.getElementById(rootId); // or root
        const scrollBarWidth = root ? root.offsetWidth - root.scrollWidth : 0;

        // Avoid width reflow
        if (scrollBarWidth) {
            document.body.style.paddingRight = `${scrollBarWidth}px`;
        }

        return () => {
            document.body.style.overflow = originalOverflow;
            document.body.style.touchAction = originalTouchAction;
            document.body.style.position = originalPositiond;
            if (scrollBarWidth) {
                document.body.style.paddingRight = originalPaddingRight;
            }
        };
    }, [locked]);

    // Update state if initialValue changes
    useEffect(() => {
        if (locked !== initialLocked) {
            setLocked(initialLocked);
        }
    }, [initialLocked]);

    return [locked, setLocked];
}

export default useLockedBody;
