import { useEffect, useState } from 'react';
import dayjs from '@utils/dayjs';
import useToggle from './useToggle';

const useCountdown = (
    startValue: number,
    format: string,
    onEnd: () => void
): [string, () => void] => {
    const [remainingTime, setRemainingTime] = useState(startValue);
    const [isPaused, togglePaused] = useToggle(false);

    useEffect(() => {
        let interval: number;

        if (!isPaused) {
            interval = window.setInterval(() => {
                setRemainingTime((prevTime) => {
                    const updatedTime = prevTime - 1000;

                    if (updatedTime <= 0) {
                        clearInterval(interval);
                        onEnd();
                        return 0;
                    }

                    return updatedTime;
                });
            }, 1000);
        }

        return () => clearInterval(interval);
    }, [isPaused]);

    return [dayjs.duration(remainingTime).format(format), togglePaused];
};

export default useCountdown;
