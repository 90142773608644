import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosRequest from '@utils/axios';
import { Stages } from '@containers/Campaigns/RealPeopleRealConnections/progressData';
import { RootState } from '@app/store';

interface AxiosError {
    response: {
        data: {
            message: string;
        };
    };
}

export interface Video {
    id: number;
    name: string;
    slug: string;
    start_date: string;
    end_date: string;
    activity_campaign_id: number;
    forward_points: number | null;
    properties: string | null;
    created_at: string;
    updated_at: string | null;
    deleted_at: string | null;
    current_video: boolean;
    games_played: number;
    has_seen_video: boolean;
}

export interface InitialState {
    status: 'idle' | 'success' | 'loading' | 'failed';
    videoStatus: 'idle' | 'success' | 'loading' | 'failed';
    deviceStatus: 'idle' | 'success' | 'loading' | 'failed';
    chancesStatus: 'idle' | 'success' | 'loading' | 'failed';
    videos: Video[];
    current_video: Video | null;
    current_game_slug: string | null;
    has_played_today: boolean;
    has_played_at_least_one_game: boolean;
    has_voted_today: boolean;
    selected_device: string | null;
    error: any;
    userVotesDataAndEligibility: {
        properties: string[];
    };
    activeExperiences: string[];
    maxExperiences: 3;
    userHasVoted: boolean;
    voteId: number;
    chances: null | {
        final: { chances: number; eligible: number };
        weekly: { chances: number; eligible: { forDevices: number; forExperiences: number } };
    };
    votes: null | { ownVotes: number; totalVotes: number };
    topExperiencesVotes: null | Array<{
        value: string;
        votes: number;
    }>;
    chancesFromBRModalisOpen: boolean;
}

const initialState: InitialState = {
    status: 'idle',
    videoStatus: 'idle',
    deviceStatus: 'idle',
    chancesStatus: 'idle',
    videos: [],
    current_video: null,
    current_game_slug: null,
    has_played_today: false,
    has_played_at_least_one_game: false,
    has_voted_today: false,
    selected_device: null,
    error: {},
    userVotesDataAndEligibility: {
        properties: []
    },
    activeExperiences: [],
    maxExperiences: 3,
    userHasVoted: false,
    voteId: 0,
    chances: null,
    votes: null,
    topExperiencesVotes: null,
    chancesFromBRModalisOpen: false
};

const updateConnectionsFromTemplate = (connections: any) => {
    return connections.map((connection: any) => {
        const template = Stages.filter((stage: any) => stage.campaign_slug === connection.slug)[0];

        return {
            ...connection,
            ...template
        };
    });
};

export const getStages = createAsyncThunk('realPeopleRealConnections/getStages', async () => {
    try {
        const stages = await axiosRequest.get('/real-connection/status');

        const connectionsUpdated = updateConnectionsFromTemplate(stages.data.videos);

        return {
            ...stages.data,
            videos: connectionsUpdated
        };
    } catch (e) {
        return (e as AxiosError).response.data;
    }
});

export const getUserEligibilityToVoteAndVotesData = createAsyncThunk(
    'realPeopleRealConnections/getUserEligibilityToVoteAndVotesData',
    async () => {
        try {
            const response = await axiosRequest.get('/real-connection/current/vote');
            return response.data;
        } catch (e) {
            return (e as AxiosError).response.data;
        }
    }
);

export const getTopExperiencesVotes = createAsyncThunk(
    'realPeopleRealConnections/getTopExperiencesVotes',
    async () => {
        try {
            const response = await axiosRequest.get('real-connection/votes');
            return response.data;
        } catch (e) {
            // console.log((e as AxiosError).response.data);
            return (e as AxiosError).response.data;
        }
    }
);

export const setUserVotes = createAsyncThunk(
    'realPeopleRealConnections/setUserVotes',
    async (values: { values: string[] }, { rejectWithValue, getState }) => {
        try {
            const { realPeopleRealConnections } = getState() as RootState;
            const response = await axiosRequest.post(`/real-connection/store/vote`, {
                vote_id: realPeopleRealConnections.voteId,
                selections: values.values
            });
            return response.data;
        } catch (e) {
            return rejectWithValue((e as AxiosError).response.data);
        }
    }
);

export const seenVideo = createAsyncThunk(
    'realPeopleRealConnections/seenVideo',
    async (gameId: number) => {
        try {
            const seenVideo = await axiosRequest.post('/real-connection/store/seen-video', {
                game_id: gameId
            });

            return seenVideo.data;
        } catch (e) {
            return (e as AxiosError).response.data;
        }
    }
);

export const selectDevice = createAsyncThunk(
    'realPeopleRealConnections/selectDevice',
    async (device: string) => {
        try {
            const seenVideo = await axiosRequest.post('/real-connection/store/selected-device', {
                device: device
            });

            return seenVideo.data;
        } catch (e) {
            return (e as AxiosError).response.data;
        }
    }
);

export const getChances = createAsyncThunk('realPeopleRealConnections/getChances', async () => {
    try {
        const chances = await axiosRequest.get(`/real-connection/telcor-status`);

        return chances.data.response;
    } catch (e) {
        return (e as AxiosError).response.data;
    }
});

export const realPeopleRealConnectionsSlice = createSlice({
    name: 'realPeopleRealConnections',
    initialState,
    reducers: {
        setDeviceSelected: (state, action) => {
            state.selected_device = action.payload;
        },
        setHasSeenVideo: (state, action) => {
            if (state.current_video) {
                state.current_video.has_seen_video = action.payload;
            }
        },

        setActiveExperiences: (state, action) => {
            const selectedExperience = action.payload;

            const activeExperiences = [...state.activeExperiences];

            const index = activeExperiences.indexOf(selectedExperience);

            if (index !== -1) {
                // If the item exists, remove it
                activeExperiences.splice(index, 1);
                state.activeExperiences = activeExperiences;
            } else {
                // If the item doesn't exist and we're below limit, push it to the array
                if (activeExperiences.length < state.maxExperiences) {
                    state.activeExperiences = [...activeExperiences, selectedExperience];
                }
            }
        },

        toggleChancesFromBRModal: (state, action) => {
            state.chancesFromBRModalisOpen = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder

            // Get stages data
            .addCase(getStages.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getStages.fulfilled, (state, action) => {
                state.status = 'idle';
                state.videos = action.payload.videos;
                state.has_played_today = action.payload.has_played_today;
                state.selected_device = action.payload.selected_device;
                state.has_played_at_least_one_game = action.payload.has_played_at_least_one_game;
                state.has_voted_today = action.payload.has_voted_today;
                state.current_game_slug = action.payload.current_game_slug;

                let currentVideo = [...action.payload.videos].filter(
                    (video: Video) => video.current_video
                )[0];

                state.current_video = currentVideo;
            })
            .addCase(getStages.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })

            // Seen video

            .addCase(seenVideo.pending, (state) => {
                state.videoStatus = 'loading';
            })
            .addCase(seenVideo.fulfilled, (state, action) => {
                state.videoStatus = 'idle';

                if (state.current_video) {
                    state.current_video = { ...state.current_video, has_seen_video: true };
                }
            })
            .addCase(seenVideo.rejected, (state, action) => {
                state.videoStatus = 'failed';
                state.error = action.payload;
            })

            // Select Device

            .addCase(selectDevice.pending, (state) => {
                state.deviceStatus = 'loading';
            })

            .addCase(selectDevice.fulfilled, (state) => {
                state.deviceStatus = 'idle';
            })

            .addCase(selectDevice.rejected, (state) => {
                state.deviceStatus = 'failed';
            })

            // User is eligible to vote and votes data
            .addCase(getUserEligibilityToVoteAndVotesData.pending, (state) => {
                state.status = 'loading';
            })

            .addCase(getUserEligibilityToVoteAndVotesData.fulfilled, (state, action) => {
                state.status = 'idle';
                state.userVotesDataAndEligibility = action.payload;
                state.voteId = action.payload.id;
            })

            .addCase(getUserEligibilityToVoteAndVotesData.rejected, (state) => {
                state.status = 'failed';
            })

            // Get top votes for top experiences

            .addCase(getTopExperiencesVotes.pending, (state) => {
                state.status = 'loading';
            })

            .addCase(getTopExperiencesVotes.fulfilled, (state, action) => {
                state.status = 'idle';
                state.topExperiencesVotes = action.payload;
            })

            .addCase(getTopExperiencesVotes.rejected, (state) => {
                state.status = 'failed';
            })

            // Send user votes

            .addCase(setUserVotes.pending, (state) => {
                state.status = 'loading';
                state.userHasVoted = false;
            })

            .addCase(setUserVotes.fulfilled, (state) => {
                state.status = 'idle';
                state.userHasVoted = true;
            })

            .addCase(setUserVotes.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
                state.userHasVoted = false;
            })

            // Telcor Chances

            .addCase(getChances.pending, (state) => {
                state.chancesStatus = 'loading';
            })

            .addCase(getChances.fulfilled, (state, action) => {
                state.chancesStatus = 'idle';
                state.chances = action.payload.luckyDrawChances;
                state.votes = action.payload.votes;
            })

            .addCase(getChances.rejected, (state, action) => {
                state.chancesStatus = 'failed';
                console.log('chances', action.payload);
            });
    }
});

export const resetState = createAction('REVERT_ALL');

export const { setDeviceSelected, setHasSeenVideo, setActiveExperiences, toggleChancesFromBRModal } =
    realPeopleRealConnectionsSlice.actions;

export default realPeopleRealConnectionsSlice.reducer;
