import slide1 from './assets/slide2/slide1.jpg';
import slide2 from './assets/slide2/slide2.jpg';
import slide3 from './assets/slide2/slide3.jpg';
import slide4 from './assets/slide2/slide4.jpg';
import slide5 from './assets/slide2/slide5.jpg';
import slide6 from './assets/slide2/slide6.jpg';
import slide7 from './assets/slide2/slide7.jpg';
import slide8 from './assets/slide2/slide8.jpg';
import slide9 from './assets/slide2/slide9.jpg';
import slide10 from './assets/slide2/slide10.jpg';
import slide11 from './assets/slide2/slide11.jpg';
import slide12 from './assets/slide2/slide12.jpg';
import slide13 from './assets/slide2/slide13.jpg';
import slide14 from './assets/slide2/slide14.jpg';
import slide15 from './assets/slide2/slide15.jpg';

import slide21 from './assets/slide1/slide1.jpg';
import slide22 from './assets/slide1/slide2.jpg';
import slide23 from './assets/slide1/slide3.jpg';
import slide24 from './assets/slide1/slide4.jpg';

export const IqosKirunaVideoSliderData: Array<{
    id: number;
    videoId: string;
    subtitle: string;
    title: string;
    videoThumbnail: string;
}> = [
    {
        id: 1,
        videoId: '920032766',
        title: 'Nutti Sámi Siida, prima întâlnire cu indigenii',
        subtitle:
            'Prima aventură a avut loc într-o tabără indigenă, unde câștigătorii au avut șansa să descopere obiceiurile tribului Sámi. S-au plimbat cu sania trasă de reni și au învățat să prepare câteva rețete autentice.',
        videoThumbnail: slide21
    },
    {
        id: 2,
        videoId: '920032809',
        title: 'Călătorie cu sania trasă de câini',
        subtitle:
            'Câștigătorii noștri au bifat și această experiență în Kiruna. Mai mult, s-au bucurat de un tur ghidat al sculpturilor de gheață din Ice Hotel.',
        videoThumbnail: slide22
    },
    {
        id: 3,
        videoId: '920033131',
        title: 'Pescuitul la copcă, o experiență unică',
        subtitle:
            'Călătoria în Kiruna nu ar fi fost completă fără o sesiune de pescuit la copcă în tabăra Luspen. Câștigătorii au avut apoi ocazia să savureze un prânz tradițional în jurul focului de tabără.',
        videoThumbnail: slide23
    },
    {
        id: 4,
        videoId: '920033266',
        title: 'Ospitalitatea la rang de artă, la Ice Hotel',
        subtitle:
            'Ice Hotel este o operă de artă în gheață, reconstruită în fiecare iarnă de artiști pasionați. Câștigătorii s-au distrat la workshopul de sculptură în gheață și au avut parte de o experiență de fine dining specială, inspirată de cultura nordică.',
        videoThumbnail: slide24
    }
];

export const IqosKirunaImgSliderData: Array<{
    id: number;
    image: string;
}> = [
    {
        id: 1,
        image: slide1
    },
    {
        id: 2,
        image: slide2
    },
    {
        id: 3,
        image: slide3
    },
    {
        id: 4,
        image: slide4
    },
    {
        id: 5,
        image: slide5
    },
    {
        id: 6,
        image: slide6
    },
    {
        id: 7,
        image: slide7
    },
    {
        id: 8,
        image: slide8
    },
    {
        id: 9,
        image: slide9
    },
    {
        id: 10,
        image: slide10
    },
    {
        id: 11,
        image: slide11
    },
    {
        id: 12,
        image: slide12
    },
    {
        id: 13,
        image: slide13
    },
    {
        id: 14,
        image: slide14
    },
    {
        id: 15,
        image: slide15
    }
];
