import React, { useEffect, useState } from 'react';
import styles from './ArticleHeader.module.scss';
import { IArticleHeaderProps } from './interface';

// Helpers
import classnames from 'classnames';
import { useWindowSize } from '@utils/hooks/windowSize';

// Assets
import { BadgeIcon, CardIconGift, ExtraChances, PlayIcon } from '@assets/icons';
import { HiHeart, HiOutlineHeart } from 'react-icons/hi';

// Components
import Vimeo from '@u-wave/react-vimeo';
import KeenSlider from '@components/KeenSlider';
import RewardBadge from '@components/RewardBadge';
import BaseModal from '@components/Layout/BaseModal';

// Redux
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { likeArticle } from '@containers/IQOSUniverse/IQOSUniverseSlice';
import { getQuiz } from '@components/Quiz/QuizSlice';
import dayjs from '@utils/dayjs';

const ArticleHeader = ({
    showSubtitle,
    hideTitle,
    isSlider = false,
    sliderImages,
    notFullWidth = false,
    backgroundColor,
    mobileVideo,
    noModalVideo,
    videoQuiz,
    showPlayIcon,
    hideTopVideoQuiz = false,
    showBadges = true,
    showHardcodedSubtitle,
    hardcodedSubtitle,
    whiteBorderLikeButton = false,
    isCustomPhotoDimension = false,
    classnameImage
}: IArticleHeaderProps) => {
    const window = useWindowSize();
    const dispatch = useAppDispatch();

    const isMobile = window.width < 460;

    const { article } = useAppSelector((state) => state.iqosUniverse);
    const is_completed = useAppSelector((state) => state.quiz?.quizQuestions?.is_completed);
    const quiz = useAppSelector((state) => state.quiz?.quizQuestions?.quiz);

    const [isLiked, setIsLiked] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        if (article?.quiz_slug) {
            dispatch(getQuiz({ slug: article?.quiz_slug || '', statistics: false }));
        }
    }, [article?.quiz_slug]);

    useEffect(() => {
        if (article.liked) {
            setIsLiked(article.liked);
        }
    }, [article]);

    const handleLike = () => {
        setIsLiked(!isLiked);
        dispatch(likeArticle({ slug: article.slug }));
    };

    return (
        <section className={styles.wrapper}>
            <div className={styles.content}>
                {hideTitle ? null : <h1>{article.title}</h1>}
                {showSubtitle && article.subtitle && (
                    <h2 dangerouslySetInnerHTML={{ __html: article.subtitle }} />
                )}
                {showHardcodedSubtitle && <h2>{hardcodedSubtitle}</h2>}
            </div>

            <div
                style={{ backgroundColor: backgroundColor }}
                className={classnames(
                    classnameImage,
                    styles.image,
                    notFullWidth && styles.notFullWidth,
                    showPlayIcon && styles.cursorPointer
                )}
            >
                {showPlayIcon && (
                    <div className={styles.playIconButton}>
                        <PlayIcon />
                    </div>
                )}

                {article.video_link && !hideTopVideoQuiz ? (
                    noModalVideo ? (
                        !isMobile ? (
                            <Vimeo
                                autoplay={true}
                                loop={true}
                                playsInline={true}
                                muted={true}
                                width={'100%'}
                                height={'100%'}
                                responsive={true}
                                className={styles.videoPlayer}
                                video={article.video_link}
                            />
                        ) : isMobile && mobileVideo ? (
                            <Vimeo
                                autoplay={true}
                                loop={true}
                                playsInline={true}
                                muted={true}
                                width={'100%'}
                                height={'100%'}
                                responsive={true}
                                className={styles.videoPlayer}
                                video={mobileVideo}
                            />
                        ) : null
                    ) : (
                        <div
                            className={styles.isVideo}
                            onClick={videoQuiz ? videoQuiz : () => setIsModalOpen(true)}
                        >
                            <PlayIcon />

                            <img
                                src={
                                    isMobile && article.picture_mobile
                                        ? article.picture_mobile
                                        : article.picture
                                }
                                alt={article.slug}
                                onClick={videoQuiz ? videoQuiz : () => setIsModalOpen(true)}
                            />
                        </div>
                    )
                ) : isSlider ? (
                    <div className={styles.slider}>
                        <KeenSlider className={styles.headerSlide} withArrows={true}>
                            {sliderImages?.map((i: string) => {
                                return <img src={i} alt={i} />;
                            })}
                        </KeenSlider>
                    </div>
                ) : (
                    <img
                        className={classnames(isCustomPhotoDimension && styles.customDimension)}
                        src={
                            isMobile && article.picture_mobile
                                ? article.picture_mobile
                                : article.picture
                        }
                        alt={article.slug}
                    />
                )}

                {!article.completed_quiz && !is_completed && showBadges ? (
                    <div className={styles.badges}>
                        <RewardBadge
                            className={styles.rewardBadge}
                            absolute={false}
                            points={null}
                            voucher={article?.badges?.includes('VOUCHER')}
                        />

                        <RewardBadge
                            className={styles.rewardBadge}
                            absolute={false}
                            points={null}
                            offer={article?.badges?.includes('DISCOUNT')}
                        />

                        <RewardBadge
                            className={styles.rewardBadge}
                            absolute={false}
                            points={null}
                            contest={article?.badges?.includes('CONTEST')}
                        />

                        {!isSlider
                            ? dayjs(quiz?.end_date).isAfter(dayjs()) && (
                                  <RewardBadge
                                      absolute={false}
                                      points={article.quiz_points || article.points}
                                  />
                              )
                            : dayjs(quiz?.end_date).isAfter(dayjs()) && (
                                  <RewardBadge
                                      absolute={false}
                                      absoluteForSlider={false}
                                      points={article.quiz_points || article.points}
                                  />
                              )}

                        {dayjs(quiz?.end_date).isAfter(dayjs()) && quiz?.chances ? (
                            <div className={styles.badgeIcon}>
                                <ExtraChances />
                            </div>
                        ) : null}

                        {article?.badges?.includes('BADGE') ? (
                            <div className={styles.badgeIcon}>
                                <BadgeIcon />
                            </div>
                        ) : null}

                        {article?.badges?.includes('GIFT') && (
                            <div className={styles.giftIcon}>
                                <CardIconGift />
                            </div>
                        )}
                    </div>
                ) : null}

                {!showPlayIcon && (
                    <div
                        onClick={handleLike}
                        className={classnames(
                            styles.likeButton,
                            isLiked && styles.liked,
                            isSlider && styles.sliderPosition,
                            whiteBorderLikeButton && styles.whiteBorder
                        )}
                    >
                        {article.liked || isLiked ? <HiHeart /> : <HiOutlineHeart />}
                    </div>
                )}
            </div>

            <BaseModal
                size="large"
                maskClosable={true}
                isModalOpen={isModalOpen}
                className={styles.videoModal}
                closeModal={() => setIsModalOpen(false)}
                destroyOnClose={true}
            >
                <Vimeo
                    autoplay={false}
                    responsive={true}
                    className={styles.player}
                    video={mobileVideo && isMobile ? mobileVideo : article.video_link}
                    onEnd={() => setIsModalOpen(false)}
                />
            </BaseModal>
        </section>
    );
};

export default ArticleHeader;
