export const PWAMonthlyChallengeIconInactive = () => {
    return (
        <svg
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M45.2527 8.16663C46.4042 8.16663 47.3492 9.07896 47.4402 10.2433L47.4472 10.4183L47.5008 28.3476L45.1675 28.3558L45.1162 11.1521C45.115 10.8313 44.8537 10.5711 44.5328 10.5711H9.91667C9.59467 10.5711 9.33333 10.8313 9.33333 11.1545V39.7343C9.33333 40.0563 9.59467 40.3176 9.91667 40.3176H23.485V42.7221H9.19333C8.0395 42.7221 7.09333 41.8051 7.00583 40.6396L7 40.4635V10.962C7 9.75446 7.8015 8.29963 9.02533 8.17479L9.1945 8.16663H45.2527Z"
                fill="#8C8A90"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M42.1747 12.2943C42.8187 12.2943 43.3414 12.817 43.3414 13.461V24.1231C41.0396 21.819 37.8849 20.398 34.4059 20.398C27.3569 20.398 21.6426 26.2313 21.6426 33.4261C21.6426 35.189 21.9856 36.8713 22.6074 38.4055H12.3932C11.7481 38.4055 11.2266 37.8828 11.2266 37.2388V13.4621C11.2266 12.817 11.7481 12.2955 12.3932 12.2955H42.1747V12.2943Z"
                fill="#C4C3C6"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M47.3599 33.6596C47.3599 41.1029 41.607 47.1358 34.509 47.1358C27.4122 47.1358 21.6582 41.1029 21.6582 33.6596C21.6582 26.2163 27.4122 20.1823 34.509 20.1823C41.6082 20.1823 47.361 26.2163 47.361 33.6596H47.3599Z"
                stroke="#8C8A90"
                strokeWidth="2.33333"
                strokeLinejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M34.3124 37.3825C34.4349 37.3184 34.5807 37.3184 34.7044 37.3825L38.6746 39.4569L37.9186 35.0737C37.8941 34.9337 37.9407 34.789 38.0422 34.691L41.2424 31.5924L36.8149 30.953C36.6761 30.9332 36.5571 30.8469 36.4941 30.7197L34.5084 26.7239L32.5227 30.7209C32.4597 30.8469 32.3407 30.9332 32.2019 30.9542L27.7744 31.5924L30.9734 34.691C31.0761 34.789 31.1227 34.9337 31.0982 35.0737L30.3422 39.4569L34.3124 37.3825Z"
                stroke="#8C8A90"
                strokeWidth="2.33333"
                strokeLinejoin="round"
            />
            <path
                d="M18.8117 15.0033H15.4552C14.5248 15.0033 13.7705 15.7575 13.7705 16.688C13.7705 17.6184 14.5248 18.3726 15.4552 18.3726H18.8117C19.7421 18.3726 20.4963 17.6184 20.4963 16.688C20.4963 15.7575 19.7421 15.0033 18.8117 15.0033Z"
                fill="#34303D"
            />
        </svg>
    );
};
