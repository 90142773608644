import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axiosRequest from '@utils/axios';

export interface UserDevicesState {
    status: 'idle' | 'loading' | 'failed';
    devices: Array<Object>;
    primaryDevice: {
        id: number,
        codentifyId: string,
        name: string,
        image: string,
        description: string,
        purchasedOn: string,
        registeredOn: string,
        warrantyEnd: string
    };
    error: any;
}

interface AxiosError {
    response: {
        data: {
            message: string;
        };
    };
}

const initialState: UserDevicesState = {
    status: 'idle',
    devices: [],
    primaryDevice: {
        id: 0,
        codentifyId: '',
        name: '',
        image: '',
        description: '',
        purchasedOn: '',
        registeredOn: '',
        warrantyEnd: ''
    },
    error: {}
};

export const getUserDevices = createAsyncThunk('profile/userDevices', async () => {
    try {
        const response = await axiosRequest.get('/user/devices').then((res) => {
            return res;
        });

        return response.data.data;
    } catch (e) {
        return (e as AxiosError).response.data.message;
    }
});

export const userDevicesSlice = createSlice({
    name: 'userDevices',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getUserDevices.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getUserDevices.fulfilled, (state, action) => {
                state.status = 'idle';
                state.devices = action.payload;
                state.primaryDevice = action.payload[0];
            })
            .addCase(getUserDevices.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    }
});

export default userDevicesSlice.reducer;
