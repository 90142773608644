import React, { useEffect } from 'react';
import styles from './RealPeopleRealConnections.module.scss';

import classnames from 'classnames';
import { useWindowSize } from '@utils/hooks/windowSize';
import { dailyPrizes, WeeklyPrizes } from './progressData';

// Components
import DateBadge from '@components/DateBadge';
import HeroSlider from '@components/HeroSlider';
import Progress from './Components/Progress';
import Dashboard from './Components/Dashboard';
import KeenSlider from '@components/KeenSlider';
import BaseLoader from '@components/Layout/BaseLoader';
import ChancesFromBRModal from './Components/ChancesFromBRModal';

// Assets
import BigPrize from './assets/big-prize.gif';
import headerSignature from './assets/signature.png';
import endedCampaignDesktop from './assets/endCampaignDesktop.png';
import endedCampaignMobile from './assets/endCampaignMobile.png';

// Redux
import { useAppDispatch, useAppSelector } from '@app/hooks';
import {
    getChances,
    getStages
} from '@containers/Campaigns/RealPeopleRealConnections/realPeopleRealConnectionsSlice';

import WinnersSection from '@containers/Campaigns/RealPeopleRealConnections/Components/WinnersSection';
import { scroller } from 'react-scroll';
import { config } from '@utils/config';
import Button from '@components/Layout/Button';

const RealPeopleRealConnections = () => {
    const dispatch = useAppDispatch();

    const window = useWindowSize();
    const isMini = window.width <= 650;
    const isMobile = window.width <= 1024;

    const {
        current_video,

        chancesStatus
    } = useAppSelector((state) => state.realPeopleRealConnections);

    const scrollToWinners = () => {
        scroller.scrollTo('winners-real-people', { smooth: true, duration: 500, offset: -130 });
    };

    const heroSlideEndedCampaign = [
        {
            id: 1,
            postTitle: DateBadge({ date: '5 februarie - 14 aprilie' }),
            title: `Oameni reali. Legături reale.`,
            subtitle: `Concursul s-a încheiat și toți câștigătorii premiilor au fost desemnați și anunțați.`,
            text_mode: 'light',
            background_color: '#34303d',
            vimeoLink: 909061205,
            cta_text: 'Vezi câștigătorii',
            cta_link: scrollToWinners,
            button_properties: {
                type: 'text',
                color: 'light',
                hover: 'turquoise'
            }
        },
        {
            id: 2,
            postTitle: DateBadge({ date: '5 februarie - 14 aprilie' }),
            title: `Oameni reali. Legături reale.`,
            subtitle: `Concursul s-a încheiat și toți câștigătorii premiilor au fost desemnați și anunțați.`,
            text_mode: 'dark',
            background_color: '#00D1D2',
            picture: isMobile ? endedCampaignMobile : endedCampaignDesktop,
            cta_text: 'Vezi câștigătorii',
            cta_link: scrollToWinners,
            button_properties: {
                type: 'text',
                color: 'dark',
                hover: 'light'
            }
        }
    ];

    useEffect(() => {
        dispatch(getStages());
    }, [current_video?.has_seen_video]);

    useEffect(() => {
        dispatch(getChances());
    }, []);

    if (chancesStatus === 'loading') {
        return <BaseLoader size={'normal'} />;
    }

    return (
        <div className={styles.container}>
            <HeroSlider
                infinite={false}
                slides={heroSlideEndedCampaign}
                fullWidth={isMini}
                showLogo={false}
                customLogo={`Oameni reali. <br/> Legături reale.`}
                showTagline
                centerSingleSlideContent
                className={styles.customHeroEnded}
                componentClassName={styles.customHeroContainer}
                visualClassName={styles.videoContainer}
                detailsClassName={styles.details}
                customDetailsClass={styles.heroDetails}
                showLogoImage={true}
                showLogoForced={true}
                autoplay={false}
            />

            <Progress />

            <WinnersSection />

            <ChancesFromBRModal />
        </div>
    );
};

export default RealPeopleRealConnections;
