import React, { useEffect, useState } from 'react';
import styles from './RatingQuiz.module.scss';

// Interface
import { IRatingQuizProps } from './interface';

// Components
import { Rate } from 'antd';
import { Star } from '@assets/icons';
import Button from '@components/Layout/Button';

// Redux
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { getQuiz, postQuizQuestions } from '@components/Quiz/QuizSlice';
import { updateUserAsync } from '@slices/userSlice';
import { config } from '@utils/config';
import { getArticle, markArticleAsRead } from '@containers/IQOSUniverse/IQOSUniverseSlice';

const RatingQuiz = ({ quizSlug, handleSuccess, iqosAnswers }: IRatingQuizProps) => {
    const dispatch = useAppDispatch();

    const [answered, setAnswered] = useState(false);

    const { quizQuestions } = useAppSelector((state) => state.quiz);
    const { article } = useAppSelector((state) => state.iqosUniverse);

    useEffect(() => {
        dispatch(getQuiz({ slug: quizSlug, statistics: false }));
    }, [quizSlug]);

    const onChange = async (value: number) => {
        let currentStep = {
            quiz_slug: quizSlug,
            step_id: quizQuestions?.quiz?.steps[0]?.id,
            answers: [
                {
                    quiz_question_id: quizQuestions?.quiz?.steps[0]?.questions[0]?.id,
                    values: [{ value: `star${value}` }]
                }
            ]
        };

        setAnswered(true);
        await dispatch(postQuizQuestions(currentStep)).then((response) => {
            if (response.meta.requestStatus === 'fulfilled') {
                dispatch(markArticleAsRead({ slug: article.slug }));
            }
        });
        await dispatch(updateUserAsync());
        handleSuccess();
    };

    if (quizQuestions?.is_completed && !answered) {
        return null;
    }

    const showEpisodeDifferentContent = () => {
        if (answered && iqosAnswers && article.episode === 'Episodul 4') {
            return (
                <Button
                    className={styles.cta}
                    href={`${config.path.iqosUniverse.iqosHub}${config.path.pwaPage}`}
                >
                    Citește și aici cum îți instalezi PWA
                </Button>
            );
        } else if (answered && iqosAnswers && article.episode === 'Episodul 5') {
            return (
                <Button className={styles.cta} href={config.path.myRewards.index}>
                    Recompensele mele
                </Button>
            );
        } else if (answered && iqosAnswers && article.episode === 'Episodul 6') {
            return (
                <Button className={styles.cta} href={config.path.campaigns.ilumaLaunch}>
                    Descoperă
                </Button>
            );
        } else if (answered && iqosAnswers && article.episode === 'Episodul 7') {
            return (
                <Button className={styles.cta} href={config.path.campaigns.ilumaLaunch}>
                    Descoperă
                </Button>
            );
        } else if (answered && iqosAnswers && article.episode === 'Episodul 8') {
            return (
                <Button className={styles.cta} href={config.path.campaigns.summerEngagement}>
                    Participă acum
                </Button>
            );
        } else return null;
    };

    return (
        <div className={styles.container}>
            <h1>{answered ? 'Mulțumim!' : quizQuestions?.quiz?.steps[0]?.questions[0]?.title}</h1>

            <Rate
                disabled={answered}
                className={styles.rate}
                character={<Star />}
                onChange={onChange}
                count={quizQuestions?.quiz?.steps[0]?.questions[0]?.options.length}
            />

            {answered && quizQuestions?.quiz?.steps[0]?.questions[0]?.description && (
                <div className={styles.descriptionContainer}>
                    <div
                        className={styles.description}
                        dangerouslySetInnerHTML={{
                            __html: quizQuestions?.quiz?.steps[0]?.questions[0]?.description
                        }}
                    />
                </div>
            )}

            {showEpisodeDifferentContent()}
        </div>
    );
};

export default RatingQuiz;
