export const IqosMasterclassSliderData: Array<{
    id: number;
    videoId: string;
    subtitle: string;
}> = [
    {
        id: 1,
        videoId: '780282835',
        subtitle: 'Decorațiune pentru masa de Crăciun'
    },
    {
        id: 2,
        videoId: '780277721',
        subtitle: 'Decor de sărbătoare cu plante de interior'
    },
    {
        id: 3,
        videoId: '780282004',
        subtitle: 'Brad reinterpretat pentru iubitorii de lectură'
    }
];
