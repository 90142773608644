import classnames from 'classnames';
import styles from './BaseLoader.module.scss';
import { IBaseLoaderProps } from './interface';

// Components
import { Skeleton } from 'antd';
import { ThreeDots } from 'react-loader-spinner';

const BaseLoader = ({ size = 'small', hasSkeleton = false, className }: IBaseLoaderProps) => {
    if (hasSkeleton) {
        return (
            <Skeleton.Node active>
                <section className={classnames(styles.wrapper, styles[size])}>
                    <ThreeDots width="32" height="22" radius="10" color="#8C8A90" />
                </section>
            </Skeleton.Node>
        );
    } else {
        return (
            <section className={classnames(styles.wrapper, styles[size], className)}>
                <ThreeDots width="32" height="22" radius="10" color="#8C8A90" />
            </section>
        );
    }
};

export default BaseLoader;
