import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@app/store';
import axiosRequest from '@utils/axios';

export interface InviteState {
    status: 'idle' | 'loading' | 'success' | 'failed';
    singleInviteCard: {
        id: number;
        name: string;
        godfather_name: string;
        token: string;
        content: string;
        picture: string;
    };
    error: any;
}

interface AxiosError {
    response: {
        data: {
            errors: object;
            message: string;
        };
    };
}

const initialState: InviteState = {
    status: 'idle',
    singleInviteCard: {
        id: 0,
        name: '',
        godfather_name: '',
        token: '',
        content: '',
        picture: ''
    },

    error: {}
};

export const getPublicCardAsync = createAsyncThunk(
    'invitePublic/getPublicCard',
    async (values: string, { rejectWithValue }) => {
        try {
            const response = await axiosRequest.get(`/invitations/${values}`);
            return response.data.data;
        } catch (e) {
            return rejectWithValue((e as AxiosError).response.data);
        }
    }
);

export const invitePublic = createSlice({
    name: 'invitePublic',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder

            .addCase(getPublicCardAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getPublicCardAsync.fulfilled, (state, action) => {
                state.status = 'success';
                state.singleInviteCard = action.payload;
            })
            .addCase(getPublicCardAsync.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    }
});

export const selectCardLoading = (state: RootState) => state.invitePublic.status === 'loading';
export const selectCardSuccess = (state: RootState) => state.invitePublic.status === 'success';
export const selectCardFailed = (state: RootState) => state.invitePublic.status === 'failed';
export const selectCardError = (state: RootState) => state.invitePublic.error;

export const selectSingleInviteCard = (state: RootState) => state.invitePublic.singleInviteCard;

export default invitePublic.reducer;
