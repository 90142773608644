export const FbMessengerIcon = () => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="10" cy="10" r="9.51" stroke="#34303D" strokeWidth="0.98" />
            <g clipPath="url(#clip0_15516_349541)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.26214 12.1986C5.43541 11.7692 8.58621 7.84096 9.01821 7.4603C9.37161 7.63742 9.76417 8.06117 10.1959 8.41186C10.5876 8.73017 10.951 9.14262 11.2855 9.39854C11.82 9.35254 14.1882 7.57397 14.8586 7.19555C14.8676 7.47066 15.0296 7.21769 14.4822 7.81947L11.1682 11.958C10.6391 11.8186 9.09068 10.0289 8.81704 10.0033C8.53709 9.97717 5.84725 12.039 5.26214 12.1986Z"
                    fill="#FFFDFB"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.81869 11.4894C5.99086 11.1433 8.86912 8.07897 9.25347 7.79027C9.53608 7.96071 9.83521 8.33897 10.1717 8.65897C10.477 8.9494 10.7526 9.31636 11.0143 9.55114C11.4622 9.54766 13.5491 8.22158 14.1317 7.95027C14.1213 8.17984 14.2726 7.97984 13.7778 8.44506L10.75 11.6738C10.3187 11.5233 9.14652 9.93288 8.92043 9.89375C8.68912 9.85375 6.31608 11.3946 5.81869 11.4894ZM5.57956 17.0711C6.27782 16.9798 7.40999 15.9877 7.96913 15.8746C8.29347 15.8094 9.46999 16.272 11.0439 16.0216C12.057 15.8607 12.9483 15.5685 13.7117 15.1138C18.1891 12.4485 18.1604 6.5468 13.7291 3.93462C12.1152 2.98245 9.88565 2.67201 7.90391 3.24071C2.98217 4.65114 1.22826 10.5572 4.96999 14.1068C5.87347 14.9642 5.50652 14.6346 5.57956 17.0711Z"
                    fill="#00D1D2"
                />
            </g>
            <defs>
                <clipPath id="clip0_15516_349541">
                    <rect
                        width="14.1061"
                        height="14.1148"
                        fill="white"
                        transform="translate(2.95654 2.95654)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};
