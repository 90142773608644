export const Discount = () => {
    return (
        <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
                stroke="#34303D"
            />
            <path
                d="M11 2.40002C13.2809 2.40002 15.4683 3.30609 17.0811 4.91891C18.6939 6.53172 19.6 8.71916 19.6 11C19.6 13.2809 18.6939 15.4683 17.0811 17.0811C15.4683 18.694 13.2809 19.6 11 19.6V2.40002Z"
                fill="#00D1D2"
            />
            <path
                d="M9.58 8.6783C9.56623 7.88948 8.89917 7.26147 8.09007 7.27559C7.28097 7.28971 6.63623 7.94062 6.65 8.72944C6.66376 9.51825 7.33083 10.1463 8.13993 10.1321C8.94903 10.118 9.59377 9.46711 9.58 8.6783Z"
                fill="#34303D"
            />
            <path
                d="M14.5072 13.0534C14.4934 12.2645 13.8428 11.6362 13.054 11.65C12.2651 11.6638 11.6368 12.3144 11.6506 13.1032C11.6644 13.892 12.315 14.5203 13.1038 14.5066C13.8926 14.4928 14.5209 13.8422 14.5072 13.0534Z"
                fill="#34303D"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.44612 14.0022L13.5748 7.87324L7.44612 14.0022Z"
                fill="#34303D"
            />
            <path
                d="M7.44612 14.0022L13.5748 7.87324"
                stroke="#34303D"
                strokeWidth="1.2"
                strokeLinecap="round"
            />
        </svg>
    );
};
