export const MonthlyChallengeBadge = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12 22.9999C18.0751 22.9999 22.9999 18.0751 22.9999 12C22.9999 5.92485 18.0751 1 12 1C5.92485 1 1 5.92485 1 12C1 18.0751 5.92485 22.9999 12 22.9999Z"
                stroke="#34303D"
            />
            <path
                d="M12 2.53992C14.5089 2.53992 16.9151 3.53659 18.6892 5.31068C20.4633 7.08477 21.46 9.49095 21.46 11.9999C21.46 14.5088 20.4633 16.915 18.6892 18.6891C16.9151 20.4632 14.5089 21.4599 12 21.4599V2.53992Z"
                fill="#FFFDFB"
            />
            <path
                d="M9.13075 10.6687H8.65262C8.27221 10.6687 7.90737 10.5176 7.63838 10.2486C7.36938 9.97961 7.21826 9.61477 7.21826 9.23436V6.84375H9.13075"
                stroke="#34303D"
                strokeMiterlimit="10"
            />
            <path
                d="M14.8684 10.6687H15.3465C15.7269 10.6687 16.0918 10.5176 16.3608 10.2486C16.6298 9.97961 16.7809 9.61477 16.7809 9.23436V6.84375H14.8684"
                stroke="#34303D"
                strokeMiterlimit="10"
            />
            <path d="M12.0002 15.45V13.5375" stroke="#34303D" strokeMiterlimit="10" />
            <path
                d="M11.9991 13.5374C11.2383 13.5374 10.5086 13.2352 9.9706 12.6972C9.43261 12.1592 9.13037 11.4296 9.13037 10.6687V6.84375H14.8678V10.6687C14.8678 11.4296 14.5656 12.1592 14.0276 12.6972C13.4896 13.2352 12.7599 13.5374 11.9991 13.5374Z"
                fill="#FFFDFB"
                stroke="#34303D"
                strokeMiterlimit="10"
                strokeLinecap="square"
            />
            <path
                d="M14.8678 17.3624H9.13037C9.13037 16.8552 9.33186 16.3688 9.69052 16.0101C10.0492 15.6514 10.5356 15.45 11.0429 15.45H12.9553C13.4626 15.45 13.949 15.6514 14.3077 16.0101C14.6663 16.3688 14.8678 16.8552 14.8678 17.3624Z"
                fill="#FFFDFB"
                stroke="#34303D"
                strokeMiterlimit="10"
                strokeLinecap="square"
            />
        </svg>
    );
};
