import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@app/store';
import axiosRequest from '@utils/axios';

export interface InviteState {
    inviteStatus: 'idle' | 'loading' | 'success' | 'failed';
    status: 'idle' | 'loading' | 'success' | 'failed';
    inviteCards: Array<{
        id: number;
        picture: string;
        content: string;
    }>;
    singleInviteCard: {
        id: number;
        name: string;
        godfather_name: string;
        token: string;
        content: string;
        picture: string;
    };
    url: string;
    error: any;
}

interface AxiosError {
    response: {
        data: {
            errors: object;
            message: string;
        };
    };
}

const initialState: InviteState = {
    inviteStatus: 'idle',
    status: 'idle',
    inviteCards: [],
    singleInviteCard: {
        id: 0,
        name: '',
        godfather_name: '',
        token: '',
        content: '',
        picture: ''
    },
    url: '',
    error: {}
};

export const getCardsAsync = createAsyncThunk('invite/getCards', async () => {
    try {
        const response = await axiosRequest.get('/invitations-cards');
        return response.data.data;
    } catch (e) {
        return (e as AxiosError).response.data;
    }
});

export const inviteAsync = createAsyncThunk(
    'invite/invite',
    async (values: object, { rejectWithValue }) => {
        try {
            const response = await axiosRequest.post('/user-invitations-card', values);
            return response.data.data;
        } catch (e) {
            return rejectWithValue((e as AxiosError).response.data);
        }
    }
);

export const invite = createSlice({
    name: 'invite',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getCardsAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getCardsAsync.fulfilled, (state, action) => {
                state.status = 'success';
                state.inviteCards = action.payload;
            })
            .addCase(getCardsAsync.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(inviteAsync.pending, (state) => {
                state.inviteStatus = 'loading';
            })
            .addCase(inviteAsync.fulfilled, (state, action) => {
                state.inviteStatus = 'success';
                state.singleInviteCard = action.payload;
            })
            .addCase(inviteAsync.rejected, (state, action) => {
                state.inviteStatus = 'failed';
                state.error = action.payload;
            })
            .addCase(resetState, () => initialState);
    }
});

export const resetState = createAction('REVERT_ALL');

export const selectCardsLoading = (state: RootState) => state.invite.status === 'loading';
export const selectCardsSuccess = (state: RootState) => state.invite.status === 'success';
export const selectCardsFailed = (state: RootState) => state.invite.status === 'failed';
export const selectCardsError = (state: RootState) => state.invite.error;

export const selectInviteLoading = (state: RootState) => state.invite.inviteStatus === 'loading';
export const selectInviteSuccess = (state: RootState) => state.invite.inviteStatus === 'success';
export const selectInviteFailed = (state: RootState) => state.invite.inviteStatus === 'failed';
export const selectInviteError = (state: RootState) => state.invite.error;

export const selectInviteCards = (state: RootState) => state.invite.inviteCards;
export const selectSingleInviteCard = (state: RootState) => state.invite.singleInviteCard;

export default invite.reducer;
