import IQOSAnswers from './IQOSAnswers';
import RDW2021 from './RDW2021';
import MaintenanceLilSolid from './MaintenanceLilSolid';
import AllAboutLilSolid from './AllAboutLilSolid';
import HeetsPearlCollection from './HeetsPearlCollection';
import HarmonyWithIqos from './HarmonyWithIqos';
import WeUpgradeSummer from './WeUpgradeSummer';
import IqosXDiploma from './IqosXDiploma';
import DifferentTogether from './DifferentTogether';
import IqosSuperBrand from './IqosSuperBrand';
import UpgradeHiro from './UpgradeHiro';
import IqosXDiplomaArta from './IqosXDiplomaArta';
import CityExperienceMilano from './CityExperienceMilano';
import JazzInTheParkCelebration from './JazzInTheParkCelebration';
import IqosRomDesignWeek from './IqosRomDesignWeek';
import WeEditionIqos3Duo from './WeEditionIqos3Duo';
import HeetsCollection from './HeetsCollection';
import FiitOptions from './FiitOptions';
import LilSolidEntersIqosClub from './LilSolidEntersIqosClub';
import EngagementWinner from './EngagementWinner';
import DeviceCleaningImportance from './DeviceCleaningImportance';
import ArtForAirTiff from './ArtForAirTiff';
import RomanianDesignWeek2021 from './RomanianDesignWeek2021';
import IQOSCommunity2022 from './IQOSCommunity2022';
import AutoDelivery from './AutoDelivery';
import IqosOriginalsChanges from './IqosOriginalsChanges';
import AerWear from './AerWear';
import IqosNova from './IqosNova';
import IqosCocktails from './IqosCocktails';
import IqosMasterclass from './IqosMasterclass';
import CollectiveStoryIqos from './CollectiveStoryIqos';
import HeetsRubyFuse from './HeetsRubyFuse';
import NewLoveRubyFuse from './NewLoveRubyFuse';
import RdwVoting from './RdwVoting';
import Hangman from './HangMan';
import ReConnectWinner from './ReConnectWinner';
import HowToCleanDevice from './HowToCleanDevice';
import BenefitsCommunityMembers from './BenefitsCommunityMembers';
import FestivalDuBonheure from './FestivalDuBonheure';
import ClubZeroBill from './ClubZeroBill';
import PWAPage from './PWAPage';
import ArticleRDW from './ArticleRDW';
import EchoesRDW from './EchoesRDW';
import WrapupFlightFestival from './WrapupFlightFestival';
import IlumaEvent from './IlumaEvent';
import SingFestWrapUp from './SingFestWrapUp';
import JazzInTheParkWrapUp from './JazzInTheParkWrapUp';
import TiffWrapUp2023 from './TiffWrapUp2023';
import IqosSeaSide from './IqosSeaSide';
import SummerFirstExperience from './SummerFirstExperience';
import SummerCampaignStart from './SummerCampaignStart';
import ToscanaExperience from './ToscanaExperience';
import SummerSecondExperience from './SummerSecondExperience';
import IqosIllumaJazzInThePark from './IqosIllumaJazzInThePark';
import TereaFuseLine from './TereaFuseLine';
import FlavourBanVideo from './FlavourBanVideo';
import TogetherXAtena from './TogetherXAtena';
import LocalHeroesDreams from './LocalHeroesDreams';
import DiplomaShow from './DiplomaShow';
import IqosStardrift from './IQOSStardrift';
import StardriftQuiz from '@containers/Campaigns/IlumaStardriftPrelaunch/StardriftQuiz';
import StardriftLaunchWrapup from './StardriftLaunchWrapup';
import LampDinners from './LampDinners';
import Winter2023 from './Winter2023';
import Winter2023X2 from './Winter2023X2';
import TipsTricksIluma from './TipsTricksIluma';

import LeadfamlyGamePage from '../pages/LeadfamlyGamePage';
import MotivationCardDragGame from './MotivationCardDragGame';
import MotivationPuzzleGame from '@containers/IQOSUniverse/articles/MotivationPuzzleGame/MotivationPuzzleGame';
import NewLevia from '@containers/IQOSUniverse/articles/NewLevia';
import KirunaWrapup from '@containers/IQOSUniverse/articles/KirunaWrapup';
import LampDinners2024 from '@containers/IQOSUniverse/articles/LampDinners2024';
import WinterWrapUp from '@containers/IQOSUniverse/articles/WinterWrapUp';
import TriviaGame9 from '@containers/IQOSUniverse/articles/TriviaGame9';
import ZeroBill2024 from '@containers/IQOSUniverse/articles/ZeroBill2024';
import IqosQuizGame from './IqosQuizGame';
import FestivalDuBonheureSecond from '@containers/IQOSUniverse/articles/FestivalDuBonheureSecond';
import RDW2024 from '@containers/IQOSUniverse/articles/RDW2024';

export const components: {
    [key: string]: React.ComponentType<any>;
} = {
    IQOSAnswers,
    RDW2021,
    MaintenanceLilSolid,
    AllAboutLilSolid,
    HeetsPearlCollection,
    HarmonyWithIqos,
    WeUpgradeSummer,
    IqosXDiploma,
    DifferentTogether,
    IqosSuperBrand,
    UpgradeHiro,
    IqosXDiplomaArta,
    CityExperienceMilano,
    JazzInTheParkCelebration,
    IqosRomDesignWeek,
    WeEditionIqos3Duo,
    HeetsCollection,
    FiitOptions,
    LilSolidEntersIqosClub,
    EngagementWinner,
    DeviceCleaningImportance,
    ArtForAirTiff,
    RomanianDesignWeek2021,
    IQOSCommunity2022,
    AutoDelivery,
    IqosOriginalsChanges,
    AerWear,
    IqosNova,
    IqosCocktails,
    IqosMasterclass,
    CollectiveStoryIqos,
    HeetsRubyFuse,
    NewLoveRubyFuse,
    RdwVoting,
    Hangman,
    ReConnectWinner,
    HowToCleanDevice,
    BenefitsCommunityMembers,
    FestivalDuBonheure,
    ClubZeroBill,
    PWAPage,
    ArticleRDW,
    EchoesRDW,
    JazzInTheParkWrapUp,
    WrapupFlightFestival,
    IlumaEvent,
    TiffWrapUp2023,
    SingFestWrapUp,
    IqosSeaSide,
    SummerFirstExperience,
    SummerCampaignStart,
    ToscanaExperience,
    SummerSecondExperience,
    IqosIllumaJazzInThePark,
    TereaFuseLine,
    LeadfamlyGamePage,
    FlavourBanVideo,
    TogetherXAtena,
    LocalHeroesDreams,
    DiplomaShow,
    MotivationCardDragGame,
    IqosStardrift,
    StardriftQuiz,
    StardriftLaunchWrapup,
    MotivationPuzzleGame,
    Winter2023,
    NewLevia,
    LampDinners,
    Winter2023X2,
    LampDinners2024,
    KirunaWrapup,
    WinterWrapUp,
    TriviaGame9,
    ZeroBill2024,
    TipsTricksIluma,
    IqosQuizGame,
    FestivalDuBonheureSecond,
    RDW2024
};
