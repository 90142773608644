// Styles
import { useEffect, useState } from 'react';
import styles from './ArticleRDW.module.scss';

// Components
import ArticleHeader from '@containers/IQOSUniverse/components/ArticleHeader';
import CompletedStateArticle from '@containers/IQOSUniverse/components/CompletedStateArticle/CompletedStateArticle';
import ArticleContainer from '@containers/IQOSUniverse/components/ArticleContainer';
import ArticleImage from '@containers/IQOSUniverse/components/ArticleImage';
import BaseModal from '@components/Layout/BaseModal';
import VideoDisclaimer from '@containers/IQOSUniverse/components/VideoDisclaimer';
import RatingQuiz from '@containers/IQOSUniverse/components/RatingQuiz';
import VimeoPlayer from '@components/VimeoPlayer';
import MediaGallery from '@containers/IQOSUniverse/components/MediaGallery';

// Assets
import galleryImages from './galleryImages';
import VideoThumbnail from './assets/videoThumbail.jpg';

// Helpers
import classnames from 'classnames';
import dayjs from '@utils/dayjs';

// Redux
import { useAppSelector } from '@app/hooks';

const ArticleRDW = () => {
    const [toggleModal, setToggleModal] = useState(false);
    const [showDisclaimer, toggleDisclaimer] = useState(false);
    const [isPastPercentage, setIsPastPercentage] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [hidePlayer, setHidePlayer] = useState(false);
    const [videoEnded, setVideoEnded] = useState(false);

    const { article } = useAppSelector((state) => state.iqosUniverse);

    useEffect(() => {
        if (!article.completed_quiz && (article.quiz_points || article.points)) {
            toggleDisclaimer(true);
        }
    }, [article]);

    let playing = false;
    let progress = 0;
    const triggerMomentPercentage = 100; // 100%

    const handleTimeUpdate = (data: any) => {
        if (playing && data.seconds - progress <= 10) {
            if (data.seconds < progress) {
                //don't update progress if user seeked back
            } else {
                progress = data.seconds;
            }
        }

        const durationPercentage = (triggerMomentPercentage * data.duration) / 100;

        if (progress >= durationPercentage && !isPastPercentage) {
            setIsPastPercentage(true);
        }
    };

    useEffect(() => {
        if (videoEnded) {
            if (isPastPercentage && !article.completed_quiz) {
                setHidePlayer(true);
            } else {
                setTimeout(() => {
                    handleCloseModal();
                }, 1000);
            }
        }
    }, [videoEnded, isPastPercentage, article]);

    const handleCloseModal = () => {
        setToggleModal(false);
        setHidePlayer(false);
        setIsPastPercentage(false);
        toggleDisclaimer(true);
        setVideoEnded(false);
    };

    return (
        <section className={styles.wrapper}>
            <ArticleHeader />

            <div className="content">
                <p>
                    Piața Amzei găzduiește evenimentul care transformă Bucureștiul în capitala
                    creativității - Romanian Design Week.
                </p>

                <p>
                    Printre zecile de proiecte expuse și o multitudine de idei care-ți dau un
                    refresh creativ, poți admira și „Echoes of Presence”, instalația interactivă
                    susținută de IQOS, pe care voturile voastre au desemnat-o câștigătoare în cadrul
                    concursului RE-CONNECT - proiect dezvoltat de Alina Rizescu, Bogdan Stefănescu,
                    Alin Tatu și Denis Flueraru.
                </p>

                <p>
                    Instalația reprezintă cea mai creativă formă a temei „A celebration of
                    togetherness” și aduce în prim-plan propriul impact creativ în rândul celorlalte
                    exponate de festival, create sub tema umbrelă CONNECTIONS.
                </p>

                <p>
                    Odată ce o descoperi, înțelegi că tu influențezi ambientul și experiența
                    celorlalți. Sau, cu alte cuvinte, că oamenii lasă o amprentă asupra spațiului
                    comun. Experiența ta înseamnă călătoria prin trei încăperi în care senzorialul
                    se îmbină cu vizualul și cu semnalele sonore.
                </p>

                <p>
                    Prin senzori de prezență declanșezi efecte care se propagă dintr-o cameră în
                    alta. Parcursul tău devine unul din ce în ce mai profund,{' '}
                    <strong>iar a auzi, a simți, a cunoaște</strong> sunt temele cu care te
                    intersectezi și prin care descoperi că omul este în continuă schimbare.
                </p>

                <p>
                    Mai mult nu îți spunem, te lăsăm să descoperi singur în{' '}
                    <strong>27 mai la IQOS x RDW Closing Party</strong> în Piața Amzei din
                    București. Dacă nu ajungi până atunci, instalația va mai fi expusă până în{' '}
                    <strong>28 mai în cadrul RDW</strong>.
                </p>

                <p>Ne vedem la festival!</p>
            </div>

            <MediaGallery images={galleryImages} />

            <ArticleContainer background={'#F6F4F0'}>
                <div className={styles.videoContainer}>
                    <ArticleImage
                        isVideo
                        mobileImage={VideoThumbnail}
                        image={VideoThumbnail}
                        onClick={() => setToggleModal(true)}
                    />

                    <BaseModal
                        size="large"
                        maskClosable={true}
                        isModalOpen={toggleModal}
                        className={styles.videoModal}
                        closeModal={() => handleCloseModal()}
                        destroyOnClose={true}
                    >
                        <div className={styles.modalContent}>
                            {!article.completed_quiz &&
                                dayjs(article.quiz_end_date).isAfter(dayjs()) &&
                                showDisclaimer && (
                                    <VideoDisclaimer handleClose={() => toggleDisclaimer(false)} />
                                )}

                            {isPastPercentage && article.quiz_slug && (
                                <div
                                    className={classnames(
                                        styles.rating,
                                        hidePlayer && styles.padding
                                    )}
                                >
                                    <RatingQuiz
                                        handleSuccess={() => setShowSuccess(true)}
                                        quizSlug={article.quiz_slug}
                                    />
                                </div>
                            )}

                            {!hidePlayer && (
                                <VimeoPlayer
                                    className={styles.player}
                                    video="829390270"
                                    options={{
                                        autoplay: false,
                                        responsive: true
                                    }}
                                    onPlay={() => (playing = true)}
                                    onTimeUpdate={(progress) => handleTimeUpdate(progress)}
                                    onPause={() => (playing = false)}
                                    onEnd={() => setVideoEnded(true)}
                                />
                            )}
                        </div>
                    </BaseModal>
                </div>
            </ArticleContainer>

            {(showSuccess || article.completed_quiz) && (
                <CompletedStateArticle
                    article={{
                        points: article.points,
                        quiz_points: article.quiz_points,
                        quiz_end_date: article.quiz_end_date
                    }}
                />
            )}
        </section>
    );
};

export default ArticleRDW;
