import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Assets
import styles from './LocalHeroesDreams.module.scss';
import img1 from './assets/img1.jpg';
import { FPIcon } from '@assets/icons';
import { slides } from './data';

// Components
import ArticleHeader from '@containers/IQOSUniverse/components/ArticleHeader';
import VideoModalQuiz from '@containers/IQOSUniverse/components/VideoModalQuiz';
import ArticleContainer from '@containers/IQOSUniverse/components/ArticleContainer';
import ArticleImage from '@containers/IQOSUniverse/components/ArticleImage';
import KeenSlider from '@components/KeenSlider';
import Button from '@components/Layout/Button';
import VimeoPlayer from '@components/VimeoPlayer';

// Helpers
import { useWindowSize } from '@utils/hooks/windowSize';
import { config } from '@utils/config';
import dayjs from '@utils/dayjs';

// Redux
import { useAppSelector } from '@app/hooks';

const LocalHeroesDreams = () => {
    const navigate = useNavigate();
    const windowSize = useWindowSize();
    const isMobile = windowSize.width < 650;

    const { article } = useAppSelector((state) => state.iqosUniverse);

    const [toggleModal, setToggleModal] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);

    return (
        <section className={styles.wrapper}>
            <ArticleHeader
                videoQuiz={() => {
                    setToggleModal(true);
                }}
            />
            <VideoModalQuiz
                toggleModal={toggleModal}
                setToggleModal={() => setToggleModal(false)}
                setShowSuccess={() => {
                    setShowSuccess(true);
                }}
            />
            <ArticleContainer background={'#fffdfb'}>
                <div className="content">
                    <p>
                        Află de la câștigătorii concursului „Imaginea comunității IQOS”
                        posibilitățile nelimitate care ți se deschid atunci când îndrăznești să
                        visezi.
                    </p>
                </div>
            </ArticleContainer>

            <ArticleContainer background={'#fffdfb'}>
                <ArticleImage mobileImage={img1} image={img1} imgPosition={'50% 40%'} cover />
                <div className="content">
                    <h3>În IQOS CLUB, visurile tale pot deveni realitate.</h3>
                    <p>
                        Cel mai bun exemplu este povestea câștigătorilor campaniei{' '}
                        <strong>„Imaginea comunității IQOS”</strong>, care și-au dorit să devină o
                        inspirație pentru întreaga comunitate, iar acum își trăiesc visul. La
                        întoarcerea din Toscana, i-am invitat pe 5 dintre ei să ne împărtășească
                        lucrurile la care visează și să ne inspire cu poveștile lor.
                    </p>
                </div>
            </ArticleContainer>

            <ArticleContainer background={'#fffdfb'}>
                <KeenSlider
                    className={styles.dreamsSlider}
                    spacing={isMobile ? 8 : 16}
                    mode={'snap'}
                    withDots={false}
                    withArrows={true}
                    darkArrows={true}
                >
                    {slides.map((slide: any) => {
                        return (
                            <div key={slide.id} className={styles.slideItem}>
                                <div className={styles.video}>
                                    <VimeoPlayer
                                        className={styles.vimeo}
                                        video={slide.video}
                                        options={{
                                            responsive: true,
                                            autoplay: false
                                        }}
                                    />
                                </div>
                                <h3>{slide.name}</h3>
                                <p>{slide.description}</p>
                            </div>
                        );
                    })}
                </KeenSlider>
            </ArticleContainer>

            <div className={styles.customArticleContainer}>
                <ArticleContainer textColor="#fffdfb" background={'#34303D'}>
                    <div className="content">
                        <h3>
                            Alege să-ți îndeplinești visul.
                            <br />
                            În IQOS CLUB.
                        </h3>
                        <p>
                            Dacă te-au inspirat poveștile celor 5 câștigători, înscrie-ți visul în
                            campania <strong>„Oameni Reali. Visuri Reale.”</strong> și poți câștiga
                            unul dintre cele{' '}
                            <strong>4 x Dream Pass-uri în valoare de 10.000 EUR</strong> și{' '}
                            <strong>4 x IQOS Aurora Experience</strong>.
                        </p>
                        <Button
                            fullwidth
                            type="solid"
                            color="light"
                            hover="turquoise"
                            label="Înscrie-ți visul"
                            onClick={() =>
                                navigate(config.path.campaigns.callForDreamsCampaignPage)
                            }
                        />
                    </div>
                </ArticleContainer>
            </div>

            {dayjs(article.quiz_end_date).isAfter(dayjs()) && (
                <>
                    {showSuccess || article.completed_quiz ? (
                        <section className={styles.congratsContainer}>
                            <div className={styles.completed}>
                                <h3>
                                    Felicitări! Ai parcurs tot articolul și ai câștigat 100 Forward
                                    Points.
                                </h3>

                                <div className={styles.reward}>
                                    <FPIcon />
                                    <span>
                                        <b>+100</b> <br />
                                        Forward Points
                                    </span>
                                </div>
                            </div>
                        </section>
                    ) : (
                        <section className={styles.congratsContainer}>
                            <div className={styles.completed}>
                                <h3>Urmărește video-ul și acumulezi 100 FP!</h3>

                                <div className={styles.reward}>
                                    <FPIcon />
                                    <span>
                                        <b>+100</b> <br />
                                        Forward Points
                                    </span>
                                </div>
                            </div>
                        </section>
                    )}
                </>
            )}
        </section>
    );
};

export default LocalHeroesDreams;
