import styles from './FinalAdvice.module.scss';
import { AdviceIcon } from '@containers/Campaigns/RealPeopleRealConnections/Components/RealConnectionsGames/icons';
import randomBetween from '@utils/randomBetween';
import { advices } from '@containers/Campaigns/RealPeopleRealConnections/Components/RealConnectionsGames/components/FinalAdvice/data';

const FinalAdvice = () => {
    const getFinalAdvice = () => {
        const randomAdvice = randomBetween(0, advices.length);

        return advices[randomAdvice].text;
    };

    return (
        <div className={styles.advicesPill}>
            <div className={styles.icon}>
                <AdviceIcon />
            </div>
            <div className={styles.advice}>
                <p dangerouslySetInnerHTML={{ __html: getFinalAdvice() }} />
            </div>
        </div>
    );
};

export default FinalAdvice;
