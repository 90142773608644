import mobile1 from './assets/mobile1.jpg';
import mobile2 from './assets/mobile2.jpg';
import mobile3 from './assets/mobile3.jpg';
import mobile4 from './assets/mobile4.jpg';
import desktop1 from './assets/desktop1.jpg';
import desktop2 from './assets/desktop2.jpg';
import desktop3 from './assets/desktop3.jpg';
import desktop4 from './assets/desktop4.jpg';

export const zeroBillSliderPhotos: Array<{
    id: number;
    image: string;
    desktopImage: string;
}> = [
    {
        id: 1,
        image: mobile1,
        desktopImage: desktop1
    },
    {
        id: 2,
        image: mobile2,
        desktopImage: desktop2
    },
    {
        id: 3,
        image: mobile3,
        desktopImage: desktop3
    },
    {
        id: 4,
        image: mobile4,
        desktopImage: desktop4
    }
];
