export const CollapseExpandMore = () => {
    return (
        <svg
            width="13"
            height="7"
            viewBox="0 0 13 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0.93689 1.01501L6.31053 6.38865C6.34975 6.43044 6.39712 6.46375 6.44972 6.48652C6.50232 6.50929 6.55903 6.52104 6.61634 6.52104C6.67366 6.52104 6.73036 6.50929 6.78296 6.48652C6.83556 6.46375 6.88293 6.43044 6.92216 6.38865L12.2958 1.01501"
                stroke="#000001"
                strokeWidth="0.873762"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
