import { useState } from 'react';
import styles from './EngagementWinner.module.scss';

// Helpers
import classNames from 'classnames';

// Assets
import videoThumbnail from './assets/videoThumbnail.png';
import videoThumbnailDesktop from './assets/videoThumbnailDesktop.png';

import slide1 from './assets/slide1.png';
import slide2 from './assets/slide2.png';
import slide3 from './assets/slide3.png';

// Components
import VideoModal from '@components/VideoModal';
import ArticleImage from '@containers/IQOSUniverse/components/ArticleImage';
import ArticleHeader from '@containers/IQOSUniverse/components/ArticleHeader';
import ArticleSlider from '@containers/IQOSUniverse/components/ArticleSlider';
import ArticleContainer from '@containers/IQOSUniverse/components/ArticleContainer';

const EngagementWinner = () => {
    const [toggleModal, setToggleModal] = useState(false);

    const slides = [{ image: slide1 }, { image: slide2 }, { image: slide3 }];

    return (
        <section className={styles.wrapper}>
            <ArticleHeader />
            <div className="content">
                <h3>
                    Cel mai frumos moment al fiecărei campanii promoționale este cel în care îi
                    cunoaștem pe câștigători.
                </h3>

                <p>
                    Fericirea lor la aflarea veștii că vor primi premii ne motivează de fiecare dată
                    să pregătim noi concursuri și să oferim aceeași bucurie și altor membri IQOS
                    Club.
                </p>
            </div>

            <ArticleSlider fullWidthImage bottomButtons slides={slides} />

            <div className="content">
                <h3>Astăzi o cunoaștem pe Mihaela</h3>

                <p>
                    Câștigătoarea marelui premiu din campania „Universul IQOS Club prinde noi forme”
                    are 45 de ani, a introdus cod după cod, însă niciodată nu și-a imaginat cu
                    adevărat că o să câștige.
                </p>
            </div>

            <ArticleContainer background={'#00D1D2'}>
                <div className={classNames(styles.videoContent, 'content')}>
                    <h3>Descoperă-i reacția la aflarea veștii și inspiră-te din povestea ei!</h3>

                    <ArticleImage
                        isVideo
                        mobileImage={videoThumbnail}
                        image={videoThumbnailDesktop}
                        onClick={() => setToggleModal(true)}
                    />

                    <VideoModal
                        toggleModal={toggleModal}
                        setToggleModal={() => setToggleModal(false)}
                        video="717453300"
                    />

                    <p className={styles.videoTextMargin}>
                        Ne-am întâlnit cu Mihaela, i-am predat premiul și i-am pus câteva întrebări
                        pentru a afla mai multe despre ea și experiența ei alături de IQOS.
                    </p>

                    <p className={styles.videoSubtitle}>
                        Te invităm să fii activ în continuare în IQOS Club și să participi la toate
                        campaniile pe care ți le pregătim. Următorul mare câștigător poți fi chiar
                        tu!
                    </p>
                </div>
            </ArticleContainer>
        </section>
    );
};
export default EngagementWinner;
