import React from 'react';

import styles from './ChancesFromBRModal.module.scss';
import headerGif from '../../assets/weeklyPrizes/prize1.gif';

// Components
import BaseModal from '@components/Layout/BaseModal';
import RewardBadge from '@components/RewardBadge';
import Button from '@components/Layout/Button';

// Redux
import { useAppSelector, useAppDispatch } from '@app/hooks';
import { toggleChancesFromBRModal } from '../../realPeopleRealConnectionsSlice';

const ChancesFromBRModal: React.FC = () => {
    const dispatch = useAppDispatch();
    const isChancesFromBRModalOpen = useAppSelector(
        (state) => state.realPeopleRealConnections.chancesFromBRModalisOpen
    );

    return (
        <BaseModal
            size="small"
            className={styles.chancesFromBRModal}
            isModalOpen={isChancesFromBRModalOpen}
            closeModal={() => dispatch(toggleChancesFromBRModal(false))}
            destroyOnClose
            lockBodyScroll={true}
        >
            <section className={styles.chancesFromBRModalContent}>
                <div className={styles.image}>
                    <RewardBadge
                        className={styles.smallBadge}
                        backgroundColor={'#FFFDFB'}
                        absolute={true}
                        chances={true}
                        extraChances={true}
                        chancesPoints={10}
                    />
                    <img src={headerGif} alt="Chances for VIP Tickets from BR" />
                </div>
                <h3 className={styles.title}>
                    Felicitări! Ai obținut 10 șanse pentru o experiență VIP la concertul anului!
                </h3>
                <p className={styles.subtitle}>
                    Interacțiunea ta în campania{' '}
                    <strong>„Oameni reali. Legături reale.”</strong> ți-a adus 10 șanse în
                    tragerea la sorți pentru{' '}
                    <strong>o experiență VIP la concertul anului</strong>!
                </p>
                <p className={styles.subtitle}>
                    Continuă să celebrezi conexiunile autentice din viața ta alături de
                    comunitatea IQOS CLUB pentru a debloca și mai multe recompense! Ți-am
                    pregătit <strong>10 x Experiențe unice IQOS în Mykonos</strong> și multe
                    alte surprize.
                </p>
                <Button fullwidth onClick={() => dispatch(toggleChancesFromBRModal(false))}>
                    Participă acum
                </Button>
            </section>
        </BaseModal>
    );
};

export default ChancesFromBRModal;
