import React, { useEffect, useState } from 'react';
import styles from './IlumaEvent.module.scss';

// Components
import ArticleHeader from '@containers/IQOSUniverse/components/ArticleHeader';
import VideoModalQuiz from 'containers/IQOSUniverse/components/VideoModalQuiz';
import CompletedStateArticle from '@containers/IQOSUniverse/components/CompletedStateArticle/CompletedStateArticle';

// Redux
import { useAppSelector } from '@app/hooks';
import Player from '@vimeo/player';

const IlumaEvent = () => {
    const { article } = useAppSelector((state) => state.iqosUniverse);

    const [toggleModal, setToggleModal] = useState(false);
    const [isPastPercentage, setIsPastPercentage] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);

    let playing = false;
    let secondsPlayed: number = 0;
    const seconds = 18;

    useEffect(() => {
        onVideoPlay();
    }, []);

    const onVideoPlay = () => {
        const iframe = document.getElementById('vimeoIframe');
        const player = iframe && new Player(iframe);

        player &&
            player.on('timeupdate', () => {
                if (playing && secondsPlayed <= seconds) {
                    secondsPlayed = secondsPlayed + 0.3;
                }

                if (secondsPlayed >= seconds && !isPastPercentage) {
                    setIsPastPercentage(true);
                }
            });

        player &&
            player.on('play', () => {
                playing = true;
            });
        player &&
            player.on('pause', () => {
                playing = false;
            });
    };

    return (
        <section className={styles.wrapper}>
            <ArticleHeader
                videoQuiz={() => {
                    setToggleModal(true);
                }}
            />
            <VideoModalQuiz
                toggleModal={toggleModal}
                setToggleModal={() => setToggleModal(false)}
                setShowSuccess={() => {
                    setShowSuccess(true);
                }}
            />
            <div className="content">
                <p>
                    <strong>
                        Am deschis împreună cel mai revoluționar capitol de până acum: IQOS ILUMA.
                    </strong>
                </p>
                <p>
                    13 iunie a fost seara în care am sărbătorit revoluția experienței IQOS adusă de
                    IQOS ILUMA alături de membrii IQOS CLUB și utilizatori VIP.
                </p>

                <p>Play și descoperă cum a fost!</p>

                <p>
                    <strong>Un eveniment cu și despre inovație</strong>
                </p>

                <p>
                    Cei 300 de invitați au fost întâmpinați la Muzeul de Artă Recentă, reimaginat.
                    Special pentru acest eveniment, spațiul a fost recreat pentru a reflecta
                    inovația și a-i surprinde astfel pe participanți: instalații kinetice de
                    oglinzi, coridoare îmbrăcate în LED și pereți pulsatili.
                </p>
            </div>

            <div className={styles.eventContainer}>
                <div className={styles.badges}></div>
                <iframe
                    title={'ilumaVideoLaunch'}
                    id={'vimeoIframe'}
                    allowFullScreen
                    allow="autoplay; fullscreen; picture-in-picture"
                    src={'https://vimeo.com/event/3495396/embed'}
                />
            </div>

            <div className="content">
                <h3>
                    <strong>IQOS ILUMA s-a lansat și în IQOS CLUB</strong>
                </h3>
                <p>
                    Pe lângă evenimentul fizic, în seara lansării, a existat un show special creat
                    pentru IQOS CLUB.
                </p>
            </div>

            {(showSuccess || article.completed_quiz) && (
                <CompletedStateArticle
                    className={styles.completedStateArticle}
                    article={{
                        points: article.points,
                        quiz_points: article.quiz_points,
                        quiz_end_date: article.quiz_end_date
                    }}
                />
            )}
        </section>
    );
};

export default IlumaEvent;
