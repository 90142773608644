export const PWAMonthlyChallengeIcon = () => {
    return (
        <svg
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_8916_9396)">
                <path
                    d="M45.2527 8.1665C46.4042 8.1665 47.3492 9.07884 47.4402 10.2432L47.4472 10.4182L47.5008 28.3475L45.1675 28.3557L45.1162 11.152C45.115 10.8312 44.8537 10.571 44.5328 10.571H9.91667C9.59467 10.571 9.33333 10.8312 9.33333 11.1543V39.7342C9.33333 40.0562 9.59467 40.3175 9.91667 40.3175H23.485V42.722H9.19333C8.0395 42.722 7.09333 41.805 7.00583 40.6395L7 40.4633V10.9618C7 9.75434 7.8015 8.2995 9.02533 8.17467L9.1945 8.1665H45.2527Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M42.1747 12.2939C42.8187 12.2939 43.3414 12.8166 43.3414 13.4606V24.1228C41.0396 21.8186 37.8849 20.3976 34.4059 20.3976C27.3569 20.3976 21.6426 26.2309 21.6426 33.4258C21.6426 35.1886 21.9856 36.8709 22.6074 38.4051H12.3932C11.7481 38.4051 11.2266 37.8824 11.2266 37.2384V13.4618C11.2266 12.8166 11.7481 12.2951 12.3932 12.2951H42.1747V12.2939Z"
                    fill="#00D1D2"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M47.3599 33.6595C47.3599 41.1028 41.607 47.1356 34.509 47.1356C27.4122 47.1356 21.6582 41.1028 21.6582 33.6595C21.6582 26.2161 27.4122 20.1821 34.509 20.1821C41.6082 20.1821 47.361 26.2161 47.361 33.6595H47.3599Z"
                    stroke="white"
                    strokeWidth="2.33333"
                    strokeLinejoin="round"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M34.3124 37.3823C34.4349 37.3181 34.5807 37.3181 34.7044 37.3823L38.6746 39.4566L37.9186 35.0735C37.8941 34.9335 37.9407 34.7888 38.0422 34.6908L41.2424 31.5921L36.8149 30.9528C36.6761 30.933 36.5571 30.8466 36.4941 30.7195L34.5084 26.7236L32.5227 30.7206C32.4597 30.8466 32.3407 30.933 32.2019 30.954L27.7744 31.5921L30.9734 34.6908C31.0761 34.7888 31.1227 34.9335 31.0982 35.0735L30.3422 39.4566L34.3124 37.3823Z"
                    stroke="white"
                    strokeWidth="2.33333"
                    strokeLinejoin="round"
                />
                <path
                    d="M18.8117 15.0029H15.4552C14.5248 15.0029 13.7705 15.7572 13.7705 16.6876C13.7705 17.618 14.5248 18.3723 15.4552 18.3723H18.8117C19.7421 18.3723 20.4963 17.618 20.4963 16.6876C20.4963 15.7572 19.7421 15.0029 18.8117 15.0029Z"
                    fill="#34303D"
                />
            </g>
            <defs>
                <clipPath id="clip0_8916_9396">
                    <rect width="56" height="56" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
