import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosRequest from '@utils/axios';
import dayjs from '@utils/dayjs';

export interface ContestsState {
    status: 'idle' | 'loading' | 'failed';
    slides: any;
    error: any;
}

interface AxiosError {
    response: {
        data: {
            message: string;
        };
    };
}

const initialState: ContestsState = {
    status: 'idle',
    slides: [],
    error: {}
};

export const getContestBanners = createAsyncThunk(
    'homepage/contests',
    async ({ expired, descending = false }: { expired: string; descending: boolean }) => {
        try {
            const response = await axiosRequest
                .get('/contests', {
                    params: {
                        expired
                    }
                })
                .then((res) => {
                    return res;
                });
            return descending
                ? response.data.data.sort(
                      (a: any, b: any) => dayjs(b.end_date).unix() - dayjs(a.end_date).unix()
                  )
                : response.data.data;
        } catch (e) {
            return (e as AxiosError).response.data.message;
        }
    }
);

export const ContestsSlice = createSlice({
    name: 'contests',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getContestBanners.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getContestBanners.fulfilled, (state, action) => {
                state.status = 'idle';
                state.slides = action.payload;
            })
            .addCase(getContestBanners.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    }
});

export default ContestsSlice.reducer;
