import classnames from 'classnames';
import styles from './RewardBadge.module.scss';
import { IRewardBadgeProps } from './interface';

// Assets
import {
    ContestIcon,
    Discount,
    FPIcon,
    VoucherIconBorder,
    FPIconWhite,
    ExtraChances,
    ContestIconWhite
} from '@assets/icons';

const RewardBadge = (props: IRewardBadgeProps) => {
    const {
        className,
        absolute = true,
        absoluteForSlider,
        darkMode = false,
        backgroundColor = '#F6F4F0',
        contest,
        date,
        voucher,
        points,
        chances,
        extraChances,
        chancesPoints,
        small,
        smaller,
        offer,
        noText,
        custom,
        badge
    } = props;

    if (contest) {
        return (
            <div
                style={{ backgroundColor: backgroundColor }}
                className={classnames(
                    'rewardBadge',
                    styles.reward,
                    noText && styles.noText,
                    small && styles.small,
                    smaller && styles.smaller,
                    absolute && styles.absolute,
                    className
                )}
            >
                {darkMode ? <ContestIconWhite /> : <ContestIcon />}
                <span>Concurs</span>
            </div>
        );
    }

    if (date) {
        return (
            <div
                style={{ backgroundColor: backgroundColor }}
                className={classnames(
                    'rewardBadge',
                    styles.reward,
                    noText && styles.noText,
                    small && styles.small,
                    absolute && styles.absolute,
                    className
                )}
            >
                {darkMode ? <ContestIconWhite /> : <ContestIcon />}
                <span>Concurs</span>
            </div>
        );
    }

    if (voucher) {
        return (
            <div
                style={{ backgroundColor: backgroundColor }}
                className={classnames(
                    'rewardBadge',
                    styles.reward,
                    noText && styles.noText,
                    small && styles.small,
                    absolute && styles.absolute,
                    className
                )}
            >
                <VoucherIconBorder />
                <span>Voucher</span>
            </div>
        );
    }

    if (offer) {
        return (
            <div
                style={{ backgroundColor: backgroundColor }}
                className={classnames(
                    'rewardBadge',
                    styles.reward,
                    noText && styles.noText,
                    small && styles.small,
                    absolute && styles.absolute,
                    className
                )}
            >
                <Discount />
                <span>Ofertă</span>
            </div>
        );
    }

    if (points) {
        return (
            <div
                style={{ backgroundColor: backgroundColor }}
                className={classnames(
                    'rewardBadge',
                    styles.reward,
                    styles.points,
                    small && styles.small,
                    absolute && styles.absolute,
                    absoluteForSlider && styles.absoluteForSlider,
                    className
                )}
            >
                {darkMode ? <FPIconWhite /> : <FPIcon />}

                <span>
                    <b>+{points}</b> <br />
                    Forward Points
                </span>
            </div>
        );
    }

    if (extraChances) {
        return (
            <div
                style={{ backgroundColor: backgroundColor }}
                className={classnames(
                    'rewardBadge',
                    styles.reward,
                    styles.points,
                    small && styles.small,
                    absolute && styles.absolute,
                    absoluteForSlider && styles.absoluteForSlider,
                    className
                )}
            >
                <ExtraChances />
                <span>
                    {chances ? (
                        <>
                            <strong> +{chancesPoints} </strong> <br />
                            {chancesPoints === 1 ? 'Șansă' : 'Șanse'}
                        </>
                    ) : (
                        <>
                            <b>+{chancesPoints}</b> <br />
                            {chancesPoints === 1 ? 'ExtraȘansă' : 'ExtraȘanse'}
                        </>
                    )}
                </span>
            </div>
        );
    }

    if (custom) {
        return (
            <div
                style={{ backgroundColor: backgroundColor }}
                className={classnames(
                    'rewardBadge',
                    styles.reward,
                    small && styles.small,
                    absolute && styles.absolute,
                    absoluteForSlider && styles.absoluteForSlider,
                    className
                )}
            >
                {badge}
                {custom.length > 1 && <span dangerouslySetInnerHTML={{ __html: custom }} />}
            </div>
        );
    }

    return null;
};

export default RewardBadge;
