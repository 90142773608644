import { useState } from 'react';
import styles from './AllAboutLilSolid.module.scss';

// Data
import { FirstUseList, FirstUse } from './Lists/FirstUseList';

// Assets
import img1 from './assets/imgDesktop.png';
import img1Mobile from './assets/img1LilMobile.png';
import img2 from './assets/allAboutLilSolidVideo.png';
import img2Mobile from './assets/allAboutLilSolidVideoMobile.png';

// Components
import VideoModal from '@components/VideoModal';
import ArticleImage from '@containers/IQOSUniverse/components/ArticleImage';
import ArticleHeader from '@containers/IQOSUniverse/components/ArticleHeader';
import ArticleContainer from '@containers/IQOSUniverse/components/ArticleContainer';
import classnames from 'classnames';

const AllAboutLilSolid = () => {
    const [toggleModal, setToggleModal] = useState(false);

    return (
        <div className={styles.wrapper}>
            <ArticleHeader />

            <div className="content">
                <h3>O experiență compactă, fără fum și fără scrum</h3>

                <p>
                    Dacă abia ai pus mâna pe un dispozitiv lil SOLID 2.0 și vrei să afli mai multe
                    despre cum îl poți utiliza, ești în locul potrivit. Așa cum poate știi deja, lil
                    SOLID 2.0 este un dispozitiv compact, care încălzește tutunul cu ajutorul unui
                    element de încălzire pentru o experiență fără scrum, fum sau ardere.
                </p>
            </div>

            <ArticleImage cover={true} mobileImage={img1Mobile} image={img1} />

            <div className="content">
                <h3>Activarea dispozitivului, înainte de prima utilizare</h3>

                <p>
                    Înainte să ai parte de prima experiență cu lil SOLID 2.0, trebuie să activezi
                    dispozitivul din modul Repaus, fie prin apăsarea butonului timp de 2 secunde,
                    fie prin conectarea dispozitivului la priză.
                </p>

                <p>
                    În timp ce lil SOLID 2.0 se activează, LED-ul de stare se aprinde de 2 ori, în
                    această ordine: roșu, portocaliu, albastru deschis, albastru.
                </p>

                <p>
                    După activare, LED-ul se va aprinde intermitent albastru deschis pentru 1
                    secundă, iar dispozitivul vibrează, în același timp, de 2 ori. Acum ești gata
                    pentru prima utilizare!
                </p>
            </div>

            <ArticleContainer background={'#34303D'} textColor={'#F6F4F0'}>
                <div className={classnames(styles.whiteText, 'content')}>
                    <h3>Ești pregătit pentru prima utilizare?</h3>

                    <p>
                        Pentru a te bucura de experiența lil SOLID 2.0, urmează pașii de mai jos. E
                        simplu!
                    </p>

                    {FirstUseList.map((f: FirstUse) => {
                        return (
                            <p key={f.id}>
                                <span className={styles.listIndex}>{f.id}. </span>
                                {f.description}
                            </p>
                        );
                    })}

                    <h3>Atât de simplu!</h3>
                </div>
            </ArticleContainer>

            <div className={styles.lilSolidWrapper}>
                <ArticleContainer background={'#00D1D2'} textColor={'#34303D'}>
                    <div className="content">
                        <h3>
                            Descoperă, pas cu pas, cum poți utiliza lil SOLID 2.0, din tutorialul
                            video de mai jos:
                        </h3>

                        <ArticleImage
                            isVideo
                            mobileImage={img2Mobile}
                            image={img2}
                            onClick={() => setToggleModal(true)}
                        />

                        <VideoModal
                            toggleModal={toggleModal}
                            setToggleModal={() => setToggleModal(false)}
                            video="764107275"
                        />
                    </div>
                </ArticleContainer>
            </div>
        </div>
    );
};

export default AllAboutLilSolid;
