import { FC, useState, Children, cloneElement, ReactElement, useEffect, useCallback } from 'react';
import { IKeenSliderProps } from './interface';

// Styles
import classnames from 'classnames';
import 'keen-slider/keen-slider.min.css';
import styles from './KeenSlider.module.scss';

// Assets
import Arrow from './assets/arrow.svg';
import DarkArrow from './assets/dark-arrow.svg';
import WhiteArrow from './assets/white-arrow.svg';

// Components
import { Tabs } from 'antd';
import { useKeenSlider } from 'keen-slider/react';

const KeenSlider: FC<IKeenSliderProps> = ({
    className,
    settings,
    children,
    darkArrows,
    whiteArrows,
    withArrows = true,
    topArrows = true,
    arrowsShouldDisable = false,
    withDots = false,
    withTabs = false,
    tabData,
    dotColor,
    spacing = 16,
    mode = 'free',
    currentSlideCallback,
    animated = false,
    infinite = false,
    origin = 'auto',
    lastSlide = 1,
    autoSwitch = false,
    timeoutBetweenSlides = 2000,
    reset = false,
    navigateToSlide = 0,
    dotsClass,
    perView = 'auto',
    drag = true
}: IKeenSliderProps) => {
    const animation = { duration: 60000, easing: (t: number) => t };
    const slidesNumber = Children.count(children);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [refAvailable, setRefAvailable] = useState(false);

    const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>(
        {
            mode: mode,
            renderMode: 'performance',
            loop: animated || infinite,
            rubberband: false,
            drag: drag,
            slides: { spacing: spacing, perView: perView, origin: origin },
            slideChanged(slider) {
                setCurrentSlide(slider.track.details.rel);
            },

            created(s) {
                if (animated) {
                    s.moveToIdx(5, true, animation);
                    s.container.addEventListener('mouseover', () => {
                        s.moveToIdx(s.track.details.abs, true, {
                            duration: Infinity,
                            easing: (t: number) => t
                        });
                    });
                    s.container.addEventListener('mouseout', () => {
                        s.moveToIdx(s.track.details.abs + 5, true, animation);
                    });
                }
            },
            updated(s) {
                if (animated) {
                    s.moveToIdx(s.track.details.abs + 5, true, animation);
                    s.container.addEventListener('mouseover', () => {
                        s.moveToIdx(s.track.details.abs, true, {
                            duration: Infinity,
                            easing: (t: number) => t
                        });
                    });
                    s.container.addEventListener('mouseout', () => {
                        s.moveToIdx(s.track.details.abs + 5, true, animation);
                    });
                }
            },
            animationEnded(s) {
                if (animated) {
                    s.container.addEventListener('mouseover', () => {
                        s.moveToIdx(s.track.details.abs, true, {
                            duration: Infinity,
                            easing: (t: number) => t
                        });
                    });
                    s.container.addEventListener('mouseout', () => {
                        s.moveToIdx(s.track.details.abs + 5, true, animation);
                    });
                }
            },
            ...settings
        },
        [
            (s) => {
                if (autoSwitch) {
                    let timeout: ReturnType<typeof setTimeout>;
                    let mouseOver = false;
                    const clearNextTimeout = () => {
                        clearTimeout(timeout);
                    };
                    const nextTimeout = () => {
                        clearTimeout(timeout);
                        if (mouseOver) return;
                        timeout = setTimeout(() => {
                            s.next();
                        }, timeoutBetweenSlides);
                    };
                    s.on('created', () => {
                        s.container.addEventListener('mouseover', () => {
                            mouseOver = true;
                            clearNextTimeout();
                        });
                        s.container.addEventListener('mouseout', () => {
                            mouseOver = false;
                            nextTimeout();
                        });
                        nextTimeout();
                    });
                    s.on('dragStarted', clearNextTimeout);
                    s.on('animationEnded', nextTimeout);
                    s.on('updated', nextTimeout);
                }
            }
        ]
    );

    const goToSlide = (idx: number) => {
        instanceRef.current?.moveToIdx(idx);
    };

    useEffect(() => {
        if (currentSlideCallback) {
            currentSlideCallback(currentSlide);
        }
    }, [currentSlide]);

    useEffect(() => {
        if (navigateToSlide >= 0) {
            goToSlide(navigateToSlide);
        }
    }, [navigateToSlide]);

    // slider update
    useEffect(() => {
        if (reset) {
            return () => {
                instanceRef.current?.update(
                    {
                        mode: mode,
                        renderMode: 'performance',
                        loop: animated || infinite,
                        rubberband: false,
                        slides: { spacing: spacing, perView: perView, origin: origin },
                        slideChanged(slider) {
                            setCurrentSlide(slider.track.details.rel);
                        },
                        created(s) {
                            if (animated) {
                                s.moveToIdx(5, true, animation);
                                s.container.addEventListener('mouseover', () => {
                                    s.moveToIdx(s.track.details.abs, true, {
                                        duration: Infinity,
                                        easing: (t: number) => t
                                    });
                                });
                                s.container.addEventListener('mouseout', () => {
                                    s.moveToIdx(s.track.details.abs + 5, true, animation);
                                });
                            }
                        },
                        updated(s) {
                            if (animated) {
                                s.moveToIdx(s.track.details.abs + 5, true, animation);
                                s.container.addEventListener('mouseover', () => {
                                    s.moveToIdx(s.track.details.abs, true, {
                                        duration: Infinity,
                                        easing: (t: number) => t
                                    });
                                });
                                s.container.addEventListener('mouseout', () => {
                                    s.moveToIdx(s.track.details.abs + 5, true, animation);
                                });
                            }
                        },
                        animationEnded(s) {
                            if (animated) {
                                s.container.addEventListener('mouseover', () => {
                                    s.moveToIdx(s.track.details.abs, true, {
                                        duration: Infinity,
                                        easing: (t: number) => t
                                    });
                                });
                                s.container.addEventListener('mouseout', () => {
                                    s.moveToIdx(s.track.details.abs + 5, true, animation);
                                });
                            }
                        },
                        ...settings
                    },
                    0
                );
            };
        }
    }, [children]);

    useEffect(() => {
        if (instanceRef.current) {
            setRefAvailable(true);
        }
    }, [instanceRef]);

    return (
        <div className={classnames(styles.wrapper, className)}>
            {withArrows && topArrows && (
                <div className={classnames(styles.arrows, 'keen-arrows')}>
                    <img
                        alt=""
                        src={darkArrows ? DarkArrow : whiteArrows ? WhiteArrow : Arrow}
                        onClick={() => instanceRef.current?.prev()}
                        className={classnames(
                            styles.arrowLeft,
                            (arrowsShouldDisable && currentSlide === 0) && styles.arrowDisabled
                        )}
                    />
                    <img
                        alt=""
                        src={darkArrows ? DarkArrow : whiteArrows ? WhiteArrow : Arrow}
                        onClick={() => instanceRef.current?.next()}
                        className={classnames(
                            styles.arrowRight,
                            (arrowsShouldDisable && currentSlide === slidesNumber - lastSlide) && styles.arrowDisabled
                        )}
                    />
                </div>
            )}

            {withTabs && (
                <div className={styles.tabs}>
                    <Tabs
                        defaultActiveKey="1"
                        centered
                        type="card"
                        tabPosition="top"
                        onChange={(key) => setCurrentSlide(Number(key))}
                        onTabClick={(key) => goToSlide(Number(key))}
                        activeKey={currentSlide.toString()}
                        items={tabData}
                    />
                </div>
            )}

            <div ref={sliderRef} className="keen-slider">
                {Children.map(children, (child) => {
                    const item = child as ReactElement<any>;
                    return cloneElement(item, {
                        className: classnames(
                            'keen-slider__slide',
                            styles.slide,
                            item.props.className
                        )
                    });
                })}
            </div>

            {withArrows && !topArrows && (
                <div className={classnames(styles.bottomArrows, 'keen-arrows')}>
                    <img
                        alt=""
                        src={darkArrows ? DarkArrow : Arrow}
                        onClick={() => instanceRef.current?.prev()}
                        className={classnames(
                            styles.arrowLeft,
                            (arrowsShouldDisable && currentSlide === 0) && styles.arrowDisabled
                        )}
                    />
                    <img
                        alt=""
                        src={darkArrows ? DarkArrow : Arrow}
                        onClick={() => instanceRef.current?.next()}
                        className={classnames(
                            styles.arrowRight,
                            (arrowsShouldDisable && currentSlide === slidesNumber - lastSlide) && styles.arrowDisabled
                        )}
                    />
                </div>
            )}

            {withDots && refAvailable && (
                <div className={classnames(styles.dots, dotsClass)}>
                    {[...Array(instanceRef?.current?.track.details.slides.length).keys()].map(
                        (idx) => {
                            return (
                                <button
                                    title="dot"
                                    type="button"
                                    key={idx}
                                    onClick={() => {
                                        instanceRef.current?.moveToIdx(idx);
                                    }}
                                    className={classnames(
                                        styles.dot,
                                        currentSlide === idx ? styles.active : ''
                                    )}
                                    style={{ background: dotColor }}
                                />
                            );
                        }
                    )}
                </div>
            )}
        </div>
    );
};

export default KeenSlider;
