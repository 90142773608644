import { useState } from 'react';
import styles from './CollectiveStoryIqos.module.scss';

// Helpers
import classNames from 'classnames';

// Assets
import videoThumbnail from './assets/videoThumbnail.png';
import videoThumbnail1 from './assets/videoThumbnail2.png';

// Components
import VideoModal from '@components/VideoModal';
import ArticleImage from '@containers/IQOSUniverse/components/ArticleImage';
import ArticleHeader from '@containers/IQOSUniverse/components/ArticleHeader';
import ArticleContainer from '@containers/IQOSUniverse/components/ArticleContainer';

const CollectiveStoryIqos = () => {
    const [toggleModal, setToggleModal] = useState(false);
    const [toggleModal1, setToggleModal1] = useState(false);

    return (
        <section className={styles.wrapper}>
            <ArticleHeader />
            <div className="content">
                <h3>Suntem povestitorii vieții noastre</h3>

                <p>
                    Comunitatea din care facem parte, IQOS Club, ne-a adus pe toți într-o poveste
                    comună: deși suntem diferiți, ne bucurăm zilnic de alternativa mai bună pe care
                    am ales-o.
                </p>

                <p>
                    Inspirați de asta, cu ocazia TIFF, am răspuns cu toții la o provocare unică:
                    aceea de a ne scrie povestea. Împreună. Așa cum suntem zi de zi.
                </p>
            </div>

            <ArticleContainer background={'#34303D'} textColor={'#FFFDFB'}>
                <div className={classNames(styles.collectiveContent, 'content')}>
                    <ArticleImage
                        isVideo
                        mobileImage={videoThumbnail}
                        image={videoThumbnail}
                        onClick={() => setToggleModal(true)}
                    />

                    <VideoModal
                        toggleModal={toggleModal}
                        setToggleModal={() => setToggleModal(false)}
                        video={'738594085'}
                    />

                    <h3 className={classNames(styles.marginTop, styles.white)}>Play și descoperă atmosfera festivalului</h3>

                    <p className={styles.white}>
                        Chiar dacă ai fost acolo sau nu, am filmat cele mai tari momente, ca să-ți
                        oferim șansa să vezi și tu cum a fost. Am descoperit împreună tot ce-i mai
                        frumos din jazz și am creat amintiri de care ne vom bucura mult timp de acum
                        înainte.
                    </p>

                    <p className={styles.white}>
                        În plus, poți urmări și livestreamurile făcute de noi pentru membrii
                        comunității IQOS Club.
                    </p>
                </div>
            </ArticleContainer>

            <div className={classNames(styles.collectiveContent, 'content')}>
                <ArticleImage
                    isVideo
                    mobileImage={videoThumbnail1}
                    image={videoThumbnail1}
                    onClick={() => setToggleModal1(true)}
                />

                <VideoModal
                    toggleModal={toggleModal1}
                    setToggleModal={() => setToggleModal1(false)}
                    video={'741875568'}
                />

                <h3 className={styles.marginTop}>Am surprins atmosfera de la TIFF</h3>

                <p>
                    Ediția de anul acesta a Festivalului Internațional de Film Transilvania a fost
                    plină de entuziasm: 200 de filme, 55 de țări și peste 100.000 de participanți.
                    Fie că ai fost printre aceștia și vrei să retrăiești farmecul acelor zile sau nu
                    ai reușit să ajungi, descoperă în clipul de mai jos cum s-a văzut festivalul
                    prin ochii noștri!
                </p>
            </div>
        </section>
    );
};

export default CollectiveStoryIqos;
