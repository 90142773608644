import { useEffect, useState } from 'react';
import styles from './MotivationCardDragGame.module.scss';

// Helpers
import classnames from 'classnames';
import { useWindowSize } from '@utils/hooks/windowSize';
import {
    dragGameData,
    sliderData
} from '@containers/IQOSUniverse/articles/MotivationCardDragGame/data';

// Components
import CardDragGame from '@containers/IQOSUniverse/articles/MotivationCardDragGame/components/CardDragGame';
import BaseLoader from '@components/Layout/BaseLoader';
import Button from '@components/Layout/Button';
import KeenSlider from '@components/KeenSlider';

// Redux
import { useAppDispatch, useAppSelector } from '@app/hooks';
import {
    getGame,
    postArticlesActionCTR,
    postGameActionCTR,
    resetGame
} from '@containers/IQOSUniverse/IQOSUniverseSlice';

const MotivationCardDragGame = () => {
    const dispatch = useAppDispatch();
    const { article, articleGame, status } = useAppSelector((state) => state.iqosUniverse);

    const [showGame, setShowGame] = useState(false);
    const [success, setSuccess] = useState<boolean | null>(null);

    const window = useWindowSize();
    const isMobile = window.width < 650;

    useEffect(() => {
        if (!articleGame) {
            dispatch(getGame(article.cta_link || ' '));
        }
        return () => {
            dispatch(resetGame());
        };
    }, [articleGame?.activity_campaign_id]);

    const onLose = () => {
        dispatch(postGameActionCTR({ gameId: articleGame?.id || 0, winner: 0 }));
        setSuccess(false);
        setShowGame(false);
    };
    const onSuccess = () => {
        setTimeout(() => {
            dispatch(postGameActionCTR({ gameId: articleGame?.id || 0, winner: 1 }));
            setSuccess(true);
            setShowGame(false);
        }, 3000);
    };

    const startGame = () => {
        if (articleGame) {
            dispatch(postArticlesActionCTR(`user_motivation_game_${articleGame.id}`));
        }
        setShowGame(true);
    };

    if (status === 'loading') {
        return <BaseLoader size={'normal'} />;
    }

    return (
        <section
            className={classnames(
                styles.wrapper,
                showGame && styles.transparentBackground,
                success !== null && styles.transparentBackground
            )}
        >
            {showGame ? (
                <CardDragGame
                    data={
                        dragGameData.filter((game: any) => game.slug === article.cta_link)[0]?.data
                    }
                    onWin={onSuccess}
                    onLose={onLose}
                />
            ) : (
                <>
                    <div className={styles.gameContainer}>
                        <img
                            src={
                                dragGameData.filter(
                                    (game: any) => game.slug === article.cta_link
                                )[0]?.header
                            }
                            alt={'headerImg'}
                        />
                        <h3
                            className={classnames(
                                success !== null ? (!success ? styles.red : styles.turquoise) : ''
                            )}
                        >
                            {success === null
                                ? dragGameData.filter(
                                      (game: any) => game.slug === article.cta_link
                                  )[0]?.title
                                : success
                                ? dragGameData.filter(
                                      (game: any) => game.slug === article.cta_link
                                  )[0]?.successTitle
                                : dragGameData.filter(
                                      (game: any) => game.slug === article.cta_link
                                  )[0]?.failTitle}
                        </h3>

                        {success !== null && (
                            <h3 className={styles.subtitle}>
                                {success
                                    ? dragGameData.filter(
                                          (game: any) => game.slug === article.cta_link
                                      )[0]?.successSubTitle
                                    : dragGameData.filter(
                                          (game: any) => game.slug === article.cta_link
                                      )[0]?.failSubTitle}
                            </h3>
                        )}

                        <p
                            dangerouslySetInnerHTML={{
                                __html:
                                    success === null
                                        ? dragGameData.filter(
                                              (game: any) => game.slug === article.cta_link
                                          )[0]?.description
                                        : success
                                        ? dragGameData.filter(
                                              (game: any) => game.slug === article.cta_link
                                          )[0]?.successText
                                        : dragGameData.filter(
                                              (game: any) => game.slug === article.cta_link
                                          )[0]?.failText
                            }}
                        />
                    </div>

                    {!showGame && success !== null && (
                        <div className={styles.sliderSide}>
                            <KeenSlider
                                autoSwitch={true}
                                withArrows={true}
                                infinite={true}
                                timeoutBetweenSlides={3000}
                                perView={1}
                                mode="snap"
                            >
                                {sliderData.map((slide: any) => (
                                    <div className={styles.slideItem} key={slide.id}>
                                        <img src={slide.image} alt={`motivation${slide.id}`} />
                                        <div className={styles.slideDetails}>
                                            <h3>{slide.title}</h3>
                                            <p
                                                dangerouslySetInnerHTML={{ __html: slide.subtitle }}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </KeenSlider>
                        </div>
                    )}

                    {!success && (
                        <Button
                            type={'solid'}
                            label={success === null ? 'ÎNCEPE' : 'Încearcă din nou'}
                            color={'dark'}
                            hover={success === null ? 'light' : 'turquoise'}
                            onClick={startGame}
                        />
                    )}
                </>
            )}
        </section>
    );
};

export default MotivationCardDragGame;
