export const slides = [
    {
        id: 1,
        video: '876354237',
        name: 'Ioana',
        description:
            'Ioana este pasionată de călătorii, pentru că adoră să descopere culturi noi. La job este specialist în experiența consumatorilor, un domeniu care îi place foarte mult.'
    },
    {
        id: 2,
        video: '876354158',
        name: 'Adrian',
        description:
            'Adrian vine din București, e pasionat de darts și călătorii cu motocicleta și-i place să petreacă timp cu animalele lui de companie.'
    },
    {
        id: 3,
        video: '876354188',
        name: 'Anca',
        description:
            'Anca e inginer, iar zilele ei încep și se termină cu IQOS. Cea mai mare pasiune a ei e gătitul, pentru că îi place să îmbine arome și să descopere ingrediente și preparate noi.'
    },
    {
        id: 4,
        video: '876354258',
        name: 'Maria',
        description:
            'Maria este graphic designer, iar pasiunea ei numărul 1 este arta. De câțiva ani a reușit să-și pornească propria afacere, care îi aduce foarte multă bucurie.'
    },
    {
        id: 5,
        video: '876354209',
        name: 'Cristina',
        description:
            'Cristina locuiește în Arad și e pasionată de meditație, yoga și citit. Își petrece marea majoritate a timpului liber în natură, unde se reîncarcă cu energie.'
    }
];
