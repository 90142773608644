import { IButtonProps } from '@components/Layout/Button/interface';
import styles from '@components/Layout/Button/Button.module.scss';
import classnames from 'classnames';
import { ThreeDots } from 'react-loader-spinner';

const BaseButton = (props: IButtonProps) => {
    const {
        type = 'solid',
        color = 'dark',
        size = 'normal',
        disabled = false,
        fullwidth = false,
        noStyle = false,
        label,
        hover,
        onClick,
        children,
        className,
        icon,
        loading
    } = props;

    return (
        <button
            type="button"
            disabled={loading || disabled}
            className={classnames(
                styles.button,
                styles[type],
                styles[size],
                styles[color],
                styles[`${hover}--hover`],
                fullwidth ? styles.fullwidth : '',
                loading || disabled ? styles.disabled : '',
                loading ? styles.loading : '',
                noStyle ? styles.noStyle : '',
                className
            )}
            onClick={onClick}
        >
            {loading && !noStyle ? (
                <ThreeDots
                    height="22"
                    width="32"
                    radius="10"
                    color="#8C8A90"
                    wrapperStyle={{ justifyContent: 'center' }}
                />
            ) : children ? (
                children
            ) : (
                <>
                    {label && (
                        <span
                            dangerouslySetInnerHTML={{ __html: label }}
                            style={{ width: icon ? 'auto' : '100%' }}
                        />
                    )}
                    {icon}
                </>
            )}
        </button>
    );
};

export default BaseButton;
