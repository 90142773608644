export const MenuIcon = () => {
    return (
        <svg
            width="23"
            height="22"
            viewBox="0 0 23 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_133_897)">
                <path
                    d="M22.0715 3.14288H1.64288"
                    stroke="#C4C3C6"
                    strokeWidth="1.71429"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M22.0715 11H1.64288"
                    stroke="#C4C3C6"
                    strokeWidth="1.71429"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M22.0715 18.8572H1.64288"
                    stroke="#C4C3C6"
                    strokeWidth="1.71429"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_133_897">
                    <rect width="22" height="22" fill="white" transform="translate(0.857178)" />
                </clipPath>
            </defs>
        </svg>
    );
};
