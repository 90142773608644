import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@app/store';

import axiosRequest from '@utils/axios';

export interface Slide {
    id: number;
    name: string;
    background_gift_card: string;
    gift_image: string;
    content: { first_description: string; second_description: string }[];
    switched: boolean;
    label: string;
    telcor_name: string;
}

export interface FormData {
    gift_id: number;
    county: string;
    city: string;
    street: string;
    street_number: number;
    building_number: number;
    floor: number;
    apartment_number: number;
    observations: string;
    phone: string;
}

export interface RewardsState {
    status: 'idle' | 'loading' | 'success' | 'failed' | 'formError';
    eligibilityStatus: 'idle' | 'loading' | 'success' | 'failed';
    giftStatus: 'idle' | 'loading' | 'success' | 'failed';
    campaign_slug: string;
    gift_id: number | null;
    slides: Slide[];
    giftOptions: { value: number; label: string; gift_image: string }[];
    selectedSlide: Slide;
    redeemSuccess: boolean;
    expired_at: string;
    error: any;
    data: { device: string; stage: number };
    gift: any;
}

interface AxiosError {
    response: {
        data: {
            errors: object;
            message: string;
        };
    };
}

const initialState: RewardsState = {
    status: 'idle',
    eligibilityStatus: 'idle',
    giftStatus: 'idle',
    campaign_slug: '',
    gift_id: null,
    slides: [],
    giftOptions: [],
    selectedSlide: {
        id: 0,
        label: '',
        name: '',
        background_gift_card: '',
        gift_image: '',
        content: [],
        switched: false,
        telcor_name: ''
    },
    data: {
        device: '',
        stage: 1
    },
    gift: null,
    redeemSuccess: false,
    expired_at: '',
    error: {}
};

export const getGifts = createAsyncThunk(
    'containers/Rewards/gifts',
    async (values: string, { rejectWithValue }) => {
        try {
            const response = await axiosRequest.get(`user/gifts-redemption/${values}/gifts`);
            return response.data.data;
        } catch (e) {
            return rejectWithValue((e as AxiosError).response.data);
        }
    }
);

export const redeemGift = createAsyncThunk(
    'containers/Rewards/redeem',
    async (values: { formData: FormData; param: string }, { rejectWithValue }) => {
        try {
            const response = await axiosRequest.post(
                `user/user-gifts-redemption/${values.param}/redeem`,
                values.formData
            );
            return response.data;
        } catch (e) {
            return rejectWithValue((e as AxiosError).response.data);
        }
    }
);

export const checkEligibility = createAsyncThunk(
    'containers/Rewards/checkEligibility',
    async (hashId: string, { rejectWithValue }) => {
        try {
            const response = await axiosRequest.get(
                `user/user-gifts-redemption/${hashId}/eligibility`
            );
            return response.data;
        } catch (e) {
            return rejectWithValue((e as AxiosError).response.data.message);
        }
    }
);

export const checkEligibilityModal = createAsyncThunk(
    'containers/Rewards/checkEligibilityrModal',
    async (slug: string, { rejectWithValue }) => {
        try {
            const response = await axiosRequest.get(`user/gifts-redemption/${slug}/eligibility`);
            return response.data;
        } catch (e) {
            return rejectWithValue((e as AxiosError).response.data.message);
        }
    }
);

export const rewardSlice = createSlice({
    name: 'reward',
    initialState,
    reducers: {
        switchPrize: (state, action) => {
            state.slides = state.slides.map((qm) => {
                if (action.payload.id === qm.id) {
                    qm.switched = !qm.switched;
                } else {
                    qm.switched = false;
                }
                return qm;
            });
        },
        resetError: (state) => {
            state.error = {};
        },
        setSelectedGift: (state, action) => {
            state.selectedSlide = action.payload.gifts.filter(
                (gift: any) => Number(gift.value) === Number(state.gift_id)
            )[0];
        }
    },

    extraReducers: (builder) => {
        builder

            // Check Eligibility
            .addCase(checkEligibility.pending, (state) => {
                state.eligibilityStatus = 'loading';
            })
            .addCase(checkEligibility.fulfilled, (state, action) => {
                state.eligibilityStatus = 'success';
                state.expired_at = action.payload.expired_at;
                state.campaign_slug = action.payload.campaign_slug;
                state.gift_id = action.payload.gift?.id || null;
                state.data = action.payload.data;
                state.gift = action.payload.gift;
            })
            .addCase(checkEligibility.rejected, (state) => {
                state.eligibilityStatus = 'failed';
            })

            // Check Modal
            .addCase(checkEligibilityModal.pending, (state) => {
                state.eligibilityStatus = 'loading';
            })
            .addCase(checkEligibilityModal.fulfilled, (state, action) => {
                state.eligibilityStatus = 'success';
                state.expired_at = action.payload.expired_at;
            })
            .addCase(checkEligibilityModal.rejected, (state) => {
                state.eligibilityStatus = 'failed';
            })

            // Get Gifts
            .addCase(getGifts.pending, (state) => {
                state.status = 'loading';
                state.giftStatus = 'loading';
            })
            .addCase(getGifts.fulfilled, (state, action) => {
                state.status = 'success';
                state.giftStatus = 'success';
                state.slides = action.payload.map((s: Slide) => {
                    s.switched = false;

                    return s;
                });

                state.giftOptions = action.payload.map(
                    ({
                        id,
                        name,
                        gift_image,
                        background_gift_card,
                        content
                    }: {
                        id: any;
                        name: any;
                        gift_image: any;
                        background_gift_card: any;
                        content: { first_description: string; second_description: string }[];
                    }) => ({
                        value: id,
                        label: name,
                        gift_image: gift_image,
                        background_gift_card: background_gift_card,
                        content: content
                    })
                );
            })
            .addCase(getGifts.rejected, (state) => {
                state.status = 'failed';
                state.giftStatus = 'failed';
            })

            // Redeem Gift
            .addCase(redeemGift.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(redeemGift.fulfilled, (state) => {
                state.status = 'success';
                state.redeemSuccess = true;
            })
            .addCase(redeemGift.rejected, (state, action) => {
                state.status = 'formError';
                state.error = action.payload;
            })

            .addCase(resetState, () => initialState);
    }
});

export const { switchPrize, resetError, setSelectedGift } = rewardSlice.actions;

export const resetState = createAction('REVERT_ALL');
export const selectLoading = (state: RootState) => state.reward.status === 'loading';
export const selectSuccess = (state: RootState) => state.reward.status === "success";
export const selectFailed = (state: RootState) => state.reward.status === "failed";

export default rewardSlice.reducer;
