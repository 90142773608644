export const BadgeIcon = () => {
    return (
        <svg
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M17 33C25.8366 33 33 25.8366 33 17C33 8.16344 25.8366 1 17 1C8.16344 1 1 8.16344 1 17C1 25.8366 8.16344 33 17 33Z"
                fill="#FFFDFB"
                stroke="#34303D"
            />
            <path
                d="M17 3.23975C20.6494 3.23975 24.1493 4.68946 26.7298 7.26996C29.3103 9.85046 30.76 13.3504 30.76 16.9997C30.76 20.6491 29.3103 24.149 26.7298 26.7295C24.1493 29.31 20.6494 30.7597 17 30.7597V3.23975Z"
                fill="#00D1D2"
            />
            <path
                d="M22.4801 22.2173C21.1682 23.939 19.2 25.4189 16.5415 26.6411C16.5278 26.6399 16.514 26.6394 16.5 26.6394C16.486 26.6394 16.4722 26.6399 16.4585 26.6411C13.8335 25.4342 11.8813 23.976 10.5696 22.2817C9.22588 20.546 8.53575 18.539 8.50208 16.2416C8.50208 16.2414 8.50207 16.2412 8.50207 16.241L8.5 16.069V11.1216L16.4482 7.35254L24.5 11.1233V16.0707C24.4985 18.4118 23.8237 20.454 22.4801 22.2173Z"
                fill="#FFFDFB"
                stroke="#34303D"
                strokeLinejoin="round"
            />
            <path
                d="M16.4719 11L21 13.3562V16.4537C20.9983 19.3299 19.5021 21.5118 16.5107 23L16.5 22.9644L16.4893 23C13.5345 21.5299 12.0383 19.383 12.0011 16.5579L12 16.4543V13.3562L16.4719 11Z"
                fill="#00D1D2"
            />
        </svg>
    );
};
