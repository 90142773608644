import { useState } from 'react';
import styles from './IqosCocktails.module.scss';

// Helpers
import classNames from 'classnames';
import { IqosCocktailsSliderData } from '@containers/IQOSUniverse/articles/IqosNova/IqosCocktailsSliderData';

// Assets
import videoThumbnail from './assets/videoThumbnail.jpg';

// Components
import VideoModal from '@components/VideoModal';
import KeenSlider from '@components/KeenSlider';
import ArticleImage from '@containers/IQOSUniverse/components/ArticleImage';
import ArticleHeader from '@containers/IQOSUniverse/components/ArticleHeader';
import ArticleContainer from '@containers/IQOSUniverse/components/ArticleContainer';

const IqosCocktails = () => {
    const [toggleModal, setToggleModal] = useState(false);
    const [currentVideo, setCurrentVideo] = useState('');

    const handleShowModal = (videoId: string) => {
        setCurrentVideo(videoId);
        setToggleModal(true);
    };

    const handleCloseModal = () => {
        setCurrentVideo('');
        setToggleModal(false);
    };

    return (
        <section className={styles.wrapper}>
            <ArticleHeader />

            <div className="content">
                <h3>Cocktailuri cu aer de sărbătoare</h3>

                <p>
                    Deja ai aflat de la designer-ul de interior Adela Pârvu cum să creezi un decor
                    inedit de sărbători, iar acum e timpul să descoperi câteva trucuri care sigur
                    îți vor cuceri invitații la petrecerea de Crăciun.
                </p>

                <p>
                    Așadar, continuăm seria de mastercass-uri alături de expertul în mixologie Paul
                    Sfârlea, care te va introduce în universul său și-ți va dezvălui câteva
                    modalități inedite prin care poți reinterpreta rețetele preferate de cocktail,
                    dându-le un aer nou, în ton cu sărbătorile.
                </p>
            </div>

            <ArticleContainer background={'#00D1D2'}>
                <div className={classNames(styles.contentCocktails, 'content')}>
                    <h3 className={styles.title}>Masterclass de mixologie cu Paul Sfîrlea</h3>

                    <KeenSlider withArrows={false} mode="snap" origin="center" spacing={10}>
                        {IqosCocktailsSliderData.map(({ id, videoId, subtitle }) => {
                            return (
                                <div className={styles.slideContainer} key={id}>
                                    <ArticleImage
                                        isVideo
                                        mobileImage={videoThumbnail}
                                        image={videoThumbnail}
                                        onClick={() => handleShowModal(videoId)}
                                    />

                                    <h3 className={styles.subtitle}>{subtitle}</h3>
                                </div>
                            );
                        })}
                    </KeenSlider>

                    <VideoModal
                        toggleModal={toggleModal}
                        setToggleModal={handleCloseModal}
                        video={currentVideo}
                    />
                </div>
            </ArticleContainer>
        </section>
    );
};

export default IqosCocktails;
