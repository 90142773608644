import styles from './VideoModal.module.scss';
import { IVideoModalProps } from './interface';

// Components
import Vimeo from '@u-wave/react-vimeo';
import BaseModal from '@components/Layout/BaseModal';

export const VideoModal = (props: IVideoModalProps) => {
    const { toggleModal, setToggleModal, video, modalSize, lockScroll = true } = props;

    return (
        <BaseModal
            size={modalSize || 'large'}
            maskClosable={true}
            isModalOpen={toggleModal}
            className={styles.videoModal}
            closeModal={setToggleModal}
            destroyOnClose
            lockBodyScroll={lockScroll}
        >
            <Vimeo
                responsive={true}
                className={styles.player}
                video={video}
                autoplay={false}
                onEnd={setToggleModal}
            />
        </BaseModal>
    );
};
