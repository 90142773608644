import { createSlice } from '@reduxjs/toolkit';

export const tradeInModalSlice = createSlice({
    name: 'tradeInModal',
    initialState: {
        isOpen: false
    },
    reducers: {
        toggleTradeInModal: (state, action) => {
            state.isOpen = action.payload;
        }
    }
});

export const { toggleTradeInModal } = tradeInModalSlice.actions;
export default tradeInModalSlice.reducer;
