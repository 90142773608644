import imageIntro from './assets/imageIntro.jpg';
import martie from './assets/martie.jpg';
import aprilie from './assets/aprilie.jpg';
import mai from './assets/mai.jpg';
import iunie from './assets/iunie.jpg';
import iulie from './assets/iulie.jpg';
import august from './assets/august.jpg';
import septembrie from './assets/septembrie.jpg';
import octombrie from './assets/octombrie.jpg';
import noiembrie from './assets/noiembrie.jpg';
import decembrie from './assets/decembrie.jpg';

export const monthsButtonContent: Array<{
    id: number;
    label: string;
}> = [
    { id: 0, label: 'Intro' },
    { id: 1, label: 'Martie' },
    { id: 2, label: 'Aprilie' },
    { id: 3, label: 'Mai' },
    { id: 4, label: 'Iunie' },
    { id: 5, label: 'Iulie' },
    { id: 6, label: 'August' },
    { id: 7, label: 'Septembrie' },
    { id: 8, label: 'Octombrie' },
    { id: 9, label: 'Noiembrie' },
    { id: 10, label: 'Decembrie' }
];

export const monthContent: Array<{
    month: {
        id: number;
        photo?: any;
        videoId?: string;
        secondParagraph?: string;
        paragraph: string;
        title: string;
    }[];
}> = [
    {
        month: [
            {
                id: 1,
                photo: imageIntro,
                title: 'Un an al experiențelor care ne-au adus împreună.',
                paragraph:
                    'În 2022, lumea noastră, IQOS Club, a devenit mai vibrantă ca niciodată. Ne-am bucurat de lansări de noi dispozitive, am îmbinat arta cu tehnologia, am reușit să facem din această comunitate una și mai puternică.',
                secondParagraph:
                    'Ne-am reinventat și am evoluat împreună. Iar acum e momentul perfect să retrăim cele mai importante momente din călătoria noastră!'
            }
        ]
    },
    {
        month: [
            {
                id: 2,
                photo: martie,
                videoId: '686744156',
                title: 'IQOS x Raluca Băraru',
                paragraph:
                    'Un nou început, un nou mod de a privi lucrurile.Am reinventat mărțișorul alături de ilustratoarea Raluca Băraru.'
            }
        ]
    },
    {
        month: [
            {
                id: 3,
                photo: aprilie,
                videoId: '717453300',
                title: 'Universul IQOS Club și-a desemnat marele câștigător',
                paragraph:
                    'Am făcut cunoștință cu Mihaela, câștigătoarea SUV-ului electric BMW iX3. Iar reacția pe care a avut-o la aflarea veștii ne-a inspirat să pregătim noi campanii cu premii!'
            }
        ]
    },
    {
        month: [
            {
                id: 4,
                photo: mai,
                videoId: '716406433',
                title: 'We Edition IQOS 3 DUO',
                paragraph:
                    'Diferențele ne-au adus împreună într-o petrecere VIP la Nuba, în care am sărbătorit frumusețea diversității.'
            }
        ]
    },
    {
        month: [
            {
                id: 5,
                photo: iunie,
                videoId: '738594085',
                title: 'IQOS x TIFF',
                paragraph:
                    'Cu ocazia TIFF, am răspuns cu toții la o provocare unică: aceea de a ne scrie povestea. Împreună. Așa cum suntem zi de zi.'
            }
        ]
    },
    {
        month: [
            {
                id: 6,
                photo: iulie,
                videoId: '786241711',
                title: 'We upgraded summer',
                paragraph:
                    'Vara lui 2022 nu a semănat cu niciuna de până acum. Am dat refresh celui mai frumos sezon din an cu evenimente speciale, multă muzică și experiențe care ne-au ajutat să facem din vara asta vara noastră.'
            }
        ]
    },
    {
        month: [
            {
                id: 7,
                photo: august,
                videoId: '774687248',
                title: 'Am aflat totul despre excursiile la Londra',
                paragraph:
                    'Câștigătorii vacanțelor la Londra ne-au povestit despre experiențele trăite acolo într-un episod special din show-ul „IQOS răspunde”.'
            }
        ]
    },
    {
        month: [
            {
                id: 8,
                photo: septembrie,
                videoId: '761885316',
                title: 'City Experience Milano',
                paragraph:
                    'Milano este mai mult decât un oraș frumos. Este un loc gata să surprindă pe oricine cu noi experiențe senzoriale. Exact asta au descoperit și câștigătorii campaniei.'
            }
        ]
    },
    {
        month: [
            {
                id: 9,
                photo: octombrie,
                videoId: '786251691',
                title: 'IQOS x Apogeum',
                paragraph:
                    'Noile sortimente de HEETS Abora și Amelia ne-au inspirat să facem o călătorie a gusturilor la crama Apogeum, unde un câștigător din IQOS Club s-a bucurat de o zi de poveste.'
            }
        ]
    },
    {
        month: [
            {
                id: 10,
                photo: noiembrie,
                videoId: '783260480',
                title: 'Lansarea IQOS Originals',
                paragraph:
                    'Povestea IQOS continuă cu un nou capitol în culori vibrante: IQOS Originals. Inspirați de noile dispozitive, am creat o petrecere de lansare cu multe experiențe intense și surprize originale.'
            }
        ]
    },
    {
        month: [
            {
                id: 11,
                photo: decembrie,
                videoId: '788659492',
                title: 'Un premiu care deschide noi orizonturi',
                paragraph:
                    '„IQOS răspunde” ne-a prezentat-o într-un episod special pe câștigătoarea marelui premiu al campaniei „Pasiunea ta deschide noi orizonturi!” din IQOS Club.'
            }
        ]
    }
];
