import styles from './LampDinners2024.module.scss';
import ArticleHeader from '@containers/IQOSUniverse/components/ArticleHeader';
import React from 'react';

const LampDinners2024 = () => {
    return (
        <section className={styles.wrapper}>
            <ArticleHeader />

            <div className="content">
                <p>
                    Concursul{' '}
                    <strong>
                        „Câștigă o experiență IQOS alături de o persoană specială din viața ta!”{' '}
                    </strong>
                    și-a desemnat câștigătorii. Aceștia s-au bucurat, alături de acea persoană dragă
                    care le-a fost mereu alături, de o cină cu multe surprize la un restaurant din
                    orașul lor.
                </p>
            </div>
        </section>
    );
};

export default LampDinners2024;
