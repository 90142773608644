export const CommunityIcon = () => {
    return (
        <svg
            width="21"
            height="19"
            viewBox="0 0 21 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <mask id="mask0_224_2650" maskUnits="userSpaceOnUse" x="0" y="0" width="17" height="18">
                <path
                    d="M0 0L16.8077 0.105757V4.26496L6.03415 4.37071V17.1656L0 15.8861V0Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0_224_2650)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.61695 1.59839C1.14038 1.59839 0.753174 1.98154 0.753174 2.45485V10.4802C0.753174 10.9518 1.14038 11.335 1.6187 11.335H2.95028L3.01861 14.7105L6.75754 11.335H14.1128C14.5893 11.335 14.9765 10.9536 14.9765 10.4802V2.45311C14.9765 1.98154 14.5911 1.59839 14.1128 1.59839H1.61695V1.59839Z"
                    stroke="#C4C3C6"
                    strokeWidth="1.77033"
                    strokeLinejoin="round"
                />
            </g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.1362 4.26343C19.6128 4.26343 20 4.64658 20 5.11989V13.1453C20 13.6169 19.6145 14 19.1362 14H17.8029L17.7345 17.3756L13.9956 14H6.64039C6.16382 14 5.77661 13.6186 5.77661 13.1453V5.11815C5.77661 4.64658 6.16382 4.26343 6.64039 4.26343H19.1362Z"
                stroke="#C4C3C6"
                strokeWidth="1.77033"
                strokeLinejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.0837 9.0399C15.0837 9.4976 15.4446 9.86862 15.8914 9.86862C16.3382 9.86862 16.6991 9.4976 16.6991 9.0399C16.6991 8.5822 16.3382 8.21118 15.8932 8.21118C15.4446 8.21118 15.0837 8.5822 15.0837 9.0399ZM12.0666 9.0399C12.0666 9.4976 12.4276 9.86862 12.8743 9.86862C13.3211 9.86862 13.6821 9.4976 13.6821 9.0399C13.6821 8.5822 13.3211 8.21118 12.8761 8.21118C12.4276 8.21118 12.0666 8.5822 12.0666 9.0399ZM9.04956 9.0399C9.04956 9.4976 9.41224 9.87035 9.85727 9.87035C10.304 9.87035 10.665 9.4976 10.665 9.0399C10.665 8.5822 10.304 8.21118 9.85902 8.21118C9.41049 8.21118 9.04956 8.5822 9.04956 9.0399Z"
                fill="#C4C3C6"
                fillOpacity="0.9"
            />
        </svg>
    );
};
