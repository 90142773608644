export const VoucherIcon = () => (
    <svg width="33" height="34" viewBox="0 0 33 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="1" width="32" height="32" rx="16" fill="#F6F4F0"/>
        <path
            d="M16.5 33C25.3366 33 32.5 25.8366 32.5 17C32.5 8.16344 25.3366 1 16.5 1C7.66344 1 0.5 8.16344 0.5 17C0.5 25.8366 7.66344 33 16.5 33Z"
            stroke="#34303D"
        />
        <path
            d="M16.5 3.24023C20.1494 3.24023 23.6493 4.68994 26.2298 7.27044C28.8103 9.85095 30.26 13.3509 30.26 17.0002C30.26 20.6496 28.8103 24.1495 26.2298 26.73C23.6493 29.3105 20.1494 30.7602 16.5 30.7602V3.24023Z"
            fill="#00D1D2"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25.2605 10L26.0236 22.5789L7.70933 23.375L7.15435 20.4527C7.82033 20.2723 8.41693 19.9203 8.8904 19.4451C9.59626 18.7392 10.035 17.7628 10.035 16.6858C10.035 15.6088 9.59626 14.6323 8.8904 13.9265C8.38051 13.4149 7.72841 13.0454 7 12.8807V10.796L25.2605 10Z"
            fill="#F6F4F0"
            stroke="#34303D"
        />
        <path
            d="M16.6782 14.4329C16.6632 13.5748 15.9554 12.8913 15.0972 12.9062C14.2391 12.9212 13.5556 13.629 13.5705 14.4872C13.5855 15.3453 14.2933 16.0289 15.1515 16.0139C16.0096 15.9989 16.6932 15.2911 16.6782 14.4329Z"
            fill="#34303D"
        />
        <path
            d="M21.0083 18.7845C20.9933 17.9264 20.2855 17.2428 19.4273 17.2578C18.5692 17.2728 17.8856 17.9806 17.9006 18.8388C17.9156 19.6969 18.6234 20.3804 19.4816 20.3655C20.3397 20.3505 21.0232 19.6427 21.0083 18.7845Z"
            fill="#34303D"
        />
        <path d="M15.0478 18.8701L19.6638 14.2541" stroke="#34303D" strokeLinecap="round"/>
    </svg>
);

export const ForwardPointsIcon = () => (
    <svg width="33" height="34" viewBox="0 0 33 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="1" width="32" height="32" rx="16" fill="#F6F4F0"/>
        <path
            d="M16.5 33C25.3366 33 32.5 25.8366 32.5 17C32.5 8.16344 25.3366 1 16.5 1C7.66344 1 0.5 8.16344 0.5 17C0.5 25.8366 7.66344 33 16.5 33Z"
            stroke="#34303D"
        />
        <path
            d="M16.5 3.24023C20.1494 3.24023 23.6493 4.68994 26.2298 7.27044C28.8103 9.85095 30.26 13.3509 30.26 17.0002C30.26 20.6496 28.8103 24.1495 26.2298 26.73C23.6493 29.3105 20.1494 30.7602 16.5 30.7602V3.24023Z"
            fill="#00D1D2"
        />
        <path
            d="M16.4526 9.32422L18.6427 13.9892L23.5393 14.7354L19.9963 18.3668L20.8328 23.4945L16.4533 21.0733L12.0737 23.4945L12.9102 18.3668L9.36719 14.7354L14.2638 13.9873L16.4526 9.32422Z"
            fill="#FFFDFB"
            stroke="#34303D"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const ChancesIcon = () => (
    <svg width="33" height="34" viewBox="0 0 33 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="1" width="32" height="32" rx="16" fill="#F6F4F0"/>
        <path
            d="M16.5 33C25.3366 33 32.5 25.8366 32.5 17C32.5 8.16344 25.3366 1 16.5 1C7.66344 1 0.5 8.16344 0.5 17C0.5 25.8366 7.66344 33 16.5 33Z"
            stroke="#34303D"
        />
        <path
            d="M16.5 3.24023C20.1494 3.24023 23.6493 4.68994 26.2298 7.27044C28.8103 9.85095 30.26 13.3509 30.26 17.0002C30.26 20.6496 28.8103 24.1495 26.2298 26.73C23.6493 29.3105 20.1494 30.7602 16.5 30.7602V3.24023Z"
            fill="#00D1D2"
        />
        <path
            d="M18.5584 24.2004H15.2754V16.6238H11.4688L16.9143 9.36133L22.3598 16.6238H19.0768H18.5532V24.2004H18.5584Z"
            fill="#FFFDFB"
        />
        <path
            d="M18.0371 23.6698V16.0932H21.3202L16.9218 10.2288L12.5235 16.0932H15.8066V23.6698H18.0424M19.0896 24.717H14.7593V17.1404H11.4763C11.047 17.1404 10.8009 16.6482 11.0574 16.3026L16.503 9.04018C16.7124 8.76267 17.1313 8.75743 17.3407 9.04018L22.7863 16.3026C23.0428 16.6482 22.7967 17.1404 22.3674 17.1404H19.0844V24.717H19.0896Z"
            fill="#34303D"
        />
    </svg>
);

export const GiftIcon = () => (
    <svg width="33" height="34" viewBox="0 0 33 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="1" width="32" height="32" rx="16" fill="#F6F4F0"/>
        <path
            d="M16.5 33C25.3366 33 32.5 25.8366 32.5 17C32.5 8.16344 25.3366 1 16.5 1C7.66344 1 0.5 8.16344 0.5 17C0.5 25.8366 7.66344 33 16.5 33Z"
            stroke="#34303D"
        />
        <path
            d="M16.5 3.24219C20.1494 3.24219 23.6493 4.6919 26.2298 7.2724C28.8103 9.8529 30.26 13.3528 30.26 17.0022C30.26 20.6516 28.8103 24.1515 26.2298 26.732C23.6493 29.3125 20.1494 30.7622 16.5 30.7622V3.24219Z"
            fill="#00D1D2"
        />
        <path
            d="M23.9358 12.9297H8.49799C8.22296 12.9297 8 13.1526 8 13.4277V16.7563C8 17.0313 8.22296 17.2543 8.49799 17.2543H23.9358C24.2108 17.2543 24.4338 17.0313 24.4338 16.7563V13.4277C24.4338 13.1526 24.2108 12.9297 23.9358 12.9297Z"
            stroke="#34303D"
            strokeWidth="0.995988"
        />
        <path
            d="M22.7032 17.3379H9.73042C9.45538 17.3379 9.23242 17.5609 9.23242 17.8359V24.6972C9.23242 24.9723 9.45538 25.1952 9.73042 25.1952H22.7032C22.9782 25.1952 23.2012 24.9723 23.2012 24.6972V17.8359C23.2012 17.5609 22.9782 17.3379 22.7032 17.3379Z"
            fill="#F6F4F0"
            stroke="#34303D"
            strokeWidth="0.995988"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.2619 8.53987C13.5234 8.46816 13.7739 8.49306 14.0139 8.61557C14.6514 8.94125 15.1932 9.81523 15.7101 11.1563C15.9387 11.7489 16.102 12.2773 16.1916 12.74L16.1667 12.6219L16.0024 12.6204C14.5084 12.5861 12.9333 12.1125 12.0956 11.3426L12.002 11.2529C11.7246 10.9711 11.5488 10.6504 11.5847 10.2898C11.6201 9.93226 11.8481 9.56175 12.266 9.16934C12.6121 8.84415 12.9407 8.62802 13.2619 8.53987ZM18.2872 8.61557C18.5267 8.49256 18.7792 8.46866 19.0442 8.54037C19.3718 8.62901 19.7095 8.84664 20.067 9.17382C20.4953 9.56574 20.7319 9.93575 20.7727 10.2928C20.8135 10.6504 20.6407 10.9681 20.3673 11.2475C19.5282 12.1035 17.7797 12.6219 16.1742 12.6219L16.2131 12.6214L16.2205 12.5831C16.2942 12.2111 16.4133 11.7982 16.5716 11.3451L16.6423 11.1484C17.1314 9.81225 17.6553 8.93976 18.2872 8.61557Z"
            fill="#00D1D2"
            stroke="#34303D"
            strokeWidth="0.995988"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.8895 16.0865C17.5338 15.8595 18.2036 15.9625 18.732 16.2932C19.2596 16.6239 19.6448 17.1813 19.7224 17.8605C19.7985 18.5297 19.6085 19.1206 19.1392 19.5972C18.8521 19.8881 18.4844 20.2583 18.0356 20.7081L17.3403 21.4034C17.1465 21.5969 16.9418 21.8012 16.7257 22.016L16.2364 22.5035L15.7426 22.0188C15.48 21.7596 15.2558 21.5379 15.0696 21.3518L14.9376 21.2197C14.8535 21.1356 14.7787 21.0605 14.7141 20.9945L14.4882 20.7654C14.0981 20.3697 13.7077 19.9744 13.3263 19.57C12.7759 18.9875 12.5971 18.2866 12.8147 17.5141C12.9257 17.1202 13.1192 16.8017 13.378 16.5544C13.6371 16.3072 13.9647 16.1291 14.3628 16.0358C15.033 15.8787 15.6385 16.0201 16.1627 16.4333L16.1962 16.4612L16.2759 16.406C16.4484 16.2894 16.6118 16.1972 16.7847 16.1263L16.8895 16.0865Z"
            fill="#00D1D2"
            stroke="#00D1D2"
            strokeWidth="0.746991"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.2285 21.5219C16.2403 21.5128 16.247 21.5089 16.2522 21.5037C17.0495 20.7057 17.8506 19.9112 18.6426 19.1073C18.9583 18.7867 19.0806 18.3883 19.0296 17.9399C18.9248 17.0252 17.9896 16.4406 17.1225 16.7455C16.9025 16.823 16.7139 16.9477 16.5497 17.1115L16.212 17.4509C16.0839 17.3207 15.9676 17.1918 15.8398 17.0748C15.4637 16.7308 15.0208 16.5995 14.5239 16.7165C13.9913 16.8412 13.6369 17.1771 13.4881 17.7041C13.3414 18.2255 13.464 18.698 13.8352 19.0906C14.2868 19.569 14.7512 20.0359 15.2129 20.5049C15.5482 20.8451 15.8873 21.1807 16.2281 21.5215L16.2285 21.5219Z"
            stroke="#34303D"
        />
    </svg>
);

export const AdviceIcon = () => (
    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_3049_18317)">
            <path
                d="M26.1836 14.2269L30.1836 18.1887V31.6815"
                stroke="#00D1D2"
                strokeWidth="1.81818"
                strokeMiterlimit="10"
            />
            <path
                d="M18.1836 22.2269V26.0087C18.1836 27.4008 19.3208 28.5311 20.729 28.5311V31.6815"
                stroke="#00D1D2"
                strokeWidth="1.81818"
                strokeMiterlimit="10"
            />
            <path
                d="M27.6374 21.3625L22.1022 15.5182C21.764 15.1707 21.2947 14.9543 20.7805 14.9543C19.7487 14.9543 18.9102 15.8161 18.9102 16.8764C18.9102 17.4084 19.1207 17.8871 19.4588 18.2346L22.6509 21.5149V25.8449C22.6509 27.2599 23.7655 28.4089 25.1459 28.4089"
                stroke="#00D1D2"
                strokeWidth="1.81818"
                strokeMiterlimit="10"
            />
            <path
                d="M23.3937 17.0885L25.4228 15.1167C26.7906 13.7113 27.6372 11.7676 27.6372 9.61752C27.6372 5.33493 24.2788 1.8669 20.1368 1.8669C17.8174 1.8669 15.7428 2.95765 14.3645 4.66719C12.9897 2.95415 10.9151 1.8634 8.59224 1.8634C4.4502 1.8634 1.0918 5.33493 1.0918 9.61402C1.0918 11.7641 1.9384 13.7078 3.30625 15.1132L14.3645 25.8634L20.693 19.7105"
                stroke="#00D1D2"
                strokeWidth="1.81818"
                strokeMiterlimit="10"
            />
        </g>
        <defs>
            <clipPath id="clip0_3049_18317">
                <rect width="31.2727" height="32" fill="white" transform="translate(0 0.772461)"/>
            </clipPath>
        </defs>
    </svg>
);