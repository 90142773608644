import { useState, useEffect } from 'react';
import styles from './HangManGame.module.scss';
import { IHangManGameProps } from './interface';

// Helpers
import classnames from 'classnames';
import DOMPurify from 'dompurify';
import Button from '@components/Layout/Button';

const HangManGame = ({
    answer,
    chances,
    title,
    hint,
    finalMessage,
    handleClose
}: IHangManGameProps) => {
    const DEFAULT_SET = new Set();
    const MAX_WRONG_ANSWERS = chances;

    const [guessed, setGuessed] = useState(DEFAULT_SET);

    const [mistakes, setMistakes] = useState(0);
    const [success, setSuccess] = useState(false);
    const [gameOver, setGameOver] = useState(false);

    useEffect(() => {
        if (mistakes >= MAX_WRONG_ANSWERS && !gameOver) {
            setGameOver(true);
        }
    }, [mistakes]);

    useEffect(() => {
        const userAnswer = [...guessedWord()].join('');

        if (userAnswer === answer) {
            setSuccess(true);
        }
    }, [guessed]);

    const getActiveClass = (letter: any) => {
        return guessed.has(letter) && answer.indexOf(letter?.trim('').toString()) >= 0
            ? styles.correct
            : '';
    };

    const handleGuess = (letter: any) => {
        const updatedLetters = (guessed: any) => new Set(guessed).add(letter);
        const updatedMistakes = mistakes + (answer.includes(letter) ? 0 : 1);

        setGuessed(updatedLetters);
        setMistakes(updatedMistakes);
    };

    const generateButtons = () => {
        return 'qwertyuiopasdfghjklzxcvbnm'.split('').map((letter) => (
            <>
                <button
                    key={letter}
                    value={letter}
                    onClick={() => handleGuess(letter)}
                    disabled={guessed.has(letter)}
                    className={getActiveClass(letter)}
                >
                    {letter}
                </button>
            </>
        ));
    };

    const guessedWord = () => {
        return answer.split('').map((bingo) => {
            if (guessed.has(bingo)) {
                return bingo;
            } else {
                if (bingo === ' ') {
                    return ' ';
                } else {
                    return '_';
                }
            }
        });
    };

    const handleStartOver = () => {
        setGuessed(DEFAULT_SET);
        setMistakes(0);
        setGameOver(false);
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <div className={styles.gameWrapper}>
                    <div className={styles.heading}>
                        {title ? (
                            <h3 dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(title) }} />
                        ) : null}

                        {hint && !success ? (
                            <p>
                                <b>Indiciu:</b> {DOMPurify.sanitize(hint)}
                            </p>
                        ) : null}
                    </div>

                    {!success && (
                        <div
                            className={classnames(
                                !success && styles.answer,
                                gameOver ? styles.error : '',
                                success && styles.success
                            )}
                        >
                            {guessedWord()}
                        </div>
                    )}

                    {gameOver && !success ? (
                        <div className={styles.errMessage}>
                            <h3>
                                Ne pare rău, dar ai atins numărul maxim de litere introduse greșit.
                            </h3>

                            <Button
                                type="solid"
                                color="dark"
                                hover="turquoise"
                                label={'Reia jocul'}
                                onClick={() => handleStartOver()}
                            />
                        </div>
                    ) : null}

                    {success ? (
                        <div className={styles.successMessage}>
                            {success && <h2>{answer}</h2>}
                            <h3>
                                Felicitări! <br /> Ai descoperit cuvântul!
                            </h3>
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(finalMessage)
                                }}
                            />

                            <Button
                                type="outlined"
                                color="dark"
                                hover="turquoise"
                                label={'Închide'}
                                onClick={handleClose}
                            />
                        </div>
                    ) : (
                        <>
                            <div className={styles.status}>
                                Litere greșite:{' '}
                                <b>
                                    {mistakes} din {MAX_WRONG_ANSWERS}
                                </b>
                            </div>

                            <div
                                className={classnames(
                                    styles.keyboard,
                                    gameOver ? styles.disabled : ''
                                )}
                            >
                                {generateButtons()}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default HangManGame;
