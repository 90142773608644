export const StarCircled = () => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19Z"
                stroke="#34303D"
            />
            <path
                d="M10 2.26001C12.0528 2.26001 14.0215 3.07547 15.473 4.527C16.9245 5.97853 17.74 7.94723 17.74 10C17.74 12.0528 16.9245 14.0215 15.473 15.473C14.0215 16.9245 12.0528 17.74 10 17.74V2.26001Z"
                fill="#00D1D2"
            />
            <path
                d="M9.9739 5.6814L11.2058 8.30544L13.9602 8.7252L11.9672 10.7678L12.4377 13.6522L9.97426 12.2903L7.51078 13.6522L7.9813 10.7678L5.98834 8.7252L8.7427 8.30436L9.9739 5.6814Z"
                fill="#FFFDFB"
                stroke="#34303D"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
