export interface HeatsColor {
    id: number;
    color: string;
    description: string;
}

export const HeatsColorsList: HeatsColor[] = [
    {
        id: 1,
        color: 'blue',
        description: `<strong>albastru</strong> - dispozitivul este încărcat între 60 și 100%;`
    },
    {
        id: 2,
        color: 'skyBlue',
        description: `<strong>albastru deschis</strong> - dispozitivul este încărcat între 30 și 60%;`
    },
    {
        id: 3,
        color: 'amber',
        description: `<strong>portocaliu</strong> - dispozitivul este încărcat între 1 și 30%`
    },
    {
        id: 4,
        color: 'red',
        description: `<strong>roșu</strong> - dispozitivul trebuie încărcat.`
    }
];
