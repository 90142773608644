export const CollapseExpand = () => {
    return (
        <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="28" height="28" rx="14" fill="#F6F4F0" />
            <g opacity="0.7">
                <path
                    d="M18.628 14.0009L9.37402 14.0009"
                    stroke="#34303D"
                    strokeWidth="1.77033"
                    strokeLinecap="round"
                />
                <path
                    d="M14.001 9.37393L14.001 18.6279"
                    stroke="#34303D"
                    strokeWidth="1.77033"
                    strokeLinecap="round"
                />
            </g>
        </svg>
    );
};
