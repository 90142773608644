import * as R from 'ramda';
import { createId, distance, randomNth } from '../utility';
import store from 'store';

// Square images game 0
import purple from '../assets/purple.png';
import red from '../assets/red.png';
import blue from '../assets/blue.png';
import yellow from '../assets/yellow.png';
import green from '../assets/green.png';

// Square images game 1
import red2 from '../assets/red2.png';
import blue2 from '../assets/blue2.png';
import gold2 from '../assets/gold2.png';
import silver2 from '../assets/silver2.png';
import yellow2 from '../assets/yellow2.png';
import purple2 from '../assets/purple2.png';

// Square images callForDreamsGame 1
import blue3 from '../assets/callForDreamsGame/blueStar.png';
import orange3 from '../assets/callForDreamsGame/orangeStar.png';
import red3 from '../assets/callForDreamsGame/redStar.png';
import turquoise3 from '../assets/callForDreamsGame/turquoiseStar.png';
import yellow3 from '../assets/callForDreamsGame/yellowStar.png';

// New images for callForDreamsGame with devices
import blueDevice from '../assets/callForDreamsDeviceGame/blueDevice.png';
import pinkDevice from '../assets/callForDreamsDeviceGame/pinkDevice.png';
import greenDevice from '../assets/callForDreamsDeviceGame/greenDevice.png';

// Images for ConnectionsGame
import one from '../assets/ConnectionsGame/one.png';
import two from '../assets/ConnectionsGame/two.png';
import three from '../assets/ConnectionsGame/three.png';
import four from '../assets/ConnectionsGame/four.png';
// V2
import redPick from '../assets/ConnectionsGameV2/red.png';
import orangePick from '../assets/ConnectionsGameV2/orange.png';
import bluePick from '../assets/ConnectionsGameV2/blue.png';
// Week 3 & 4
import redPickPhase2 from '../assets/connectionsGameWeek3/red.png';
import orangePickPhase2 from '../assets/connectionsGameWeek3/orange.png';
import bluePickPhase2 from '../assets/connectionsGameWeek3/blue.png';

// week 5 & 6
import redPickPhase3 from '../assets/connectionsGameWeek4/red.png';
import orangePickPhase3 from '../assets/connectionsGameWeek4/orange.png';
import bluePickPhase3 from '../assets/connectionsGameWeek4/blue.png';

// week 7 & 8
import redPickPhase4 from '../assets/connectionsGameP4/red.png';
import orangePickPhase4 from '../assets/connectionsGameP4/orange.png';
import bluePickPhase4 from '../assets/connectionsGameP4/blue.png';

// week 9 & 10
import redPickPhase5 from '../assets/connectionsGameWeek5/red.png';
import orangePickPhase5 from '../assets/connectionsGameWeek5/orange.png';
import bluePickPhase5 from '../assets/connectionsGameWeek5/blue.png';

const MATCHING_SIZE = 3;
const BOMB_RADIUS = 1.5;

/* 


*/

export const Colors = [
    { Red: 'red', Blue: 'blue', Yellow: 'yellow', Green: 'green', Purple: 'purple' },
    {
        Red: 'red',
        Blue: 'blue',
        Gold: 'gold',
        Silver: 'silver',
        Yellow: 'yellow',
        Purple: 'purple'
    },
    { Blue: 'blue', Turquoise: 'turquoise', Orange: 'orange', Yellow: 'yellow', Red: 'red' },
    { Blue: 'blue', Pink: 'pink', Green: 'green' },
    { One: 'one', Two: 'two', Three: 'three', Four: 'four' },
    { Red: 'red', Orange: 'orange', Blue: 'blue' },
    { Red: 'red', Orange: 'orange', Blue: 'blue' },
    { Red: 'red', Orange: 'orange', Blue: 'blue' },
    { Red: 'red', Orange: 'orange', Blue: 'blue' },
    { Red: 'red', Orange: 'orange', Blue: 'blue' }
];

export const Images = [
    { red: red, blue: blue, yellow: yellow, green: green, purple: purple },
    { red: red2, blue: blue2, gold: gold2, silver: silver2, yellow: yellow2, purple: purple2 },
    { blue: blue3, turquoise: turquoise3, orange: orange3, yellow: yellow3, red: red3 },
    { blue: blueDevice, pink: pinkDevice, green: greenDevice },
    { one: one, two: two, three: three, four: four },
    { red: redPick, orange: orangePick, blue: bluePick },
    { red: redPickPhase2, orange: orangePickPhase2, blue: bluePickPhase2 },
    { red: redPickPhase3, orange: orangePickPhase3, blue: bluePickPhase3 },
    { red: redPickPhase4, orange: orangePickPhase4, blue: bluePickPhase4 },
    { red: redPickPhase5, orange: orangePickPhase5, blue: bluePickPhase5 }
];

export const COLORS = () => {
    let gameIndex = store.get('matchThreeGame') || 5;

    return Object.values(Colors[gameIndex]);
};

const createRandomColor = () => {
    return randomNth(COLORS());
};

export const ItemType = {
    ColorBomb: 'ColorBomb',
    RadiusBomb: 'RadiusBomb',
    LineBomb: 'LineBomb'
};

// const createRandomItemType = () => {
//   return Math.random() <= 1 / 20 ? randomNth(R.values(ItemType)) : undefined;
// };

// export const createRandomItem = () => ({
//   id: createId(),
//   color: createRandomColor(),
//   image: color => Images[color],
//   //type: createRandomItemType(),
//   type: undefined
// });

export const createRandomItem = () => {
    let color = createRandomColor();
    let gameIndex = store.get('matchThreeGame') || 0;

    return {
        id: createId(),
        color: color,
        image: Images[gameIndex][color],
        //type: createRandomItemType(),
        type: undefined
    };
};

export const createRandomBoard = (rowCount = 7, columnCount = 7) =>
    R.times(() => R.times(() => createRandomItem(), columnCount), rowCount);

const mergeColumns = R.zipWith((item1, item2) => (R.isNil(item1) || R.isNil(item2) ? null : item1));

const mergeBoards = R.zipWith(mergeColumns);

const toColumnCount = R.length;

const toRowCount = R.pipe(R.head, R.length);

const toIndexes = R.memoizeWith(
    (board) => R.join(', ', [toColumnCount(board), toRowCount(board)]),
    (board) => R.xprod(R.range(0, toColumnCount(board)), R.range(0, toRowCount(board)))
);
const toIndexesWhere = (predicate, board) =>
    R.filter((index) => predicate(index, R.path(index, board)), toIndexes(board));

const setIndex = (index, value, board) => R.set(R.lensPath(index), value, board);

/* 

clear matchings

*/

const clearColumnMatchings = R.map(
    R.pipe(
        R.groupWith(R.eqBy(R.prop('color'))),
        R.map(R.when(R.pipe(R.length, R.gte(R.__, MATCHING_SIZE)), R.map(R.always(null)))),
        R.unnest
    )
);

const clearRowMatchings = R.pipe(R.transpose, clearColumnMatchings, R.transpose);

const clearMatchings = (board) => {
    return mergeBoards(clearRowMatchings(board), clearColumnMatchings(board));
};

//

const toMatchingIndexes = (board) =>
    toIndexesWhere((index, item) => R.isNil(item), clearMatchings(board));

const toRowMatchingIndexes = (board) =>
    toIndexesWhere((index, item) => R.isNil(item), clearRowMatchings(board));

const toColumnMatchingIndexes = (board) =>
    toIndexesWhere((index, item) => R.isNil(item), clearColumnMatchings(board));

/* 

clear radius bombs

*/

const isRadiusBomb = (item) => item.type === ItemType.RadiusBomb;

const toRadiusBombIndexes = (board) => toIndexesWhere((index, item) => isRadiusBomb(item), board);

const clearRadius = (index1, board) =>
    R.reduce(
        (board, index) => setIndex(index, null, board),
        board,
        toIndexesWhere((index2) => distance(index1, index2) <= BOMB_RADIUS, board)
    );

const clearRadiusBombs = (board) =>
    R.reduce(
        (runningBoard, index) => clearRadius(index, runningBoard),
        board,
        R.intersection(toMatchingIndexes(board), toRadiusBombIndexes(board))
    );

/* 

clear same color bombs

*/

const isColorBomb = (item) => item.type === ItemType.ColorBomb;

const toColorBombIndexes = (board) => toIndexesWhere((index, item) => isColorBomb(item), board);

const clearColor = (color, board) =>
    R.map(R.map(R.when((item) => item?.color === color, R.always(null))), board);

const clearColorBombs = (board) =>
    R.reduce(
        (runningBoard, index) => clearColor(R.path(index, board).color, runningBoard),
        board,
        R.intersection(toMatchingIndexes(board), toColorBombIndexes(board))
    );

/* 

clear line bombs

*/

const isLineBomb = (item) => item.type === ItemType.LineBomb;

const toLineBombIndexes = (board) => toIndexesWhere((index, item) => isLineBomb(item), board);

//

const clearColumn = (columnIndex, board) =>
    R.reduce(
        (runningBoard, index) => setIndex(index, null, runningBoard),
        board,
        toIndexesWhere((index) => index[0] === columnIndex, board)
    );

const clearColumnLineBombs = (board) =>
    R.reduce(
        (runningBoard, index) => clearColumn(index[0], runningBoard),
        board,
        R.intersection(toColumnMatchingIndexes(board), toLineBombIndexes(board))
    );

//

const clearRow = (rowIndex, board) =>
    R.reduce(
        (runningBoard, index) => setIndex(index, null, runningBoard),
        board,
        toIndexesWhere((index) => index[1] === rowIndex, board)
    );

const clearRowLineBombs = (board) =>
    R.reduce(
        (runningBoard, index) => clearRow(index[1], runningBoard),
        board,
        R.intersection(toRowMatchingIndexes(board), toLineBombIndexes(board))
    );

/* 

clear board

*/

export const clear = (board) =>
    R.reduce(mergeBoards, board, [
        clearMatchings(board),
        clearRadiusBombs(board),
        clearColorBombs(board),
        clearColumnLineBombs(board),
        clearRowLineBombs(board)
    ]);

/* 


*/

export const collapse = R.map(R.sort(R.descend(R.isNil)));

/* 


*/

export const fill = R.map(R.map(R.when(R.isNil, createRandomItem)));

/* 


*/

export const isStable = R.converge(R.equals, [R.identity, clear]);

/* 


*/

export const swap = R.curry((index1, index2, board) =>
    R.pipe(
        R.assocPath(index1, R.path(index2, board)),
        R.assocPath(index2, R.path(index1, board))
    )(board)
);

/* 


*/

export const isAdjacent = R.pipe(distance, R.equals(1));
