import { useState } from 'react';
import styles from './IqosMasterclass.module.scss';

// Helpers
import { IqosMasterclassSliderData } from '@containers/IQOSUniverse/articles/IqosMasterclass/IqosMasterclassSliderData';

// Assets
import videoThumbnail from './assets/videoThumbnail.jpg';

// Components
import VideoModal from '@components/VideoModal';
import KeenSlider from '@components/KeenSlider';
import ArticleImage from '@containers/IQOSUniverse/components/ArticleImage';
import ArticleHeader from '@containers/IQOSUniverse/components/ArticleHeader';
import ArticleContainer from '@containers/IQOSUniverse/components/ArticleContainer';

const IqosMasterclass = () => {
    const [toggleModal, setToggleModal] = useState(false);
    const [currentVideo, setCurrentVideo] = useState('');

    const handleShowModal = (videoId: string) => {
        setCurrentVideo(videoId);
        setToggleModal(true);
    };

    const handleCloseModal = () => {
        setCurrentVideo('');
        setToggleModal(false);
    };

    return (
        <section className={styles.wrapper}>
            <ArticleHeader />

            <div className="content">
                <h3>Design Interior reinterpretat de sărbători</h3>

                <p>
                    Știm deja că ne împărtășești dorința de a evolua continuu și cauți întotdeauna
                    moduri surprinzătoare prin care să te reinventezi. Pornind de la acest gând, am
                    creat o serie de masterclass-uri care să-ți inspire momentele speciale de
                    sărbători.
                </p>

                <p>
                    Descoperă, alături de designer-ul de interior Adela Pârvu, câteva modalități
                    inedite prin care poți crea un decor reinterpretat de Crăciun, folosindu-te doar
                    de obiectele pe care le ai în casă și de un pic de inspirație și creativitate.
                </p>
            </div>

            <ArticleContainer background={'#00D1D2'}>
                <div className="content">
                    <h3>Masterclass de design interior cu Adela Pârvu</h3>

                    <KeenSlider withArrows={false} mode={'snap'} origin={'center'} spacing={10}>
                        {IqosMasterclassSliderData.map(({ id, videoId, subtitle }) => {
                            return (
                                <div className={styles.slideContainer} key={id}>
                                    <ArticleImage
                                        isVideo
                                        mobileImage={videoThumbnail}
                                        image={videoThumbnail}
                                        onClick={() => handleShowModal(videoId)}
                                    />

                                    <h3>{subtitle}</h3>
                                </div>
                            );
                        })}
                    </KeenSlider>

                    {currentVideo ? (
                        <VideoModal
                            toggleModal={toggleModal}
                            setToggleModal={handleCloseModal}
                            video={currentVideo}
                        />
                    ) : null}
                </div>
            </ArticleContainer>
        </section>
    );
};

export default IqosMasterclass;
