import dayjs from 'dayjs';

// Set Romanian locale globally
import 'dayjs/locale/ro';

// Plugins (https://day.js.org/docs/en/plugin/plugin)
import advancedFormat from 'dayjs/plugin/advancedFormat';
import localeData from 'dayjs/plugin/localeData';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import customParseFromat from 'dayjs/plugin/customParseFormat';
import toObject from 'dayjs/plugin/toObject';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import updateLocale from 'dayjs/plugin/updateLocale';
import isBetween from 'dayjs/plugin/isBetween';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

dayjs.locale('ro');
dayjs.extend(advancedFormat);
dayjs.extend(localeData);
dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(customParseFromat);
dayjs.extend(toObject);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(updateLocale);
dayjs.extend(isBetween);
dayjs.extend(weekday);
dayjs.extend(weekOfYear);
dayjs.extend(isSameOrAfter);

dayjs.updateLocale('ro', {
    relativeTime: {
        ...dayjs.Ls['ro'].relativeTime,
        s: '', // replace "acum câteva secunde" with "acum"
        mm: '%d min.',
        m: 'un min.'
    },
    monthsShort: [
        'Ian.',
        'Feb.',
        'Mar.',
        'Apr.',
        'Mai',
        'Iun.',
        'Iul.',
        'Aug.',
        'Sept.',
        'Oct.',
        'Nov.',
        'Dec.'
    ]
});

export default dayjs;
