export const CollapsePinIcon = () => {
    return (
        <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="28" height="28" rx="14" fill="#F6F4F0" />
            <path
                opacity="0.7"
                d="M15.4286 8.4V11.9C15.4286 12.684 15.6929 13.412 16.1429 14H11.8571C12.3214 13.398 12.5714 12.67 12.5714 11.9V8.4H15.4286ZM17.5714 7H10.4286C10.0357 7 9.71429 7.315 9.71429 7.7C9.71429 8.085 10.0357 8.4 10.4286 8.4H11.1429V11.9C11.1429 13.062 10.1857 14 9 14V15.4H13.2643V20.3L13.9786 21L14.6929 20.3V15.4H19V14C17.8143 14 16.8571 13.062 16.8571 11.9V8.4H17.5714C17.9643 8.4 18.2857 8.085 18.2857 7.7C18.2857 7.315 17.9643 7 17.5714 7Z"
                fill="#34303D"
            />
        </svg>
    );
};
