import { useEffect, useState } from 'react';
import { IButtonProps } from './interface';

// Components
import BaseButton from '@components/Layout/Button/components/BaseButton';
import BaseAnchor from '@components/Layout/Button/components/BaseAnchor';
import BaseLink from '@components/Layout/Button/components/BaseLink';
import ExternalModal from '@components/ExternalModal';

// Utils
import store from 'store';
import { config } from '@utils/config';
import validURL from '@utils/validURL';
import useToggle from '@utils/hooks/useToggle';

// Redux
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { redirectSSOAsync, selectLoading } from 'slices/ssoSlice';

// Tracking
import { TagManager } from '@utils/GTM';
import { DataLayerArgs } from 'react-gtm-module';

const Button = (props: IButtonProps) => {
    const { disabled = false, href, target, onClick, tagEvent } = props;
    const dispatch = useAppDispatch();

    const token = store.get(config.user.authToken);
    const ssoLoadingSelector = useAppSelector(selectLoading);

    const [modalOpen, toggleModal] = useToggle();
    const [ssoButtonLoading, toggleSsoButtonLoading] = useState(false);

    const _onClick = (e: any) => {
        e.preventDefault();

        if (!disabled) {
            if (onClick) onClick();
            if (href) {
                if (validURL(href)) {
                    if (target === '_blank') {
                        window.open(href, '_blank');
                    } else {
                        (window as Window).location = href;
                    }
                } else {
                    (window as Window).location = href;
                }
            }
        }
    };

    const _onTrack = () => {
        if (tagEvent) {
            TagManager.dataLayer(tagEvent);
        }
        if (onClick) onClick();
    };

    const ssoLogin = (href: string) => {
        toggleSsoButtonLoading(true);
        dispatch(redirectSSOAsync(href));
    };

    useEffect(() => {
        if (!ssoLoadingSelector) {
            toggleSsoButtonLoading(false);
        }
    }, [ssoLoadingSelector]);

    if (href) {
        if (validURL(href)) {
            // const { host } = new URL(href);
            const host = href;

            if (host.includes('iqos.ro') && token && host !== config.path.socialMedia.facebook) {
                // SSO for buttons that redirects to iqos.ro
                return (
                    <BaseButton
                        {...props}
                        loading={ssoButtonLoading}
                        onClick={() => {
                            ssoLogin(href);
                            onClick && onClick();
                        }}
                    />
                );
            } else if (
                (!host.includes('iqos.ro/') &&
                    !host.includes('iqos-on') &&
                    !host.includes('bit.ly')) ||
                host === config.path.socialMedia.facebook
            ) {
                // External link modal for sites that aren't iqos.ro
                return (
                    <>
                        <BaseButton {...props} onClick={toggleModal} />

                        <ExternalModal
                            externalRoute={href}
                            toggleModal={toggleModal}
                            modalOpen={modalOpen}
                        />
                    </>
                );
            } else {
                // Other cases (buttons on <PublicRoute> pages for example)
                return <BaseAnchor {...props} onTrack={_onTrack} />;
            }
        } else {
            return <BaseLink {...props} onTrack={_onTrack} />;
        }
    } else {
        return <BaseButton {...props} onClick={(e) => _onClick(e)} onTrack={_onTrack} />;
    }
};

export default Button;
