import React, { useState } from 'react';
import styles from './SummerCampaignStart.module.scss';

// Helpers
import classnames from 'classnames';

// Components
import ArticleImage from '@containers/IQOSUniverse/components/ArticleImage';
import ArticleHeader from '@containers/IQOSUniverse/components/ArticleHeader';
import CompletedStateArticle from '@containers/IQOSUniverse/components/CompletedStateArticle/CompletedStateArticle';
import VideoModalQuiz from '@containers/IQOSUniverse/components/VideoModalQuiz';

// Assets
import image1 from './assets/image1.png';
import image1Mobile from './assets/image1Mobile.png';
import image2 from './assets/image2.png';
import image2Mobile from './assets/image2Mobile.png';
import videoImage from './assets/videoImage.png';
import videoImageMobile from './assets/videoImageMobile.png';
import icon from './assets/locationIcon.png';

// Redux
import { useAppSelector } from '@app/hooks';
import BaseModal from '@components/Layout/BaseModal';
import Button from '@components/Layout/Button';

const SummerCampaignStart = () => {
    const { article } = useAppSelector((state) => state.iqosUniverse);

    const [toggleModal, setToggleModal] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showMapModal, setMapModal] = useState(false);

    const listData: Array<{
        title: string;
        program: string;
        secondProgram?: string;
    }> = [
        {
            title: 'Alezzi Pop up (Nikos Alezzi)',
            program: 'Duminică - Miercuri: 10:00 - 22:00',
            secondProgram: 'Sâmbătă - 11:00 - 23:00'
        },
        {
            title: 'Neptun -Plaja Mandala (la stegulețe)',
            program: 'Luni - Duminică: 08:00 - 22:00'
        },
        {
            title: 'Costinești – Plaja Marimar Beach',
            program: 'Luni - Duminică: 08:00 - 22:00'
        },
        {
            title: 'Vama Veche – Vis a vis de „La Rogojini”',
            program: 'Duminică - Miercuri: 10:00 - 22:00',
            secondProgram: 'Sâmbătă - 11:00 - 23:00'
        }
    ];

    return (
        <section className={styles.wrapper}>
            <ArticleHeader hideTopVideoQuiz />
            <div className="content">
                <h3>Îți facem upgrade la vacanță cu distracție, dar și cu relaxare.</h3>

                <p>
                    Vara asta, ne-am propus să trăim împreună cele mai tari experiențe. Ne-am văzut
                    deja la festivaluri, la evenimente cool și bineînțeles, interacționăm zilnic și
                    în IQOS Club. Însă pe lângă asta, ne întâlnim și la mare!
                </p>

                <p>
                    Ți-am pregătit o mulțime de surprize care să aducă un plus de distracție
                    vacanței tale, dar și experiențe care să te ajute să te deconectezi și relaxezi.
                </p>
            </div>
            <div className={classnames('content', styles.noMargin)}>
                <ArticleImage cover image={image1} mobileImage={image1Mobile} />
            </div>
            <div className={classnames('content', styles.marginTop)}>
                <h3>Bucură-te de experiențe memorabile!</h3>

                <p>
                    Dacă mergi la mare în luna august, treci și pe la noi. Ne găsești la plaja Shut
                    Up Beach Mamaia, unde te așteptăm cu ședințe de masaj la care te poți programa
                    gratuit, dar și cu surprize pentru tine și prietenii tăi. <br/> Oferim în fiecare
                    sâmbătă două experiențe VIP pentru tine și încă 3 prieteni adulți. Tu trebuie
                    doar să te întâlnești în zilele de vineri cu reprezentantul IQOS aflat pe plajă.
                </p>

                <p>
                    În plus, te așteptăm și în punctele noastre fixe cu multe surprize. Vezi mai jos
                    lista acestora și programul lor.
                </p>
            </div>
            <div className={classnames('content', styles.list)}>
                {listData.map(({ title, program, secondProgram }) => {
                    return (
                        <div className={styles.row}>
                            <div className={styles.icon}>
                                <img src={icon} alt={'location icon'} />
                            </div>
                            <div className={styles.content}>
                                <h3 className={styles.title}>{title}</h3>
                                <p>Program:</p>
                                <p className={styles.program}>{program}</p>
                                <p className={styles.secondProgram}>{secondProgram}</p>
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className={classnames('content', styles.marginTop)}>
                <p>
                    Mai mult, în fiecare săptămână, de joi până duminică reprezentanții IQOS
                    vizitează locațiile și plajele partenere.{' '}
                    <Button
                        type="text"
                        color="dark"
                        hover="turquoise"
                        label="Descoperă "
                        onClick={() => setMapModal(true)}
                    />{' '}
                    harta locațiilor și vino să trăim împreună vara la următorul nivel.
                </p>
            </div>

            <div className={classnames('content', styles.margin)}>
                <ArticleImage cover image={image2} mobileImage={image2Mobile} />
            </div>
            <div className={classnames('content', styles.marginBottom)}>
                <h3>Urmărește cum s-au relaxat până acum membrii IQOS Club și câștigă 100 FP!</h3>

                <p>
                    Ca să-ți arătăm experiențele de care te poți bucura alături de IQOS la mare,
                    ți-am pregătit un clip care le sumarizează pe toate, dar îți prezintă și
                    locațiile în care ne găsești. Urmărește-l și câștigă 100 FP în contul tău!
                </p>
            </div>
            <ArticleImage
                cover
                isVideo
                mobileImage={videoImageMobile}
                image={videoImage}
                onClick={() => setToggleModal(true)}
            />
            <VideoModalQuiz
                toggleModal={toggleModal}
                setToggleModal={() => setToggleModal(false)}
                setShowSuccess={() => {
                    setShowSuccess(true);
                }}
            />
            {(showSuccess || article.completed_quiz) && (
                <CompletedStateArticle
                    className={styles.completedStateArticle}
                    article={{
                        points: article.points,
                        quiz_points: article.quiz_points,
                        quiz_end_date: article.quiz_end_date
                    }}
                />
            )}
            <BaseModal
                size="large"
                className={styles.mapModal}
                isModalOpen={showMapModal}
                closeModal={() => setMapModal(false)}
                destroyOnClose
                lockBodyScroll={false}
            >
                <section className={styles.mapModalContainer}>
                    <iframe
                        src="https://www.google.com/maps/d/u/0/embed?mid=14TjFrSK8cViC2aodJ1jz4I1HM3kO3f0&ehbc=2E312F%22&ll=44.02727215407445%2C28.606457150000008&z=10"
                        width="640"
                        height="480"
                    ></iframe>
                </section>
            </BaseModal>
        </section>
    );
};

export default SummerCampaignStart;
