import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import exampleReducer from '@containers/ExamplePage/exampleSlice';

import faqsReducer from '@containers/FAQs/FAQsSlice';
import regulationsReducer from '@containers/Regulation/RegulationSlice';
import heroBannersReducer from '@containers/Dashboard/HeroSection/HeroSectionSlice';
import contestsReducer from '@containers/Dashboard/ContestsSection/ContestsSectionSlice';
import discoverArticlesReducer from '@components/ListDiscoverArticles/ListDiscoverArticlesSlice';
import activitiesReducer from '@containers/Dashboard/Activities/ActivitiesSlice';
import leaderboardReducer from '@containers/Dashboard/SmallLeaderboard/SmallLeaderboardSlice';
import listWinnersReducer from '@containers/ListWinners/ListWinnersSlice';
import quizReducer from '@components/Quiz/QuizSlice';
import listStoreLocationsReducer from '@containers/ListLocations/ListLocationsSlice';

// Core
import countiesReducer from '@slices/countiesSlice';
import loginReducer from '@containers/Auth/Login/loginSlice';

// MGM
import inviteReducer from '@containers/MGM/pages/Invite/inviteSlice';
import historyReducer from '@containers/MGM/pages/History/historySlice';
import invitePublicReducer from '@containers/MGM/pages/InvitePublic/invitePublicSlice';
import recommendReducer from '@containers/MGM/components/RecommendForm/recommendFormSlice';

// OfflineRedemption
import offlineRedemptionReducer from '@containers/OfflineRedemption/OfflineRedemptionSlice';

// Monthly Challenge
import entryReducer from '@containers/MonthlyChallenge/pages/Entry/entrySlice';
import likeReducer from '@containers/MonthlyChallenge/components/LikeButton/likeButtonSlice';
import enrollChallengeReducer from '@containers/MonthlyChallenge/components/EnrollForm/enrollFormSlice';
import challengeGalleryReducer from '@containers/MonthlyChallenge/pages/ChallengeGallery/challengeGallerySlice';
import previousChallengesReducer from '@containers/MonthlyChallenge/pages/PreviousChallenges/previousChallengesSlice';

// IQOS Universe
import IQOSUniverseReducer from '@containers/IQOSUniverse/IQOSUniverseSlice';

// SSO
import ssoReducer from '../slices/ssoSlice';

// My account
import userReducer from '@slices/userSlice';
import addDeviceReducer from '@containers/NoDevice/addDeviceSlice';
import userDevicesReducer from '@containers/MyDevices/MyDevicesSlice';
import notificationsReducer from '@containers/Notifications/NotificationsSlice';

// Badges
import aboutBadgesReducer from '@containers/AboutBadges/pages/AboutBadges/AboutBadgesSlice';

// Add Pack Codes
import addPackCodesReducer from '@containers/Dashboard/AddPackCodes/AddPackCodesSlice';

// MyRewards
import userRewardsReducer from '@containers/MyRewards/slices/MyRewardsSlice';
import rewardReducer from '@containers/MyRewards/slices/RewardSlice';

// Global components
import qrCodeModalReducer from '@components/QRCodeModal/QRCodeModalSlice';
import miniProfieModalReducer from '@components/MiniProfileModal/MiniProfileModalSlice';
import phoneValidationReducer from '@containers/Auth/PhoneValidation/PhoneValidationSlice';
import SpecialGiftModalReducer from '@components/SpecialGiftModal/SpecialGiftModalSlice';
import TradeInModalReducer from '@components/TradeInModal/TradeInModalSlice';

// Activity Journal
import activityJournalReducer from '@containers/ActivityJournal/activityJournalSlice';

// Live Reactions Reducer
import LiveReactionsReducer from '@components/LiveReactions/LiveReactionsSlice';

// Stories
import storiesReducer from '@components/Stories/storiesSlice';

// Call for dreams phase two
import callForDreamsPhaseTwoReducer from '@containers/Campaigns/CallForDreamsPhaseTwo/callForDreamsPhaseTwoSlice';

// Call for dreams phase three
import callForDreamsPhaseThreeReducer from '@containers/Campaigns/CallForDreamsPhaseThree/callForDreamsPhaseThreeSlice';

// Real People Real Connections
import realPeopleRealConnectionsReducer from '@containers/Campaigns/RealPeopleRealConnections/realPeopleRealConnectionsSlice';

//Dashboard entry count
import benefitsDashboardCountReducer from '@containers/Dashboard/DashboardSlice';

//TicketingVoting
import ticketingVotingReducer from '@containers/Campaigns/LiveTicketing/pages/TicketingVoting/TicketingVotingSlice';

export const store = configureStore({
    reducer: {
        counter: counterReducer,
        example: exampleReducer,
        login: loginReducer,
        heroBanner: heroBannersReducer,
        contests: contestsReducer,
        discoverArticles: discoverArticlesReducer,
        activities: activitiesReducer,
        leaderboard: leaderboardReducer,
        listWinners: listWinnersReducer,
        addDevice: addDeviceReducer,
        counties: countiesReducer,
        recommend: recommendReducer,
        sso: ssoReducer,
        history: historyReducer,
        invite: inviteReducer,
        invitePublic: invitePublicReducer,
        userDevices: userDevicesReducer,
        user: userReducer,
        faqs: faqsReducer,
        regulations: regulationsReducer,
        notifications: notificationsReducer,
        offlineRedemption: offlineRedemptionReducer,
        previousChallenges: previousChallengesReducer,
        challengeGallery: challengeGalleryReducer,
        enrollChallenge: enrollChallengeReducer,
        entry: entryReducer,
        like: likeReducer,
        quiz: quizReducer,
        myRewards: userRewardsReducer,
        reward: rewardReducer,
        iqosUniverse: IQOSUniverseReducer,
        qrCodeModal: qrCodeModalReducer,
        miniProfileModal: miniProfieModalReducer,
        activityJournal: activityJournalReducer,
        aboutBadges: aboutBadgesReducer,
        addPackCodes: addPackCodesReducer,
        phoneValidationModal: phoneValidationReducer,
        specialGiftModal: SpecialGiftModalReducer,
        liveReactions: LiveReactionsReducer,
        listStoreLocations: listStoreLocationsReducer,
        stories: storiesReducer,
        benefitsDashboardCount: benefitsDashboardCountReducer,
        tradeInModal: TradeInModalReducer,
        callForDreamsPhaseTwo: callForDreamsPhaseTwoReducer,
        callForDreamsPhaseThree: callForDreamsPhaseThreeReducer,
        realPeopleRealConnections: realPeopleRealConnectionsReducer,
        ticketingVoting: ticketingVotingReducer
    },
    devTools: process.env.NODE_ENV !== 'production'
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
