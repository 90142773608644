import qs from 'qs';
import axiosRequest from '@utils/axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export interface ListWinnersState {
    status: 'idle' | 'loading' | 'failed';
    listWinners: [];
    meta: {
        current_page: number;
        per_page: number;
        total: number;
    };
    filters: {
        cities: [];
        contests: [];
        reward: [];
    };
    error: any;
}

interface Options {
    filter: {
        date: string | null;
        reward: string | null;
        contest: string | null;
        city: string | null;
    };
    per_page: number;
    page: number;
}

interface AxiosError {
    response: {
        data: {
            message: string;
        };
    };
}

const initialState: ListWinnersState = {
    status: 'idle',
    listWinners: [],
    meta: {
        current_page: 1,
        per_page: 5,
        total: 1
    },
    filters: {
        cities: [],
        contests: [],
        reward: []
    },
    error: {}
};

export const getListWinners = createAsyncThunk('containers/Winners', async (values: Options) => {
    try {
        const options = qs.stringify(values, { skipNulls: true });

        const response = await axiosRequest.get(`/winners?${decodeURI(options)}`).then((res) => {
            return res;
        });
        return response.data;
    } catch (e) {
        return (e as AxiosError).response.data.message;
    }
});

export const getWinnersFilters = createAsyncThunk('containers/WinnersFilters', async () => {
    try {
        const response = await axiosRequest.get('/winners/filters').then((res) => {
            return res;
        });
        return response.data;
    } catch (e) {
        return (e as AxiosError).response.data.message;
    }
});
export const listWinnersSlice = createSlice({
    name: 'listWinners',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getListWinners.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getListWinners.fulfilled, (state, action) => {
                state.status = 'idle';
                state.listWinners = action.payload.data;
                state.meta = action.payload.meta;
            })
            .addCase(getListWinners.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(getWinnersFilters.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getWinnersFilters.fulfilled, (state, action) => {
                state.status = 'idle';
                state.filters = action.payload;
            })
            .addCase(getWinnersFilters.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    }
});

export default listWinnersSlice.reducer;
