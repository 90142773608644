import { useEffect, useState } from 'react';
import { IReverseHangman } from './interface';

// Styles
import styles from './ReverseHangman.module.scss';

// Utils
import classNames from 'classnames';

const ReverseHangman = ({
    title = 'Completează propoziția:',
    image,
    clue,
    word,
    maxGuesses,
    onWin,
    onLose
}: IReverseHangman) => {
    const DEFAULT_SET = new Set();
    const normalizedWord = word.normalize('NFD').replace(/[\u0300-\u036f]/g, ''); // Remove diacritics
    const [guesses, setGuesses] = useState(DEFAULT_SET);
    const [wrongCount, setWrongCount] = useState(0);
    const [rightCount, setRightCount] = useState(0);
    const [gameEnded, setGameEnded] = useState(false);

    useEffect(() => {
        if (wrongCount >= maxGuesses && !gameEnded) {
            setGameEnded(true);
            onLose();
        }
    }, [wrongCount]);

    useEffect(() => {
        const userAnswer = [...guessedWord(false)].join('');

        if (userAnswer === normalizedWord && !gameEnded) {
            setGameEnded(true);
            onWin();
        }
    }, [guesses]);

    // Make the first letter guessed from the start
    useEffect(() => {
        handleGuess(normalizedWord[0]);
    }, []);

    const handleGuess = (letter: any) => {
        const updatedLetters = (guessed: any) => new Set(guessed).add(letter);
        const updatedWrongCount = wrongCount + (normalizedWord.includes(letter) ? 0 : 1);
        const updatedRightCount =
            rightCount +
            (normalizedWord.includes(letter) ? normalizedWord.split(letter).length - 1 : 0);

        setGuesses(updatedLetters);
        setWrongCount(updatedWrongCount);
        setRightCount(updatedRightCount);
    };

    // showDiacriticsWord: We want to keep the logic of the game without diacritics, but show on screen the correspondent word with diacritics
    const guessedWord = (showDiacriticsWord: boolean) => {
        return normalizedWord.split('').map((bingo, index) => {
            if (guesses.has(bingo)) {
                return showDiacriticsWord ? word[index] : bingo;
            } else {
                if (bingo === ' ') {
                    return ' ';
                } else {
                    return '_';
                }
            }
        });
    };

    const getActiveClass = (letter: any) => {
        return guesses.has(letter) && normalizedWord.includes(letter) ? styles.correct : '';
    };

    const generateKeyboard = () =>
        ['qwertyuiop', 'asdfghjkl', 'zxcvbnm'].map((row) => (
            <div className={styles.row}>
                {row.split('').map((letter) => (
                    <button
                        key={letter}
                        value={letter}
                        onClick={() => handleGuess(letter)}
                        disabled={guesses.has(letter)}
                        className={getActiveClass(letter)}
                    >
                        {letter}
                    </button>
                ))}
            </div>
        ));

    return (
        <div className={styles.container}>
            <div>{generateMatrix(normalizedWord, image, rightCount)}</div>
            <div className={styles.clue}>
                <p>
                    <strong>{title}</strong>
                </p>
                <p dangerouslySetInnerHTML={{ __html: clue }} />
            </div>
            <div className={styles.guessedWord}>{guessedWord(true)}</div>
            <div className={styles.wrongLetters}>
                Litere greșite: {wrongCount} din {maxGuesses}
            </div>
            <div className={classNames(styles.keyboard, gameEnded && styles.disabled)}>
                {generateKeyboard()}
            </div>
        </div>
    );
};

const generateMatrix = (word: string, backgroundImage: string, guessedLetters: number) => {
    const letters = word.length;
    const rowsAndColumns = [
        [2, 3],
        [1, 1],
        [2, 1],
        [2, 3],
        [2, 2],
        [1, 5],
        [2, 3],
        [1, 7],
        [2, 4],
        [3, 3],
        [2, 5],
        [2, 4],
        [3, 4],
        [13, 1]
    ];
    const [rows, columns] = rowsAndColumns[letters];

    return (
        <div className={styles.matrix} style={{ backgroundImage: `url(${backgroundImage})` }}>
            {[...Array(rows)].map((_, row) => (
                <div key={row} className={styles.row} style={{ height: `${100 / rows}%` }}>
                    {[...Array(columns)].map((_, column) => (
                        <div
                            key={column}
                            className={classNames(
                                styles.column,
                                row * columns + column + 1 <= guessedLetters && styles.guessed
                            )}
                            style={{ width: `${100 / columns}%` }}
                        ></div>
                    ))}
                </div>
            ))}
        </div>
    );
};

export default ReverseHangman;
