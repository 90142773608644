export const IQOSon = () => {
    return (
        <svg width="23" height="23" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 23">
            <g>
                <defs>
                    <rect id="SVGID_3_" x="-2.84" y="-2.84" width="28.84" height="28.84" />
                </defs>
                <clipPath id="SVGID_1_">
                    <use xlinkHref="#SVGID_3_" overflow="visible" />
                </clipPath>

                <path
                    clipPath="url(#SVGID_1_)"
                    fill="none"
                    stroke="#C4C3C6"
                    strokeWidth="1.7"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.92,2.18l2.05,2v12.81"
                />
                <path
                    clipPath="url(#SVGID_1_)"
                    fill="none"
                    stroke="#C4C3C6"
                    strokeWidth="1.7"
                    strokeLinecap="round"
                    d="M6.77,19.39h13.82"
                />
                <path
                    clipPath="url(#SVGID_1_)"
                    fill="none"
                    stroke="#C4C3C6"
                    strokeWidth="1.7"
                    d="M4.67,21.79c1.16,0,2.1-0.94,2.1-2.1
                    c0-1.16-0.94-2.1-2.1-2.1s-2.1,0.94-2.1,2.1C2.57,20.85,3.51,21.79,4.67,21.79z M8.7,6.17h9.37c0.4,0,0.72,0.32,0.72,0.72v8.77
                    c0,0.4-0.32,0.72-0.72,0.72H8.7c-0.4,0-0.72-0.32-0.72-0.72V6.89C7.97,6.49,8.3,6.17,8.7,6.17z"
                />
                <path
                    clipPath="url(#SVGID_1_)"
                    fill="#C4C3C6"
                    d="M2.42,1.22c0-0.33-0.27-0.6-0.6-0.6s-0.6,0.27-0.6,0.6s0.27,0.6,0.6,0.6
                    S2.42,1.55,2.42,1.22z"
                />
                <path
                    clipPath="url(#SVGID_1_)"
                    fill="none"
                    stroke="#C4C3C6"
                    strokeWidth="1.7"
                    d="M15.06,6.17H11.7c-0.4,0-0.72,0.32-0.72,0.72
                    v3.36c0,0.4,0.32,0.72,0.72,0.72h3.36c0.4,0,0.72-0.32,0.72-0.72V6.89C15.79,6.5,15.46,6.17,15.06,6.17z"
                />
            </g>
        </svg>
    );
};
