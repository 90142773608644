export interface Change {
    id:number;
    description:string;
}

export const ChangeList:Change[] = [
    {
        id:1,
        description:`Este mai simplu să utilizezi dispozitivul pentru că rezervele HEETS sunt introduse mai ușor în „stilou”.`
    },
    {
        id:2,
        description:`Pentru că în fiecare călătorie și vacanță puneți și IQOS în bagaj;`
    },
    {
        id:3,
        description:`Pentru că vă distrați cu IQOS în mașină, în timp ce dați volumul la maximum și dansați la semafor;`
    },
    {
        id:4,
        description:`Pentru că lucrați din greu pentru un proiect cu predare „ieri” și știți că nu mai aveți nevoie de întreruperi;`
    },
    {
        id:5,
        description:`Pentru că vă relaxați acasă cu un nou serial și nu vreți să puneți „pauză” între episoade;`
    },
    {
        id:6,
        description:`Pentru că ne inspirați să fim împreună și să ne uităm la lucruri dintr-o altă perspectivă!`
    }
]