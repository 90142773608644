export const LeaderBoardCircle = () => {
    return (
        <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9 17.2957C13.3983 17.2957 16.9638 13.7302 16.9638 9.33191C16.9638 4.93366 13.3983 1.36816 9 1.36816C4.60175 1.36816 1.03625 4.93366 1.03625 9.33191C1.03625 13.7302 4.60175 17.2957 9 17.2957Z"
                fill="#00D1D2"
                stroke="#34303D"
                strokeWidth="1.44444"
            />
            <path
                d="M9.06122 15.1444C12.3477 15.1444 15.0118 12.4802 15.0118 9.19379C15.0118 5.90735 12.3477 3.24316 9.06122 3.24316C5.77478 3.24316 3.1106 5.90735 3.1106 9.19379C3.1106 12.4802 5.77478 15.1444 9.06122 15.1444Z"
                fill="#FFFDFB"
            />
        </svg>
    );
};
