import styles from './CustomDisclaimer.module.scss';
import classnames from 'classnames';
import { ICustomDisclaimerProps } from '@components/CustomDisclaimer/interface';
import React from 'react';

const CustomDisclaimer = ({
    label,
    error = false,
    warning = false,
    light = false,
    lightBkg = false,
    lightBorder = false,
    fontSize = 14,
    className
}: ICustomDisclaimerProps) => {
    if (label.length === 0) {
        return null;
    }

    return (
        <div
            className={classnames(
                styles.wrapper,
                error && styles.error,
                warning && styles.warning,
                lightBkg && styles.light,
                lightBorder && styles.lightBorder,
                className
            )}
        >
            <p
                style={{ fontSize: fontSize }}
                className={classnames(light && styles.light)}
                dangerouslySetInnerHTML={{ __html: label }}
            />
        </div>
    );
};

export default CustomDisclaimer;
