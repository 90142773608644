import React from 'react';
import classnames from 'classnames';

import styles from './StardriftLaunchWrapup.module.scss';
import ArticleHeader from '@containers/IQOSUniverse/components/ArticleHeader';
import KeenSlider from '@components/KeenSlider';

import img01 from './assets/im1.jpg';
import img02 from './assets/drones.jpg';
import { slides } from './data';

import { useWindowSize } from '@utils/hooks/windowSize';

const StardriftLaunchWrapup = () => {
    const { width } = useWindowSize();
    const isMobile = width < 768;

    return (
        <section className={styles.wrapper}>
            <ArticleHeader />
            <div className="content">
                <p>
                    În data de 22 noiembrie a avut loc{' '}
                    <strong>lansarea oficială a noului IQOS ILUMA STARDRIFT</strong>, un dispozitiv
                    în ediție limitată care îi reprezintă pe toți cei care simt că pot atinge
                    stelele. Inspirat de designul său care combină albastrul infinit al cerului
                    înstelat și sclipirea aurie a prafului de stele, IQOS a organizat un eveniment
                    plin de surprize și experiențe multi-senzoriale.
                </p>
            </div>
            <div className={styles.imageWrapper}>
                <img src={img01} alt="devices" />
            </div>
            <div className="content">
                <h3>Mai multe surprize inspirate de IQOS ILUMA STARDRIFT</h3>
                <p>
                    În cadrul evenimentului, cei prezenți au descoperit universul IQOS ILUMA
                    STARDRIFT cu ajutorul unei instalații imersive. <br/> O altă surpriză a fost Harta
                    Stelară a Comunității IQOS. Invitații au fost rugați să introducă pe un
                    touchscreen 2 date importante pentru ei și astfel au descoperit steaua personală
                    în cadrul constelației întregii comunități IQOS. În plus, în cadrul
                    evenimentului participanții și-au putut grava numele sau constelația zodiacală
                    pe dispozitivul IQOS.
                </p>
            </div>
            <div className={styles.sliderSection}>
              <div className={classnames('content', styles.sliderWrapper)}>
                  <div className={styles.slider}>
                      <KeenSlider withArrows={true} mode="snap" spacing={isMobile ? 0 : 10}>
                          {slides.map(({ id, image }) => {
                              return (
                                  <div className={styles.slide} key={id}>
                                      <img src={image} alt={`sliderimg-${id}`} />
                                  </div>
                              );
                          })}
                      </KeenSlider>
                  </div>
              </div>
              <div className='content'>
                <h3>Momente artistice pentru toți cei care simt că pot atinge stelele</h3>
                <p>Inspirat de noul dispozitiv, Emil Rengle a coregrafiat un moment artistic pentru a celebra comunitatea IQOS. În continuare, atmosfera a fost întreținută de DJ Francis Mercier iar invitații au putut savura un cocktail cu design special, în ton cu sortimentul lor preferat de TEREA.</p>
              </div>
            </div>
            <div className='content'>
              <h3 style={{margin: 0}}>Împreună țintim către stele, cu noul IQOS ILUMA STARDRIFT!</h3>
            </div>
        </section>
    );
};

export default StardriftLaunchWrapup;
